const Path = /^(\/((\w+-{0,1}\w+)+|\w)?)$/; //Path allowing words, numbers, and one dash between words, with multiple slash starters

const Sizing = /(^$)|^(\d)+(\.\d+)?$|(auto)|(unset)|(inherit)|(initial)|(^$)/i; //margin, paddings
const FloatOrEmpty = /(^$)|^(\d)+(\.\d+)?$/i;
const IntOrEmpty = /(^$)|^\d+$/i;
const YouTube = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;

export const ExtraRegexes = {
    Path,
    Sizing,
    YouTube,
    IntOrEmpty,
    FloatOrEmpty
};
