import { useState } from "react";
import { ISetState } from "xa-generics";
import { FrameQtyView } from "./FrameQty.view";

interface IQtyProps {
    className?: string;
    disabled?: boolean;
    defaultQuantity?: number;
}

interface IQtyHook {
    qty: number;
    setQty: ISetState<number>;
    increment: () => void;
    decrement: () => void;
    JSX: JSX.Element;
}

export const FrameQtyHook = (props: IQtyProps): IQtyHook => {
    const [qty, setQty] = useState<number>(props.defaultQuantity || 1);

    const increment = (): void => {
        setQty((current) => current + 1);
    };

    const decrement = (): void => {
        setQty((current) => {
            const nextValue = current - 1;
            if (nextValue < 1) return current;
            return nextValue;
        });
    };

    const qtyClasses: string[] = ["global__quantity-hook"];
    if (props.className) qtyClasses.push(props.className);

    return {
        qty,
        setQty,
        increment,
        decrement,
        JSX: (
            <FrameQtyView
                qty={qty}
                increment={increment}
                decrement={decrement}
                disabled={props.disabled}
                className={props.className}
            />
        )
    };
};
