import { FC } from "react";
import { Send } from "@carbon/icons-react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { TextInput } from "../../../UI/InputFields/TextInput/TextInput.view";
import { ClientButton } from "../../../DomMapper/DomComponents/Button/ClientButton.view";
import { useTranslation } from "react-i18next";

interface IForgottenPasswordViewProps {}

export const ForgottenPasswordView: FC<IForgottenPasswordViewProps> = (props) => {
    const { t } = useTranslation<ILang>();

    return (
        <div className={"forgotten-password"}>
            <form onSubmit={(e) => e.preventDefault()} className="forgotten-password__form">
                <h3 className="forgotten-password__form--title">
                    {t<ILang>("forgotten_password_title")}
                </h3>
                <div className="forgotten-password__form--content">
                    <TextInput
                        id={"email"}
                        onChange={(value) => {}}
                        value={"example@gmail.com"}
                        labelText={"forgotten_password_email"}
                    />

                    <ClientButton type={"submit"} RightIcon={Send}>
                        {t<ILang>("submit")}
                    </ClientButton>
                </div>
            </form>
        </div>
    );
};
