import { axios, IDynamicObject } from "xa-generics";
import { WidgetsModel } from "../Model/Widgets.model";
import { IWidgets } from "../Interfaces/IWidgets.type";
import { RootDAO } from "../../../DAO/Restaurant.dao";

export abstract class WidgetsDAO extends RootDAO {
    private static url(): string {
        return `${this.baseUrl()}/widgets`;
    }

    public static async load(): Promise<WidgetsModel> {
        const request = await axios.getInstance().get<IDynamicObject<any>>(`${this.url()}`);
        return new WidgetsModel(request.data);
    }

    public static async save(data: IWidgets): Promise<void> {
        await axios.getInstance().put(`${this.url()}`, { widgets: data });
        return;
    }
}
