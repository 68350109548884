import {
    Row,
    Add,
    Edit,
    Close,
    Column,
    ArrowUp,
    ArrowDown,
    DragVertical
} from "@carbon/icons-react";
import { IAllDomTypes, ISection } from "../../DomTracker/Interfaces/IDomTypes.interface";
import { useTranslation } from "react-i18next";
import { useRestaurant } from "../../../Contexts/Restaurant.context";
import { useTemplate } from "../../Templates/Provider/Template.provider";
import { useEditor } from "../../Editor/Controller/EditorContext.provider";
import { useAccess } from "../../../Contexts/Access.context";
import { useDom } from "../../DomTracker/Controller/DomTracker.provider";
import { ILang } from "../../../Interfaces/ILang.type";
import { useComponentDnD } from "../../ComponentDnD/Provider/ComponentDnD.provider";

export interface IOverlayProps {
    parent: IAllDomTypes;
    isLast?: boolean;
    isFirst?: boolean;
    isPrimary?: boolean;
    /** Overlay control align (defaults to right) */
    align?: "left" | "center";
    isSection?: boolean;
    isSpecial?: boolean;
    /** For special types such as gallery, contact form, etc...
     * the delete should unmount the parent section instead.
     * Pass the sectionUUID to this prop to make that work. */
    unmountId?: string;
    className?: string;
    childSectionsIds?: string[];
    showOnlyBaseControls?: boolean;
}

const Overlay: React.FC<IOverlayProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { unmountNode } = useDom();
    const { accessModel } = useAccess();
    const { setSaveBlockData } = useTemplate();
    const { restaurant } = useRestaurant();
    const { moveSectionByArrow } = useComponentDnD();
    const { setEditor, editor, setDragOrigin, dragOrigin, setChanges } = useEditor();
    const id = props.parent.uuid;

    const classes: string[] = [
        `overlay`,
        `overlay-align-${props.align || "right"}`,
        `z-index-${props.isPrimary ? 0 : 1}`
    ];
    if (props.className) classes.push(props.className);
    if (props.isSection) classes.push("section-overlay");
    if (props.isSpecial) return null;

    return (
        <div className={classes.join(" ")}>
            <div className={`overlay__controls`}>
                {props.isSection && accessModel.super_admin && !props.showOnlyBaseControls && (
                    <div
                        className="svg danger-svg"
                        onClick={() => {
                            setSaveBlockData({
                                section_id: props.parent.uuid,
                                dom: props.parent as ISection,
                                restaurant_id: restaurant.id
                            });
                        }}
                        title={t<ILang>("add_as_block")}
                    >
                        <Add />
                    </div>
                )}
                {!props.isFirst &&
                    (props.isSection || props.unmountId) &&
                    !props.showOnlyBaseControls && (
                        <div
                            className="svg"
                            onClick={() => {
                                moveSectionByArrow(props.unmountId || props.parent.uuid, "UP");
                            }}
                            title={t<ILang>("move_up")}
                        >
                            <ArrowUp />
                        </div>
                    )}
                {!props.isLast &&
                    (props.isSection || props.unmountId) &&
                    !props.showOnlyBaseControls && (
                        <div
                            className="svg"
                            onClick={() => {
                                moveSectionByArrow(props.unmountId || props.parent.uuid, "DOWN");
                            }}
                            title={t<ILang>("move_down")}
                        >
                            <ArrowDown />
                        </div>
                    )}
                {props.parent.type !== "Column" && !props.showOnlyBaseControls && (
                    <div
                        className="svg"
                        onClick={() => {
                            if ("type" in props.parent && props.parent.type !== "Column") {
                                if (dragOrigin && dragOrigin.uuid === props.parent.uuid) {
                                    setDragOrigin(null);
                                } else setDragOrigin(props.parent);
                            }
                        }}
                        title={t<ILang>("dnd")}
                    >
                        <DragVertical />
                    </div>
                )}
                <div
                    className="svg"
                    onClick={() => {
                        if (dragOrigin && dragOrigin.uuid !== id) setDragOrigin(null);
                        setEditor(props.parent);
                    }}
                    title={t<ILang>("edit")}
                >
                    {props.parent.type === "Column" ? (
                        <Column />
                    ) : props.parent.type === "Section" ? (
                        <Row />
                    ) : (
                        <Edit />
                    )}
                </div>
                {props.parent.type !== "Column" && (
                    <div
                        className="svg"
                        title={t<ILang>("delete")}
                        onClick={() => {
                            if (props.unmountId) {
                                setChanges((current) => {
                                    const state = { ...current };
                                    state[props.unmountId!] = true;
                                    return state;
                                });
                                unmountNode(props.unmountId, "Section", props.childSectionsIds);
                            } else {
                                setChanges((current) => {
                                    const state = { ...current };
                                    state[id] = true;
                                    return state;
                                });
                                unmountNode(id, props.parent.type);
                            }
                            if (dragOrigin && dragOrigin.uuid === id) setDragOrigin(null);
                            if (editor?.uuid === id) setEditor(null);
                        }}
                    >
                        <Close />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Overlay;
