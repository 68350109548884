import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { Login } from "@carbon/icons-react";
import { NavLink } from "react-router-dom";
import { TextInput } from "../../../UI/InputFields/TextInput/TextInput.view";
import { ILoginForm } from "../Interfaces/ILoginForm.interface";
import { ErrorHandler } from "xa-error-with-lang";
import { useTranslation } from "react-i18next";
import { IWidgetLoginProps } from "../Controller/Login.controller";
import { GenericOnChange, IError, ILoading, ISetState } from "xa-generics";
import Loading from "../../../UI/Loading/Loading.view";
import Modal from "../../../UI/Modal/Modal.view";

interface ILoginViewProps extends IWidgetLoginProps {
    setForm: ISetState<ILoginForm>;
    setError: ISetState<IError>;
    loading: ILoading;
    form: ILoginForm;
    error: IError;
}

export const LoginView: FC<ILoginViewProps> = (props) => {
    const { t } = useTranslation<ILang>();

    const onChange = (id: keyof ILoginForm, value: any): void => {
        GenericOnChange(id, value, props.setForm);
    };

    return (
        <Modal
            submitIcon={Login}
            appLogin={props.app}
            onClose={props.onClose}
            onSubmit={() => {
                if (props.onSubmit) props.onSubmit();
                else props.onClose();
            }}
            heading={t<ILang>("login")}
            submitText={t<ILang>("login")}
        >
            <form
                className="login"
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <TextInput<ILoginForm>
                    required
                    id={"email"}
                    type={"email"}
                    value={props.form.email}
                    labelColor={props.app?.color}
                    onChange={(value) => onChange("email", value)}
                />
                <TextInput<ILoginForm>
                    required
                    id={"password"}
                    type={"password"}
                    value={props.form.password}
                    labelColor={props.app?.color}
                    onChange={(value) => onChange("password", value)}
                />
                <button type={"submit"} style={{ display: "none" }}></button>
            </form>

            {props.setShowRegistration && (
                <NavLink
                    className={"global__link"}
                    to={"/create-account"}
                    onClick={(e) => {
                        e.preventDefault();
                        props.onClose();
                        props.setShowRegistration!(true);
                    }}
                >
                    {t<ILang>("registration")}
                </NavLink>
            )}

            <NavLink
                className={"global__link"}
                to={"/account-access-problem"}
                onClick={(e) => e.preventDefault()}
            >
                {t<ILang>("forgotten_password")}
            </NavLink>

            <Loading loading={props.loading} />
            <ErrorHandler error={props.error} resetError={() => props.setError(null)} />
        </Modal>
    );
};
