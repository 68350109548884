import { FC } from "react";
import { map } from "lodash";
import { useWidgets } from "../../../Widgets/Controller/WidgetsContext.provider";
import { ErrorBoundary } from "../../../ErrorBoundary/View/ErrorBoundary.view";
import { INavbarSettings } from "../Interfaces/INavbarWidget.interface";
import { GetWidgetOverlay } from "../../Util/WidgetOverlay.util";
import { NavbarLinkElement } from "./NavbarLink.element";
import WidgetOverlay from "../../../Widgets/View/WidgetOverlay.view";

interface INavbarLinksProps {}

export const NavbarLinks: FC<INavbarLinksProps> = (props) => {
    const { widgets } = useWidgets();
    const { navbarLinks } = widgets.Navbar.draftSettings;

    const { showOverlay, ...events } = GetWidgetOverlay<INavbarSettings>("Navbar", "navbarLinks");

    return (
        <div className="navbar__bottom__links" {...events}>
            {showOverlay && (
                <WidgetOverlay<INavbarSettings>
                    align={"right"}
                    parent={widgets.Navbar}
                    subKey={"navbarLinks"}
                />
            )}
            {map(navbarLinks, (link, index) => (
                <ErrorBoundary key={`${index}-link-${link.index}`}>
                    <NavbarLinkElement key={`${index}-link-${link.index}-link-item`} link={link} />
                </ErrorBoundary>
            ))}
        </div>
    );
};
