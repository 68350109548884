import {
    FontStyles,
    WidthStyles,
    HeightStyles,
    MarginStyles,
    BorderStyles,
    PaddingStyles,
    OpacityStyles,
    BoxShadowSyles,
    TextShadowSyles,
    BackgroundStyles,
    BorderRadiusStyles,
    BackgroundImageStyles,
    DisplayStyles
} from "../Components/DomMapper/Static/GroupsStyles.static";
import { IGenericCSSFields } from "sitebuilder-common";

export const ALL_STYLES: IGenericCSSFields<true> = {
    color: true,
    textAlign: true,
    ...BackgroundImageStyles,
    ...BorderRadiusStyles,
    ...BackgroundStyles,
    ...TextShadowSyles,
    ...BoxShadowSyles,
    ...OpacityStyles,
    ...PaddingStyles,
    ...DisplayStyles,
    ...HeightStyles,
    ...MarginStyles,
    ...BorderStyles,
    ...WidthStyles,
    ...FontStyles,
    ...FontStyles
};

export const STYLE_GROUPS = {
    padding: {
        ...PaddingStyles
    },
    margin: {
        ...MarginStyles
    },
    background: {
        ...BackgroundStyles
    },
    backgroundImage: {
        ...BackgroundImageStyles
    },
    font: {
        ...FontStyles
    },
    borderRadius: {
        ...BorderRadiusStyles
    },
    border: {
        ...BorderStyles
    },
    opacity: {
        ...OpacityStyles
    },
    boxShadow: {
        ...BoxShadowSyles
    },
    textShadow: {
        ...TextShadowSyles
    },
    height: {
        ...HeightStyles
    },
    width: {
        ...WidthStyles
    },
    display: {
        ...DisplayStyles
    }
};
