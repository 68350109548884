import { map } from "lodash";
import { ISection } from "../../../DomTracker/Interfaces/IDomTypes.interface";
import { DnDEvents } from "../../Util/DND.util";
import { useImages } from "../../../../Contexts/Images.context";
import { SectionStyles } from "../../Static/SectionStyles.static";
import { ISectionContent } from "../../Interfaces/ISectionComponent.interface";
import { ParseComponentStyle } from "sitebuilder-common";
import DomColumn from "../Column/DomColumn.view";
import Overlay from "../../View/Overlay.view";

export interface IDomSectionProps {
    showOnlyBaseControls?: boolean;
    childSections?: ISection[];
    section: ISection;
    isFirst?: boolean;
    isLast?: boolean;
    index: number;
}

const DomSection: React.FC<IDomSectionProps> = (props) => {
    const { id, showOverlay, size, refs, ...events } = DnDEvents<ISectionContent>(
        props.section,
        "Section",
        props.index === 0,
        props.section.isSpecial
    );
    const { images } = useImages();
    const colCount: number = props.section.elements.length;
    const classes: string[] = ["section"];
    if (props.section.isSpecial) classes.push("special-section");
    classes.push(`section-grid-col-${colCount}`);
    classes.push(`section-${size}`);

    const style = ParseComponentStyle(props.section.content, SectionStyles, size, images);
    if (props.section.content.isInactive) {
        classes.push("inactive");
    }
    return (
        <div id={id} {...events} style={style} ref={refs.current[id]} className={classes.join(" ")}>
            {showOverlay && (
                <Overlay
                    isSection
                    align={"center"}
                    isLast={props.isLast}
                    parent={props.section}
                    isFirst={props.isFirst}
                    isSpecial={props.section.isSpecial}
                    showOnlyBaseControls={props.showOnlyBaseControls}
                    className={props.showOnlyBaseControls ? "slideshow-section-overlay" : undefined}
                />
            )}
            {map(props.section.elements, (column) => (
                <DomColumn
                    column={column}
                    key={column.uuid}
                    childSections={props.childSections}
                    isSlideshowColumn={props.showOnlyBaseControls}
                />
            ))}
        </div>
    );
};

export default DomSection;
