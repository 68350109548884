import Cookie from "js-cookie";
import moment from "moment";

export const SaveLangCookie = (lang: string): void => {
    Cookie.set("lang", lang, {
        secure: process.env.NODE_ENV === "production",
        sameSite: "Strict",
        expires: moment().add({ year: 10 }).toDate()
    });
};
