import { FC } from "react";
import { ISVGSize } from "./ISVGSize.type";

const SvgHeight: FC<ISVGSize> = ({ s, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 27.41 28.83"
            height={`${s}px`}
            width={`${s}px`}
            transform={`rotate(90)`}
            {...props}
        >
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="square"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M26 5.41l-4-4-4 4M26 23.41l-4 4-4-4"
            />
            <path
                fill="none"
                stroke="currentColor"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M22 1.41v26"
            />
            <path
                fill="currentColor"
                strokeLinecap="square"
                d="M10 3.41v2h3v5H8v2h5v4h-3v2h3v5h-3v2h3v3H0v-28h13v3h-3z"
            />
        </svg>
    );
};

export const Width16 = () => {
    return <SvgHeight s={"16"} />;
};

export const Width20 = () => {
    return <SvgHeight s={"20"} />;
};

export const Width24 = () => {
    return <SvgHeight s={"24"} />;
};

export const Width32 = () => {
    return <SvgHeight s={"32"} />;
};
