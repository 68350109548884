import { IFormInternal } from "../Components/UseForm/IUseForm.interface";
import { IUseFormResult } from "../Components/UseForm/UseForm.provider";

export const CheckIfHasValue = <Fields extends object, IsNonNullable extends boolean>(
    form: IUseFormResult<Fields, IsNonNullable>,
    groupOrDefaults: Omit<IFormInternal<Fields, any>, "unit">
): boolean => {
    if (form.isDirty) return true;
    for (let key in form.editor) {
        if (key === "unit") continue;
        const field = key as keyof Fields;
        if (!(field in groupOrDefaults)) continue;
        if (form.editor[field] !== groupOrDefaults[field as never]) return true;
    }
    return false;
};
