import { FC } from "react";
import { useProducts } from "../Context/ProductsContext.provider";
import { CategoryRowElement } from "./CategoryRow.element";
import { IProductsIndexProps } from "./Products.index";

interface IDefaultCategoryMapperViewProps extends IProductsIndexProps {}

export const DefaultCategoryMapperView: FC<IDefaultCategoryMapperViewProps> = (props) => {
    const { categories } = useProducts(props.filteredIds);
    if (props.filteredProducts.length > 0) return null;
    return (
        <div className={"products__categories default-categories"}>
            {categories.map((category, index) => {
                if (category.parent_id) return null;
                return (
                    <CategoryRowElement
                        key={`${index}-category-row`}
                        isMainCategory={true}
                        category={category}
                        {...props}
                    />
                );
            })}
        </div>
    );
};
