import { Add } from "@carbon/icons-react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useState } from "react";
import { IFieldBlur } from "../../../Editor/Utils/EditorHook.util";
import { v4 as uuidv4 } from "uuid";
import { IListElement } from "../../../DomMapper/Interfaces/IBulletListComponent.interface";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { cloneDeep, map } from "lodash";
import { useTranslation } from "react-i18next";
import { IDynamicObject, useAfterTriggerChanged } from "xa-generics";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import ListInputSubForm from "./ListInputSubForm.view";
import Button from "../../Button/Button.view";

type IList = { list?: any };

export type IListInput<Fields extends IList> = {
    defaults: IList;
    id: keyof Fields;
    noLabel?: boolean;
    labelText?: ILang;
    className?: string;
    onBlur: (data: IFieldBlur<Fields>) => void;
};

export const ListInput = <Fields extends IList>(props: IListInput<Fields>) => {
    let wrapperStyle: string[] = ["wrapper"];
    const { t, i18n } = useTranslation();
    const { restaurant } = useRestaurant();

    if (props.className) wrapperStyle.push(props.className);
    const id = props.id as ILang;

    const generateDefaultValue = (): IDynamicObject => {
        const value: IDynamicObject = {
            [restaurant.primary_language]: "",
            [i18n.language]: "" //This should match primary or secondary language, but just in case, it is initialized.
        };
        if (restaurant.secondary_language) {
            value[restaurant.secondary_language] = "";
        }
        return value;
    };

    const [list, setList] = useState<IListElement[]>(
        props.defaults.list || [{ id: uuidv4(), value: generateDefaultValue() }]
    );

    const onItemChange = (index: number, value: string): void => {
        const state = cloneDeep(list);
        state[index].value[i18n.language] = value;
        setList(state);
    };

    const onDelete = (index: number): void => {
        if (list.length === 1) return;
        const state = cloneDeep(list);
        state.splice(index, 1);
        setList(state);
    };

    const onCreate = (): void => {
        const state = cloneDeep(list);
        state.push({ id: uuidv4(), value: generateDefaultValue() });
        setList(state);
    };

    const reorder = (
        list: IListElement[],
        startIndex: number,
        endIndex: number
    ): IListElement[] => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result: DropResult): void => {
        if (!result.destination) {
            return;
        }

        const items = reorder(list, result.source.index, result.destination.index);

        setList(items);
    };

    useAfterTriggerChanged(() => {
        props.onBlur({ value: list, field: props.id });
    }, [list]);

    return (
        <div className={wrapperStyle.join(" ")}>
            {!props.noLabel && (
                <label htmlFor={props.id as string} className="input-label">
                    {t(props.labelText || id)}
                </label>
            )}

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {map(list, (item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <ListInputSubForm
                                                {...item}
                                                index={index}
                                                onDelete={onDelete}
                                                onBlur={onItemChange}
                                                language={i18n.language}
                                                key={`${item.id}-sub-form`}
                                                deleteDisabled={list.length === 1}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <div className="list-add-button">
                <Button kind={"SIMPLE"} size="FIELD" Icon={Add} onClick={onCreate}>
                    {t<ILang>("add_new")}
                </Button>
            </div>
        </div>
    );
};
