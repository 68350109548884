import { Interval, ISetState, useDidMount } from "xa-generics";
import { DomlessPageModel } from "../../DomTracker/Model/DomlessPage.model";
import { useResetHistory } from "../../ComponentHistory/Controller/ResetHistory.provider";
import { useTranslation } from "react-i18next";
import { useRestaurant } from "../../../Contexts/Restaurant.context";
import { GetEpochText } from "../../../Lang/EpochHook.util";
import { Edit, Launch, View } from "@carbon/icons-react";
import { useEditor } from "../../Editor/Controller/EditorContext.provider";
import { useState } from "react";
import { useDom } from "../../DomTracker/Controller/DomTracker.provider";
import { ILang } from "../../../Interfaces/ILang.type";
import Modal from "../../UI/Modal/Modal.view";
import Button from "../../UI/Button/Button.view";

export interface ISidemenuFooterProps {
    showCMPS: boolean;
    setShowCMPS: ISetState<boolean>;
    showHistory: boolean;
    setShowHistory: ISetState<boolean>;
}

const SidemenuFooter: React.FC<ISidemenuFooterProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { isSaving } = useResetHistory();
    const { restaurant } = useRestaurant();
    const { lastUpdatedAt, pages, currentPage, publishPage, setCurrentPage } = useDom();
    const { editor, setEditor, setPageEditor, pageEditor, hasChanges, setNavigateEvent } =
        useEditor();
    const [now, setNow] = useState<number>(Date.now());
    const [showConfirm, setShowConfirm] = useState<boolean>(false);

    const getPageName = (): string => {
        let text = currentPage?.name || "?";
        if (currentPage.draft) {
            text += ` ${t<ILang>("draft")}`;
        }
        return text;
    };

    const unsetControllers = (): void => {
        if (props.showCMPS) props.setShowCMPS(false);
        if (props.showHistory) props.setShowHistory(false);
        if (editor) setEditor(null);
        if (pageEditor) setPageEditor(null);
    };

    const timeText = GetEpochText(lastUpdatedAt?.toDate().getTime() || 0, now, lastUpdatedAt);
    const isInteractionAllowed: boolean =
        currentPage.draft !== null || !currentPage.is_active ? true : false;

    useDidMount(() => {
        Interval.setInt(
            () => {
                setNow(Date.now());
            },
            1000,
            "time"
        );
        return () => Interval.clearInt("time");
    });

    const classes: string[] = ["editor__footer"];
    if (pages.length === 0 || !isInteractionAllowed) classes.push("--disabled");
    if (currentPage.isCurrentPageDraft) classes.push("--dual-row");

    return (
        <>
            <div className={classes.join(" ")}>
                <button
                    className="editor__footer--last-saved"
                    onClick={() => {
                        if (!isInteractionAllowed) return;
                        if (pages.length !== 0) props.setShowHistory(!props.showHistory);
                    }}
                >
                    {isSaving ? t<ILang>("saving_content") : timeText}
                </button>
                {currentPage.isCurrentPageDraft && (
                    <Button
                        kind={"DANGER"}
                        className={"editor__footer--edit"}
                        Icon={currentPage.isCurrentPageDraft ? View : Edit}
                        onClick={() => {
                            const event = () => {
                                const bool = currentPage.isCurrentPageDraft ? false : true;
                                setCurrentPage(new DomlessPageModel(currentPage, restaurant, bool));
                            };
                            if (hasChanges) setNavigateEvent(() => event);
                            else event();
                        }}
                    >
                        {t<ILang>(currentPage.isCurrentPageDraft ? "view_original" : "edit_draft")}
                    </Button>
                )}
                <button
                    disabled={!isInteractionAllowed}
                    className={"editor__footer--save"}
                    onClick={() => setShowConfirm(true)}
                >
                    {t<ILang>("publish")}
                    <div className="svg">
                        <Launch />
                    </div>
                </button>
            </div>

            {showConfirm && (
                <Modal
                    size="XS"
                    onSubmit={() => {
                        publishPage(currentPage);
                        setShowConfirm(false);
                        unsetControllers();
                    }}
                    onClose={() => setShowConfirm(false)}
                    submitText={isSaving ? t<ILang>("saving_content") : t<ILang>("publish")}
                    heading={t<ILang>("warning")}
                    isSubmitDisabled={isSaving}
                    submitIcon={Launch}
                >
                    <p>
                        {t<ILang>("publish_warn1")} (<strong>{getPageName()}</strong>){" "}
                        {t<ILang>("publish_warn2")}
                    </p>
                </Modal>
            )}
        </>
    );
};

export default SidemenuFooter;
