import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { Margins } from "../../../UI/InputGroups/Margins/Margins.view";
import { Paddings } from "../../../UI/InputGroups/Paddings/Paddings.view";
import { Untransform } from "../../../../Utils/UntransformComponentContent.util";
import { IWidgetGroups } from "../../../Widgets/Interfaces/IWidgetGroups.type";
import { useTranslation } from "react-i18next";
import { INavbarEditorProps } from "./NavbarEditor.index";
import TextShadowView from "../../../UI/InputGroups/TextShadow/TextShadow.view";
import FontColor from "../../../UI/InputGroups/FontInputs/FontColor.input";
import Font from "../../../UI/InputGroups/FontInputs/Font.view";

interface INavbarLinkEditorViewProps extends INavbarEditorProps {}

export const NavbarLinkEditorView: FC<INavbarLinkEditorViewProps> = (props) => {
    const {
        i18n: { language }
    } = useTranslation<ILang>();
    const { size } = useDom();

    const widgetProp: IWidgetGroups = "navbarLink";
    if (props.subKey !== widgetProp) return null;

    const model = new Untransform(props.editor.draftSettings.navbarLink || {}, size, language);
    const defaults = model.toJSON();
    const units = model.getUnits();

    return (
        <>
            <FontColor
                onBlur={(data) => props.handleChange(widgetProp, data)}
                onReset={(keys) => props.onReset(widgetProp, keys)}
                defaultValues={defaults}
                units={units}
            />

            <Font
                onBlur={(data) => props.handleChange(widgetProp, data)}
                onReset={(keys) => props.onReset(widgetProp, keys)}
                defaultValues={defaults}
                units={units}
            />

            <TextShadowView
                onBlur={(data) => props.handleChange(widgetProp, data)}
                onReset={(keys) => props.onReset(widgetProp, keys)}
                defaultValues={defaults}
                units={units}
            />

            <Paddings
                onBlur={(data) => props.handleChange(widgetProp, data)}
                onReset={(keys) => props.onReset(widgetProp, keys)}
                defaultValues={defaults}
                units={units}
            />

            <Margins
                onBlur={(data) => props.handleChange(widgetProp, data)}
                onReset={(keys) => props.onReset(widgetProp, keys)}
                defaultValues={defaults}
                units={units}
            />
        </>
    );
};
