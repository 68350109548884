interface ISVGProps {
    className?: string;
    size?: number;
}

export const AppShoppingCart = ({ size, ...props }: ISVGProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={`${size || 16}px`}
            height={`${size || 16}px`}
            viewBox={`0 0 44 44`}
            {...props}
        >
            <g data-name="Group 4" transform="translate(-1619 80)">
                <rect
                    width="44"
                    height="44"
                    fill="#fff"
                    data-name="Rectangle 1"
                    rx="10"
                    transform="translate(1619 -80)"
                ></rect>
                <g
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.9"
                    transform="translate(262 -102.25)"
                >
                    <path d="M1371.667 40a.622.622 0 00-.471.228.848.848 0 00-.2.55v10.5a2.592 2.592 0 002.337 2.722h11.333a2.533 2.533 0 002.334-2.661V40.778a.848.848 0 00-.2-.55.622.622 0 00-.471-.228z"></path>
                    <path d="M1374.5 40v-1.25a4.5 4.5 0 019 0V40" data-name="Vector"></path>
                    <path d="M1374.5 43v.75a4.5 4.5 0 009 0V43" data-name="Vector"></path>
                </g>
            </g>
        </svg>
    );
};
