import { useApp } from "../../Provider/App.context";
import { IAppOrderIndexProps } from "./AppOrder.index";
import { ProductCategoryModel } from "../../../DomMapper/DomComponents/Products/Models/ProductCategory.model";
import { FC, useEffect, useRef } from "react";
import { useProducts } from "../../../DomMapper/DomComponents/Products/Context/ProductsContext.provider";

interface IAppCategoryElementProps extends IAppOrderIndexProps {
    containerRef: React.MutableRefObject<HTMLDivElement | null>;
    category: ProductCategoryModel;
    parentId?: string;
    currency: string;
}

export const AppV2CategoryElement: FC<IAppCategoryElementProps> = ({ category, ...props }) => {
    const { catObject } = useProducts();
    const { data, dataSource } = useApp();
    const isActive = category.id === props.selector.category?.id;
    const p = data[dataSource].product;
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (props.selector.category?.id === category.id) {
            ref.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
        }
    }, [props, category]);

    const style: React.CSSProperties = {
        backgroundColor: p.v2CatBgColor,
        color: p.v2CatColor
    };

    let name = category.name;
    if (category.parent_id && catObject[category.parent_id]) {
        name = `${catObject[category.parent_id].name} / ${name}`;
    }

    return (
        <>
            {category.has_child ? (
                category.children.map((cat) => (
                    <AppV2CategoryElement
                        {...props}
                        category={cat}
                        parentId={category.id}
                        key={`${cat.id}-sub-category`}
                    />
                ))
            ) : (
                <div
                    className={"app-category"}
                    ref={(r) => (ref.current = r)}
                    style={isActive ? style : undefined}
                    onClick={() => {
                        props.appProudctNavigate(category);
                        if (props.containerRef.current) {
                            const el = document.getElementById(category.id);
                            if (el) {
                                const top = el.getBoundingClientRect().top - 200;
                                props.containerRef.current.scrollTo({
                                    behavior: "smooth",
                                    top: props.containerRef.current.scrollTop + top,
                                    left: 0
                                });
                            }
                        }
                    }}
                >
                    <div className="app-category__name">{name}</div>
                </div>
            )}
        </>
    );
};
