import { TransformContent } from "./TransformContent.util";
import { DomlessPageModel } from "../Model/DomlessPage.model";
import { IContentUpdate } from "../Interfaces/ContentUpdate.interface";
import { PageDataModel } from "../Model/PageData.model";
import { IAllDomTypes } from "../Interfaces/IDomTypes.interface";
import { cloneDeep } from "lodash";
import { ISizes } from "sitebuilder-common";

const recursiveFindAndUpdate = (
    list: IAllDomTypes[],
    contentChange: Partial<IContentUpdate>[],
    size: ISizes,
    lang: string
): void => {
    for (let item of list) {
        if (contentChange.length === 0) break;

        for (let index in contentChange) {
            const value = contentChange[index];
            if (value.domType !== item.type || value.uuid !== item.uuid) continue;

            delete contentChange[index].uuid; //Remove props that are no longer necessary
            delete contentChange[index].domType; //and shouldn't be stored in the node content.

            //Merges the new values with the old ones
            TransformContent(item, contentChange[index], size, lang);

            contentChange.splice(parseInt(index), 1); //Remove the content change since it's been applied.
        }

        if ("elements" in item) {
            recursiveFindAndUpdate(item.elements, contentChange, size, lang);
        }
    }
};

export const UpdateDomContent = (
    unclonedPages: PageDataModel[],
    currentPage: DomlessPageModel,
    values: IContentUpdate[],
    size: ISizes,
    lang: string
): null | {
    state: PageDataModel[];
    updatedPageIndex: number;
} => {
    if (!values.length) return null; //Return on empty array
    const state = cloneDeep(unclonedPages);
    let updatedPageIndex: number = -1;
    for (let index in state) {
        const page = state[index];
        if (page.page_id !== currentPage.page_id) continue; //Only current page can be updated, so skip if it doesn't match
        if (!page.draft) continue;
        updatedPageIndex = parseInt(index);
        recursiveFindAndUpdate(page.draft.draft_elements.dom, values, size, lang);
    }
    return {
        state,
        updatedPageIndex
    };
};
