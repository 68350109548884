import { ILang } from "../../../../Interfaces/ILang.type";
import { IBlur } from "../../../Editor/Utils/EditorHook.util";
import { useState } from "react";
import { TextArea } from "../../InputFields/TextInput/TextArea.view";
import { TextInput } from "../../InputFields/TextInput/TextInput.view";
import { useTranslation } from "react-i18next";

type IText = { text: string };

export interface IComponentTextProps {
    onBlur: (data: IBlur<IText>) => void;
    type: "TEXT" | "TEXT_AREA";
    defaultValues: Partial<IText>;
    defaultText: ILang;
    labelText?: ILang;
}

const ComponentText: React.FC<IComponentTextProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const [text, setText] = useState<string>(
        props.defaultValues.text || t<ILang>(props.defaultText)
    );

    return props.type === "TEXT" ? (
        <TextInput<IText>
            id={"text"}
            value={text}
            onBlur={props.onBlur}
            labelText={props.labelText}
            onChange={(id, value) => setText(value)}
        />
    ) : (
        <TextArea<IText>
            id={"text"}
            value={text}
            onBlur={props.onBlur}
            labelText={props.labelText}
            onChange={(id, value) => setText(value)}
        />
    );
};

export default ComponentText;
