import { RootDAO } from "./Restaurant.dao";
import { ImageModel } from "sitebuilder-common";
import { IImageDimensions } from "../Interfaces/IImageDimensions.interface";
import { axios, IDynamicObject } from "xa-generics";

export abstract class ImageDAO extends RootDAO {
    private static url(id?: string): string {
        let url = `/api/partners/${this.pid}/restaurants/${this.rid}/images`;
        if (id) url += `/${id}`;
        return url;
    }

    private static templateURL(id: string, type: "restaurants" | "sections"): string {
        return `/api/partners/${this.pid}/restaurants/${this.rid}/templates/${type}/${id}/images/clone`;
    }

    public static async loadImages(): Promise<ImageModel[]> {
        const request = await axios.getInstance().get<IDynamicObject[]>(`${this.url()}`);
        return request.data.map((item) => new ImageModel(item));
    }

    public static async cloneRestImages(templateRestId: string): Promise<{
        restaurantImages: ImageModel[];
        templateImages: ImageModel[];
    }> {
        const request = await axios.getInstance().post<{
            templateImages: IDynamicObject<any>[];
            restaurantImages: IDynamicObject<any>[];
        }>(this.templateURL(templateRestId, "restaurants"));
        return {
            restaurantImages: request.data.restaurantImages.map((image) => new ImageModel(image)),
            templateImages: request.data.templateImages.map((image) => new ImageModel(image))
        };
    }

    public static async cloneSectImages(
        templateId: string,
        images_to_clone: string[]
    ): Promise<{
        restaurantImages: ImageModel[];
        templateImages: ImageModel[];
    }> {
        const request = await axios.getInstance().post<{
            templateImages: IDynamicObject<any>[];
            restaurantImages: IDynamicObject<any>[];
        }>(this.templateURL(templateId, "sections"), {
            images_to_clone
        });
        return {
            restaurantImages: request.data.restaurantImages.map((image) => new ImageModel(image)),
            templateImages: request.data.templateImages.map((image) => new ImageModel(image))
        };
    }

    public static async save(
        image: Blob | File,
        dimensions: IImageDimensions,
        fileNameIfBlob?: string
    ): Promise<ImageModel> {
        const data = new FormData();
        if (image instanceof File) {
            data.append("image", image, image.name);
        } else {
            data.append("image", image, fileNameIfBlob!);
        }
        data.append("width", `${dimensions.width}`);
        data.append("height", `${dimensions.height}`);

        const request = await axios.getInstance().post<IDynamicObject<any>>(`${this.url()}`, data);
        return new ImageModel(request.data);
    }

    public static async remove(id: string): Promise<void> {
        axios.getInstance().delete<void>(`${this.url(id)}`);
        return;
    }
}
