import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useCart } from "../../../WidgetModules/Cart/Provider/Cart.provider";
import { useTranslation } from "react-i18next";
import { RawSelectInput } from "../../../UI/InputFields/RawSelectInput/RawSelectInput.view";
import { ICartUserForm } from "../../../WidgetModules/Cart/Interfaces/ICartUserForm.interface";
import { ISelectOption } from "xa-generics";

interface IAppPaymentMethodsProps {}

export const AppPaymentMethods: FC<IAppPaymentMethodsProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { form } = useCart();

    return (
        <div className="cart-block cart-payment-methods">
            <RawSelectInput<ICartUserForm, ISelectOption>
                noLabel
                id={"payment_method_id"}
                onChange={form.handleChange}
                options={[
                    { id: "1", name: t<ILang>("simplepay") },
                    { id: "2", name: t<ILang>("cash") },
                    { id: "3", name: t<ILang>("otp_nice_card") }
                ]}
                value={form.editor.payment_method_id}
            />
        </div>
    );
};
