import { clone } from "lodash";
import { AppConfigsModel } from "sitebuilder-common";
import { ModelConstructor, IDynamicObject } from "xa-generics";

export class AppModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as AppMData;
        ModelConstructor(data, this);

        if (data.elements_config) {
            this.elements_config = new AppConfigsModel(JSON.parse(data.elements_config));
        } else this.elements_config = new AppConfigsModel();

        if (data.draft_elements_config) {
            this.draft_elements_config = new AppConfigsModel(
                JSON.parse(data.draft_elements_config)
            );
        } else this.draft_elements_config = new AppConfigsModel();
    }

    restaurant_id: string = "";
    elements_config!: AppConfigsModel;
    draft_elements_config!: AppConfigsModel;
    created_at: string = "";
    updated_at: string = "";

    public get Deconvert(): IDynamicObject<any> {
        const data: IDynamicObject<any> = clone(this);
        data.elements_config = JSON.stringify(this.elements_config);
        data.draft_elements_config = JSON.stringify(this.draft_elements_config);
        delete data.created_at;
        delete data.updated_at;
        return data;
    }
}

//If you add public methods, you may want to replace the empty string with them!
type AppMData = Partial<
    InstanceType<typeof AppModel> & {
        elements_config: string;
        draft_elements_config: string;
    }
>;
