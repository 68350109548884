import { ILang } from "../../../../../Interfaces/ILang.type";
import { ImageCrop } from "./ImageCrop.view";
import { useImages } from "../../../../../Contexts/Images.context";
import { ImageModel } from "sitebuilder-common";
import { ImageSelector } from "./ImageSelector.view";
import { Close, Upload } from "@carbon/icons-react";
import { useTranslation } from "react-i18next";
import { IImageInputProps } from "../Controller/ImageInput.controller";
import { MutableRefObject } from "react";
import { IDynamicObject, ISetState } from "xa-generics";

export interface IImageInputViewProps<T extends object> extends IImageInputProps<T> {
    fileSrc: string;
    clearFile: () => void;
    showSelector: boolean;
    setFileSrc: ISetState<string>;
    selectedImages: IDynamicObject;
    internalFile: string | File | null;
    onFileSelect: (file: File) => void;
    setShowSelector: ISetState<boolean>;
    onImageSelect: (imageId: string) => void;
    deleteWarnImgId: null | string | string[];
    setInternalFile: ISetState<string | File | null>;
    inputRef: MutableRefObject<HTMLInputElement | null>;
    setDeleteWarnImgId: ISetState<null | string | string[]>;
}

export const ImageInputView = <T extends object>(props: IImageInputViewProps<T>) => {
    const { t } = useTranslation<ILang>();
    const { images } = useImages();

    const isNull = props.internalFile === null || props.internalFile === "";
    const isStringId = typeof props.internalFile === "string" && props.internalFile.length > 0;
    const isFile = props.internalFile instanceof File;

    const classes: string[] = ["image-input"];
    const containerClasses: string[] = ["image-input__container"];

    if (props.className) classes.push(props.className);

    if (isNull) containerClasses.push("--layout-2");
    if (isStringId) containerClasses.push("--layout-3");
    if (isFile) containerClasses.push("--layout-2");

    const imageData: null | ImageModel = isStringId
        ? images[props.internalFile as string] || null
        : null;

    const id = props.id as string;

    return (
        <div className={classes.join(" ")}>
            {!props.noLabel && (
                <div className="image-input__label">
                    {props.customLabel || t<ILang>(props.labelText || (props.id as ILang))}
                </div>
            )}
            <div className={containerClasses.join(" ")}>
                {isStringId && (
                    <>
                        <div
                            className="image-input__box image-input__box--icon"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <img
                                src={imageData?.url || (props.internalFile as string)}
                                alt={imageData?.parsedName || (props.internalFile as string)}
                                className={"image-input__box--image"}
                            />
                        </div>
                        <div className="image-input__text">
                            {imageData?.parsedName || (props.internalFile as string)}
                        </div>
                    </>
                )}

                {isNull && (
                    <div
                        className="image-input__text --prompt"
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            props.setShowSelector(true);
                        }}
                    >
                        {t<ILang>("select_an_image")}
                    </div>
                )}
                {isFile && (
                    <div className="image-input__text">{(props.internalFile as File).name}</div>
                )}

                {isStringId && (
                    <div
                        className="image-input__box image-input__clear-icon"
                        onClick={() => {
                            props.clearFile();
                            props.onChange(props.id, "");
                        }}
                    >
                        <Close />
                    </div>
                )}
                {isNull && (
                    <label className="image-input__box image-input__upload-icon" htmlFor={id}>
                        <Upload />
                        <input
                            id={id}
                            name={id}
                            type={"file"}
                            multiple={false}
                            ref={(r) => (props.inputRef.current = r)}
                            className={"file-input"}
                            accept={"image/*"}
                            disabled={props.disabled}
                            onChange={(e) => {
                                const files: FileList | null = e.target.files;
                                if (files && files.length > 0) props.onFileSelect(files[0]);
                            }}
                        />
                    </label>
                )}

                {props.showSelector && <ImageSelector {...props} />}
            </div>

            {imageData && (
                <div className="image-input__dimensions">
                    {imageData.width} x {imageData.height}
                </div>
            )}

            {props.fileSrc && props.internalFile instanceof File && (
                <ImageCrop {...props} file={props.internalFile} onChange={props.onChange} />
            )}
        </div>
    );
};
