import { FC } from "react";
import { useForm } from "../../../UseForm/UseForm.provider";
import { IDisplay } from "./IDisplay.interface";
import { ResetValues } from "../../../Editor/Utils/ResetValues.util";
import { IInputGroup } from "../../../../Interfaces/IInputGroup.interface";
import { SelectInput } from "../../InputFields/Select/SelectInput.view";
import { Destructure } from "../../../../Utils/Destructure.util";
import { DisplayDefaults } from "./Defaults.static";
import { CheckIfHasValue } from "../../../../Utils/CheckIfHasValue.util";
import { AlignBoxMiddleCenter } from "@carbon/icons-react";
import ResetFormView from "../../InputGrouper/ResetForm.view";
import InputGrouper from "../../InputGrouper/InputGrouper.view";
import * as OPTIONS from "./DisplayOptions.static";

interface IDisplayProps extends IInputGroup<IDisplay<any>> {}

type IOption = { id: string };

export const Display: FC<IDisplayProps> = (props) => {
    const form = useForm<IDisplay, true>({
        editor: Destructure(DisplayDefaults, props.defaultValues),
        hookOnAnyFieldChange: (s, id, value) => {
            if (value) props.onBlur({ field: id, value });
            else props.onReset([id]);
        }
    });

    return (
        <InputGrouper
            groupKey={"Display"}
            Icon={AlignBoxMiddleCenter}
            labelKey={"display"}
            Reset={
                <ResetFormView
                    hasValue={CheckIfHasValue(form, DisplayDefaults)}
                    onReset={(e) => ResetValues(e, DisplayDefaults, form.reset, props.onReset)}
                />
            }
        >
            <div className="display-form">
                <SelectInput<IDisplay, IOption>
                    noLabel
                    isClearable
                    id={"display"}
                    nameAccessor={"id"}
                    errors={form.fieldErrors}
                    onChange={form.handleChange}
                    options={OPTIONS.DISPLAY_OPTIONS}
                    value={form.editor.display}
                />

                <SelectInput<IDisplay, IOption>
                    isClearable
                    nameAccessor={"id"}
                    id={"justifyContent"}
                    errors={form.fieldErrors}
                    onChange={form.handleChange}
                    options={OPTIONS.JUSTIFY_OPTIONS}
                    value={form.editor.justifyContent}
                />

                <SelectInput<IDisplay, IOption>
                    isClearable
                    id={"alignItems"}
                    nameAccessor={"id"}
                    errors={form.fieldErrors}
                    onChange={form.handleChange}
                    options={OPTIONS.JUSTIFY_OPTIONS}
                    value={form.editor.alignItems}
                />

                <SelectInput<IDisplay, IOption>
                    isClearable
                    id={"alignSelf"}
                    nameAccessor={"id"}
                    errors={form.fieldErrors}
                    onChange={form.handleChange}
                    options={OPTIONS.JUSTIFY_OPTIONS}
                    value={form.editor.alignSelf}
                />

                {(form.editor.display === "flex" || form.editor.display === "inline-flex") && (
                    <>
                        <SelectInput<IDisplay, IOption>
                            isClearable
                            nameAccessor={"id"}
                            id={"flexDirection"}
                            errors={form.fieldErrors}
                            onChange={form.handleChange}
                            options={OPTIONS.FLEX_DIRECTION_OPTIONS}
                            value={form.editor.flexDirection}
                        />

                        <SelectInput<IDisplay, IOption>
                            isClearable
                            id={"flexWrap"}
                            nameAccessor={"id"}
                            errors={form.fieldErrors}
                            onChange={form.handleChange}
                            options={OPTIONS.WRAP_OPTIONS}
                            value={form.editor.flexWrap}
                        />
                    </>
                )}
            </div>
        </InputGrouper>
    );
};
