import { IBlur } from "../Components/Editor/Utils/EditorHook.util";
import { IFieldBlur } from "./../Components/Editor/Utils/EditorHook.util";
import { ISizeMetrics } from "../Interfaces/IMetrics.type";
import { SPECIAL_VALUES } from "../Static/SpeicalValues.static";

type IConstraint = { unit: ISizeMetrics };

export const BuildGroupData = <GroupDataType extends IConstraint>(state: GroupDataType) => {
    const data: IBlur<GroupDataType> = [];
    for (let key in state) {
        if (key === "unit") continue;
        const field = key as keyof GroupDataType;
        if (!state[field] || SPECIAL_VALUES.indexOf(state[field] as never as string) > -1) {
            data.push({ field, value: state[field] });
        } else data.push({ field, value: `${state[field]}${state.unit}` });
    }
    return data as IBlur<Omit<GroupDataType, "unit">>;
};

type IPropConstraint<GroupType extends object> = {
    onBlur: (data: IFieldBlur<GroupType>) => void;
};

export const OnSizedBlur = <GroupType extends object>(
    props: IPropConstraint<GroupType>,
    data: IFieldBlur<GroupType>,
    size: ISizeMetrics
): void => {
    if (!data.value) return;
    if (SPECIAL_VALUES.indexOf(data.value) > -1) {
        props.onBlur({ field: data.field, value: data.value.trim() });
    } else {
        const value = `${data.value}${size}`.trim();
        props.onBlur({ field: data.field, value });
    }
};
