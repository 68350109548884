import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useIFrame } from "../../Provider/IFrame.context";
import { TextInput } from "../../../UI/InputFields/TextInput/TextInput.view";
import { ImageInput } from "../../../UI/InputFields/ImageInput/Controller/ImageInput.controller";
import { SelectInput } from "../../../UI/InputFields/Select/SelectInput.view";
import { FONT_OPTIONS } from "sitebuilder-common";
import { CheckboxInput } from "../../../UI/InputFields/Checkbox/Checkbox.view";
import { useTranslation } from "react-i18next";
import { BG_SIZE_OPTIONS } from "../../../UI/InputGroups/BackgroundImage/BGSizeOptions.static";
import { FrameEditorGroup } from "../IFrameEditorGroup.view";
import { BG_POSITION_OPTIONS } from "../../../UI/InputGroups/BackgroundImage/BGPositionOptions.static";
import { BG_ATTACHMENT_OPTIONS } from "../../../UI/InputGroups/BackgroundImage/BGAttachmentOptions.static";
import ColorInput from "../../../UI/InputFields/ColorInput/ColorInput.view";

interface IFrameGlobalFormProps {}

export const FrameGlobalForm: FC<IFrameGlobalFormProps> = (props) => {
    const { forms } = useIFrame<"global">();
    const { t } = useTranslation<ILang>();
    const { editor, fieldErrors, handleChange } = forms.global;
    const lastIndex = FONT_OPTIONS.length - 1;

    return (
        <FrameEditorGroup groupKey={"global"} nameKey={"iframe_global"}>
            <CheckboxInput
                onChange={handleChange}
                id={"isDarkTheme"}
                value={editor.isDarkTheme}
                labelText={"is_dark_theme"}
                errors={fieldErrors}
            />
            <CheckboxInput
                onChange={handleChange}
                labelText={"is_rounded_btn"}
                id={"isRounded"}
                value={editor.isRounded}
                errors={fieldErrors}
            />
            <SelectInput
                noLabel
                id={"fontFamily"}
                className={"font-input full-width"}
                errors={fieldErrors}
                value={editor.fontFamily || FONT_OPTIONS[lastIndex].id}
                options={FONT_OPTIONS}
                onChange={handleChange}
                isClearable
                formatOptionLabel={(option) => (
                    <div className={`font-option ${option.class}`}>
                        {option.name}{" "}
                        <span className="example-text">({t<ILang>("example_text_font")})</span>
                    </div>
                )}
            />
            <TextInput
                isNumeric
                errors={fieldErrors}
                onChange={handleChange}
                id={"takeAwayProductId"}
                value={editor.takeAwayProductId}
            />
            <ImageInput
                onChange={handleChange}
                value={editor.image}
                id={"image"}
                labelText={"backgroundImage"}
            />
            <SelectInput
                useTranslationOnName
                onChange={handleChange}
                id={"imageSize"}
                isClearable
                options={BG_SIZE_OPTIONS}
                labelText={"backgroundSize"}
                value={editor.imageSize}
            />
            <SelectInput
                isClearable
                useTranslationOnName
                onChange={handleChange}
                id={"imagePosition"}
                options={BG_POSITION_OPTIONS}
                labelText={"backgroundPosition"}
                value={editor.imagePosition}
            />
            <SelectInput
                useTranslationOnName
                onChange={handleChange}
                id={"imageAttachment"}
                isClearable
                options={BG_ATTACHMENT_OPTIONS}
                labelText={"backgroundAttachment"}
                value={editor.imageAttachment}
            />
            <CheckboxInput
                onChange={handleChange}
                id={"imageRepeat"}
                labelText={"backgroundRepeat"}
                value={editor.imageRepeat}
            />
            <ColorInput
                onChange={handleChange}
                id={"color"}
                labelText={"color"}
                value={editor.color}
                errors={fieldErrors}
                withValueSync
                isClearable
            />
            <ColorInput
                onChange={handleChange}
                labelText={"backgroundColor"}
                value={editor.backgroundColor}
                id={"backgroundColor"}
                errors={fieldErrors}
                withValueSync
                isClearable
            />
        </FrameEditorGroup>
    );
};
