export interface ISVGProps {
    className?: string;
    size: number;
}

export const CartSVG = ({ size, ...props }: ISVGProps) => (
    <svg
        viewBox="0 0 24 24"
        fill={"currentColor"}
        xmlns="http://www.w3.org/2000/svg"
        height={`${size || 16}px`}
        width={`${size || 16}px`}
        {...props}
    >
        <path d="m4.558 7 4.701-4.702a1.02 1.02 0 0 1 1.442 1.442L7.441 7H4.558zm12.001 0h2.883L14.74 2.298a1.02 1.02 0 0 0-1.441 1.442L16.559 7zM24 9v2h-.642a1.4 1.4 0 0 0-1.257.786L18 22H6L1.899 11.784A1.397 1.397 0 0 0 .643 11H0V9h24zM9 13a1 1 0 0 0-2 0v5a1 1 0 0 0 2 0v-5zm4 0a1 1 0 0 0-2 0v5a1 1 0 0 0 2 0v-5zm4 0a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0v-5z" />
    </svg>
);
