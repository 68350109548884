import { FC } from "react";
import { AppProductPageView } from "./AppProductPage.view";
import { IAppOrderIndexProps } from "./AppOrder.index";

export interface IAppSubproductCtrlProps extends IAppOrderIndexProps {}

export const AppSubproductController: FC<IAppSubproductCtrlProps> = (props) => {
    if (!props.selector.category || !props.selector.product) {
        return null;
    }

    const onItemSubmit = (): void => {
        props.unsetSelector("product");
    };

    return <AppProductPageView {...props} failedGroupId={""} onItemSubmit={onItemSubmit} />;
};
