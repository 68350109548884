import { useWidgetEditor } from "../../Editor/Controller/WidgetEditorContext.provider";
import { Cube, Edit, Row } from "@carbon/icons-react";
import { IWidgets } from "../Interfaces/IWidgets.type";

export interface IWidgetOverlayProps<WidgetGroup> {
    parent: IWidgets[keyof IWidgets];
    align: "left" | "center" | "right";
    subKey?: keyof WidgetGroup;
    isBlock?: boolean;
    isMain?: boolean;
}

const WidgetOverlay = <WidgetGroup extends string | object>(
    props: IWidgetOverlayProps<WidgetGroup>
) => {
    const { setWidgetEditor } = useWidgetEditor();
    const classes: string[] = [`overlay`, `overlay-align-${props.align}`, `z-index-1`];

    return (
        <div className={classes.join(" ")}>
            <div className={"overlay__controls"}>
                <div
                    className="svg"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setWidgetEditor({
                            group: props.parent,
                            subKey: props.subKey as string
                        });
                    }}
                >
                    {props.isMain ? <Cube /> : props.isBlock ? <Row /> : <Edit />}
                </div>
            </div>
        </div>
    );
};

export default WidgetOverlay;
