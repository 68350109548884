import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useTranslation } from "react-i18next";
import {
    GetCurrency,
    RenderPrice
} from "../../../DomMapper/DomComponents/Products/Utils/GetCurrency.util";

interface IAppCartPricesViewProps {}

export const AppCartPricesView: FC<IAppCartPricesViewProps> = (props) => {
    const currency = GetCurrency();
    const { t } = useTranslation<ILang>();

    return (
        <div className={"app-prices"}>
            <div className="cart__items--footer--prices">
                <div className="cart__items--footer--prices--row">
                    <span className="cart__items--footer--prices--row--label">
                        {t<ILang>("coupon")}:
                    </span>
                    {"coupon code"}
                </div>
                <div className="cart__items--footer--prices--row">
                    <span className="cart__items--footer--prices--row--label">
                        {t<ILang>("delivery_price")}:
                    </span>
                    {RenderPrice(currency, "500")}
                </div>
                <div className="cart__items--footer--prices--row">
                    <span className="cart__items--footer--prices--row--label">
                        {t<ILang>("discount")}:
                    </span>
                    <span className="total-price">{RenderPrice(currency, "300")}</span>
                </div>
                <div className="cart__items--footer--prices--row total-price-row">
                    <span className="cart__items--footer--prices--row--label">
                        {t<ILang>("total_price")}:
                    </span>
                    {RenderPrice(currency, "2300")}
                </div>
            </div>
        </div>
    );
};
