import { Width } from "../../UI/InputGroups/Width/Width.view";
import { Regexes } from "xa-generics";
import { Margins } from "../../UI/InputGroups/Margins/Margins.view";
import { useForm } from "../../UseForm/UseForm.provider";
import { Paddings } from "../../UI/InputGroups/Paddings/Paddings.view";
import { TextInput } from "../../UI/InputFields/TextInput/TextInput.view";
import { IDomElement } from "../../DomTracker/Interfaces/IDomTypes.interface";
import {
    IAppStoreCustoms,
    IAppStoreEditors
} from "../../DomMapper/Interfaces/IAppStoreComponent.interface";
import { EditorHook, IFieldBlur } from "../Utils/EditorHook.util";
import { InputGroupContextProvider } from "../../UI/InputGrouper/InputGroup.provider";
import FormSeparatorView from "../../UI/FormSeparator/FormSeparator.view";

export interface IAppStoreEditorViewProps {
    editor: IDomElement;
}

const AppStoreEditorView: React.FC<IAppStoreEditorViewProps> = (props) => {
    const { onBlur, defaults, onClear, units } = EditorHook<IAppStoreEditors>();

    const form = useForm<IAppStoreCustoms, true>({
        editor: {
            ios: defaults.ios || "",
            android: defaults.android || ""
        },
        initialRules: {
            ios: {
                pattern: {
                    value: Regexes.Url,
                    message: "invalid_url"
                }
            },
            android: {
                pattern: {
                    value: Regexes.Url,
                    message: "invalid_url"
                }
            }
        }
    });

    const onTextBlur = (data: IFieldBlur<IAppStoreCustoms>): void => {
        form.handleSubmit(() => {
            onBlur(data);
        })();
    };

    return (
        <InputGroupContextProvider defaultActive={"Width"}>
            <FormSeparatorView
                defKey={"AppStore"}
                defaultGroup={
                    <>
                        <TextInput
                            required
                            id={"android"}
                            autoComplete={false}
                            value={form.editor.android}
                            onChange={form.handleChange}
                            onBlur={onTextBlur}
                            errors={form.fieldErrors}
                        />

                        <TextInput
                            required
                            id={"ios"}
                            autoComplete={false}
                            value={form.editor.ios}
                            onChange={form.handleChange}
                            onBlur={onTextBlur}
                            errors={form.fieldErrors}
                        />
                    </>
                }
                advancedGroup={
                    <>
                        <Width
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Paddings
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Margins
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                    </>
                }
            />
        </InputGroupContextProvider>
    );
};

export default AppStoreEditorView;
