import React, { FC } from "react";
import { DnDEvents } from "../../../DomMapper/Util/DND.util";
import { ICarouselProps } from "../Controller/Carousel.controller";
import { ISlideshowContent } from "../../../DomMapper/Interfaces/ISlideShowComponent.interface";
import { IDynamicObject, ISetState } from "xa-generics";
import { ArrowLeft, ArrowRight, CircleFilled } from "@carbon/icons-react";
import DomSection from "../../../DomMapper/DomComponents/Section/Section.view";
import Overlay from "../../../DomMapper/View/Overlay.view";

interface ICarouselViewProps extends ICarouselProps {
    childRefs: React.MutableRefObject<IDynamicObject<HTMLElement | null>>;
    carouselRef: React.MutableRefObject<HTMLElement | null>;
    setCurrentVisibleIndex: ISetState<number>;
    currentVisibleIndex: number;
    oneWidth: number;
}

export const CarouselView: FC<ICarouselViewProps> = (props) => {
    const lastIndex = props.childSections.length - 1;
    const { refs, size, id, showOverlay, ...events } = DnDEvents<ISlideshowContent>(
        props,
        "ELEMENT"
    );

    return (
        <section className={"carousel"} id={props.uuid} {...events}>
            {showOverlay && (
                <Overlay
                    parent={props}
                    unmountId={props.sectionUUID}
                    childSectionsIds={props.childSections?.map((s) => s.uuid)}
                />
            )}

            {props.childSections.length > 1 && props.content.showArrows ? (
                <>
                    {props.currentVisibleIndex !== 0 && (
                        <button
                            className="carousel__control --left"
                            onClick={() => {
                                props.setCurrentVisibleIndex((c) => c - 1);
                            }}
                        >
                            <ArrowLeft />
                        </button>
                    )}
                    {props.currentVisibleIndex !== lastIndex && (
                        <button
                            className="carousel__control --right"
                            onClick={() => {
                                props.setCurrentVisibleIndex((c) => c + 1);
                            }}
                        >
                            <ArrowRight />
                        </button>
                    )}
                </>
            ) : null}

            <div className="carousel__container" ref={(ref) => (props.carouselRef.current = ref)}>
                {props.childSections.map((sect, index) => (
                    <section
                        className={"carousel__element"}
                        style={{ minWidth: `${props.oneWidth}px` }}
                        ref={(sectionRef) => {
                            //Stores the section container ref in the childRefs for various calculations...
                            props.childRefs.current[sect.uuid] = sectionRef;
                        }}
                        key={`${sect.uuid}-carousel-container`}
                    >
                        <DomSection
                            section={sect}
                            index={sect.index}
                            showOnlyBaseControls
                            key={`${index}-${sect.index}-${sect.uuid}-section`}
                        />
                    </section>
                ))}
            </div>

            {props.content.showElementDots && (
                <div className="carousel__dot-controls">
                    {props.childSections.map((s, index) => {
                        const cls = ["carousel__dot-controls--dot"];
                        if (index === props.currentVisibleIndex) cls.push("--ACTIVE");

                        return (
                            <button
                                className={cls.join(" ")}
                                key={`${s.uuid}-${index}-${props.uuid}-dot`}
                                onClick={() => props.setCurrentVisibleIndex(index)}
                            >
                                <CircleFilled />
                            </button>
                        );
                    })}
                </div>
            )}
        </section>
    );
};
