import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ILang } from "../../../Interfaces/ILang.type";

export interface IFormSeparatorViewProps {
    defaultGroup: JSX.Element | null;
    advancedGroup: JSX.Element | null;
    defKey?: ILang;
    advancedKey?: string;
    areKeysStrings?: boolean;
    noSecondaryKey?: boolean;
}

const FormSeparatorView: React.FC<IFormSeparatorViewProps> = (props) => {
    const defKey: ILang = props.defKey || "DEFAULT";
    const [active, setActive] = useState<string>(defKey);
    const { t } = useTranslation<ILang>();

    const defGroupClasses: string[] = ["form-group"];
    const advGroupClasses: string[] = ["form-group"];

    if (active === props.defKey) defGroupClasses.push("visible-group");
    else advGroupClasses.push("visible-group");

    const getHead = (key: string): JSX.Element => {
        const classes: string[] = ["group"];
        if (key === active) classes.push("active-group");

        return (
            <div onClick={() => setActive(key)} className={classes.join(" ")}>
                {props.areKeysStrings ? key : t<string>(key)}
            </div>
        );
    };

    const classes: string[] = ["form-group-header"];
    if (props.noSecondaryKey) classes.push("__single");

    return (
        <>
            <div className={classes.join(" ")}>
                {getHead(defKey)}
                {!props.noSecondaryKey && getHead(props.advancedKey || "ADVANCED")}
            </div>
            <div className={defGroupClasses.join(" ")}>{props.defaultGroup}</div>
            <div className={advGroupClasses.join(" ")}>{props.advancedGroup}</div>
        </>
    );
};

export default FormSeparatorView;
