import { FC } from "react";
import { useForm } from "../../UseForm/UseForm.provider";
import { useTexts } from "../../../Contexts/Texts.context";
import { DictModel } from "sitebuilder-common";
import { useRestaurant } from "../../../Contexts/Restaurant.context";
import { DictionaryView } from "../View/Dictionary.view";
import { useAfterTriggerChanged } from "xa-generics";

export interface IDictionaryProps {
    onClose: () => void;
}

export const Dictionary: FC<IDictionaryProps> = (props) => {
    const { restaurant } = useRestaurant();
    const { dict } = useTexts();
    const form = useForm<DictModel, false>({
        editor: null,
        initialRules: {
            text_key: true,
            primary_text: true,
            secondary_text: restaurant.secondary_language ? true : false
        }
    });

    useAfterTriggerChanged(() => {
        form.setEditor(null);
    }, [dict]);

    return <DictionaryView {...props} form={form} />;
};
