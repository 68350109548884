import { ILang } from "../../../../Interfaces/ILang.type";
import { IFieldBlur } from "../../../Editor/Utils/EditorHook.util";
import { IFormErrors } from "../../../UseForm/IUseForm.interface";
import { FormErrorView } from "../../../UseForm/FormError.view";
import { useTranslation } from "react-i18next";

export type ITextArea<Fields extends object> = {
    value: string;
    id: keyof Fields;
    hidden?: boolean;
    message?: string;
    noLabel?: boolean;
    labelText?: ILang;
    disabled?: boolean;
    className?: string;
    placeholder?: string;
    description?: string;
    errors?: IFormErrors<Fields>;
    onBlur?: (data: IFieldBlur<Fields>) => void;
    onChange?: (id: keyof Fields, value: string) => void;
};

export const TextArea = <Fields extends object>(props: ITextArea<Fields>) => {
    let wrapperStyle: string[] = ["wrapper"];
    const { t } = useTranslation();

    if (props.className) wrapperStyle.push(props.className);
    if (props.hidden) wrapperStyle.push("wrapper-hidden");
    if (props.disabled) wrapperStyle.push("wrapper-disabled");

    const id = props.id as string;

    return (
        <div className={wrapperStyle.join(" ")}>
            {!props.noLabel && (
                <label htmlFor={props.id as string} className="input-label">
                    {t(props.labelText || id)}
                </label>
            )}
            <textarea
                id={id}
                name={id}
                value={props.value}
                className={"text-area"}
                disabled={props.disabled}
                placeholder={props.placeholder}
                onChange={(e) => {
                    if (props.onChange) props.onChange(props.id, e.target.value);
                }}
                onBlur={(e) => {
                    if (props.onBlur) props.onBlur({ field: props.id, value: e.target.value });
                }}
            />
            {props.errors && <FormErrorView id={props.id} errors={props.errors} />}
            {props.description ? <p className="input-description">{props.description}</p> : null}
        </div>
    );
};
