import galleryImg1 from "../../../../Assets/placeholders/counted_gallery/column_gallery_1.jpg";
import galleryImg2 from "../../../../Assets/placeholders/counted_gallery/column_gallery_2.jpg";
import galleryImg3 from "../../../../Assets/placeholders/counted_gallery/column_gallery_3.jpg";
import galleryImg4 from "../../../../Assets/placeholders/counted_gallery/column_gallery_4.jpg";
import galleryImg5 from "../../../../Assets/placeholders/counted_gallery/column_gallery_5.jpg";
import infImg1 from "../../../../Assets/placeholders/infinite_gallery/gallery_1.jpg";
import infImg2 from "../../../../Assets/placeholders/infinite_gallery/gallery_2.jpg";
import infImg3 from "../../../../Assets/placeholders/infinite_gallery/gallery_3.jpg";
import infImg4 from "../../../../Assets/placeholders/infinite_gallery/gallery_4.jpg";
import infImg5 from "../../../../Assets/placeholders/infinite_gallery/gallery_5.jpg";
import infImg6 from "../../../../Assets/placeholders/infinite_gallery/gallery_6.jpg";
import infImg7 from "../../../../Assets/placeholders/infinite_gallery/gallery_7.jpg";
import infImg8 from "../../../../Assets/placeholders/infinite_gallery/gallery_8.jpg";
import infImg9 from "../../../../Assets/placeholders/infinite_gallery/gallery_9.jpg";
import infImg10 from "../../../../Assets/placeholders/infinite_gallery/gallery_10.jpg";

const infGallery: string[] = [
    infImg1,
    infImg2,
    infImg3,
    infImg4,
    infImg5,
    infImg6,
    infImg7,
    infImg8,
    infImg9,
    infImg10
];

const commonGallery: string[] = [galleryImg1, galleryImg2, galleryImg3, galleryImg4, galleryImg5];

export const DefaultImageGetter = (imgCount: number | string): string[] => {
    const imageCount = typeof imgCount === "number" ? imgCount : parseInt(imgCount || "3");

    if (imageCount > 5) {
        let i = 0;
        let count = 1;
        const images: string[] = [];
        while (count <= imageCount) {
            images.push(infGallery[i]);
            i++;
            count++;
            if (i === 10) i = 0;
        }
        return images;
    } else {
        return commonGallery;
    }
};
