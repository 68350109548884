import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { Checkmark } from "@carbon/icons-react";
import { FrameButton } from "../FrameButton.view";
import { CartDMCAView } from "../../../WidgetModules/Cart/View/CartDMCA.view";
import { CartItemsView } from "./CartItems.view";
import { useTranslation } from "react-i18next";
import { CartMessageView } from "../../../WidgetModules/Cart/View/CartMessage.view";
import { CartPaymentSplit } from "../../../WidgetModules/Cart/View/CartPaymentSplit.view";
import { CartUserDataView } from "../../../WidgetModules/Cart/View/CartUserData.view";
import { CartDeliveryAtView } from "../../../WidgetModules/Cart/View/CartDeliveryAt.view";
import { CartPaymentMethods } from "../../../WidgetModules/Cart/View/CartPaymentMethods.view";

interface ICartViewProps {}

export const FrameCartView: FC<ICartViewProps> = (props) => {
    const { t } = useTranslation<ILang>();

    const classes: string[] = ["frame-cart", "cart", "cart-md"];

    return (
        <div className={classes.join(" ")}>
            <div className="cart__container">
                <div className="cart__container--left">
                    <CartUserDataView />
                </div>

                <div className="cart__container--right">
                    <CartItemsView />
                    <CartDeliveryAtView />
                    <CartPaymentMethods />
                    <CartPaymentSplit />
                    <CartMessageView />
                    <CartDMCAView />

                    <div className="cart__finalize-button-container">
                        <FrameButton Icon={<Checkmark size={20} />}>
                            {t<ILang>("finalize_order")}
                        </FrameButton>
                    </div>
                </div>
            </div>
        </div>
    );
};
