import { IRegForm } from "../Interfaces/IRegForm.interface";
import { FC, useState } from "react";
import { RegistrationView } from "../View/Registration.view";
import { IError, ILoading } from "xa-generics";
import { AppConfigsModel } from "sitebuilder-common";

export interface IWidgetRegistrationProps {
    app?: AppConfigsModel["login"];
    onClose: () => void;
}

export const WidgetRegistration: FC<IWidgetRegistrationProps> = (props) => {
    const [showPw, setShowPw] = useState<boolean>(false);
    const [error, setError] = useState<IError>(null);
    const [loading] = useState<ILoading>(false);
    const [form, setForm] = useState<IRegForm>({
        email: "",
        password: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        city: "",
        street: "",
        premise_number: "",
        floor: "",
        door_number: "",
        doorbell: ""
    });

    return (
        <RegistrationView
            {...props}
            form={form}
            error={error}
            showPw={showPw}
            loading={loading}
            setForm={setForm}
            setError={setError}
            setShowPw={setShowPw}
        />
    );
};
