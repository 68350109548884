import { FC } from "react";
import { useApp } from "../../Provider/App.context";
import { TextArea } from "../../../UI/InputFields/TextInput/TextArea.view";
import { TextInput } from "../../../UI/InputFields/TextInput/TextInput.view";
import { ImageInput } from "../../../UI/InputFields/ImageInput/Controller/ImageInput.controller";
import { SelectInput } from "../../../UI/InputFields/Select/SelectInput.view";
import { CheckboxInput } from "../../../UI/InputFields/Checkbox/Checkbox.view";
import { AppEditorGroup } from "../AppEditorGroup.view";
import { BG_SIZE_OPTIONS } from "../../../UI/InputGroups/BackgroundImage/BGSizeOptions.static";
import { BG_POSITION_OPTIONS } from "../../../UI/InputGroups/BackgroundImage/BGPositionOptions.static";
import { BG_ATTACHMENT_OPTIONS } from "../../../UI/InputGroups/BackgroundImage/BGAttachmentOptions.static";
import ColorInput from "../../../UI/InputFields/ColorInput/ColorInput.view";

interface IAppSidemenuFormProps {}

export const AppSidemenuForm: FC<IAppSidemenuFormProps> = (props) => {
    const { forms } = useApp<"sidemenu">();
    const { editor, fieldErrors, handleChange } = forms.sidemenu;
    return (
        <AppEditorGroup groupKey={"sidemenu"} nameKey={"sidemenu"}>
            <ColorInput
                onChange={handleChange}
                id={"backgroundColor"}
                value={editor.backgroundColor}
                errors={fieldErrors}
                withValueSync
                isClearable
            />
            <TextInput
                id={"filter"}
                errors={fieldErrors}
                value={editor.filter}
                onChange={handleChange}
                placeholder={"blur(0.7rem)"}
            />
            <ColorInput
                onChange={handleChange}
                id={"color"}
                value={editor.color}
                errors={fieldErrors}
                withValueSync
                isClearable
            />
            <ImageInput
                onChange={handleChange}
                value={editor.backgroundImage}
                id={"backgroundImage"}
            />
            <SelectInput
                isClearable
                errors={fieldErrors}
                useTranslationOnName
                id={"backgroundSize"}
                onChange={handleChange}
                options={BG_SIZE_OPTIONS}
                value={editor.backgroundSize}
            />
            <SelectInput
                isClearable
                errors={fieldErrors}
                useTranslationOnName
                onChange={handleChange}
                id={"backgroundPosition"}
                options={BG_POSITION_OPTIONS}
                value={editor.backgroundPosition}
            />
            <SelectInput
                isClearable
                errors={fieldErrors}
                useTranslationOnName
                onChange={handleChange}
                id={"backgroundAttachment"}
                options={BG_ATTACHMENT_OPTIONS}
                value={editor.backgroundAttachment}
            />
            <CheckboxInput
                errors={fieldErrors}
                id={"backgroundRepeat"}
                onChange={handleChange}
                labelText={"backgroundRepeat"}
                value={editor.backgroundRepeat}
            />
            <TextArea
                id={"orderSVG"}
                errors={fieldErrors}
                onChange={handleChange}
                value={editor.orderSVG}
            />
            <TextArea
                id={"infoSVG"}
                errors={fieldErrors}
                onChange={handleChange}
                value={editor.infoSVG}
            />
            <TextArea
                id={"cartSVG"}
                errors={fieldErrors}
                onChange={handleChange}
                value={editor.cartSVG}
            />
            <TextArea
                id={"userSVG"}
                errors={fieldErrors}
                onChange={handleChange}
                value={editor.userSVG}
            />
            <TextArea
                id={"addressesSVG"}
                errors={fieldErrors}
                onChange={handleChange}
                value={editor.addressesSVG}
            />
            <TextArea
                id={"prevOrdersSVG"}
                errors={fieldErrors}
                onChange={handleChange}
                value={editor.prevOrdersSVG}
            />
            <TextArea
                id={"pointsSVG"}
                errors={fieldErrors}
                onChange={handleChange}
                value={editor.pointsSVG}
            />
            <TextArea
                id={"logoutSVG"}
                errors={fieldErrors}
                onChange={handleChange}
                value={editor.logoutSVG}
            />
        </AppEditorGroup>
    );
};
