import { ILang } from "../../../../Interfaces/ILang.type";
import { IBlur } from "../../../Editor/Utils/EditorHook.util";
import { IBorder } from "./IBorder.interface";
import { useForm } from "../../../UseForm/UseForm.provider";
import { TextInput } from "../../InputFields/TextInput/TextInput.view";
import { IInputGroup } from "../../../../Interfaces/IInputGroup.interface";
import { ResetValues } from "../../../Editor/Utils/ResetValues.util";
import { SelectInput } from "../../InputFields/Select/SelectInput.view";
import { Destructure } from "../../../../Utils/Destructure.util";
import { NUMERIC_RULE } from "../../../../Static/FormRules.static";
import { IFormWithUnit } from "../../../../Interfaces/IFormWithUnit.type";
import { BorderDefaults } from "./Defaults.static";
import { useTranslation } from "react-i18next";
import { CheckIfHasValue } from "../../../../Utils/CheckIfHasValue.util";
import { ISelectOption, useAfterTriggerChanged, useTimeout } from "xa-generics";
import {
    OpenPanelFilledBottom,
    OpenPanelFilledRight,
    OpenPanelFilledLeft,
    OpenPanelFilledTop,
    CircleDash
} from "@carbon/icons-react";
import SizeUnitControlView from "../../SizeUnitControl/SizeUnitControl.view";
import ResetFormView from "../../InputGrouper/ResetForm.view";
import InputGrouper from "../../InputGrouper/InputGrouper.view";
import ColorInput from "../../InputFields/ColorInput/ColorInput.view";

export interface IBorderProps extends IInputGroup<IBorder<any>> {}

export const Border: React.FC<IBorderProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { setTm } = useTimeout();

    const hasOneBorder = (state: IBorder): boolean => {
        if (
            state.borderBottomWidth ||
            state.borderTopWidth ||
            state.borderLeftWidth ||
            state.borderRightWidth
        )
            return true;
        return false;
    };

    const form = useForm<IFormWithUnit<IBorder>, true>({
        editor: Destructure(BorderDefaults, props.defaultValues, props.units.border),
        initialRules: {
            borderTopWidth: NUMERIC_RULE(t),
            borderBottomWidth: NUMERIC_RULE(t),
            borderLeftWidth: NUMERIC_RULE(t),
            borderRightWidth: NUMERIC_RULE(t)
        }
    });

    useAfterTriggerChanged(() => {
        form.handleSubmit((state) => {
            if (state.borderStyle && state.borderColor && hasOneBorder(state)) {
                let data: IBlur<IBorder> = [];
                for (let key in state) {
                    const field = key as keyof IBorder | "unit";
                    if (field === "unit") continue;
                    if (field !== "borderColor" && field !== "borderStyle") {
                        data.push({ field, value: `${state[field]}${state.unit}` });
                    } else {
                        data.push({ field, value: state[field] });
                    }
                }
                setTm(() => props.onBlur(data), 300, "queue-update");
            }
        })();
    }, [form.editor]);

    return (
        <InputGrouper
            groupKey={"Border"}
            Icon={CircleDash}
            labelKey={"border"}
            Reset={
                <ResetFormView
                    hasValue={CheckIfHasValue(form, BorderDefaults)}
                    onReset={(e) => ResetValues(e, BorderDefaults, form.reset, props.onReset)}
                />
            }
            Controls={<SizeUnitControlView unit={form.editor.unit} setUnit={form.handleChange} />}
        >
            <div className="border-radius-form">
                <TextInput<IBorder>
                    noLabel
                    id={"borderTopWidth"}
                    value={form.editor.borderTopWidth}
                    errors={form.fieldErrors}
                    onChange={form.handleChange}
                    unit={<OpenPanelFilledTop />}
                    className="border-radius-input"
                />
                <TextInput<IBorder>
                    noLabel
                    id={"borderRightWidth"}
                    value={form.editor.borderRightWidth}
                    errors={form.fieldErrors}
                    onChange={form.handleChange}
                    unit={<OpenPanelFilledRight />}
                    className="border-radius-input"
                />
                <TextInput<IBorder>
                    noLabel
                    id={"borderBottomWidth"}
                    value={form.editor.borderBottomWidth}
                    errors={form.fieldErrors}
                    onChange={form.handleChange}
                    unit={<OpenPanelFilledBottom />}
                    className="border-radius-input"
                />
                <TextInput<IBorder>
                    noLabel
                    id={"borderLeftWidth"}
                    value={form.editor.borderLeftWidth}
                    errors={form.fieldErrors}
                    onChange={form.handleChange}
                    unit={<OpenPanelFilledLeft />}
                    className="border-radius-input"
                />
            </div>
            <ColorInput<IBorder>
                onChange={form.handleChange}
                value={form.editor.borderColor}
                errors={form.fieldErrors}
                id={"borderColor"}
                withValueSync
            />

            <SelectInput<IBorder, ISelectOption>
                value={form.editor.borderStyle}
                onChange={form.handleChange}
                errors={form.fieldErrors}
                className={"full-width"}
                useTranslationOnName
                id={"borderStyle"}
                options={[
                    { id: "solid", name: "solid" },
                    { id: "dashed", name: "dashed" },
                    { id: "dotted", name: "dotted" },
                    { id: "double", name: "double" },
                    { id: "groove", name: "groove" },
                    { id: "ridge", name: "ridge" },
                    { id: "inset", name: "inset" },
                    { id: "outset", name: "outset" },
                    { id: "hidden", name: "hidden" }
                ]}
            />
        </InputGrouper>
    );
};
