import { ModelConstructor, IDynamicObject, setString } from "xa-generics";

export class OrderItemModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as OrderItemMData;
        ModelConstructor(data, this);

        if (data.item_options) {
            this.item_options = data.item_options.map((option) => new OrderOptionModel(option));
            this.optionListName = this.item_options
                .map((option) => `${option.quantity}x ${option.option.name}`)
                .join("; ");
        }

        if (data.product?.name) {
            this.product.name = setString(data.product.name);
        }
        this.name = this.product.name;
        if (data.subproduct?.name) {
            this.subproduct.name = setString(data.subproduct.name);
            this.name += ` | ${this.subproduct.name}`;
        }
    }

    id: string = "";
    order_id: string = "";
    subproduct_id: string = "";
    quantity: number = 0;
    created_at: string = "";
    updated_at: string = "";
    message: string = "";
    sale_id: string = "";
    unit_price: number = 0;
    total_price: number = 0;
    archived_at: string = "";
    is_new: boolean = false;
    remote_id: string = "";
    modified_columns: string = "";
    qstatus: string = "";
    options_parsed: boolean = false;
    product: { name: string } = { name: "" };
    subproduct: { name: string } = { name: "" };
    item_options: OrderOptionModel[] = [];

    //Custom props
    name: string = "";
    optionListName: string = "";
}

//If you add public methods, you may want to replace the empty string with them!
type OrderItemMData = Partial<InstanceType<typeof OrderItemModel>>;

export class OrderOptionModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as OrderOptionsMData;
        ModelConstructor(data, this);

        if (data.option?.name) {
            this.option.name = setString(data.option.name);
        }
    }

    id: string = "";
    order_item_id: string = "";
    option_id: string = "";
    quantity: number = 0;
    unit_price: number = 0;
    total_price: number = 0;
    qstatus: string = "";
    remote_id: string = "";
    created_at: string = "";
    updated_at: string = "";
    option: { name: string } = {
        name: ""
    };
}

//If you add public methods, you may want to replace the empty string with them!
type OrderOptionsMData = Partial<InstanceType<typeof OrderOptionModel>>;
