import { IMapStyles } from "../Interfaces/IMapComponent.interface";
import {
    BackgroundImageStyles,
    BackgroundStyles,
    PaddingStyles,
    MarginStyles,
    ColorStyles,
    FontStyles
} from "./GroupsStyles.static";

export const MapStyles: Required<IMapStyles<true>> = {
    ...FontStyles,
    ...ColorStyles,
    ...MarginStyles,
    ...PaddingStyles,
    ...BackgroundStyles,
    ...BackgroundImageStyles
};
