import ColorInput from "../ColorInput/ColorInput.view";
import { useTimeout, ISelectOption, useMountWithTriggers } from "xa-generics";
import { IBackgroundMetrics } from "../../../../Interfaces/IMetrics.type";
import { useTranslation } from "react-i18next";
import { ParseGradient } from "../../../../Utils/ParseGradient.util";
import { IGradientForm } from "./IGradientForm.interface";
import { SelectInput } from "../Select/SelectInput.view";
import { TextInput } from "../TextInput/TextInput.view";
import { useForm } from "../../../UseForm/UseForm.provider";
import { ILang } from "../../../../Interfaces/ILang.type";
import { FC } from "react";

export interface IGradientPickerInputProps {
    background?: string;
    currentUnit?: IBackgroundMetrics;
    onChange: (id: "background", value: string) => void;
}

const GradientPickerInput: FC<IGradientPickerInputProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const form = useForm<IGradientForm, true>({
        editor: ParseGradient(props.background),
        hookOnAnyFieldChange: (state) => {
            if (props.currentUnit === "GRADIENT") {
                const gradient = toGradient(state);
                setTm(() => props.onChange("background", gradient), 400, "gradientChange");
            }
        }
    });

    const { setTm } = useTimeout();

    const toType = (state: IGradientForm): string => {
        return `${state.type.toLowerCase()}-gradient`;
    };
    const toDeg = (state: IGradientForm): string => {
        if (state.deg) return `${state.deg}deg,`;
        else return "90deg";
    };
    const toGradient = (state: IGradientForm): string => {
        if (state.type === "LINEAR") {
            return `${toType(state)}(${toDeg(state)}${state.color1},${state.range}%,${
                state.color2
            })`;
        }
        return `${toType(state)}(${state.shape} ${state.size} ${state.position},${state.color1},${
            state.range
        }%,${state.color2})`;
    };

    const radialSettingsClasses: string[] = ["radial-settings"];
    if (form.editor.type === "LINEAR") radialSettingsClasses.push("hidden");

    useMountWithTriggers(() => {
        if (props.currentUnit === "GRADIENT") {
            const gradient = toGradient(form.editor);
            if (props.onChange) props.onChange("background", gradient);
            props.onChange("background", gradient);
        }
    }, [props.currentUnit]);

    return (
        <div className="gradient-picker">
            <ColorInput<IGradientForm>
                value={form.editor.color1}
                onChange={form.handleChange}
                errors={form.fieldErrors}
                labelText={"gradient1"}
                id={"color1"}
            />

            <ColorInput<IGradientForm>
                value={form.editor.color2}
                onChange={form.handleChange}
                errors={form.fieldErrors}
                labelText={"gradient2"}
                id={"color2"}
            />

            <SelectInput<IGradientForm, ISelectOption>
                onChange={form.handleChange}
                labelText={"gradient_type"}
                errors={form.fieldErrors}
                value={form.editor.type}
                useTranslationOnName
                id={"type"}
                options={[
                    { id: "LINEAR", name: "linear" },
                    { id: "RADIAL", name: "radial" }
                ]}
            />

            <div className="bar-container">
                <div className="gradient-bar-container">
                    <label className="gradient-label" htmlFor="range">
                        {t<ILang>("gradient_label")}
                    </label>
                    <input
                        type="range"
                        className="gradient-bar"
                        onChange={(e) => form.handleChange("range", e.target.value)}
                        style={{ background: toGradient(form.editor) }}
                    ></input>
                </div>
                <TextInput<IGradientForm>
                    isNumeric
                    id={"deg"}
                    className="deg-input"
                    value={form.editor.deg}
                    errors={form.fieldErrors}
                    onChange={form.handleChange}
                    hidden={form.editor.type === "RADIAL"}
                />
            </div>
            <div className={radialSettingsClasses.join(" ")}>
                <SelectInput<IGradientForm, ISelectOption>
                    onChange={form.handleChange}
                    value={form.editor.shape}
                    errors={form.fieldErrors}
                    labelText={"rad_shape"}
                    useTranslationOnName
                    id={"shape"}
                    options={[
                        { id: "ellipse", name: "ellipse" },
                        { id: "circle", name: "circle" }
                    ]}
                />
                <SelectInput<IGradientForm, ISelectOption>
                    onChange={form.handleChange}
                    value={form.editor.size}
                    errors={form.fieldErrors}
                    labelText={"rad_size"}
                    useTranslationOnName
                    id={"size"}
                    options={[
                        { id: "farthest-corner", name: "farthest_corner" },
                        { id: "closest-corner", name: "closest_corner" },
                        { id: "farthest-side", name: "farthest_side" }
                    ]}
                />
                <SelectInput<IGradientForm, ISelectOption>
                    onChange={form.handleChange}
                    value={form.editor.position}
                    errors={form.fieldErrors}
                    labelText={"rad_position"}
                    useTranslationOnName
                    id={"position"}
                    options={[
                        { id: "at center", name: "center" },
                        { id: "at left", name: "left" },
                        { id: "at top", name: "top" },
                        { id: "at right", name: "right" },
                        { id: "at bottom", name: "bottom" }
                    ]}
                />
            </div>
        </div>
    );
};

export default GradientPickerInput;
