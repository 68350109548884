import { ISizeMetrics } from "../../../Interfaces/IMetrics.type";

export interface ISizeUnitControlViewProps {
    disablePx?: boolean;
    disableRem?: boolean;
    unit?: ISizeMetrics | "";
    disablePercentage?: boolean;
    setUnit: (id: "unit", newUnit: ISizeMetrics) => void;
}

const SizeUnitControlView: React.FC<ISizeUnitControlViewProps> = (props) => {
    const getUnit = (unit: ISizeMetrics): JSX.Element => {
        const classes: string[] = ["global__units--item"];
        if (unit === props.unit || (!props.unit && unit === "rem")) {
            classes.push("active-unit");
        }
        return (
            <div onClick={() => props.setUnit("unit", unit)} className={classes.join(" ")}>
                {unit}
            </div>
        );
    };
    return (
        <div className="global__units">
            {!props.disablePx && getUnit("px")}
            {!props.disableRem && getUnit("rem")}
            {!props.disablePercentage && getUnit("%")}
        </div>
    );
};

export default SizeUnitControlView;
