import { useDom } from "../../DomTracker/Controller/DomTracker.provider";
import { cloneDeep } from "lodash";
import { IChangeHistory } from "../Interfaces/IChangeHistory.interface";
import { useChangeHistory } from "./ComponentHistory.provider";
import { IResetHistoryContext } from "../Interfaces/IResetHistoryContext.interface";
import { Context, createContext, useContext, useState } from "react";
import { useTimeout, useAfterTriggerChanged, useMountWithTriggers } from "xa-generics";

/**
 * ## ResetHistoryContext
 */
export const ResetHistoryContext: Context<IResetHistoryContext> =
    createContext<IResetHistoryContext>(null as any);

ResetHistoryContext.displayName = "ResetHistoryContext";

interface IResetHistoryContextProviderProps {}

/**
 * ## ResetHistory context provider component
 *
 */
export const ResetHistoryContextProvider: React.FC<IResetHistoryContextProviderProps> = (props) => {
    const { changeHistory, setCurrentIndex, currentIndex } = useChangeHistory();
    const { pages, setPages, currentPage, save } = useDom();
    const { setTm, clearTm } = useTimeout();
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const pageid = currentPage.page_id;
    const historyList = changeHistory[pageid] || [];
    const timeoutName: string = "autoSave";

    const stepInHistory = (page_id: string, dir: "FORWARD" | "BACKWARD"): void => {
        const history = changeHistory[page_id] || [];
        const maxIndex = history.length - 1;
        const ci = currentIndex[page_id];
        const nextIndex = dir === "FORWARD" ? ci - 1 : ci + 1;
        if (isNaN(nextIndex) || isNaN(ci)) return;
        if (!history.length || nextIndex === -1 || nextIndex > maxIndex) return;
        const changeObject = history[nextIndex];
        resetToThisState(changeObject, nextIndex);
    };

    const updateNodesByPageID = (history: IChangeHistory): void => {
        const state = cloneDeep(pages);
        for (let index in state) {
            const page = state[index];
            if (!page.draft) continue;

            if (page.page_id === history.page_id) {
                state[index].draft!.draft_elements.dom = history.state;
                break;
            }
        }
        setPages(state);
    };

    const resetToThisState = (history: IChangeHistory, index: number): void => {
        updateNodesByPageID(history);
        setCurrentIndex(history.page_id, index);
    };

    const handleKeyDown = (e: KeyboardEvent): void => {
        if (!currentPage?.page_id) return;
        if (!currentPage.isCurrentPageDraft) return;
        if (e.ctrlKey && e.key === "z") {
            stepInHistory(currentPage.page_id, "BACKWARD");
        }
        if (e.ctrlKey && e.key === "y") {
            stepInHistory(currentPage.page_id, "FORWARD");
        }
    };

    useMountWithTriggers(() => {
        window.addEventListener("keydown", handleKeyDown, { passive: true, capture: true });
        return () => window.removeEventListener("keydown", handleKeyDown, true);
    }, [changeHistory, pages, currentIndex, currentPage]);

    useAfterTriggerChanged(
        () => {
            if (historyList.length > 0) {
                setIsSaving(true);
                setTm(
                    () => {
                        if (historyList.length > 0) {
                            save();
                        }
                        setIsSaving(false);
                    },
                    2000,
                    timeoutName
                );
            }
        },
        [changeHistory, pages],
        () => {
            clearTm(timeoutName);
            setIsSaving(false);
        }
    );

    return (
        <ResetHistoryContext.Provider
            value={{
                resetToThisState,
                setIsSaving,
                isSaving
            }}
        >
            {props.children}
        </ResetHistoryContext.Provider>
    );
};

export const useResetHistory = (): IResetHistoryContext => useContext(ResetHistoryContext);
