import ColorInput from "../../InputFields/ColorInput/ColorInput.view";
import InputGrouper from "../../InputGrouper/InputGrouper.view";
import ResetFormView from "../../InputGrouper/ResetForm.view";
import { CheckIfHasValue } from "../../../../Utils/CheckIfHasValue.util";
import { ColorDefaults } from "./Defaults.static";
import { ColorPalette } from "@carbon/icons-react";
import { ResetValues } from "../../../Editor/Utils/ResetValues.util";
import { IInputGroup } from "../../../../Interfaces/IInputGroup.interface";
import { Destructure } from "../../../../Utils/Destructure.util";
import { useForm } from "../../../UseForm/UseForm.provider";
import { FC } from "react";
import { useAfterTriggerChanged } from "xa-generics";

type IColor = { color?: any };
export interface IFontColorProps extends IInputGroup<IColor> {}

const FontColor: FC<IFontColorProps> = (props) => {
    const form = useForm<IColor, true>({
        editor: Destructure(ColorDefaults, props.defaultValues)
    });

    useAfterTriggerChanged(() => {
        form.handleSubmit((state) => {
            props.onBlur({ field: "color", value: state.color });
        })();
    }, [form.editor.color]);

    return (
        <InputGrouper
            groupKey={"FontColor"}
            labelKey={"color"}
            Icon={ColorPalette}
            Reset={
                <ResetFormView
                    hasValue={CheckIfHasValue(form, ColorDefaults)}
                    onReset={(e) => ResetValues(e, ColorDefaults, form.reset, props.onReset)}
                />
            }
        >
            <ColorInput
                onChange={form.handleChange}
                value={form.editor.color}
                errors={form.fieldErrors}
                withValueSync
                id={"color"}
                noLabel
            />
        </InputGrouper>
    );
};

export default FontColor;
