import { FC } from "react";

export interface IInlineLoadingProps {
    isAlternate?: boolean;
}

export const InlineLoading: FC<IInlineLoadingProps> = (props) => {
    const classes: string[] = ["inline-loading"];
    if (props.isAlternate) classes.push("alternate-inline-loading");
    return (
        <div className={classes.join(" ")}>
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};
