import React, { FC } from "react";
import { useDidMount, useMountWithTriggers } from "xa-generics";
import { ArrowLeft, Close, Save } from "@carbon/icons-react";
import { AppConfigsModel } from "sitebuilder-common";
import { useTranslation } from "react-i18next";
import { createPortal } from "react-dom";
import Button from "../Button/Button.view";
import { useImages } from "../../../Contexts/Images.context";
import { CustomSVG } from "../SVG/Custom.svg";

export interface IModalProps<Lang = string> {
    size?: "XS" | "SM" | "MD" | "LG";
    appLogin?: AppConfigsModel["login"];
    closeText?: Lang;
    isForm?: boolean;
    isDanger?: boolean;
    className?: string;
    submitText?: string;
    isPassive?: boolean;
    closeIcon?: FC<any>;
    isDarkened?: boolean;
    allowOverflow?: boolean;
    isSubmitDisabled?: boolean;
    allowEnterKeyDown?: boolean;
    heading?: string | JSX.Element;
    submitIcon?: FC<any> | JSX.Element;
    onClose: (e?: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>) => void;
    onSubmit?: (
        e?: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent> | React.FormEvent
    ) => void;
    onSecondary?: (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>) => void;
}

const Modal: React.FC<IModalProps> = (props) => {
    const size = props.size || "XS";
    const classes: string[] = ["modal", `modal-${size}`];
    const container: string[] = ["modal-container"];
    const { images } = useImages();
    const { t } = useTranslation();

    if (props.className) classes.push(props.className);
    if (props.isPassive) classes.push("modal-passive");
    if (props.isDanger) classes.push("modal-danger");
    if (props.allowOverflow) classes.push("modal-allow-overflow");
    if (props.isDarkened) container.push("darkened-container");

    const appStyle: React.CSSProperties = {};
    if (props.appLogin) {
        container.push("app-modal-container");
        const app = props.appLogin;
        if (images[app.backgroundImage]?.url) {
            appStyle.backgroundImage = `url(${images[app.backgroundImage].url})`;
        }
        appStyle.backgroundAttachment = app.backgroundAttachment;
        appStyle.background = "unset !important";
        appStyle.backgroundRepeat = app.backgroundRepeat ? "repeat" : "no-repeat";
        appStyle.backgroundPosition = app.backgroundPosition;
        appStyle.backgroundSize = app.backgroundSize;
        appStyle.backgroundColor = app.backgroundColor;
        appStyle.color = app.color;
    }

    const handleKeyBinds = (e: KeyboardEvent): void => {
        if (e.key === "Escape") {
            e.preventDefault();
            props.onClose();
        }
        if (props.allowEnterKeyDown && e.key === "Enter") {
            e.preventDefault();
            if (props.onSubmit) props.onSubmit();
            else props.onClose();
        }
    };

    const getContext = (children?: any): JSX.Element => {
        if (props.isForm) {
            return (
                <form
                    className={classes.join(" ")}
                    onClick={(e) => e.stopPropagation()}
                    autoComplete={"off"}
                    noValidate={true}
                    autoSave={"off"}
                    style={appStyle}
                    onSubmit={props.onSubmit}
                >
                    {children}
                </form>
            );
        }
        return (
            <div
                style={appStyle}
                className={classes.join(" ")}
                onClick={(e) => e.stopPropagation()}
            >
                {children}
            </div>
        );
    };

    useMountWithTriggers(() => {
        window.addEventListener("keydown", handleKeyBinds);
        return () => {
            window.removeEventListener("keydown", handleKeyBinds);
        };
    }, [props.allowEnterKeyDown]);

    useDidMount(() => {
        const element = document.getElementById("root")!;
        const pg = document.getElementById("PAGE_CONTAINER");
        element.style.overflow = "hidden";
        if (pg) pg.style.overflow = "hidden";
        document.body.style.overflow = "hidden";
        return () => {
            element.style.overflow = "unset";
            if (pg) pg.style.overflow = "unset";
            document.body.style.overflow = "unset";
        };
    });

    return (
        <>
            {createPortal(
                <div className={container.join(" ")} onClick={props.onClose}>
                    {getContext(
                        <>
                            <div
                                className="modal__heading"
                                style={{
                                    color: props.appLogin?.color,
                                    borderBottom: props.appLogin?.backgroundImage
                                        ? "none"
                                        : undefined
                                }}
                            >
                                <div className="modal__heading--content">{props.heading}</div>
                                <div className="modal__heading--close" onClick={props.onClose}>
                                    <Close size={24} />
                                </div>
                            </div>
                            <div className="modal__content">{props.children}</div>
                            {!props.isPassive && (
                                <div className="modal__controls">
                                    <Button
                                        kind={"SECONDARY"}
                                        Icon={
                                            props.appLogin?.buttonCancelSvg ? (
                                                <CustomSVG
                                                    svgString={props.appLogin?.buttonCancelSvg}
                                                />
                                            ) : (
                                                props.closeIcon || ArrowLeft
                                            )
                                        }
                                        onClick={props.onSecondary || props.onClose}
                                        className="modal__controls--button"
                                        style={{
                                            backgroundColor: props.appLogin?.secondaryButtonBgColor,
                                            color: props.appLogin?.secondaryButtonColor,
                                            border: props.appLogin?.secondaryButtonBgColor
                                                ? `2px solid ${props.appLogin.secondaryButtonBgColor}`
                                                : undefined
                                        }}
                                    >
                                        {t(props.closeText || "cancel")}
                                    </Button>
                                    <Button
                                        disabled={props.isSubmitDisabled}
                                        kind={props.isDanger ? "DANGER" : "PRIMARY"}
                                        onClick={props.onSubmit}
                                        Icon={
                                            props.appLogin?.buttonSubmitSvg ? (
                                                <CustomSVG
                                                    svgString={props.appLogin.buttonSubmitSvg}
                                                />
                                            ) : (
                                                props.submitIcon || Save
                                            )
                                        }
                                        className="modal__controls--button"
                                        style={{
                                            backgroundColor: props.appLogin?.primaryButtonBgColor,
                                            color: props.appLogin?.primaryButtonColor,
                                            border: props.appLogin?.primaryButtonBgColor
                                                ? `2px solid ${props.appLogin.primaryButtonBgColor}`
                                                : undefined
                                        }}
                                    >
                                        {props.submitText || t("save")}
                                    </Button>
                                </div>
                            )}
                        </>
                    )}
                </div>,
                document.getElementById(
                    props.appLogin ? "APP_MAIN_CONTAINER" : "root"
                ) as HTMLDivElement
            )}
        </>
    );
};

export default Modal;
