import { ModelConstructor, IDynamicObject } from "xa-generics";
import { ISection } from "../../DomTracker/Interfaces/IDomTypes.interface";

export class TemplateBlockModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as TemplateBlockMData;
        ModelConstructor(data, this);

        this.dom = typeof rawData.dom === "string" ? JSON.parse(rawData.dom) : data.dom;
    }

    template_id: string = "";
    dom!: ISection;
    origin_restaurant_id: string = "";
    preview_url: string = "";
    preview_file_name: string = "";
    preview_file_size: number = 0;
    preview_height: number = 0;
    preview_width: number = 0;
    preview_mime_type: string = "";
    created_at: string = "";
    updated_at: string = "";
}

//If you add public methods, you may want to replace the empty string with them!
type TemplateBlockMData = Partial<InstanceType<typeof TemplateBlockModel>>;
