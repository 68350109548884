import { ISizes } from "sitebuilder-common";
import { GetSizeValue } from "./GetSizeValue.util";

export const GetWidgetLeftAndRight = (
    size: ISizes,
    isOpen: boolean
): { left: number; right: number } => {
    let leftSpacing: number = isOpen ? 300 : 0;
    let right: number = 0;
    let left: number = 0;
    let borderWidth: number = size === "xl" ? 0 : 10;

    const blockWidth = GetSizeValue(size);
    const innerWidth = window.innerWidth;
    const leftoverBlockSize = innerWidth - leftSpacing - blockWidth;
    const halfOfBlock = Math.round(leftoverBlockSize / 2);

    left = innerWidth < blockWidth || size === "xl" ? leftSpacing : halfOfBlock + leftSpacing;
    if (size !== "xl") {
        left += borderWidth / 2;
        right += borderWidth / 2;
        right += halfOfBlock;
    } else {
        right = 0;
    }
    return { left, right };
};
