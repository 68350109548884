import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useTranslation } from "react-i18next";
import { RadioButton, RadioButtonChecked } from "@carbon/icons-react";

interface ICartRadioRowProps {
    onClick: React.MouseEventHandler<HTMLDivElement>;
    isSelected: boolean;
    noBorder?: boolean;
    labelText: ILang;
    className?: string;
}

export const CartRadioRow: FC<ICartRadioRowProps> = (props) => {
    const { t } = useTranslation<ILang>();

    const classes: string[] = ["cart__radio-row"];
    if (props.children) classes.push("with-children");
    if (props.noBorder) classes.push("no-border");
    if (props.className) classes.push(props.className);

    return (
        <div className={classes.join(" ")} onClick={props.onClick}>
            <div className="cart__radio-row--state">
                {props.isSelected ? <RadioButtonChecked /> : <RadioButton />}
            </div>
            <div className="cart__radio-row__text">{t<ILang>(props.labelText)}</div>
            {props.children ? <div className="cart__radio-row--child">{props.children}</div> : null}
        </div>
    );
};
