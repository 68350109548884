import { IPartialCSS } from "sitebuilder-common";

export const getScaledIconSize = (
    styleGroup: IPartialCSS<any> | undefined,
    defIconSize: number = 16
): number => {
    const fontSize = parseFloat(styleGroup?.fontSize || "invalid");
    let iconSize = defIconSize;
    if (fontSize && !isNaN(fontSize)) {
        if (styleGroup?.fontSize.endsWith("rem")) iconSize = fontSize * 10;
        else iconSize = fontSize;
    }
    return iconSize;
};
