import {
    IError,
    Regexes,
    ILoading,
    useTimeout,
    useDidMount,
    useAfterTriggerChanged
} from "xa-generics";
import { Context, createContext, useState, useContext, useMemo, useRef } from "react";
import { CartContextProvider } from "../../WidgetModules/Cart/Provider/Cart.provider";
import { IActiveAppEditor } from "../Interfaces/IActiveFrameEditor.type";
import { AppConfigsModel } from "sitebuilder-common";
import { IAppDataSource } from "../Interfaces/IIFrameDataSources.type";
import { useTranslation } from "react-i18next";
import { FloatingError } from "xa-error-with-lang";
import { IAppContext } from "../Interfaces/IAppContext.interface";
import { AppModel } from "../Models/App.model";
import { useForm } from "../../UseForm/UseForm.provider";
import { AppDAO } from "../DAO/App.dao";
import { clone } from "lodash";
import { ILang } from "../../../Interfaces/ILang.type";
import Loading from "../../UI/Loading/Loading.view";

/**
 * ## IAppContext
 */
const AppContext: Context<IAppContext<any>> = createContext<IAppContext<any>>(null as any);

AppContext.displayName = "IAppContext";

interface IAppContextProviderProps {}

/**
 * ## IApp context provider component
 *
 */
export const AppContextProvider: React.FC<IAppContextProviderProps> = (props) => {
    const { setTm } = useTimeout();
    const { t } = useTranslation<ILang>();
    const isFirstLoad = useRef<boolean>(true);

    const [dataSource, setDataSource] = useState<IAppDataSource>("draft_elements_config");
    const [activeEditor, setActiveEditor] = useState<IActiveAppEditor>("");
    const [data, setData] = useState<AppModel | null>(null);
    const [loading, setLoading] = useState<ILoading>(false);
    const [error, setError] = useState<IError>(null);

    const emptyModel = useMemo(() => new AppConfigsModel(), []);

    const buttonsForm = useForm<AppConfigsModel["buttons"], true>({
        editor: emptyModel.buttons
    });
    const globalForm = useForm<AppConfigsModel["global"], true>({
        editor: emptyModel.global,
        initialRules: {
            extraPageLink: {
                pattern: {
                    value: Regexes.Url,
                    message: "invalid_url"
                }
            }
        }
    });
    const headerForm = useForm<AppConfigsModel["header"], true>({
        editor: emptyModel.header
    });
    const productForm = useForm<AppConfigsModel["product"], true>({
        editor: emptyModel.product
    });
    const loginForm = useForm<AppConfigsModel["login"], true>({
        editor: emptyModel.login
    });
    const sidemenuForm = useForm<AppConfigsModel["sidemenu"], true>({
        editor: emptyModel.sidemenu
    });
    const mapForm = useForm<AppConfigsModel["map"], true>({
        editor: emptyModel.map,
        initialRules: {
            lat: {
                pattern: { value: Regexes.NumFloatInt, message: t<ILang>("invalid_lat") }
            },
            lng: {
                pattern: { value: Regexes.NumFloatInt, message: t<ILang>("invalid_lng") }
            }
        }
    });

    const initAndLoad = (): void => {
        AppDAO.initAndLoad()
            .then((model) => {
                setData(model);
                if (isFirstLoad.current) {
                    isFirstLoad.current = false;
                    sidemenuForm.setEditor(model.draft_elements_config.sidemenu);
                    buttonsForm.setEditor(model.draft_elements_config.buttons);
                    productForm.setEditor(model.draft_elements_config.product);
                    globalForm.setEditor(model.draft_elements_config.global);
                    headerForm.setEditor(model.draft_elements_config.header);
                    loginForm.setEditor(model.draft_elements_config.login);
                    mapForm.setEditor(model.draft_elements_config.map);
                }
            })
            .catch(setError)
            .finally(() => setLoading(false));
    };

    const updateData = (states: Record<keyof AppConfigsModel, any>): void => {
        if (!data) return;
        const current = clone(data);
        current.draft_elements_config = new AppConfigsModel(states);
        setData(current);
        AppDAO.update(current.Deconvert)
            .catch(setError)
            .finally(() => setLoading(false));
    };

    const publishData = (): void => {
        if (!data) return;
        const newState = data.Deconvert;
        newState.elements_config = newState.draft_elements_config;

        setLoading(
            AppDAO.update(newState)
                .then(initAndLoad)
                .catch((error) => {
                    setError(error);
                    setLoading(false);
                })
        );
    };

    const cloneAppFrom = (cloneFromAppId: string): void => {
        setLoading(
            AppDAO.cloneFrom(cloneFromAppId)
                .then(() => {
                    isFirstLoad.current = false;
                    initAndLoad();
                })
                .catch((error) => {
                    setError(error);
                    setLoading(false);
                })
        );
    };

    const onSetActiveEditor = (group: IActiveAppEditor): void => {
        setActiveEditor(group);
    };

    useDidMount(initAndLoad);

    useAfterTriggerChanged(() => {
        setTm(
            () => {
                updateData({
                    sidemenu: sidemenuForm.editor,
                    buttons: buttonsForm.editor,
                    product: productForm.editor,
                    global: globalForm.editor,
                    header: headerForm.editor,
                    login: loginForm.editor,
                    map: mapForm.editor
                });
            },
            1000,
            "autosave-draft-app"
        );
    }, [
        sidemenuForm.editor,
        buttonsForm.editor,
        productForm.editor,
        globalForm.editor,
        headerForm.editor,
        loginForm.editor,
        mapForm.editor
    ]);

    return (
        <AppContext.Provider
            value={{
                loading: loading ? true : false,
                data: data as AppModel,
                onSetActiveEditor,
                setDataSource,
                cloneAppFrom,
                activeEditor,
                publishData,
                dataSource,
                forms: {
                    sidemenu: sidemenuForm,
                    product: productForm,
                    buttons: buttonsForm,
                    global: globalForm,
                    header: headerForm,
                    login: loginForm,
                    map: mapForm
                }
            }}
        >
            <CartContextProvider>
                <Loading isAbsolute loading={loading} />
                <FloatingError error={error} resetError={() => setError(null)} />
                {/* INFO this will prevent children from rendering before the state is set.
                    That's why it's also safe to assert the interfaces as non-null values. */}
                {!data ? <Loading isAbsolute isExternalConditional /> : props.children}
            </CartContextProvider>
        </AppContext.Provider>
    );
};

export const useApp = <FormType extends keyof AppConfigsModel>(): IAppContext<FormType> => {
    return useContext(AppContext);
};
export const AppConsumer = AppContext.Consumer;
