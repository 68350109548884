import { ILinkStyles } from "../Interfaces/ILinkComponent.interface";
import {
    BackgroundStyles,
    BorderRadiusStyles,
    FontStyles,
    PaddingStyles,
    BorderStyles,
    OpacityStyles,
    HeightStyles,
    WidthStyles,
    MarginStyles,
    AlignStyles,
    ColorStyles,
    BoxShadowSyles,
    TextShadowSyles,
    BackgroundImageStyles
} from "./GroupsStyles.static";

export const LinkStyles: Required<ILinkStyles<true>> = {
    ...BackgroundStyles,
    ...PaddingStyles,
    ...AlignStyles,
    ...FontStyles,
    ...ColorStyles,
    ...BorderRadiusStyles,
    ...BorderStyles,
    ...OpacityStyles,
    ...BoxShadowSyles,
    ...TextShadowSyles,
    ...HeightStyles,
    ...WidthStyles,
    ...MarginStyles,
    ...BackgroundImageStyles
};
