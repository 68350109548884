import { FC } from "react";
import { useSwipeable } from "react-swipeable";
import { useProducts } from "../../../DomMapper/DomComponents/Products/Context/ProductsContext.provider";
import { AppCategoryElement } from "./AppCategory.element";
import { IAppOrderIndexProps } from "./AppOrder.index";

interface IAppCategoriesViewProps extends IAppOrderIndexProps {}

export const AppCategoriesView: FC<IAppCategoriesViewProps> = (props) => {
    const { categories } = useProducts();
    const handlers = useSwipeable({
        onSwipedRight: (e) => {
            props.appProudctNavigate(null);
            if (props.setIsMenuOpen) {
                props.setIsMenuOpen(true);
            }
        },
        delta: 100
    });

    if (props.selector.category && !props.selector.category.has_child) return null;
    if (props.selector.product) return null;

    return (
        <div className={"app-categories-container"} {...handlers}>
            {props.selector.category && props.selector.category.has_child && (
                <div className="app-products-container__category">
                    {props.selector.category.name}
                </div>
            )}
            {categories.map((category) => (
                <AppCategoryElement
                    {...props}
                    category={category}
                    key={`${category.id}-category-element`}
                />
            ))}
        </div>
    );
};
