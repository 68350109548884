import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useCart } from "../Provider/Cart.provider";
import { TextInput } from "../../../UI/InputFields/TextInput/TextInput.view";
import { ICartUserForm } from "../Interfaces/ICartUserForm.interface";
import { useTranslation } from "react-i18next";

interface ICartMessageViewProps {}

export const CartMessageView: FC<ICartMessageViewProps> = (props) => {
    const { form } = useCart();
    const { t } = useTranslation<ILang>();
    return (
        <div className={"cart-block cart__message"}>
            <h4 className="cart__title">{t<ILang>("message")}</h4>
            <TextInput<ICartUserForm>
                noLabel
                id={"message"}
                value={form.editor.message}
                className={"message"}
                onChange={form.handleChange}
            />
        </div>
    );
};
