import { Width } from "../../UI/InputGroups/Width/Width.view";
import { Height } from "../../UI/InputGroups/Height/Height.view";
import { Display } from "../../UI/InputGroups/Display/Display.view";
import { IColumn } from "../../DomTracker/Interfaces/IDomTypes.interface";
import { Paddings } from "../../UI/InputGroups/Paddings/Paddings.view";
import { EditorHook } from "../Utils/EditorHook.util";
import { IColumnEditor } from "../../DomMapper/Interfaces/IColumnComponent.interface";
import { InputGroupContextProvider } from "../../UI/InputGrouper/InputGroup.provider";
import BackgroundImageGroup from "../../UI/InputGroups/BackgroundImage/BackgroundImageGroup.view";
import BackgroundGroupView from "../../UI/InputGroups/Background/BackgroundGroup.view";
import FormSeparatorView from "../../UI/FormSeparator/FormSeparator.view";

export interface IColumnEditorViewProps {
    editor: IColumn;
}

const ColumnEditorView: React.FC<IColumnEditorViewProps> = (props) => {
    const { onBlur, onClear, defaults, units } = EditorHook<IColumnEditor>();

    return (
        <InputGroupContextProvider>
            <FormSeparatorView
                defKey={"Column"}
                defaultGroup={
                    <>
                        <Paddings
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />

                        <BackgroundGroupView
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />

                        <BackgroundImageGroup
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                    </>
                }
                advancedGroup={
                    <>
                        <Display
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />

                        <Width
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Height
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                    </>
                }
            />
        </InputGroupContextProvider>
    );
};

export default ColumnEditorView;
