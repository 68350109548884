import HTMLReactParser from "html-react-parser";
import { FC } from "react";

interface IProps {
    svgString: string;
}

export const CustomSVG: FC<IProps> = ({ svgString }) => {
    return <>{HTMLReactParser(svgString)}</>;
};
