import { Margins } from "../../UI/InputGroups/Margins/Margins.view";
import { useForm } from "../../UseForm/UseForm.provider";
import { Paddings } from "../../UI/InputGroups/Paddings/Paddings.view";
import { TextInput } from "../../UI/InputFields/TextInput/TextInput.view";
import { SelectInput } from "../../UI/InputFields/Select/SelectInput.view";
import { IDomElement } from "../../DomTracker/Interfaces/IDomTypes.interface";
import { CheckboxInput } from "../../UI/InputFields/Checkbox/Checkbox.view";
import { EditorHook, IFieldBlur } from "../Utils/EditorHook.util";
import { IMapCustoms, IMapEditors } from "../../DomMapper/Interfaces/IMapComponent.interface";
import { InputGroupContextProvider } from "../../UI/InputGrouper/InputGroup.provider";
import BackgroundImageGroup from "../../UI/InputGroups/BackgroundImage/BackgroundImageGroup.view";
import BackgroundGroupView from "../../UI/InputGroups/Background/BackgroundGroup.view";
import FormSeparatorView from "../../UI/FormSeparator/FormSeparator.view";
import FontColor from "../../UI/InputGroups/FontInputs/FontColor.input";
import Font from "../../UI/InputGroups/FontInputs/Font.view";

export interface IMapEditorView {
    editor: IDomElement;
}

const MapEditorView: React.FC<IMapEditorView> = (props) => {
    const { onBlur, onClear, units, defaults } = EditorHook<IMapEditors>();

    const form = useForm<IMapCustoms, true>({
        editor: {
            zoom: defaults.zoom || "16",
            lat: defaults.lat || "",
            lng: defaults.lng || "",
            isFullWidth: defaults.isFullWidth || false
        },
        initialRules: {
            lat: {
                pattern: {
                    value: /^(\d)+(\.\d+)+$/,
                    message: "invalid_lat"
                }
            },
            lng: {
                pattern: {
                    value: /^(\d)+(\.\d+)+$/,
                    message: "invalid_lng"
                }
            }
        }
    });

    const onTextBlur = (data: IFieldBlur<IMapCustoms>): void => {
        form.handleSubmit((state) => {
            onBlur(data);
        })();
    };

    return (
        <InputGroupContextProvider defaultActive={"BackgroundGroup"}>
            <FormSeparatorView
                defKey={"Gallery"}
                defaultGroup={
                    <>
                        <TextInput
                            required
                            id={"lat"}
                            value={form.editor.lat}
                            errors={form.fieldErrors}
                            onChange={form.handleChange}
                            onBlur={onTextBlur}
                        />

                        <TextInput
                            required
                            id={"lng"}
                            value={form.editor.lng}
                            errors={form.fieldErrors}
                            onChange={form.handleChange}
                            onBlur={onTextBlur}
                        />
                        <SelectInput
                            id={"zoom"}
                            nameAccessor={"id"}
                            options={[
                                { id: "10" },
                                { id: "11" },
                                { id: "12" },
                                { id: "13" },
                                { id: "14" },
                                { id: "15" },
                                { id: "16" },
                                { id: "17" },
                                { id: "18" },
                                { id: "19" },
                                { id: "20" }
                            ]}
                            onChange={(field, value) => {
                                form.handleChange(field, value);
                                onBlur({ field, value });
                            }}
                            value={form.editor.zoom}
                        />

                        <CheckboxInput
                            onBlur={onBlur}
                            id={"isFullWidth"}
                            onChange={form.handleChange}
                            value={form.editor.isFullWidth}
                        />
                    </>
                }
                advancedGroup={
                    <>
                        <BackgroundImageGroup
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <BackgroundGroupView
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Paddings
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Margins
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <FontColor
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Font
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                    </>
                }
            />
        </InputGroupContextProvider>
    );
};

export default MapEditorView;
