import { ModelConstructor, IDynamicObject } from "xa-generics";

export class TemplateRestaurantModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as TemplateRestaurantMData;
        ModelConstructor(data, this);
    }

    restaurant_id: string = "";
    template_category_id: string = "";
    preview_url: string = "";
    description: string = "";
    description_secondary: string = "";
    created_at: string = "";
    updated_at: string = "";
    image_url: string = "";
    image_file_name: string = "";
    image_file_size: number = 0;
    image_mime_type: string = "";
    image_width: number = 0;
    image_height: number = 0;
}

//If you add public methods, you may want to replace the empty string with them!
type TemplateRestaurantMData = Partial<InstanceType<typeof TemplateRestaurantModel>>;
