import { clone } from "lodash";
import { IGradientForm } from "../Components/UI/InputFields/GradientPicker/IGradientForm.interface";

export const DEFAULT_GRADIENT: IGradientForm = {
    color1: "#000000ff",
    color2: "#ffffffff",
    type: "LINEAR",
    deg: "90",
    range: "50",
    size: "farthest-corner",
    shape: "ellipse",
    position: "at center"
};
export const ParseGradient = (background?: string): IGradientForm => {
    if (!background) return DEFAULT_GRADIENT;
    //Example values:
    //linear-gradient(182deg,#aa77bb99,25%,ccab76ff)
    //radial-gradient(#aa77bb99,25%,ccab76ff)
    const value = background;
    const result = clone(DEFAULT_GRADIENT);
    const cindex1 = value.indexOf("#"); // index of first color
    const cindex2 = value.lastIndexOf("#"); // index of second color
    const lengthOfHexaColor = 9; //This includes the # and the extra two transparency values, making it 9 instead of 6!
    const color1 = value.slice(cindex1, cindex1 + lengthOfHexaColor);
    const color2 = value.slice(cindex2, cindex2 + lengthOfHexaColor);
    //Step over first color code (9) and the comma (extra 1) and take the
    //part between the second color (except for the comma and % characters, hence ( - 2))
    const lengthOfComma: number = 1;
    const lengthOfPercentAndComma = 2;
    result.range = value.slice(
        cindex1 + lengthOfHexaColor + lengthOfComma,
        cindex2 - lengthOfPercentAndComma
    );
    if (value.includes("linear")) {
        result.type = "LINEAR";
        result.color1 = color1;
        result.color2 = color2;
        result.deg = value.slice(value.indexOf("(") + 1, value.indexOf("deg"));
    } else {
        result.type = "RADIAL";
        result.color1 = color1;
        result.color2 = color2;
        const params = value.slice(value.indexOf("(") + 1, cindex1 - 1).split(" ");
        result.shape = params[0] || "ellipse";
        result.size = params[1] || "farthest-corner";
        result.position = params[2] && params[3] ? `${params[2]} ${params[3]}` : "at center";
    }
    return result;
};
