import { FC } from "react";
import { useApp } from "../../Provider/App.context";
import { TextInput } from "../../../UI/InputFields/TextInput/TextInput.view";
import { AppEditorGroup } from "../AppEditorGroup.view";
import { CheckboxInput } from "../../../UI/InputFields/Checkbox/Checkbox.view";

interface IAppMapFormProps {}

export const AppMapForm: FC<IAppMapFormProps> = (props) => {
    const { forms } = useApp<"map">();
    const { editor, fieldErrors, handleChange } = forms.map;

    return (
        <AppEditorGroup groupKey={"map"} nameKey={"iframe_map"}>
            <TextInput
                id={"lat"}
                onChange={handleChange}
                value={editor.lat}
                errors={fieldErrors}
                labelText={"lat"}
            />
            <TextInput
                id={"lng"}
                onChange={handleChange}
                value={editor.lng}
                errors={fieldErrors}
                labelText={"lng"}
            />
            <TextInput
                id={"zoom"}
                onChange={handleChange}
                value={editor.zoom}
                errors={fieldErrors}
                labelText={"zoom"}
                isNumeric
            />
            <CheckboxInput
                id={"showOpenHours"}
                onChange={handleChange}
                value={editor.showOpenHours}
                errors={fieldErrors}
            />
        </AppEditorGroup>
    );
};
