import { ILang } from "../../../../Interfaces/ILang.type";
import { useApp } from "../../Provider/App.context";
import { IAppStep } from "../../Interfaces/IAppStep.type";
import { ArrowRight, Checkmark } from "@carbon/icons-react";
import { AppButton } from "../AppButton.view";
import { FC, useRef } from "react";
import { useNavigate } from "react-router";
import { useSwipeable } from "react-swipeable";
import { AppCartItems } from "./AppCartItems.view";
import { useTranslation } from "react-i18next";
import { CartMessageView } from "../../../WidgetModules/Cart/View/CartMessage.view";
import { CartUserDataView } from "../../../WidgetModules/Cart/View/CartUserData.view";
import { CartPaymentSplit } from "../../../WidgetModules/Cart/View/CartPaymentSplit.view";
import { AppCartPricesView } from "./AppCartPrices.view";
import { AppPaymentMethods } from "./AppPaymentMethods.view";
import { AppDeliveryOptions } from "./AppDeliveryOptions.view";
import { AppCouponAndPrices } from "./AppCoupon.view";
import { AppCartProgressbar } from "./AppCartProgressbar.view";
import { useMountWithTriggers } from "xa-generics";

interface IAppViewProps {
    step: IAppStep;
}

export const AppCartView: FC<IAppViewProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { data, dataSource } = useApp();
    const app = data[dataSource];

    const navigateTo = useNavigate();
    const contentRef = useRef<HTMLDivElement | null>(null);
    const handlers = useSwipeable({
        onSwipedLeft: (e) => {
            if (props.step === "content") navigateTo("/cart/settings");
            if (props.step === "settings") navigateTo("/cart/finalize");
        },
        onSwipedRight: (e) => {
            if (props.step === "content") navigateTo("/order");
            if (props.step === "finalize") navigateTo("/cart/settings");
            if (props.step === "settings") navigateTo("/cart");
        },
        delta: 100
    });

    const classes: string[] = ["app-cart"];
    if (app.global.isRounded) classes.push("rounded-cart");

    if (props.step === "content") classes.push("--step1");
    if (props.step === "settings") classes.push("--step2");
    if (props.step === "finalize") classes.push("--step3");

    useMountWithTriggers(() => {
        if (!contentRef.current) return;
        const width = contentRef.current.clientWidth;
        let nextLeft = 0;
        if (props.step === "settings") nextLeft += width;
        if (props.step === "finalize") nextLeft += 2 * width;
        contentRef.current.scroll({ behavior: "smooth", left: nextLeft, top: 0 });
    }, [props.step]);

    return (
        <div className={classes.join(" ")} {...handlers}>
            <AppCartProgressbar {...props} />
            <div className="app-cart__content" ref={(ref) => (contentRef.current = ref)}>
                <div className="app-cart__content--step1">
                    <AppCartItems />
                </div>
                <div className="app-cart__content--step2">
                    <AppDeliveryOptions />
                </div>
                <div className="app-cart__content--step3">
                    <CartUserDataView isApp={true} />
                    <AppPaymentMethods />
                    <CartPaymentSplit />
                    <CartMessageView />
                    <AppCartPricesView />
                </div>
                {props.step === "content" && <AppCouponAndPrices />}
            </div>
            {props.step === "content" && (
                <div className="app-cart__next">
                    <AppButton
                        isThick
                        Icon={<ArrowRight />}
                        onClick={() => navigateTo("/cart/settings")}
                    >
                        {t<ILang>("next_step")}
                    </AppButton>
                </div>
            )}
            {props.step === "settings" && (
                <div className="app-cart__next --with-shadow">
                    <AppButton
                        isThick
                        Icon={<ArrowRight />}
                        onClick={() => navigateTo("/cart/finalize")}
                    >
                        {t<ILang>("next_step")}
                    </AppButton>
                </div>
            )}
            {props.step === "finalize" && (
                <div className="cart-finalize">
                    <AppButton isThick Icon={<Checkmark size={20} />}>
                        {t<ILang>("finalize_order")}
                    </AppButton>
                </div>
            )}
        </div>
    );
};
