import {
    FontStyles,
    ColorStyles,
    TextShadowSyles
} from "../../../DomMapper/Static/GroupsStyles.static";
import { IFooterNameStyles } from "../Interfaces/IFooterName.interface";

interface IFooter extends IFooterNameStyles<Required<true>> {}

export const FooterNameStyles: IFooter = {
    ...TextShadowSyles,
    ...ColorStyles,
    ...FontStyles
};
