import { FC } from "react";
import { IWC } from "xa-generics";
import { useMenuContext } from "./ContextMenu.view";

export interface IMenuItemProps extends IWC {
    Icon?: FC<any>;
    onClick: () => void;
}

export const MenuItem: FC<IMenuItemProps> = ({ Icon, onClick, ...props }) => {
    const { setIsOpened } = useMenuContext();

    return (
        <div
            className="menu-item"
            onClick={(e) => {
                onClick();
                setIsOpened(false);
            }}
        >
            {Icon ? (
                <div className="menu-item__icon">
                    <Icon />
                </div>
            ) : null}{" "}
            <div className="menu-item__content">{props.children}</div>
        </div>
    );
};
