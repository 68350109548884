import { ProductsContextProvider } from "../../DomMapper/DomComponents/Products/Context/ProductsContext.provider";
import { IFrameContainerView } from "./IFrameContainer.view";
import { IFrameSidemenuView } from "./IFrameSidemenu.view";
import { useIFrame } from "../Provider/IFrame.context";
import { FC } from "react";

interface IIFrameLayoutProps {}

export const IFrameLayout: FC<IIFrameLayoutProps> = (props) => {
    const { data, dataSource } = useIFrame();

    const classes = ["iframe-container"];
    if (data[dataSource].global.isRounded) classes.push("rounded-corners");
    if (data[dataSource].global.isDarkTheme) classes.push("dark-theme-iframe");

    return (
        <ProductsContextProvider>
            <div className={classes.join(" ")}>
                <IFrameSidemenuView />
                <div className="iframe-editor-view">
                    <IFrameContainerView>{props.children}</IFrameContainerView>
                </div>
            </div>
        </ProductsContextProvider>
    );
};
