import { axios, IDynamicObject } from "xa-generics";
import { AppModel } from "../Models/App.model";
import { RootDAO } from "../../../DAO/Restaurant.dao";

export abstract class AppDAO extends RootDAO {
    private static readonly path: string = "app";

    public static async initAndLoad(): Promise<AppModel> {
        const request = await axios
            .getInstance()
            .get<IDynamicObject<any>>(`${this.baseUrl()}/${this.path}`);
        return new AppModel(request.data);
    }

    public static async update(data: IDynamicObject<any>): Promise<void> {
        await axios.getInstance().put<void>(`${this.baseUrl()}/${this.path}`, data);
        return;
    }

    public static async cloneFrom(cloneFromAppId: string): Promise<void> {
        await axios
            .getInstance()
            .put<void>(`${this.baseUrl()}/${this.path}/clone-from/${cloneFromAppId}`);
        return;
    }
}
