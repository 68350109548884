import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { NavLink } from "react-router-dom";
import { useWidgets } from "../../../Widgets/Controller/WidgetsContext.provider";
import { IPageEditor } from "../../../DomMapper/Interfaces/IPageEditor.interface";
import { useTranslation } from "react-i18next";
import { INavbarVersions } from "../Interfaces/INavbar.type";
import { NavbarLinkStyles } from "../Static/NavbarLinkStyles.static";
import { GetWidgetOverlay } from "../../Util/WidgetOverlay.util";
import { ParseComponentStyle } from "sitebuilder-common";
import { INavbarLink, INavbarSettings } from "../Interfaces/INavbarWidget.interface";
import WidgetOverlay from "../../../Widgets/View/WidgetOverlay.view";

interface INavbarLinkElementProps {
    link: INavbarLink;
    navVersion?: INavbarVersions;
}

export const NavbarLinkElement: FC<INavbarLinkElementProps> = ({ link, navVersion }) => {
    const { t, i18n } = useTranslation<ILang>();
    const lang = i18n.language;

    const { widgets } = useWidgets();
    const { size, pages } = useDom();
    const { navbarLink } = widgets.Navbar.draftSettings;

    const { showOverlay, ...events } = GetWidgetOverlay<INavbarSettings>("Navbar", "navbarLink");

    const linkStyles = ParseComponentStyle(navbarLink, NavbarLinkStyles, size);

    const defaultClass =
        !navVersion || navVersion === "default"
            ? "navbar__bottom__links--element"
            : `${navVersion}navbar__links--element`;
    const data = link[lang] as IPageEditor;
    const routeName = data?.routeName || t<ILang>("none");

    if (link.isExternal) {
        const url = data?.url || "#";
        return (
            <a
                href={url}
                style={linkStyles}
                className={defaultClass}
                {...(link.openOnNewPage ? { target: "_blank", rel: "noreferrer noopener" } : {})}
                {...events}
            >
                {showOverlay && (
                    <WidgetOverlay<INavbarSettings>
                        align={"right"}
                        parent={widgets.Navbar}
                        subKey={"navbarLink"}
                    />
                )}
                {routeName}
            </a>
        );
    }

    const getLinkData = (urlAsPageId: string): IPageEditor => {
        const defaultData = { routeName, url: "#" };
        if (!urlAsPageId) return defaultData;

        const page = pages.find((page) => page.page_id === urlAsPageId);
        if (!page) return defaultData;
        const seo = page.getSeo(i18n.language);
        if (!seo || !seo.url) return defaultData;

        return {
            routeName: data?.routeName || seo.title || t<ILang>("none"),
            url: seo.url || "#"
        };
    };

    const linkData = getLinkData(data.url);

    return (
        <NavLink
            to={linkData.url}
            style={linkStyles}
            className={defaultClass}
            {...events}
            onClick={(e) => e.preventDefault()}
        >
            {showOverlay && (
                <WidgetOverlay<INavbarSettings>
                    align={"right"}
                    parent={widgets.Navbar}
                    subKey={"navbarLink"}
                />
            )}
            {linkData.routeName}
        </NavLink>
    );
};
