import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { useWidgets } from "../../../Widgets/Controller/WidgetsContext.provider";
import { CalcContrast } from "sitebuilder-common";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import { IFooterSettings } from "../Interfaces/IFooterWidget.interface";
import { GetWidgetOverlay } from "../../Util/WidgetOverlay.util";
import { FooterBlockStyles } from "../Static/FooterBlockStyles.static";
import { ParseComponentStyle } from "sitebuilder-common";
import WidgetOverlay from "../../../Widgets/View/WidgetOverlay.view";
import SzechenyiCard from "../../../../Assets/Szechenyi.png";
import OTPNiceCard from "../../../../Assets/OTP_szepmatrica.png";
import CardsLogos from "../../../../Assets/bankcards_logos.png";
import Simplepay from "../../../../Assets/simplepay.png";
import BigFish from "../../../../Assets/big_fish.png";
import LogoRed from "../../../../Assets/logo_red.png";
import Logo from "../../../../Assets/logo.png";

interface IFooterBottomBarViewProps {}

export const FooterBottomBarView: FC<IFooterBottomBarViewProps> = (props) => {
    const { size } = useDom();
    const { t } = useTranslation<ILang>();
    const { widgets } = useWidgets();
    const { config } = useRestaurant();

    const { footerBottom } = widgets.Footer.draftSettings;
    const { showOverlay, ...events } = GetWidgetOverlay<IFooterSettings>("Footer", "footerBottom");

    const style = ParseComponentStyle(footerBottom, FooterBlockStyles, size);

    const fallbackColor = config.is_dark_theme ? "#333333" : "#ffffff";
    const imgSrc =
        CalcContrast("#ffffff", style?.backgroundColor || fallbackColor) < 50 ? LogoRed : Logo;

    return (
        <div style={style} {...events} className={"footer__bottom"}>
            {showOverlay && (
                <WidgetOverlay<IFooterSettings>
                    isBlock
                    align={"center"}
                    subKey={"footerBottom"}
                    parent={widgets.Footer}
                />
            )}
            <div className="footer__bottom--payment-methods">
                <img
                    src={SzechenyiCard}
                    className={"payment-method-image szechenyicard"}
                    alt={"SzechenyiCard"}
                />
                <img
                    src={OTPNiceCard}
                    className={"payment-method-image otpnicecard"}
                    alt={"OTPNiceCard"}
                />
                <img
                    src={CardsLogos}
                    className={"payment-method-image cardslogos"}
                    alt={"CardsLogos"}
                />
                <img
                    src={Simplepay}
                    className={"payment-method-image simplepay"}
                    alt={"Simplepay"}
                />
                <img src={BigFish} className={"payment-method-image bigfish"} alt={"BigFish"} />
            </div>
            <div className="footer__bottom--logo">
                {t<ILang>("powered_by")}{" "}
                <img src={imgSrc} alt="Onemin" className={"footer__bottom--logo--onemin"} />
            </div>
            <div className="footer__bottom--policy-files">
                <a
                    className={"global__link policy-link"}
                    href={"/unknown"}
                    rel={"noreferrer noopener"}
                    target={"_blank"}
                    onClick={(e) => e.preventDefault()}
                >
                    {t<ILang>("footer_privacy_policy")}
                </a>
                <a
                    className={"global__link policy-link"}
                    href={"/unknown"}
                    rel={"noreferrer noopener"}
                    target={"_blank"}
                    onClick={(e) => e.preventDefault()}
                >
                    {t<ILang>("footer_terms_and_conditions")}
                </a>
            </div>
        </div>
    );
};
