import { IBorder } from "./IBorder.interface";
import { IFormWithUnit } from "../../../../Interfaces/IFormWithUnit.type";

export const BorderDefaults: IFormWithUnit<IBorder> = {
    borderBottomWidth: "0",
    borderColor: "",
    borderLeftWidth: "0",
    borderRightWidth: "0",
    borderStyle: "solid",
    borderTopWidth: "0",
    unit: "px"
};
