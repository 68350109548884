import InputGrouper from "../../InputGrouper/InputGrouper.view";
import { Column } from "@carbon/icons-react";

export interface IColumnPickerProps {
    value: number | string;
    setValue: (colCount: number) => void;
}

const ColumnPicker: React.FC<IColumnPickerProps> = (props) => {
    const getCol = (value: number | string): JSX.Element => {
        const classes: string[] = ["column-picker__value"];
        if (Number(value) === Number(props.value)) classes.push("column-picker__active");
        return (
            <div onClick={() => props.setValue(Number(value))} className={classes.join(" ")}>
                {value}
            </div>
        );
    };
    return (
        <InputGrouper groupKey={"Column"} Icon={Column} labelKey={"columnCount"} defaultOpen>
            <div className="column-picker">
                <div className="column-picker__values">
                    {getCol(1)}
                    {getCol(2)}
                    {getCol(3)}
                    {getCol(4)}
                    {getCol(5)}
                </div>
            </div>
        </InputGrouper>
    );
};

export default ColumnPicker;
