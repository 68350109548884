import { IAllDomTypes } from "../Interfaces/IDomTypes.interface";
import { PageDataModel } from "../Model/PageData.model";

/**
 * ## ReSortNodes
 *
 * This util function takes a **DEEP CLONED** 'pages' state as its
 * argument and re-sorts it recursively by its index value.
 *
 * Note that this should be used only when a component is:
 * -    unmounted from the page nodes
 * -    mounted into the page nodes
 * -    its index is altered
 *
 * The provider will always return with the sorted pages and its nodes,
 * so you can directly map them into the dom.
 */
export const ReSortNodes = (clonedList: PageDataModel[]): PageDataModel[] => {
    const resort = (list: IAllDomTypes[]): void => {
        list.sort((a, b) => a.index - b.index).map((item) => {
            if ("elements" in item) {
                resort(item.elements);
            }
            return item;
        });
    };
    for (let page of clonedList) {
        resort(page.elements.dom);
        if (page.draft) {
            resort(page.draft.draft_elements.dom);
        }
    }
    return clonedList;
};
