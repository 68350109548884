import { AppCartPricesView } from "./AppCartPrices.view";
import { Checkmark, Close } from "@carbon/icons-react";
import { useTranslation } from "react-i18next";
import { ICartUserForm } from "../../../WidgetModules/Cart/Interfaces/ICartUserForm.interface";
import { TextInput } from "../../../UI/InputFields/TextInput/TextInput.view";
import { AppButton } from "../AppButton.view";
import { useCart } from "../../../WidgetModules/Cart/Provider/Cart.provider";
import { ILang } from "../../../../Interfaces/ILang.type";
import { FC } from "react";

interface IAppCouponAndPricesProps {}

export const AppCouponAndPrices: FC<IAppCouponAndPricesProps> = (props) => {
    const { form } = useCart();
    const { t } = useTranslation<ILang>();

    const isApplied = form.editor.apply_coupon;

    return (
        <div className="cart-block app-coupon-and-prices">
            <div className="app-coupon-and-prices__coupon">
                <TextInput<ICartUserForm>
                    noLabel
                    id={"coupon_temp"}
                    className={"coupon_temp"}
                    onChange={form.handleChange}
                    placeholder={t<ILang>("coupon")}
                    value={form.editor.coupon_temp}
                    disabled={form.editor.apply_coupon}
                />

                <AppButton
                    isSecondary={isApplied}
                    Icon={isApplied ? <Close /> : <Checkmark />}
                    disabled={isApplied ? false : !form.editor.coupon_temp}
                    onClick={() => form.handleChange("apply_coupon", !isApplied)}
                >
                    {t<ILang>(isApplied ? "delete" : "apply")}
                </AppButton>
            </div>
            <AppCartPricesView />
        </div>
    );
};
