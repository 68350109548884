import { INavbarLogoV3Styles } from "../Interfaces/INavbarLogoV3.interface";
import {
    PaddingStyles,
    MarginStyles,
    HeightStyles
} from "../../../DomMapper/Static/GroupsStyles.static";

interface INavbarLogoV3 extends INavbarLogoV3Styles<Required<true>> {}

export const NavbarLogoV3Styles: INavbarLogoV3 = {
    ...PaddingStyles,
    ...MarginStyles,
    ...HeightStyles
};
