export const PLACEHOLDERS = {
    hotjar: `<script type='text/javascript'>
window.smartlook||(function(d) {
var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
})(document);
smartlook('init', 'jvlkjv98vj982vj4928j34vo2i3j4v8v4ju93v4h', { region: 'eu' });
</script>`,
    gtag_body: `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-J7VH7KV" height="0" width="0" style="display: none; visibility: hidden"></iframe></noscript>`,
    ga_head: `<script async src="https://www.googletagmanager.com/gtag/js?id=G-XXXXXXXXXX"></script>
<script>
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'G-XXXXXXXXXX');
gtag('config', 'G-YYYYYYYYYY');
</script>`,
    fb_head: `<!-- Meta Pixel Code -->
<script>
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1221634185224137');
fbq('init', '1111111111111111');
fbq('track', 'PageView');
</script>
<noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=1111111111111111&ev=PageView&noscript=1"
/></noscript>
<!-- End Meta Pixel Code -->`,
    fb_pixel: "873339587924831",
    fb_token:
        "lkjboiwjoberiuj297bh4oi2b3h4iu23h49283ihjiu2o3h43bjklsjelbifjslkbjn2938b42oi3bj4234bö82j3"
};
