import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { useWidgets } from "../../../Widgets/Controller/WidgetsContext.provider";
import { Untransform } from "../../../../Utils/UntransformComponentContent.util";
import { CheckboxInput } from "../../../UI/InputFields/Checkbox/Checkbox.view";
import { IWidgetGroups } from "../../../Widgets/Interfaces/IWidgetGroups.type";
import { useTranslation } from "react-i18next";
import { IFooterSettings } from "../../../WidgetModules/Footer/Interfaces/IFooterWidget.interface";
import { IBlur, IFieldBlur } from "../../Utils/EditorHook.util";
import { IGenericCSSFields } from "sitebuilder-common";
import { IFooterEditorProps } from "./FooterEditor.index";
import BackgroundGroupView from "../../../UI/InputGroups/Background/BackgroundGroup.view";
import BoxShadowView from "../../../UI/InputGroups/BoxShadow/BoxShadow.view";
import FontColor from "../../../UI/InputGroups/FontInputs/FontColor.input";

interface IFooterStylesEditorViewProps extends IFooterEditorProps {
    handleChange: (id: keyof IFooterSettings, data: IFieldBlur<any> | IBlur<any>) => void;
    onReset: (id: keyof IFooterSettings, keys: (keyof IGenericCSSFields)[]) => void;
}

export const FooterStylesEditorView: FC<IFooterStylesEditorViewProps> = (props) => {
    const {
        i18n: { language }
    } = useTranslation<ILang>();
    const { size } = useDom();
    const { widgets } = useWidgets();

    const isOpenHourHidden = widgets.Footer.draftSettings.isOpenHourHidden;
    const widgetProp: IWidgetGroups = "footerStyle";

    if (props.subKey !== widgetProp) return null;

    const model = new Untransform(props.editor.draftSettings.footerStyle || {}, size, language);
    const defaults = model.toJSON();
    const units = model.getUnits();

    return (
        <>
            <BackgroundGroupView
                onBlur={(data) => props.handleChange(widgetProp, data)}
                onReset={(keys) => props.onReset(widgetProp, keys)}
                defaultValues={defaults}
                units={units}
            />

            <BoxShadowView
                onBlur={(data) => props.handleChange(widgetProp, data)}
                onReset={(keys) => props.onReset(widgetProp, keys)}
                defaultValues={defaults}
                units={units}
            />

            <FontColor
                onBlur={(data) => props.handleChange(widgetProp, data)}
                onReset={(keys) => props.onReset(widgetProp, keys)}
                defaultValues={defaults}
                units={units}
            />
            <CheckboxInput<IFooterSettings>
                id={"isOpenHourHidden"}
                value={isOpenHourHidden === undefined ? false : isOpenHourHidden}
                onBlur={(data) => props.handleChange("isOpenHourHidden", data)}
            />
        </>
    );
};
