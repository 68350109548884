import { IAllDomTypes } from "../Interfaces/IDomTypes.interface";

export const ReIndexIfHasIndex = (list: IAllDomTypes[], index: number): void => {
    let hasIndex: boolean = false;
    for (let item of list) {
        if (item.index === index || hasIndex) {
            item.index += 1;
            hasIndex = true;
        }
    }
};
