import { IDynamicObject } from "xa-generics";

export const SPECIAL_DOM_DEFAULTS: IDynamicObject<any> = {
    SlideShow: {
        dynamicHeight: false,
        showArrows: true,
        slideshowDelay: "20",
        showElementDots: true
    },
    ContactForm: {},
    TableReserver: {},
    Gallery: {
        imageCount: "3",
        defaultVisible: "3",
        images: {}
    },
    RestMap: {
        lat: "47.50538",
        lng: "19.045615",
        zoom: "16"
    },
    Products: {
        layoutType: "default_layout"
    }
};
