import { ILang } from "../../../../Interfaces/ILang.type";
import { IFieldBlur } from "../../../Editor/Utils/EditorHook.util";
import { IFormErrors } from "../../../UseForm/IUseForm.interface";
import { ISizeMetrics } from "../../../../Interfaces/IMetrics.type";
import { FormErrorView } from "../../../UseForm/FormError.view";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import SizeUnitControlView from "../../SizeUnitControl/SizeUnitControl.view";

export type ITextInputWithSize<Fields extends object> = {
    value: string;
    id: keyof Fields;
    hidden?: boolean;
    message?: string;
    disabled?: boolean;
    className?: string;
    noLabel?: boolean;
    labelText?: ILang;
    placeholder?: string;
    description?: string;
    autoComplete?: boolean;
    sizeUnit?: ISizeMetrics;
    errors?: IFormErrors<Fields>;
    onBlur?: (data: IFieldBlur<Fields>) => void;
    onChange?: (id: keyof Fields, value: string) => void;
    type?: "text" | "email" | "password" | "tel" | "number" | "url";
};

export const TextInputWithSize = <Fields extends object>(props: ITextInputWithSize<Fields>) => {
    let wrapperStyle: string[] = ["wrapper"];
    const { t } = useTranslation();

    if (props.className) wrapperStyle.push(props.className);
    if (props.hidden) wrapperStyle.push("wrapper-hidden");
    if (props.disabled) wrapperStyle.push("wrapper-disabled");

    const [unit, setUnit] = useState<ISizeMetrics>(props.sizeUnit || "rem");
    const trackedValue = useRef<string>(props.value || "");

    const id = props.id as string;

    return (
        <div className={wrapperStyle.join(" ")}>
            <div className="input-label input-label-with-unit">
                <label htmlFor={id}>{t(props.labelText || id)}</label>
                <div className="input-controls">
                    <SizeUnitControlView
                        unit={unit}
                        setUnit={(id, newUnit) => {
                            setUnit(newUnit);
                            if (props.onBlur) {
                                const value = trackedValue.current
                                    ? `${trackedValue.current}${newUnit}`
                                    : "";
                                props.onBlur({ field: props.id, value });
                            }
                        }}
                        disablePercentage
                    />
                </div>
            </div>
            <input
                id={id}
                name={id}
                type={props.type}
                value={props.value}
                className={"text-input"}
                disabled={props.disabled}
                placeholder={props.placeholder}
                onChange={(e) => {
                    trackedValue.current = e.target.value;
                    if (props.onChange) props.onChange(props.id, e.target.value);
                }}
                onBlur={(e) => {
                    if (props.onBlur) {
                        props.onBlur({
                            field: props.id,
                            value: e.currentTarget.value ? `${e.currentTarget.value}${unit}` : ""
                        });
                    }
                }}
                autoComplete={props.autoComplete ? "on" : "off"}
            />
            {props.errors && <FormErrorView id={props.id} errors={props.errors} />}
            {props.description ? <p className="input-description">{props.description}</p> : null}
        </div>
    );
};
