import { FC } from "react";
import { Width } from "../../UI/InputGroups/Width/Width.view";
import { Height } from "../../UI/InputGroups/Height/Height.view";
import { Margins } from "../../UI/InputGroups/Margins/Margins.view";
import { useForm } from "../../UseForm/UseForm.provider";
import { Paddings } from "../../UI/InputGroups/Paddings/Paddings.view";
import { TextInput } from "../../UI/InputFields/TextInput/TextInput.view";
import { EditorHook } from "../Utils/EditorHook.util";
import { IDomElement } from "../../DomTracker/Interfaces/IDomTypes.interface";
import { CheckboxInput } from "../../UI/InputFields/Checkbox/Checkbox.view";
import { createEmbedUrl } from "../../../Utils/YoutubeRegex.util";
import { InputGroupContextProvider } from "../../UI/InputGrouper/InputGroup.provider";
import { IVideoCustom, IVideoEditor } from "../../DomMapper/Interfaces/IVideoComponent.interface";
import BackgroundGroupView from "../../UI/InputGroups/Background/BackgroundGroup.view";
import FormSeparatorView from "../../UI/FormSeparator/FormSeparator.view";

interface IVideoEditorProps {
    editor: IDomElement;
}

export const VideoEditor: FC<IVideoEditorProps> = (props) => {
    const { onBlur, onClear, units, defaults } = EditorHook<IVideoEditor>();

    const form = useForm<IVideoCustom & { transformed: string }, true>({
        editor: {
            youtube_url: defaults.youtube_url,
            transformed: defaults.youtube_url,
            autoplay: defaults.autoplay
        },
        initialRules: {
            youtube_url: true
        }
    });

    return (
        <InputGroupContextProvider defaultActive={""}>
            <FormSeparatorView
                defKey={"Text"}
                defaultGroup={
                    <>
                        <TextInput
                            id={"youtube_url"}
                            errors={form.fieldErrors}
                            onChange={form.handleChange}
                            value={form.editor.youtube_url}
                            labelText={"youtube_video_link"}
                            description={form.editor.transformed}
                            onBlur={(data) => {
                                const embedLink = createEmbedUrl(data.value, form.editor.autoplay);
                                form.setEditor((current) => {
                                    const state = { ...current };
                                    state.transformed = embedLink;
                                    state.youtube_url = embedLink ? embedLink : data.value;
                                    return state;
                                });
                                form.handleSubmit(() => {
                                    onBlur({ field: data.field, value: embedLink });
                                })();
                            }}
                        />
                        <CheckboxInput
                            id={"autoplay"}
                            value={form.editor.autoplay}
                            onChange={(field, value) => {
                                const embedLink = createEmbedUrl(form.editor.youtube_url, value);
                                form.setEditor((current) => {
                                    const state = { ...current };
                                    state.transformed = embedLink;
                                    state.youtube_url = embedLink;
                                    state.autoplay = value;
                                    return state;
                                });
                                onBlur([
                                    { field, value },
                                    { field: "youtube_url", value: embedLink }
                                ]);
                            }}
                        />
                        <Width
                            units={units}
                            onBlur={onBlur}
                            onReset={onClear}
                            defaultValues={defaults}
                        />
                        <Height
                            units={units}
                            onBlur={onBlur}
                            onReset={onClear}
                            defaultValues={defaults}
                        />
                    </>
                }
                advancedGroup={
                    <>
                        <Margins
                            units={units}
                            onBlur={onBlur}
                            onReset={onClear}
                            defaultValues={defaults}
                        />
                        <Paddings
                            units={units}
                            onBlur={onBlur}
                            onReset={onClear}
                            defaultValues={defaults}
                        />
                        <BackgroundGroupView
                            units={units}
                            onBlur={onBlur}
                            onReset={onClear}
                            defaultValues={defaults}
                        />
                    </>
                }
            />
        </InputGroupContextProvider>
    );
};
