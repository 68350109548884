import moment from "moment";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMountWithTriggers } from "xa-generics";
import { clone, cloneDeep, isEmpty, isEqual } from "lodash";
import { ISetState, SetCookie, useAfterTriggerChanged } from "xa-generics";
import {
    IRule,
    IForm,
    IReset,
    IUseForm,
    IFormRules,
    IFormErrors,
    IFormSubmit,
    IFormInternal,
    IPartialFormInternal
} from "./IUseForm.interface";

export interface IUseFormResult<Fields extends object, IsNonNull extends boolean> {
    updateRules: ISetState<IPartialFormInternal<Fields, IRule | boolean> | undefined>;
    isFormValid: () => { result: boolean; errors: IFormErrors<Fields> };
    handleChange: (id: keyof Fields, value: any, option?: any) => void;
    bulkChange: (data: { field: keyof Fields; value: any }[]) => void;
    rules?: IPartialFormInternal<Fields, IRule | boolean>;
    setEditor: ISetState<IForm<Fields, IsNonNull>>;
    setFieldErrors: ISetState<IFormErrors<Fields>>;
    clearErrors: (id: (keyof Fields)[]) => void;
    reset: (fields: IReset<Fields>[]) => void;
    clearError: (id: keyof Fields) => void;
    handleSubmit: IFormSubmit<Fields>;
    editor: IForm<Fields, IsNonNull>;
    fieldErrors: IFormErrors<Fields>;
    setIsDirty: ISetState<boolean>;
    isDirty: boolean;
}

export const useForm = <Fields extends object, IsNonNull extends boolean = false>(
    props: IUseForm<Fields, IsNonNull>
): IUseFormResult<Fields, IsNonNull> => {
    const { t } = useTranslation();
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [fieldErrors, setFieldErrors] = useState<IFormErrors<Fields>>({});
    const [isSubmitAttempted, setisSubmitAttempted] = useState<boolean>(false);
    const [rules, updateRules] = useState<IFormRules<Fields>>(props.initialRules);
    const [editor, setEditor] = useState<IForm<Fields, IsNonNull>>(cloneDeep(props.editor));

    const resetLock = useRef<boolean>(false);

    useMountWithTriggers(() => {
        if (!rules || isEmpty(rules)) return;

        for (let key in rules) {
            const rule = rules[key];
            if (typeof rule === "boolean") continue;
            if (rule?.pattern && rule.pattern.value.flags.indexOf("g") > -1) {
                console.error(
                    "Don't use the 'g' flag for field patterns because",
                    "its result is unreliable with the regexp.test()",
                    "between renders due to its index not resetting!",
                    `This error was thrown for the ${key} field!`
                );
            }
        }
    }, [rules]);

    const clearError = (id: keyof Fields): void => {
        setFieldErrors((current) => {
            const state = clone(current);
            delete state[id];
            return state;
        });
    };

    const clearErrors = (fields: (keyof Fields)[]): void => {
        setFieldErrors((current) => {
            const state = clone(current);
            for (let field of fields) {
                delete state[field];
            }
            return state;
        });
    };

    const addRuleError = (
        errors: IPartialFormInternal<Fields, IRule<string>>,
        field: keyof Fields,
        errorKey: keyof Omit<IRule, "required">
    ): void => {
        errors[field] = {};
        const fieldRules = rules![field] as IRule;
        errors[field]![errorKey] = fieldRules[errorKey]!.noTranslate
            ? fieldRules[errorKey]!.message
            : t<any>(fieldRules[errorKey]!.message);
    };

    const reset = (fields: IReset<Fields>[]): void => {
        resetLock.current = true;
        setEditor((current) => {
            if (!current) return current;
            const state = clone(current!);
            const source = fields.length > 0 ? fields : Object.keys(state);

            for (let key of source) {
                if (typeof key === "object") {
                    state[key.field] = key.value;
                } else {
                    const field = key as keyof Fields;
                    if (typeof state[field] !== "boolean") {
                        state[field] = "" as never;
                    }
                }
            }
            return state;
        });
        setIsDirty(false);
    };

    /**
     * The third parameter **obj** exists only for the select and datepicker inputs!
     * - For the select, it returns the clicked option or null.
     * - For the datepicker, it returns a moment object or null.
     */
    const handleChange = (id: keyof Fields, value: any, obj?: any): void => {
        setEditor((current) => {
            if (!current) return current;
            let state = clone(current!);
            state[id] = value as never;

            if (fieldErrors[id] && props.noValidateOnChange) clearError(id);
            if (props.fieldHooks && props.fieldHooks[id]) {
                let modifiedState = props.fieldHooks[id]!(state, value, obj);
                if (modifiedState) state = modifiedState as never;
            }
            if (props.hookOnAnyFieldChange) {
                let modifiedState = props.hookOnAnyFieldChange(state, id, value, obj);
                if (modifiedState) state = modifiedState as never;
            }
            if (!props.noValidateOnChange && isSubmitAttempted) isFormValid(state);
            if (props.saveInCookie) {
                let newState = clone(state);
                if (props.saveExceptionFields) {
                    for (let field of props.saveExceptionFields) {
                        delete newState[field];
                    }
                }
                SetCookie(props.saveInCookie, newState, 30);
            }
            return state;
        });
    };

    const bulkChange = (dataSet: { field: keyof Fields; value: any }[]): void => {
        setEditor((current) => {
            if (!current) return current;
            const state = clone(current!);
            let shouldRunValidate: boolean = false;
            const errorClears: (keyof Fields)[] = [];
            for (const data of dataSet) {
                state[data.field] = data.value;
                if (fieldErrors[data.field]) {
                    if (!props.noValidateOnChange) shouldRunValidate = true;
                    else errorClears.push(data.field);
                }
                if (props.saveInCookie) {
                    let newState = clone(state);
                    if (props.saveExceptionFields) {
                        for (let field of props.saveExceptionFields) {
                            delete newState[field];
                        }
                    }
                    SetCookie(props.saveInCookie, newState, 30);
                }
            }
            if (shouldRunValidate) isFormValid(state);
            else clearErrors(errorClears);
            return state;
        });
    };

    const isFormValid = (
        state?: IFormInternal<Fields>
    ): {
        result: boolean;
        errors: IFormErrors<Fields>;
    } => {
        if (!editor) return { result: false, errors: {} };
        if (!rules || isEmpty(rules)) return { result: true, errors: {} };

        let errors: IFormErrors<Fields> = {};

        for (let field in rules) {
            const fieldRules = rules[field];
            const value = state ? (state[field] as any) : (editor[field] as any);
            if (!fieldRules) continue;
            if (typeof fieldRules === "boolean") {
                if (!value) {
                    errors[field] = {};
                    errors[field]!.required = t("required_field");
                    continue;
                }
            } else {
                if (fieldRules.required && !value) {
                    errors[field] = {};
                    errors[field]!.required = t("required_field");
                    continue;
                }
                if (fieldRules.max && Number(value) > fieldRules.max.value) {
                    addRuleError(errors, field, "max");
                    continue;
                }
                if (fieldRules.min && Number(value) < fieldRules.min.value) {
                    addRuleError(errors, field, "min");
                    continue;
                }
                if (fieldRules.maxDate && moment(value).isAfter(fieldRules.maxDate.value)) {
                    addRuleError(errors, field, "maxDate");
                    continue;
                }
                if (fieldRules.minDate && moment(value).isBefore(fieldRules.minDate.value)) {
                    addRuleError(errors, field, "minDate");
                    continue;
                }
                if (
                    fieldRules.minLength &&
                    typeof value === "string" &&
                    value.length < fieldRules.minLength.value
                ) {
                    addRuleError(errors, field, "minLength");
                    continue;
                }
                if (
                    fieldRules.maxLength &&
                    typeof value === "string" &&
                    value.length > fieldRules.maxLength.value
                ) {
                    addRuleError(errors, field, "maxLength");
                    continue;
                }
                if (fieldRules.pattern && !fieldRules.pattern.value.test(value)) {
                    addRuleError(errors, field, "pattern");
                    continue;
                }
                if (
                    fieldRules.maxFileSize &&
                    (value instanceof File || (value instanceof Array && value[0] instanceof File))
                ) {
                    const file = value instanceof File ? value : (value[0] as File);
                    if (file.size > fieldRules.maxFileSize.value) {
                        addRuleError(errors, field, "maxFileSize");
                        continue;
                    }
                }
            }
        }

        if (isEmpty(errors)) {
            if (!isEmpty(fieldErrors)) setFieldErrors({});
            return { result: true, errors: {} };
        } else {
            //Run this only if submit is called.
            //A state is passed only if the validation is allowed for onChanges after a failed submit.
            if (!state) {
                let firstFieldId: keyof Fields | null = null;
                for (let key in errors) {
                    firstFieldId = key;
                    break;
                }
                if (firstFieldId) {
                    const input = document.getElementById(firstFieldId as string);
                    if (input)
                        input.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "center"
                        });
                }
            }
            if (!isEqual(errors, fieldErrors)) {
                setFieldErrors(errors);
            }
            return { result: false, errors };
        }
    };

    const handleSubmit =
        (onValid: (values: IFormInternal<Fields>) => void, onInvalid?: (error?: any) => void) =>
        async (e?: React.BaseSyntheticEvent) => {
            e?.preventDefault();
            if (!editor) return;
            if (!isSubmitAttempted) setisSubmitAttempted(true);
            try {
                const validationResult = isFormValid();
                if (!validationResult.result) {
                    if (onInvalid) onInvalid(validationResult.errors);
                    return;
                }
                return onValid(editor!);
            } catch (error) {
                if (onInvalid) return onInvalid(error);
            }
        };

    useAfterTriggerChanged(() => {
        if (resetLock.current) {
            resetLock.current = false;
            return;
        }
        setIsDirty(true);
    }, [editor]);

    return {
        setFieldErrors,
        handleSubmit,
        handleChange,
        isFormValid,
        updateRules,
        fieldErrors,
        clearErrors,
        setIsDirty,
        bulkChange,
        clearError,
        setEditor,
        isDirty,
        editor,
        rules,
        reset
    };
};
