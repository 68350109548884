import { FC } from "react";
import { ILang } from "../../../Interfaces/ILang.type";
import { WithConfirm } from "../../UI/Confirm/Confirm.view";
import { useTemplate } from "../Provider/Template.provider";
import { InlineLoading } from "../../UI/InlineLoading/InlineLoading.view";
import { useRestaurant } from "../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import { ISetState, Objectify } from "xa-generics";
import { TemplateRestaurantModel } from "../Model/TemplateRestaurant.model";
import {
    Launch,
    Restaurant,
    Application,
    ThumbsUpFilled,
    NotebookReference
} from "@carbon/icons-react";
import Button from "../../UI/Button/Button.view";

interface IChooseTemplateRestaurantViewProps {
    applyRestaurantTemplate: (model: TemplateRestaurantModel) => void;
    setLoading: ISetState<boolean>;
    loading?: boolean;
}

export const ChooseTemplateRestaurantView: FC<IChooseTemplateRestaurantViewProps> = (props) => {
    const { templateRestaurants, templateCategories } = useTemplate();
    const { declineConfigTemplate } = useRestaurant();
    const { t, i18n } = useTranslation<ILang>();

    const categories = Objectify(templateCategories, "template_category_id");
    const lang = i18n.language;

    const nameKey = lang !== "hu" ? "name_secondary" : "name";
    const descKey = lang !== "hu" ? "description_secondary" : "description";

    return (
        <div className={"choose-rest-template"}>
            <div className="template-restaurant">
                <div className="template-restaurant__category">
                    {t<ILang>("empty_template_title")}
                </div>
                <div className="template-restaurant__description">
                    {t<ILang>("empty_template_desc")}
                </div>
                <div className="template-restaurant__image">
                    <Application size={200} />
                </div>
                <div className="template-restaurant__buttons">
                    <Button
                        kind={"DANGER"}
                        size={"NORMAL"}
                        Icon={props.loading ? <InlineLoading isAlternate /> : <NotebookReference />}
                        className="template-restaurant__buttons--button"
                        onClick={() => {
                            props.setLoading(true);
                            declineConfigTemplate();
                        }}
                        title={t<ILang>("apply")}
                    >
                        {t<ILang>("apply")}
                    </Button>
                </div>
            </div>
            {templateRestaurants.map((model) => (
                <div
                    className={"template-restaurant"}
                    key={`${model.restaurant_id}-template-restaurant`}
                >
                    <div className="template-restaurant__category">
                        <div className="container">
                            <Restaurant size={20} />
                            <span className="text">
                                {categories[model.template_category_id]?.[nameKey]}
                            </span>
                        </div>

                        <a
                            className="om-btn template-restaurant__buttons--button --SMALL --SIMPLE"
                            rel={"noreferrer noopener"}
                            href={model.preview_url}
                            target={"_blank"}
                        >
                            <div className="om-btn__text">{t<ILang>("open_link")}</div>
                            <div className="om-btn__icon">
                                <Launch />
                            </div>
                        </a>
                    </div>
                    <div className="template-restaurant__description">{model[descKey]}</div>
                    <div className="template-restaurant__image">
                        <img src={model.image_url} alt={model.image_file_name} className={"img"} />
                    </div>
                    <div className="template-restaurant__buttons">
                        <WithConfirm
                            className="template-restaurant__buttons--button"
                            Icon={<ThumbsUpFilled />}
                            questionContent={
                                <div className={"global__justified"}>
                                    {t<ILang>("rest_apply_confirm")}
                                    <br />
                                    <strong> {t<ILang>("rest_apply_recommend")}</strong>
                                </div>
                            }
                            onConfirm={() => props.applyRestaurantTemplate(model)}
                            isDanger={false}
                            size={"SM"}
                        >
                            <Button
                                kind={"DANGER"}
                                size={"NORMAL"}
                                Icon={<NotebookReference />}
                                className="template-restaurant__buttons--button"
                                title={t<ILang>("apply")}
                            >
                                {t<ILang>("apply")}
                            </Button>
                        </WithConfirm>
                    </div>
                </div>
            ))}
        </div>
    );
};
