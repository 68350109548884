import { AppConfigsModel } from "sitebuilder-common";
import { DEF_BG, DEF_BG2, DEF_COLOR } from "../../../Static/IFrameBaseColors.static";

export const GetColors = (app: AppConfigsModel) => {
    const fallbackBg = app.global.isDarkTheme ? DEF_BG2 : DEF_BG;
    return {
        backgroundColor: app.buttons.backgroundColor || fallbackBg,
        color: app.buttons.color || DEF_COLOR
    };
};
