import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { UserModel } from "../../../../Models/User.model";
import { TextInput } from "../../../UI/InputFields/TextInput/TextInput.view";
import { ClientButton } from "../../../DomMapper/DomComponents/Button/ClientButton.view";
import { useTranslation } from "react-i18next";
import { FormatPhoneNumber } from "sitebuilder-common";
import { IProfileIndexProps } from "./Profile.index";
import { Repeat, Save, TrashCan } from "@carbon/icons-react";

interface IProfileMyDataViewProps extends IProfileIndexProps {}

export const ProfileMyDataView: FC<IProfileMyDataViewProps> = (props) => {
    const { t } = useTranslation<ILang>();

    return (
        <>
            <form className={"profile__my-data"} onSubmit={props.onSaveUser} autoComplete={"off"}>
                <h3 className="profile__my-data--title">{t<ILang>("my_data")}</h3>
                <TextInput<UserModel>
                    required
                    id={"email"}
                    type={"email"}
                    value={props.userForm.editor.email}
                    className={"full-row"}
                    onChange={props.userForm.handleChange}
                />
                <TextInput<UserModel>
                    required
                    id={"first_name"}
                    value={props.userForm.editor.first_name}
                    onChange={props.userForm.handleChange}
                />
                <TextInput<UserModel>
                    required
                    id={"last_name"}
                    value={props.userForm.editor.last_name}
                    onChange={props.userForm.handleChange}
                />
                <TextInput<UserModel>
                    required
                    id={"phone_1"}
                    type={"tel"}
                    autoComplete={false}
                    className={"full-row"}
                    labelText={"phone_number"}
                    value={props.userForm.editor.phone_1}
                    onChange={(id, value) => {
                        const cleanValue = value.replace(/\s/g, "");
                        if (cleanValue !== "" && isNaN(Number(cleanValue))) return;
                        props.userForm.handleChange(id, FormatPhoneNumber(value));
                    }}
                />
                <div className="profile__my-data--control">
                    {props.v2Btn ? (
                        <props.v2Btn
                            type={"submit"}
                            Icon={<Save />}
                            disabled={!props.isUserAltered}
                        >
                            {t<ILang>("save")}
                        </props.v2Btn>
                    ) : (
                        <ClientButton
                            type={"submit"}
                            RightIcon={Save}
                            isDisabled={!props.isUserAltered}
                        >
                            {t<ILang>("save")}
                        </ClientButton>
                    )}
                </div>
            </form>
            <div className="profile__my-data-block profile__my-data-pw-change">
                <h6 className="profile__my-data-block--sub-title">{t<ILang>("password")}</h6>
                {props.v2Btn ? (
                    <props.v2Btn Icon={<Repeat />} isSecondary>
                        {t<ILang>("change_pw")}
                    </props.v2Btn>
                ) : (
                    <ClientButton RightIcon={Repeat} isSecondary>
                        {t<ILang>("change_pw")}
                    </ClientButton>
                )}
            </div>
            <div className="profile__my-data-block profile__my-data-terminate">
                <h6 className="profile__my-data-block--sub-title">{t<ILang>("terminate_user")}</h6>
                {props.v2Btn ? (
                    <props.v2Btn Icon={<TrashCan />}>
                        {t<ILang>("terminate_button_text")}
                    </props.v2Btn>
                ) : (
                    <ClientButton RightIcon={TrashCan}>
                        {t<ILang>("terminate_button_text")}
                    </ClientButton>
                )}
            </div>
        </>
    );
};
