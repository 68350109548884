import { useTranslation } from "react-i18next";
import { useEditor } from "../Controller/EditorContext.provider";
import { ILang } from "../../../Interfaces/ILang.type";

export interface IEditorHeaderViewProps {
    langKey?: ILang;
}

const EditorHeaderView: React.FC<IEditorHeaderViewProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { editor } = useEditor();

    return (
        <div className="editor__header --centerized">
            {t<ILang>(props.langKey || editor?.type || "")}
        </div>
    );
};

export default EditorHeaderView;
