import { FC } from "react";
import { ISVGSize } from "./ISVGSize.type";

interface IRadius extends ISVGSize {
    deg: "0" | "90" | "180" | "270";
}

const SvgRadius: FC<IRadius> = ({ deg, s, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            transform={`rotate(${deg})`}
            viewBox="0 0 29.5 29.5"
            height={`${s}px`}
            width={`${s}px`}
            {...props}
        >
            <path
                d="M25.5 3.5a26 22 0 00-22 22"
                fill="none"
                stroke="currentColor"
                strokeLinejoin="bevel"
                strokeWidth={8}
            />
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="square"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M3.5 24.5h21v-22"
            />
        </svg>
    );
};

export const TopLeftRadius16 = () => {
    return <SvgRadius deg={"0"} s={"16"} />;
};

export const TopLeftRadius32 = () => {
    return <SvgRadius deg={"0"} s={"32"} />;
};

export const TopRightRadius16 = () => {
    return <SvgRadius deg={"90"} s={"16"} />;
};

export const TopRightRadius32 = () => {
    return <SvgRadius deg={"90"} s={"32"} />;
};

export const BottomLeftRadius16 = () => {
    return <SvgRadius deg={"270"} s={"16"} />;
};

export const BottomLeftRadius32 = () => {
    return <SvgRadius deg={"270"} s={"32"} />;
};

export const BottomRightRadius16 = () => {
    return <SvgRadius deg={"180"} s={"16"} />;
};

export const BottomRightRadius32 = () => {
    return <SvgRadius deg={"180"} s={"32"} />;
};

const SvgWeak: FC<IRadius> = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 29.5 29.5"
            width={`${props.s}px`}
            height={`${props.s}px`}
            transform={`rotate(${props.deg})`}
            {...props}
        >
            <path
                d="M25.5 3.5a26 22 0 00-22 22"
                fill="none"
                stroke="currentColor"
                strokeLinejoin="bevel"
                strokeWidth={4}
            />
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="square"
                strokeMiterlimit={6}
                strokeWidth={2}
                d="M3.5 24.5h21v-22"
            />
        </svg>
    );
};

export const BorderRadius16 = () => {
    return <SvgWeak deg={"0"} s={"16"} />;
};
