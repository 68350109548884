import { IDynamicObject, useDidMount } from "xa-generics";
import { Draggable, TrashCan } from "@carbon/icons-react";
import { createRef, useState } from "react";
import { IListElement } from "../../../DomMapper/Interfaces/IBulletListComponent.interface";
import { cloneDeep } from "lodash";
import { TextInput } from "../TextInput/TextInput.view";

export interface IListInputSubFormProps extends IListElement {
    index: number;
    language: string;
    deleteDisabled?: boolean;
    onDelete: (index: number) => void;
    onBlur: (index: number, value: string) => void;
}

const ListInputSubForm: React.FC<IListInputSubFormProps> = (props) => {
    const [value, setValue] = useState<IDynamicObject>(props.value);

    const localRef = createRef<HTMLInputElement>();

    useDidMount(() => {
        if (localRef.current && !props.value) {
            localRef.current.focus();
        }
    });

    const delete_classes: string[] = ["svg", "list-input-field__button"];
    if (props.deleteDisabled) delete_classes.push("list-input-field__button--disabled");

    return (
        <div className="list-input-field" draggable={true}>
            <div className="svg list-input-field__button">
                <Draggable />
            </div>

            <TextInput
                noLabel
                focusRef={localRef}
                value={value[props.language] || ""}
                onChange={(id, val) => {
                    const state = cloneDeep(value);
                    state[props.language] = val;
                    setValue(state);
                }}
                placeholder={`${props.index + 1}.`}
                id={`${props.id}_${props.index}_list_item`}
                onBlur={(data) => props.onBlur(props.index, data.value)}
            />
            <div className={delete_classes.join(" ")} onClick={() => props.onDelete(props.index)}>
                <TrashCan />
            </div>
        </div>
    );
};

export default ListInputSubForm;
