import { IBackgroundMetrics, ISizeMetrics } from "../Interfaces/IMetrics.type";
import { LANGIFIED_PROPS } from "../Static/LangifiedProps.static";
import { ICommonContent } from "sitebuilder-common";
import { IPartialCSS } from "sitebuilder-common";
import { ALL_STYLES } from "../Static/AllStyles.static";
import { IPR, IPRO } from "sitebuilder-common";
import { ISizes } from "sitebuilder-common";

/**
 * ## UntransformComponentContent
 *
 * This class must be used in the editors of each component
 * to parse the css property value to a valid value.
 *
 * This is because the content stores it like this:
 * ```json
 * {
 *  "uuid-random-string": {
 *      "paddingTop": {
 *          "xl": "40px",
 *          "md": "20px"
 *      },
 *      "paddingBottom": {
 *          "xl": "20px"
 *      }
 *  }
 * }
 * ```
 * In case of size 'md' this will be transformed to this:
 * ```json
 * "uuid-random-string": {
 *   "paddingTop": "20"
 *  }
 * }
 * ```
 */
export class Untransform<T extends ICommonContent<IPartialCSS<IPR<ISizes>>>> {
    constructor(sourceObject: T, size: ISizes, currentLang: string) {
        this.newObject = {};
        this.sourceObject = Object.freeze(sourceObject); //Don't modify this as it's coming from a state!
        this.size = size;

        for (let ruleKey in sourceObject) {
            //Skip non-css content settings as they aren't size-related
            if (!(ruleKey in ALL_STYLES)) {
                if (typeof sourceObject[ruleKey] === "object" && ruleKey === currentLang) {
                    for (let key in sourceObject[ruleKey]) {
                        if (key in LANGIFIED_PROPS) {
                            this.newObject[key as keyof T] = sourceObject[ruleKey][key];
                        }
                    }
                } else {
                    this.newObject[ruleKey] = sourceObject[ruleKey];
                }

                continue;
            }

            //Skip setting if there is no value in a rule with the current size
            if (!((size in sourceObject[ruleKey as never]) as any)) continue;

            this.generateValueByRule(ruleKey as keyof IPartialCSS<IPR<ISizes, any>>);
        }
    }
    private sourceObject: T;
    private units: IUntransformedUnits = {};
    private newObject: IPRO<T>;
    private size: ISizes;

    private parseSizeUnits(
        rule: keyof IPartialCSS<IPR<ISizes>>,
        unitKey?: keyof IUntransformedUnits
    ): void {
        const value = this.sourceObject[rule]![this.size] as string;
        let uk: keyof IUntransformedUnits = unitKey || rule;
        let subStringIndex: number = 0;
        if (value.endsWith("px")) {
            subStringIndex = 2;
            this.units[uk] = "px";
        } else if (value.endsWith("rem")) {
            subStringIndex = 3;
            this.units[uk] = "rem";
        } else if (value.endsWith("%")) {
            subStringIndex = 1;
            this.units[uk] = "%";
        } else this.units[uk] = "px";
        this.newObject[rule] = value.slice(0, value.length - subStringIndex);
    }
    private parseBG(rule: "background" | "backgroundColor"): void {
        const value = this.sourceObject[rule]![this.size] as string;

        if (rule === "background") {
            this.units.bgType = "GRADIENT";
            this.newObject.background = value;
        } else {
            this.units.bgType = "GENERIC";
            this.newObject.backgroundColor = value;
        }
    }

    private parseNormalValue(rule: keyof IPartialCSS<IPR<ISizes>>): void {
        this.newObject[rule] = this.sourceObject[rule]![this.size];
    }

    private generateValueByRule(ruleKey: keyof IPartialCSS<IPR<ISizes>>): any {
        switch (ruleKey) {
            case "paddingLeft":
            case "paddingRight":
            case "paddingTop":
            case "paddingBottom":
                return this.parseSizeUnits(ruleKey, "padding");
            case "marginLeft":
            case "marginRight":
            case "marginTop":
            case "marginBottom":
                return this.parseSizeUnits(ruleKey, "margin");
            case "height":
            case "minHeight":
            case "maxHeight":
                return this.parseSizeUnits(ruleKey, "height");
            case "minWidth":
            case "width":
            case "maxWidth":
                return this.parseSizeUnits(ruleKey, "width");
            case "borderTopLeftRadius":
            case "borderTopRightRadius":
            case "borderBottomRightRadius":
            case "borderBottomLeftRadius":
                return this.parseSizeUnits(ruleKey, "borderRadius");
            case "borderLeftWidth":
            case "borderRightWidth":
            case "borderBottomWidth":
            case "borderTopWidth":
                return this.parseSizeUnits(ruleKey, "border");
            case "fontSize":
            case "fontWeight":
            case "letterSpacing":
            case "lineHeight":
                return this.parseSizeUnits(ruleKey);
            case "backgroundColor":
            case "background":
                return this.parseBG(ruleKey);

            default:
                return this.parseNormalValue(ruleKey);
        }
    }

    public toJSON(): Partial<Record<keyof T, any>> {
        return this.newObject;
    }

    public getUnits(): IUntransformedUnits {
        return this.units;
    }
}

export type IUntransformedUnits = IPR<keyof IPartialCSS<any>, any> &
    Partial<{
        bgType: IBackgroundMetrics;
        padding: ISizeMetrics;
        margin: ISizeMetrics;
        borderRadius: ISizeMetrics;
        border: ISizeMetrics;
        boxShadow: ISizeMetrics;
        textShadow: ISizeMetrics;
        height: ISizeMetrics;
        width: ISizeMetrics;
    }>;
