import { useChangeHistory } from "../Controller/ComponentHistory.provider";
import { Reset, ViewNext } from "@carbon/icons-react";
import { useResetHistory } from "../Controller/ResetHistory.provider";
import { useTranslation } from "react-i18next";
import { useDom } from "../../DomTracker/Controller/DomTracker.provider";
import { ILang } from "../../../Interfaces/ILang.type";
import { map } from "lodash";

export interface IHistoryViewProps {
    setShowHistory: (value: boolean) => void;
}

const HistoryView: React.FC<IHistoryViewProps> = (props) => {
    const { changeHistory, currentIndex } = useChangeHistory();
    const { resetToThisState } = useResetHistory();
    const { currentPage } = useDom();
    const pageid = currentPage.page_id;
    const historyList = changeHistory[pageid] || [];
    const cid = currentIndex[pageid] || 0;
    const { t } = useTranslation<ILang>();

    return (
        <div className="editor__content">
            <div className="editor__header --centerized">{t<ILang>("history")}</div>
            <div className="history">
                {map(historyList, (item, index) => (
                    <div
                        className={`history__item${cid === index ? " --active" : ""}`}
                        key={`${index}-history`}
                    >
                        <div className="history__item--index">
                            {index + 1}. ({item.lang.toUpperCase()})
                        </div>
                        <div className="history__item--message">
                            {item.displayMessage}
                            {!("isDefaultHistory" in item)
                                ? item.domType
                                    ? ` (${t<ILang>(item.domType)})`
                                    : ""
                                : null}
                        </div>
                        <div className="history__item--controls">
                            <div
                                className="svg"
                                title={`${t<ILang>("reset_here")}: ${index + 1}`}
                                onClick={() => resetToThisState(item, index)}
                            >
                                {index < cid ? <ViewNext /> : <Reset />}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HistoryView;
