import { v4 as uuidv4 } from "uuid";
import { createRef } from "react";
import { ISection } from "../Interfaces/IDomTypes.interface";
import { IRefs } from "../Interfaces/IRefs.type";

export const ColumnChangeHandler = (
    refs: IRefs,
    section: ISection,
    newCount: number,
    sectionID: string
): void => {
    let currentCount = section.elements.length;
    if (currentCount < newCount) {
        for (let index = currentCount; index < newCount; index++) {
            let uuid = uuidv4();
            refs.current[uuid] = createRef<HTMLDivElement>();
            section.elements.push({
                type: "Column",
                index,
                content: {},
                elements: [],
                sectionUUID: sectionID,
                uuid
            });
        }
    } else {
        for (let index = currentCount; index > newCount; index--) {
            section.elements.pop();
        }
    }
};
