import { FC } from "react";
import HTMLReactParser from "html-react-parser";
import { useApp } from "../Provider/App.context";

interface IAppExtraContentProps {}

export const AppExtraContent: FC<IAppExtraContentProps> = (props) => {
    const { data, dataSource } = useApp();
    const content = data[dataSource].global.extraPageContent;
    return <>{HTMLReactParser(content)}</>;
};
