import { ParseComponentStyle } from "sitebuilder-common";
import { useTranslation } from "react-i18next";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { ILinkContent } from "../../Interfaces/ILinkComponent.interface";
import { IDomElement } from "../../../DomTracker/Interfaces/IDomTypes.interface";
import { LinkStyles } from "../../Static/LinkStyles.static";
import { useImages } from "../../../../Contexts/Images.context";
import { DnDEvents } from "../../Util/DND.util";
import { NavLink } from "react-router-dom";
import { ILang } from "../../../../Interfaces/ILang.type";
import Overlay from "../../View/Overlay.view";

export interface IDomLinkButtonProps extends IDomElement {}

const DomLinkButton: React.FC<IDomLinkButtonProps> = (props) => {
    const { t, i18n } = useTranslation<ILang>();
    const currentLang = i18n.language;
    const { restaurant } = useRestaurant();
    const { images } = useImages();
    const { refs, size, id, showOverlay, ...events } = DnDEvents<ILinkContent>(props, "ELEMENT");

    let style = ParseComponentStyle(props.content, LinkStyles, size, images);

    const data = props.content?.[currentLang] as ILinkContent | undefined;
    const text = (data?.text as string) || t<ILang>("link_text");

    const getLink = (text: string): JSX.Element => {
        let url = data?.url || "#";
        if (data?.isExternal && props.content?.openOnNewTab) {
            return (
                <a rel={"noreferrer noopener"} target={"_blank"} href={url}>
                    <button style={style} className="dom__link--button-type">
                        {text}
                    </button>
                </a>
            );
        } else {
            if (!props.content?.openOnNewTab && url.indexOf(restaurant.domain) > -1) {
                url = url.split(restaurant.domain)[1];
            }
            return (
                <NavLink to={url}>
                    <button style={style} className="dom__link--button-type">
                        {text}
                    </button>
                </NavLink>
            );
        }
    };

    return (
        <div
            style={{
                textAlign: style?.textAlign
            }}
            ref={refs.current[props.uuid]}
            className="dom__link"
            {...events}
            id={id}
        >
            {showOverlay && <Overlay parent={props} />}
            {getLink(text)}
        </div>
    );
};

export default DomLinkButton;
