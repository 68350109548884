import { FC } from "react";
import { useApp } from "../Provider/App.context";
import { AppButton } from "./AppButton.view";
import { ProfileController } from "../../WidgetModules/Profile/Controller/Profile.controller";

interface IAppProfileIndexProps {}

export const AppProfileIndex: FC<IAppProfileIndexProps> = (props) => {
    const { data, dataSource } = useApp();

    return <ProfileController styleData={data[dataSource]} v2Btn={AppButton} />;
};
