import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { useWidgets } from "../../../Widgets/Controller/WidgetsContext.provider";
import { useSideMenu } from "../../../Sidemenu/Controller/SideMenu.provider";
import { FooterStyles } from "../Static/FooterStyles.static";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { GetWidgetOverlay } from "../../Util/WidgetOverlay.util";
import { FooterTopBarView } from "./FooterTopBar.view";
import { FooterMidBarView } from "./FooterMidBar.view";
import { FooterBottomBarView } from "./FooterBottomBar.view";
import { ParseComponentStyle } from "sitebuilder-common";
import { IFooter, IFooterSettings } from "../Interfaces/IFooterWidget.interface";
import WidgetOverlay from "../../../Widgets/View/WidgetOverlay.view";

export interface IWidgetFooterProps {}

const WidgetFooter: React.FC<IWidgetFooterProps> = (props) => {
    const { size } = useDom();
    const { widgets } = useWidgets<IFooter>();
    const { currentFont } = useRestaurant();
    const { isOpen } = useSideMenu();
    const { showOverlay, ...events } = GetWidgetOverlay("Footer", "footerStyle");

    const style = ParseComponentStyle(
        widgets.Footer.draftSettings?.footerStyle,
        FooterStyles,
        size
    );
    const classes: string[] = ["widget footer", `footer-${size}`, currentFont];
    if (isOpen) classes.push("footer-with-sidemenu-opened");

    return (
        <footer className={classes.join(" ")} style={style} {...events}>
            {showOverlay && (
                <WidgetOverlay<IFooterSettings>
                    isMain
                    align={"left"}
                    subKey={"footerStyle"}
                    parent={widgets.Footer}
                />
            )}
            <FooterTopBarView />
            <FooterMidBarView />
            <FooterBottomBarView />
        </footer>
    );
};

export default WidgetFooter;
