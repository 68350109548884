import { Context, createContext, useState, useContext } from "react";
import { IGrouperContext } from "./IGroupContext.interface";

/**
 * ## GroupContext
 */
const GroupContext: Context<IGrouperContext<any>> = createContext<IGrouperContext<any>>(
    null as any
);

GroupContext.displayName = "GroupContext";

interface IGroupContextProviderProps<T extends string> {
    defaultActive?: T | "";
    children?: any;
}

/**
 * ## Group context provider component
 *
 */
export const GroupContextProvider = <T extends string>(props: IGroupContextProviderProps<T>) => {
    const [active, setActive] = useState<T | "">(props.defaultActive || "");

    return (
        <GroupContext.Provider
            value={{
                setActive,
                active
            }}
        >
            {props.children}
        </GroupContext.Provider>
    );
};

export const useGroup = <T extends string>(): IGrouperContext<T> => useContext(GroupContext);
