import { IFormWithUnit } from "../../../../Interfaces/IFormWithUnit.type";
import { IBoxShadow } from "./IBoxShadow.interface";

export const BoxShDefaults: IFormWithUnit<IBoxShadow> = {
    blurRadius: "0",
    spreadRadius: "0",
    color: "#00000022",
    offsetX: "0",
    offsetY: "0",
    unit: "px"
};
