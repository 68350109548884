import { Laptop, Mobile, Screen, Tablet, TabletLandscape } from "@carbon/icons-react";
import { ISizes } from "sitebuilder-common";

export const GetSizeIcon = (domSize: ISizes) => {
    if (domSize === "xl") return <Screen size={24} />;
    if (domSize === "lg") return <Laptop size={24} />;
    if (domSize === "md") return <TabletLandscape size={24} />;
    if (domSize === "sm") return <Tablet size={24} />;
    if (domSize === "xs") return <Mobile size={24} />;
    return null;
};
