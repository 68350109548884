import { ActiveFontCollectionContextProvider } from "./Contexts/ActiveFontCollection.context";
import { ChangeHistoryContextProvider } from "./Components/ComponentHistory/Controller/ComponentHistory.provider";
import { ResetHistoryContextProvider } from "./Components/ComponentHistory/Controller/ResetHistory.provider";
import { WidgetEditorContextProvider } from "./Components/Editor/Controller/WidgetEditorContext.provider";
import { ComponentDnDContextProvider } from "./Components/ComponentDnD/Provider/ComponentDnD.provider";
import { SideMenuContextProvider } from "./Components/Sidemenu/Controller/SideMenu.provider";
import { TemplateContextProvider } from "./Components/Templates/Provider/Template.provider";
import { ProductsContextProvider } from "./Components/DomMapper/DomComponents/Products/Context/ProductsContext.provider";
import { Navigate, Route, Routes } from "react-router";
import { WidgetsContextProvider } from "./Components/Widgets/Controller/WidgetsContext.provider";
import { EditorContextProvider } from "./Components/Editor/Controller/EditorContext.provider";
import { IFrameContextProvider } from "./Components/IFrame/Provider/IFrame.context";
import { CartContextProvider } from "./Components/WidgetModules/Cart/Provider/Cart.provider";
import { DomContextProvider } from "./Components/DomTracker/Controller/DomTracker.provider";
import { IFrameProductsView } from "./Components/IFrame/View/Order/IFrameProducts.view";
import { FrameProfileIndex } from "./Components/IFrame/View/FrameProfile.index";
import { FrameCartView } from "./Components/IFrame/View/Cart/FrameCart.index";
import { FrameInfoView } from "./Components/IFrame/View/FrameInfo.view";
import { IFrameLayout } from "./Components/IFrame/View/IFrame.layout";
import { AppRoutes } from "./Components/App/View/App.routes";
import { useAccess } from "./Contexts/Access.context";
import { AuthView } from "./Components/Auth/View/Auth.view";
import SideMenuView from "./Components/Sidemenu/View/SideMenu.view";
import RouteMapper from "./Components/DomMapper/View/RouteMapper.view";

export interface IRouterProps {}

export const SitebuilderRouter: React.FC<IRouterProps> = (props) => {
    const { accessModel, builderType } = useAccess();
    if (!accessModel.id) {
        return (
            <Routes>
                <Route element={<AuthView />} path={"*"} />
            </Routes>
        );
    }
    if (builderType === "IFRAME") {
        return (
            <IFrameContextProvider>
                <IFrameLayout>
                    <Routes>
                        <Route path={"/order"} element={<IFrameProductsView />} />
                        <Route path={"/info"} element={<FrameInfoView />} />
                        <Route path={"/cart"} element={<FrameCartView />} />
                        <Route path={"/profile"} element={<FrameProfileIndex />} />
                        <Route path={"*"} element={<Navigate to={"/order"} replace />} />
                    </Routes>
                </IFrameLayout>
            </IFrameContextProvider>
        );
    }
    if (builderType === "APP") {
        return <AppRoutes />;
    }

    //Render this only for authenticated users later
    return (
        <ActiveFontCollectionContextProvider>
            <CartContextProvider>
                <ProductsContextProvider>
                    <ChangeHistoryContextProvider>
                        <SideMenuContextProvider>
                            <DomContextProvider>
                                <WidgetsContextProvider>
                                    <TemplateContextProvider>
                                        <EditorContextProvider>
                                            <WidgetEditorContextProvider>
                                                <ResetHistoryContextProvider>
                                                    <ComponentDnDContextProvider>
                                                        <SideMenuView>
                                                            <RouteMapper />
                                                        </SideMenuView>
                                                    </ComponentDnDContextProvider>
                                                </ResetHistoryContextProvider>
                                            </WidgetEditorContextProvider>
                                        </EditorContextProvider>
                                    </TemplateContextProvider>
                                </WidgetsContextProvider>
                            </DomContextProvider>
                        </SideMenuContextProvider>
                    </ChangeHistoryContextProvider>
                </ProductsContextProvider>
            </CartContextProvider>
        </ActiveFontCollectionContextProvider>
    );
};
