import { FC } from "react";
import { useEnv } from "../../../Contexts/Environment.context";
import { useApp } from "../Provider/App.context";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import RestIcon from "../../../Assets/map_restaurant.png";

interface IAppRestMapProps {}

export const AppRestMap: FC<IAppRestMapProps> = (props) => {
    const env = useEnv();
    const { data, dataSource } = useApp();

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: env.GOOGLE_API_KEY
    });

    const content = data[dataSource].map;

    return (
        <div className={"app-info__map-container"}>
            {isLoaded ? (
                <GoogleMap
                    center={{
                        lat: parseFloat(content.lat),
                        lng: parseFloat(content.lng)
                    }}
                    zoom={parseInt(content.zoom)}
                    mapContainerClassName={"app-info__map-container--map"}
                >
                    <Marker
                        icon={RestIcon}
                        position={{
                            lat: parseFloat(content.lat),
                            lng: parseFloat(content.lng)
                        }}
                    />
                </GoogleMap>
            ) : null}
        </div>
    );
};
