import { ILang } from "../../../../../Interfaces/ILang.type";
import { clone } from "lodash";
import { useDom } from "../../../../DomTracker/Controller/DomTracker.provider";
import { useState } from "react";
import { ProductsDAO } from "../DAO/Products.dao";
import { useProducts } from "../Context/ProductsContext.provider";
import { IDomElement } from "../../../../DomTracker/Interfaces/IDomTypes.interface";
import { ProductModel } from "../Models/Product.model";
import { ProductsIndex } from "../View/Products.index";
import { ISelectorData } from "../Interfaces/ISelectorData.type";
import { useTranslation } from "react-i18next";
import { IProductSelector } from "../Interfaces/IProductSelector.interface";
import { IProductsContent } from "../../../Interfaces/IProductComponent.interface";
import { ProductCategoryModel } from "../Models/ProductCategory.model";
import { useTimeout, useAfterTriggerChanged, useDidMount } from "xa-generics";

export interface IProductsControllerProps extends IDomElement<IProductsContent> {}

export const ProductsController: React.FC<IProductsControllerProps> = (props) => {
    const { t } = useTranslation<ILang>();

    const { productBySection } = useDom();
    const filteredIds = productBySection[props.sectionUUID];
    //INFO if the category has a parent_id, it shouldn't be set as the selectedCategory
    //unless there are no more categories that refer to the id of the clicked category.
    //Instead, set it as a temporary 'mainCategory'. Basically, the categories can be infinitely recursive.
    const [selector, setSelector] = useState<IProductSelector>({
        category: null,
        product: null
    });
    const [filteredProducts, setFilteredProducts] = useState<ProductModel[]>([]);
    const [isFilterPending, setIsFilterPending] = useState<boolean>(false);
    const [showOptionWarn, setShowOptionWarn] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>("");
    const [error, setError] = useState<string>("");

    const { setTm } = useTimeout();
    const { setLoading, categories } = useProducts(filteredIds);

    const setSelectorData = (data: ISelectorData): void => {
        setSelector((current) => {
            const state = clone(current);
            if (data instanceof ProductCategoryModel) {
                state.category = data;
                state.product = null;
            }
            if (data instanceof ProductModel) {
                state.product = data;
            }
            if (data && "category" in data && "product" in data) {
                state.category = data.category;
                state.product = data.product;
            }
            return state;
        });
    };

    const unsetSelector = (unsetType: keyof IProductSelector): void => {
        setSelector((current) => {
            const state = clone(current);
            switch (unsetType) {
                case "category":
                    state.category = null;
                    state.product = null;
                    break;
                case "product":
                    state.product = null;
                    break;
            }
            return state;
        });
    };

    useDidMount(() => {
        if (props.content.layoutType !== "default_layout") return;
        const category = categories.find((category) => !category.has_child); //Find the first category that is a final category.
        if (category) setSelectorData(category);
    });

    useAfterTriggerChanged(() => {
        if (!filter) {
            setIsFilterPending(false);
            setFilteredProducts([]);
            unsetSelector("category");
        } else {
            setIsFilterPending(true);
            setTm(
                () => {
                    setLoading(
                        ProductsDAO.loadProductsByName(filter)
                            .then((products) => setFilteredProducts(products))
                            .catch(() => setError(t<ILang>("error_product_search")))
                            .finally(() => {
                                setLoading(false);
                                setIsFilterPending(false);
                            })
                    );
                },
                1000,
                "product-name-filter"
            );
        }
    }, [filter]);

    return (
        <ProductsIndex
            {...props}
            error={error}
            filter={filter}
            selector={selector}
            setError={setError}
            setFilter={setFilter}
            filteredIds={filteredIds}
            unsetSelector={unsetSelector}
            showOptionWarn={showOptionWarn}
            setSelectorData={setSelectorData}
            isFilterPending={isFilterPending}
            filteredProducts={filteredProducts}
            setShowOptionWarn={setShowOptionWarn}
            setFilteredProducts={setFilteredProducts}
        />
    );
};
