import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { Height } from "../../../UI/InputGroups/Height/Height.view";
import { Margins } from "../../../UI/InputGroups/Margins/Margins.view";
import { Grouper } from "../../../UI/Grouper/Grouper.view";
import { Paddings } from "../../../UI/InputGroups/Paddings/Paddings.view";
import { ImageInput } from "../../../UI/InputFields/ImageInput/Controller/ImageInput.controller";
import { useWidgets } from "../../../Widgets/Controller/WidgetsContext.provider";
import { Untransform } from "../../../../Utils/UntransformComponentContent.util";
import { IWidgetGroups } from "../../../Widgets/Interfaces/IWidgetGroups.type";
import { CheckboxInput } from "../../../UI/InputFields/Checkbox/Checkbox.view";
import { useTranslation } from "react-i18next";
import { INavbarSettings } from "../../../WidgetModules/Navbar/Interfaces/INavbarWidget.interface";
import { INavbarEditorProps } from "./NavbarEditor.index";
import FontColor from "../../../UI/InputGroups/FontInputs/FontColor.input";
import Font from "../../../UI/InputGroups/FontInputs/Font.view";

interface INavbarNameEditorViewProps extends INavbarEditorProps {}

export const NavbarNameEditorView: FC<INavbarNameEditorViewProps> = (props) => {
    const {
        i18n: { language }
    } = useTranslation<ILang>();
    const { size } = useDom();
    const {
        widgets: {
            Navbar: { draftSettings }
        }
    } = useWidgets();

    const widgetProp: IWidgetGroups = "navbarName";
    const v3LogoProp: IWidgetGroups = "navbarLogoV3";
    const logoProp: IWidgetGroups = "navbarLogo";

    if (props.subKey !== widgetProp && props.subKey !== v3LogoProp && props.subKey !== logoProp)
        return null;

    const model = new Untransform(props.editor.draftSettings.navbarName || {}, size, language);
    const defaults = model.toJSON();
    const units = model.getUnits();

    const v3LogoModel = new Untransform(
        props.editor.draftSettings.navbarLogoV3 || {},
        size,
        language
    );
    const v3LogoDefaults = v3LogoModel.toJSON();
    const v3LogoUnits = v3LogoModel.getUnits();

    return (
        <>
            {(props.navVersion === "default" || props.navVersion === "v3") && (
                <Grouper<IWidgetGroups> groupName={"navbarName"} title={widgetProp}>
                    <Paddings
                        onBlur={(data) => props.handleChange(widgetProp, data)}
                        onReset={(keys) => props.onReset(widgetProp, keys)}
                        defaultValues={defaults}
                        units={units}
                    />

                    <FontColor
                        onBlur={(data) => props.handleChange(widgetProp, data)}
                        onReset={(keys) => props.onReset(widgetProp, keys)}
                        defaultValues={defaults}
                        units={units}
                    />

                    <Font
                        onBlur={(data) => props.handleChange(widgetProp, data)}
                        onReset={(keys) => props.onReset(widgetProp, keys)}
                        defaultValues={defaults}
                        units={units}
                    />

                    <CheckboxInput<INavbarSettings>
                        id={"isNameHidden"}
                        value={draftSettings.isNameHidden || false}
                        onBlur={(data) => props.handleChange("isNameHidden", data)}
                    />
                </Grouper>
            )}

            <Grouper<IWidgetGroups> groupName={logoProp} title={logoProp}>
                <ImageInput
                    id={logoProp}
                    onChange={(field, value) => props.handleChange(logoProp, { field, value })}
                    value={draftSettings.navbarLogo || null}
                    noLabel
                />

                {props.navVersion === "v3" && (
                    <>
                        <Paddings
                            onBlur={(data) => props.handleChange(v3LogoProp, data)}
                            onReset={(keys) => props.onReset(v3LogoProp, keys)}
                            defaultValues={v3LogoDefaults}
                            units={v3LogoUnits}
                        />

                        <Margins
                            onBlur={(data) => props.handleChange(v3LogoProp, data)}
                            onReset={(keys) => props.onReset(v3LogoProp, keys)}
                            defaultValues={v3LogoDefaults}
                            units={v3LogoUnits}
                        />

                        <Height
                            onBlur={(data) => props.handleChange(v3LogoProp, data)}
                            onReset={(keys) => props.onReset(v3LogoProp, keys)}
                            defaultValues={v3LogoDefaults}
                            units={v3LogoUnits}
                        />
                    </>
                )}
            </Grouper>
        </>
    );
};
