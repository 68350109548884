import { ProductCategoryModel } from "../Models/ProductCategory.model";
import { IProductsIndexProps } from "./Products.index";
import { useTranslation } from "react-i18next";
import { useRestaurant } from "../../../../../Contexts/Restaurant.context";
import { ClientButton } from "../../Button/ClientButton.view";
import { useProducts } from "../Context/ProductsContext.provider";
import { ArrowLeft } from "@carbon/icons-react";
import { ILang } from "../../../../../Interfaces/ILang.type";
import { FC } from "react";

interface ICategoryBackButtonViewProps extends IProductsIndexProps {}

export const CategoryBackButtonView: FC<ICategoryBackButtonViewProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { catObject } = useProducts();
    const { config } = useRestaurant();

    if (!props.selector.category) return null;

    const recursiveNameResolve = (baseObj: ProductCategoryModel, name?: string): string => {
        let baseName = name || baseObj.name;

        if (baseObj.parent_id) {
            baseName = `${catObject[baseObj.parent_id].name} / ${baseName}`;
            baseName = recursiveNameResolve(catObject[baseObj.parent_id], baseName);
        }
        return baseName;
    };

    return (
        <div className="products__heading">
            <div
                className="products__heading-container"
                style={{ borderBottomColor: config.main_btn_bg_color }}
            >
                <span className="products__heading--name">
                    {recursiveNameResolve(props.selector.category!)}
                </span>
                <div className="products__heading--button-container">
                    <ClientButton
                        isSecondary
                        LeftIcon={ArrowLeft}
                        isDisabled={!props.selector.category}
                        onClick={() => {
                            if (props.selector.category!.parent_id) {
                                const category = catObject[props.selector.category!.parent_id];
                                props.setSelectorData(category);
                            } else props.unsetSelector("category");
                        }}
                    >
                        {t<ILang>("go_back")}
                    </ClientButton>
                </div>
            </div>
        </div>
    );
};
