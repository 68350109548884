import { FC } from "react";
import { axios } from "xa-generics";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { useEditor } from "../../../Editor/Controller/EditorContext.provider";
import { useWidgets } from "../../../Widgets/Controller/WidgetsContext.provider";
import { CalcContrast } from "sitebuilder-common";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import { SaveLangCookie } from "../../../../Utils/SaveLangCookie.util";
import { INavbarSettings } from "../Interfaces/INavbarWidget.interface";
import { NavbarLinkStyles } from "../Static/NavbarLinkStyles.static";
import { GetWidgetOverlay } from "../../Util/WidgetOverlay.util";
import { ParseComponentStyle } from "sitebuilder-common";
import WidgetOverlay from "../../../Widgets/View/WidgetOverlay.view";
import { useWidgetEditor } from "../../../Editor/Controller/WidgetEditorContext.provider";

interface INavbarLangPickerProps {
    navbarTopColor?: string;
}

export const NavbarLangPicker: FC<INavbarLangPickerProps> = (props) => {
    const { size } = useDom();
    const { widgets } = useWidgets();
    const { setEditor } = useEditor();
    const { i18n } = useTranslation<ILang>();
    const { setWidgetEditor } = useWidgetEditor();
    const { restaurant, config } = useRestaurant();

    const { navbarLangPicker } = widgets.Navbar.draftSettings;

    const { showOverlay, ...events } = GetWidgetOverlay<INavbarSettings>(
        "Navbar",
        "navbarLangPicker"
    );

    let langStyles = ParseComponentStyle(navbarLangPicker, NavbarLinkStyles, size) || {};

    const getLangButton = (lang: string): JSX.Element => {
        const cls: string[] = ["navbar__top__languages--button"];
        let clonedStyles = { ...langStyles };

        if (lang === i18n.language) {
            cls.push("active-language");
            if (!clonedStyles.color) {
                clonedStyles.color = config.main_btn_bg_color;
            }
        }

        if (!clonedStyles.color && props.navbarTopColor) {
            if (config.is_dark_theme) {
                if (CalcContrast(props.navbarTopColor, "#171717") > 50)
                    clonedStyles.color = "#000000";
                else clonedStyles.color = "#ffffff";
            } else {
                if (CalcContrast(props.navbarTopColor, "#ffffff") < 50)
                    clonedStyles.color = "#000000";
                else clonedStyles.color = "#ffffff";
            }
        }

        return (
            <button
                className={cls.join(" ")}
                style={clonedStyles}
                onClick={() => {
                    axios.setLanguage(lang);
                    i18n.changeLanguage(lang);
                    SaveLangCookie(lang);
                    setEditor(null);
                    setWidgetEditor({ group: null, subKey: "" });
                }}
            >
                {lang.toUpperCase()}
            </button>
        );
    };

    return (
        <div className="navbar__top__languages" {...events}>
            {showOverlay && (
                <WidgetOverlay<INavbarSettings>
                    align={"right"}
                    parent={widgets.Navbar}
                    subKey={"navbarLangPicker"}
                />
            )}
            {getLangButton(restaurant.primary_language)}
            {restaurant.secondary_language && (
                <>
                    <span className="navbar__top__languages--separator">/</span>
                    {getLangButton(restaurant.secondary_language)}
                </>
            )}
        </div>
    );
};
