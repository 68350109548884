export const Destructure = <Fields extends object>(
    defaults: Fields,
    editorDefaults: any,
    unit?: any
) => {
    const editor: Partial<Record<keyof Fields, any>> = {};
    for (let key in defaults) {
        const field = key as keyof Fields;
        editor[field] = editorDefaults[field] || defaults[field];
    }
    if ("unit" in defaults) {
        (editor as any).unit = unit || (defaults as any).unit;
    }
    return editor as Required<Fields>;
};
