import { useTranslation } from "react-i18next";
import { useInputGroup } from "./InputGroup.provider";
import { IInputGroups } from "../../../Interfaces/IInputGroups.type";
import { ILang } from "../../../Interfaces/ILang.type";
import { FC } from "react";

export interface IInputGrouperProps {
    groupKey: IInputGroups;
    defaultOpen?: true;
    labelKey: ILang;
    Icon?: FC<any>;
    Controls?: JSX.Element;
    Reset?: JSX.Element;
}

const InputGrouper: FC<IInputGrouperProps> = ({ Icon, Controls, Reset, ...props }) => {
    const { t } = useTranslation<ILang>();
    const { active, setActiveGroup } = useInputGroup();

    const classes: string[] = ["input-grouper__form"];
    const containerClasses: string[] = ["input-grouper"];
    const isOpen = active === props.groupKey;
    if (isOpen) {
        classes.push("inputs-visible");
        if (Controls) containerClasses.push("input-grouper-open", "active-group-label");
        else containerClasses.push("active-group-label");
    }

    return (
        <div className={containerClasses.join(" ")}>
            <div className="input-grouper__row">
                <div className="input-grouper__heading">
                    <div className="input-grouper__heading--reset">{Reset}</div>
                    <div
                        className={"input-grouper__heading--label"}
                        onClick={() => {
                            if (isOpen) setActiveGroup("");
                            else setActiveGroup(props.groupKey);
                        }}
                    >
                        {Icon && (
                            <div className="heading-icon">
                                <Icon />
                            </div>
                        )}
                        {t<ILang>(props.labelKey)}
                    </div>
                </div>
                {Controls && isOpen && <div className="input-grouper__controls">{Controls}</div>}
            </div>
            <div className={classes.join(" ")}>{props.children}</div>
        </div>
    );
};

export default InputGrouper;
