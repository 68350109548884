import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { ISetState } from "xa-generics";
import { useEditor } from "../../Controller/EditorContext.provider";
import { DraftModel } from "../../../DomTracker/Model/Draft.model";
import { PageDataModel } from "../../../DomTracker/Model/PageData.model";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import { DomlessPageModel } from "../../../DomTracker/Model/DomlessPage.model";
import { Edit, Settings, TrashCan } from "@carbon/icons-react";
import { IPageListElementViewProps } from "./PageList.element";

interface IPageControlsProps extends IPageListElementViewProps {
    page: PageDataModel;
    draft: DraftModel | null;
    setShowDeleteConfirm: ISetState<boolean>;
}

export const PageControls: FC<IPageControlsProps> = ({ page, ...props }) => {
    const { t } = useTranslation<ILang>();
    const { setPageEditor } = useEditor();
    const { clonePage } = useDom();
    const { restaurant } = useRestaurant();

    return (
        <div className={"page__controls"}>
            {(!page.page_type || props.draft) && (
                <div
                    className="svg delete-svg"
                    title={t<ILang>(props.draft ? "delete_draft" : "delete_page_icon")}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.setShowDeleteConfirm(true);
                    }}
                >
                    <TrashCan />
                </div>
            )}
            {!props.draft && (
                <div
                    className="svg edit-svg"
                    title={t<ILang>("page_seo_icon")}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setPageEditor(new DomlessPageModel(page, restaurant));
                    }}
                >
                    <Settings />
                </div>
            )}
            {!page.draft && (
                <div
                    className="svg edit-svg"
                    title={t<ILang>("page_edit_icon")}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        clonePage(page);
                        props.setShowCMPS(true);
                    }}
                >
                    <Edit />
                </div>
            )}
        </div>
    );
};
