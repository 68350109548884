import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { IRegForm } from "../Interfaces/IRegForm.interface";
import { TextInput } from "../../../UI/InputFields/TextInput/TextInput.view";
import { ErrorHandler } from "xa-error-with-lang";
import { useTranslation } from "react-i18next";
import { User, View, ViewOff } from "@carbon/icons-react";
import { IWidgetRegistrationProps } from "../Controller/Registration.controller";
import { GenericOnChange, IError, ILoading, ISetState } from "xa-generics";
import Loading from "../../../UI/Loading/Loading.view";
import Modal from "../../../UI/Modal/Modal.view";

interface IRegistrationViewProps extends IWidgetRegistrationProps {
    setShowPw: ISetState<boolean>;
    setForm: ISetState<IRegForm>;
    setError: ISetState<IError>;
    loading: ILoading;
    showPw: boolean;
    form: IRegForm;
    error: IError;
}

export const RegistrationView: FC<IRegistrationViewProps> = (props) => {
    const { t } = useTranslation<ILang>();

    const onChange = (id: keyof IRegForm, value: any): void => {
        GenericOnChange(id, value, props.setForm);
    };

    return (
        <Modal
            submitIcon={User}
            appLogin={props.app}
            onClose={props.onClose}
            onSubmit={props.onClose}
            heading={t<ILang>("registration")}
            submitText={t<ILang>("registration")}
        >
            <form
                className="registration"
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <div className="registration__block details-block">
                    <h4 className="registration__title no-space">{t<ILang>("my_data")}</h4>
                    <TextInput<IRegForm>
                        required
                        id={"email"}
                        className={"email"}
                        type={"email"}
                        value={props.form.email}
                        onChange={(value) => onChange("email", value)}
                    />
                    <TextInput<IRegForm>
                        required
                        id={"first_name"}
                        className={"first_name"}
                        value={props.form.first_name}
                        onChange={(value) => onChange("first_name", value)}
                    />
                    <TextInput<IRegForm>
                        required
                        id={"last_name"}
                        className={"last_name"}
                        value={props.form.last_name}
                        onChange={(value) => onChange("last_name", value)}
                    />
                    <TextInput<IRegForm>
                        required
                        id={"phone_number"}
                        className={"phone_number"}
                        value={props.form.phone_number}
                        onChange={(value) => onChange("phone_number", value)}
                    />
                    <TextInput<IRegForm>
                        required
                        id={"password"}
                        className={"password"}
                        type={props.showPw ? "text" : "password"}
                        value={props.form.password}
                        unit={props.showPw ? <ViewOff /> : <View />}
                        onChange={(value) => onChange("password", value)}
                        onUnitClick={() => props.setShowPw(!props.showPw)}
                    />
                </div>
                <div className="registration__block address-block">
                    <h4 className="registration__title">{t<ILang>("delivery_address")}</h4>
                    <TextInput<IRegForm>
                        required
                        id={"city"}
                        className={"city"}
                        value={props.form.city}
                        onChange={(value) => onChange("city", value)}
                    />
                    <TextInput<IRegForm>
                        required
                        id={"street"}
                        className={"street"}
                        value={props.form.street}
                        onChange={(value) => onChange("street", value)}
                    />
                    <TextInput<IRegForm>
                        required
                        id={"premise_number"}
                        className={"premise_number"}
                        value={props.form.premise_number}
                        onChange={(value) => onChange("premise_number", value)}
                    />
                    <TextInput<IRegForm>
                        id={"floor"}
                        className={"floor"}
                        value={props.form.floor}
                        onChange={(value) => onChange("floor", value)}
                    />
                    <TextInput<IRegForm>
                        id={"door_number"}
                        className={"door_number"}
                        value={props.form.door_number}
                        onChange={(value) => onChange("door_number", value)}
                    />
                    <TextInput<IRegForm>
                        id={"doorbell"}
                        className={"doorbell"}
                        value={props.form.doorbell}
                        onChange={(value) => onChange("doorbell", value)}
                    />
                </div>
                <button type={"submit"} style={{ display: "none" }}></button>
            </form>
            <Loading loading={props.loading} />
            <ErrorHandler error={props.error} resetError={() => props.setError(null)} />
        </Modal>
    );
};
