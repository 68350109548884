import Overlay from "../../View/Overlay.view";
import { map } from "lodash";
import { ILang } from "../../../../Interfaces/ILang.type";
import { DnDEvents } from "../../Util/DND.util";
import { IDomElement } from "../../../DomTracker/Interfaces/IDomTypes.interface";
import { useTranslation } from "react-i18next";
import { ParseComponentStyle } from "sitebuilder-common";
import { IBulletListContent } from "../../Interfaces/IBulletListComponent.interface";
import { BulletListTypeStyles } from "../../Static/BulletListStyles.static";

export interface IDomBulletListProps extends IDomElement<IBulletListContent> {}

const DomBulletList: React.FC<IDomBulletListProps> = (props) => {
    const { i18n } = useTranslation<ILang>();
    const { refs, size, id, showOverlay, ...events } = DnDEvents<IBulletListContent>(
        props,
        "ELEMENT"
    );

    let style = ParseComponentStyle(props.content, BulletListTypeStyles, size);

    const renderList = (): JSX.Element | null => {
        if (!props.content?.list) return null;

        return (
            <ul>
                {map(props.content?.list, (item, i) => (
                    <li key={`${item.id}-${i}-list`}>{item.value[i18n.language] || ""}</li>
                ))}
            </ul>
        );
    };

    return (
        <div
            id={id}
            {...events}
            className="dom__bullet-list"
            ref={refs.current[props.uuid]}
            style={style}
        >
            {showOverlay && <Overlay parent={props} />}
            {renderList() || (
                <ul>
                    <li>Helytartó...</li>
                    <li>Placeholder...</li>
                </ul>
            )}
        </div>
    );
};

export default DomBulletList;
