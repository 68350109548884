import { Email, Login, Password } from "@carbon/icons-react";
import { useTranslation } from "react-i18next";
import { ErrorHandler } from "xa-error-with-lang";
import { useAccess } from "../../../Contexts/Access.context";
import { TextInput } from "../../UI/InputFields/TextInput/TextInput.view";
import { useForm } from "../../UseForm/UseForm.provider";
import { Regexes } from "xa-generics";
import { ILang } from "../../../Interfaces/ILang.type";
import { Img } from "xa-image";
import { FC } from "react";
import Loading from "../../UI/Loading/Loading.view";
import Button from "../../UI/Button/Button.view";
import Logo from "../../../Assets/logo.png";

interface IAuthViewProps {}

export const AuthView: FC<IAuthViewProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const access = useAccess();
    const form = useForm<{ email: string; password: string }, true>({
        editor: {
            email: "",
            password: ""
        },
        initialRules: {
            email: {
                pattern: {
                    message: t<ILang>("invalid_email_format"),
                    value: Regexes.Email
                }
            },
            password: true
        }
    });

    return (
        <div className="login-page">
            <div className="login-page__header">
                <div className="login-page__header--block">
                    <Img src={Logo} alt="ONEMINORDER" />
                    <div className="login-page__header--text">{t<ILang>("login_header_title")}</div>
                </div>
            </div>
            <div className="login-page__container">
                <h1 className="login-page__title">{t<ILang>("login")}</h1>
                <form
                    id="login"
                    className="login-page__form"
                    onSubmit={form.handleSubmit(access.login)}
                >
                    <div className="login-input">
                        <TextInput
                            errors={form.fieldErrors}
                            className="login-page__form--input-field"
                            placeholder={"email@gmail.com"}
                            onChange={form.handleChange}
                            value={form.editor.email}
                            id={"email"}
                        />
                        <div className="login-input-icon">
                            <Email />
                        </div>
                    </div>

                    <div className="login-input">
                        <TextInput
                            type="password"
                            errors={form.fieldErrors}
                            placeholder={"*********************"}
                            className="login-page__form--input-field"
                            onChange={form.handleChange}
                            value={form.editor.password}
                            id={"password"}
                        />
                        <div className="login-input-icon">
                            <Password />
                        </div>
                    </div>

                    <Button
                        Icon={Login}
                        size="FIELD"
                        className="login-page__form--submit"
                        type="submit"
                    >
                        {access.loading ? (
                            <Loading loading={access.loading} isAlternate isInline />
                        ) : (
                            t("login")
                        )}
                    </Button>
                </form>
                <ErrorHandler error={access.error} />
            </div>
            <div className="login-footer">OneMinorder © {new Date().getFullYear()}</div>
        </div>
    );
};
