import { IFooterStyles } from "../Interfaces/IFooterDetails.interface";
import {
    BackgroundStyles,
    BoxShadowSyles,
    ColorStyles
} from "../../../DomMapper/Static/GroupsStyles.static";

interface IFooter extends IFooterStyles<Required<true>> {}

export const FooterStyles: IFooter = {
    ...BackgroundStyles,
    ...BoxShadowSyles,
    ...ColorStyles
};
