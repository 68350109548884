import { useTranslation } from "react-i18next";
import { ILoading } from "xa-generics";
import { ILang } from "../../../Interfaces/ILang.type";

export interface ILoadingProps {
    message?: ILang;
    useSpacing?: boolean;
    isAbsolute?: boolean;
    size?: "sm" | "md" | "lg";
    loading?: ILoading | string;
    isExternalConditional?: boolean;
    itemIndex?: number;
    isAlternate?: boolean;
    isInline?: boolean;
}

const Loading: React.FC<ILoadingProps> = (props) => {
    const { t } = useTranslation<ILang>();
    if (!props.loading && !props.isExternalConditional) return null;

    let loadMessage: string | undefined;
    if (typeof props.loading === "object" && props.loading) {
        //If the loading is index-promise type and the 'itemIndex' from a list item
        //doesn't match the loading state index, return with null.
        if ("index" in props.loading && props.loading.index !== props.itemIndex) return null;
        if ("reason" in props.loading) loadMessage = props.loading.reason;
    }

    const classes: string[] = ["loader-container"];
    classes.push(`loading-size-${props.size || "sm"}`);

    if (props.isAlternate) classes.push("alternate-loading");
    if (props.useSpacing) classes.push("spaced-container");
    if (props.isAbsolute) classes.push("absolute-container");
    if (props.isInline) classes.push("inline-loading");
    else classes.push("loading-size-sm");

    return (
        <div className={classes.join(" ")}>
            <div className="loader"></div>
            {loadMessage}
            {!loadMessage && !props.isInline && `${t<ILang>(props.message || "loading")}...`}
        </div>
    );
};

export default Loading;
