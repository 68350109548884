import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { Border } from "../../../UI/InputGroups/Border/Border.view";
import { Grouper } from "../../../UI/Grouper/Grouper.view";
import { ImageInput } from "../../../UI/InputFields/ImageInput/Controller/ImageInput.controller";
import { useWidgets } from "../../../Widgets/Controller/WidgetsContext.provider";
import { Untransform } from "../../../../Utils/UntransformComponentContent.util";
import { IWidgetGroups } from "../../../Widgets/Interfaces/IWidgetGroups.type";
import { CheckboxInput } from "../../../UI/InputFields/Checkbox/Checkbox.view";
import { useTranslation } from "react-i18next";
import { IFooterSettings } from "../../../WidgetModules/Footer/Interfaces/IFooterWidget.interface";
import { IBlur, IFieldBlur } from "../../Utils/EditorHook.util";
import { IGenericCSSFields } from "sitebuilder-common";
import { IFooterEditorProps } from "./FooterEditor.index";
import BackgroundGroupView from "../../../UI/InputGroups/Background/BackgroundGroup.view";
import TextShadowView from "../../../UI/InputGroups/TextShadow/TextShadow.view";
import FontColor from "../../../UI/InputGroups/FontInputs/FontColor.input";
import Font from "../../../UI/InputGroups/FontInputs/Font.view";

interface IFooterTopEditorViewProps extends IFooterEditorProps {
    handleChange: (id: keyof IFooterSettings, data: IFieldBlur<any> | IBlur<any>) => void;
    onReset: (id: keyof IFooterSettings, keys: (keyof IGenericCSSFields)[]) => void;
}

export const FooterTopEditorView: FC<IFooterTopEditorViewProps> = (props) => {
    const {
        i18n: { language }
    } = useTranslation<ILang>();
    const { size } = useDom();
    const {
        widgets: {
            Footer: { draftSettings }
        }
    } = useWidgets();

    const widgetProp: IWidgetGroups = "footerTop";
    const logoProp: IWidgetGroups = "footerLogo";
    const nameProp: IWidgetGroups = "footerName";
    if (props.subKey !== widgetProp && props.subKey !== logoProp && props.subKey !== nameProp) {
        return null;
    }

    const model = new Untransform(props.editor.draftSettings.footerTop || {}, size, language);
    const defaults = model.toJSON();
    const units = model.getUnits();

    const nameModel = new Untransform(props.editor.draftSettings.footerName || {}, size, language);
    const nameDefaults = nameModel.toJSON();
    const nameUnits = nameModel.getUnits();

    return (
        <>
            <Grouper<IWidgetGroups> groupName={logoProp} title={logoProp}>
                <ImageInput
                    id={logoProp}
                    onChange={(field, value) => props.handleChange(logoProp, { field, value })}
                    value={draftSettings.footerLogo || null}
                    noLabel
                />
            </Grouper>

            <Grouper<IWidgetGroups> groupName={widgetProp} title={widgetProp}>
                <BackgroundGroupView
                    onBlur={(data) => props.handleChange(widgetProp, data)}
                    onReset={(keys) => props.onReset(widgetProp, keys)}
                    defaultValues={defaults}
                    units={units}
                />

                <FontColor
                    onBlur={(data) => props.handleChange(widgetProp, data)}
                    onReset={(keys) => props.onReset(widgetProp, keys)}
                    defaultValues={defaults}
                    units={units}
                />

                <Border
                    onBlur={(data) => props.handleChange(widgetProp, data)}
                    onReset={(keys) => props.onReset(widgetProp, keys)}
                    defaultValues={defaults}
                    units={units}
                />
            </Grouper>

            <Grouper<IWidgetGroups> groupName={nameProp} title={nameProp}>
                <FontColor
                    onBlur={(data) => props.handleChange(nameProp, data)}
                    onReset={(keys) => props.onReset(nameProp, keys)}
                    defaultValues={nameDefaults}
                    units={nameUnits}
                />

                <Font
                    onBlur={(data) => props.handleChange(nameProp, data)}
                    onReset={(keys) => props.onReset(nameProp, keys)}
                    defaultValues={nameDefaults}
                    units={nameUnits}
                />

                <TextShadowView
                    onBlur={(data) => props.handleChange(nameProp, data)}
                    onReset={(keys) => props.onReset(nameProp, keys)}
                    defaultValues={nameDefaults}
                    units={nameUnits}
                />

                <CheckboxInput<IFooterSettings>
                    id={"isNameHidden"}
                    value={
                        draftSettings.isNameHidden === undefined ? true : draftSettings.isNameHidden
                    }
                    onBlur={(data) => props.handleChange("isNameHidden", data)}
                />
            </Grouper>
        </>
    );
};
