import { FC } from "react";
import { useApp } from "../../Provider/App.context";
import { TextInput } from "../../../UI/InputFields/TextInput/TextInput.view";
import { ImageInput } from "../../../UI/InputFields/ImageInput/Controller/ImageInput.controller";
import { SelectInput } from "../../../UI/InputFields/Select/SelectInput.view";
import { CheckboxInput } from "../../../UI/InputFields/Checkbox/Checkbox.view";
import { AppEditorGroup } from "../AppEditorGroup.view";
import { BG_SIZE_OPTIONS } from "../../../UI/InputGroups/BackgroundImage/BGSizeOptions.static";
import { BG_POSITION_OPTIONS } from "../../../UI/InputGroups/BackgroundImage/BGPositionOptions.static";
import { BG_ATTACHMENT_OPTIONS } from "../../../UI/InputGroups/BackgroundImage/BGAttachmentOptions.static";
import { PRODUCT_FONT_SIZE_OPTIONS } from "../../Static/ProductFontSizes.static";
import ColorInput from "../../../UI/InputFields/ColorInput/ColorInput.view";

interface IAppProductsFormProps {}

export const AppProductsForm: FC<IAppProductsFormProps> = (props) => {
    const { forms } = useApp<"product">();
    const { editor, fieldErrors, handleChange } = forms.product;

    return (
        <AppEditorGroup groupKey={"product"} nameKey={"iframe_products"}>
            <SelectInput
                id={"version"}
                errors={fieldErrors}
                labelText={"LAYOUT"}
                useTranslationOnName
                onChange={handleChange}
                value={editor.version}
                options={[
                    { id: "V1", name: "V1" },
                    { id: "V2", name: "V2" }
                ]}
            />
            {editor.version === "V2" && (
                <>
                    <ColorInput
                        isClearable
                        withValueSync
                        errors={fieldErrors}
                        id={"v2CatBgColor"}
                        onChange={handleChange}
                        labelText={"cat_bg_color"}
                        value={editor.v2CatBgColor}
                    />
                    <ColorInput
                        isClearable
                        id={"v2CatColor"}
                        withValueSync
                        errors={fieldErrors}
                        onChange={handleChange}
                        value={editor.v2CatColor}
                        labelText={"cat_color"}
                    />
                </>
            )}
            <ImageInput
                onChange={handleChange}
                value={editor.backgroundImage}
                id={"backgroundImage"}
            />
            <SelectInput
                isClearable
                errors={fieldErrors}
                useTranslationOnName
                id={"backgroundSize"}
                onChange={handleChange}
                options={BG_SIZE_OPTIONS}
                value={editor.backgroundSize}
            />
            <SelectInput
                isClearable
                errors={fieldErrors}
                useTranslationOnName
                onChange={handleChange}
                id={"backgroundPosition"}
                options={BG_POSITION_OPTIONS}
                value={editor.backgroundPosition}
            />
            <SelectInput
                isClearable
                errors={fieldErrors}
                useTranslationOnName
                onChange={handleChange}
                id={"backgroundAttachment"}
                options={BG_ATTACHMENT_OPTIONS}
                value={editor.backgroundAttachment}
            />
            <CheckboxInput
                errors={fieldErrors}
                id={"backgroundRepeat"}
                onChange={handleChange}
                labelText={"backgroundRepeat"}
                value={editor.backgroundRepeat}
            />
            <hr />
            <ColorInput
                isClearable
                withValueSync
                errors={fieldErrors}
                id={"backgroundColor"}
                onChange={handleChange}
                labelText={"product_tile_bg"}
                value={editor.backgroundColor}
            />
            <ColorInput
                isClearable
                id={"color"}
                withValueSync
                errors={fieldErrors}
                value={editor.color}
                onChange={handleChange}
                labelText={"product_tile_color"}
            />
            <ColorInput
                isClearable
                withValueSync
                errors={fieldErrors}
                id={"priceBackground"}
                labelText={"price_bg"}
                onChange={handleChange}
                value={editor.priceBackground}
            />
            <ColorInput
                isClearable
                withValueSync
                id={"priceColor"}
                errors={fieldErrors}
                onChange={handleChange}
                value={editor.priceColor}
                labelText={"price_color"}
            />
            <CheckboxInput
                errors={fieldErrors}
                onChange={handleChange}
                id={"disablePricePadding"}
                value={editor.disablePricePadding}
            />
            <hr />
            <SelectInput
                isClearable
                id={"nameFontSize"}
                value={editor.nameFontSize}
                errors={fieldErrors}
                onChange={handleChange}
                options={PRODUCT_FONT_SIZE_OPTIONS}
            />
            <SelectInput
                isClearable
                id={"nameLineHeight"}
                value={editor.nameLineHeight}
                errors={fieldErrors}
                onChange={handleChange}
                options={PRODUCT_FONT_SIZE_OPTIONS.concat([
                    { id: "2.8", name: "2.8rem" },
                    { id: "3", name: "3rem" },
                    { id: "3.1", name: "3.1rem" },
                    { id: "3.2", name: "3.2rem" },
                    { id: "3.4", name: "3.4rem" },
                    { id: "3.6", name: "3.6rem" }
                ])}
            />
            <SelectInput
                isClearable
                id={"descFontSize"}
                value={editor.descFontSize}
                errors={fieldErrors}
                onChange={handleChange}
                options={PRODUCT_FONT_SIZE_OPTIONS}
            />
            <TextInput
                isNumeric
                id={"descriptionBreakCharacterCount"}
                value={editor.descriptionBreakCharacterCount}
                errors={fieldErrors}
                onChange={handleChange}
            />
            <hr />
            <CheckboxInput
                errors={fieldErrors}
                id={"hideLineUnderName"}
                onChange={handleChange}
                value={editor.hideLineUnderName}
            />
            <CheckboxInput
                errors={fieldErrors}
                id={"imageRenderContain"}
                onChange={handleChange}
                value={editor.imageRenderContain}
            />
            <CheckboxInput
                errors={fieldErrors}
                onChange={handleChange}
                id={"isCategoryDescriptionVisible"}
                value={editor.isCategoryDescriptionVisible}
            />
            <CheckboxInput
                errors={fieldErrors}
                onChange={handleChange}
                id={"hideImageIfNotAvailable"}
                value={editor.hideImageIfNotAvailable}
            />
        </AppEditorGroup>
    );
};
