import { ILang } from "../../../../Interfaces/ILang.type";
import { useMemo } from "react";
import { DnDEvents } from "../../Util/DND.util";
import { IDomElement } from "../../../DomTracker/Interfaces/IDomTypes.interface";
import { useTranslation } from "react-i18next";
import { TextTypeStyles } from "../../Static/TextTypeStyles.static";
import { IHeaderTypeContent } from "../../Interfaces/IHeaderEditor.interface";
import { ParseComponentStyle, TextStyleSeparator } from "sitebuilder-common";
import Overlay from "../../View/Overlay.view";

export interface IDomHeaderProps extends IDomElement<IHeaderTypeContent> {}

const DomHeader: React.FC<IDomHeaderProps> = (props) => {
    const { t, i18n } = useTranslation<ILang>();
    const currentLang = i18n.language;
    const { refs, size, id, showOverlay, ...events } = DnDEvents<IHeaderTypeContent>(
        props,
        "ELEMENT"
    );

    const style = useMemo(
        () => ParseComponentStyle(props.content, TextTypeStyles, size),
        [props.content, size]
    );
    const classes: string[] = ["h", `dom__header--h${props.content.headerType || 1}`];

    const { textStyles, divStyles } = useMemo(() => TextStyleSeparator(style || {}), [style]);

    const Heading = (): JSX.Element => {
        const text = props.content?.[currentLang]?.text || t<ILang>("Header");
        switch (props.content.headerType) {
            case 6:
                return (
                    <h6 style={textStyles} className={classes.join(" ")}>
                        {text}
                    </h6>
                );
            case 5:
                return (
                    <h5 style={textStyles} className={classes.join(" ")}>
                        {text}
                    </h5>
                );
            case 4:
                return (
                    <h4 style={textStyles} className={classes.join(" ")}>
                        {text}
                    </h4>
                );
            case 3:
                return (
                    <h3 style={textStyles} className={classes.join(" ")}>
                        {text}
                    </h3>
                );
            case 2:
                return (
                    <h2 style={textStyles} className={classes.join(" ")}>
                        {text}
                    </h2>
                );
            case 1:
            default:
                return (
                    <h1 style={textStyles} className={classes.join(" ")}>
                        {text}
                    </h1>
                );
        }
    };

    return (
        <div
            ref={refs.current[props.uuid]}
            className="dom__header"
            style={divStyles}
            {...events}
            id={id}
        >
            {showOverlay && <Overlay parent={props} />}
            {Heading()}
        </div>
    );
};

export default DomHeader;
