import { ILang } from "./../Interfaces/ILang.type";
import { TFunction } from "react-i18next";
import { ExtraRegexes } from "../Utils/ExtraRegex.util";

export const NUMERIC_RULE = (t: TFunction<ILang>, IS_INT_ONLY?: boolean) => ({
    pattern: {
        value: IS_INT_ONLY ? ExtraRegexes.IntOrEmpty : ExtraRegexes.FloatOrEmpty,
        message: t<ILang>("must_be_numeric")
    }
});

export const SIZING_RULE = (t: TFunction<ILang>) => ({
    pattern: {
        value: ExtraRegexes.Sizing,
        message: t<ILang>("must_be_numeric")
    }
});
