import { ILang } from "../../../../Interfaces/ILang.type";
import { IFont } from "./IFont.interface";
import { useForm } from "../../../UseForm/UseForm.provider";
import { TextInput } from "../../InputFields/TextInput/TextInput.view";
import { Paragraph } from "@carbon/icons-react";
import { IInputGroup } from "../../../../Interfaces/IInputGroup.interface";
import { ResetValues } from "../../../Editor/Utils/ResetValues.util";
import { SelectInput } from "../../InputFields/Select/SelectInput.view";
import { Destructure } from "../../../../Utils/Destructure.util";
import { FontDefaults } from "./Defaults.static";
import { NUMERIC_RULE } from "../../../../Static/FormRules.static";
import { FONT_OPTIONS } from "sitebuilder-common";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import { CheckIfHasValue } from "../../../../Utils/CheckIfHasValue.util";
import { TextInputWithSize } from "../../InputFields/TextInputWithSize/TextInputWithSize.view";
import ResetFormView from "../../InputGrouper/ResetForm.view";
import InputGrouper from "../../InputGrouper/InputGrouper.view";

export interface IFontProps extends IInputGroup<IFont<any>> {}

const Font: React.FC<IFontProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { currentFont } = useRestaurant();
    const option = FONT_OPTIONS.find((opt) => opt.class === currentFont);

    const form = useForm<IFont, true>({
        editor: Destructure(
            FontDefaults(option?.font || FONT_OPTIONS[1].font),
            props.defaultValues
        ),
        initialRules: {
            lineHeight: NUMERIC_RULE(t),
            letterSpacing: NUMERIC_RULE(t),
            fontSize: NUMERIC_RULE(t),
            fontWeight: NUMERIC_RULE(t)
        }
    });

    const onTextBlur = (data: { field: keyof IFont; value: string }): void => {
        if (form.isFormValid()) {
            if (data.value) props.onBlur(data);
            else props.onReset([data.field]);
        }
    };

    const onSelectBlur = (field: keyof IFont, value: string): void => {
        form.handleChange(field, value);

        if (value) props.onBlur({ field, value });
        else props.onReset([field]);
    };

    return (
        <InputGrouper
            groupKey={"Font"}
            Icon={Paragraph}
            labelKey={"Font"}
            Reset={
                <ResetFormView
                    hasValue={CheckIfHasValue(
                        form,
                        FontDefaults(option?.font || FONT_OPTIONS[1].font)
                    )}
                    onReset={(e) => {
                        ResetValues(
                            e,
                            FontDefaults(option?.font || FONT_OPTIONS[1].font),
                            form.reset,
                            props.onReset
                        );
                    }}
                />
            }
        >
            <div className="font-form">
                <SelectInput
                    noLabel
                    isClearable
                    id={"fontFamily"}
                    idAccessor={"font"}
                    options={FONT_OPTIONS}
                    onChange={onSelectBlur}
                    errors={form.fieldErrors}
                    value={form.editor.fontFamily}
                    className={"font-input full-width"}
                    formatOptionLabel={(option) => (
                        <div className={`font-option ${option.class}`}>
                            {option.name}{" "}
                            <span className="example-text">({t<ILang>("example_text_font")})</span>
                        </div>
                    )}
                />
                <TextInput
                    onBlur={onTextBlur}
                    id={"fontWeight"}
                    value={form.editor.fontWeight}
                    className={"font-input left-column"}
                    errors={form.fieldErrors}
                    onChange={form.handleChange}
                />
                <TextInputWithSize
                    id={"fontSize"}
                    value={form.editor.fontSize}
                    onBlur={onTextBlur}
                    className={"font-input right-column"}
                    sizeUnit={props.units.fontSize}
                    onChange={form.handleChange}
                    errors={form.fieldErrors}
                />
                <TextInputWithSize
                    id={"letterSpacing"}
                    value={form.editor.letterSpacing}
                    onBlur={onTextBlur}
                    className={"font-input left-column"}
                    sizeUnit={props.units.letterSpacing}
                    errors={form.fieldErrors}
                    onChange={form.handleChange}
                />
                <TextInputWithSize
                    id={"lineHeight"}
                    value={form.editor.lineHeight}
                    onBlur={onTextBlur}
                    className={"font-input right-column"}
                    onChange={form.handleChange}
                    sizeUnit={props.units.lineHeight}
                    errors={form.fieldErrors}
                />
                <SelectInput
                    id={"fontStyle"}
                    value={form.editor.fontStyle}
                    errors={form.fieldErrors}
                    className={"font-input left-column"}
                    onChange={onSelectBlur}
                    useTranslationOnName
                    isClearable
                    options={[
                        { id: "italic", name: "italic" },
                        { id: "normal", name: "normal" }
                    ]}
                />
                <SelectInput
                    id={"textDecoration"}
                    value={form.editor.textDecoration}
                    errors={form.fieldErrors}
                    useTranslationOnName
                    onChange={onSelectBlur}
                    isClearable
                    className={"font-input right-column"}
                    options={[
                        { id: "line-through", name: "line-through" },
                        { id: "underline", name: "underline" },
                        { id: "none", name: "none" },
                        { id: "overline", name: "overline" }
                    ]}
                />
                <SelectInput
                    id={"textTransform"}
                    value={form.editor.textTransform}
                    errors={form.fieldErrors}
                    onChange={onSelectBlur}
                    className={"font-input full-width"}
                    useTranslationOnName
                    isClearable
                    options={[
                        { id: "uppercase", name: t<ILang>("uppercase") },
                        { id: "lowercase", name: t<ILang>("lowercase") },
                        { id: "capitalize", name: t<ILang>("capitalize") }
                    ]}
                />
            </div>
        </InputGrouper>
    );
};

export default Font;
