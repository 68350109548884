import { ILang } from "../../../../Interfaces/ILang.type";
import { IWidth } from "./IWidth.interface";
import { Width16 } from "../../SVG/Width.svg";
import { useForm } from "../../../UseForm/UseForm.provider";
import { TextInput } from "../../InputFields/TextInput/TextInput.view";
import { ResetValues } from "../../../Editor/Utils/ResetValues.util";
import { IInputGroup } from "../../../../Interfaces/IInputGroup.interface";
import { SIZING_RULE } from "../../../../Static/FormRules.static";
import { Destructure } from "../../../../Utils/Destructure.util";
import { WidthDefaults } from "./Defaults.static";
import { IFormWithUnit } from "../../../../Interfaces/IFormWithUnit.type";
import { useTranslation } from "react-i18next";
import { BuildGroupData } from "../../../../Utils/BuildGroupData.util";
import { CheckIfHasValue } from "../../../../Utils/CheckIfHasValue.util";
import { useAfterTriggerChanged, useTimeout } from "xa-generics";
import SizeUnitControlView from "../../SizeUnitControl/SizeUnitControl.view";
import ResetFormView from "../../InputGrouper/ResetForm.view";
import InputGrouper from "../../InputGrouper/InputGrouper.view";

export interface IWidthProps extends IInputGroup<IWidth<any>> {}

export const Width: React.FC<IWidthProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { setTm } = useTimeout();
    const form = useForm<IFormWithUnit<IWidth>, true>({
        editor: Destructure(WidthDefaults, props.defaultValues, props.units.width),
        initialRules: {
            minWidth: SIZING_RULE(t),
            width: SIZING_RULE(t),
            maxWidth: SIZING_RULE(t)
        }
    });

    useAfterTriggerChanged(() => {
        form.handleSubmit((state) => {
            setTm(() => props.onBlur(BuildGroupData(state)), 500);
        })();
    }, [form.editor]);

    return (
        <InputGrouper
            groupKey={"Width"}
            Icon={Width16}
            labelKey={"width"}
            Reset={
                <ResetFormView
                    hasValue={CheckIfHasValue(form, WidthDefaults)}
                    onReset={(e) => ResetValues(e, WidthDefaults, form.reset, props.onReset)}
                />
            }
            Controls={<SizeUnitControlView unit={form.editor.unit} setUnit={form.handleChange} />}
        >
            <div className="width-form">
                <TextInput
                    noLabel
                    unit={"min"}
                    id={"minWidth"}
                    className="height-input"
                    value={form.editor.minWidth}
                    onChange={form.handleChange}
                    errors={form.fieldErrors}
                />
                <TextInput
                    noLabel
                    id={"width"}
                    className="width-input"
                    value={form.editor.width}
                    onChange={form.handleChange}
                    errors={form.fieldErrors}
                />
                <TextInput
                    noLabel
                    unit={"max"}
                    id={"maxWidth"}
                    className="width-input"
                    value={form.editor.maxWidth}
                    onChange={form.handleChange}
                    errors={form.fieldErrors}
                />

                <div className="global__form-group--desc global__form-group--three-row">
                    {t<ILang>("special_values_desc")}
                </div>
            </div>
        </InputGrouper>
    );
};
