import { ILang } from "../../../Interfaces/ILang.type";
import { useDom } from "../../DomTracker/Controller/DomTracker.provider";
import { DomDAO } from "../../DomTracker/DAO/Dom.dao";
import { useForm } from "../../UseForm/UseForm.provider";
import { IWidgets } from "../../Widgets/Interfaces/IWidgets.type";
import { cloneDeep } from "lodash";
import { useImages } from "../../../Contexts/Images.context";
import { useWidgets } from "../../Widgets/Controller/WidgetsContext.provider";
import { FC, useState } from "react";
import { TemplatesDAO } from "../DAO/Templates.dao";
import { useRestaurant } from "../../../Contexts/Restaurant.context";
import { PageDataModel } from "../../DomTracker/Model/PageData.model";
import { TemplatesView } from "../View/Templates.view";
import { RestaurantDAO } from "../../../DAO/Restaurant.dao";
import { ReplaceImages } from "../Utils/ReplaceImages.util";
import { SanitizePages } from "../Utils/SanitizeSection.util";
import { BuildImageLinks } from "../Utils/BuildImageLinks.util";
import { TemplateRestaurantModel } from "../Model/TemplateRestaurant.model";
import { IDynamicObject, ISetState } from "xa-generics";
import { ChooseTemplateRestaurantView } from "../View/ChooseTemplateRestaurant.view";
import { ConfigModel, ImageModel, SeoModel } from "sitebuilder-common";
import Loading from "../../UI/Loading/Loading.view";

interface ITemplatesProps {
    setLoading?: ISetState<boolean>;
    loading?: boolean;
}

export const Templates: FC<ITemplatesProps> = (props) => {
    const form = useForm<TemplateRestaurantModel, false>({
        editor: null
    });
    const { pages, setPages, refs } = useDom();
    const { saveWidgets, setWidgets } = useWidgets();
    const { cloneTemplateRestImages } = useImages();
    const { restaurant, setConfig } = useRestaurant();

    const [loading, setLoading] = useState<ILang>("");

    const createPage = async (data: PageDataModel): Promise<PageDataModel> => {
        return await DomDAO.createPage(
            {
                ...data,
                competition_image_id: null,
                competition_image_align: null,
                elements: data.elements.dom,
                seos: data.seos.map((seo) => seo.Transform)
            },
            restaurant
        );
    };

    const handleSeoDifference = (newPage: PageDataModel, existingPage: PageDataModel): void => {
        const copiedSeos = cloneDeep(newPage.seos);
        newPage.seos = [];
        for (const seo of existingPage.seos) {
            const newPageSeo = copiedSeos.find((newSeo) => newSeo.lang === seo.lang);
            if (newPageSeo) {
                newPageSeo.seo_id = seo.seo_id;
                newPageSeo.restaurant_id = restaurant.id;
                newPageSeo.page_id = existingPage.page_id;
                newPage.seos.push(newPageSeo);
            }
        }

        const generateSeoInLangIfNotExists = (
            key: "primary_language" | "secondary_language"
        ): void => {
            if (!restaurant[key]) return;
            const newSeoInLang = newPage.seos.find((seo) => seo.lang === restaurant[key]);
            if (!newSeoInLang) {
                const model = existingPage.seos.find((seo) => seo.lang === restaurant[key]);
                newPage.seos.push(
                    model ||
                        new SeoModel({
                            lang: restaurant[key],
                            page_id: existingPage.page_id,
                            restaurant_id: restaurant.id,
                            url: `/${Math.random().toString(36).slice(3)}`
                        })
                );
            }
        };

        generateSeoInLangIfNotExists("primary_language");
        generateSeoInLangIfNotExists("secondary_language");
    };

    const mountPages = async (
        state: PageDataModel[],
        templatePages: PageDataModel[]
    ): Promise<IDynamicObject> => {
        const pagePairs: IDynamicObject<string> = {};
        for (let pageIndex = 0; pageIndex < templatePages.length; pageIndex++) {
            const pageType = templatePages[pageIndex].page_type;
            templatePages[pageIndex].restaurant_id = restaurant.id;
            templatePages[pageIndex].draft = null;
            templatePages[pageIndex].competition_image_align = null;
            templatePages[pageIndex].competition_image_id = null;
            const previousPageId = templatePages[pageIndex].page_id;
            delete (templatePages[pageIndex] as Partial<PageDataModel>).page_id;

            if (pageType) {
                //Find the index of the static page in the current existing page list
                const existingIndex = state.findIndex((page) => page.page_type === pageType);
                if (existingIndex >= 0) {
                    const existingPage = state[existingIndex];
                    templatePages[pageIndex].page_id = existingPage.page_id;
                    handleSeoDifference(templatePages[pageIndex], existingPage);
                    await DomDAO.updatePageWithTemplate(templatePages[pageIndex]);
                    //Replace the static page of the current list with the template version
                    state[existingIndex] = templatePages[pageIndex];
                    pagePairs[previousPageId] = existingPage.page_id;
                } else {
                    const result = await createPage(templatePages[pageIndex]);
                    //The static page doesn't exist in the current list, so simply add the template version.
                    //This will be possible if future static pages are added without a migration that would generate them for initialized restaurants.
                    pagePairs[previousPageId] = result.page_id;
                    state.push(result);
                }
            } else {
                const result = await createPage(templatePages[pageIndex]);
                pagePairs[previousPageId] = result.page_id;
                //Add the non-static pages to the state
                state.push(result);
            }
        }
        return pagePairs;
    };

    const replaceCurrentConfig = async (
        templateRestId: string,
        imageLinker: IDynamicObject<ImageModel>
    ): Promise<void> => {
        const templateConfig = (await TemplatesDAO.loadRestConfig(
            templateRestId
        )) as Partial<ConfigModel>;
        if (imageLinker[templateConfig.favicon_image_id!]) {
            templateConfig.favicon_image_id =
                imageLinker[templateConfig.favicon_image_id!].image_id;
        }
        if (imageLinker[templateConfig.body_bg_image_id!]) {
            templateConfig.body_bg_image_id =
                imageLinker[templateConfig.body_bg_image_id!].image_id;
        }
        templateConfig.applied_template_id = templateRestId;
        templateConfig.takeaway_product_id = "";
        delete templateConfig.og_title;
        delete templateConfig.og_image_id;
        delete templateConfig.og_image_alt;
        delete templateConfig.og_description;
        delete templateConfig.qr_cart_mode;
        delete templateConfig.takeaway_product_id;
        await RestaurantDAO.updateConfig(templateConfig.Transform!);
        const newConfig = await RestaurantDAO.loadConfig();
        setConfig(newConfig);
    };

    const replaceCurrentWidgets = async (
        templateRestId: string,
        imageLinker: IDynamicObject<ImageModel>,
        pagePairs: IDynamicObject
    ): Promise<void> => {
        const templateWidgets = await TemplatesDAO.loadRestWidgets(templateRestId);
        const json = templateWidgets.getWidgetsJSON();
        if (json.Footer.settings.footerLogo && imageLinker[json.Footer.settings.footerLogo]) {
            json.Footer.settings.footerLogo = imageLinker[json.Footer.settings.footerLogo].image_id;
        }
        if (json.Navbar.settings.navbarLogo && imageLinker[json.Navbar.settings.navbarLogo]) {
            json.Navbar.settings.navbarLogo = imageLinker[json.Navbar.settings.navbarLogo].image_id;
        }
        if (json.Navbar.settings.navbarLinks) {
            for (const link of json.Navbar.settings.navbarLinks) {
                if (link.isExternal) continue;

                if (link.url && typeof link.url === "string") {
                    link.url = pagePairs[link.url] as never;
                }
                const oldMainPageId = link[restaurant.primary_language].url;
                const oldSecondaryPageId = link[restaurant.secondary_language]?.url;

                if (oldMainPageId) {
                    link[restaurant.primary_language].url = pagePairs[oldMainPageId];
                }
                if (oldSecondaryPageId) {
                    link[restaurant.secondary_language].url = pagePairs[oldSecondaryPageId];
                }
            }
        }
        for (const key in json) {
            const widgetType = key as keyof IWidgets;
            if ("draftSettings" in json[widgetType]) {
                json[widgetType].draftSettings = json[widgetType].settings;
            }
        }
        setWidgets(json);
        saveWidgets(json);
    };

    const replaceSeoImages = (
        pages: PageDataModel[],
        imageLinker: IDynamicObject<ImageModel>
    ): void => {
        for (const page of pages) {
            for (const seo of page.seos) {
                if (seo.image_id && imageLinker[seo.image_id]) {
                    seo.image_id = imageLinker[seo.image_id].image_id;
                } else seo.image_id = "";
                seo.restaurant_id = restaurant.id;
                delete (seo as Partial<SeoModel>).page_id;
                delete (seo as Partial<SeoModel>).seo_id;
            }
        }
    };

    const applyRestaurantTemplate = async (template: TemplateRestaurantModel): Promise<void> => {
        setLoading("preparing_template");
        const state = cloneDeep(pages);
        const templatePages = await TemplatesDAO.loadRestPages(template.restaurant_id, restaurant);
        SanitizePages(state, templatePages, refs);
        setLoading("perparing_images");
        const { restaurantImages, templateImages } = await cloneTemplateRestImages(
            template.restaurant_id
        );
        const imageLinker = BuildImageLinks(restaurantImages, templateImages);
        for (const page of templatePages) {
            for (const section of page.elements.dom) {
                ReplaceImages(section, imageLinker);
            }
        }
        setLoading("preparing_pages");
        replaceSeoImages(templatePages, imageLinker);
        const pagePairs = await mountPages(state, templatePages);
        setLoading("preparing_config");
        await replaceCurrentConfig(template.restaurant_id, imageLinker);
        setLoading("preparing_widgets");
        await replaceCurrentWidgets(template.restaurant_id, imageLinker, pagePairs);
        setLoading("reloading_pages");
        const newPageList = await DomDAO.loadPages(restaurant);
        setPages(newPageList);
        setLoading("");
    };

    if (loading) {
        return <Loading loading={true} message={loading} isAbsolute size={"md"} />;
    }

    if (props.setLoading) {
        return (
            <ChooseTemplateRestaurantView
                loading={props.loading}
                setLoading={props.setLoading}
                applyRestaurantTemplate={applyRestaurantTemplate}
            />
        );
    }

    return <TemplatesView form={form} applyRestaurantTemplate={applyRestaurantTemplate} />;
};
