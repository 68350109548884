import { FC } from "react";
import { useForm } from "../../../UseForm/UseForm.provider";
import { BgDefaults } from "./Defaults.static";
import { IBackground } from "./IBackground.interface";
import { IInputGroup } from "../../../../Interfaces/IInputGroup.interface";
import { ResetValues } from "../../../Editor/Utils/ResetValues.util";
import { ColorSwitch } from "@carbon/icons-react";
import { Destructure } from "../../../../Utils/Destructure.util";
import { IFormWithUnit } from "../../../../Interfaces/IFormWithUnit.type";
import { CheckIfHasValue } from "../../../../Utils/CheckIfHasValue.util";
import { IBackgroundMetrics } from "../../../../Interfaces/IMetrics.type";
import GradientPickerInput from "../../InputFields/GradientPicker/GradientPicker.view";
import BackgroundControls from "./BackgroundControls.view";
import ResetFormView from "../../InputGrouper/ResetForm.view";
import InputGrouper from "../../InputGrouper/InputGrouper.view";
import ColorInput from "../../InputFields/ColorInput/ColorInput.view";

export interface IBackgroundGroupViewProps extends IInputGroup<IBackground<any>> {}

const BackgroundGroupView: FC<IInputGroup<IBackground>> = (props) => {
    const form = useForm<IFormWithUnit<IBackground<any>, IBackgroundMetrics>, true>({
        editor: Destructure(BgDefaults, props.defaultValues, props.units.bgType)
    });

    const getGroup = (unitType: IBackgroundMetrics, content: JSX.Element): JSX.Element => {
        const classes: string[] = ["group"];
        if (unitType === form.editor.unit) classes.push("active-group");

        return <div className={classes.join(" ")}>{content}</div>;
    };

    const onUnitChange = (field: "unit", value: IBackgroundMetrics): void => {
        if (value === "GRADIENT" && form.editor.backgroundColor) {
            props.onReset(["backgroundColor"]);
            form.reset([{ field: "backgroundColor", value: BgDefaults.backgroundColor }]);
        }
        if (value === "GENERIC" && form.editor.background) {
            props.onReset(["background"]);
            form.reset([{ field: "background", value: BgDefaults.background }]);
            form.setIsDirty(false);
        }
        form.handleChange(field, value);
    };

    const onBgChange = (field: "background" | "backgroundColor", value: string): void => {
        form.handleChange(field, value);
        if (value) props.onBlur({ field, value });
        else props.onReset([field]);
    };

    return (
        <InputGrouper
            Icon={ColorSwitch}
            groupKey={"BackgroundGroup"}
            labelKey={"backgroundColor"}
            Controls={<BackgroundControls unit={form.editor.unit} setUnit={onUnitChange} />}
            Reset={
                <ResetFormView
                    hasValue={CheckIfHasValue(form, BgDefaults)}
                    onReset={(e) => ResetValues(e, BgDefaults, form.reset, props.onReset)}
                />
            }
        >
            <div className="background-form">
                {getGroup(
                    "GENERIC",
                    <ColorInput<IBackground>
                        noLabel
                        withValueSync
                        id={"backgroundColor"}
                        errors={form.fieldErrors}
                        onChange={onBgChange}
                        value={form.editor.backgroundColor}
                    />
                )}
                {getGroup(
                    "GRADIENT",
                    <GradientPickerInput
                        onChange={onBgChange}
                        currentUnit={form.editor.unit}
                        background={form.editor.background}
                    />
                )}
            </div>
        </InputGrouper>
    );
};

export default BackgroundGroupView;
