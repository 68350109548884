import { axios, IDynamicObject } from "xa-generics";
import { ProductCategoryModel } from "../Models/ProductCategory.model";
import { ProductModel } from "../Models/Product.model";
import { AXIOS_NAMES } from "../../../../../Static/AxiosInstanceNames.static";
import { RootDAO } from "../../../../../DAO/Restaurant.dao";

export abstract class ProductsDAO extends RootDAO {
    private static readonly cat: string = "product-categories";
    private static readonly prod: string = "products";
    private static query: string = "?type=web";

    public static setQuery(type: string) {
        this.query = `?type=${type}`;
    }

    private static request<GetType>(url: string) {
        return axios.getInstance(AXIOS_NAMES.ONEMIN).get<GetType>(url);
    }

    public static async loadFullTree(): Promise<
        [ProductCategoryModel[], IDynamicObject<ProductCategoryModel>]
    > {
        const request = await this.request<IDynamicObject[]>(
            `${this.v3url(true, false)}/${this.cat}/with-products${this.query}`
        );
        const categories = request.data.map((item) => new ProductCategoryModel(item));
        const categoryObject: IDynamicObject<ProductCategoryModel> = {};
        for (let category of categories) {
            category.setHasChild(categories);
            categoryObject[category.id] = category;
        }
        return [categories, categoryObject];
    }

    public static async loadProductsByName(name: string): Promise<ProductModel[]> {
        const url = `${this.v3url(true, false)}/${this.prod}${
            this.query
        }&name_filter=${encodeURIComponent(name)}`;
        const request = await this.request<IDynamicObject[]>(url);
        return request.data.map((item) => new ProductModel(item));
    }
}
