import { ISetState } from "xa-generics";
import { IDomContext } from "../../DomTracker/Interfaces/IDomContext.interface";
import { PageDataModel } from "../../DomTracker/Model/PageData.model";
import { RestaurantModel } from "sitebuilder-common";
import { DomlessPageModel } from "../../DomTracker/Model/DomlessPage.model";

export const FindPageSource = async (
    pages: PageDataModel[],
    currentPage: DomlessPageModel,
    restaurant: RestaurantModel,
    setCurrentPage: ISetState<DomlessPageModel>,
    asyncClonePage: IDomContext["asyncClonePage"]
): Promise<{ src: PageDataModel[]; pageIndex: number }> => {
    let pageIndex: number = -1;
    let src = pages;
    if (currentPage.isCurrentPageDraft) {
        pageIndex = pages.findIndex((page) => page.page_id === currentPage.page_id);
    } else {
        if (currentPage.draft !== null) {
            const model = new DomlessPageModel(currentPage, restaurant, true);
            setCurrentPage(model);
            pageIndex = pages.findIndex((page) => page.page_id === currentPage.page_id);
        } else {
            try {
                const result = await asyncClonePage(currentPage);
                pageIndex = result.index;
                src = result.pages;
            } catch (error) {
                console.log(error);
            }
        }
    }
    return {
        pageIndex,
        src
    };
};
