import {
    TextAlignLeft,
    TextAlignRight,
    TextAlignCenter,
    TextAlignJustify
} from "@carbon/icons-react";
import { IFieldBlur } from "../../../Editor/Utils/EditorHook.util";
import { useState } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import InputGrouper from "../../InputGrouper/InputGrouper.view";

type IAlign = { textAlign?: string; justifyContent?: string; alignItem?: string };

export interface IAlignPickerViewProps<Fields extends IAlign> {
    id: keyof Fields;
    labelText?: ILang;
    defaultValue?: any;
    valueSet?: "TEXT_ALIGN" | "FLEX" | "NAV_GRID";
    onBlur: (data: IFieldBlur<Fields>) => void;
}

type IValues = "left" | "center" | "right" | "justify" | "" | "flex-start" | "flex-end";

const AlignPickerView = <Fields extends IAlign>(props: IAlignPickerViewProps<Fields>) => {
    const defValue = props.valueSet === "TEXT_ALIGN" ? "left" : "flex-start";
    const [active, setActive] = useState<IValues>(props.defaultValue || defValue);
    const valueSet = props.valueSet || "TEXT_ALIGN";

    const getCol = (boxValue: IValues, Icon: JSX.Element): JSX.Element => {
        const classes: string[] = ["column-picker__value"];
        if (boxValue === active) classes.push("column-picker__active");
        return (
            <div
                onClick={() => {
                    setActive(boxValue);
                    props.onBlur({ value: boxValue, field: props.id });
                }}
                className={classes.join(" ")}
            >
                {Icon}
            </div>
        );
    };
    let left: IValues, right: IValues, showJustify: boolean;
    let colCount: number = 4;
    if (valueSet === "TEXT_ALIGN") {
        left = "left";
        right = "right";
        showJustify = true;
    } else if (valueSet === "NAV_GRID") {
        left = "left";
        right = "";
        showJustify = false;
        colCount = 2;
    } else {
        left = "flex-start";
        right = "flex-end";
        showJustify = false;
        colCount = 3;
    }
    return (
        <InputGrouper
            groupKey={"AlignPicker"}
            Icon={TextAlignLeft}
            labelKey={props.labelText || "textAlign"}
            defaultOpen
        >
            <div className={`column-picker --values-${colCount}`}>
                <div className="column-picker__values">
                    {getCol(left, <TextAlignLeft />)}
                    {getCol("center", <TextAlignCenter />)}
                    {right && getCol(right, <TextAlignRight />)}
                    {showJustify && getCol("justify", <TextAlignJustify />)}
                </div>
            </div>
        </InputGrouper>
    );
};

export default AlignPickerView;
