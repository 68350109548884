import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { Regexes } from "xa-generics";
import { NavLink } from "react-router-dom";
import { DnDEvents } from "../../Util/DND.util";
import { useImages } from "../../../../Contexts/Images.context";
import { IDomElement } from "../../../DomTracker/Interfaces/IDomTypes.interface";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import { ImageTypeStyles } from "../../Static/ImageTypeStyle.static";
import { ParseComponentStyle } from "sitebuilder-common";
import { ImageStyleSeparator } from "sitebuilder-common";
import {
    IImageTypeStyles,
    IImageTypeContent
} from "../../Interfaces/IImageTypeComponent.interface";
import Overlay from "../../View/Overlay.view";

interface IDomImageProps extends IDomElement<IImageTypeContent> {}

export const DomImage: FC<IDomImageProps> = (props) => {
    const { i18n, t } = useTranslation<ILang>();
    const { restaurant } = useRestaurant();
    const { images } = useImages();

    const currentLang = i18n.language;

    const { refs, size, id, showOverlay, ...events } = DnDEvents<IImageTypeContent>(
        props,
        "ELEMENT"
    );

    let style = ParseComponentStyle(props.content, ImageTypeStyles, size) as IImageTypeStyles<any>;
    const data = props.content?.[currentLang] as IImageTypeContent | undefined;
    const text = data?.text || "";

    const image = images[props.content.src];

    const getLink = (child: JSX.Element): JSX.Element => {
        let url = data?.url;
        if (!url || (!url.match(Regexes.Url) && !isNaN(parseInt(url)))) {
            return <>{child}</>;
        }
        if (data?.isExternal && props.content?.openOnNewTab) {
            return (
                <a rel={"noreferrer noopener"} target={"_blank"} href={url}>
                    {child}
                </a>
            );
        } else {
            if (!data?.openOnNewTab && url.indexOf(restaurant.domain) > -1) {
                url = url.split(restaurant.domain)[1];
            }
            return <NavLink to={url}>{child}</NavLink>;
        }
    };

    const { container, tag } = ImageStyleSeparator(style);
    return (
        <div
            id={id}
            {...events}
            style={container}
            ref={refs.current[props.uuid]}
            className={"dom__image-container"}
        >
            {showOverlay && <Overlay parent={props} />}
            {image?.url ? (
                getLink(
                    <img
                        src={image?.url || ""}
                        alt={text}
                        className={"dom__image-container--image"}
                        style={tag}
                    />
                )
            ) : (
                <div className="dom__image-container--placeholder">
                    {t<ILang>("img_placeholder")}
                </div>
            )}
        </div>
    );
};
