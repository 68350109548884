import { IError, ILoading, useAfterTriggerChanged, useTimeout } from "xa-generics";
import { AnalyticsModel, ConfigModel } from "sitebuilder-common";
import { useTranslation } from "react-i18next";
import { useRestaurant } from "../../../Contexts/Restaurant.context";
import { RestaurantDAO } from "../../../DAO/Restaurant.dao";
import { FC, useState } from "react";
import { AnalyticsDAO } from "../../../DAO/Analytics.dao";
import { ConfigView } from "../View/Config.view";
import { cloneDeep } from "lodash";
import { useForm } from "../../UseForm/UseForm.provider";
import { ILang } from "../../../Interfaces/ILang.type";

interface IConfigControllerProps {}

export const ConfigController: FC<IConfigControllerProps> = (props) => {
    const { config, loadConfig } = useRestaurant();
    const { t } = useTranslation<ILang>();
    const { setTm } = useTimeout();

    const [error, setError] = useState<IError>(null);
    const [loading, setLoading] = useState<ILoading>(false);
    const [isAltered, setIsAltered] = useState<boolean>(false);
    const [isConversionSaved, setIsConversionSaved] = useState<boolean>(false);
    const [currentAnalytics, setCurrentAnalytics] = useState<AnalyticsModel | null>(null);

    const conversionForm = useForm<AnalyticsModel, true>({
        editor: new AnalyticsModel(),
        hookOnAnyFieldChange: (state, field, value) => {
            if (
                field === "hotjar_script" ||
                field === "fb_head_script" ||
                field === "ga_head_script" ||
                field === "gtag_body_script"
            ) {
                state[field] = value.trim().replace(/\s+/g, " ");
                return state;
            } else {
                state[field] = value.trim();
                return state;
            }
        }
    });
    const form = useForm<ConfigModel, true>({
        editor: cloneDeep(config),
        hookOnAnyFieldChange: () => {
            setIsAltered(true);
        }
    });

    const saveConfig = (): void => {
        setLoading({
            reason: t<ILang>("save_config"),
            promise: RestaurantDAO.updateConfig(form.editor.Transform)
                .then(() => {
                    loadConfig();
                    setIsAltered(false);
                    setCurrentAnalytics(cloneDeep(conversionForm.editor));
                })
                .catch((error) => setError(error))
                .finally(() => setLoading(false))
        });
    };

    const showCurrent = (): void => {
        AnalyticsDAO.load()
            .then((data) => {
                conversionForm.setEditor(data);
                setCurrentAnalytics(data);
            })
            .catch((err) => setError(err));
    };

    const saveAnalytics = (data: AnalyticsModel): void => {
        AnalyticsDAO.saveAnalytics(data)
            .then(() => {
                setIsConversionSaved(true);
                setTm(() => setIsConversionSaved(false), 4000, "pixel-saved");
            })
            .catch((e) => setError(e));
    };

    useAfterTriggerChanged(() => {
        if (conversionForm.editor.fb_access_token || conversionForm.editor.fb_pixel_id) {
            conversionForm.updateRules((cs) => {
                let state = { ...cs };
                state.fb_access_token = true;
                state.fb_pixel_id = true;
                return state;
            });
        } else {
            conversionForm.updateRules((cs) => {
                let state = { ...cs };
                delete state.fb_access_token;
                delete state.fb_pixel_id;
                return state;
            });
        }
    }, [conversionForm.editor.fb_access_token, conversionForm.editor.fb_pixel_id]);
    useAfterTriggerChanged(() => {
        if (conversionForm.editor.ga_measurement_id || conversionForm.editor.ga_secret) {
            conversionForm.updateRules((cs) => {
                let state = { ...cs };
                state.ga_measurement_id = true;
                state.ga_secret = true;
                return state;
            });
        } else {
            conversionForm.updateRules((cs) => {
                let state = { ...cs };
                delete state.ga_measurement_id;
                delete state.ga_secret;
                return state;
            });
        }
    }, [conversionForm.editor.ga_measurement_id, conversionForm.editor.ga_secret]);

    useAfterTriggerChanged(() => {
        form.setEditor(cloneDeep(config));
    }, [config]);

    return (
        <ConfigView
            form={form}
            error={error}
            loading={loading}
            setError={setError}
            isAltered={isAltered}
            saveConfig={saveConfig}
            showCurrent={showCurrent}
            saveAnalytics={saveAnalytics}
            conversionForm={conversionForm}
            currentAnalytics={currentAnalytics}
            isConversionSaved={isConversionSaved}
        />
    );
};
