import { IFormWithUnit } from "../../../../Interfaces/IFormWithUnit.type";
import { IBorderRadius } from "./IBorderRadius.interface";

export const BorderRadiusDefaults: IFormWithUnit<IBorderRadius> = {
    borderBottomLeftRadius: "",
    borderBottomRightRadius: "",
    borderTopLeftRadius: "",
    borderTopRightRadius: "",
    unit: "rem"
};
