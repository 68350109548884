import { FC } from "react";
import { useIFrame } from "../Provider/IFrame.context";
import { FrameButton } from "./FrameButton.view";
import { ProfileController } from "../../WidgetModules/Profile/Controller/Profile.controller";

interface IFrameProfileIndexProps {}

export const FrameProfileIndex: FC<IFrameProfileIndexProps> = (props) => {
    const { data, dataSource } = useIFrame();

    return <ProfileController styleData={data[dataSource]} v2Btn={FrameButton} />;
};
