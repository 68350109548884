import { ModelConstructor, IDynamicObject } from "xa-generics";

export class AddressModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as AddressMData;
        ModelConstructor(data, this);
    }

    id: string = "";
    country: string = "";
    state_province_county: string = "";
    city: string = "";
    street: string = "";
    premise_number: string = "";
    building: string = "";
    floor: string = "";
    door_number: string = "";
    doorbell: string = "";
    address_notes: string = "";
    postcode: string = "";
    customer_id: string = "";
    created_at: string = "";
    updated_at: string = "";

    public toString(): string {
        let address = `${this.postcode}, ${this.street} ${this.premise_number}.`;
        if (this.floor) address += ` ${this.floor}`;
        if (this.door_number) address += ` ${this.door_number}`;
        if (this.doorbell) address += ` ${this.doorbell}`;
        return address;
    }
}

//If you add public methods, you may want to replace the empty string with them!
type AddressMData = Partial<InstanceType<typeof AddressModel>>;

export const MOCK_ADDRESSES: AddressModel[] = [
    new AddressModel({
        id: "1",
        country: "Magyarország",
        state_province_county: "Hajdú-Bihar",
        city: "Debrecen",
        street: "Simonffy utca",
        premise_number: "4",
        building: "Halköz",
        floor: "1. emelet",
        door_number: "123-as ajtó",
        postcode: "4025"
    }),
    new AddressModel({
        id: "2",
        country: "Magyarország",
        state_province_county: "Hajdú-Bihar",
        city: "Debrecen",
        street: "Csapó utca",
        premise_number: "54",
        postcode: "4025"
    })
];
