import { ToMoneyInt } from "xa-generics";
import { useRestaurant } from "../../../../../Contexts/Restaurant.context";

export const GetCurrency = (): string => {
    const { restaurant } = useRestaurant();
    switch (restaurant.currency) {
        case "usd":
            return "$";
        case "eur":
            return "€";
        case "huf":
        default:
            return "Ft";
    }
};

export const RenderPrice = (currency: string, price: string | number): JSX.Element => {
    return (
        <div className={"price"}>
            {currency === "Ft" ? (
                <>
                    {ToMoneyInt(price)} {currency}
                </>
            ) : (
                <>
                    {currency}
                    {ToMoneyInt(price)}
                </>
            )}
        </div>
    );
};
