import { ModelConstructor, IDynamicObject } from "xa-generics";

export class UserModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as UserMData;
        ModelConstructor(data, this);
    }

    id: string = "";
    email: string = "";
    first_name: string = "";
    last_name: string = "";
    phone_1: string = "";
}

//If you add public methods, you may want to replace the empty string with them!
type UserMData = Partial<InstanceType<typeof UserModel>>;

export const MOCK_USER_MODEL: UserModel = new UserModel({
    id: "1",
    email: "testexample@email.com",
    first_name: "Test",
    last_name: "Elek",
    phone_1: "36 30 111 00 00"
});
