import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { useImages } from "../../../../Contexts/Images.context";
import { useWidgets } from "../../../Widgets/Controller/WidgetsContext.provider";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import { IFooterSettings } from "../Interfaces/IFooterWidget.interface";
import { GetWidgetOverlay } from "../../Util/WidgetOverlay.util";
import { FooterNameStyles } from "../Static/FooterNameStyles.static";
import { FormatPhoneNumber } from "sitebuilder-common";
import { FooterBlockStyles } from "../Static/FooterBlockStyles.static";
import { ParseComponentStyle } from "sitebuilder-common";
import { Calendar, Email, Location, Phone } from "@carbon/icons-react";
import WidgetOverlay from "../../../Widgets/View/WidgetOverlay.view";

interface IFooterTopBarViewProps {}

export const FooterTopBarView: FC<IFooterTopBarViewProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { size } = useDom();
    const { images } = useImages();
    const { widgets } = useWidgets();
    const { restaurant } = useRestaurant();

    const { footerTop, footerName, footerLogo, isOpenHourHidden, isNameHidden } =
        widgets.Footer.draftSettings;
    const { showOverlay, ...events } = GetWidgetOverlay<IFooterSettings>("Footer", "footerTop");

    const style = ParseComponentStyle(footerTop, FooterBlockStyles, size);
    const nameStyle = ParseComponentStyle(footerName, FooterNameStyles, size);

    const hasAddress: boolean =
        restaurant.city && restaurant.street && restaurant.premise_number ? true : false;
    const address = hasAddress
        ? `${restaurant.city}+${restaurant.street}+${restaurant.premise_number}`
        : "Debrecen Simonffy utca 4-6";

    const className = ["footer__top"];
    if (isOpenHourHidden) className.push("--NO_OPEN_HOUR");

    return (
        <div style={style} {...events} className={className.join(" ")}>
            {showOverlay && (
                <WidgetOverlay<IFooterSettings>
                    isBlock
                    align={"center"}
                    subKey={"footerTop"}
                    parent={widgets.Footer}
                />
            )}

            <div className="footer__top--content">
                <div className="footer__top--logo logo-block">
                    {isNameHidden === false && (
                        <div className="footer__top--logo--name" style={nameStyle}>
                            {restaurant.name}
                        </div>
                    )}
                    {footerLogo && images[footerLogo]?.url && (
                        <img
                            alt={restaurant.name}
                            src={images[footerLogo].url}
                            className={"footer__top--logo--image"}
                        />
                    )}
                </div>
                <address className="footer__top--block">
                    <div className="footer__top--block--icon">
                        <Location size={24} />
                    </div>
                    <a
                        href={`https://www.google.com/maps/search/${address.replace(/\s/g, "+")}`}
                        className="footer__top--block--data"
                        target={"_blank"}
                        rel={"noreferrer noopener"}
                    >
                        <div className="city">
                            {hasAddress ? restaurant.city.toLowerCase() : "Debrecen"}
                        </div>
                        <div className="address">
                            {hasAddress ? (
                                <>
                                    {restaurant.street.toLowerCase()} {restaurant.premise_number}
                                </>
                            ) : (
                                "Simonffy utca 4-6"
                            )}
                        </div>
                    </a>
                </address>
                <address className="footer__top--block">
                    <div className="footer__top--block--icon">
                        <Phone size={24} />
                    </div>
                    <div className="footer__top--block--data">
                        <a
                            href={`tel:${restaurant.phone_1 || "36304872462"}`}
                            className="phone"
                            target={"_blank"}
                            rel={"noreferrer noopener"}
                        >
                            {FormatPhoneNumber(restaurant.phone_1 || "36304872462")}
                        </a>
                    </div>
                </address>
                <address className="footer__top--block">
                    <div className="footer__top--block--icon">
                        <Email size={24} />
                    </div>
                    <div className="footer__top--block--data">
                        <a
                            href={`mailto: ${restaurant.contact_email || "example@email.com"}`}
                            className="email"
                            target={"_blank"}
                            rel={"noreferrer noopener"}
                        >
                            {restaurant.contact_email || "example@email.com"}
                        </a>
                    </div>
                </address>
                {!isOpenHourHidden && (
                    <div className="footer__top--block open-hours">
                        <div className="footer__top--block--icon">
                            <Calendar size={24} />
                        </div>
                        <div className="footer__top--block--data">
                            <div className="open-hours--item">
                                <span className="day">{t<ILang>("monday")}</span>{" "}
                                <span className="hour">08:00 - 23:00</span>
                            </div>
                            <div className="open-hours--item">
                                <span className="day">{t<ILang>("tuesday")}</span>{" "}
                                <span className="hour">10:00 - 20:00</span>
                            </div>
                            <div className="open-hours--item">
                                <span className="day">{t<ILang>("wednesday")}</span>{" "}
                                <span className="hour">08:00 - 23:00</span>
                            </div>
                            <div className="open-hours--item">
                                <span className="day">{t<ILang>("thursday")}</span>{" "}
                                <span className="hour">11:00 - 00:00</span>
                            </div>
                            <div className="open-hours--item">
                                <span className="day">{t<ILang>("friday")}</span>{" "}
                                <span className="hour">{t<ILang>("closed_hour")}</span>
                            </div>
                            <div className="open-hours--item">
                                <span className="day">{t<ILang>("saturday")}</span>{" "}
                                <span className="hour">12:00 - 00:00</span>
                            </div>
                            <div className="open-hours--item">
                                <span className="day">{t<ILang>("sunday")}</span>{" "}
                                <span className="hour">12:00 - 00:00</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
