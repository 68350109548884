import { ILang } from "../../../Interfaces/ILang.type";
import { DomDAO } from "../../DomTracker/DAO/Dom.dao";
import { useDom } from "../../DomTracker/Controller/DomTracker.provider";
import { useForm } from "../../UseForm/UseForm.provider";
import { SeoModel } from "sitebuilder-common";
import { cloneDeep } from "lodash";
import { useEditor } from "./EditorContext.provider";
import { FC, useState } from "react";
import { ExtraRegexes } from "../../../Utils/ExtraRegex.util";
import { useRestaurant } from "../../../Contexts/Restaurant.context";
import { PageDataModel } from "../../DomTracker/Model/PageData.model";
import { PageEditorView } from "../View/PageEditor.view";
import { useTranslation } from "react-i18next";
import { DomlessPageModel } from "../../DomTracker/Model/DomlessPage.model";
import { IPageCreate, IPageUpdate } from "../../DomTracker/Interfaces/IPageCreate.interface";
import { IError, ILoading, useAfterTriggerChanged } from "xa-generics";

export interface IPageEditorProps {
    page: DomlessPageModel;
}

export const PageEditor: FC<IPageEditorProps> = (props) => {
    const { t } = useTranslation<ILang>();

    const { onPageCreate, onPageUpdate, currentPage, save, pages } = useDom();
    const { setPageEditor } = useEditor();
    const { restaurant } = useRestaurant();

    const form = useForm<DomlessPageModel, true>({
        editor: props.page,
        initialRules: {
            name: true
        }
    });
    const [validationMsg, setValidationMsg] = useState<string>("");
    const [loading, setLoading] = useState<ILoading>(false);
    const [error, setError] = useState<IError>(null);

    const onSeoChange = (id: keyof SeoModel, value: any, lang: string): void => {
        form.setEditor((current) => {
            const state = cloneDeep(current);
            const seoIndex = state.seos.findIndex((seo: SeoModel) => seo.lang === lang);
            state.seos[seoIndex][id] = value as never;
            return state;
        });
    };

    const isValidData = (): boolean => {
        for (let seo of form.editor.seos) {
            //Skip languages that aren't selectable/editable
            if (
                seo.lang !== restaurant.primary_language &&
                seo.lang !== restaurant.secondary_language
            ) {
                continue;
            }
            if (!seo.url) {
                setValidationMsg(`${t<ILang>("no_seo_url_for_lang")}: ${seo.lang.toUpperCase()}`);
                return false;
            }
            if (!ExtraRegexes.Path.test(seo.url)) {
                setValidationMsg(
                    `${t<ILang>("invalid_seo_url_for_lang")}: ${seo.lang.toUpperCase()}`
                );
                return false;
            }
        }
        return true;
    };

    const unsetOtherMainPage = (): void | {
        request: Promise<void>;
        page: PageDataModel;
    } => {
        const page = pages.find((page) => page.is_main_page);
        if (!page || page.page_id === props.page.page_id) return;

        return {
            request: DomDAO.patchPage(page.page_id, { is_main_page: false }),
            page
        };
    };

    const onSubmit = (): void => {
        if (!isValidData()) return;
        const data: IPageCreate | IPageUpdate = {
            name: form.editor.name,
            is_active: form.editor.is_active,
            is_main_page: form.editor.is_main_page,
            competition_image_id: form.editor.competition_image_id || null,
            competition_image_align: form.editor.competition_image_align || null,
            seos: form.editor.seos.map((seo: SeoModel) => seo.Transform)
        };
        let otheMainPage: void | { request: Promise<void>; page: PageDataModel };
        if (data.is_main_page) {
            otheMainPage = unsetOtherMainPage();
        }
        const requests: [Promise<PageDataModel | void>, Promise<void> | void] = [
            !form.editor.page_id
                ? DomDAO.createPage({ ...data, elements: [] }, restaurant)
                : DomDAO.updatePage(form.editor.page_id, data),
            void 0
        ];

        if (otheMainPage!) requests[1] = otheMainPage.request;

        setLoading(
            Promise.all(requests)
                .then(([page]) => {
                    if (!page) {
                        const newModel = new DomlessPageModel(
                            { ...form.editor, ...data },
                            restaurant
                        );
                        onPageUpdate(newModel, otheMainPage?.page.page_id);
                    } else onPageCreate(page);
                    if (validationMsg) setValidationMsg("");
                    setPageEditor(null);
                })
                .catch((error) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    useAfterTriggerChanged(() => {
        save(); //This saves the default inserted dom node
    }, [currentPage]);

    useAfterTriggerChanged(() => {
        //Update only if a different page is passed
        //but skip if the current and the new one is also
        //an empty new page model
        if (
            (!form.editor.page_id && props.page.page_id) ||
            form.editor.page_id !== props.page.page_id
        ) {
            form.setEditor(props.page);
        }
    }, [props.page]);

    return (
        <PageEditorView
            {...props}
            form={form}
            error={error}
            loading={loading}
            setError={setError}
            onSubmit={onSubmit}
            onSeoChange={onSeoChange}
            validationMsg={validationMsg}
            setValidationMsg={setValidationMsg}
        />
    );
};
