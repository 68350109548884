import { FC } from "react";
import { useIFrame } from "../../Provider/IFrame.context";
import { IPartialCSS } from "sitebuilder-common";
import { Add, Subtract } from "@carbon/icons-react";

interface IQtyViewProps {
    qty: number;
    className?: string;
    disabled?: boolean;
    decrement: () => void;
    increment: () => void;
}

export const FrameQtyView: FC<IQtyViewProps> = (props) => {
    const qtyClasses: string[] = ["global__quantity-hook"];

    const { data, dataSource } = useIFrame();
    const style: IPartialCSS<any> = {};
    const content = data[dataSource].buttons;

    if (content.backgroundColor) style.backgroundColor = content.backgroundColor;
    if (content.color) style.color = content.color;

    if (props.className) qtyClasses.push(props.className);

    return (
        <div className={qtyClasses.join(" ")}>
            <button
                disabled={props.qty === 1 || props.disabled}
                className={"global__quantity-hook--btn"}
                onClick={props.decrement}
                style={style}
            >
                <Subtract />
            </button>
            <div className="global__quantity-hook--qty">{props.qty}</div>
            <button
                className={"global__quantity-hook--btn"}
                onClick={props.increment}
                disabled={props.disabled}
                style={style}
            >
                <Add />
            </button>
        </div>
    );
};
