import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { useEditor } from "../../../Editor/Controller/EditorContext.provider";
import { useWidgets } from "../../../Widgets/Controller/WidgetsContext.provider";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import { ParseComponentStyle } from "sitebuilder-common";
import { NavbarLinkStyles } from "../Static/NavbarLinkStyles.static";
import { GetWidgetOverlay } from "../../Util/WidgetOverlay.util";
import { INavbarSettings } from "../Interfaces/INavbarWidget.interface";
import WidgetOverlay from "../../../Widgets/View/WidgetOverlay.view";
import { axios } from "xa-generics";
import { AXIOS_NAMES } from "../../../../Static/AxiosInstanceNames.static";
import { SaveLangCookie } from "../../../../Utils/SaveLangCookie.util";
import { useWidgetEditor } from "../../../Editor/Controller/WidgetEditorContext.provider";

interface IV2NavbarLangsProps {}

export const V2NavbarLangs: FC<IV2NavbarLangsProps> = (props) => {
    const { size } = useDom();
    const { widgets } = useWidgets();
    const { setEditor } = useEditor();
    const { restaurant } = useRestaurant();
    const { i18n } = useTranslation<ILang>();
    const { setWidgetEditor } = useWidgetEditor();

    const { navbarLangPicker } = widgets.Navbar.draftSettings;
    const style = ParseComponentStyle(navbarLangPicker, NavbarLinkStyles, size) || {};
    const { showOverlay, ...events } = GetWidgetOverlay<INavbarSettings>(
        "Navbar",
        "navbarLangPicker"
    );

    const classes = ["v2navbar__langs"];

    return (
        <div className={classes.join(" ")} {...events}>
            {showOverlay && (
                <WidgetOverlay<INavbarSettings>
                    align={"right"}
                    parent={widgets.Navbar}
                    subKey={"navbarLangPicker"}
                />
            )}
            <button
                style={style}
                className={"v2navbar__langs--button"}
                onClick={() => {
                    const nextLang =
                        i18n.language === restaurant.primary_language &&
                        restaurant.secondary_language
                            ? restaurant.secondary_language
                            : restaurant.primary_language;
                    axios.setLanguage(nextLang, AXIOS_NAMES.BASIC);
                    axios.setLanguage(nextLang, AXIOS_NAMES.ONEMIN);
                    i18n.changeLanguage(nextLang);
                    SaveLangCookie(nextLang);
                    setEditor(null);
                    setWidgetEditor({ group: null, subKey: "" });
                }}
            >
                {i18n.language.toUpperCase()}
            </button>
        </div>
    );
};
