import { Context, createContext, useState, useContext } from "react";
import { MOCK_ORDER_MODEL, OrderModel } from "../../../../Models/Order.model";
import { IError, ILoading } from "xa-generics";
import { ICartUserForm } from "../Interfaces/ICartUserForm.interface";
import { ICartContext } from "../Interfaces/ICartContext.interface";
import { cloneDeep } from "lodash";
import { useForm } from "../../../UseForm/UseForm.provider";

/**
 * ## CartContext
 */
const CartContext: Context<ICartContext> = createContext<ICartContext>(null as any);

CartContext.displayName = "CartContext";

interface ICartContextProviderProps {}

/**
 * ## Cart context provider component
 *
 */
export const CartContextProvider: React.FC<ICartContextProviderProps> = (props) => {
    const [error, setError] = useState<IError>(null);
    const [loading] = useState<ILoading>(false);
    const form = useForm<ICartUserForm, true>({
        editor: {
            email: "",
            first_name: "",
            last_name: "",
            password: "",
            pickup: true,
            phone_number: "",
            reg_with_order: true,
            city: "",
            street: "",
            premise_number: "",
            floor: "",
            door_number: "",
            doorbell: "",
            privacy_policy: true,
            terms_and_conditions: true,
            message: "",
            payment_method_id: "1",
            payment_split: false,
            expected_at: "",
            is_preorder: false,
            is_take_away: false,
            coupon: "",
            coupon_temp: "",
            apply_coupon: false,
            delivery_method: "DELIVERY",
            delivery_time: "ASAP"
        }
    });
    const [order] = useState<OrderModel>(cloneDeep(MOCK_ORDER_MODEL));

    const couponChange = (apply: boolean): void => {
        if (apply) {
            form.setEditor((current) => {
                const state = { ...current };
                state.apply_coupon = true;
                state.coupon = state.coupon_temp;
                return state;
            });
        } else {
            form.setEditor((current) => {
                const state = { ...current };
                state.apply_coupon = false;
                state.coupon = "";
                state.coupon_temp = "";
                return state;
            });
        }
    };

    return (
        <CartContext.Provider
            value={{
                form,
                error,
                order,
                loading,
                setError,
                couponChange
            }}
        >
            {props.children}
        </CartContext.Provider>
    );
};

export const useCart = (): ICartContext => useContext(CartContext);
