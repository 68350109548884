import { ILang } from "../../../../Interfaces/ILang.type";
import { TextArea } from "../../../UI/InputFields/TextInput/TextArea.view";
import { DnDEvents } from "../../Util/DND.util";
import { TextInput } from "../../../UI/InputFields/TextInput/TextInput.view";
import { useImages } from "../../../../Contexts/Images.context";
import { IDomElement } from "../../../DomTracker/Interfaces/IDomTypes.interface";
import { ClientButton } from "../Button/ClientButton.view";
import { useTranslation } from "react-i18next";
import { TableReserverStyles } from "../../Static/TableReserverStyles.static";
import { ITableReserverContent } from "../../Interfaces/ITableReserverComponent.interface";
import { FormStyleSeparator, ParseComponentStyle } from "sitebuilder-common";
import ContactUsPlaceholder from "../../../../Assets/placeholders/reservation_placeholder.jpg";
import HTMLParser from "html-react-parser";
import Overlay from "../../View/Overlay.view";
import moment from "moment";

export interface ITableReserverProps extends IDomElement<ITableReserverContent> {}

export const TableReserver: React.FC<ITableReserverProps> = (props) => {
    const { t, i18n } = useTranslation<ILang>();
    const { images } = useImages();
    const currentLang = i18n.language;

    const { refs, size, id, showOverlay, ...events } = DnDEvents<ITableReserverContent>(
        props,
        "ELEMENT"
    );

    let style = ParseComponentStyle(props.content, TableReserverStyles, size);
    const rawText = props.content?.[currentLang]?.text || t<ILang>("reserve_table_desc");
    const htmlText = rawText.replace(/\n/g, "<br />");
    const imageFile = images[props.content.src];
    const { body, text, image } = FormStyleSeparator(style);

    return (
        <div
            id={id}
            {...events}
            style={body}
            className="dom__form dom__table-reserver"
            ref={refs.current[props.uuid]}
        >
            {showOverlay && <Overlay parent={props} unmountId={props.sectionUUID} />}
            <div className="dom__form--image dom__table-reserver--left">
                <img
                    src={imageFile ? imageFile.url : ContactUsPlaceholder}
                    className={"img dom__table-reserver--image"}
                    alt={"Table reserver"}
                    draggable={false}
                    style={image}
                />
            </div>
            <div className="dom__table-reserver--right">
                <h1 className={"dom__form--title dom__table-reserver--title"}>
                    {t<ILang>("reserve_table_title")}
                </h1>
                <div style={text} className={"dom__table-reserver--desc"}>
                    {HTMLParser(htmlText)}
                </div>
                <form
                    onSubmit={(e) => e.preventDefault()}
                    className="dom__form--element dom__table-reserver--form"
                >
                    <TextInput
                        id={"name"}
                        onChange={() => {}}
                        value={"Kiss József"}
                        labelText={"what_name"}
                    />
                    <TextInput
                        id={"phone"}
                        onChange={() => {}}
                        value={"+36 30 999 88 77"}
                        labelText={"what_phone"}
                    />
                    <TextInput
                        id={"email"}
                        onChange={() => {}}
                        value={"example@gmail.com"}
                        labelText={"what_email"}
                    />
                    <TextInput
                        id={"datetime"}
                        onChange={() => {}}
                        value={moment().format("YYYY/MM/DD HH:mm")}
                        labelText={"what_datetime"}
                    />
                    <TextInput
                        id={"howmanypeople"}
                        onChange={() => {}}
                        value={"5"}
                        labelText={"what_howmanypeople"}
                    />
                    <TextArea id={"message"} onChange={() => {}} value={t<ILang>("message_text")} />
                    <ClientButton type={"submit"}>{t<ILang>("send")}</ClientButton>
                </form>
            </div>
        </div>
    );
};
