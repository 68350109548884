import { IDomElement, IExistingDragData, ISection } from "../Interfaces/IDomTypes.interface";
import { SPECIAL_ELEMENT_TYPES } from "../../../Static/SpecialTypes.static";
import { ReIndexIfHasIndex } from "./Reindex.util";
import { DomlessPageModel } from "../Model/DomlessPage.model";
import { PageDataModel } from "../Model/PageData.model";
import { ITargetKeys } from "../Interfaces/ITargetKeys.interface";
import { ReSortNodes } from "./ResortNodes.util";
import { clone } from "lodash";

const unmountOriginalNode = (
    clonedState: PageDataModel[],
    dragOrigin: IExistingDragData,
    currentPage: DomlessPageModel
): ISection | null => {
    const isSpecial = dragOrigin.type in SPECIAL_ELEMENT_TYPES;

    for (let pageIndex in clonedState) {
        const page = clonedState[pageIndex];
        if (!page.draft || page.page_id !== currentPage.page_id) continue;

        for (let sectionIndex in page.draft.draft_elements.dom) {
            const section = page.draft.draft_elements.dom[sectionIndex];
            if (
                section.uuid === dragOrigin.uuid ||
                (isSpecial &&
                    (dragOrigin as never as IDomElement<any>).sectionUUID === section.uuid)
            ) {
                //Remove the original section node
                const removedNodes = page.draft.draft_elements.dom.splice(
                    parseInt(sectionIndex),
                    1
                );
                if (removedNodes.length > 0) {
                    return removedNodes[0];
                }
            }

            //Skip column and element check if the drag origin is a section or a special element.
            if (dragOrigin.type === "Section" || isSpecial) continue;
            if (dragOrigin.sectionUUID !== section.uuid) continue;

            for (let columnIndex in section.elements) {
                const column = section.elements[columnIndex];

                if (dragOrigin.columnUUID === column.uuid) {
                    const elementIndex = column.elements.findIndex(
                        (element) => element.uuid === dragOrigin.uuid
                    );
                    if (elementIndex > -1) {
                        column.elements.splice(elementIndex, 1);
                        return null;
                    }
                }
            }
        }
    }
    return null;
};

const mountRemovedNode = (
    clonedState: PageDataModel[],
    dragOrigin: IExistingDragData,
    currentPage: DomlessPageModel,
    removedNode: ISection | IDomElement<any> | null,
    targetKeys: ITargetKeys,
    targetIndex: number
): void => {
    const isSpecial = dragOrigin.type in SPECIAL_ELEMENT_TYPES;

    for (let page of clonedState) {
        if (!page.draft || page.page_id !== currentPage.page_id) continue;

        if (removedNode?.type === "Section") {
            ReIndexIfHasIndex(page.draft.draft_elements.dom, targetIndex);
            removedNode.index = targetIndex;
            page.draft.draft_elements.dom.push(removedNode);
            return;
        }
        //Skip column and element check if the drag origin is a section or a special element.
        if (dragOrigin.type === "Section" || isSpecial) continue;

        for (let section of page.draft.draft_elements.dom) {
            if (targetKeys.sectionUUID !== section.uuid) continue;

            for (const column of section.elements) {
                if (targetKeys.columnUUID === column.uuid) {
                    ReIndexIfHasIndex(column.elements, targetIndex);
                    const origin = clone(dragOrigin);
                    origin.columnUUID = column.uuid;
                    origin.sectionUUID = section.uuid;
                    origin.index = targetIndex;
                    column.elements.push(origin);
                }
            }
        }
    }
};

export const MoveNode = (
    clonedState: PageDataModel[],
    dragOrigin: IExistingDragData,
    currentPage: DomlessPageModel,
    targetKeys: ITargetKeys,
    targetIndex: number
): void => {
    const removedNode = unmountOriginalNode(clonedState, dragOrigin, currentPage);
    mountRemovedNode(clonedState, dragOrigin, currentPage, removedNode, targetKeys, targetIndex);
    ReSortNodes(clonedState);
};
