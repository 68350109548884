import { ISelectInputProps } from "../SelectInput.view";

export const SelectClear = <O extends object, F extends object, L = string>(
    e: MouseEvent,
    props: ISelectInputProps<O, F, L>
): void => {
    e.stopPropagation();
    e.preventDefault();
    props.onChange(props.id, "", null);
};
