import { AXIOS_NAMES } from "../Static/AxiosInstanceNames.static";
import { axios, IDynamicObject } from "xa-generics";
import { RestaurantModel, SocialModel, ConfigModel } from "sitebuilder-common";

export abstract class RootDAO {
    protected static pid: string = "";
    protected static rid: string = "";

    protected static v3url(isRidIncluded: boolean = true, isAdmin: boolean = true): string {
        let url: string = `/api/v3`;
        if (isAdmin) url += "/admin";
        url += `/partners/${this.pid}`;
        if (isRidIncluded) url += `/restaurants/${this.rid}`;
        return url;
    }
    protected static baseUrl(): string {
        return `/api/partners/${this.pid}/restaurants/${this.rid}`;
    }
    public static setPartnerID(pid: string): void {
        this.pid = pid;
    }
    public static setRestID(rid: string): void {
        this.rid = rid;
    }
}

export abstract class RestaurantDAO extends RootDAO {
    //URL GENERATOR
    private static confUrl(): string {
        return `${this.baseUrl()}/configs`;
    }

    //API DAO
    public static async loadRestaurant(): Promise<RestaurantModel> {
        const request = await axios
            .getInstance(AXIOS_NAMES.ONEMIN)
            .get<IDynamicObject>(`${this.v3url()}`);
        return new RestaurantModel(request.data);
    }

    public static async loadSuperAdminRestaurants(): Promise<RestaurantModel[]> {
        const request = await axios
            .getInstance(AXIOS_NAMES.ONEMIN)
            .get<IDynamicObject[]>(`/api/v3/super-admin/restaurants`);
        return request.data.map((item) => new RestaurantModel(item));
    }

    public static async loadPartner(): Promise<RestaurantModel[]> {
        const request = await axios
            .getInstance(AXIOS_NAMES.ONEMIN)
            .get<IDynamicObject[]>(`${this.v3url(false, true)}/restaurants`);
        return request.data.map((item) => new RestaurantModel(item));
    }

    public static async loadSocial(): Promise<SocialModel> {
        const request = await axios
            .getInstance(AXIOS_NAMES.ONEMIN)
            .get<IDynamicObject>(`${this.v3url(true)}/socials`);
        return new SocialModel(request.data);
    }

    public static async loadConfig(): Promise<ConfigModel> {
        const request = await axios.getInstance().get<IDynamicObject>(`${this.confUrl()}`);
        return new ConfigModel(request.data);
    }

    public static async updateConfig(data: IDynamicObject<any>): Promise<ConfigModel> {
        const request = await axios.getInstance().put<IDynamicObject>(`${this.confUrl()}`, data);
        return new ConfigModel(request.data);
    }

    public static async init(rest: RestaurantModel): Promise<void> {
        const langs: string[] = [rest.primary_language];
        if (rest.secondary_language) langs.push(rest.secondary_language);

        await axios
            .getInstance()
            .post<void>(`/api/partners/${this.pid}/restaurants/${this.rid}/init`, {
                og_title: rest.name,
                langs
            });
        return;
    }
}
