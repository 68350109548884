import { IReset } from "./../../UseForm/IUseForm.interface";

type IEvent = React.MouseEvent<Element, MouseEvent>;

export const ResetValues = <Fields extends object>(
    e: IEvent,
    /**
     * This is either a style group like 'PaddingStyles' or in case of complex rules like
     * boxShadow that is built from multiple inputs and the form has default values,
     * it should be the DEFAULT_BOX_SHADOW. The main editor onReset is NOT called in this case
     * as the 'boxShadow' should be reset instead of offsetX, offsetY, blurRadius, etc...
     */
    formDefaults: Fields,
    formReset: (fieldIds: IReset<any>[]) => void,
    /**
     * As mentioned above, this shouldn't be defined in case of complex css rules like
     * boxShadow and textShadow.
     */
    mainEditorOnReset?: (keys: Exclude<keyof Fields, "unit">[]) => void
): void => {
    e.preventDefault();
    const Keys = Object.keys(formDefaults) as (keyof Fields)[];
    const keylist: IReset<any>[] = [];
    let indexOfUnit: string = "";
    for (let index in Keys) {
        const field = Keys[index];
        if (field === "unit") {
            indexOfUnit = index;
            continue;
        }
        if (formDefaults && field in formDefaults) {
            keylist.push({ field, value: formDefaults[field] });
        } else keylist.push({ field, value: "" });
    }
    //Removes the 'unit' from the form keys
    if (indexOfUnit) Keys.splice(parseInt(indexOfUnit), 1);
    formReset(keylist);

    if (mainEditorOnReset) mainEditorOnReset(Keys as Exclude<keyof Fields, "unit">[]);
};
