import { useDom } from "../../DomTracker/Controller/DomTracker.provider";
import { useEditor } from "../Controller/EditorContext.provider";
import { PageEditor } from "../Controller/PageEditor.controller";
import { ProductsEditor } from "./Products/ProductsEditor.index";
import { ThankYouEditor } from "./ThankYouEditor.view";
import { useWidgetEditor } from "../Controller/WidgetEditorContext.provider";
import { INavbarSettings } from "../../WidgetModules/Navbar/Interfaces/INavbarWidget.interface";
import { ImageEditorView } from "./ImageEditor.view";
import { IFooterSettings } from "../../WidgetModules/Footer/Interfaces/IFooterWidget.interface";
import { useAfterTriggerChanged, useTimeout } from "xa-generics";
import TableReserverEditorView from "./TableReserverEditor.view";
import ContactFormEditorView from "./ContactFormEditor.view";
import BulletListEditorView from "./BulletListEditor.view";
import NavEditorController from "../Controller/NavEditor.controller";
import AppStoreEditorView from "./AppStoreEditor.view";
import GalleryEditorView from "./GalleryEditor.view";
import SectionEditorView from "./SectionEditor.view";
import FooterEditorView from "./Footer/FooterEditor.index";
import ColumnEditorView from "./ColumnEditor.view";
import HeaderEditorView from "./HeaderEditor.view";
import TextEditorView from "./TextEditor.view";
import LinkEditorView from "./LinkEditor.view";
import MapEditorView from "./MapEditor.view";
import PageListView from "./Page/PageList.view";
import { SlideshowEditorView } from "./SlideshowEditor.view";
import { VideoEditor } from "./VideoEditor.view";

export interface IShowEditorByTypeViewProps {
    showCMPS: boolean;
    showConfig: boolean;
    showHistory: boolean;
    showTemplates: boolean;
    setShowCMPS: (value: boolean) => void;
}

const ShowEditorByTypeView: React.FC<IShowEditorByTypeViewProps> = (props) => {
    const editorContext = useEditor();
    const widgetEditorContext = useWidgetEditor();
    const { size } = useDom();
    const { setTm } = useTimeout();

    const editor =
        editorContext.editor || editorContext.pageEditor || widgetEditorContext.widgetEditor.group;

    const returnContent = (): JSX.Element => {
        let subKey: string = "";
        switch (editor?.type) {
            case "Page":
                return <PageEditor key={`${editor.page_id}-${size}`} page={editor} />;
            case "Section":
                return <SectionEditorView key={`${editor.uuid}-${size}`} editor={editor} />;
            case "Column":
                return <ColumnEditorView key={`${editor.uuid}-${size}`} editor={editor} />;
            case "Header":
                return <HeaderEditorView key={`${editor.uuid}-${size}`} editor={editor} />;
            case "Text":
                return <TextEditorView key={`${editor.uuid}-${size}`} editor={editor} />;
            case "Link":
                return <LinkEditorView key={`${editor.uuid}-${size}`} editor={editor} />;
            case "LinkButton":
                return (
                    <LinkEditorView key={`${editor.uuid}-${size}`} editor={editor} isButtonType />
                );
            case "Image":
                return <ImageEditorView key={`${editor.uuid}-${size}`} editor={editor} />;
            case "Video":
                return <VideoEditor key={`${editor.uuid}-${size}`} editor={editor} />;
            case "List":
                return <BulletListEditorView key={`${editor.uuid}-${size}`} editor={editor} />;
            case "ContactForm":
                return <ContactFormEditorView key={`${editor.uuid}-${size}`} editor={editor} />;
            case "TableReserver":
                return <TableReserverEditorView key={`${editor.uuid}-${size}`} editor={editor} />;
            case "Gallery":
                return <GalleryEditorView key={`${editor.uuid}-${size}`} editor={editor} />;
            case "RestMap":
                return <MapEditorView key={`${editor.uuid}-${size}`} editor={editor} />;
            case "AppStore":
                return <AppStoreEditorView key={`${editor.uuid}-${size}`} editor={editor} />;
            case "Products":
                return <ProductsEditor key={`${editor.uuid}`} editor={editor} />;
            case "SlideShow":
                return <SlideshowEditorView key={`${editor.uuid}`} editor={editor} />;
            case "Navbar":
                subKey = widgetEditorContext.widgetEditor.subKey;
                return (
                    <NavEditorController
                        key={`${editor.uuid}`}
                        editor={editor}
                        subKey={subKey as keyof INavbarSettings}
                    />
                );
            case "Footer":
                subKey = widgetEditorContext.widgetEditor.subKey;
                return (
                    <FooterEditorView
                        key={`${editor.uuid}`}
                        editor={editor}
                        subKey={subKey as keyof IFooterSettings}
                    />
                );
            case "ThankYou":
                return <ThankYouEditor key={`${editor.uuid}`} />;
            default:
                return <PageListView setShowCMPS={props.setShowCMPS} />;
        }
    };

    useAfterTriggerChanged(() => {
        if (editor && "uuid" in editor) {
            setTm(
                () => {
                    const element = document.getElementById(editor.uuid);
                    if (!element) return;

                    element.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "center"
                    });
                },
                600,
                "scroll-to-edited-item"
            );
        }
    }, [size, editor]);

    return (
        <>
            {!props.showCMPS && !props.showHistory && !props.showConfig && !props.showTemplates && (
                <div className="editor__content">{returnContent()}</div>
            )}
        </>
    );
};

export default ShowEditorByTypeView;
