import {
    INavbar,
    INavbarLink,
    INavbarSettings
} from "../../../WidgetModules/Navbar/Interfaces/INavbarWidget.interface";
import { ILang } from "../../../../Interfaces/ILang.type";
import { ISetState } from "xa-generics";
import { IPageEditor } from "../../../DomMapper/Interfaces/IPageEditor.interface";
import { useTranslation } from "react-i18next";
import { MutableRefObject } from "react";
import { IGenericCSSFields } from "sitebuilder-common";
import { IBlur, IFieldBlur } from "../../Utils/EditorHook.util";
import { NavbarLinksEditorView } from "./NavbarLinksEditor.view";
import { NavbarButtonEditorView } from "./NavbarButtonEditor.view";
import { NavbarDetailsEditorView } from "./NavbarDetailsEditor.view";
import { INavEditorControllerProps } from "../../Controller/NavEditor.controller";
import { InputGroupContextProvider } from "../../../UI/InputGrouper/InputGroup.provider";
import { NavbarRegBtnEditorView } from "./NavbarRegEditor.view";
import { NavbarBottomEditorView } from "./NavbarBottomBarEditor.view";
import { NavbarStatusEditorView } from "./NavbarStatus.view";
import { NavbarLoginEditorView } from "./NavbarLoginEditor.view";
import { NavbarLangEditorView } from "./NavbarLangEditor.view";
import { NavbarLinkEditorView } from "./NavbarLinkEditor.view";
import { NavbarNameEditorView } from "./NavbarName.view";
import { GroupContextProvider } from "../../../UI/Grouper/Group.provider";
import { NavbarTopEditorView } from "./NavbarTopBarEditor.view";
import { INavbarVersions } from "../../../WidgetModules/Navbar/Interfaces/INavbar.type";
import { IWidgetGroups } from "../../../Widgets/Interfaces/IWidgetGroups.type";
import { PageDataModel } from "../../../DomTracker/Model/PageData.model";
import { WidgetPublish } from "../WidgetPublish.view";

export interface INavbarEditorProps extends INavEditorControllerProps {
    editor: INavbar;
    addNewLink: () => void;
    navLinks: INavbarLink[];
    navVersion: INavbarVersions;
    draggedLink: INavbarLink | null;
    setNavLinks: ISetState<INavbarLink[]>;
    deleteLink: (link: INavbarLink) => void;
    dndTargetIndex: MutableRefObject<number>;
    toggleNewTab: (link: INavbarLink) => void;
    toggleExternal: (link: INavbarLink) => void;
    setDraggedLink: ISetState<INavbarLink | null>;
    insertLinkAt: (targetLink?: INavbarLink) => void;
    onLinkRefToPage: (link: INavbarLink, page: PageDataModel) => void;
    onLinkChange: (link: INavbarLink, field: keyof IPageEditor, value: string) => void;
    handleChange: (key: keyof INavbarSettings, data: IBlur<any> | IFieldBlur<any>) => void;
    onReset: (widgetProp: keyof INavbarSettings, keys: (keyof IGenericCSSFields)[]) => void;
}

const NavbarEditorView: React.FC<INavbarEditorProps> = (props) => {
    const { t } = useTranslation<ILang>();

    return (
        <>
            <div className="editor__header">
                {t<ILang>("navbar")} / {t<ILang>(props.subKey)}
            </div>
            <GroupContextProvider<IWidgetGroups> defaultActive={props.subKey || "links"}>
                <InputGroupContextProvider defaultActive={"BackgroundGroup"}>
                    <NavbarLinksEditorView {...props} />

                    <NavbarDetailsEditorView {...props} />

                    <NavbarLinkEditorView {...props} />

                    <NavbarButtonEditorView {...props} />

                    <NavbarNameEditorView {...props} />

                    <NavbarLangEditorView {...props} />

                    <NavbarLoginEditorView {...props} />

                    <NavbarRegBtnEditorView {...props} />

                    <NavbarStatusEditorView {...props} />

                    <NavbarTopEditorView {...props} />

                    <NavbarBottomEditorView {...props} />
                </InputGroupContextProvider>
            </GroupContextProvider>
            <WidgetPublish widgetType={"Navbar"} />
        </>
    );
};

export default NavbarEditorView;
