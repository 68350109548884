import { Add } from "@carbon/icons-react";
import { ILang } from "../../../Interfaces/ILang.type";
import { useDom } from "../../DomTracker/Controller/DomTracker.provider";
import { CartView } from "../../WidgetModules/Cart/View/Cart.view";
import { ISection } from "../../DomTracker/Interfaces/IDomTypes.interface";
import { useImages } from "../../../Contexts/Images.context";
import { ThankYouView } from "../../WidgetModules/ThankYou/View/ThankYou.view";
import { PageDataModel } from "../../DomTracker/Model/PageData.model";
import { IDynamicObject } from "xa-generics";
import { useTranslation } from "react-i18next";
import { ProfileController } from "../../WidgetModules/Profile/Controller/Profile.controller";
import { ForgottenPasswordView } from "../../WidgetModules/ForgottenPassword/View/ForgottenPassword.view";
import DomSection from "../DomComponents/Section/Section.view";

export interface IDomMapperViewProps {
    page: PageDataModel;
    isMappingDraft?: boolean;
}

const DomMapperView: React.FC<IDomMapperViewProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { images } = useImages();
    const { size } = useDom();
    const domSrc = props.isMappingDraft
        ? props.page.draft!.draft_elements.dom
        : props.page.elements.dom;

    const nodes = [];
    const sectionSlideChildren: IDynamicObject<ISection[]> = {};

    for (let section of domSrc) {
        if (section.content?.childOf) {
            if (!(sectionSlideChildren[section.content.childOf] instanceof Array)) {
                sectionSlideChildren[section.content.childOf] = [];
            }
            sectionSlideChildren[section.content.childOf].push(section);
        }
    }
    const firstElement = domSrc[0];
    const lastElement = domSrc[domSrc.length - 1];

    for (let index in domSrc) {
        const section = domSrc[index];
        if (section.content.childOf) continue;
        nodes.push(
            <DomSection
                childSections={sectionSlideChildren[section.uuid]}
                isFirst={firstElement?.uuid === section.uuid}
                isLast={lastElement?.uuid === section.uuid}
                key={section.uuid}
                section={section}
                index={+index}
            />
        );
    }
    const cmp_cls = ["competition-image-container"];
    if (props.page.competition_image_align === "bottom") cmp_cls.push("--BOTTOM");
    else cmp_cls.push("--TOP");

    return (
        <>
            {nodes}
            {props.page.competition_image_id &&
                images[props.page.competition_image_id] &&
                size !== "xs" &&
                size !== "sm" && (
                    <div className={cmp_cls.join(" ")}>
                        <img
                            alt={"EU Support"}
                            className={"competition-image"}
                            src={images[props.page.competition_image_id]!.url}
                        />
                    </div>
                )}
            {props.page.page_type === "profile" && <ProfileController />}
            {props.page.page_type === "thank_you" && <ThankYouView />}
            {props.page.page_type === "forgotten_password" && <ForgottenPasswordView />}
            {props.page.page_type === "cart" && <CartView />}
            {nodes.length === 0 && !props.page.page_type && (
                <div className="no-page-elements-container">
                    <div className="no-page-elements-info">
                        {t<ILang>("no_page_element_info")}
                        <div className="svg">
                            <Add size={32} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DomMapperView;
