import { Context, createContext, useState, useContext } from "react";
import { IDynamicObject, IError, ILoading, useMountWithTriggers } from "xa-generics";
import { DictModel, TextModel } from "sitebuilder-common";
import { useTranslation } from "react-i18next";
import { useRestaurant } from "./Restaurant.context";
import { cloneDeep } from "lodash";
import { TextsDAO } from "../DAO/Texts.dao";
import { FloatingError } from "xa-error-with-lang";

export interface ITextsContext {
    error: IError;
    dict: DictModel[];
    texts: TextModel[];
    loading: ILoading;
    loadDictionary: () => void;
    updateDictionary: (dictionary: DictModel) => void;
    deleteDictionary: (dictionaryId: string) => void;
    saveDictionary: (dictionary: DictModel) => void;
}
const TextsContext: Context<ITextsContext> = createContext<ITextsContext>(null as any);
TextsContext.displayName = "TextsContext";

interface ITextsContextProviderProps {}
export const TextsContextProvider: React.FC<ITextsContextProviderProps> = (props) => {
    const { restaurant } = useRestaurant();
    const { i18n } = useTranslation();

    const [loading, setLoading] = useState<ILoading>(false);
    const [error, setError] = useState<IError>(null);
    const [texts, setTexts] = useState<TextModel[]>([]);
    const [dict, setDict] = useState<DictModel[]>([]);

    const loadTexts = (): void => {
        setLoading(
            TextsDAO.loadTexts()
                .then((data) => setTexts(data))
                .catch((error) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    const loadDictionary = (): void => {
        setLoading(
            TextsDAO.loadDictionary()
                .then((data) => setDict(data))
                .catch((error) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    const saveDictionary = (dictionary: DictModel): void => {
        setLoading(
            TextsDAO.saveDictionary(dictionary)
                .then((data) => {
                    setDict((current) => {
                        const state = cloneDeep(current);
                        state.push(data);
                        return state;
                    });
                })
                .catch((error) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    const updateDictionary = (dictionary: DictModel): void => {
        setLoading(
            TextsDAO.updateDictionary(dictionary)
                .then(() => {
                    const index = dict.findIndex(
                        (item) => item.dictionary_id === dictionary.dictionary_id
                    );
                    dict[index] = dictionary;
                    setDict((current) => {
                        const state = cloneDeep(current);
                        state[index] = dictionary;
                        return state;
                    });
                })
                .catch((error) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    const deleteDictionary = (dictionaryId: string): void => {
        setLoading(
            TextsDAO.deleteDictionary(dictionaryId)
                .then(() => {
                    const index = dict.findIndex((item) => item.dictionary_id === dictionaryId);
                    dict.splice(index, 1);
                    setDict([...dict]);
                })
                .catch((error) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    useMountWithTriggers(() => {
        if (dict.length === 0) return;
        if (!restaurant?.id) return;

        const primary = restaurant.primary_language;
        const secondary = restaurant.secondary_language;

        let translations: IDynamicObject<IDynamicObject<string>> = {
            [primary]: {}
        };
        if (secondary) translations[secondary] = {};

        for (const d of dict) {
            translations[primary][d.text_key] = d.primary_text;
            if (secondary) translations[secondary][d.text_key] = d.secondary_text;
        }

        i18n.addResourceBundle(primary, "translation", translations[primary], true, true);
        i18n.reloadResources(primary, "translation");

        if (translations[secondary]) {
            i18n.addResourceBundle(secondary, "translation", translations[secondary], true, true);
            i18n.reloadResources(secondary, "translation");
        }
    }, [dict, restaurant]);

    useMountWithTriggers(() => {
        if (restaurant?.id) {
            loadTexts();
            loadDictionary();
        }
    }, [restaurant]);

    return (
        <TextsContext.Provider
            value={{
                dict,
                error,
                texts,
                loading,
                loadDictionary,
                saveDictionary,
                updateDictionary,
                deleteDictionary
            }}
        >
            <FloatingError error={error} resetError={() => setError(null)} />
            {props.children}
        </TextsContext.Provider>
    );
};

export const useTexts = (): ITextsContext => useContext(TextsContext);
