import { Crop } from "react-image-crop";
import { ILang } from "../../../../../Interfaces/ILang.type";
import { useImages } from "../../../../../Contexts/Images.context";
import { ErrorHandler } from "xa-error-with-lang";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import { IImageInputProps } from "../Controller/ImageInput.controller";
import { IImageDimensions } from "../../../../../Interfaces/IImageDimensions.interface";
import { IImageInputViewProps } from "./ImageInput.view";
import ReactCrop from "react-image-crop";
import Loading from "../../../Loading/Loading.view";
import Modal from "../../../Modal/Modal.view";
import "react-image-crop/dist/ReactCrop.css";

interface IImageCropProps<Fields extends object> extends IImageInputViewProps<Fields> {
    file: File;
    onChange: IImageInputProps<Fields>["onChange"];
}

export const ImageCrop = <Fields extends object>(props: IImageCropProps<Fields>) => {
    const { t } = useTranslation<ILang>();
    const { saveImage, loading, error } = useImages();
    const [crop, setCrop] = useState<Partial<Crop>>({
        aspect: props.aspectRatio
    });
    const [croppedImage, setCroppedImage] = useState<null | Blob>(null);
    const [croppedImgUrl, setCroppedImgUrl] = useState<string>("");
    const [imgDimensions, setImgDimensions] = useState<null | IImageDimensions>(null);

    const imageRef = useRef<HTMLImageElement>();
    const fileUrl = useRef<string>();

    const onImageLoaded = (image: HTMLImageElement) => {
        imageRef.current = image;
        const localCrop: Crop = {
            width: image.width,
            height: image.height,
            aspect: props.aspectRatio,
            unit: "px",
            x: 0,
            y: 0
        };
        setCrop(localCrop);
        makeClientCrop(localCrop);
    };

    const makeClientCrop = async (crop: Crop): Promise<void> => {
        if (imageRef.current && crop.width && crop.height) {
            const cropUrl = await getCroppedImg(imageRef.current, crop);
            setCroppedImgUrl(cropUrl);
        }
    };

    const getCroppedImg = (image: HTMLImageElement, crop: Crop): Promise<string> => {
        const canvas = document.createElement("canvas");
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext("2d");

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx!.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx!.imageSmoothingQuality = "high";

        ctx!.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        //reject(new Error('Canvas is empty'));
                        console.error("Canvas is empty");
                        return;
                    }
                    window.URL.revokeObjectURL(fileUrl.current!);
                    fileUrl.current = window.URL.createObjectURL(blob);
                    const image = new Image();
                    image.onload = () => {
                        setImgDimensions({
                            width: image.width,
                            height: image.height
                        });
                    };
                    image.src = fileUrl.current;
                    setCroppedImage(blob);
                    resolve(fileUrl.current);
                },
                props.file.type,
                0.8
            );
        });
    };

    const classes: string[] = ["image-cropper__react-crop"];
    if (crop.height && crop.width && crop.height >= crop.width) classes.push("tall");
    else classes.push("wide");

    return (
        <Modal
            size={"LG"}
            onClose={props.clearFile}
            heading={t<ILang>("image_crop")}
            onSubmit={() => {
                if (!croppedImage || !imgDimensions) return;
                saveImage(croppedImage, imgDimensions, props.file.name, (newImage) => {
                    props.onChange(props.id, newImage.image_id);
                    props.setShowSelector(false);
                    props.setInternalFile(newImage.image_id);
                    props.setFileSrc("");
                });
            }}
        >
            <Loading loading={loading} />
            <ErrorHandler error={error} />
            <div className="image-cropper">
                <h4 className="image-cropper__title">{t<ILang>("original_image")}</h4>
                <ReactCrop
                    crop={crop}
                    ruleOfThirds
                    src={props.fileSrc}
                    onChange={(c) => setCrop(c)}
                    onImageLoaded={onImageLoaded}
                    onComplete={(c) => makeClientCrop(c)}
                />
            </div>
            {croppedImgUrl && (
                <div className="image-cropper image-cropper__preview">
                    <h4 className="image-cropper__title">{t<ILang>("cropped_image")}</h4>
                    <img
                        src={croppedImgUrl}
                        alt={t<ILang>("cropped_image")}
                        className={"cropped-image"}
                    />
                </div>
            )}
        </Modal>
    );
};
