import { useApp } from "../../Provider/App.context";
import { useImages } from "../../../../Contexts/Images.context";
import { FC, useRef } from "react";
import { IPartialCSS } from "sitebuilder-common";
import { useProducts } from "../../../DomMapper/DomComponents/Products/Context/ProductsContext.provider";
import { AppProducts } from "./AppProducts.view";
import { ProductModel } from "../../../DomMapper/DomComponents/Products/Models/Product.model";
import { AppV2Products } from "./AppV2Products.view";
import { ISelectorData } from "../../../DomMapper/DomComponents/Products/Interfaces/ISelectorData.type";
import { SubproductModel } from "../../../DomMapper/DomComponents/Products/Models/Subproduct.model";
import { IProductSelector } from "../../../DomMapper/DomComponents/Products/Interfaces/IProductSelector.interface";
import { AppCategoriesView } from "./AppCategories.view";
import { AppV2CategoriesView } from "./AppV2Categories.view";
import { ProductCategoryModel } from "../../../DomMapper/DomComponents/Products/Models/ProductCategory.model";
import { AppSubproductController } from "./AppSubproducts.controller";
import { IAppOrderControllerProps } from "./AppOrder.controller";
import Loading from "../../../UI/Loading/Loading.view";

export interface IAppOrderIndexProps extends IAppOrderControllerProps {
    appProudctNavigate: (
        data: ProductCategoryModel | ProductModel | SubproductModel | null
    ) => void;
    unsetSelector: (data: keyof IProductSelector) => void;
    setSelectorData: (data: ISelectorData) => void;
    selector: IProductSelector;
    currency: string;
}

export const AppOrderIndex: FC<IAppOrderIndexProps> = (props) => {
    const { data, dataSource } = useApp();
    const { loading } = useProducts();
    const { images } = useImages();

    const app = data[dataSource];
    const product = app.product;
    const containerRef = useRef<HTMLDivElement | null>(null);

    const classes: string[] = ["app-products"];
    const style: IPartialCSS<any> = {};

    if (app.global.isRounded) classes.push("rounded-corners");
    if (app.global.isDarkTheme) classes.push("dark-app-products");

    if (product.backgroundImage) {
        style.backgroundImage = `url(${images[product.backgroundImage].url})`;
        delete style.backgroundColor;
    }
    if (product.backgroundAttachment) style.backgroundAttachment = product.backgroundAttachment;
    if (product.backgroundPosition) style.backgroundPosition = product.backgroundPosition;
    if (product.backgroundRepeat) style.backgroundRepeat = "repeat";
    else style.backgroundRepeat = "no-repeat";
    if (product.backgroundSize) style.backgroundSize = product.backgroundSize;
    if (product.version === "V2") classes.push("v2-container");

    return (
        <div className={classes.join(" ")} style={style}>
            {loading ? (
                <Loading loading={loading} />
            ) : product.version === "V1" ? (
                <>
                    <AppCategoriesView {...props} />
                    <AppProducts {...props} />
                </>
            ) : (
                <>
                    <AppV2CategoriesView {...props} containerRef={containerRef} />
                    <AppV2Products {...props} containerRef={containerRef} />
                </>
            )}
            <AppSubproductController {...props} />
        </div>
    );
};
