import { FC } from "react";
import { ArrowLeft, ArrowRight, Close } from "@carbon/icons-react";
import { ISetState, useMountWithTriggers } from "xa-generics";

interface IGalleryImageViewerProps {
    imageSrcs: string[];
    imageCount: number;
    viewImage: number;
    setViewImage: ISetState<number>;
}

export const GalleryImageViewer: FC<IGalleryImageViewerProps> = (props) => {
    const handleLeft = (): void => {
        if (props.viewImage - 1 < 0) {
            return props.setViewImage(props.imageCount - 1);
        }
        return props.setViewImage(props.viewImage - 1);
    };

    const handleRight = (): void => {
        if (props.viewImage + 1 >= props.imageCount) {
            return props.setViewImage(0);
        }
        return props.setViewImage(props.viewImage + 1);
    };

    const handleKeys = (e: KeyboardEvent): void => {
        if (e.key === "Escape") {
            e.preventDefault();
            return props.setViewImage(-1);
        }
        if (e.key === "ArrowLeft") {
            e.preventDefault();
            return handleLeft();
        }
        if (e.key === "ArrowRight") {
            e.preventDefault();
            return handleRight();
        }
    };
    useMountWithTriggers(() => {
        window.addEventListener("keydown", handleKeys);
        return () => {
            window.removeEventListener("keydown", handleKeys);
        };
    }, [props.viewImage]);

    return (
        <div className={"dom__image-viewer"}>
            <div className="dom__image-viewer--heading">
                <div
                    className="dom__image-viewer--heading--close"
                    onClick={() => props.setViewImage(-1)}
                >
                    <Close size={24} />
                </div>
            </div>
            <div className="dom__image-viewer--left" onClick={handleLeft}>
                <ArrowLeft size={32} />
            </div>
            <div
                className="dom__image-viewer--container"
                onClick={(e) => {
                    e.stopPropagation();
                    props.setViewImage(-1);
                }}
            >
                <img
                    className={"dom__image-viewer--container--image"}
                    src={props.imageSrcs[props.viewImage]}
                    alt={"Gallery"}
                />
            </div>
            <div className="dom__image-viewer--right" onClick={handleRight}>
                <ArrowRight size={32} />
            </div>
        </div>
    );
};
