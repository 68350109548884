import { map } from "lodash";
import { Gallery } from "../Gallery/Gallery.view";
import { RestMap } from "../RestMap/RestMap.view";
import { DomImage } from "../Image/DomImage.view";
import { AppStore } from "../AppStore/AppStore.view";
import { DomVideo } from "../Video/Video.view";
import { Carousel } from "../../../UI/Carousel/Controller/Carousel.controller";
import { DnDEvents } from "../../Util/DND.util";
import { useImages } from "../../../../Contexts/Images.context";
// import { SlideShow } from "../SlideShow/SlideShow.view";
import { ContactForm } from "../ContactForm/ContactForm.view";
import { ColumnStyles } from "../../Static/ColumnStyles.static";
import { TableReserver } from "../TableReserver/TableReserver.view";
import { IColumnContent } from "../../Interfaces/IColumnComponent.interface";
import { IColumn, ISection } from "../../../DomTracker/Interfaces/IDomTypes.interface";
import { ProductsController } from "../Products/Controller/Products.controller";
import { ParseComponentStyle } from "sitebuilder-common";
import DomBulletList from "../List/DomBulletList.view";
import DomLinkButton from "../Link/DomLinkButton.view";
import DomHeader from "../Header/DomHeader.view";
import DomLink from "../Link/DomLink.view";
import DomText from "../Text/DomText.view";
import Overlay from "../../View/Overlay.view";

export interface IDomColumnProps {
    isSlideshowColumn?: boolean;
    childSections?: ISection[];
    column: IColumn;
}

const DomColumn: React.FC<IDomColumnProps> = (props) => {
    const { images } = useImages();
    const { refs, size, id, showOverlay, ...events } = DnDEvents<IColumnContent>(
        props.column,
        "Column",
        undefined,
        props.column.isSpecial
    );
    const classes: string[] = ["column"];
    if (props.column.isSpecial) classes.push("special-column");
    const style = ParseComponentStyle(props.column.content, ColumnStyles, size, images);

    return (
        <div className={classes.join(" ")} ref={refs.current[id]} style={style} {...events} id={id}>
            {showOverlay && (
                <Overlay
                    isPrimary
                    align={"left"}
                    parent={props.column}
                    isSpecial={props.column.isSpecial}
                    className={props.isSlideshowColumn ? "slideshow-column-overlay" : undefined}
                />
            )}
            {map(props.column.elements, (element) => {
                switch (element.type) {
                    case "Header":
                        return <DomHeader key={element.uuid} {...element} />;
                    case "Text":
                        return <DomText key={element.uuid} {...element} />;
                    case "Link":
                        return <DomLink key={element.uuid} {...element} />;
                    case "LinkButton":
                        return <DomLinkButton key={element.uuid} {...element} />;
                    case "List":
                        return <DomBulletList key={element.uuid} {...element} />;
                    case "Image":
                        return <DomImage key={element.uuid} {...element} />;
                    case "Video":
                        return <DomVideo key={element.uuid} {...element} />;
                    case "ContactForm":
                        return <ContactForm key={element.uuid} {...element} />;
                    case "TableReserver":
                        return <TableReserver key={element.uuid} {...element} />;
                    case "Gallery":
                        return <Gallery key={element.uuid} {...element} />;
                    case "RestMap":
                        return <RestMap key={element.uuid} {...element} />;
                    case "AppStore":
                        return <AppStore key={element.uuid} {...element} />;
                    case "Products":
                        return <ProductsController key={element.uuid} {...element} />;
                    case "SlideShow":
                        return (
                            <Carousel
                                childSections={props.childSections!}
                                key={element.uuid}
                                {...element}
                            />
                        );
                    default:
                        return null;
                }
            })}
        </div>
    );
};

export default DomColumn;
