import { ModelConstructor, IDynamicObject, ToMoneyInt } from "xa-generics";
import { AbstractProductCommonModel } from "./AbstractProductCommon.model";
import { ProductModel } from "./Product.model";

export class SubproductModel extends AbstractProductCommonModel {
    constructor(rawData?: IDynamicObject<any>) {
        super();
        if (!rawData) return;
        const data = rawData as SubproductMData;
        ModelConstructor(data, this);

        this.parsed_name = `${this.product_name}`;
        if (this.name) this.parsed_name += ` - ${this.name}`;
    }

    description: string = "";
    product_id: string = "";
    price: number = 0;
    description_sec_lang: string = "";
    visible_online: boolean = true;
    visible_sit_in: boolean = true;
    visible_phone: boolean = true;
    vat_id: string = "";
    sit_in_vat_id: string = "";
    gtsg_external_id: string = "";
    netpincer_id: string = "";
    recipe_id: string = "";

    product_name: string = "";
    parsed_name: string | null = null;

    option_group_ids: string[] = [];

    public toName(currency: string): string {
        let name = this.name;
        if (!this.name) name = `${this.price} ${currency}`;
        else name += ` - ${ToMoneyInt(this.price)} ${currency}`;
        return name;
    }

    public toFullName(product: ProductModel) {
        let name = product.name;
        if (this.name) name += ` ${this.name}`;
        return name;
    }
}

//If you add public methods, you may want to replace the empty string with them!
type SubproductMData = Partial<InstanceType<typeof SubproductModel>>;
