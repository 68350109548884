import {
    ISection,
    IAllDomKeys,
    IDomElement,
    IAllDomTypes,
    IComponentTypes,
    ISpecialDomKeys,
    IExistingDragData
} from "../Interfaces/IDomTypes.interface";
import { Context, createContext, createRef, useContext, useMemo, useRef, useState } from "react";
import {
    IDynamicObject,
    IError,
    ILoading,
    ISetState,
    useAfterTriggerChanged,
    useDidMount
} from "xa-generics";
import { cloneDeep, isEmpty, merge } from "lodash";
import { useLocation, useNavigate } from "react-router";
import { useActiveFontCollection } from "../../../Contexts/ActiveFontCollection.context";
import { SPECIAL_DOM_DEFAULTS } from "../../ComponentDnD/Static/SpecialDomDefaults.static";
import { ColumnChangeHandler } from "../Util/ColumnChange.util";
import { IGenericCSSFields } from "sitebuilder-common";
import { useChangeHistory } from "../../ComponentHistory/Controller/ComponentHistory.provider";
import { UpdateDomContent } from "../Util/UpdateDomContent.util";
import { DomlessPageModel } from "../Model/DomlessPage.model";
import { ISectionCustoms } from "../../DomMapper/Interfaces/ISectionComponent.interface";
import { IRefData, IRefs } from "../Interfaces/IRefs.type";
import { ICommonContent } from "sitebuilder-common";
import { IPageProcessor } from "../Interfaces/IPageProcessor.interface";
import { FindNodeByUUID } from "../Util/FindNode.util";
import { useTranslation } from "react-i18next";
import { IContentUpdate } from "../Interfaces/ContentUpdate.interface";
import { FloatingError } from "xa-error-with-lang";
import { PageDataModel } from "../Model/PageData.model";
import { useRestaurant } from "../../../Contexts/Restaurant.context";
import { ISeoGenerator } from "../Interfaces/ISeoGenerate.interface";
import { MountNewNode } from "../Util/MountNewNode.util";
import { v4 as uuidv4 } from "uuid";
import { IDomContext } from "../Interfaces/IDomContext.interface";
import { ReSortNodes } from "../Util/ResortNodes.util";
import { ITargetKeys } from "../Interfaces/ITargetKeys.interface";
import { UnmountNode } from "../Util/UnmountNode.util";
import { useProducts } from "../../DomMapper/DomComponents/Products/Context/ProductsContext.provider";
import { MoveNode } from "../Util/MoveNode.util";
import { ISizes } from "sitebuilder-common";
import { DomDAO } from "../DAO/Dom.dao";
import { ILang } from "../../../Interfaces/ILang.type";
import Loading from "../../UI/Loading/Loading.view";
import moment from "moment";

/**
 * ## DomTrackerContext
 */
const DomContext: Context<IDomContext> = createContext<IDomContext>(null as any);

DomContext.displayName = "DomTrackerContext";

interface IDomContextProviderProps {}

/**
 * ## DomTracker context provider component
 *
 */
export const DomContextProvider: React.FC<IDomContextProviderProps> = (props) => {
    const refs: IRefs = useRef<IRefData>({});
    const { t, i18n } = useTranslation<ILang>();
    const { categories } = useProducts();
    const { createHistory, createDefaultHistory, clearHistory } = useChangeHistory();
    const { pathname, search, hash } = useLocation();
    const { restaurant, createProductPicker, setCreateProductPicker } = useRestaurant();
    const { checkFontCollection } = useActiveFontCollection();

    const navigateTo = useNavigate();

    const [size, setSize] = useState<ISizes>("xl");
    const [error, setError] = useState<IError>(null);
    const [loading, setLoading] = useState<ILoading>(true);
    const [pages, setPages] = useState<PageDataModel[]>([]);
    const [subLoading, setSubLoading] = useState<ILoading>(false);
    const [lastUpdatedAt, setlastUpdatedAt] = useState<null | moment.Moment>(null);
    const [draggedNode, setDraggedNode] = useState<IComponentTypes | null>(null);
    const [currentPage, setCurrentPage] = useState<DomlessPageModel>(new DomlessPageModel());

    const capturedNode = useRef<IAllDomTypes | null | "PAGE_CONTAINER">(null);
    const language = i18n.language;

    const processInitRequest = async (
        pages: PageDataModel[],
        loadSetter: ISetState<ILoading>
    ): Promise<void> => {
        const indexOfIndexPage = pages.findIndex((page) => page.page_type === "index");
        const indexPage = pages[indexOfIndexPage]!;

        const clonePage = await DomDAO.clonePage(indexPage.page_id, restaurant);
        pages[indexOfIndexPage] = clonePage;

        const page_id = clonePage.page_id;
        const sectionUUID: string = uuidv4(),
            columnUUID: string = uuidv4(),
            elementUUID: string = uuidv4();
        MountNewNode(sectionUUID, pages, "Section", 0, { page_id }, true);
        MountNewNode(columnUUID, pages, "Column", 0, { page_id, sectionUUID }, true);
        const keys = { page_id, sectionUUID, columnUUID };
        MountNewNode(elementUUID, pages, "Products", 0, keys, true, SPECIAL_DOM_DEFAULTS.Products);
        ReSortNodes(pages);
        await DomDAO.saveDom(pages[indexOfIndexPage].draft!.draft_elements);
        await DomDAO.publishPage(clonePage.page_id);
        const finalPages = await DomDAO.loadPages(restaurant);

        setCreateProductPicker(false);
        processPages(finalPages);
        createDefaultHistory(finalPages);
        setPages(finalPages);
        setLoading(false);
    };

    const loadRestaurantPages = (pageId?: string): void => {
        const loadSetter = pageId ? setSubLoading : setLoading;
        loadSetter({
            reason: t<ILang>("reload_nodes"),
            promise: DomDAO.loadPages(restaurant)
                .then((pages) => {
                    if (error) setError(null);
                    if (createProductPicker) {
                        return processInitRequest(pages, loadSetter).catch((error) =>
                            setError(error)
                        );
                    }
                    processPages(pages);
                    createDefaultHistory(pages);
                    setPages(pages);
                    if (pageId) {
                        //INFO This branch runs only after publish and the used fonts should be recalculated on page publishes!
                        checkFontCollection(pages);
                        const page = pages.find((page) => page.page_id === pageId);
                        setCurrentPage(new DomlessPageModel(page, restaurant, false));
                    }
                })
                .catch((error) => setError(error))
                .finally(() => {
                    if (!createProductPicker) loadSetter(false);
                })
        });
    };

    const save = (newState?: PageDataModel[]): void => {
        const state = newState || pages;
        const indexOfPage = state.findIndex((page) => page.page_id === currentPage.page_id);
        if (indexOfPage === -1) return;
        if (!state[indexOfPage].draft?.draft_elements) return;
        DomDAO.saveDom(state[indexOfPage].draft!.draft_elements).catch((error) => setError(error));
    };

    const onPageCreate = (data: PageDataModel): void => {
        setSubLoading(
            DomDAO.clonePage(data.page_id, restaurant)
                .then((clonedPage) => {
                    const state = cloneDeep(pages);
                    const pageIndex = state.push(clonedPage) - 1;
                    const sectionUUID = uuidv4();
                    const columnUUID = uuidv4();

                    MountNewNode(sectionUUID, state, "Section", 0, { page_id: clonedPage.page_id });
                    MountNewNode(columnUUID, state, "Column", 0, {
                        page_id: clonedPage.page_id,
                        sectionUUID
                    });
                    refs.current[sectionUUID] = createRef<HTMLDivElement>();
                    refs.current[columnUUID] = createRef<HTMLDivElement>();
                    setPages(state);
                    setCurrentPage(new DomlessPageModel(state[pageIndex], restaurant, true));
                    createDefaultHistory([state[pageIndex]]);
                })
                .catch((error) => setError(error))
                .finally(() => setSubLoading(false))
        );
    };

    const onPageUpdate = (data: DomlessPageModel, unsetIsMainOfPageId?: string): void => {
        const state = cloneDeep(pages);
        for (let index in state) {
            const page = state[index];
            if (page.page_id === unsetIsMainOfPageId) {
                page.is_main_page = false;
                continue;
            }
            if (page.page_id !== data.page_id) continue;
            const newModel = merge(page, data);
            state[index] = newModel;
            if (currentPage.page_id === newModel.page_id) setCurrentPage(data);
        }
        setPages(state);
    };

    const clonePage = (
        page: DomlessPageModel,
        cb?: (pages?: PageDataModel[], index?: number) => void
    ): void => {
        setSubLoading({
            reason: t<ILang>("page_clone_info"),
            promise: DomDAO.clonePage(page.page_id, restaurant)
                .then((clonedPage) => {
                    const state = cloneDeep(pages);
                    const originIndex = pages.findIndex(
                        (page) => page.page_id === clonedPage.page_id
                    );
                    state[originIndex] = clonedPage;
                    processPages(state);
                    setPages(state);
                    const domlessModel = new DomlessPageModel(clonedPage, restaurant, true);
                    setCurrentPage(domlessModel);
                    createDefaultHistory([clonedPage]);
                    if (cb) cb(state, originIndex);
                })
                .catch((error) => {
                    setError(error);
                    if (cb) cb();
                })
                .finally(() => setSubLoading(false))
        });
    };

    const asyncClonePage = (
        page: DomlessPageModel
    ): Promise<{ pages: PageDataModel[]; index: number }> => {
        return new Promise((resolve, reject) => {
            clonePage(page, (pages, index) => {
                if (pages && index !== undefined) {
                    resolve({ pages, index });
                }
                reject("FAILED_CLONING");
            });
        });
    };

    const publishPage = async (draftPage: DomlessPageModel): Promise<void> => {
        setSubLoading({
            reason: t<ILang>("publish"),
            promise: new Promise((r, rj) => r(void 0))
        });
        try {
            await DomDAO.publishPage(draftPage.page_id);
            loadRestaurantPages(draftPage.page_id);
            setSubLoading(false);
        } catch (error) {
            setError(error as IError);
            setSubLoading(false);
        }
    };

    const destroyPage = (page_id: string): void => {
        setSubLoading(
            DomDAO.destroyPage(page_id)
                .then(() => {
                    clearHistory([page_id]);
                    if (page_id === currentPage.page_id) {
                        const indexPage = pages.find((page) => page.page_type === "index")!;
                        setCurrentPage(new DomlessPageModel(indexPage, restaurant));
                    }
                    const state = cloneDeep(pages);
                    const index = pages.findIndex((page) => page.page_id === page_id);
                    state.splice(index, 1);
                    setPages(state);
                })
                .catch((error) => setError(error))
                .finally(() => setSubLoading(false))
        );
    };

    const destroyDraftOfPage = (page_id: string): void => {
        setSubLoading(
            DomDAO.destroyDraft(page_id)
                .then(() => {
                    const state = cloneDeep(pages);
                    const pageIndex = state.findIndex((page) => page.page_id === page_id);
                    clearHistory([page_id]);
                    state[pageIndex].draft = null;
                    state[pageIndex].isCurrentPageDraft = false;
                    if (
                        currentPage.page_id === state[pageIndex].page_id &&
                        currentPage.isCurrentPageDraft
                    ) {
                        setCurrentPage(new DomlessPageModel(state[pageIndex], restaurant, false));
                    }
                    setPages(state);
                })
                .catch((error) => setError(error))
                .finally(() => setSubLoading(false))
        );
    };

    const recursiveDomRefGenerator = (list: (IAllDomTypes | PageDataModel)[]): IPageProcessor => {
        let currentPageModel: DomlessPageModel | null = null;
        let mainPage: PageDataModel | null = null;
        let orderPage: PageDataModel | null = null;

        for (let item of list) {
            if (item instanceof PageDataModel) {
                if (item.is_main_page) mainPage = item;
                if (item.page_type === "index") orderPage = item;

                const src = item.draft ? item.draft.draft_elements : item.elements;
                const domJson = src.dom;
                const [baseUrl, draftUrl] = item.getBothUrls(i18n);
                if (pathname === baseUrl) {
                    currentPageModel = new DomlessPageModel(item, restaurant);
                } else if (pathname === draftUrl) {
                    currentPageModel = new DomlessPageModel(item, restaurant, true);
                }
                recursiveDomRefGenerator(domJson);
            } else {
                if (!refs.current[item.uuid]) {
                    refs.current[item.uuid] = createRef<HTMLDivElement>();
                }
                if ("elements" in item) recursiveDomRefGenerator(item.elements);
            }
        }

        return {
            currentPageModel,
            orderPage,
            mainPage
        };
    };

    const checkAndCreateMissingSeos = async (pages: PageDataModel[]): Promise<void> => {
        const newSeos: ISeoGenerator[] = [];
        const primary = restaurant.primary_language;
        const secondary = restaurant.secondary_language;

        for (const page of pages) {
            const page_id = page.page_id;
            let isPrimaryExisting = false;
            let isSecondaryExisting = !secondary || primary === secondary ? true : false;
            for (const seo of page.seos) {
                if (seo?.lang === primary && seo?.url) isPrimaryExisting = true;
                if (seo?.lang === secondary && seo?.url) isSecondaryExisting = true;
            }

            if (!isPrimaryExisting) newSeos.push({ seo_lang: primary, page_id });
            if (!isSecondaryExisting) {
                newSeos.push({ seo_lang: secondary, page_id });
            }
        }
        if (newSeos.length > 0) {
            const isNewGenerated = await DomDAO.generateMissingSeos({ pages: newSeos });
            if (isNewGenerated) loadRestaurantPages();
        }
    };

    const processPages = (pages: PageDataModel[]): void => {
        const { currentPageModel, mainPage, orderPage } = recursiveDomRefGenerator(pages);
        checkAndCreateMissingSeos(pages);
        if (currentPageModel) return setCurrentPage(currentPageModel);

        if (!currentPageModel && (mainPage || orderPage)) {
            const page = mainPage || orderPage || pages[0];
            setCurrentPage(new DomlessPageModel(page, restaurant, page.draft ? true : false));
        }
    };

    const setMountHistory = (type: IAllDomKeys, state: PageDataModel): void => {
        if (!state.draft) return;
        state.draft.draft_elements.updated_at = new Date().toISOString();
        createHistory({
            state: state.draft.draft_elements.dom,
            domType: type,
            page_id: state.page_id,
            displayMessage: `${t<ILang>("mount_new_component")}: ${t<ILang>(type)}`
        });
    };

    const setContentChangeHistory = (type: IAllDomKeys, state: PageDataModel): void => {
        if (!state.draft) return;
        state.draft.draft_elements.updated_at = new Date().toISOString();
        createHistory({
            domType: type,
            state: state.draft.draft_elements.dom,
            page_id: state.page_id,
            displayMessage: `${t<ILang>("content_changed")}`
        });
    };

    const setClearHistory = (
        keys: (keyof IGenericCSSFields)[],
        domType: IAllDomKeys,
        state: PageDataModel
    ): void => {
        if (!state.draft) return;
        createHistory({
            domType,
            displayMessage: `${t<ILang>("reset_content_of_rule")} (${t<ILang>(size)}): ${keys
                .map((rule: keyof IGenericCSSFields) => t<ILang>(rule))
                .join("; ")}`,
            page_id: state.page_id,
            state: state.draft.draft_elements.dom
        });
    };

    const generateColumns = (newCount: number, pageID: string, sectionID: string): void => {
        let state = cloneDeep(pages);
        for (let pageIndex in pages) {
            let page = pages[parseInt(pageIndex)];
            if (page.page_id !== pageID) continue;
            if (!page.draft) break;

            for (let sectionIndex in page.draft.draft_elements.dom) {
                let section = cloneDeep(page.draft.draft_elements.dom[parseInt(sectionIndex)]);
                section.content.columnCount = newCount;
                if (section.uuid !== sectionID) continue;
                ColumnChangeHandler(refs, section, newCount, sectionID);
                state[pageIndex].draft!.draft_elements.dom[parseInt(sectionIndex)] = section;
                setContentChangeHistory("Section", state[pageIndex]);
                //After finding and modifying the section elements, commit changes and return
                return setPages(state);
            }
        }
    };

    const clearRulesBySize = (
        keys: (keyof IGenericCSSFields)[],
        uuid: string,
        domType: IAllDomKeys
    ): void => {
        const state = cloneDeep(pages);
        let isCompleted: boolean = false;
        const recursiveSearch = (list: IAllDomTypes[], page: PageDataModel): void => {
            for (let item of list) {
                if (isCompleted) return;
                if (item.type !== domType) {
                    if ("elements" in item) recursiveSearch(item.elements, page);
                    else continue;
                }
                if (item.uuid === uuid) {
                    for (let rule of keys) {
                        if (item.content[rule]) {
                            delete item.content[rule][size];
                        }
                        if (isEmpty(item.content[rule])) delete item.content[rule];
                    }
                    isCompleted = true;
                    setClearHistory(keys, domType, page);
                    return;
                }
            }
        };
        for (let page of state) {
            if (!page.draft) continue;
            recursiveSearch(page.draft.draft_elements.dom, page);
        }
        setPages(state);
    };

    const clearComponentSpecialProp = (key: any, uuid: string, domType: IAllDomKeys): void => {
        const state = cloneDeep(pages);
        let isCompleted: boolean = false;
        const recursiveSearch = (list: IAllDomTypes[], page: PageDataModel): void => {
            for (let item of list) {
                if (isCompleted) return;
                if (item.type !== domType) {
                    if ("elements" in item) recursiveSearch(item.elements, page);
                    else continue;
                }
                if (item.uuid === uuid) {
                    isCompleted = true;
                    delete item.content[key];
                    setClearHistory([key], domType, page);
                    return;
                }
            }
        };
        for (let page of state) {
            if (!page.draft) continue;
            recursiveSearch(page.draft.draft_elements.dom, page);
        }
        setPages(state);
    };

    const updateContent = (values: IContentUpdate[]) => {
        const domType = values[0].domType;
        const result = UpdateDomContent(pages, currentPage, values, size, language);
        if (!result) return;

        if (result.updatedPageIndex > -1) {
            setContentChangeHistory(domType, result.state[result.updatedPageIndex]);
        }
        setPages(result.state);
    };

    const mountSection = (
        index: number,
        defaultContent?: Partial<ISectionCustoms>
    ): ISection | null => {
        const state = cloneDeep(pages);

        const page_id: string = currentPage.page_id;
        const sectionUUID: string = uuidv4();
        const columnUUID: string = uuidv4();
        const section = MountNewNode(
            sectionUUID,
            state,
            "Section",
            index,
            { page_id },
            false,
            defaultContent
        );
        MountNewNode(columnUUID, state, "Column", 0, { page_id, sectionUUID });

        refs.current[sectionUUID] = createRef<HTMLDivElement>();
        refs.current[columnUUID] = createRef<HTMLDivElement>();

        ReSortNodes(state);
        capturedNode.current = null;
        setMountHistory("Section", state.find((page) => page.page_id === page_id)!);
        setPages(state);
        return section as ISection | null;
    };

    const mountSpecialElement = (
        newNodeType: ISpecialDomKeys,
        index: number,
        defaultContent?: any
    ): IDomElement | null => {
        const state = cloneDeep(pages);

        const page_id: string = currentPage.page_id;
        const sectionUUID: string = uuidv4(),
            columnUUID: string = uuidv4(),
            elementUUID: string = uuidv4();
        MountNewNode(sectionUUID, state, "Section", index, { page_id }, true);
        MountNewNode(columnUUID, state, "Column", 0, { page_id, sectionUUID }, true);
        const element = MountNewNode(
            elementUUID,
            state,
            newNodeType,
            0,
            {
                page_id,
                sectionUUID,
                columnUUID
            },
            true,
            defaultContent
        );

        refs.current[sectionUUID] = createRef<HTMLDivElement>();
        refs.current[columnUUID] = createRef<HTMLDivElement>();
        refs.current[elementUUID] = createRef<HTMLDivElement>();

        ReSortNodes(state);
        capturedNode.current = null;
        setMountHistory(newNodeType, state.find((page) => page.page_id === page_id)!);
        setPages(state);
        return element as IDomElement | null;
    };

    const mountElement = (
        newNodeType: IAllDomKeys,
        index: number,
        parentUUIDs: ITargetKeys
    ): IDomElement | null => {
        const uuid: string = uuidv4();
        const state = cloneDeep(pages);
        const page_id = currentPage.page_id;
        const element = MountNewNode(uuid, state, newNodeType, index, { page_id, ...parentUUIDs });
        refs.current[uuid] = createRef<HTMLDivElement>();
        ReSortNodes(state);
        capturedNode.current = null;
        setMountHistory(newNodeType, state.find((page) => page.page_id === page_id)!);
        setPages(state);
        return element as IDomElement | null;
    };

    const moveComponent = (
        dragOrigin: IExistingDragData,
        keys: ITargetKeys,
        index: number
    ): void => {
        const state = cloneDeep(pages);
        const pageIndex = state.findIndex((page) => page.page_id === currentPage.page_id);
        if (!state[pageIndex].draft) return;
        MoveNode(state, dragOrigin, currentPage, keys, index);
        createHistory({
            displayMessage: `${t<ILang>("moved_node")}`,
            page_id: currentPage.page_id,
            domType: dragOrigin.type,
            state: state[pageIndex].draft!.draft_elements.dom
        });
        setPages(state);
    };

    const unmountNode = (uuid: string, type: IAllDomKeys, childSectionIds?: string[]): void => {
        const state = cloneDeep(pages);
        const pageIndex = state.findIndex((page) => page.page_id === currentPage.page_id);
        if (!state[pageIndex].draft) return;
        UnmountNode(state, currentPage, uuid, type);
        if (childSectionIds) {
            for (const id of childSectionIds) {
                UnmountNode(state, currentPage, id, "Section");
            }
        }
        state[pageIndex].draft!.draft_elements.updated_at = new Date().toISOString();
        createHistory({
            displayMessage: `${t<ILang>("unmount_new_component")}`,
            page_id: currentPage.page_id,
            domType: type,
            state: state[pageIndex].draft!.draft_elements.dom
        });
        setPages(state);
    };

    useDidMount(loadRestaurantPages);
    //This will automatically redirect to the correct url
    //in each language that's assigned to a page.
    useAfterTriggerChanged(() => {
        const url = currentPage.getUrl(i18n);
        const cp = pages.find((page) => page.page_id === currentPage.page_id);
        if (currentPage) {
            if (currentPage?.isCurrentPageDraft && currentPage.draft) {
                setlastUpdatedAt(moment(currentPage.draft.draft_elements.updated_at));
            } else {
                setlastUpdatedAt(moment(cp?.elements.updated_at));
            }
        }
        if (url !== pathname) {
            let fullUrl = url;
            if (search) fullUrl += search;
            if (hash) fullUrl += hash;
            navigateTo(fullUrl);
        }
    }, [currentPage, language, pages]);

    const productBySection = useMemo((): { [sectionId: string]: string[] } => {
        let categoriesBySection: { [sectionId: string]: string[] } = {};
        let sectionsWithAllOtherIds: IDynamicObject<string[]> = {};
        let leftoverCategoryIds: IDynamicObject<true> = {};
        categories.forEach((category) => {
            //populates the leftoverCategoryIds object with all the category ids
            leftoverCategoryIds[category.id] = true;
        });
        let areCategoriesFiltered = false;
        for (const page of pages) {
            for (const section of page.elements.dom) {
                if (!section.isSpecial) continue;
                for (const column of section.elements) {
                    for (const element of column.elements) {
                        if (element.type !== "Products") continue;

                        if (
                            element.content.displayCategoryId instanceof Array &&
                            element.content.displayCategoryId.length > 0
                        ) {
                            for (const catId of element.content.displayCategoryId) {
                                if (catId in leftoverCategoryIds) {
                                    if (!categoriesBySection[element.sectionUUID]) {
                                        categoriesBySection[element.sectionUUID] = [];
                                    }
                                    areCategoriesFiltered = true;
                                    delete leftoverCategoryIds[catId];
                                    categoriesBySection[element.sectionUUID].push(catId);
                                }
                            }
                        } else {
                            sectionsWithAllOtherIds[element.sectionUUID] = [];
                        }
                    }
                }
            }
        }

        if (!areCategoriesFiltered) return {};
        else {
            for (const sectionId in sectionsWithAllOtherIds) {
                sectionsWithAllOtherIds[sectionId] = Object.keys(leftoverCategoryIds);
            }
            return { ...categoriesBySection, ...sectionsWithAllOtherIds };
        }
    }, [categories, pages]);

    return (
        <DomContext.Provider
            value={{
                findNodeByUUID: (uuid) => FindNodeByUUID(pages, uuid),
                clearComponentSpecialProp,
                mountSpecialElement,
                destroyDraftOfPage,
                clearRulesBySize,
                productBySection,
                generateColumns,
                asyncClonePage,
                setDraggedNode,
                setCurrentPage,
                updateContent,
                lastUpdatedAt,
                moveComponent,
                onPageUpdate,
                mountElement,
                mountSection,
                capturedNode,
                onPageCreate,
                unmountNode,
                draggedNode,
                currentPage,
                publishPage,
                destroyPage,
                clonePage,
                setPages,
                setSize,
                pages,
                save,
                size,
                refs
            }}
        >
            <FloatingError error={error} resetError={() => setError(null)} />
            {loading ? <Loading useSpacing isExternalConditional size={"lg"} /> : props.children}
            <Loading loading={subLoading} size={"sm"} useSpacing isAbsolute />
        </DomContext.Provider>
    );
};

export const useDom = <ContentType extends ICommonContent>(): IDomContext<ContentType> => {
    const context = useContext(DomContext);

    return context as never as IDomContext<ContentType>;
};
