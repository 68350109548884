import { FC } from "react";
import { useApp } from "../../Provider/App.context";
import { IAppOrderIndexProps } from "./AppOrder.index";
import { ProductCategoryModel } from "../../../DomMapper/DomComponents/Products/Models/ProductCategory.model";

interface IAppCategoryElementProps extends IAppOrderIndexProps {
    category: ProductCategoryModel;
    parentId?: string;
    currency: string;
}

export const AppCategoryElement: FC<IAppCategoryElementProps> = ({ category, ...props }) => {
    const { data, dataSource } = useApp();

    if (category.parent_id !== "" && !props.parentId) return null;
    if (
        props.selector.category &&
        props.selector.category.id !== props.parentId &&
        props.selector.category.id !== category.id
    ) {
        return null;
    }
    const p = data[dataSource].product;

    const style: React.CSSProperties = {
        backgroundColor: p.backgroundColor,
        color: p.color
    };
    const image = category.image_app?.medium || category?.image_web.medium;
    const classes = ["app-category"];

    const nameStyle: React.CSSProperties = {};
    if (p.nameFontSize) nameStyle.fontSize = `${p.nameFontSize}rem`;
    if (p.nameLineHeight) nameStyle.lineHeight = `${p.nameLineHeight}rem`;
    if (p.isCategoryDescriptionVisible && category.description) {
        classes.push("--DESCRIPTION_VISIBLE");
        if (category.description.length > 100) classes.push("--DESCRIPTION_LONG");
    }

    return (
        <>
            {props.selector.category?.id !== category.id && (
                <div
                    style={style}
                    className={classes.join(" ")}
                    onClick={() => props.appProudctNavigate(category)}
                >
                    <div
                        className="app-category__image"
                        style={{
                            backgroundImage: `url(${image})`,
                            backgroundSize: p.imageRenderContain ? "contain" : undefined
                        }}
                    />
                    <div style={nameStyle} className="app-category__name">
                        {category.name}
                    </div>
                    {p.isCategoryDescriptionVisible && category.description && (
                        <>
                            <span className="app-category__description">
                                {category.description.length > 70
                                    ? category.description.slice(0, 70 - 3) + "..."
                                    : category.description}
                            </span>
                        </>
                    )}
                </div>
            )}
            {props.selector.category?.id === category.id &&
                category.children.map((subCategory) => (
                    <AppCategoryElement
                        key={`${subCategory.id}-subcategory-element`}
                        category={subCategory}
                        parentId={category.id}
                        {...props}
                    />
                ))}
        </>
    );
};
