import { FC } from "react";
import { Save } from "@carbon/icons-react";
import { ILang } from "../../../Interfaces/ILang.type";
import { isEqual } from "lodash";
import { IWidgets } from "../../Widgets/Interfaces/IWidgets.type";
import { useWidgets } from "../../Widgets/Controller/WidgetsContext.provider";
import { InlineLoading } from "../../UI/InlineLoading/InlineLoading.view";
import { useTranslation } from "react-i18next";
import Button from "../../UI/Button/Button.view";

interface IWidgetPublishProps {
    widgetType: keyof IWidgets;
}

export const WidgetPublish: FC<IWidgetPublishProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { widgets, publishWidget, loading } = useWidgets();

    const disabled =
        isEqual(widgets[props.widgetType].draftSettings, widgets[props.widgetType].settings) ||
        loading
            ? true
            : false;

    return (
        <div className={"editor__publish-widget"}>
            <Button
                useContainer
                size={"FIELD"}
                align={"right"}
                disabled={disabled}
                onClick={() => publishWidget(props.widgetType)}
                Icon={loading ? <InlineLoading isAlternate /> : <Save />}
            >
                {t<ILang>("publish")}
            </Button>
        </div>
    );
};
