import { ParseComponentStyle } from "sitebuilder-common";
import { useTranslation } from "react-i18next";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { ILinkContent } from "../../Interfaces/ILinkComponent.interface";
import { IDomElement } from "../../../DomTracker/Interfaces/IDomTypes.interface";
import { LinkStyles } from "../../Static/LinkStyles.static";
import { DnDEvents } from "../../Util/DND.util";
import { NavLink } from "react-router-dom";
import { ILang } from "../../../../Interfaces/ILang.type";
import Overlay from "../../View/Overlay.view";

export interface IDomLinkProps extends IDomElement<ILinkContent> {}

const DomLink: React.FC<IDomLinkProps> = (props) => {
    const { t, i18n } = useTranslation<ILang>();
    const currentLang = i18n.language;
    const { restaurant } = useRestaurant();
    const { refs, size, id, showOverlay, ...events } = DnDEvents<ILinkContent>(props, "ELEMENT");

    let style = ParseComponentStyle(props.content, LinkStyles, size);

    const data = props.content?.[currentLang] as ILinkContent | undefined;
    const text = (data?.text as string) || t<ILang>("link_text");

    const getLink = (text: string): JSX.Element => {
        let url = data?.url || "#";
        if (data?.isExternal && props.content?.openOnNewTab) {
            return (
                <a rel={"noreferrer noopener"} target={"_blank"} href={url}>
                    {text}
                </a>
            );
        } else {
            if (!data?.openOnNewTab && url.indexOf(restaurant.domain) > -1) {
                url = url.split(restaurant.domain)[1];
            }
            return <NavLink to={url}>{text}</NavLink>;
        }
    };

    return (
        <div ref={refs.current[props.uuid]} className="dom__link" style={style} {...events} id={id}>
            {showOverlay && <Overlay parent={props} />}
            {getLink(text)}
        </div>
    );
};

export default DomLink;
