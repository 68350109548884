import { ISelectOption } from "xa-generics";

export const BG_SIZE_OPTIONS: ISelectOption[] = [
    { id: "contain", name: "bg_size_contain" },
    { id: "cover", name: "bg_size_cover" },
    { id: "revert", name: "bg_size_revert" },
    { id: "auto", name: "bg_size_auto" },
    { id: "unset", name: "bg_size_unset" },
    { id: "inherit", name: "bg_size_inherit" }
];
