import { Width } from "../../UI/InputGroups/Width/Width.view";
import { Height } from "../../UI/InputGroups/Height/Height.view";
import { Margins } from "../../UI/InputGroups/Margins/Margins.view";
import { Paddings } from "../../UI/InputGroups/Paddings/Paddings.view";
import { EditorHook } from "../Utils/EditorHook.util";
import { ImageInput } from "../../UI/InputFields/ImageInput/Controller/ImageInput.controller";
import { IDomElement } from "../../DomTracker/Interfaces/IDomTypes.interface";
import { IContactFormEditors } from "../../DomMapper/Interfaces/IContactFormComponent.interface";
import { InputGroupContextProvider } from "../../UI/InputGrouper/InputGroup.provider";
import BackgroundGroupView from "../../UI/InputGroups/Background/BackgroundGroup.view";
import FormSeparatorView from "../../UI/FormSeparator/FormSeparator.view";
import AlignPickerView from "../../UI/InputFields/AlignPicker/AlignPicker.view";
import ComponentText from "../../UI/InputGroups/ComponentText/ComponentText.view";
import FontColor from "../../UI/InputGroups/FontInputs/FontColor.input";
import Font from "../../UI/InputGroups/FontInputs/Font.view";

export interface IContactFormEditorView {
    editor: IDomElement;
}

const ContactFormEditorView: React.FC<IContactFormEditorView> = (props) => {
    const { onBlur, onClear, units, defaults, onSpecialRuleClear } =
        EditorHook<IContactFormEditors>();

    return (
        <InputGroupContextProvider defaultActive={"FontColor"}>
            <FormSeparatorView
                defKey={"contact_form_label"}
                defaultGroup={
                    <>
                        <ImageInput
                            id={"src"}
                            onChange={(field, value) => onBlur({ field, value })}
                            labelText={"Image"}
                            value={defaults.src}
                            onReset={onSpecialRuleClear}
                        />
                        <ComponentText
                            labelText={"success_email_message"}
                            defaultText={"message_sent"}
                            defaultValues={defaults}
                            type={"TEXT_AREA"}
                            onBlur={onBlur}
                        />
                        <AlignPickerView
                            id={"textAlign"}
                            onBlur={onBlur}
                            defaultValue={defaults.textAlign}
                        />
                        <FontColor
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Font
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <BackgroundGroupView
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                    </>
                }
                advancedGroup={
                    <>
                        <Paddings
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Margins
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Height
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                            defaultValues={defaults}
                        />
                        <Width
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                    </>
                }
            />
        </InputGroupContextProvider>
    );
};

export default ContactFormEditorView;
