import { useState } from "react";
import { IDynamicObject, ISelectOption, Objectify, useAfterTriggerChanged } from "xa-generics";
import LongSelectInput from "./Internals/LongSelectInput.view";

export interface ILongSelectProps<
    Fields extends object,
    Lang = string,
    OptionType extends object = ISelectOption
> {
    value: string;
    id: keyof Fields;
    labelText?: Lang;
    noLabel?: boolean;
    required?: boolean;
    className?: string;
    isHidden?: boolean;
    placeholder?: Lang;
    pageLimit?: number;
    isDisabled?: boolean;
    options: OptionType[];
    isTypingTextKey?: Lang;
    newOptionLangKey?: Lang;
    allowNewOption?: boolean;
    idAccessor?: keyof OptionType;
    useTranslationOnName?: boolean;
    nameAccessor?: keyof OptionType;
    description?: string | JSX.Element;
    handleNewOptionClick?: (id: keyof Fields, currentSearch: string) => void;
    onChange: (id: keyof Fields, value: string, option: OptionType | null) => void;
}

export const LongSelect = <
    Fields extends object,
    Lang = string,
    OptionType extends object = ISelectOption
>(
    props: ILongSelectProps<Fields, Lang, OptionType>
) => {
    const classes: string[] = ["wrapper", "long-select"];
    if (props.className) classes.push(props.className);
    if (props.isHidden) classes.push("wrapper-hidden");
    if (props.isDisabled) classes.push("wrapper-disabled");
    const idAccessor = props.idAccessor || ("id" as keyof OptionType);
    const nameAccessor = props.nameAccessor || ("name" as keyof OptionType);
    const [optionObject, setOptionObject] = useState<IDynamicObject<OptionType>>(
        Objectify(props.options, idAccessor)
    );

    useAfterTriggerChanged(() => {
        setOptionObject(Objectify(props.options, idAccessor));
    }, [props.options]);

    const { onChange, value, ...otherProps } = props;

    return (
        <LongSelectInput
            value={value}
            {...otherProps}
            classes={classes}
            idAccessor={idAccessor}
            nameAccessor={nameAccessor}
            optionObject={optionObject}
            key={`${value}-value-key-trigger`}
            onChange={(value, option) => {
                if (onChange) onChange(props.id, value, option);
            }}
        />
    );
};
