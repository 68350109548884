import { ProductModel } from "../../../DomMapper/DomComponents/Products/Models/Product.model";

export const MOCK_ORDER_AGAIN: ProductModel[] = [
    new ProductModel({
        id: 123,
        base_price: 1950,
        name: "Al Capone",
        description: "mustáros alap, bacon, virsli, kolbász, hagyma, füstölt sajt"
    }),
    new ProductModel({
        id: 355,
        base_price: 1700,
        name: "Amigo",
        description: "paradicsomos sertésragu, csípős pepperóni, sajt"
    }),
    new ProductModel({
        id: 34,
        base_price: 1800,
        name: "Argentin grill",
        description: "fokhagymás-tejfölös alap, lilahagyma, dél-amerikai fűszerek, sajt"
    }),
    new ProductModel({
        id: 57,
        base_price: 1950,
        name: "Athén",
        description: "fokhagymás-tejfölös alap, gyroshús, oliva, sajt"
    })
];
