import { ILang } from "../../../../Interfaces/ILang.type";
import { useTranslation } from "react-i18next";
import { IBackgroundMetrics } from "../../../../Interfaces/IMetrics.type";

export interface IBackgroundControlsProps {
    unit?: IBackgroundMetrics;
    setUnit: (id: "unit", type: IBackgroundMetrics) => void;
}

const BackgroundControls: React.FC<IBackgroundControlsProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const getControl = (unit: IBackgroundMetrics): JSX.Element => {
        const classes: string[] = ["global__units--item"];
        if (unit === props.unit || (!props.unit && unit === "GENERIC")) {
            classes.push("active-unit");
        }
        return (
            <div onClick={() => props.setUnit("unit", unit)} className={classes.join(" ")}>
                {t<ILang>(unit)}
            </div>
        );
    };
    return (
        <div className="global__units">
            {getControl("GENERIC")}
            {getControl("GRADIENT")}
        </div>
    );
};

export default BackgroundControls;
