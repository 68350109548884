import { ILang } from "../../../Interfaces/ILang.type";
import { useIFrame } from "../Provider/IFrame.context";
import { DATEFORMATS } from "sitebuilder-common";
import { FC, useState } from "react";
import { EmbedCodeGen } from "./EmbedCodeGen.view";
import { FrameMapForm } from "./Editors/FrameMap.form";
import { InlineLoading } from "../../UI/InlineLoading/InlineLoading.view";
import { useTranslation } from "react-i18next";
import { FrameGlobalForm } from "./Editors/FrameGlobal.form";
import { FrameHeaderForm } from "./Editors/FrameHeader.form";
import { FrameButtonsForm } from "./Editors/FrameButtons.form";
import { FrameProductsForm } from "./Editors/FrameProducts.form";
import {
    Information,
    ArrowRight,
    ArrowLeft,
    Launch,
    View,
    Edit,
    Menu,
    Close,
    Logout
} from "@carbon/icons-react";
import Button from "../../UI/Button/Button.view";
import moment from "moment";
import { axios, useMountWithTriggers } from "xa-generics";
import { SaveLangCookie } from "../../../Utils/SaveLangCookie.util";
import { useRestaurant } from "../../../Contexts/Restaurant.context";
import { AXIOS_NAMES } from "../../../Static/AxiosInstanceNames.static";
import { LongSelect } from "../../UI/InputFields/LongSelect/LongSelect.view";
import { useAccess } from "../../../Contexts/Access.context";
import { isEqual } from "lodash";

interface IIframeSidemenuViewProps {}

export const IFrameSidemenuView: FC<IIframeSidemenuViewProps> = (props) => {
    const { i18n, t } = useTranslation<ILang>();

    const { logout, loading: accessLoading } = useAccess();
    const { restaurant, switchToRestaurant, targets } = useRestaurant();
    const { data, setDataSource, dataSource, publishData, loading } = useIFrame();

    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [showToolbox, setShowToolbox] = useState<boolean>(false);
    const [isOpened, setIsOpened] = useState<boolean>(true);

    const classes = ["iframe-sidemenu"];
    if (!isOpened) classes.push("iframe-sidemenu-hidden");
    const isOriginal = dataSource === "elements_config";
    if (isOriginal) classes.push("original-version");
    if (showToolbox) classes.push("toolbox-visible");

    const changeLang = (lang: string): void => {
        axios.setLanguage(lang, AXIOS_NAMES.BASIC);
        axios.setLanguage(lang, AXIOS_NAMES.ONEMIN);
        i18n.changeLanguage(lang);
        SaveLangCookie(lang);
    };

    const handleOutClick = (e: MouseEvent) => {
        const path = e.composedPath();
        let isParent = false;
        for (const target of path) {
            if ((target as any)?.id === "toolbox") {
                isParent = true;
                break;
            }
        }
        if (!isParent) setShowToolbox(false);
    };

    useMountWithTriggers(() => {
        if (showToolbox) window.addEventListener("click", handleOutClick, { passive: true });
        return () => {
            window.removeEventListener("click", handleOutClick);
        };
    }, [showToolbox]);

    return (
        <div className={classes.join(" ")}>
            <div className={"iframe-sidemenu-toggler"} onClick={() => setIsOpened(!isOpened)}>
                {isOpened ? <ArrowLeft size={24} /> : <ArrowRight size={24} />}
            </div>
            <h4 className="iframe-sidemenu__title">
                {!isOriginal && (
                    <div
                        className="info-icon"
                        onMouseEnter={() => setShowInfo(true)}
                        onMouseLeave={() => setShowInfo(false)}
                    >
                        <Information />
                        {showInfo && (
                            <div className="iframe-sidemenu__info">{t<ILang>("iframe_info")}</div>
                        )}
                    </div>
                )}
                {t<ILang>(isOriginal ? "production_version" : "editor")}
                <div
                    id={"toolbox"}
                    className={"iframe-sidemenu__toolbox"}
                    onClick={() => setShowToolbox(!showToolbox)}
                >
                    <Menu size={24} />

                    <div
                        className="iframe-sidemenu__toolbox-content"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="iframe-sidemenu__toolbox--head">
                            {t<ILang>("menu")}
                            <button className="close" onClick={() => setShowToolbox(false)}>
                                <Close />
                            </button>
                        </div>
                        {restaurant.secondary_language !== "" && (
                            <div className="iframe-sidemenu__toolbox--row lang-row">
                                <button
                                    className="iframe-sidemenu__toolbox--lang"
                                    onClick={() => changeLang(restaurant.primary_language)}
                                >
                                    {restaurant.primary_language.toUpperCase()}
                                </button>
                                /
                                <button
                                    className="iframe-sidemenu__toolbox--lang"
                                    onClick={() => changeLang(restaurant.secondary_language)}
                                >
                                    {restaurant.secondary_language.toUpperCase()}
                                </button>
                            </div>
                        )}
                        <div className="iframe-sidemenu__toolbox--row">
                            {targets.length > 0 ? (
                                <LongSelect
                                    options={targets}
                                    value={restaurant.id}
                                    id={"restaurant_selector"}
                                    onChange={(id, value) => switchToRestaurant(value)}
                                />
                            ) : null}
                            <button
                                className="iframe-sidemenu__toolbox--row logout-button"
                                onClick={() => logout()}
                            >
                                {t<ILang>("logout")}{" "}
                                {accessLoading ? <InlineLoading /> : <Logout />}
                            </button>
                        </div>
                    </div>
                </div>
            </h4>
            {dataSource === "draft_elements_config" ? (
                <form className="iframe-editor">
                    <EmbedCodeGen />

                    <FrameGlobalForm />
                    <FrameButtonsForm />
                    <FrameHeaderForm />
                    <FrameMapForm />
                    <FrameProductsForm />
                </form>
            ) : (
                <div className={"iframe-editor"}>
                    {t<ILang>("iframe_info")}
                    <EmbedCodeGen />
                </div>
            )}
            <Button
                onClick={() => {
                    setDataSource(isOriginal ? "draft_elements_config" : "elements_config");
                }}
                kind={"DANGER"}
                className={"iframe-sidemenu__button"}
                Icon={isOriginal ? <Edit size={24} /> : View}
            >
                {t<ILang>(isOriginal ? "open_editor_mode" : "exit_editor_mode")}
            </Button>
            {dataSource === "draft_elements_config" && (
                <div className="iframe-sidemenu__footer">
                    <div className="iframe-sidemenu__footer--control">
                        {t<ILang>("last_saved")}:{" "}
                        {moment(data.updated_at).format(DATEFORMATS.DATETIMEFORMAT)}
                    </div>
                    <Button
                        disabled={
                            loading || isEqual(data.draft_elements_config, data.elements_config)
                        }
                        onClick={() => publishData()}
                        Icon={loading ? <InlineLoading isAlternate /> : Launch}
                    >
                        {t<ILang>("publish")}
                    </Button>
                </div>
            )}
        </div>
    );
};
