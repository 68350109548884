import { ILang } from "../../../../Interfaces/ILang.type";
import { IWidgetGroups } from "../../../Widgets/Interfaces/IWidgetGroups.type";
import { useTranslation } from "react-i18next";
import { GroupContextProvider } from "../../../UI/Grouper/Group.provider";
import { InputGroupContextProvider } from "../../../UI/InputGrouper/InputGroup.provider";
import {
    IFooterSettings,
    IFooter
} from "../../../WidgetModules/Footer/Interfaces/IFooterWidget.interface";
import { FooterStylesEditorView } from "./FooterStylesEditor.view";
import { FooterBottomEditorView } from "./FooterBottomEditor.view";
import { FooterTopEditorView } from "./FooterTopEditor.view";
import { FooterMidEditorView } from "./FooterMidEditor.view";
import { IGenericCSSFields } from "sitebuilder-common";
import { IBlur, IFieldBlur } from "../../Utils/EditorHook.util";
import { WidgetPublish } from "../WidgetPublish.view";
import { useWidgets } from "../../../Widgets/Controller/WidgetsContext.provider";

export interface IFooterEditorProps {
    editor: IFooter;
    subKey: keyof IFooterSettings;
}

const FooterEditorView: React.FC<IFooterEditorProps> = (props) => {
    const { t } = useTranslation<ILang>();

    const { updateWidget, clearRulesBySize } = useWidgets<IFooterSettings>();

    const handleChange = (
        widgetProp: keyof IFooterSettings,
        data: IFieldBlur<any> | IBlur<any>
    ) => {
        updateWidget(
            data instanceof Array
                ? data.map((dat) => ({
                      widgetType: "Footer",
                      widgetProp,
                      data: dat
                  }))
                : [{ widgetType: "Footer", widgetProp, data }]
        );
    };

    const onReset = (id: keyof IFooterSettings, keys: (keyof IGenericCSSFields)[]) => {
        clearRulesBySize("Footer", id, keys);
    };

    return (
        <>
            <div className="editor__header">
                {t<ILang>("footer")} / {t<ILang>(props.subKey)}
            </div>
            <GroupContextProvider<IWidgetGroups> defaultActive={props.subKey || "footerStyle"}>
                <InputGroupContextProvider defaultActive={"BackgroundGroup"}>
                    <FooterStylesEditorView
                        {...props}
                        onReset={onReset}
                        handleChange={handleChange}
                    />

                    <FooterTopEditorView {...props} onReset={onReset} handleChange={handleChange} />

                    <FooterMidEditorView {...props} onReset={onReset} handleChange={handleChange} />

                    <FooterBottomEditorView
                        {...props}
                        onReset={onReset}
                        handleChange={handleChange}
                    />
                </InputGroupContextProvider>
            </GroupContextProvider>
            <WidgetPublish widgetType={"Footer"} />
        </>
    );
};

export default FooterEditorView;
