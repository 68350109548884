import { CenterSquare, ShoppingBag } from "@carbon/icons-react";
import { IAppOrderIndexProps } from "./AppOrder.index";
import { useTranslation } from "react-i18next";
import { AppOptionsView } from "./AppOptions.view";
import { RawSelectInput } from "../../../UI/InputFields/RawSelectInput/RawSelectInput.view";
import { useSwipeable } from "react-swipeable";
import { RenderPrice } from "../../../DomMapper/DomComponents/Products/Utils/GetCurrency.util";
import { AppQtyHook } from "../QtyHook/AppQty.hook";
import { AppButton } from "../AppButton.view";
import { GetColors } from "../../Utils/GetColors.util";
import { useApp } from "../../Provider/App.context";
import { ILang } from "../../../../Interfaces/ILang.type";
import { FC } from "react";
import { CustomSVG } from "../../../UI/SVG/Custom.svg";

export interface IAppProductPageViewProps extends IAppOrderIndexProps {
    onItemSubmit: () => void;
    failedGroupId: string;
}

export const AppProductPageView: FC<IAppProductPageViewProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { data, dataSource } = useApp();
    const handlers = useSwipeable({
        onSwipedRight: (e) => props.appProudctNavigate(props.selector.category),
        delta: 100
    });
    const theme = GetColors(data[dataSource]);
    const { JSX } = AppQtyHook({});
    const header = data[dataSource].header;
    const priceColor = data[dataSource].global.priceColor;
    const product = props.selector.product;
    const classes = ["product"];
    if (product && product.subproducts.length > 1) classes.push("--with-subproducts");

    return (
        <>
            <div className={"product-container"} {...handlers}>
                <div
                    className="product-container__image"
                    style={{ backgroundImage: `url(${product!.image.medium})` }}
                >
                    <div className="product-container__control product-container__control--view-image">
                        <CenterSquare size={44} />
                    </div>
                </div>
                <div className={classes.join(" ")}>
                    <h2 className="product__name">{product!.name}</h2>
                    <h3
                        className="product__price"
                        style={{ color: priceColor || theme.backgroundColor }}
                    >
                        {RenderPrice(props.currency, product!.base_price)}
                    </h3>
                    <div className="product__row --row2">
                        {product!.subproducts.length > 1 && (
                            <div className="product__select">
                                <RawSelectInput
                                    noLabel
                                    value={""}
                                    id={"subproduct"}
                                    options={product!.subproducts}
                                    onChange={(i, v, option) => {}}
                                />
                            </div>
                        )}
                        {JSX}
                    </div>
                    <div className="product__row --row3">
                        <div className="product__description">
                            {product!.description || `${t<ILang>("no_desc")}...`}
                        </div>
                    </div>
                    <AppOptionsView {...props} />
                </div>
            </div>
            <div className="product-add-to-cart">
                <AppButton
                    isThick
                    Icon={
                        header.cartSVG ? (
                            <CustomSVG key={"cart"} svgString={header.cartSVG} />
                        ) : (
                            <ShoppingBag size={28} />
                        )
                    }
                    onClick={props.onItemSubmit}
                >
                    {t<ILang>("add_to_cart")}
                </AppButton>
            </div>
        </>
    );
};
