import { FC } from "react";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { ISetState } from "xa-generics";
import { useWidgets } from "../../../Widgets/Controller/WidgetsContext.provider";
import { useSwipeable } from "react-swipeable";
import { V2NavbarLogo } from "./V2NavbarLogo.view";
import { NavbarStyles } from "../Static/NavbarStyles.static";
import { V2NavbarLinks } from "./V2NavbarLinks.view";
import { V2NavbarLangs } from "./V2NavbarLangs.view";
import { INavbarVersions } from "../Interfaces/INavbar.type";
import { V2NavbarProfile } from "./V2NavbarProfile.view";
import { INavbarSettings } from "../Interfaces/INavbarWidget.interface";
import { GetWidgetOverlay } from "../../Util/WidgetOverlay.util";
import { ParseComponentStyle } from "sitebuilder-common";
import WidgetOverlay from "../../../Widgets/View/WidgetOverlay.view";

interface IV2NavbarSidemenuProps {
    setIsNavbarOpened: ISetState<boolean>;
    navVersion: INavbarVersions;
    isNavbarOpened: boolean;
}

export const V2NavbarSidemenu: FC<IV2NavbarSidemenuProps> = (props) => {
    const classes = ["v2sidemenu"];
    const { size } = useDom();
    const { widgets } = useWidgets();
    const { showOverlay, ...events } = GetWidgetOverlay<INavbarSettings>(
        "Navbar",
        "navbarBottomBar"
    );
    const { navbarBottomBar } = widgets.Navbar.draftSettings;
    const style = ParseComponentStyle(navbarBottomBar, NavbarStyles, size);

    const swipe = useSwipeable({
        onSwipedLeft: () => props.setIsNavbarOpened(false),
        delta: 50
    });

    if (props.isNavbarOpened) classes.push("--OPENED_SIDEMENU");

    return (
        <div className={classes.join(" ")} onClick={() => props.setIsNavbarOpened(false)}>
            <div
                {...swipe}
                {...events}
                style={style}
                className={"v2sidemenu__content"}
                onClick={(e) => e.stopPropagation()}
            >
                {showOverlay && (
                    <WidgetOverlay<INavbarSettings>
                        isMain
                        align={"left"}
                        parent={widgets.Navbar}
                        subKey={"navbarBottomBar"}
                    />
                )}
                <V2NavbarLogo />
                <V2NavbarLinks navVersion={props.navVersion} />
                <V2NavbarLangs />
                <V2NavbarProfile isSidemenu />
            </div>
        </div>
    );
};
