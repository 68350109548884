import { ILang } from "../../../Interfaces/ILang.type";
import { useDom } from "../../DomTracker/Controller/DomTracker.provider";
import { useTranslation } from "react-i18next";
import { IComponentTypes } from "../../DomTracker/Interfaces/IDomTypes.interface";

export interface ICreateCardProps {
    type: IComponentTypes;
    Icon: JSX.Element;
    handleDragend: (e: React.DragEvent<HTMLDivElement>, type: IComponentTypes) => void;
}

const CreateCard: React.FC<ICreateCardProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { setDraggedNode } = useDom();

    return (
        <div
            className="component-creator__card"
            draggable={true}
            onDragStart={(e) => setDraggedNode(props.type)}
            onDragEnd={(e) => props.handleDragend(e, props.type)}
        >
            <div className="component-creator__card--icon-container svg">{props.Icon}</div>
            <div className="component-creator__card--label">{t<ILang>(props.type)}</div>
        </div>
    );
};

export default CreateCard;
