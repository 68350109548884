import { useApp } from "../../Provider/App.context";
import { GetColors } from "../../Utils/GetColors.util";
import { useProducts } from "../../../DomMapper/DomComponents/Products/Context/ProductsContext.provider";
import { FC, useEffect } from "react";
import { IAppOrderIndexProps } from "./AppOrder.index";
import { AppV2CategoryElement } from "./AppV2Category.element";

interface IAppCategoriesViewProps extends IAppOrderIndexProps {
    containerRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const AppV2CategoriesView: FC<IAppCategoriesViewProps> = (props) => {
    const { categories } = useProducts();
    const { data, dataSource } = useApp();
    const theme = GetColors(data[dataSource]);

    const style: React.CSSProperties = {
        backgroundColor: theme.backgroundColor,
        color: theme.color
    };

    useEffect(() => {
        if (categories.length > 0 && !props.selector.category) {
            props.appProudctNavigate(categories[0]);
        }
    }, [categories, props]);

    if (props.selector.product) return null;

    return (
        <div className={"app-v2-categories-container"} style={style}>
            <div className="app-v2-categories-scroller">
                {categories.map((category) => {
                    if (category.parent_id) return null;
                    return (
                        <AppV2CategoryElement
                            {...props}
                            category={category}
                            key={`${category.id}-category-element`}
                        />
                    );
                })}
            </div>
        </div>
    );
};
