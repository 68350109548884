import { ICommonContent, ImageModel } from "sitebuilder-common";
import { IDynamicObject } from "xa-generics";
import { ISection } from "../../DomTracker/Interfaces/IDomTypes.interface";

const replaceImageId = (
    imageLinker: IDynamicObject<ImageModel>,
    objectRef: ICommonContent
): void => {
    if (!objectRef || typeof objectRef !== "object") return;

    for (const sizeKey in objectRef) {
        const imageId = objectRef[sizeKey];
        if (imageLinker[imageId]) {
            objectRef[sizeKey] = imageLinker[imageId].image_id;
        } else {
            delete objectRef[sizeKey];
        }
    }
};

export const ReplaceImages = (section: ISection, imageLinker: IDynamicObject<ImageModel>): void => {
    replaceImageId(imageLinker, section.content.backgroundImage);
    for (const column of section.elements) {
        replaceImageId(imageLinker, column.content.backgroundImage);
        for (const element of column.elements) {
            if (element.type === "Image") {
                if (imageLinker[element.content.src]) {
                    element.content.src = imageLinker[element.content.src].image_id;
                } else {
                    delete element.content.src;
                }
            }
            if (element.content.backgroundImage) {
                replaceImageId(imageLinker, element.content.backgroundImage);
            }
        }
    }
};
