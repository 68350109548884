import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useCart } from "../../../WidgetModules/Cart/Provider/Cart.provider";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import { DeliveryOptionElement } from "./DeliveryOption.element";

interface IAppDeliveryOptionsProps {}

export const AppDeliveryOptions: FC<IAppDeliveryOptionsProps> = (props) => {
    const { restaurant } = useRestaurant();
    const { form, order } = useCart();
    const { t } = useTranslation<ILang>();

    const { editor, bulkChange } = form;

    return (
        <div className={"app-delivery-options"}>
            <div className="app-delivery-options__cart-group">
                <h5 className="app-delivery-options__cart-group--title">
                    {t<ILang>("delivery_method")}
                </h5>

                <DeliveryOptionElement
                    activatorValue={"DELIVERY"}
                    currentValue={editor.delivery_method}
                    renderCondition={restaurant.normal_delivery || restaurant.preorder_delivery}
                    onClick={() => {
                        bulkChange([
                            { field: "delivery_method", value: "DELIVERY" },
                            { field: "delivery_time", value: "ASAP" },
                            { field: "is_preorder", value: false },
                            { field: "expected_at", value: "" },
                            { field: "pickup", value: false }
                        ]);
                    }}
                >
                    {t<ILang>("delivery")}
                </DeliveryOptionElement>

                <DeliveryOptionElement
                    activatorValue={"PICKUP"}
                    currentValue={editor.delivery_method}
                    renderCondition={restaurant.normal_pickup || restaurant.preorder_pickup}
                    onClick={() => {
                        bulkChange([
                            { field: "delivery_method", value: "PICKUP" },
                            { field: "delivery_time", value: "ASAP" },
                            { field: "is_preorder", value: false },
                            { field: "expected_at", value: "" },
                            { field: "pickup", value: true }
                        ]);
                    }}
                >
                    {t<ILang>("pickup")}
                </DeliveryOptionElement>
            </div>

            <div className="app-delivery-options__cart-group">
                <h5 className="app-delivery-options__cart-group--title">
                    {t<ILang>(
                        editor.delivery_method === "DELIVERY" ? "delivery_time" : "pickup_time"
                    )}
                </h5>

                {editor.delivery_method === "DELIVERY" ? (
                    <>
                        <DeliveryOptionElement
                            activatorValue={"ASAP"}
                            currentValue={editor.delivery_time}
                            renderCondition={restaurant.normal_delivery}
                            onClick={() => {
                                bulkChange([
                                    { field: "delivery_method", value: "DELIVERY" },
                                    { field: "delivery_time", value: "ASAP" },
                                    { field: "is_preorder", value: false },
                                    { field: "expected_at", value: "" },
                                    { field: "pickup", value: false }
                                ]);
                            }}
                        >
                            {t<ILang>("asap")}
                        </DeliveryOptionElement>

                        <DeliveryOptionElement
                            activatorValue={"PREORDER"}
                            currentValue={editor.delivery_time}
                            renderCondition={
                                restaurant.preorder_delivery && !order?.order_parent_id
                            }
                            datepicker={{
                                matrixFrom: "min_preorder_delivery_diff",
                                onCloseHook: () => {
                                    bulkChange([
                                        { field: "delivery_method", value: "DELIVERY" },
                                        { field: "delivery_time", value: "ASAP" },
                                        { field: "is_preorder", value: false },
                                        { field: "expected_at", value: "" },
                                        { field: "pickup", value: false }
                                    ]);
                                }
                            }}
                            onClick={() => {
                                bulkChange([
                                    { field: "delivery_method", value: "DELIVERY" },
                                    { field: "delivery_time", value: "PREORDER" },
                                    { field: "is_preorder", value: true },
                                    { field: "pickup", value: false }
                                ]);
                            }}
                        >
                            {t<ILang>("is_preorder")}
                        </DeliveryOptionElement>
                    </>
                ) : (
                    <>
                        <DeliveryOptionElement
                            activatorValue={"ASAP"}
                            currentValue={editor.delivery_time}
                            renderCondition={restaurant.normal_pickup}
                            onClick={() => {
                                bulkChange([
                                    { field: "delivery_method", value: "PICKUP" },
                                    { field: "delivery_time", value: "ASAP" },
                                    { field: "is_preorder", value: false },
                                    { field: "expected_at", value: "" },
                                    { field: "pickup", value: true }
                                ]);
                            }}
                        >
                            {t<ILang>("pickup_asap")}
                        </DeliveryOptionElement>

                        <DeliveryOptionElement
                            activatorValue={"PREORDER"}
                            currentValue={editor.delivery_time}
                            renderCondition={restaurant.preorder_pickup}
                            onClick={() => {
                                bulkChange([
                                    { field: "delivery_method", value: "PICKUP" },
                                    { field: "delivery_time", value: "PREORDER" },
                                    { field: "is_preorder", value: true },
                                    { field: "pickup", value: true }
                                ]);
                            }}
                            datepicker={{
                                matrixFrom: "min_preorder_pickup_diff",
                                onCloseHook: () => {
                                    bulkChange([
                                        { field: "delivery_method", value: "PICKUP" },
                                        { field: "delivery_time", value: "ASAP" },
                                        { field: "is_preorder", value: false },
                                        { field: "expected_at", value: "" },
                                        { field: "pickup", value: true }
                                    ]);
                                }
                            }}
                        >
                            {t<ILang>("pick_up_at")}
                        </DeliveryOptionElement>
                    </>
                )}
            </div>
        </div>
    );
};
