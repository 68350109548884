import { IAllDomKeys, IAllDomTypes } from "../Interfaces/IDomTypes.interface";
import { DomlessPageModel } from "../Model/DomlessPage.model";
import { PageDataModel } from "../Model/PageData.model";

export const UnmountNode = (
    clonedStateReference: PageDataModel[],
    currentPage: DomlessPageModel,
    uuid: string,
    type: IAllDomKeys
): IAllDomTypes | null => {
    let removedNode: IAllDomTypes[] | null = null;

    const recursiveCheck = (list: IAllDomTypes[]): void => {
        const listLength: number = list.length;
        for (let index = 0; index < listLength; index++) {
            const item = list[index];

            if (type === item.type) {
                if (item.uuid === uuid) {
                    removedNode = list.splice(index, 1);
                    return;
                }
            }

            if ("elements" in item) {
                recursiveCheck(item.elements);
            }
        }
    };
    for (let page of clonedStateReference) {
        if (page.page_id !== currentPage.page_id) continue;
        if (!page.draft) continue;
        recursiveCheck(page.draft.draft_elements.dom);
    }
    return removedNode ? removedNode[0] : null;
};
