import { FC } from "react";
import { Map } from "@carbon/icons-react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useCart } from "../../Cart/Provider/Cart.provider";
import { IPartialCSS } from "sitebuilder-common";
import { GetCurrency } from "../../../DomMapper/DomComponents/Products/Utils/GetCurrency.util";
import { ClientButton } from "../../../DomMapper/DomComponents/Button/ClientButton.view";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import { ProfileOrderItem } from "./ProfileOrderItem.element";
import { PrevOrderElement } from "./ProfilePrevOrder.element";
import { MOCK_PREV_ORDERS } from "../Static/MockPrevOrders.static";
import { MOCK_ORDER_AGAIN } from "../Static/MockOrderAgain.static";
import { OrderAgainElement } from "./OrderAgain.element";
import { IProfileIndexProps } from "./Profile.index";
import moment from "moment";

interface IProfilePrevOrdersProps extends IProfileIndexProps {}

export const ProfilePrevOrders: FC<IProfilePrevOrdersProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { order } = useCart();
    const { config } = useRestaurant();
    const currency = GetCurrency();

    const statusStyle: IPartialCSS<any> = {};
    if (config) {
        if (config.secondary_btn_bg_color) {
            statusStyle.backgroundColor = config.secondary_btn_bg_color;
        }
        if (config.secondary_btn_color) statusStyle.color = config.secondary_btn_color;
    }

    return (
        <div className={"profile__prev-orders"}>
            {/* INFO current order block */}
            <h5 className="profile__prev-orders--title">
                {t<ILang>("active_orders")}{" "}
                <span className="status-label" style={statusStyle}>
                    {t<ILang>("order_accepted")}
                </span>
            </h5>
            <div className="profile__prev-orders--shadow-container">
                {order.items.map((item, index) => (
                    <ProfileOrderItem
                        item={item}
                        currency={currency}
                        key={`${index}-profile-currentorder-item`}
                    />
                ))}

                <div className="profile__prev-orders--extra-details">
                    <div className="extra-details-block-1">
                        <div className="detail-block">
                            <span className="label">{t<ILang>("order_id")}</span>
                            <span className="value">{order.id}</span>
                        </div>
                        <div className="detail-block">
                            <span className="label">{t<ILang>("order_datetime")}</span>
                            <span className="value">
                                {moment()
                                    .subtract({ hour: 2 })
                                    .set({ minute: 0 })
                                    .format("YYYY.MM.DD HH:mm")}
                            </span>
                        </div>
                        <div className="detail-block">
                            <span className="label">{t<ILang>("payment_method_id")}</span>
                            <span className="value">{t<ILang>("simplepay")}</span>
                        </div>
                    </div>
                    <div className="extra-details-block-2">
                        <div className="detail-block">
                            <span className="label">{t<ILang>("address")}</span>
                            <span className="value">
                                {order.postcode} {order.street} {order.premise_number},{" "}
                                {order.floor} {t<ILang>("floor")}. {order.door_number}.{" "}
                                {t<ILang>("door_number")}
                            </span>
                        </div>
                        <div className="detail-block">
                            <span className="label">{t<ILang>("comment")}</span>
                            <span className="value">{order.message}</span>
                        </div>
                    </div>
                </div>
                <div className="profile__prev-orders--track">
                    {props.v2Btn ? (
                        <props.v2Btn Icon={<Map />}>{t<ILang>("track_courier")}</props.v2Btn>
                    ) : (
                        <ClientButton RightIcon={Map}>{t<ILang>("track_courier")}</ClientButton>
                    )}
                </div>
            </div>

            {/* INFO order again block */}
            <h5 className="profile__prev-orders--title">{t<ILang>("order_again")}</h5>
            <div className="profile__prev-orders--order-again">
                {MOCK_ORDER_AGAIN.map((product, index) => (
                    <OrderAgainElement
                        product={product}
                        currency={currency}
                        styleData={props.styleData}
                        v2Btn={props.v2Btn}
                        key={`${index}-${product.id}-order-again-product`}
                    />
                ))}
            </div>

            {/* INFO all prev orders block */}
            <h5 className="profile__prev-orders--title">{t<ILang>("all_prev_orders")}</h5>
            {MOCK_PREV_ORDERS.map((order, index) => (
                <PrevOrderElement
                    order={order}
                    currency={currency}
                    key={`${index}-prev-order`}
                    styleData={props.styleData}
                    v2Btn={props.v2Btn}
                />
            ))}
        </div>
    );
};
