export const BG_POSITION_OPTIONS = [
    { id: "center", name: "center" },
    { id: "left", name: "left" },
    { id: "top", name: "top" },
    { id: "right", name: "right" },
    { id: "bottom", name: "bottom" },
    { id: "top left", name: "top_left" },
    { id: "top right", name: "top_right" },
    { id: "bottom left", name: "bottom_left" },
    { id: "bottom right", name: "bottom_right" }
];
