import { ILang } from "../Interfaces/ILang.type";
import { ImageDAO } from "../DAO/Image.dao";
import { cloneDeep } from "lodash";
import { ImageModel } from "sitebuilder-common";
import { IImageConext } from "../Interfaces/IImageContext.interface";
import { useRestaurant } from "./Restaurant.context";
import { useTranslation } from "react-i18next";
import { IError, ILoading } from "xa-generics";
import { IImageDimensions } from "../Interfaces/IImageDimensions.interface";
import { IDynamicObject, Objectify, useDidMount } from "xa-generics";
import { Context, createContext, useState, useContext } from "react";
import Loading from "../Components/UI/Loading/Loading.view";

/**
 * ## ImagesContext
 */
const ImagesContext: Context<IImageConext> = createContext<IImageConext>(null as any);

ImagesContext.displayName = "ImagesContext";

interface IImagesContextProviderProps {}

/**
 * ## Images context provider component
 *
 */
export const ImagesContextProvider: React.FC<IImagesContextProviderProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { restaurant } = useRestaurant();

    const [error, setError] = useState<IError>(null);
    const [loading, setLoading] = useState<ILoading>(true);
    const [images, setImages] = useState<IDynamicObject<ImageModel>>({});
    const [isLoaded, setIsLoaded] = useState<boolean>(restaurant.id ? false : true);

    const loadImages = (): void => {
        if (!restaurant?.id) return;
        setLoading(
            ImageDAO.loadImages()
                .then((data) => {
                    setImages(Objectify(data, "image_id"));
                    setIsLoaded(true);
                })
                .catch((error) => setError(error))
                .finally(() => setLoading(false))
        );
    };
    useDidMount(loadImages);

    const saveImage = (
        image: Blob | File,
        dimensions: IImageDimensions,
        imageName?: string,
        cb?: (img: ImageModel) => void
    ): void => {
        setLoading({
            reason: t<ILang>("saving_image"),
            promise: ImageDAO.save(image, dimensions, imageName)
                .then((newImg) => {
                    const state = cloneDeep(images);
                    state[newImg.image_id] = newImg;
                    setImages(state);
                    if (cb) cb(newImg);
                })
                .catch((error) => setError(error))
                .finally(() => setLoading(false))
        });
    };

    const deleteImage = (id: string): void => {
        setLoading({
            reason: t<ILang>("delete"),
            promise: ImageDAO.remove(id)
                .then(() => {
                    const state = cloneDeep(images);
                    delete state[id];
                    setImages(state);
                })
                .catch((error) => setError(error))
                .finally(() => setLoading(false))
        });
    };

    const massDelete = (imageIds: string[]): void => {
        const requests = Promise.all(imageIds.map((id) => ImageDAO.remove(id)));
        setLoading({
            reason: t<ILang>("delete"),
            promise: requests
                .then(() => {
                    const state = cloneDeep(images);
                    for (const id of imageIds) {
                        delete state[id];
                    }
                    setImages(state);
                })
                .catch((error) => setError(error))
                .finally(() => setLoading(false))
        });
    };

    const cloneTemplateRestImages = async (template_restaurant_id: string) => {
        try {
            const data = await ImageDAO.cloneRestImages(template_restaurant_id);
            const restaurantImages = Objectify(data.restaurantImages, "image_id");
            const templateImages = Objectify(data.templateImages, "image_id");
            setImages(restaurantImages);
            return {
                restaurantImages,
                templateImages
            };
        } catch (error) {
            setError(error as IError);
            return {
                templateImages: {},
                restaurantImages: images
            };
        }
    };

    const cloneTemplateSectImages = async (templateId: string, imagesToClone: string[]) => {
        try {
            const data = await ImageDAO.cloneSectImages(templateId, imagesToClone);
            const restaurantImages = Objectify(data.restaurantImages, "image_id");
            const templateImages = Objectify(data.templateImages, "image_id");
            setImages(restaurantImages);
            return {
                restaurantImages,
                templateImages
            };
        } catch (error) {
            setError(error as IError);
            return {
                templateImages: {},
                restaurantImages: images
            };
        }
    };

    return (
        <ImagesContext.Provider
            value={{
                error,
                images,
                loading,
                saveImage,
                massDelete,
                deleteImage,
                cloneTemplateRestImages,
                cloneTemplateSectImages
            }}
        >
            {!isLoaded ? <Loading isExternalConditional /> : props.children}
        </ImagesContext.Provider>
    );
};

export const useImages = (): IImageConext => useContext(ImagesContext);
