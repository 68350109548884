import { ICommonContent, ImageModel } from "sitebuilder-common";
import { IDynamicObject } from "xa-generics";
import { ISection } from "../../DomTracker/Interfaces/IDomTypes.interface";

export const FindNonExistentImages = (
    section: ISection,
    images: IDynamicObject<ImageModel>,
    templateImages: IDynamicObject<ImageModel>
): string[] => {
    const imageIds: string[] = [];

    const loopSizes = (backgroundImageRule: ICommonContent): void => {
        if (!backgroundImageRule || typeof backgroundImageRule !== "object") return;
        ruleSizeLoop: for (let sizeKey in backgroundImageRule) {
            const fileName = templateImages[backgroundImageRule[sizeKey]].file_name;

            for (let imageId in images) {
                if (images[imageId].file_name === fileName) continue ruleSizeLoop;
            }

            imageIds.push(backgroundImageRule[sizeKey]);
        }
    };

    loopSizes(section.content.backgroundImage);
    for (const column of section.elements) {
        loopSizes(column.content.backgroundImage);

        columnLoop: for (const element of column.elements) {
            loopSizes(element.content.backgroundImage);

            if (element.type === "Image") {
                const templateFileName = templateImages[element.content.src].file_name;
                for (let imageId in images) {
                    if (images[imageId].file_name === templateFileName) continue columnLoop;
                }
                imageIds.push(element.content.src);
            }
        }
    }
    return imageIds;
};
