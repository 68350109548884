import { GetCurrency } from "../../../DomMapper/DomComponents/Products/Utils/GetCurrency.util";
import { FC, useState } from "react";
import { FrameQtyHook } from "../QtyHook/FrameQty.hook";
import { OrderItemModel } from "../../../../Models/OrderItem.model";
import { Checkmark, Close, TrashCan } from "@carbon/icons-react";
import { ToMoneyInt, useAfterTriggerChanged } from "xa-generics";

interface ICartItemElementProps {
    item: OrderItemModel;
}

export const CartItemElement: FC<ICartItemElementProps> = ({ item }) => {
    const currency = GetCurrency();
    const { JSX, qty, setQty } = FrameQtyHook({ defaultQuantity: item.quantity });
    const [showApply, setShowApply] = useState<boolean>(false);

    useAfterTriggerChanged(() => {
        if (qty !== item.quantity) {
            setShowApply(true);
        }
    }, [qty]);

    const classes: string[] = ["cart-item"];
    if (showApply) classes.push("cart-item-with-apply");

    return (
        <div className={classes.join(" ")}>
            <div className="cart-item__qty">{JSX}</div>
            {showApply && (
                <div className="cart-item__apply">
                    <button
                        className="cart-item__apply--button"
                        onClick={() => {
                            setShowApply(false);
                            setQty(item.quantity);
                        }}
                    >
                        <Close />
                    </button>
                    <button
                        className="cart-item__apply--button"
                        onClick={() => setShowApply(false)}
                    >
                        <Checkmark />
                    </button>
                </div>
            )}
            <div className="cart-item__name">
                <div className="cart-item__name--product">{item.name}</div>
                <div className="cart-item__name--options">{item.optionListName}</div>
            </div>
            <div className="cart-item__price">
                {ToMoneyInt(item.total_price)} {currency}
            </div>
            <button className="cart-item__control">
                <TrashCan size={20} />
            </button>
        </div>
    );
};
