import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { useWidgets } from "../../../Widgets/Controller/WidgetsContext.provider";
import { useTranslation } from "react-i18next";
import { INavbarSettings } from "../Interfaces/INavbarWidget.interface";
import { GetWidgetOverlay } from "../../Util/WidgetOverlay.util";
import { getScaledIconSize } from "../Utils/ScaledIconSize.util";
import { NavbarStatusStyles } from "../Static/NavbarStatus.static";
import { IPartialCSS, ParseComponentStyle } from "sitebuilder-common";
import WidgetOverlay from "../../../Widgets/View/WidgetOverlay.view";

interface INavbarStatusProps {}

export const NavbarStatus: FC<INavbarStatusProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { size } = useDom();
    const { widgets } = useWidgets();
    const { navbarStatus, disableOrder } = widgets.Navbar.draftSettings;
    const { showOverlay, ...events } = GetWidgetOverlay<INavbarSettings>("Navbar", "navbarStatus");

    if (disableOrder) return null;
    const statusStyles = ParseComponentStyle(navbarStatus, NavbarStatusStyles, size);

    const metaClasses: string[] = ["navbar__top__meta--info"];
    const isClosed: boolean = false;
    if (isClosed) metaClasses.push("--closed");

    //Closed status background and bg color can't be styled!
    const bgRelated: React.CSSProperties | undefined = isClosed
        ? undefined
        : typeof statusStyles === "object"
        ? {
              background: statusStyles.background,
              backgroundColor: statusStyles.backgroundColor
          }
        : undefined;

    //Closed status text color can't be styled!
    const colorRelated: IPartialCSS<any> | undefined = isClosed
        ? undefined
        : typeof statusStyles === "object"
        ? {
              color: isClosed ? undefined : statusStyles.color,
              fontSize: statusStyles.fontSize
          }
        : undefined;

    const iconSize = getScaledIconSize(statusStyles, 16);
    const statusMarker = `${iconSize * 0.6}px`;

    return (
        <div
            style={{ fontSize: statusStyles?.fontSize, fontWeight: statusStyles?.fontWeight }}
            className={metaClasses.join(" ")}
            {...events}
        >
            {showOverlay && (
                <WidgetOverlay<INavbarSettings>
                    align={"right"}
                    parent={widgets.Navbar}
                    subKey={"navbarStatus"}
                />
            )}
            <div
                className="navbar__top__meta--info--status"
                style={{ ...bgRelated, width: statusMarker, height: statusMarker }}
            ></div>
            <div className="navbar__top__meta--info--text" style={colorRelated}>
                {t<ILang>(isClosed ? "closed" : "opened")}
            </div>
        </div>
    );
};
