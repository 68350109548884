import { createRef, Dispatch, SetStateAction } from "react";
import { ISelectOption, useMountWithTriggers } from "xa-generics";
import { ILongSelectInputProps } from "./LongSelectInput.view";
import { useTranslation } from "react-i18next";
import { map } from "lodash";

export interface IListMapperProps<
    Fields extends object,
    Lang = string,
    OptionType extends object = ISelectOption
> extends ILongSelectInputProps<Fields, Lang, OptionType> {
    list: OptionType[];
    setLocalValue: Dispatch<SetStateAction<string>>;
    setShowOptions: Dispatch<SetStateAction<boolean>>;
}

const ListMapper = <
    Fields extends object,
    Lang = string,
    OptionType extends object = ISelectOption
>(
    props: IListMapperProps<Fields, Lang, OptionType>
) => {
    const { t } = useTranslation();
    const selectedRef = createRef<HTMLInputElement>();
    const id = props.id as string;
    //Scrolls the selected option into the view in the list if it's rendered
    useMountWithTriggers(() => {
        if (!selectedRef.current) return;
        selectedRef.current.scrollIntoView({
            behavior: "auto",
            block: "center",
            inline: "center"
        });
    }, [selectedRef]);
    return (
        <>
            {map(props.list, (option, index) => {
                let classes: string = "long-select__options--item";
                const value = option[props.idAccessor] as never as string;
                const isActive: boolean = value === props.value;
                if (isActive) classes += " active-option";
                return (
                    <div
                        className={classes}
                        key={`${index}-${id}-option-key`}
                        onMouseUp={() => {
                            props.setLocalValue(option[props.nameAccessor] as never as string);
                            props.setShowOptions(false);
                            props.onChange(value, option);
                        }}
                        ref={isActive ? selectedRef : undefined}
                    >
                        {props.useTranslationOnName
                            ? t(option[props.nameAccessor] as never as string)
                            : (option[props.nameAccessor] as never as string)}
                    </div>
                );
            })}
        </>
    );
};

export default ListMapper;
