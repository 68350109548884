import { useTranslation } from "react-i18next";
import { FormErrorView } from "../../../UseForm/FormError.view";
import { IFormErrors } from "../../../UseForm/IUseForm.interface";
import { IFieldBlur } from "../../../Editor/Utils/EditorHook.util";
import { ILang } from "../../../../Interfaces/ILang.type";
import { Checkmark } from "@carbon/icons-react";

export type IBooleanInput<Fields extends object> = {
    children?: any;
    value: boolean;
    id: keyof Fields;
    hidden?: boolean;
    noLabel?: boolean;
    labelText?: ILang;
    required?: boolean;
    disabled?: boolean;
    className?: string;
    errors?: IFormErrors<Fields>;
    description?: string | JSX.Element;
    onBlur?: (data: IFieldBlur<Fields>) => void;
    onChange?: (id: keyof Fields, value: boolean) => void;
};

export const CheckboxInput = <Fields extends object>(props: IBooleanInput<Fields>) => {
    const { t } = useTranslation<ILang>();
    let wrapperStyle: string[] = ["wrapper wrapper-sm checkbox-wrapper"];

    const id = props.id as ILang;

    if (props.className) wrapperStyle.push(props.className);
    if (props.disabled) wrapperStyle.push("disabled-checkbox");
    if (props.hidden) wrapperStyle.push("wrapper-hidden");

    return (
        <div className={wrapperStyle.join(" ")}>
            <label className="checkbox-container" htmlFor={id}>
                <input
                    type={"checkbox"}
                    checked={props.value}
                    className="checkbox-input"
                    disabled={props.disabled}
                    onChange={(e) => {
                        if (props.onChange) {
                            props.onChange(props.id, e.target.checked);
                        }
                        if (props.onBlur) {
                            props.onBlur({
                                value: e.target.checked,
                                field: props.id
                            });
                        }
                    }}
                    name={id}
                    id={id}
                />
                <div className="check-mark">
                    {props.value && <Checkmark className={"checkmark"} size={22} />}
                </div>
                {!props.noLabel && <>{t<string>((props.labelText as any) || id)}</>}
                {props.children}
            </label>
            {props.description && <div className="input-description">{props.description}</div>}
            {props.errors && <FormErrorView id={props.id} errors={props.errors} />}
        </div>
    );
};
