import { FC } from "react";
import { Img } from "xa-image";
import { ILang } from "../../../../../Interfaces/ILang.type";
import { useWidgets } from "../../../../Widgets/Controller/WidgetsContext.provider";
import { ProductModel } from "../Models/Product.model";
import { ClientButton } from "../../Button/ClientButton.view";
import { ShoppingCart } from "@carbon/icons-react";
import { useRestaurant } from "../../../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import { GetCurrency, RenderPrice } from "../Utils/GetCurrency.util";
import Loading from "../../../../UI/Loading/Loading.view";

interface IProductBlockElementProps {
    product: ProductModel;
    trimDescription: number;
}

export const ProductBlockElement: FC<IProductBlockElementProps> = ({ product, ...props }) => {
    const { t } = useTranslation<ILang>();
    const { config } = useRestaurant();
    const currency = GetCurrency();
    const { widgets } = useWidgets();
    const isDisabled = widgets?.Navbar.draftSettings.disableOrder;

    const getDesc = (): string => {
        if (product.description.length > props.trimDescription) {
            return `${product.description.slice(0, props.trimDescription)}...`;
        }
        return product.description;
    };

    return (
        <div className={"product"}>
            <div className="product__details">
                <h5 className="product__details--name">{product.name}</h5>

                {product.description && (
                    <div className="product__details--description">{getDesc()}</div>
                )}

                <h6 className="product__details--price" style={{ color: config.main_btn_bg_color }}>
                    {RenderPrice(currency, product.base_price)}
                </h6>

                {!isDisabled && (
                    <ClientButton LeftIcon={ShoppingCart}>{t<ILang>("add_to_cart")}</ClientButton>
                )}
            </div>
            <div className="product__image-container">
                <Img
                    className="product__image-container--image"
                    loader={<Loading isExternalConditional isAbsolute />}
                    src={product.image.medium}
                    alt={product.name}
                />
            </div>
        </div>
    );
};
