import { FC } from "react";
import { axios } from "xa-generics";
import { ILang } from "../../../Interfaces/ILang.type";
import { AXIOS_NAMES } from "../../../Static/AxiosInstanceNames.static";
import { SaveLangCookie } from "../../../Utils/SaveLangCookie.util";
import { useTranslation } from "react-i18next";

interface ILangBoxViewProps {
    lang: string;
    unsetEverything: () => void;
}

export const LangBoxView: FC<ILangBoxViewProps> = ({ lang, unsetEverything }) => {
    const { i18n } = useTranslation<ILang>();
    const langClasses: string[] = ["lang"];
    if (lang === i18n.language) langClasses.push("active-lang");
    return (
        <div
            className={langClasses.join(" ")}
            onClick={() => {
                axios.setLanguage(lang, AXIOS_NAMES.BASIC);
                axios.setLanguage(lang, AXIOS_NAMES.ONEMIN);
                i18n.changeLanguage(lang);
                SaveLangCookie(lang);
                unsetEverything();
            }}
        >
            {lang.toUpperCase()}
        </div>
    );
};
