import { ProductCategoryModel } from "./../../DomMapper/DomComponents/Products/Models/ProductCategory.model";
import { IDynamicObject } from "xa-generics";
import { PathMatch } from "react-router";

export const GetBackLocation = (
    path: string,
    catMatch: PathMatch<"categoryId"> | null,
    categoriesById: IDynamicObject<ProductCategoryModel>,
    prodMatch: PathMatch<"productId" | "categoryId"> | null
): string => {
    switch (path) {
        case "/cart/settings":
            return "/cart";
        case "/cart/finalize":
            return "/cart/settings";
        default:
            if (!catMatch && !prodMatch) return "/order";

            if (prodMatch) {
                return path.split("/products")[0];
            }

            let category = null;
            const catParams = catMatch?.params;
            const catId = catParams?.categoryId;
            if (catId) category = categoriesById[catId];
            if (category) {
                if (category.parent_id) category = categoriesById[category.parent_id];
                else return "/order";

                return `/order/categories/${category.id}`;
            }

            return "/order";
    }
};
