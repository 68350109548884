import { useState } from "react";
import { IWidgets } from "../../Widgets/Interfaces/IWidgets.type";
import { useWidgetEditor } from "../../Editor/Controller/WidgetEditorContext.provider";

interface IReturn {
    showOverlay: boolean;
    onMouseEnter: React.MouseEventHandler<any>;
    onMouseLeave: React.MouseEventHandler<any>;
}

export const GetWidgetOverlay = <WidgetGroup extends object>(
    widgetType: keyof IWidgets,
    subKey: keyof WidgetGroup
): IReturn => {
    const [isMouseOver, setisMouseOver] = useState<boolean>(false);
    const { widgetEditor } = useWidgetEditor();

    let showOverlay = isMouseOver;
    if (widgetEditor.group?.type === widgetType && subKey === widgetEditor.subKey) {
        showOverlay = true;
    }

    return {
        onMouseEnter: () => setisMouseOver(true),
        onMouseLeave: () => setisMouseOver(false),
        showOverlay
    };
};
