import { FC } from "react";
import { ILang } from "../../../../../Interfaces/ILang.type";
import { useDom } from "../../../../DomTracker/Controller/DomTracker.provider";
import { useProducts } from "../Context/ProductsContext.provider";
import { GetCurrency } from "../Utils/GetCurrency.util";
import { ClientButton } from "../../Button/ClientButton.view";
import { useRestaurant } from "../../../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import { IProductsIndexProps } from "./Products.index";
import { Delivery, Tag, TrashCan } from "@carbon/icons-react";
import { CategorizedProductElement } from "./CategorizedProduct.element";
import Modal from "../../../../UI/Modal/Modal.view";

interface ICategorizedProductsProps extends IProductsIndexProps {
    trimDescription: number;
    useTrimOnCategorized: boolean;
}

export const CategorizedProducts: FC<ICategorizedProductsProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { categories, products } = useProducts(props.filteredIds);
    const { config, restaurant } = useRestaurant();
    const { size } = useDom();
    const currency = GetCurrency();
    const classes: string[] = ["products-with-cart", `products-with-cart-${size}`];
    if (config.is_dark_theme) classes.push("dark-theme-products");
    if (config.is_rounded_btn) classes.push("rounded-corners");

    const color = config.is_dark_theme ? "#ffffff" : "#000000";

    return (
        <>
            <div className={classes.join(" ")} style={{ color }}>
                <div className="container">
                    {categories.map((category) => {
                        if (category.parent_id) return null;
                        return (
                            <div className="category" key={`${category.id}-cat-container`}>
                                <h3 className="category__name" id={category.slugName}>
                                    {category.name}
                                </h3>
                                {category.description && (
                                    <h5 className="category__description">
                                        {category.description}
                                    </h5>
                                )}

                                <div className="category-products">
                                    {products[category.id] &&
                                        products[category.id].map((product) => (
                                            <CategorizedProductElement
                                                useTrimOnCategorized={props.useTrimOnCategorized}
                                                key={`${product.id}-product-of-${category.id}`}
                                                setShowOptionWarn={props.setShowOptionWarn}
                                                trimDescription={props.trimDescription}
                                                category={category}
                                                currency={currency}
                                                product={product}
                                            />
                                        ))}
                                </div>

                                {category.has_child &&
                                    categories.map((subCategory) => {
                                        if (subCategory.parent_id !== category.id) return null;

                                        return (
                                            <div
                                                className="sub-category"
                                                id={subCategory.slugName}
                                                key={`${subCategory.id}-sub-category`}
                                            >
                                                <h3 className="sub-category__name">
                                                    {subCategory.name}
                                                </h3>
                                                {subCategory.description && (
                                                    <h5 className="sub-category__description">
                                                        {subCategory.description}
                                                    </h5>
                                                )}

                                                <div className="category-products">
                                                    {products[subCategory.id] &&
                                                        products[subCategory.id].map((product) => (
                                                            <CategorizedProductElement
                                                                key={`${product.id}-product-of-${subCategory.id}`}
                                                                useTrimOnCategorized={
                                                                    props.useTrimOnCategorized
                                                                }
                                                                trimDescription={
                                                                    props.trimDescription
                                                                }
                                                                setShowOptionWarn={
                                                                    props.setShowOptionWarn
                                                                }
                                                                category={subCategory}
                                                                currency={currency}
                                                                product={product}
                                                            />
                                                        ))}
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        );
                    })}
                </div>
                <div className="cart">
                    <div className="cart-container">
                        <h2 className="cart__title">{t<ILang>("your_order")}</h2>

                        <div className="cart__items">
                            <div className="cart-item">
                                <div className="cart-item__count">1</div>
                                <div className="cart-item__name">Marcipános eperkrémleves</div>
                                <div className="cart-item__price">1 890 Ft</div>
                                <button className={"cart-item__delete"}>
                                    <TrashCan className={"trashcan"} />
                                </button>
                            </div>
                            <div className="cart-item">
                                <div className="cart-item__count">2</div>
                                <div className="cart-item__name">
                                    Bélszíből készült prémium steak
                                </div>
                                <div className="cart-item__price">8 199 Ft</div>
                                <button className={"cart-item__delete"}>
                                    <TrashCan className={"trashcan"} />
                                </button>
                            </div>
                        </div>

                        <div className="cart__footer">
                            <div className="cart__footer--prices">
                                <div className="cart__footer--prices--name">
                                    {t<ILang>("delivery_price")}
                                </div>
                                <div className="cart__footer--prices--price">699 Ft</div>
                            </div>
                            <div className="cart__footer--prices">
                                <div className="cart__footer--prices--name">
                                    {t<ILang>("discount")}
                                </div>
                                <div className="cart__footer--prices--price">500 Ft</div>
                            </div>
                            <div className="cart__footer--prices total-price">
                                <div className="cart__footer--prices--name">
                                    {t<ILang>("total_price")}
                                </div>
                                <div className="cart__footer--prices--price">10 288 Ft</div>
                            </div>

                            <ClientButton>{t<ILang>("finalize_order")}</ClientButton>
                        </div>
                    </div>
                    <div className="cart-container info-container">
                        <div className="cart__info">
                            <div className="cart__info--icon">
                                <Delivery size={32} />
                            </div>
                            <div className="cart__info--label">
                                {restaurant.estimated_delivery_time} {t<ILang>("mins")}
                            </div>
                            <div className="cart__info--note">{t<ILang>("avg_time")}</div>
                        </div>
                        <div className="cart__info">
                            <div className="cart__info--icon">
                                <Tag size={32} />
                            </div>
                            <div className="cart__info--label">{restaurant.min_order_price} Ft</div>
                            <div className="cart__info--note">{t<ILang>("min_order_cart")}</div>
                        </div>
                    </div>
                </div>
            </div>

            {props.showOptionWarn && (
                <Modal
                    isPassive
                    onClose={() => props.setShowOptionWarn(false)}
                    allowEnterKeyDown
                    heading={t<ILang>("warning")}
                >
                    {t<ILang>("option_warn_desc")}
                </Modal>
            )}
        </>
    );
};
