import { PageDataModel } from "../../DomTracker/Model/PageData.model";
import { cloneDeep } from "lodash";
import { createRef } from "react";
import { ISection } from "../../DomTracker/Interfaces/IDomTypes.interface";
import { IRefs } from "../../DomTracker/Interfaces/IRefs.type";
import { v4 } from "uuid";

export const SanitizeSection = (template: ISection, refs: IRefs): ISection => {
    const domClone = cloneDeep(template);
    const sectionUUID = v4();
    refs.current[sectionUUID] = createRef();
    domClone.uuid = sectionUUID;

    for (let sI = 0; sI < domClone.elements.length; sI++) {
        domClone.elements[sI].sectionUUID = sectionUUID;

        const columnUUID = v4();
        refs.current[columnUUID] = createRef();
        domClone.elements[sI].uuid = columnUUID;
        const columnElementLength = domClone.elements[sI].elements.length;

        for (let cI = 0; cI < columnElementLength; cI++) {
            domClone.elements[sI].elements[cI].sectionUUID = sectionUUID;
            domClone.elements[sI].elements[cI].columnUUID = columnUUID;

            const elementUUID = v4();
            refs.current[elementUUID] = createRef();
            domClone.elements[sI].elements[cI].uuid = elementUUID;
        }
    }
    return domClone;
};

export const SanitizePages = (
    state: PageDataModel[],
    templatePages: PageDataModel[],
    refs: IRefs
): void => {
    for (let templatePage of templatePages) {
        for (let page of state) {
            //sI = sectionIndex | Rebuilds the uuid references recursively and creates refs for them
            for (let sI = 0; sI < page.elements.dom.length; sI++) {
                if (templatePage.elements.dom[sI]) {
                    templatePage.elements.dom[sI] = SanitizeSection(
                        templatePage.elements.dom[sI],
                        refs
                    );
                }
            }

            if (page.page_type) continue;
            //tSI = templateSeoIndex | Checks for currently matching urls and dirties the template urls if necessary
            for (let tSI = 0; tSI < templatePage.seos.length; tSI++) {
                for (let pageSeo of page.seos) {
                    if (pageSeo.url === templatePage.seos[tSI].url) {
                        //Attaches a short random string to the end of the url to make it unique
                        templatePage.seos[tSI].url += `-${Math.random().toString(36).slice(5)}`;
                    }
                }
            }
        }
    }
};
