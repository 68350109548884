import { LANGIFIED_PROPS } from "../../../Static/LangifiedProps.static";
import { IDynamicObject } from "xa-generics";
import { IAllDomTypes } from "../Interfaces/IDomTypes.interface";
import { IPartialCSS } from "sitebuilder-common";
import { ALL_STYLES } from "../../../Static/AllStyles.static";
import { ISizes } from "sitebuilder-common";

export const TransformContent = (
    node: IAllDomTypes,
    newContent: IDynamicObject<any>,
    size: ISizes,
    currentLang: string
): void => {
    let transformedContent: IDynamicObject<any> = node.content;
    let common: IDynamicObject<any> = {};
    for (let key in newContent) {
        const cssRule = key as keyof IPartialCSS;
        if (!(cssRule in ALL_STYLES)) {
            if (key in LANGIFIED_PROPS) {
                if (!transformedContent[currentLang]) transformedContent[currentLang] = {};
                transformedContent[currentLang] = {
                    ...transformedContent[currentLang],
                    ...newContent
                };
            } else {
                common[key] = newContent[key];
            }
            continue;
        }
        if (!transformedContent[cssRule]) transformedContent[cssRule] = {};
        transformedContent[cssRule][size] = newContent[cssRule];
    }
    node.content = { ...transformedContent, ...common };
};
