import { IVideoStyles } from "../Interfaces/IVideoComponent.interface";
import {
    BackgroundStyles,
    PaddingStyles,
    MarginStyles,
    HeightStyles,
    WidthStyles
} from "./GroupsStyles.static";

export const VideoStyles: Required<IVideoStyles<true>> = {
    ...WidthStyles,
    ...MarginStyles,
    ...HeightStyles,
    ...PaddingStyles,
    ...BackgroundStyles
};
