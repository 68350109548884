import { ModelConstructor, IDynamicObject } from "xa-generics";
import { v4 as uuidv4 } from "uuid";
import { IWidgets } from "../Interfaces/IWidgets.type";

export class WidgetsModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as WidgetsMData;
        ModelConstructor(data, this);
    }

    created_at: string = "";
    widgets: string = "";
    restaurant_id: string = "";
    updated_at: string = "";

    private generateDefault(key: keyof IWidgets, data?: any): any {
        return {
            draftSettings: data || {},
            settings: {},
            type: key,
            uuid: uuidv4()
        };
    }

    public getWidgetsJSON(lctEnabled?: boolean): IWidgets {
        let parsed: Partial<IWidgets> = this.widgets ? JSON.parse(this.widgets) : {};
        if (!parsed.Navbar) parsed.Navbar = this.generateDefault("Navbar");
        if (!parsed.Footer) parsed.Footer = this.generateDefault("Footer");
        if (!parsed.ThankYou) {
            parsed.ThankYou = this.generateDefault(
                "ThankYou",
                lctEnabled ? { isTrackerVisible: true } : {}
            );
        }
        return parsed as IWidgets;
    }
}

//If you add public methods, you may want to replace the empty string with them!
type WidgetsMData = Partial<InstanceType<typeof WidgetsModel>>;
