import { useLocation, useMatch, useNavigate } from "react-router";
import { ISetState, useMountWithTriggers } from "xa-generics";
import { ProductCategoryModel } from "../../../DomMapper/DomComponents/Products/Models/ProductCategory.model";
import { IProductSelector } from "../../../DomMapper/DomComponents/Products/Interfaces/IProductSelector.interface";
import { SubproductModel } from "../../../DomMapper/DomComponents/Products/Models/Subproduct.model";
import { ISelectorData } from "../../../DomMapper/DomComponents/Products/Interfaces/ISelectorData.type";
import { AppOrderIndex } from "./AppOrder.index";
import { ProductModel } from "../../../DomMapper/DomComponents/Products/Models/Product.model";
import { GetCurrency } from "../../../DomMapper/DomComponents/Products/Utils/GetCurrency.util";
import { useProducts } from "../../../DomMapper/DomComponents/Products/Context/ProductsContext.provider";
import { useState } from "react";

export interface IAppOrderControllerProps {
    setIsMenuOpen: ISetState<boolean>;
    isMenuOpen: boolean;
}

export const AppOrderController: React.FC<IAppOrderControllerProps> = (props) => {
    const { catObject, products } = useProducts();
    const { pathname } = useLocation();

    const currency = GetCurrency();
    const navigateTo = useNavigate();
    const [selector, setSelector] = useState<IProductSelector>({
        category: null,
        product: null
    });

    const setSelectorData = (data: ISelectorData): void => {
        setSelector((current) => {
            const state = { ...current };
            if (data instanceof ProductCategoryModel) {
                state.category = data;
                state.product = null;
            }
            if (data instanceof ProductModel) {
                state.product = data;
            }
            if (data && "category" in data && "product" in data) {
                state.category = data.category;
                state.product = data.product;
            }
            return state;
        });
    };

    const unsetSelector = (unsetType: keyof IProductSelector): void => {
        setSelector((current) => {
            const state = { ...current };
            switch (unsetType) {
                case "category":
                    state.category = null;
                    state.product = null;
                    break;
                case "product":
                    state.product = null;
                    break;
            }
            return state;
        });
    };

    const cat = "/order/categories/:categoryId";
    const prod = `${cat}/products/:productId`;
    const categoryMatch = useMatch(cat);
    const productMatch = useMatch(prod);

    const appProudctNavigate = (
        data: ProductCategoryModel | ProductModel | SubproductModel | null
    ): void => {
        if (data instanceof ProductCategoryModel) {
            navigateTo(`/order/categories/${data.id}`);
        } else if (data instanceof ProductModel) {
            navigateTo(`/order/categories/${data.product_category_id}/products/${data.id}`);
        } else {
            navigateTo("/order");
        }
    };

    useMountWithTriggers(() => {
        let category = null,
            product = null;

        const catParams = categoryMatch?.params,
            prodParams = productMatch?.params;

        const catId = catParams?.categoryId || prodParams?.categoryId;
        const prodId = prodParams?.productId;

        if (catId) category = catObject[catId];
        if (prodId && category) {
            product = products[category.id]?.find((product) => product.id === prodId);
        }
        if (categoryMatch && category) return setSelectorData(category);
        if (product) return setSelectorData(product);
        return unsetSelector("category");
    }, [pathname, catObject]);

    return (
        <AppOrderIndex
            {...props}
            currency={currency}
            selector={selector}
            unsetSelector={unsetSelector}
            setSelectorData={setSelectorData}
            appProudctNavigate={appProudctNavigate}
        />
    );
};
