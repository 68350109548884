import { IAllDomTypes, IComponentTypes } from "../../DomTracker/Interfaces/IDomTypes.interface";
import { useMountWithTriggers } from "xa-generics";
import { useComponentDnD } from "../../ComponentDnD/Provider/ComponentDnD.provider";
import { ICommonContent } from "sitebuilder-common";
import { isSpecialType } from "./IsSpecialType.util";
import { useEditor } from "./../../Editor/Controller/EditorContext.provider";
import { useState } from "react";
import { useDom } from "../../DomTracker/Controller/DomTracker.provider";

export const DnDEvents = <ContentType extends ICommonContent>(
    dom: IAllDomTypes | "PAGE_CONTAINER",
    dragActiveForType?: IComponentTypes | "ELEMENT",
    isFirstSection?: boolean,
    isSpecial: boolean = false
) => {
    const { editor, dragOrigin, setDragOrigin } = useEditor();
    const { handleItemDragEnd, handleSectionDragEnd } = useComponentDnD();
    const { refs, size, draggedNode, currentPage, capturedNode, setDraggedNode } =
        useDom<ContentType>();
    const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
    const id = typeof dom === "string" ? dom : dom.uuid;
    const node = (refs.current[id]?.current || null) as HTMLDivElement | null;

    const isEdited: boolean = editor?.uuid === id;
    const draggable: boolean = dragOrigin?.uuid === id;

    const showOverlay: boolean = isMouseOver || isEdited || draggable;

    useMountWithTriggers(() => {
        if (node && dragOrigin && dragOrigin.uuid === id) {
            node!.classList.add("global__draggable");
        }
        return () => {
            if (node) {
                node!.classList.remove("global__draggable");
            }
        };
    }, [dragOrigin, node, (dom as IAllDomTypes)?.uuid]);

    const calcPosition = (): number => {
        let halfHeight: number;
        if (dragActiveForType === "ELEMENT" && node!.parentElement) {
            const rectTop = node!.parentNode?.parentElement?.offsetTop || 0;
            halfHeight = node!.offsetTop + rectTop + node!.offsetHeight / 2;
        } else halfHeight = node!.offsetTop + node!.offsetHeight / 2;
        return halfHeight;
    };

    const changeClassNames = (e: React.DragEvent<HTMLDivElement>, halfHeight: number): void => {
        if (e.pageY <= halfHeight) {
            node!.classList.remove("global__insert-below");
            node!.classList.add("global__insert-above");
        } else {
            node!.classList.remove("global__insert-above");
            node!.classList.add("global__insert-below");
        }
    };
    return {
        onDragOverCapture: (e: React.DragEvent<HTMLDivElement>) => {
            if (!currentPage.isCurrentPageDraft) return;
            // if (isDraggedNonSpecial && isSpecial) return;
            if (typeof dom === "object") {
                if (draggedNode === "Section" && dom.type === "SlideShow") {
                    capturedNode.current = dom;
                    return;
                }
                //If the dragged cmp is special like Section, but the drop target is element, return...
                if (isSpecialType(draggedNode) && dom.type !== "Section") return;
                //If the dragged cmp is plain element like Text but the drop target is special like Section, ContactForm, return...
                if (isSpecialType(draggedNode, false) && (isSpecialType(dom.type) || isSpecial)) {
                    return;
                }
            }
            //If plain element is over plain element/column or special type is over special
            //type (ex: Section, ContactForm), set the capture target to it.
            capturedNode.current = dom;
        },
        onMouseEnter: () => {
            if (!currentPage.isCurrentPageDraft) return;
            if (isFirstSection && refs.current[id].current) {
                refs.current[id].current!.classList.add("show-as-top-section");
            }
            setIsMouseOver(true);
        },
        onMouseLeave: () => {
            if (!currentPage.isCurrentPageDraft) return;
            if (isFirstSection && refs.current[id].current) {
                refs.current[id].current!.classList.remove("show-as-top-section");
            }
            setIsMouseOver(false);
        },
        onDragOver: (e: React.DragEvent<HTMLDivElement>) => {
            if (!node) return;
            if (!currentPage.isCurrentPageDraft) return;
            const isNonSpecialDrag = isSpecialType(draggedNode, false);
            if (isNonSpecialDrag && isSpecial) return;
            if (isNonSpecialDrag && dragActiveForType === "Column") {
                node.classList.add("global__insert-into-column");
            }
            if (dragActiveForType && typeof dom === "object") {
                const isNonElementType = dom.type === "Section" || dom.type === "Column";
                if (dragActiveForType === "ELEMENT") {
                    if (isNonElementType) return;
                    if (isSpecialType(draggedNode)) return;
                } else if (isSpecialType(draggedNode) && isSpecialType(dom.type, false)) return;
            }
            const halfHeight = calcPosition();
            changeClassNames(e, halfHeight);
        },
        onDragLeave: () => {
            if (!node) return;
            node.classList.remove(
                "global__insert-below",
                "global__insert-above",
                "global__insert-into-column"
            );
        },
        onDragStart: (e: React.DragEvent<HTMLDivElement>) => {
            if (!node || !draggable || typeof dom !== "object" || dom.type === "Column") return;

            setDragOrigin(dom);
            setDraggedNode(dom.type);
        },
        onDragEnd: (e: React.DragEvent<HTMLDivElement>) => {
            if (capturedNode.current && typeof capturedNode.current !== "string" && draggable) {
                if (isSpecialType(dragOrigin?.type)) handleSectionDragEnd(e, dragOrigin!.type);
                else handleItemDragEnd(e, dragOrigin!.type);
            }
        },
        showOverlay,
        draggable,
        size,
        refs,
        id
    };
};
