import { SPECIAL_TYPES } from "../../../Static/SpecialTypes.static";

export const isSpecialType = <T extends unknown>(
    comparedValue: T,
    shouldMatch: boolean = true
): boolean => {
    if (!shouldMatch) {
        //If it matches either of the keys, return false
        for (let key in SPECIAL_TYPES) {
            if (comparedValue === key) return false;
        }
        return true;
    }

    //If it matches either of the special keys, return true
    for (let key in SPECIAL_TYPES) {
        if (comparedValue === key) return true;
    }
    return false;
};
