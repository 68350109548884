import InputGrouper from "../../InputGrouper/InputGrouper.view";
import ResetFormView from "../../InputGrouper/ResetForm.view";
import { BG_ATTACHMENT_OPTIONS } from "./BGAttachmentOptions.static";
import { BG_POSITION_OPTIONS } from "./BGPositionOptions.static";
import { IBackgroundImage } from "./IBackgroundImage.interface";
import { BG_SIZE_OPTIONS } from "./BGSizeOptions.static";
import { CheckIfHasValue } from "../../../../Utils/CheckIfHasValue.util";
import { CheckboxInput } from "../../InputFields/Checkbox/Checkbox.view";
import { ISelectOption } from "xa-generics";
import { BGImgDefaults } from "./Defaults.static";
import { ResetValues } from "../../../Editor/Utils/ResetValues.util";
import { IInputGroup } from "../../../../Interfaces/IInputGroup.interface";
import { SelectInput } from "../../InputFields/Select/SelectInput.view";
import { Destructure } from "../../../../Utils/Destructure.util";
import { ImageInput } from "../../InputFields/ImageInput/Controller/ImageInput.controller";
import { useForm } from "../../../UseForm/UseForm.provider";
import { Image } from "@carbon/icons-react";

export interface IBackgroundImageGroupProps extends IInputGroup<IBackgroundImage<any>> {}

const BackgroundImageGroup: React.FC<IBackgroundImageGroupProps> = (props) => {
    const form = useForm<IBackgroundImage<any>, true>({
        editor: Destructure(BGImgDefaults, props.defaultValues)
    });

    const onChange = (field: keyof IBackgroundImage, value: any): void => {
        form.handleChange(field, value);
        if (value) props.onBlur({ field, value });
        else props.onReset([field]);
    };

    return (
        <InputGrouper
            Icon={Image}
            labelKey={"backgroundImage"}
            groupKey={"BackgroundImage"}
            Reset={
                <ResetFormView
                    hasValue={CheckIfHasValue(form, BGImgDefaults)}
                    onReset={(e) => ResetValues(e, BGImgDefaults, form.reset, props.onReset)}
                />
            }
        >
            <div className="background-image-form">
                <ImageInput
                    noLabel
                    id={"backgroundImage"}
                    onChange={onChange}
                    value={form.editor.backgroundImage}
                />

                <SelectInput<IBackgroundImage, ISelectOption>
                    isClearable
                    useTranslationOnName
                    id={"backgroundSize"}
                    errors={form.fieldErrors}
                    options={BG_SIZE_OPTIONS}
                    onChange={onChange}
                    value={form.editor.backgroundSize}
                />

                <SelectInput<IBackgroundImage, ISelectOption>
                    labelText={"backgroundPosition"}
                    errors={form.fieldErrors}
                    id={"backgroundPosition"}
                    useTranslationOnName
                    isClearable
                    onChange={onChange}
                    options={BG_POSITION_OPTIONS}
                    value={form.editor.backgroundPosition}
                />

                <SelectInput<IBackgroundImage, ISelectOption>
                    isClearable
                    errors={form.fieldErrors}
                    useTranslationOnName
                    id={"backgroundAttachment"}
                    onChange={onChange}
                    options={BG_ATTACHMENT_OPTIONS}
                    value={form.editor.backgroundAttachment}
                />

                <CheckboxInput<IBackgroundImage>
                    onChange={onChange}
                    id={"backgroundRepeat"}
                    value={form.editor.backgroundRepeat}
                />
            </div>
        </InputGrouper>
    );
};

export default BackgroundImageGroup;
