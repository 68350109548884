import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { CarouselView } from "../View/Carousel.view";
import { IDynamicObject } from "xa-generics";
import { ISlideshowContent } from "../../../DomMapper/Interfaces/ISlideShowComponent.interface";
import { IDomElement, ISection } from "../../../DomTracker/Interfaces/IDomTypes.interface";
import { FC, useLayoutEffect, useRef, useState } from "react";

export interface ICarouselProps extends IDomElement<ISlideshowContent> {
    childSections: ISection[];
}

export const Carousel: FC<ICarouselProps> = (props) => {
    const childRefs = useRef<IDynamicObject<HTMLElement | null>>({});
    const carouselRef = useRef<HTMLElement | null>(null);
    const { size } = useDom();

    const [oneWidth, setOneWidth] = useState<number>(0);
    const [currentVisibleIndex, setCurrentVisibleIndex] = useState<number>(0);

    useLayoutEffect(() => {
        const children = props.childSections || [];
        const current = children[currentVisibleIndex];
        if (!current || !carouselRef.current || !childRefs.current[current.uuid]) return;

        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const { target, contentRect } = entry;
                if (target === carouselRef.current) {
                    const carouselContainer = carouselRef.current;
                    const section = childRefs.current[current.uuid]!;
                    carouselContainer.style.maxHeight = `${section.scrollHeight + 20}px`;
                    carouselContainer.style.height = `${section.scrollHeight + 20}px`;
                    section.style.maxWidth = `${contentRect.width}px`;
                    setOneWidth(contentRect.width);

                    carouselContainer.scrollTo({
                        behavior: "smooth",
                        top: 0,
                        left: contentRect.width * currentVisibleIndex
                    });
                }
            }
        });
        if (carouselRef.current) {
            resizeObserver.observe(carouselRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [currentVisibleIndex, props.childSections, size]);

    return (
        <CarouselView
            {...props}
            oneWidth={oneWidth}
            childRefs={childRefs}
            carouselRef={carouselRef}
            childSections={props.childSections || []}
            currentVisibleIndex={currentVisibleIndex}
            setCurrentVisibleIndex={setCurrentVisibleIndex}
        />
    );
};
