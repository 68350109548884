import { FC } from "react";
import { useIFrame } from "../../Provider/IFrame.context";
import { useProducts } from "../../../DomMapper/DomComponents/Products/Context/ProductsContext.provider";
import { IPartialCSS } from "sitebuilder-common";
import { TopbarCategories } from "../../../DomMapper/DomComponents/Products/View/TopbarCategories.view";
import { FrameCategoryElement } from "./FrameCategory.element";

interface IIFrameProductsViewProps {}

export const IFrameProductsView: FC<IIFrameProductsViewProps> = (props) => {
    const { data, dataSource } = useIFrame();
    const { categories } = useProducts();

    const products = data[dataSource].products;
    const global = data[dataSource].global;
    const style: IPartialCSS<any> = {};

    const classes = ["iframe-products"];
    if (data[dataSource].global.isRounded) classes.push("rounded-corners");
    if (products.backgroundColor) style.backgroundColor = products.backgroundColor;
    if (products.color) style.color = products.color;

    return (
        <div className={classes.join(" ")}>
            <TopbarCategories
                isDarkTheme={global.isDarkTheme}
                isRounded={global.isRounded}
                style={{ backgroundColor: data[dataSource].buttons.backgroundColor }}
            />

            <div className="iframe-products-container" style={style}>
                {categories.map((category) => {
                    if (category.parent_id) return null;
                    const key = `${category.id}-category`;

                    if (category.has_child) {
                        return (
                            <div className="category parent-category" key={key}>
                                <h2 className="category__name">{category.name}</h2>

                                {categories.map((cat) => {
                                    if (cat.parent_id !== category.id) return null;

                                    return (
                                        <FrameCategoryElement
                                            category={cat}
                                            key={`${cat.id}-cof-${key}`}
                                        />
                                    );
                                })}
                            </div>
                        );
                    }
                    return <FrameCategoryElement category={category} key={key} />;
                })}
            </div>
        </div>
    );
};
