import { FC } from "react";
import { Add } from "@carbon/icons-react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { map, orderBy } from "lodash";
import { IWidgetGroups } from "../../../Widgets/Interfaces/IWidgetGroups.type";
import { NavbarLinkItem } from "./NavbarLinkItem.element";
import { useTranslation } from "react-i18next";
import { INavbarEditorProps } from "./NavbarEditor.index";
import Button from "../../../UI/Button/Button.view";

interface INavbarLinksEditorViewProps extends INavbarEditorProps {}

export const NavbarLinksEditorView: FC<INavbarLinksEditorViewProps> = (props) => {
    const { t } = useTranslation<ILang>();

    const widgetProp: IWidgetGroups = "navbarLinks";

    if (props.subKey !== widgetProp) return null;

    return (
        <>
            <div className={"navbar-editor"}>
                <Button
                    size={"FIELD"}
                    useContainer
                    kind={"SIMPLE"}
                    align={"right"}
                    Icon={Add}
                    onClick={props.addNewLink}
                >
                    {t<ILang>("add_new")}
                </Button>
                {map(orderBy(props.navLinks, "index"), (link, index) => (
                    <NavbarLinkItem {...props} link={link} key={`${index}-link-item`} />
                ))}
                {props.navLinks.length === 0 ? (
                    <div className={"no-data"}>{t<ILang>("no_data")}</div>
                ) : null}
            </div>
        </>
    );
};
