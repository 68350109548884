import { IWC } from "xa-generics";
import { IPartialFormInternal, IRule } from "./IUseForm.interface";

interface IFormErrorViewProps<Fields extends object> extends IWC {
    errors: IPartialFormInternal<Fields, IRule<string>>;
    id: keyof Fields;
}

export const FormErrorView = <Fields extends object>(props: IFormErrorViewProps<Fields>) => {
    let error: null | string = null;
    if (!props.errors || !props.errors[props.id] || typeof props.errors[props.id] !== "object") {
        return null;
    }
    for (let errorKey in props.errors[props.id]) {
        error = props.errors[props.id]![errorKey as keyof IRule] as never;
    }
    if (!error) return null;

    return (
        <div className={"global__error"}>
            {error}
            {props.children}
        </div>
    );
};
