import { useState } from "react";
import { ISideMenuContext } from "../Interfaces/ISideMenuContext.interface";
import { useMountWithTriggers } from "xa-generics";
import { Context, createContext, useContext } from "react";

/**
 * ## SideMenuContext
 */
const SideMenuContext: Context<ISideMenuContext> = createContext<ISideMenuContext>(null as any);

SideMenuContext.displayName = "SideMenuContext";

interface ISideMenuContextProviderProps {}

/**
 * ## SideMenu context provider component
 *
 */
export const SideMenuContextProvider: React.FC<ISideMenuContextProviderProps> = (props) => {
    const [isOpen, setisOpen] = useState<boolean>(window.innerWidth < 768 ? false : true);

    const onKeyDown = (e: KeyboardEvent): void => {
        if (e.ctrlKey && e.key === "b") {
            setisOpen(!isOpen);
        }
    };

    useMountWithTriggers(() => {
        document.addEventListener("keydown", onKeyDown, { capture: true, passive: true });
        return () => document.removeEventListener("keydown", onKeyDown, true);
    }, [isOpen]);

    return (
        <SideMenuContext.Provider
            value={{
                setisOpen,
                isOpen
            }}
        >
            {props.children}
        </SideMenuContext.Provider>
    );
};

export const useSideMenu = (): ISideMenuContext => useContext(SideMenuContext);
