import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { RenderPrice } from "../../../DomMapper/DomComponents/Products/Utils/GetCurrency.util";
import { ProductModel } from "../../../DomMapper/DomComponents/Products/Models/Product.model";
import { useTranslation } from "react-i18next";

interface IFrameProductElementProps {
    currency: string;
    product: ProductModel;
}

export const FrameProductElement: FC<IFrameProductElementProps> = ({ product, currency }) => {
    const { t } = useTranslation<ILang>();
    const classes = ["product-element"];
    if (!product.image.present) classes.push("product-without-image");

    const getDesc = () => {
        if (product.description.length > 200) {
            return `${product.description.slice(0, 200)}...`;
        }
        return product.description || t<ILang>("no_desc");
    };

    return (
        <div className={"product-element"}>
            <div className="product-element__name">{product.name}</div>
            <div className="product-element__description">{getDesc()}</div>
            <div className="product-element__price">
                {RenderPrice(currency, product.base_price)}
            </div>
            {product.image.present && (
                <div
                    className="product-element__image"
                    style={{ backgroundImage: `url(${product.image.medium})` }}
                ></div>
            )}
        </div>
    );
};
