import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useForm } from "../../../UseForm/UseForm.provider";
import { TextInput } from "../../InputFields/TextInput/TextInput.view";
import { SendToBack } from "@carbon/icons-react";
import { ResetValues } from "../../../Editor/Utils/ResetValues.util";
import { IInputGroup } from "../../../../Interfaces/IInputGroup.interface";
import { Destructure } from "../../../../Utils/Destructure.util";
import { BoxShDefaults } from "./Defaults.static";
import { IFormWithUnit } from "../../../../Interfaces/IFormWithUnit.type";
import { useTranslation } from "react-i18next";
import { ParseBoxShadow } from "../../../../Utils/ParseBoxShadow.util";
import { CheckIfHasValue } from "../../../../Utils/CheckIfHasValue.util";
import { useAfterTriggerChanged } from "xa-generics";
import { IBoxShadow, IBoxShadowGroup } from "./IBoxShadow.interface";
import SizeUnitControlView from "../../SizeUnitControl/SizeUnitControl.view";
import ResetFormView from "../../InputGrouper/ResetForm.view";
import InputGrouper from "../../InputGrouper/InputGrouper.view";
import ColorInput from "../../InputFields/ColorInput/ColorInput.view";

export interface IBoxShadowViewProps extends IInputGroup<IBoxShadowGroup<any>> {}

const BoxShadowView: FC<IBoxShadowViewProps> = (props) => {
    const { t } = useTranslation<ILang>();

    const form = useForm<IFormWithUnit<IBoxShadow>, true>({
        editor: Destructure(
            BoxShDefaults,
            ParseBoxShadow(props.defaultValues.boxShadow),
            props.units.boxShadow
        )
    });

    const toBoxShadow = (state: IBoxShadow): string => {
        return `${state.offsetX}px ${state.offsetY}px ${state.blurRadius}px ${state.spreadRadius}px ${state.color}`;
    };

    useAfterTriggerChanged(() => {
        form.handleSubmit((state) => {
            const boxShadow = toBoxShadow(state);
            props.onBlur({ field: "boxShadow", value: boxShadow });
        })();
    }, [form.editor]);

    return (
        <InputGrouper
            groupKey={"BoxShadowGroup"}
            Icon={SendToBack}
            labelKey={"boxShadow"}
            Controls={
                <SizeUnitControlView
                    unit={form.editor.unit}
                    setUnit={form.handleChange}
                    disableRem
                    disablePercentage
                />
            }
            Reset={
                <ResetFormView
                    hasValue={CheckIfHasValue(form, BoxShDefaults)}
                    onReset={(e) => {
                        ResetValues(e, BoxShDefaults, form.reset);
                        props.onReset(["boxShadow"]);
                    }}
                />
            }
        >
            <div className="box-shadow">
                <ColorInput<IBoxShadow>
                    onChange={form.handleChange}
                    labelText={"shadow_color"}
                    value={form.editor.color}
                    errors={form.fieldErrors}
                    withValueSync
                    id={"color"}
                />

                <div className="bar-container">
                    <div className="input-bar-container">
                        <label className="label" htmlFor="range">
                            {t<ILang>("offsetX")}
                        </label>
                        <input
                            id="offsetXRange"
                            type="range"
                            value={form.editor.offsetX}
                            onChange={(e) => form.handleChange("offsetX", e.target.value)}
                            className="input-slider"
                            min={0}
                            max={40}
                        />
                    </div>
                    <TextInput
                        isNumeric
                        noLabel
                        id={"offsetX"}
                        errors={form.fieldErrors}
                        value={form.editor.offsetX}
                        onChange={form.handleChange}
                        className="deg-input"
                    />
                </div>

                <div className="bar-container">
                    <div className="input-bar-container">
                        <label className="label" htmlFor="range">
                            {t<ILang>("offsetY")}
                        </label>
                        <input
                            id="offsetYRange"
                            type="range"
                            value={form.editor.offsetY}
                            onChange={(e) => form.handleChange("offsetY", e.target.value)}
                            className="input-slider"
                            min={0}
                            max={40}
                        />
                    </div>
                    <TextInput
                        isNumeric
                        noLabel
                        id={"offsetY"}
                        errors={form.fieldErrors}
                        value={form.editor.offsetY}
                        onChange={form.handleChange}
                        className="deg-input"
                    />
                </div>

                <div className="bar-container">
                    <div className="input-bar-container">
                        <label className="label" htmlFor="range">
                            {t<ILang>("blurRadius")}
                        </label>
                        <input
                            id="blurRadiusRange"
                            type="range"
                            value={form.editor.blurRadius}
                            onChange={(e) => form.handleChange("blurRadius", e.target.value)}
                            className="input-slider"
                            min={0}
                            max={40}
                        />
                    </div>
                    <TextInput
                        isNumeric
                        noLabel
                        id={"blurRadius"}
                        errors={form.fieldErrors}
                        value={form.editor.blurRadius}
                        onChange={form.handleChange}
                        className="deg-input"
                    />
                </div>

                <div className="bar-container">
                    <div className="input-bar-container">
                        <label className="label" htmlFor="range">
                            {t<ILang>("spreadRadius")}
                        </label>
                        <input
                            id="spreadRadiusRange"
                            type="range"
                            value={form.editor.spreadRadius}
                            onChange={(e) => form.handleChange("spreadRadius", e.target.value)}
                            className="input-slider"
                            min={0}
                            max={40}
                        />
                    </div>
                    <TextInput
                        isNumeric
                        noLabel
                        id={"spreadRadius"}
                        errors={form.fieldErrors}
                        value={form.editor.spreadRadius}
                        onChange={form.handleChange}
                        className="deg-input"
                    />
                </div>
            </div>
        </InputGrouper>
    );
};

export default BoxShadowView;
