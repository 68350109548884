import { AppConfigsModel, ISizes } from "sitebuilder-common";
import { useForm } from "../../../UseForm/UseForm.provider";
import { FC, useState } from "react";
import { ProfileIndex } from "../View/Profile.index";
import { clone, cloneDeep } from "lodash";
import { FrameConfigsModel } from "sitebuilder-common";
import { IFrameButtonProps } from "../../../IFrame/View/FrameButton.view";
import { MOCK_USER_MODEL, UserModel } from "../../../../Models/User.model";
import { AddressModel, MOCK_ADDRESSES } from "../../../../Models/Address.model";

export interface IProfileControllerProps {
    size?: ISizes;
    v2Btn?: FC<IFrameButtonProps>;
    styleData?: FrameConfigsModel | AppConfigsModel;
}

export const ProfileController: FC<IProfileControllerProps> = (props) => {
    const userForm = useForm<UserModel, true>({
        editor: clone(MOCK_USER_MODEL),
        hookOnAnyFieldChange: () => setIsUserAltered(true)
    });
    const addressForm = useForm<AddressModel, false>({
        editor: null
    });
    const [isUserAltered, setIsUserAltered] = useState<boolean>(false);
    const [addresses, setAddresses] = useState<AddressModel[]>(cloneDeep(MOCK_ADDRESSES));

    const onSaveUser = (): void => {
        setIsUserAltered(false);
    };

    const onSaveAddress = (e: React.FormEvent<any>): void => {
        e.preventDefault();
        if (!addressForm.editor) return;
        if (addressForm.editor.id) {
            setAddresses((current) => {
                const state = cloneDeep(current);
                for (let index in state) {
                    const adr = state[index];
                    if (adr.id === addressForm.editor!.id) {
                        state[index] = cloneDeep(addressForm.editor!);
                    }
                }
                return state;
            });
        } else {
            setAddresses((current) => {
                const state = cloneDeep(current);
                const item = cloneDeep(addressForm.editor!);
                item.id = `${addresses.length + 1}`;
                state.push(item);
                return state;
            });
        }
        addressForm.setEditor(null);
    };

    const onDeleteAddress = (id: string): void => {
        setAddresses((current) => {
            const state = cloneDeep(current);
            for (let index in state) {
                const address = state[index];
                if (address.id === id) {
                    state.splice(parseInt(index), 1);
                }
            }
            return state;
        });
    };

    return (
        <ProfileIndex
            {...props}
            userForm={userForm}
            addresses={addresses}
            onSaveUser={onSaveUser}
            addressForm={addressForm}
            isUserAltered={isUserAltered}
            onSaveAddress={onSaveAddress}
            onDeleteAddress={onDeleteAddress}
        />
    );
};
