import { axios, IDynamicObject } from "xa-generics";
import { AnalyticsModel } from "sitebuilder-common";
import { RootDAO } from "./Restaurant.dao";

export abstract class AnalyticsDAO extends RootDAO {
    public static async load(): Promise<AnalyticsModel> {
        const request = await axios
            .getInstance()
            .get<IDynamicObject<any>>(`${this.baseUrl()}/analytics/credentials`);
        return new AnalyticsModel(request.data);
    }

    public static async saveAnalytics(data: AnalyticsModel): Promise<void> {
        await axios.getInstance().put<void>(`${this.baseUrl()}/analytics/credentials`, data);
        return;
    }
}
