import { IImageTypeStyles } from "../Interfaces/IImageTypeComponent.interface";
import {
    AlignStyles,
    BackgroundStyles,
    BorderRadiusStyles,
    BorderStyles,
    BoxShadowSyles,
    HeightStyles,
    MarginStyles,
    OpacityStyles,
    PaddingStyles,
    WidthStyles
} from "./GroupsStyles.static";

export const ImageTypeStyles: Required<IImageTypeStyles<true>> = {
    ...PaddingStyles,
    ...MarginStyles,
    ...BackgroundStyles,
    ...BorderStyles,
    ...BorderRadiusStyles,
    ...OpacityStyles,
    ...BoxShadowSyles,
    ...WidthStyles,
    ...HeightStyles,
    ...AlignStyles
};
