import { Context, createContext, useState, useContext } from "react";
import { IWidgetEditor, IWidgetEditorContext } from "../Interfaces/IEditorContext.interface";

/**
 * ## WidgetEditorContext
 */
const WidgetEditorContext: Context<IWidgetEditorContext> = createContext<IWidgetEditorContext>(
    null as any
);

WidgetEditorContext.displayName = "WidgetEditorContext";

interface IWidgetEditorContextProviderProps {}

/**
 * ## Editor context provider component
 *
 */
export const WidgetEditorContextProvider: React.FC<IWidgetEditorContextProviderProps> = (props) => {
    const [widgetEditor, setWidgetEditor] = useState<IWidgetEditor>({
        group: null,
        subKey: ""
    });

    return (
        <WidgetEditorContext.Provider
            value={{
                setWidgetEditor,
                widgetEditor
            }}
        >
            {props.children}
        </WidgetEditorContext.Provider>
    );
};

export const useWidgetEditor = (): IWidgetEditorContext => useContext(WidgetEditorContext);
