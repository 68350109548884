import { FC } from "react";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { useImages } from "../../../../Contexts/Images.context";
import { useWidgets } from "../../../Widgets/Controller/WidgetsContext.provider";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { INavbarSettings } from "../Interfaces/INavbarWidget.interface";
import { GetWidgetOverlay } from "../../Util/WidgetOverlay.util";
import { NavbarNameStyles } from "../Static/NavbarName.static";
import { IGenericCSSFields, ParseComponentStyle } from "sitebuilder-common";
import WidgetOverlay from "../../../Widgets/View/WidgetOverlay.view";

interface INavbarNameAndLogoProps {
    v3LogoStyles?: Partial<IGenericCSSFields<any>>;
}

export const NavbarNameAndLogo: FC<INavbarNameAndLogoProps> = (props) => {
    const { widgets } = useWidgets();
    const { size } = useDom();
    const { images } = useImages();
    const { restaurant } = useRestaurant();
    const { navbarName, navbarLogo, isNameHidden } = widgets.Navbar.draftSettings;
    const { showOverlay, ...events } = GetWidgetOverlay<INavbarSettings>("Navbar", "navbarName");

    const nameStyles = ParseComponentStyle(navbarName, NavbarNameStyles, size);

    return (
        <div className="navbar__bottom__logo" {...events}>
            {showOverlay && (
                <WidgetOverlay<INavbarSettings>
                    align={"right"}
                    parent={widgets.Navbar}
                    subKey={"navbarName"}
                />
            )}

            {navbarLogo && images[navbarLogo]?.url && (
                <div className="navbar__bottom__logo--image-container" style={props.v3LogoStyles}>
                    <img
                        className={"navbar__bottom__logo--image-container--image"}
                        src={images[navbarLogo].url}
                        alt={restaurant.name}
                    />
                </div>
            )}

            {!isNameHidden && (
                <h1 className="navbar__bottom__logo--name" style={nameStyles}>
                    {restaurant.name}
                </h1>
            )}
        </div>
    );
};
