import { ExtraRegexes } from "./ExtraRegex.util";

export const createEmbedUrl = (url: string, isAutoplay: boolean): string => {
    const match: string[] | null = url.match(ExtraRegexes.YouTube);

    const ytVideoIdLength = 11;
    const newUrl =
        match && match[2].length === ytVideoIdLength
            ? `https://www.youtube.com/embed/${match[2]}`
            : "";
    if (!newUrl) return "";

    if (isAutoplay) return `${newUrl}?autoplay=1`;
    else return `${newUrl}?autoplay=0&loading=lazy`;
};
