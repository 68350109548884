import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useTranslation } from "react-i18next";
import { IAppProductPageViewProps } from "./AppProductPage.view";

interface IAppOptionsViewProps extends IAppProductPageViewProps {}

export const AppOptionsView: FC<IAppOptionsViewProps> = (props) => {
    const { t } = useTranslation<ILang>();

    const classes = ["app-options"];

    return (
        <div className={classes.join(" ")}>
            <div className={"grid-row-placeholder"}>{t<ILang>("option_warn_desc")}</div>
        </div>
    );
};
