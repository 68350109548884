import { FC } from "react";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { useCart } from "../../Cart/Provider/Cart.provider";
import { useWidgets } from "../../../Widgets/Controller/WidgetsContext.provider";
import { ClientButton } from "../../../DomMapper/DomComponents/Button/ClientButton.view";
import { INavbarSettings } from "../Interfaces/INavbarWidget.interface";
import { INavbarVersions } from "../Interfaces/INavbar.type";
import { GetWidgetOverlay } from "../../Util/WidgetOverlay.util";
import { getScaledIconSize } from "../Utils/ScaledIconSize.util";
import { NavbarButtonStyles } from "../Static/NavbarButtonStyles.static";
import { ParseComponentStyle } from "sitebuilder-common";
import {
    GetCurrency,
    RenderPrice
} from "../../../DomMapper/DomComponents/Products/Utils/GetCurrency.util";
import { ArrowRight, ShoppingCart } from "@carbon/icons-react";
import WidgetOverlay from "../../../Widgets/View/WidgetOverlay.view";

interface INavbarCartProps {
    navVersion?: INavbarVersions;
}

export const NavbarCart: FC<INavbarCartProps> = (props) => {
    const { widgets } = useWidgets();
    const { size } = useDom();
    const { order } = useCart();
    const currency = GetCurrency();
    const { navbarCart, hideCartButtons } = widgets.Navbar.draftSettings;
    const buttonStyles = ParseComponentStyle(navbarCart, NavbarButtonStyles, size);

    if (hideCartButtons) return null;
    const { showOverlay, ...events } = GetWidgetOverlay<INavbarSettings>("Navbar", "navbarCart");
    let iconSize = getScaledIconSize(buttonStyles);

    const navVersion = props.navVersion ? props.navVersion : "default";

    const rightIcon =
        navVersion === "default" ? (
            <ArrowRight size={iconSize} />
        ) : size === "xs" || size === "sm" ? undefined : (
            <ArrowRight size={iconSize} />
        );

    const text =
        navVersion === "default"
            ? RenderPrice(currency, order.total_price)
            : size === "xs" || size === "sm"
            ? undefined
            : RenderPrice(currency, order.total_price);
    return (
        <div className="navbar__bottom__checkout">
            <ClientButton
                {...events}
                style={buttonStyles}
                RightIcon={rightIcon}
                LeftIcon={<ShoppingCart size={iconSize} />}
            >
                {showOverlay && (
                    <WidgetOverlay<INavbarSettings>
                        align={"right"}
                        parent={widgets.Navbar}
                        subKey={"navbarCart"}
                    />
                )}
                {text}
            </ClientButton>
        </div>
    );
};
