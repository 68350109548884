import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { IPartialCSS } from "sitebuilder-common";
import { useTranslation } from "react-i18next";
import { CheckmarkFilled, RadioButton } from "@carbon/icons-react";

interface IThankYouStatusElementProps {
    style: IPartialCSS<any>;
    isCompleted?: boolean;
    text: ILang;
}

export const ThankYouStatusElement: FC<IThankYouStatusElementProps> = (props) => {
    const { t } = useTranslation<ILang>();

    const style = props.isCompleted ? props.style : undefined;
    const classes: string[] = ["tracking-status"];
    if (props.isCompleted) classes.push("completed-status");

    return (
        <div className={classes.join(" ")}>
            {props.isCompleted ? (
                <CheckmarkFilled size={20} style={style} className={"tracking-status__icon"} />
            ) : (
                <RadioButton size={20} style={style} className={"tracking-status__icon"} />
            )}
            <div className="tracking-status__text">{t<ILang>(props.text)}</div>
        </div>
    );
};
