import { FC } from "react";
import { useIFrame } from "../../Provider/IFrame.context";
import { FrameEditorGroup } from "../IFrameEditorGroup.view";
import ColorInput from "../../../UI/InputFields/ColorInput/ColorInput.view";

interface IFrameProductsFormProps {}

export const FrameProductsForm: FC<IFrameProductsFormProps> = (props) => {
    const { forms } = useIFrame<"products">();
    const { editor, fieldErrors, handleChange } = forms.products;

    return (
        <FrameEditorGroup groupKey={"products"} nameKey={"iframe_products"}>
            <ColorInput
                onChange={handleChange}
                id={"backgroundColor"}
                value={editor.backgroundColor}
                errors={fieldErrors}
                withValueSync
                isClearable
            />
            <ColorInput
                onChange={handleChange}
                id={"color"}
                value={editor.color}
                errors={fieldErrors}
                withValueSync
                isClearable
            />
        </FrameEditorGroup>
    );
};
