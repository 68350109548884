import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useForm } from "../../../UseForm/UseForm.provider";
import { TextInput } from "../../InputFields/TextInput/TextInput.view";
import { SendToBack } from "@carbon/icons-react";
import { IInputGroup } from "../../../../Interfaces/IInputGroup.interface";
import { ResetValues } from "../../../Editor/Utils/ResetValues.util";
import { Destructure } from "../../../../Utils/Destructure.util";
import { IFormWithUnit } from "../../../../Interfaces/IFormWithUnit.type";
import { TextShDefaults } from "./Defaults.static";
import { useTranslation } from "react-i18next";
import { CheckIfHasValue } from "../../../../Utils/CheckIfHasValue.util";
import { ParseTextShadow } from "../../../../Utils/ParseTextShadow.util";
import { useAfterTriggerChanged } from "xa-generics";
import { ITextShadow, ITextShadowGroup } from "./ITextShadow.interface";
import SizeUnitControlView from "../../SizeUnitControl/SizeUnitControl.view";
import ResetFormView from "../../InputGrouper/ResetForm.view";
import InputGrouper from "../../InputGrouper/InputGrouper.view";
import ColorInput from "../../InputFields/ColorInput/ColorInput.view";

export interface ITextShadowViewProps extends IInputGroup<ITextShadowGroup<any>> {}

const TextShadowView: FC<ITextShadowViewProps> = (props) => {
    const { t } = useTranslation<ILang>();

    const form = useForm<IFormWithUnit<ITextShadow>, true>({
        editor: Destructure(
            TextShDefaults,
            ParseTextShadow(props.defaultValues.textShadow),
            props.units.textShadow
        )
    });

    const toTextShadow = (state: ITextShadow): string => {
        return `${state.x}px ${state.y}px ${state.textBlur}px ${state.textColor}`;
    };

    useAfterTriggerChanged(() => {
        form.handleSubmit((state) => {
            const textShadow = toTextShadow(state);
            props.onBlur({ field: "textShadow", value: textShadow });
        })();
    }, [form.editor]);

    return (
        <InputGrouper
            groupKey={"TextShadowGroup"}
            Icon={SendToBack}
            labelKey={"textShadow"}
            Controls={
                <SizeUnitControlView
                    unit={form.editor.unit}
                    setUnit={form.handleChange}
                    disableRem
                    disablePercentage
                />
            }
            Reset={
                <ResetFormView
                    hasValue={CheckIfHasValue(form, TextShDefaults)}
                    onReset={(e) => {
                        ResetValues(e, TextShDefaults, form.reset);
                        props.onReset(["textShadow"]);
                    }}
                />
            }
        >
            <div className="box-shadow">
                <ColorInput
                    value={form.editor.textColor}
                    onChange={form.handleChange}
                    labelText={"shadow_color"}
                    errors={form.fieldErrors}
                    id={"textColor"}
                />

                <div className="bar-container">
                    <div className="input-bar-container">
                        <label className="label" htmlFor="range">
                            {t<ILang>("offsetX")}
                        </label>
                        <input
                            id="x-range"
                            type="range"
                            onChange={(e) => form.handleChange("x", e.target.value)}
                            className="input-slider"
                            value={form.editor.x}
                            min={0}
                            max={40}
                        />
                    </div>
                    <TextInput
                        isNumeric
                        noLabel
                        id={"x"}
                        value={form.editor.x}
                        errors={form.fieldErrors}
                        onChange={form.handleChange}
                        className="deg-input"
                    />
                </div>

                <div className="bar-container">
                    <div className="input-bar-container">
                        <label className="label" htmlFor="range">
                            {t<ILang>("offsetY")}
                        </label>
                        <input
                            id="y-range"
                            type="range"
                            value={form.editor.x}
                            onChange={(e) => form.handleChange("y", e.target.value)}
                            className="input-slider"
                            min={0}
                            max={15}
                        />
                    </div>
                    <TextInput
                        noLabel
                        id={"y"}
                        isNumeric
                        value={form.editor.y}
                        className={"deg-input"}
                        errors={form.fieldErrors}
                        onChange={form.handleChange}
                    />
                </div>

                <div className="bar-container">
                    <div className="input-bar-container">
                        <label className="label" htmlFor="range">
                            {t<ILang>("blurRadius")}
                        </label>
                        <input
                            id="blurRadius-range"
                            type="range"
                            value={form.editor.textBlur}
                            onChange={(e) => form.handleChange("textBlur", e.target.value)}
                            className="input-slider"
                            min={0}
                            max={5}
                        />
                    </div>
                    <TextInput
                        isNumeric
                        noLabel
                        id={"textBlur"}
                        errors={form.fieldErrors}
                        value={form.editor.textBlur}
                        onChange={form.handleChange}
                        className="deg-input"
                    />
                </div>
            </div>
        </InputGrouper>
    );
};

export default TextShadowView;
