import { useApp } from "../../../App/Provider/App.context";
import { IPartialCSS } from "sitebuilder-common";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { DEF_BG, DEF_COLOR } from "../../../../Static/IFrameBaseColors.static";
import { CaretDown, CaretUp } from "@carbon/icons-react";
import { IWC, useAfterTriggerChanged, useMountWithTriggers } from "xa-generics";

export interface IRawSelectInputProps<Fields extends object, Option extends object, Lang = string>
    extends IWC {
    value: string;
    id: keyof Fields;
    labelText?: Lang;
    noLabel?: boolean;
    className?: string;
    options: Option[];
    required?: boolean;
    isDisabled?: boolean;
    idAccessor?: keyof Option;
    nameAccessor?: keyof Option;
    useTranslationOnName?: boolean;
    description?: string | JSX.Element;
    onChange: (id: keyof Fields, value: string, option: Option | null) => void;
}

export const RawSelectInput = <Fields extends object, Option extends object, Lang = string>(
    props: IRawSelectInputProps<Fields, Option, Lang>
) => {
    const { t } = useTranslation();
    const { data, dataSource } = useApp();
    const app = data[dataSource];

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const id = props.id as string;
    const wrapperStyle: string[] = ["wrapper", "select", "raw-select-input"];
    const uniqueKey = `${id}-menu`;
    const idAccessor = props.idAccessor || ("id" as keyof Option);
    const nameAccessor = props.nameAccessor || ("name" as keyof Option);

    const selectedOption = useMemo(() => {
        return props.options.find((opt) => (opt[idAccessor] as never) === props.value) || null;
    }, [props.value, props.options, idAccessor]);

    useAfterTriggerChanged(() => {
        setIsOpen(false);
    }, [props.value]);

    const handleClick = (e: MouseEvent): void => {
        const path = e.composedPath();
        for (let target of path) {
            const id: string | undefined = (target as HTMLElement)?.id;
            if (id === uniqueKey) return;
        }
        setIsOpen(false);
    };

    useMountWithTriggers(() => {
        window.addEventListener("mouseup", handleClick, { passive: true });
        return () => window.removeEventListener("mouseup", handleClick);
    }, [isOpen, props.value]);

    if (props.className) wrapperStyle.push(props.className);
    if (props.isDisabled) wrapperStyle.push("select-disabled");
    if (app?.global.isRounded) {
        wrapperStyle.push("global__rounded-corners");
    }

    const selectedStyle: IPartialCSS<any> = {
        backgroundColor: app.buttons.backgroundColor || DEF_BG,
        color: app.buttons.color || DEF_COLOR
    };

    return (
        <div className={wrapperStyle.join(" ")}>
            {!props.noLabel && (
                <label htmlFor={id} className={"input-label"}>
                    {t((props.labelText as never) || id)}
                    {props.required ? "*" : null}
                </label>
            )}
            <div
                className={"select-input"}
                id={uniqueKey}
                onMouseUp={() => {
                    if (props.isDisabled) return;
                    setIsOpen(true);
                }}
            >
                <div className="select-input__search" style={selectedStyle}>
                    {selectedOption?.[nameAccessor] || "..."}
                </div>

                <div className="select-input__controls" style={{ color: selectedStyle.color }}>
                    <span className="select-input__controls--separator">|</span>
                    {isOpen ? (
                        <CaretUp
                            size={24}
                            className={"multi-select-input__controls--icon"}
                            onMouseUp={(e) => {
                                e.stopPropagation();
                                setIsOpen(false);
                            }}
                        />
                    ) : (
                        <CaretDown
                            size={24}
                            className={"multi-select-input__controls--icon"}
                            onMouseUp={(e) => {
                                e.stopPropagation();
                                if (props.isDisabled) return;
                                setIsOpen(true);
                            }}
                        />
                    )}
                </div>

                {isOpen && (
                    <div className="select-input__list">
                        <div className="select-input__list--scrollable">
                            {props.options.map((option, index) => {
                                const classes: string[] = ["select-input__list--item"];
                                const value = option[idAccessor] as never;
                                const isSelected = value === props.value;

                                if (value === props.value) classes.push("--selected");

                                return (
                                    <div
                                        className={classes.join(" ")}
                                        key={`${index}-${id}-${option[idAccessor]}`}
                                        style={isSelected ? selectedStyle : undefined}
                                        onMouseUp={() => {
                                            if (props.isDisabled) return;
                                            props.onChange(props.id, value, option);
                                            setIsOpen(false);
                                        }}
                                    >
                                        {props.useTranslationOnName
                                            ? t<any, any>(option[nameAccessor])
                                            : option[nameAccessor]}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
