import { ISectionStyles } from "../Interfaces/ISectionComponent.interface";
import {
    BackgroundImageStyles,
    BackgroundStyles,
    DisplayStyles,
    PaddingStyles
} from "./GroupsStyles.static";

export const SectionStyles: Required<ISectionStyles<true>> = {
    ...DisplayStyles,
    ...PaddingStyles,
    ...BackgroundStyles,
    ...BackgroundImageStyles
};
