import { LetterSwaps, ArrayLetterSwaps } from "xa-generics";

export const PathSlug = (str: string): string => {
    let text = str.startsWith("/") ? str : `/${str}`;
    text = text.toLocaleLowerCase();
    text = text
        .replace(/(!|\s|,|\.|:|@)+/g, "-")
        .replace(/-+/g, "-")
        .replace(/\/+/g, "/")
        .replace(/^-+/, "");

    const SwapLength: number = ArrayLetterSwaps.length;
    for (let i = 0; i < SwapLength; i++) {
        const swap = ArrayLetterSwaps[i];
        const charCount: number = LetterSwaps[swap].length;
        for (let j = 0; j < charCount; j++) {
            const char = LetterSwaps[swap][j];
            text = text.replace(RegExp(char, "g"), swap);
        }
    }
    return text.replace(/[^a-z0-9 -_]/g, ""); // remove invalid chars
};
