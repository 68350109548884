import { ITextShadowGroup } from "../../UI/InputGroups/TextShadow/ITextShadow.interface";
import { IBackgroundImage } from "../../UI/InputGroups/BackgroundImage/IBackgroundImage.interface";
import { IBoxShadowGroup } from "../../UI/InputGroups/BoxShadow/IBoxShadow.interface";
import { IBorderRadius } from "../../UI/InputGroups/BorderRadius/IBorderRadius.interface";
import { IColor, IFont } from "../../UI/InputGroups/FontInputs/IFont.interface";
import { IAlignPicker } from "../../UI/InputFields/AlignPicker/IAlignPicker.interface";
import { IBackground } from "../../UI/InputGroups/Background/IBackground.interface";
import { IPaddings } from "../../UI/InputGroups/Paddings/IPaddings.interface";
import { IOpacity } from "../../UI/InputGroups/Opacity/IOpacity.interface";
import { IDisplay } from "../../UI/InputGroups/Display/IDisplay.interface";
import { IMargins } from "../../UI/InputGroups/Margins/IMargins.interface";
import { IBorder } from "../../UI/InputGroups/Border/IBorder.interface";
import { IHeight } from "../../UI/InputGroups/Height/IHeight.interface";
import { IWidth } from "../../UI/InputGroups/Width/IWidth.interface";

export const PaddingStyles: IPaddings<true> = {
    paddingTop: true,
    paddingLeft: true,
    paddingRight: true,
    paddingBottom: true
};

export const MarginStyles: IMargins<true> = {
    marginTop: true,
    marginLeft: true,
    marginRight: true,
    marginBottom: true
};

export const FontStyles: IFont<true> = {
    textDecoration: true,
    letterSpacing: true,
    fontStyle: true,
    lineHeight: true,
    fontFamily: true,
    fontWeight: true,
    fontSize: true,
    textTransform: true
};

export const BackgroundStyles: IBackground<true> = {
    background: true,
    backgroundColor: true
};

export const BackgroundImageStyles: IBackgroundImage<true> = {
    backgroundSize: true,
    backgroundImage: true,
    backgroundRepeat: true,
    backgroundPosition: true,
    backgroundAttachment: true
};

export const BorderRadiusStyles: IBorderRadius<true> = {
    borderTopLeftRadius: true,
    borderTopRightRadius: true,
    borderBottomRightRadius: true,
    borderBottomLeftRadius: true
};

export const BorderStyles: IBorder<true> = {
    borderColor: true,
    borderStyle: true,
    borderTopWidth: true,
    borderLeftWidth: true,
    borderBottomWidth: true,
    borderRightWidth: true
};

export const OpacityStyles: IOpacity<true> = {
    opacity: true
};

export const BoxShadowSyles: IBoxShadowGroup<true> = {
    boxShadow: true
};

export const TextShadowSyles: ITextShadowGroup<true> = {
    textShadow: true
};

export const HeightStyles: IHeight<true> = {
    minHeight: true,
    height: true,
    maxHeight: true
};

export const WidthStyles: IWidth<true> = {
    minWidth: true,
    width: true,
    maxWidth: true
};

export const AlignStyles: IAlignPicker<true> = {
    textAlign: true
};

export const ColorStyles: IColor<true> = {
    color: true
};

export const DisplayStyles: IDisplay<true> = {
    display: true,
    alignItems: true,
    justifyContent: true,
    flexDirection: true,
    flexWrap: true,
    alignSelf: true
};
