import { Component, ErrorInfo } from "react";

interface IState {
    hasError: boolean;
}

export class ErrorBoundary extends Component<{}, IState> {
    constructor(props: {}) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error): IState {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can log the error to an error reporting service
        console.error("Uncaught error:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <h1>ERROR RENDERING</h1>;
        }

        return this.props.children;
    }
}
