import { ModelConstructor, IDynamicObject } from "xa-generics";
import { DomModel } from "./Dom.model";

export class DraftModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as DraftMData;
        ModelConstructor(data, this);

        this.draft_elements = new DomModel(data.draft_elements);
    }

    page_id: string = "";
    restaurant_id: string = "";
    draft_id: string = "";
    created_at: string = "";
    updated_at: string = "";
    draft_elements!: DomModel;
}

//If you add public methods, you may want to replace the empty string with them!
type DraftMData = Partial<InstanceType<typeof DraftModel>>;
