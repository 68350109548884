import { Width } from "../../UI/InputGroups/Width/Width.view";
import { Border } from "../../UI/InputGroups/Border/Border.view";
import { Height } from "../../UI/InputGroups/Height/Height.view";
import { Margins } from "../../UI/InputGroups/Margins/Margins.view";
import { Paddings } from "../../UI/InputGroups/Paddings/Paddings.view";
import { EditorHook } from "../Utils/EditorHook.util";
import { IDomElement } from "../../DomTracker/Interfaces/IDomTypes.interface";
import { BorderRadius } from "../../UI/InputGroups/BorderRadius/BorderRadius.view";
import { OpacityInput } from "../../UI/InputGroups/Opacity/Opacity.view";
import { ITextTypeEditors } from "../../DomMapper/Interfaces/ITextTypeComponents.interface";
import { InputGroupContextProvider } from "../../UI/InputGrouper/InputGroup.provider";
import BackgroundGroupView from "../../UI/InputGroups/Background/BackgroundGroup.view";
import FormSeparatorView from "../../UI/FormSeparator/FormSeparator.view";
import AlignPickerView from "../../UI/InputFields/AlignPicker/AlignPicker.view";
import TextShadowView from "../../UI/InputGroups/TextShadow/TextShadow.view";
import BoxShadowView from "../../UI/InputGroups/BoxShadow/BoxShadow.view";
import ComponentText from "../../UI/InputGroups/ComponentText/ComponentText.view";
import FontColor from "../../UI/InputGroups/FontInputs/FontColor.input";
import Font from "../../UI/InputGroups/FontInputs/Font.view";

export interface ITextEditorViewProps {
    editor: IDomElement;
}

const TextEditorView: React.FC<ITextEditorViewProps> = (props) => {
    const { onBlur, onClear, units, defaults } = EditorHook<ITextTypeEditors>();

    return (
        <InputGroupContextProvider defaultActive={"AlignPicker"}>
            <FormSeparatorView
                defKey={"Text"}
                defaultGroup={
                    <>
                        <ComponentText
                            defaultValues={defaults}
                            defaultText={"Text"}
                            type={"TEXT_AREA"}
                            onBlur={onBlur}
                        />
                        <AlignPickerView
                            id={"textAlign"}
                            onBlur={onBlur}
                            defaultValue={defaults.textAlign}
                        />
                        <FontColor
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Font
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <BackgroundGroupView
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Border
                            defaultValues={defaults}
                            units={units}
                            onReset={onClear}
                            onBlur={onBlur}
                        />
                    </>
                }
                advancedGroup={
                    <>
                        <Paddings
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <TextShadowView
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Margins
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <BorderRadius
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <OpacityInput onReset={onClear} onBlur={onBlur} defaultValues={defaults} />
                        <Height
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                            defaultValues={defaults}
                        />
                        <Width
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <BoxShadowView
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                    </>
                }
            />
        </InputGroupContextProvider>
    );
};

export default TextEditorView;
