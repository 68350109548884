import { FC } from "react";
import { RenderPrice } from "../Utils/GetCurrency.util";
import { ProductModel } from "../Models/Product.model";
import { IProductsIndexProps } from "./Products.index";
import { ProductCategoryModel } from "../Models/ProductCategory.model";

interface ICategorizedProductElementProps {
    setShowOptionWarn: IProductsIndexProps["setShowOptionWarn"];
    category: ProductCategoryModel;
    trimDescription: number;
    useTrimOnCategorized: boolean;
    product: ProductModel;
    currency: string;
}

export const CategorizedProductElement: FC<ICategorizedProductElementProps> = (props) => {
    const { category, product, currency } = props;

    const classes: string[] = ["product"];

    if (!props.useTrimOnCategorized) classes.push("--trim-description");
    if (!product.image?.present) {
        classes.push("product-without-image");
    }

    return (
        <div
            className={classes.join(" ")}
            key={`${product.id}-product-of-${category.id}`}
            onClick={() => props.setShowOptionWarn(true)}
        >
            <h4 title={product.name} className="product__name">
                {product.name}
            </h4>
            <div title={product.description} className="product__description">
                {props.useTrimOnCategorized ? (
                    <>{product.description.slice(0, props.trimDescription)}...</>
                ) : (
                    product.description || "..."
                )}
            </div>
            <div className="product__price">{RenderPrice(currency, product.base_price)}</div>
            <div
                className="product__image"
                style={{
                    backgroundImage: `url(${product.image.medium})`
                }}
            />
        </div>
    );
};
