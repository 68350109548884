import { useApp } from "../../Provider/App.context";
import { useProducts } from "../../../DomMapper/DomComponents/Products/Context/ProductsContext.provider";
import { FC, Fragment } from "react";
import { IAppOrderIndexProps } from "./AppOrder.index";
import { AppV2ProductElement } from "./AppV2Product.element";
import { ProductCategoryModel } from "../../../DomMapper/DomComponents/Products/Models/ProductCategory.model";

interface IAppProductsProps extends IAppOrderIndexProps {
    currency: string;
    containerRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const AppV2Products: FC<IAppProductsProps> = (props) => {
    const { categories } = useProducts();
    const { data, dataSource } = useApp();

    if (props.selector.product) return null;

    const app = data[dataSource];
    const p = app.product;
    const style: React.CSSProperties = {
        backgroundColor: p.backgroundColor,
        color: p.color
    };

    const renderCategory = (
        cat: ProductCategoryModel,
        allowChild: boolean = false
    ): JSX.Element | null => {
        if (!allowChild && cat.parent_id) return null;

        return (
            <CategoryComponent
                cat={cat}
                {...props}
                key={`${cat.id}-category-v2`}
                renderCategory={renderCategory}
            />
        );
    };

    return (
        <div
            style={style}
            id={"APP_V2_CATEGORY_CONTAINER"}
            ref={(r) => (props.containerRef.current = r)}
            className={"app-products-container app-v2-products-container"}
        >
            {categories.map((cat) => renderCategory(cat))}
        </div>
    );
};

const CategoryComponent = (
    props: IAppProductsProps & {
        cat: ProductCategoryModel;
        renderCategory: (cat: ProductCategoryModel, allowChild: boolean) => JSX.Element | null;
    }
) => {
    const { catObject } = useProducts();
    const cat = props.cat;
    const renderCategory = props.renderCategory;

    let name = cat.name;
    if (cat.parent_id && catObject[cat.parent_id]) {
        name = `${catObject[cat.parent_id].name} / ${name}`;
    }

    return cat.has_child ? (
        <div className={"v2-product-category"} id={cat.id}>
            {cat.children.map((childCategory) => renderCategory(childCategory, true))}
        </div>
    ) : (
        <div id={cat.id} className={"app-end-category-container"}>
            <div className="app-products-container__category v2-category-title">{name}</div>
            {cat.products.map((product) => (
                <Fragment key={`${product.id}-app-v2-product-element`}>
                    <AppV2ProductElement product={product} {...props} />
                    <hr className="product-line" />
                </Fragment>
            ))}
        </div>
    );
};
