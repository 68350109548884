import { useDom } from "../../DomTracker/Controller/DomTracker.provider";
import { Display } from "../../UI/InputGroups/Display/Display.view";
import { useForm } from "../../UseForm/UseForm.provider";
import { ISection } from "../../DomTracker/Interfaces/IDomTypes.interface";
import { Paddings } from "../../UI/InputGroups/Paddings/Paddings.view";
import { EditorHook } from "../Utils/EditorHook.util";
import {
    ISectionCustoms,
    ISectionEditor
} from "../../DomMapper/Interfaces/ISectionComponent.interface";
import { InputGroupContextProvider } from "../../UI/InputGrouper/InputGroup.provider";
import BackgroundImageGroup from "../../UI/InputGroups/BackgroundImage/BackgroundImageGroup.view";
import BackgroundGroupView from "../../UI/InputGroups/Background/BackgroundGroup.view";
import FormSeparatorView from "../../UI/FormSeparator/FormSeparator.view";
import ColumnPicker from "../../UI/InputFields/ColumnPicker/ColumnPicker.view";
import { CheckboxInput } from "../../UI/InputFields/Checkbox/Checkbox.view";

export interface ISectionEditorViewProps {
    editor: ISection;
}

const SectionEditorView: React.FC<ISectionEditorViewProps> = (props) => {
    const { onBlur, onClear, defaults, units } = EditorHook<ISectionEditor>();
    const { generateColumns, currentPage } = useDom();
    const form = useForm<Omit<ISectionCustoms, "childOf">, true>({
        editor: {
            columnCount: defaults.columnCount,
            isInactive: defaults.isInactive || false
        }
    });

    return (
        <InputGroupContextProvider defaultActive={"Column"}>
            <FormSeparatorView
                defKey={"Section"}
                defaultGroup={
                    <>
                        {!props.editor.isSpecial && (
                            <ColumnPicker
                                value={form.editor.columnCount}
                                setValue={(count) => {
                                    form.handleChange("columnCount", count);
                                    generateColumns(count, currentPage.page_id, props.editor.uuid);
                                }}
                            />
                        )}
                        <Paddings
                            defaultValues={defaults}
                            onBlur={onBlur}
                            onReset={onClear}
                            units={units}
                        />

                        <BackgroundGroupView
                            defaultValues={defaults}
                            onBlur={onBlur}
                            onReset={onClear}
                            units={units}
                        />

                        <BackgroundImageGroup
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />

                        <CheckboxInput
                            onBlur={onBlur}
                            id={"isInactive"}
                            onChange={form.handleChange}
                            value={form.editor.isInactive}
                        />
                    </>
                }
                advancedGroup={
                    <>
                        <Display
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                    </>
                }
            />
        </InputGroupContextProvider>
    );
};

export default SectionEditorView;
