import { useState } from "react";
import { useEditor } from "../../Editor/Controller/EditorContext.provider";
import { Templates } from "../../Templates/Controller/Templates.controller";
import { AddMenuClass } from "../../../Utils/AddMenuClass.util";
import { useWidgetEditor } from "../../Editor/Controller/WidgetEditorContext.provider";
import { ConfigController } from "../../Config/Controller/Config.controller";
import { ArrowLeft, ArrowRight } from "@carbon/icons-react";
import { useAfterTriggerChanged } from "xa-generics";
import ComponentCreatorView from "../../ComponentDnD/View/ComponentCreator.view";
import ShowEditorByTypeView from "../../Editor/View/ShowEditorByType.index";
import SidemenuHeaderView from "./SidemenuHeader.view";
import SidemenuFooter from "./SidemenuFooter";
import HistoryView from "../../ComponentHistory/View/History.view";

export interface ISideMenuViewProps {}

const SideMenuView: React.FC<ISideMenuViewProps> = (props) => {
    const classes: string[] = ["sidemenu"];
    const sideClasses: string[] = ["sidemenu__side-control"];
    const { isOpen, setisOpen } = AddMenuClass(sideClasses, "absolute");
    const { editor, pageEditor, setEditor, setPageEditor } = useEditor();
    const { widgetEditor, setWidgetEditor } = useWidgetEditor();

    if (isOpen) classes.push("sidemenu-opened");
    const [showCMPS, setShowCMPS] = useState<boolean>(false);
    const [showConfig, setShowConfig] = useState<boolean>(false);
    const [showHistory, setShowHistory] = useState<boolean>(false);
    const [showTemplates, setShowTemplates] = useState<boolean>(false);

    if (showConfig || showTemplates || widgetEditor.group) {
        classes.push("sidemenu-editor-full-height");
    }

    //These hooks will unset the other context values to prevent multiple
    //components from appearing in the sidemenu.
    useAfterTriggerChanged(() => {
        if (editor) {
            setShowCMPS(false);
            setShowHistory(false);
            setWidgetEditor({ group: null, subKey: "" });
            setPageEditor(null);
            setShowConfig(false);
            setShowTemplates(false);
        }
    }, [editor]);

    useAfterTriggerChanged(() => {
        if (showCMPS) {
            setEditor(null);
            setWidgetEditor({ group: null, subKey: "" });
            setShowHistory(false);
            setPageEditor(null);
            setShowConfig(false);
            setShowTemplates(false);
        }
    }, [showCMPS]);

    useAfterTriggerChanged(() => {
        if (showHistory) {
            setEditor(null);
            setWidgetEditor({ group: null, subKey: "" });
            setShowCMPS(false);
            setPageEditor(null);
            setShowConfig(false);
            setShowTemplates(false);
        }
    }, [showHistory]);

    useAfterTriggerChanged(() => {
        if (widgetEditor.group) {
            setShowCMPS(false);
            setShowHistory(false);
            setEditor(null);
            setPageEditor(null);
            setShowConfig(false);
            setShowTemplates(false);
        }
    }, [widgetEditor]);

    useAfterTriggerChanged(() => {
        if (pageEditor) {
            setEditor(null);
            setWidgetEditor({ group: null, subKey: "" });
            setShowCMPS(false);
            setShowHistory(false);
            setShowConfig(false);
            setShowTemplates(false);
        }
    }, [pageEditor]);

    useAfterTriggerChanged(() => {
        if (showConfig) {
            setEditor(null);
            setWidgetEditor({ group: null, subKey: "" });
            setShowCMPS(false);
            setShowHistory(false);
            setPageEditor(null);
            setShowTemplates(false);
        }
    }, [showConfig]);

    useAfterTriggerChanged(() => {
        if (showTemplates) {
            setEditor(null);
            setWidgetEditor({ group: null, subKey: "" });
            setShowCMPS(false);
            setShowHistory(false);
            setPageEditor(null);
            setShowConfig(false);
        }
    }, [showTemplates]);

    return (
        <>
            <div className={classes.join(" ")}>
                <SidemenuHeaderView
                    showCMPS={showCMPS}
                    showConfig={showConfig}
                    setShowCMPS={setShowCMPS}
                    showHistory={showHistory}
                    setShowConfig={setShowConfig}
                    showTemplates={showTemplates}
                    setShowHistory={setShowHistory}
                    setShowTemplates={setShowTemplates}
                />
                <div className="editor">
                    {showCMPS && (
                        <div className="editor__content">
                            <ComponentCreatorView />
                        </div>
                    )}
                    {showHistory && <HistoryView setShowHistory={setShowHistory} />}

                    <ShowEditorByTypeView
                        showCMPS={showCMPS}
                        showConfig={showConfig}
                        showHistory={showHistory}
                        setShowCMPS={setShowCMPS}
                        showTemplates={showTemplates}
                    />

                    {showConfig && <ConfigController />}
                    {showTemplates && <Templates />}
                    <SidemenuFooter
                        showHistory={showHistory}
                        setShowHistory={setShowHistory}
                        setShowCMPS={setShowCMPS}
                        showCMPS={showCMPS}
                    />
                </div>
            </div>
            <div className={sideClasses.join(" ")}>
                <div
                    className="svg sidemenu__side-control--icon-container"
                    onClick={() => setisOpen(!isOpen)}
                >
                    {isOpen ? <ArrowLeft size={20} /> : <ArrowRight size={20} />}
                </div>
            </div>
            {props.children}
        </>
    );
};

export default SideMenuView;
