import { Dispatch, SetStateAction, FC } from "react";
import { IWC } from "xa-generics";

export interface ILongSelectControlProps extends IWC {
    isVisible: boolean;
    optionPage: number;
    value: number;
    setOptionPage: Dispatch<SetStateAction<number>>;
}

const LongSelectControl: FC<ILongSelectControlProps> = (props) => {
    if (!props.isVisible) return null;

    return (
        <div
            className="long-select__options--control"
            onMouseUp={() => props.setOptionPage(props.optionPage + props.value)}
        >
            {props.children}
        </div>
    );
};

export default LongSelectControl;
