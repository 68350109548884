import { Slugify } from "xa-generics";

export class AbstractProductCommonModel {
    id: string = "";
    name: string = "";
    created_at: string = "";
    updated_at: string = "";
    archived_at: string = "";
    active: boolean = true;
    name_sec_lang: string = "";
    ranking: number = 0;

    //Optional (description doesn't exist on subproducts, options, option groups...)
    description: string = "";
    description_sec_lang: string = "";

    //Custom prop
    slugName: string = "";
    shortName: string = "";

    protected setBaseName(lang: string): void {
        if (lang === "hu") return;
        if (this.name_sec_lang) this.name = this.name_sec_lang;
        if (this.description_sec_lang) this.description = this.description_sec_lang;
    }

    protected setSlugAndShortName(): void {
        this.name = this.name.replace(/,(?!\s)/g, ", ").trim();
        this.description = this.description.replace(/,(?!\s)/g, ", ").trim();

        this.slugName = Slugify(this.name);

        const nameLength = this.name.length;
        if (nameLength > 18) {
            const firstSlice = this.name.slice(0, 18);
            if (firstSlice.indexOf(" ") === -1) {
                this.shortName = `${firstSlice} ${this.name.slice(18, 30)}...`;
                return;
            }
            if (nameLength > 30) {
                this.shortName = `${this.name.slice(0, 30)}...`;
                return;
            }
            this.shortName = this.name;
        } else {
            this.shortName = this.name;
        }
    }

    public isDisplayable(): boolean {
        if (!this.active || this.archived_at) return false;
        return true;
    }
}
