import { IPR } from "sitebuilder-common";
import { ISizes } from "sitebuilder-common";

export const SOCIAL_CSS_BY_NODE_COUNT: IPR<
    ISizes,
    {
        [key: number]: {
            gridTemplateColumns: string;
            maxWidth: string;
        };
    }
> = {
    xl: {
        1: {
            gridTemplateColumns: "1fr",
            maxWidth: "30rem"
        },
        2: {
            gridTemplateColumns: "repeat(2, 1fr)",
            maxWidth: "60rem"
        },
        3: {
            gridTemplateColumns: "repeat(3, 1fr)",
            maxWidth: "60rem"
        },
        4: {
            gridTemplateColumns: "repeat(4,1fr)",
            maxWidth: "70rem"
        },
        5: {
            gridTemplateColumns: "repeat(5, 1fr)",
            maxWidth: "80rem"
        },
        6: {
            gridTemplateColumns: "repeat(6, 1fr)",
            maxWidth: "90rem"
        }
    },
    sm: {
        1: {
            gridTemplateColumns: "1fr",
            maxWidth: "100%"
        },
        2: {
            gridTemplateColumns: "repeat(2, 1fr)",
            maxWidth: "100%"
        },
        3: {
            gridTemplateColumns: "repeat(3, 1fr)",
            maxWidth: "100%"
        },
        4: {
            gridTemplateColumns: "repeat(2,1fr)",
            maxWidth: "100%"
        },
        5: {
            gridTemplateColumns: "repeat(3, 1fr)",
            maxWidth: "100%"
        },
        6: {
            gridTemplateColumns: "repeat(3, 1fr)",
            maxWidth: "100%"
        }
    },
    xs: {
        1: {
            gridTemplateColumns: "1fr",
            maxWidth: "100%"
        },
        2: {
            gridTemplateColumns: "1fr",
            maxWidth: "100%"
        },
        3: {
            gridTemplateColumns: "1fr",
            maxWidth: "100%"
        },
        4: {
            gridTemplateColumns: "1fr",
            maxWidth: "100%"
        },
        5: {
            gridTemplateColumns: "1fr",
            maxWidth: "100%"
        },
        6: {
            gridTemplateColumns: "1fr",
            maxWidth: "100%"
        }
    }
};
