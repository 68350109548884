import { CaretDown, CaretUp } from "@carbon/icons-react";
import { useTranslation } from "react-i18next";
import { useGroup } from "./Group.provider";
import { ILang } from "../../../Interfaces/ILang.type";

export interface IGrouperProps<T extends string> {
    groupName: T;
    title: ILang;
    children: any;
    onOpen?: () => void;
}

export const Grouper = <T extends string>({
    groupName,
    title,
    children,
    onOpen
}: IGrouperProps<T>) => {
    const { t } = useTranslation<ILang>();
    const { active, setActive } = useGroup<T>();

    const isActive = active === groupName;
    const handleClick = (): void => {
        if (isActive) setActive("" as T);
        else {
            if (onOpen) onOpen();
            setActive(groupName);
        }
    };

    const classes: string[] = ["grouper"];
    if (isActive) classes.push("active-grouper-group");

    return (
        <div className={classes.join(" ")}>
            <div className="grouper__head" onClick={handleClick}>
                <div className="grouper__head--title">{t<ILang>(title)}</div>
                <div className="grouper__head--control">
                    {isActive ? <CaretUp /> : <CaretDown />}
                </div>
            </div>
            {isActive && children}
        </div>
    );
};
