import { FC } from "react";
import { useIFrame } from "../../Provider/IFrame.context";
import { FrameEditorGroup } from "../IFrameEditorGroup.view";
import ColorInput from "../../../UI/InputFields/ColorInput/ColorInput.view";

interface IFrameButtonsFormProps {}

export const FrameButtonsForm: FC<IFrameButtonsFormProps> = (props) => {
    const { forms } = useIFrame<"buttons">();
    const { editor, fieldErrors, handleChange } = forms.buttons;

    return (
        <FrameEditorGroup groupKey={"buttons"} nameKey={"iframe_buttons"}>
            <ColorInput
                onChange={handleChange}
                id={"backgroundColor"}
                value={editor.backgroundColor}
                errors={fieldErrors}
                withValueSync
                isClearable
            />
            <ColorInput
                onChange={handleChange}
                id={"color"}
                value={editor.color}
                errors={fieldErrors}
                withValueSync
                isClearable
            />
        </FrameEditorGroup>
    );
};
