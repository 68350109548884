import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useCart } from "../Provider/Cart.provider";
import { TextInput } from "../../../UI/InputFields/TextInput/TextInput.view";
import { ICartUserForm } from "../Interfaces/ICartUserForm.interface";
import { CheckboxInput } from "../../../UI/InputFields/Checkbox/Checkbox.view";
import { useTranslation } from "react-i18next";

interface ICartUserDataViewProps {
    isApp?: boolean;
}

export const CartUserDataView: FC<ICartUserDataViewProps> = (props) => {
    const { t, i18n } = useTranslation<ILang>();
    const { form } = useCart();

    const { editor, handleChange } = form;
    const cls = ["registration cart__registration"];
    if (i18n.language === "hu") cls.push("swap-names");
    return (
        <form
            className={cls.join(" ")}
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <div className="registration__block cart-block details-block">
                {!props.isApp && (
                    <h4 className="registration__title no-space">{t<ILang>("my_data")}</h4>
                )}
                <TextInput<ICartUserForm>
                    required
                    id={"email"}
                    className={"email"}
                    type={"email"}
                    value={editor.email}
                    onChange={handleChange}
                />
                <TextInput<ICartUserForm>
                    required
                    id={"first_name"}
                    className={"first_name"}
                    value={editor.first_name}
                    onChange={handleChange}
                />
                <TextInput<ICartUserForm>
                    required
                    id={"last_name"}
                    className={"last_name"}
                    value={editor.last_name}
                    onChange={handleChange}
                />
                {editor.reg_with_order && (
                    <TextInput<ICartUserForm>
                        required
                        id={"password"}
                        className={"password"}
                        value={editor.password}
                        onChange={handleChange}
                    />
                )}
                <TextInput<ICartUserForm>
                    required
                    id={"phone_number"}
                    className={"phone_number"}
                    value={editor.phone_number}
                    onChange={handleChange}
                />
                <CheckboxInput<ICartUserForm>
                    id={"reg_with_order"}
                    value={editor.reg_with_order}
                    className={"reg_with_order"}
                    onBlur={(data) => handleChange(data.field, data.value)}
                />
            </div>
            <div className="registration__block cart-block address-block">
                {!props.isApp && (
                    <h4 className="registration__title">{t<ILang>("delivery_address")}</h4>
                )}
                {!editor.is_take_away && (
                    <>
                        <TextInput<ICartUserForm>
                            required={!editor.is_take_away}
                            id={"city"}
                            className={"city"}
                            value={editor.city}
                            onChange={handleChange}
                        />
                        <TextInput<ICartUserForm>
                            required={!editor.is_take_away}
                            id={"street"}
                            className={"street"}
                            value={editor.street}
                            onChange={handleChange}
                        />
                        <TextInput<ICartUserForm>
                            required={!editor.is_take_away}
                            id={"premise_number"}
                            className={"premise_number"}
                            value={editor.premise_number}
                            onChange={handleChange}
                        />
                        <TextInput<ICartUserForm>
                            id={"floor"}
                            className={"floor"}
                            value={editor.floor}
                            onChange={handleChange}
                        />
                        <TextInput<ICartUserForm>
                            id={"door_number"}
                            className={"door_number"}
                            value={editor.door_number}
                            onChange={handleChange}
                        />
                        <TextInput<ICartUserForm>
                            id={"doorbell"}
                            className={"doorbell"}
                            value={editor.doorbell}
                            onChange={handleChange}
                        />
                    </>
                )}
                {!props.isApp && (
                    <CheckboxInput<ICartUserForm>
                        id={"is_take_away"}
                        value={editor.is_take_away}
                        className={"is_take_away"}
                        onBlur={(data) => handleChange(data.field, data.value)}
                    />
                )}
            </div>
            <button type={"submit"} style={{ display: "none" }}></button>
        </form>
    );
};
