import { LONG_SELECT_SLUG_REGEX } from "./LongSelectSlug.static";

export const LongSelectSlugify = (value: string): RegExp => {
    return RegExp(value.replace(LONG_SELECT_SLUG_REGEX, "").toLocaleLowerCase(), "i");
};

export const LongSelectAddSlugNameProperty = <T extends { longSlugName: RegExp }>(
    model: T,
    nameAccessor: keyof T
): void => {
    model.longSlugName = LongSelectSlugify(model[nameAccessor] as never as string);
};
