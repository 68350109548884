import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useEnv } from "../../../../Contexts/Environment.context";
import { DnDEvents } from "../../Util/DND.util";
import { MapStyles } from "../../Static/MapStyles.static";
import { useImages } from "../../../../Contexts/Images.context";
import { IDomElement } from "../../../DomTracker/Interfaces/IDomTypes.interface";
import { IMapContent } from "../../Interfaces/IMapComponent.interface";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { FormatPhoneNumber, ParseComponentStyle } from "sitebuilder-common";
import RestIcon from "../../../../Assets/map_restaurant.png";
import Overlay from "../../View/Overlay.view";

interface IRestMapProps extends IDomElement<IMapContent> {}

export const RestMap: FC<IRestMapProps> = (props) => {
    const env = useEnv();
    const { t } = useTranslation<ILang>();
    const { images } = useImages();
    const { restaurant, config } = useRestaurant();
    const { refs, size, id, showOverlay, ...events } = DnDEvents<IMapContent>(props, "ELEMENT");

    const style = ParseComponentStyle(props.content, MapStyles, size, images);
    const linkAddress = `${restaurant.city}+${restaurant.street}+${restaurant.premise_number}`;

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: env.GOOGLE_API_KEY
    });

    const labelColor = { color: config.main_btn_bg_color };

    const classes: string[] = ["dom__map-content"];
    if (props.content.isFullWidth) classes.push("single-grid");
    if (style && style.color) labelColor.color = style.color;

    return (
        <div
            id={id}
            {...events}
            style={style}
            className={"dom__map"}
            ref={refs.current[props.uuid]}
        >
            {showOverlay && <Overlay parent={props} unmountId={props.sectionUUID} />}

            <div className={classes.join(" ")}>
                <div className="dom__map--texts">
                    <h1 className="dom__map--texts--title">{t<ILang>("you_will_find_us_here")}</h1>

                    <div className="dom__map--texts--block">
                        <h3 style={labelColor} className="label">
                            {t<ILang>("availability")}
                        </h3>
                        <a
                            id="address"
                            href={`https://www.google.com/maps/search/${linkAddress.replace(
                                /\s/g,
                                "+"
                            )}`}
                            rel={"noreferrer noopener"}
                            target="_blank"
                            className="value"
                        >
                            {restaurant.city}, {restaurant.street} {restaurant.premise_number}.
                        </a>
                    </div>
                    <div className="dom__map--texts--block">
                        <h3 style={labelColor} className="label">
                            {t<ILang>("phone_number")}
                        </h3>
                        <a
                            id="phone"
                            href={`tel:${restaurant.phone_1}`}
                            rel={"noreferrer noopener"}
                            target="_blank"
                            className="value"
                        >
                            {FormatPhoneNumber(restaurant.phone_1)}
                        </a>
                    </div>
                    <div className="dom__map--texts--block">
                        <h3 style={labelColor} className="label">
                            {t<ILang>("email")}
                        </h3>
                        <a
                            id="email"
                            href={`mailto:${restaurant.contact_email}`}
                            rel={"noreferrer noopener"}
                            target="_blank"
                            className="value"
                        >
                            {restaurant.contact_email}
                        </a>
                    </div>
                </div>
                <div className="dom__map--container">
                    {isLoaded ? (
                        <GoogleMap
                            center={{
                                lat: parseFloat(props.content.lat),
                                lng: parseFloat(props.content.lng)
                            }}
                            zoom={parseInt(props.content.zoom)}
                            mapContainerClassName={"dom__map--container--map-instance"}
                        >
                            <Marker
                                icon={RestIcon}
                                position={{
                                    lat: parseFloat(props.content.lat),
                                    lng: parseFloat(props.content.lng)
                                }}
                            />
                        </GoogleMap>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
