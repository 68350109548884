import Modal, { IModalProps } from "../Modal/Modal.view";
import { useTranslation } from "react-i18next";
import { InlineLoading } from "../InlineLoading/InlineLoading.view";
import { FC, useState } from "react";
import { TrashCan } from "@carbon/icons-react";
import { ILoading } from "xa-generics";
import { ILang } from "../../../Interfaces/ILang.type";

interface IWithConfirmProps {
    className?: string;
    disabled?: boolean;
    size?: IModalProps["size"];
    isDanger?: boolean;
    questionContent?: JSX.Element | ILang;
    onCancel?: () => void;
    onConfirm: () => void;
    allowEnterKeyDown?: boolean;
    Icon?: JSX.Element;
    loading?: ILoading;
}

export const WithConfirm: FC<IWithConfirmProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const [showModal, setShowModal] = useState<boolean>(false);

    const classes: string[] = ["global__with-delete-confirm"];
    if (props.className) classes.push(props.className);
    if (props.disabled) classes.push("disabled-delete");

    return (
        <>
            <div
                className={classes.join(" ")}
                onClick={() => {
                    if (props.disabled) return;
                    setShowModal(!showModal);
                }}
            >
                {props.children}
            </div>
            {showModal && (
                <Modal
                    isDanger={props.isDanger === undefined ? true : props.isDanger}
                    allowEnterKeyDown={props.allowEnterKeyDown}
                    onClose={() => {
                        setShowModal(false);
                        if (props.onCancel) props.onCancel();
                    }}
                    heading={t<ILang>("warning")}
                    submitText={t<ILang>("yes")}
                    size={props.size || "XS"}
                    submitIcon={
                        props.loading ? <InlineLoading /> : props.Icon ? props.Icon : TrashCan
                    }
                    onSubmit={() => {
                        props.onConfirm();
                        setShowModal(false);
                    }}
                >
                    {!props.questionContent
                        ? t<ILang>("delete_question")
                        : typeof props.questionContent === "string"
                        ? t<ILang>(props.questionContent)
                        : props.questionContent}
                </Modal>
            )}
        </>
    );
};
