import { FC } from "react";
import { useApp } from "../../Provider/App.context";
import { useProducts } from "../../../DomMapper/DomComponents/Products/Context/ProductsContext.provider";
import { useSwipeable } from "react-swipeable";
import { AppProductElement } from "./AppProduct.element";
import { IAppOrderIndexProps } from "./AppOrder.index";

interface IAppProductsProps extends IAppOrderIndexProps {
    currency: string;
}

export const AppProducts: FC<IAppProductsProps> = (props) => {
    const { catObject } = useProducts();
    const { data, dataSource } = useApp();
    const productConfig = data[dataSource].product;
    const handlers = useSwipeable({
        onSwipedRight: (e) => {
            if (props.selector.category && props.selector.category.parent_id) {
                props.appProudctNavigate(catObject[props.selector.category.parent_id]);
            } else props.appProudctNavigate(null);
        },
        delta: 100
    });
    if (!props.selector.category || props.selector.category.has_child) return null;
    if (props.selector.product) return null;

    const classes = ["app-products-container"];
    if (productConfig.isCategoryDescriptionVisible) {
        classes.push("--DESCRIPTION_VISIBLE");
    }

    return (
        <div className={classes.join(" ")} {...handlers}>
            <div className="app-products-container__category">
                {props.selector.category.name}
                {productConfig.isCategoryDescriptionVisible &&
                    props.selector.category.description && (
                        <span className="description">{props.selector.category.description}</span>
                    )}
            </div>
            {props.selector.category.products.map((product) => (
                <AppProductElement
                    key={`${product.id}-app-product-element`}
                    product={product}
                    {...props}
                />
            ))}
        </div>
    );
};
