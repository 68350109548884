import { FC } from "react";
// import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { useImages } from "../../../../Contexts/Images.context";
import { useWidgets } from "../../../Widgets/Controller/WidgetsContext.provider";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { INavbarSettings } from "../Interfaces/INavbarWidget.interface";
import { GetWidgetOverlay } from "../../Util/WidgetOverlay.util";
import WidgetOverlay from "../../../Widgets/View/WidgetOverlay.view";

interface IV2NavbarLogoProps {}

export const V2NavbarLogo: FC<IV2NavbarLogoProps> = (props) => {
    // const { size } = useDom();
    const { images } = useImages();
    const { widgets } = useWidgets();
    const { restaurant } = useRestaurant();
    const { navbarLogo } = widgets.Navbar.draftSettings;

    const { showOverlay, ...events } = GetWidgetOverlay<INavbarSettings>("Navbar", "navbarLogo");

    return (
        <div className="v2navbar__logo" {...events}>
            {showOverlay && (
                <WidgetOverlay<INavbarSettings>
                    align={"right"}
                    subKey={"navbarLogo"}
                    parent={widgets.Navbar}
                />
            )}

            <img
                alt={restaurant.name}
                className={"v2navbar__logo--image"}
                src={navbarLogo ? images[navbarLogo].url : "SET IMAGE"}
            />
        </div>
    );
};
