import { IFrameHeader } from "./IFrameHeader.view";
import { FONT_OPTIONS, IPartialCSS } from "sitebuilder-common";
import { useIFrame } from "../Provider/IFrame.context";
import { useImages } from "../../../Contexts/Images.context";
import { FC } from "react";

interface IIFrameContainerViewProps {}

export const IFrameContainerView: FC<IIFrameContainerViewProps> = (props) => {
    const { data, dataSource } = useIFrame();
    const { images } = useImages();
    const content = data[dataSource].global;

    const style: IPartialCSS<any> = {};

    if (content.color) style.color = content.color;
    if (content.backgroundColor) style.backgroundColor = content.backgroundColor;

    if (content.image) {
        style.backgroundImage = `url(${images[content.image].url})`;
        delete style.backgroundColor;
    }
    if (content.imageAttachment) style.backgroundAttachment = content.imageAttachment;
    if (content.imagePosition) style.backgroundPosition = content.imagePosition;
    if (content.imageRepeat) style.backgroundRepeat = "repeat";
    else style.backgroundRepeat = "no-repeat";
    if (content.imageSize) style.backgroundSize = content.imageSize;
    if (content.fontFamily) {
        style.fontFamily = FONT_OPTIONS.find((o) => o.id === content.fontFamily)?.font;
    }

    const classes = ["container"];
    if (data[dataSource].global.isRounded) classes.push("rounded-corners");
    if (data[dataSource].global.isDarkTheme) classes.push("dark-container");

    return (
        <div className={classes.join(" ")} style={style}>
            <IFrameHeader />
            {props.children}
        </div>
    );
};
