import { IBuilderTypes } from "./../Interfaces/IBuilder.type";
import { LS } from "../Static/LS.static";

export const GetBuilderType = (source: string): IBuilderTypes => {
    switch (source) {
        case "IFRAME":
            localStorage.setItem(LS.builderType, source);
            return "IFRAME";
        case "APP":
            localStorage.setItem(LS.builderType, source);
            return "APP";
        default:
            localStorage.setItem(LS.builderType, "SITEBUILDER");
            return "SITEBUILDER";
    }
};
