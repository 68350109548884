import { ILang } from "../../../Interfaces/ILang.type";
import { Templates } from "../Controller/Templates.controller";
import { FC, useState } from "react";
import { useRestaurant } from "../../../Contexts/Restaurant.context";
import { RuleCancelled } from "@carbon/icons-react";
import { InlineLoading } from "../../UI/InlineLoading/InlineLoading.view";
import { useTranslation } from "react-i18next";
import Button from "../../UI/Button/Button.view";
import Modal from "../../UI/Modal/Modal.view";

interface IChooseTemplateModalProps {}

export const ChooseTemplateModal: FC<IChooseTemplateModalProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { setIsNewInstance, declineConfigTemplate } = useRestaurant();
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <Modal
            isPassive
            isDarkened
            size={"LG"}
            onClose={() => setIsNewInstance(false)}
            heading={t<ILang>("choose_a_template")}
        >
            <div className="choose-template">
                {t<ILang>("choose_template_info")}{" "}
                <Button
                    useContainer
                    align={"right"}
                    onClick={() => {
                        setLoading(true);
                        declineConfigTemplate();
                    }}
                    Icon={loading ? <InlineLoading isAlternate /> : <RuleCancelled />}
                >
                    {t<ILang>("cancel_prompt_popup")}
                </Button>
            </div>
            <Templates setLoading={setLoading} loading={loading} />
        </Modal>
    );
};
