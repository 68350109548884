import { Context, createContext, useState, useContext } from "react";
import { IInputGroupContext } from "./IInputGroupContext.interface";
import { IInputGroups } from "../../../Interfaces/IInputGroups.type";

/**
 * ## InputGroupContext
 */
const InputGroupContext: Context<IInputGroupContext> = createContext<IInputGroupContext>(
    null as any
);

InputGroupContext.displayName = "InputGroupContext";

interface IInputGroupContextProviderProps {
    defaultActive?: IInputGroups;
}

/**
 * ## InputGroup context provider component
 *
 */
export const InputGroupContextProvider: React.FC<IInputGroupContextProviderProps> = (props) => {
    const [active, setActive] = useState<IInputGroups>(props.defaultActive || "");

    return (
        <InputGroupContext.Provider
            value={{
                setActiveGroup: setActive,
                active
            }}
        >
            {props.children}
        </InputGroupContext.Provider>
    );
};

export const useInputGroup = (): IInputGroupContext => useContext(InputGroupContext);
