import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useCart } from "../Provider/Cart.provider";
import { CartRadioRow } from "./CartRadioRow.view";
import { useTranslation } from "react-i18next";

interface ICartPaymentMethodsProps {}

export const CartPaymentMethods: FC<ICartPaymentMethodsProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { form } = useCart();
    return (
        <div className={"cart-block cart__payment-methods"}>
            <h4 className="cart__title">{t<ILang>("payment_methods")}</h4>
            <CartRadioRow
                isSelected={form.editor.payment_method_id === "1"}
                labelText={"simplepay"}
                onClick={() => form.handleChange("payment_method_id", "1")}
            />
            <CartRadioRow
                isSelected={form.editor.payment_method_id === "2"}
                labelText={"cash"}
                onClick={() => form.handleChange("payment_method_id", "2")}
            />
            <CartRadioRow
                noBorder
                isSelected={form.editor.payment_method_id === "3"}
                labelText={"otp_nice_card"}
                onClick={() => form.handleChange("payment_method_id", "3")}
            />
        </div>
    );
};
