import { useIFrame } from "../Provider/IFrame.context";
import { FC, useState } from "react";

export interface IFrameButtonProps {
    disabled?: boolean;
    isSecondary?: boolean;
    Icon: JSX.Element | string;
    type?: "submit" | "button" | "reset";
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const FrameButton: FC<IFrameButtonProps> = (props) => {
    const [isOver, setIsOver] = useState<boolean>(false);
    const { data, dataSource } = useIFrame();
    const content = data[dataSource].buttons;

    const btnStyle: React.CSSProperties = {};
    const cornerStyle: React.CSSProperties = {};
    const triStyle: React.CSSProperties = {};

    const classes = ["global__btnv2"];
    if (props.isSecondary) classes.push("--SECONDARY");

    if (!props.isSecondary) {
        if (isOver) {
            if (content.backgroundColor) {
                cornerStyle.backgroundColor = content.backgroundColor;
                triStyle.borderRightColor = content.backgroundColor;
                btnStyle.color = content.backgroundColor;
            }
            if (content.color) cornerStyle.color = content.color;
        } else {
            if (content.backgroundColor) {
                btnStyle.backgroundColor = content.backgroundColor;
                cornerStyle.color = content.backgroundColor;
            }
            if (content.color) btnStyle.color = content.color;
        }
    }
    if (data[dataSource].global.isDarkTheme) classes.push("dark-icon");
    if (data[dataSource].global.isRounded) classes.push("rounded-corners");

    return (
        <button
            type={props.type || "button"}
            onMouseLeave={() => {
                setIsOver(false);
            }}
            onMouseOver={() => {
                if (!isOver) setIsOver(true);
            }}
            onMouseEnter={() => {
                setIsOver(true);
            }}
            className={classes.join(" ")}
            disabled={props.disabled}
            onClick={props.onClick}
            style={btnStyle}
        >
            <div className="global__btnv2__text">{props.children}</div>
            <div className="global__btnv2__icon" style={cornerStyle}>
                <div className="global__btnv2__icon--triangle" style={triStyle}></div>
                {props.Icon}
            </div>
        </button>
    );
};
