import { useSideMenu } from "../Components/Sidemenu/Controller/SideMenu.provider";
import { ISideMenuContext } from "../Components/Sidemenu/Interfaces/ISideMenuContext.interface";

/**
 * ## AddMenuClass
 *
 * This util function pulls in the **useSideMenu()** react hook
 * *(so it can be used only in a component and NOT in the render)*,
 * and based on the provided **type** value, it will add a global
 * className to your container className holding array.
 *
 * If the type is padding, the content will be pushed away with *padding-left*,
 * if the type is absolute, the content will be pushed away with *left*.
 *
 * This method also returns the hook value in case you
 * need to use the hook controls as well.
 *
 * Example of usage:
 *
 * ```tsx
 *
 * const ExampleComponent: React.FC<any> = (props) => {
 *  const classes: string[] = ["example-container"];
 *
 *  //Use it like this:
 *  AddMenuClass(classes, "padding");
 *
 *  //Or like this, and then you can use the isOpen and setisOpen if necessary
 *  const { isOpen, setisOpen } = AddMenuClass(classes, "padding");
 *
 *  return (
 *      <div className={classes.join(" ")}>
 *          {props.children}
 *      </div>
 *  )
 * }
 * ```
 */
export const AddMenuClass = (
    classArray: string[],
    type: "padding" | "absolute"
): ISideMenuContext => {
    const state = useSideMenu();
    if (state.isOpen) {
        if (type === "absolute") classArray.push("global__menu-open-absolute");
        else classArray.push("global__menu-open-padding");
    }

    return state;
};
