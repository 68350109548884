import { FC } from "react";
import { TileView } from "./Tile.view";
import { IPartialCSS } from "sitebuilder-common";
import { useProducts } from "../Context/ProductsContext.provider";
import { IProductsIndexProps } from "./Products.index";
import { ProductCategoryModel } from "../Models/ProductCategory.model";
import { CategoryBackButtonView } from "./CategoryBackButton.view";

interface ITileCategoryMapperViewProps extends IProductsIndexProps {
    contrastedElements: IPartialCSS<any>;
}

export const TileCategoryMapperView: FC<ITileCategoryMapperViewProps> = (props) => {
    const { categories } = useProducts(props.filteredIds);

    if (props.filteredProducts.length > 0 || props.filter.length > 0) return null;

    const JSX: JSX.Element[] = [];

    for (let index in categories) {
        const category = categories[index];
        if (props.selector.category) {
            if (props.selector.category.id !== category.parent_id) continue;
        } else if (category.parent_id) continue;
        JSX.push(
            <TileView<ProductCategoryModel>
                hideImageBackground={props.content.hideTileBackground}
                onClick={() => props.setSelectorData(category)}
                key={`${category.id}-tile-category-${index}`}
                imageUrl={category.image_web!.medium || ""}
                style={props.contrastedElements}
                content={props.content}
                nameKey={"name"}
                item={category}
            />
        );
    }

    return (
        <>
            <CategoryBackButtonView {...props} />
            {(!props.selector.category || props.selector.category.has_child) && (
                <div className={"products__categories tile-categories"}>{JSX}</div>
            )}
        </>
    );
};
