import { HashLink } from "react-router-hash-link";
import { MenuItem } from "../../../../UI/ContextMenu/MenuItem.view";
import { useProducts } from "../Context/ProductsContext.provider";
import { RadioButton } from "@carbon/icons-react";
import { ContextMenu } from "../../../../UI/ContextMenu/ContextMenu.view";
import { FC, useState } from "react";
import { IPartialCSS, ISizes } from "sitebuilder-common";
import { TopbarCategoryElement } from "./TopbarCategory.element";

interface ITopbarCategoriesProps {
    size?: ISizes;
    isRounded: boolean;
    isDarkTheme: boolean;
    filteredIds?: string[];
    style?: IPartialCSS<any>;
}

export const TopbarCategories: FC<ITopbarCategoriesProps> = (props) => {
    const { categories } = useProducts(props.filteredIds);

    const [lastClicked, setLastClicked] = useState<string>("");
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [showSubCategories, setShowSubCategories] = useState<string>("");

    const classes: string[] = ["topbar"];
    if (props.size) classes.push(`topbar-${props.size}`);
    if (props.isDarkTheme) classes.push("topbar-dark");
    if (props.isRounded) classes.push("rounded-topbar");
    return (
        <div className={classes.join(" ")} style={props.style}>
            <div className="topbar__container">
                <div className="topbar__container--categories">
                    {categories.map((category) => {
                        if (category.parent_id) return null;
                        if (category.has_child) {
                            const classes: string[] = ["hash-link"];
                            if (lastClicked === category.id || showSubCategories === category.id) {
                                classes.push("active-hash");
                            }

                            return (
                                <ContextMenu
                                    style={props.style}
                                    isRounded={props.isRounded}
                                    id={`category-${category.id}`}
                                    isDarkTheme={props.isDarkTheme}
                                    Toggler={TopbarCategoryElement}
                                    key={`${category.id}-topbar-category`}
                                    TogglerProps={{ name: category.name, className: "hash-link" }}
                                >
                                    {category.children.map((subCategory) => (
                                        <MenuItem
                                            key={`${subCategory.id}-topbar-category`}
                                            onClick={() => {
                                                if (isOpened) setIsOpened(false);
                                                setShowSubCategories("");
                                                setLastClicked(subCategory.id);
                                            }}
                                            Icon={RadioButton}
                                        >
                                            <HashLink
                                                smooth
                                                className={"hash-link"}
                                                id={subCategory.slugName}
                                                to={`#${subCategory.slugName}`}
                                            >
                                                {subCategory.name}
                                            </HashLink>
                                        </MenuItem>
                                    ))}
                                </ContextMenu>
                            );
                        } else {
                            const classes = ["hash-link"];
                            if (lastClicked === category.id) classes.push("active-hash");
                            return (
                                <HashLink
                                    smooth
                                    className={classes.join(" ")}
                                    to={`#${category.slugName}`}
                                    key={`${category.id}-topbar-category`}
                                    onClick={(e) => {
                                        if (isOpened) setIsOpened(false);
                                        setShowSubCategories("");
                                        setLastClicked(category.id);
                                    }}
                                >
                                    {category.name}
                                </HashLink>
                            );
                        }
                    })}
                </div>
            </div>
        </div>
    );
};
