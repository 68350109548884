import {
    IError,
    Regexes,
    ILoading,
    useTimeout,
    useDidMount,
    useAfterTriggerChanged
} from "xa-generics";
import { Context, createContext, useState, useContext, useMemo, useRef } from "react";
import { IActiveIFrameEditor } from "../Interfaces/IActiveFrameEditor.type";
import { CartContextProvider } from "../../WidgetModules/Cart/Provider/Cart.provider";
import { FrameConfigsModel } from "sitebuilder-common";
import { IFrameDataSource } from "../Interfaces/IIFrameDataSources.type";
import { IIFrameContext } from "../Interfaces/IIFrameContext.interface";
import { useTranslation } from "react-i18next";
import { FloatingError } from "xa-error-with-lang";
import { IFrameModel } from "../Models/IFrame.model";
import { IFrameDAO } from "../DAO/IFrame.dao";
import { useForm } from "../../UseForm/UseForm.provider";
import { clone } from "lodash";
import { ILang } from "../../../Interfaces/ILang.type";
import Loading from "../../UI/Loading/Loading.view";

/**
 * ## IFrameContext
 */
const IFrameContext: Context<IIFrameContext<any>> = createContext<IIFrameContext<any>>(null as any);

IFrameContext.displayName = "IFrameContext";

interface IIFrameContextProviderProps {}

/**
 * ## IFrame context provider component
 *
 */
export const IFrameContextProvider: React.FC<IIFrameContextProviderProps> = (props) => {
    const { setTm } = useTimeout();
    const { t } = useTranslation<ILang>();
    const isFirstLoad = useRef<boolean>(true);

    const [dataSource, setDataSource] = useState<IFrameDataSource>("draft_elements_config");
    const [activeEditor, setActiveEditor] = useState<IActiveIFrameEditor>("");
    const [data, setData] = useState<IFrameModel | null>(null);
    const [loading, setLoading] = useState<ILoading>(false);
    const [error, setError] = useState<IError>(null);

    const emptyModel = useMemo(() => new FrameConfigsModel(), []);

    const buttonsForm = useForm<FrameConfigsModel["buttons"], true>({
        editor: emptyModel.buttons
    });
    const globalForm = useForm<FrameConfigsModel["global"], true>({
        editor: emptyModel.global
    });
    const headerForm = useForm<FrameConfigsModel["header"], true>({
        editor: emptyModel.header
    });
    const mapForm = useForm<FrameConfigsModel["map"], true>({
        editor: emptyModel.map,
        initialRules: {
            lat: {
                pattern: { value: Regexes.NumFloatInt, message: t<ILang>("invalid_lat") }
            },
            lng: {
                pattern: { value: Regexes.NumFloatInt, message: t<ILang>("invalid_lng") }
            }
        }
    });
    const productsForm = useForm<FrameConfigsModel["products"], true>({
        editor: emptyModel.products
    });

    const initAndLoad = (): void => {
        IFrameDAO.initAndLoad()
            .then((model) => {
                setData(model);
                if (isFirstLoad.current) {
                    isFirstLoad.current = false;
                    buttonsForm.setEditor(model.draft_elements_config.buttons);
                    globalForm.setEditor(model.draft_elements_config.global);
                    headerForm.setEditor(model.draft_elements_config.header);
                    mapForm.setEditor(model.draft_elements_config.map);
                    productsForm.setEditor(model.draft_elements_config.products);
                }
            })
            .catch(setError)
            .finally(() => setLoading(false));
    };

    const updateData = (states: Record<keyof FrameConfigsModel, any>): void => {
        if (!data) return;
        const current = clone(data);
        current.draft_elements_config = new FrameConfigsModel(states);
        setData(current);
        IFrameDAO.update(current.Deconvert)
            .catch(setError)
            .finally(() => setLoading(false));
    };

    const publishData = (): void => {
        if (!data) return;
        const newState = data.Deconvert;
        newState.elements_config = newState.draft_elements_config;

        setLoading(
            IFrameDAO.update(newState)
                .then(initAndLoad)
                .catch((error) => {
                    setError(error);
                    setLoading(false);
                })
        );
    };

    const onSetActiveEditor = (group: IActiveIFrameEditor): void => {
        setActiveEditor(group);
    };

    useDidMount(initAndLoad);

    useAfterTriggerChanged(() => {
        setTm(
            () => {
                updateData({
                    products: productsForm.editor,
                    buttons: buttonsForm.editor,
                    global: globalForm.editor,
                    header: headerForm.editor,
                    map: mapForm.editor
                });
            },
            1000,
            "autosave-draft-iframe"
        );
    }, [
        productsForm.editor,
        buttonsForm.editor,
        globalForm.editor,
        headerForm.editor,
        mapForm.editor
    ]);

    return (
        <IFrameContext.Provider
            value={{
                loading: loading ? true : false,
                data: data as IFrameModel,
                onSetActiveEditor,
                setDataSource,
                activeEditor,
                publishData,
                dataSource,
                forms: {
                    products: productsForm,
                    buttons: buttonsForm,
                    global: globalForm,
                    header: headerForm,
                    map: mapForm
                }
            }}
        >
            <CartContextProvider>
                <Loading isAbsolute loading={loading} />
                <FloatingError error={error} resetError={() => setError(null)} />
                {/* INFO this will prevent children from rendering before the state is set.
                    That's why it's also safe to assert the interfaces as non-null values. */}
                {!data ? <Loading isAbsolute isExternalConditional /> : props.children}
            </CartContextProvider>
        </IFrameContext.Provider>
    );
};

export const useIFrame = <FormType extends keyof FrameConfigsModel>(): IIFrameContext<FormType> => {
    return useContext(IFrameContext);
};
