import { ILang } from "../../../Interfaces/ILang.type";
import { useEnv } from "../../../Contexts/Environment.context";
import { useTimeout } from "xa-generics";
import { Close, Copy } from "@carbon/icons-react";
import { FC, useState } from "react";
import { createPortal } from "react-dom";
import { useRestaurant } from "../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import CopyExec from "copy-to-clipboard";

interface IEmbedCodeGenProps {}

export const EmbedCodeGen: FC<IEmbedCodeGenProps> = (props) => {
    const { restaurant } = useRestaurant();
    const { setTm } = useTimeout();
    const { t } = useTranslation<ILang>();
    const env = useEnv();

    const [isCopied, setIsCopied] = useState<boolean>(false);
    const [showFrame, setShowFrame] = useState<boolean>(false);

    const url = `${env.CLIENT_URL}/iframes?domain=${restaurant.domain}`;
    const frameName = "onemin-iframe";
    const code = `
    <!-- HTML a tag code. You could also implement your own a tag or just copy the properties to an already existing a tag. -->
    <a
        href="${url}"
        target="onemin-iframe"
    >
        {t<ILang>("test_iframe_button")}
    </a>
    <!-- IFRAME code -->
    <iframe
    onclick="(e) => e.stopPropagation()"
    id="onemin-iframe"
    name="onemin-iframe"
    frameborder="0"
    style="background-color: #37373799; box-shadow: 0 0 8px 2px #00000055; border: 1px solid #888888; z-index: 1000; max-width: 120rem; width: 100%; min-height: 90vh; height: 95%;"
    title="${restaurant.name}"
    marginheight="0"
    loading="eager"
    marginwidth="0"
    allowfullscreen
    scrolling="yes"
    referrerpolicy="strict-origin-when-cross-origin"
></iframe>`;
    return (
        <div className="iframe-test">
            <div className="iframe-embed-code">
                <a
                    href={url}
                    target={frameName}
                    className={"iframe-embed-code__link"}
                    onClick={() => setShowFrame(true)}
                >
                    {t<ILang>("test_iframe_button")}
                </a>
                <button
                    type={"button"}
                    className="iframe-embed-code__copy"
                    onClick={() => {
                        CopyExec(code, {
                            debug: true,
                            message: "debug msg?",
                            onCopy: (d) => {
                                setIsCopied(true);
                                setTm(() => setIsCopied(false), 3000);
                                return d;
                            }
                        });
                    }}
                >
                    <Copy />
                </button>
                {isCopied && (
                    <div className="iframe-embed-code__copied">
                        {t<ILang>("successfully_copied")}
                    </div>
                )}

                {createPortal(
                    <div
                        className={showFrame ? "iframe-div iframe-div-visible" : "iframe-div"}
                        onClick={() => setShowFrame(false)}
                    >
                        <button className="close-iframe" onClick={() => setShowFrame(false)}>
                            <Close size={20} />
                        </button>
                        <iframe
                            onClick={(e) => e.stopPropagation()}
                            id={frameName}
                            name={frameName}
                            loading={"eager"}
                            // referrerPolicy={"strict-origin-when-cross-origin"}
                            frameBorder={0}
                            style={{
                                backgroundColor: "#37373799",
                                boxShadow: "0 0 8px 2px #00000055",
                                border: "1px solid #888888",
                                zIndex: 1000,
                                maxWidth: "120rem",
                                width: "100%",
                                minHeight: "90vh",
                                height: "95%"
                            }}
                            title={restaurant.name}
                            marginHeight={0}
                            marginWidth={0}
                            allowFullScreen
                            scrolling="yes"
                        />
                    </div>,
                    document.getElementById("root")!
                )}
            </div>
        </div>
    );
};
