import {
    INavbar,
    INavbarLink,
    INavbarSettings
} from "../../WidgetModules/Navbar/Interfaces/INavbarWidget.interface";
import { useTimeout, useAfterTriggerChanged } from "xa-generics";
import { IBlur, IFieldBlur } from "../Utils/EditorHook.util";
import { IGenericCSSFields } from "sitebuilder-common";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageDataModel } from "../../DomTracker/Model/PageData.model";
import { IPageEditor } from "../../DomMapper/Interfaces/IPageEditor.interface";
import { useWidgets } from "../../Widgets/Controller/WidgetsContext.provider";
import { cloneDeep } from "lodash";
import { useDom } from "../../DomTracker/Controller/DomTracker.provider";
import { ILang } from "../../../Interfaces/ILang.type";
import NavbarEditorView from "../View/Navbar/NavbarEditor.index";

export interface INavEditorControllerProps {
    editor: INavbar;
    subKey: keyof INavbarSettings;
}

const NavEditorController: React.FC<INavEditorControllerProps> = (props) => {
    const { i18n } = useTranslation<ILang>();
    const { updateWidget, clearRulesBySize, widgets } = useWidgets<INavbarSettings>();
    const { pages } = useDom();
    const { setTm, clearTm } = useTimeout();
    const [navLinks, setNavLinks] = useState<INavbarLink[]>(
        props.editor.draftSettings.navbarLinks || []
    );
    const [draggedLink, setDraggedLink] = useState<null | INavbarLink>(null);
    const dndTargetIndex = useRef<number>(-1);

    const addNewLink = (): void => {
        const state = cloneDeep(navLinks);
        const page = pages.find((page) => !page.draft); //Find first page that isn't a draf of another

        let data: INavbarLink = {
            isExternal: false,
            index: navLinks.length,
            openOnNewPage: false
        };

        if (page) {
            for (let seo of page.seos) {
                data[seo.lang] = {
                    url: page.page_id || "",
                    routeName: ""
                };
            }
        } else {
            data[i18n.language] = {
                url: "",
                routeName: ""
            };
        }
        state.push(data);
        setNavLinks(state);
    };

    const enforceLangExists = (
        clonedState: INavbarLink[],
        link: INavbarLink,
        lang?: string
    ): void => {
        //Enforces that the object exists in any language when the isExternal is toggled.
        if (typeof clonedState[link.index][lang || i18n.language] !== "object") {
            clonedState[link.index][lang || i18n.language] = {
                url: "",
                routeName: ""
            };
        }
    };

    const toggleExternal = (link: INavbarLink): void => {
        const state = cloneDeep(navLinks);
        const nextValue = !state[link.index].isExternal;
        state[link.index].isExternal = nextValue;
        enforceLangExists(state, link);

        if (nextValue) {
            (state[link.index][i18n.language] as IPageEditor).url = "https://";
        } else {
            (state[link.index][i18n.language] as IPageEditor).routeName = "";
            (state[link.index][i18n.language] as IPageEditor).url = "";
        }
        setNavLinks(state);
    };

    const toggleNewTab = (link: INavbarLink): void => {
        const state = cloneDeep(navLinks);
        const nextValue = !state[link.index].openOnNewPage;
        state[link.index].openOnNewPage = nextValue;
        enforceLangExists(state, link);
        setNavLinks(state);
    };

    const deleteLink = (link: INavbarLink): void => {
        const state = cloneDeep(navLinks);
        state.splice(link.index, 1);
        reindexLinks(state);
        setNavLinks(state);
    };

    const insertLinkAt = (): void => {
        if (!draggedLink || dndTargetIndex.current === -1) return;
        const state = cloneDeep(navLinks);
        state.splice(draggedLink.index, 1); //Removes original link
        state.splice(dndTargetIndex.current, 0, draggedLink); //Inserts it before target element
        reindexLinks(state);
        setNavLinks(state);
        setDraggedLink(null);
    };

    const reindexLinks = (deepClonedState: INavbarLink[]): void => {
        //Reindex elements...
        const length = deepClonedState.length;
        for (let i = 0; i < length; i++) {
            deepClonedState[i].index = i;
        }
    };

    const onLinkChange = (link: INavbarLink, field: keyof IPageEditor, value: any): void => {
        const state = cloneDeep(navLinks);
        enforceLangExists(state, link);
        (state[link.index][i18n.language] as IPageEditor)[field] = value;
        setNavLinks(state);
    };

    const onLinkRefToPage = (link: INavbarLink, page: PageDataModel): void => {
        const state = cloneDeep(navLinks);
        for (let seo of page.seos) {
            enforceLangExists(state, link, seo.lang);
            //Set the url to the page id in every language...
            (state[link.index][seo.lang] as IPageEditor).url = page.page_id;
        }
        setNavLinks(state);
    };

    const handleChange = (
        widgetProp: keyof INavbarSettings,
        data: IBlur<any> | IFieldBlur<any>
    ): void => {
        updateWidget(
            data instanceof Array
                ? data.map((dat) => ({
                      widgetType: "Navbar",
                      widgetProp,
                      data: dat
                  }))
                : [
                      {
                          widgetType: "Navbar",
                          widgetProp,
                          data
                      }
                  ]
        );
    };

    const onReset = (
        widgetProp: keyof INavbarSettings,
        keys: (keyof IGenericCSSFields)[]
    ): void => {
        clearRulesBySize("Navbar", widgetProp, keys);
    };

    useAfterTriggerChanged(
        () => {
            setTm(
                () =>
                    updateWidget([
                        {
                            widgetType: "Navbar",
                            widgetProp: "navbarLinks",
                            data: { field: "navbarLinks", value: navLinks }
                        }
                    ]),
                1000,
                "nav-widget-update"
            );
        },
        [navLinks],
        () => clearTm("nav-widget-update")
    );

    return (
        <NavbarEditorView
            {...props}
            onReset={onReset}
            navLinks={navLinks}
            deleteLink={deleteLink}
            addNewLink={addNewLink}
            draggedLink={draggedLink}
            setNavLinks={setNavLinks}
            toggleNewTab={toggleNewTab}
            handleChange={handleChange}
            insertLinkAt={insertLinkAt}
            onLinkChange={onLinkChange}
            dndTargetIndex={dndTargetIndex}
            setDraggedLink={setDraggedLink}
            toggleExternal={toggleExternal}
            onLinkRefToPage={onLinkRefToPage}
            navVersion={widgets.Navbar.draftSettings.navbarVersion || "default"}
        />
    );
};

export default NavEditorController;
