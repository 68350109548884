import { useMemo } from "react";
import { SelectClear } from "./Utils/SelectClear.util";
import { IFormErrors } from "../../../UseForm/IUseForm.interface";
import { FormErrorView } from "../../../UseForm/FormError.view";
import { useTranslation } from "react-i18next";
import { SelectBindingHook } from "./Utils/SelectBindingHook.util";
import { SelectOptionGenerate } from "./Utils/SelectOptionGenerate.util";
import { CaretDown, CaretUp, Close } from "@carbon/icons-react";
import { IWC, useAfterTriggerChanged } from "xa-generics";
import { createRef, useRef, useState } from "react";

export interface ISelectInputProps<Fields extends object, Option extends object, Lang = string>
    extends IWC {
    value: string;
    id: keyof Fields;
    labelText?: Lang;
    noLabel?: boolean;
    options: Option[];
    className?: string;
    placeholder?: Lang;
    required?: boolean;
    isDisabled?: boolean;
    isClearable?: boolean;
    idAccessor?: keyof Option;
    nameAccessor?: keyof Option;
    errors?: IFormErrors<Fields>;
    useTranslationOnName?: boolean;
    description?: string | JSX.Element;
    /**
     * This option key could be a slugified, lower-cased 'name' version
     * to speed up the process of filtering. If you define a **searchAccessor**,
     * the toLocaleLowerCase() and other similar steps will be skipped in the filter!
     */
    searchAccessor?: keyof Option;
    formatOptionLabel?: (option: Option) => React.ReactNode;
    onChange: (id: keyof Fields, value: string, option: Option | null) => void;
}

export const SelectInput = <Fields extends object, Option extends object, Lang = string>(
    props: ISelectInputProps<Fields, Option, Lang>
) => {
    const { t } = useTranslation();

    const [search, setSearch] = useState<string>("");
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [contextMenuSelected, setContextMenuSelected] = useState<number>(-1);

    const optionRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});

    const id = props.id as string;
    const wrapperStyle: string[] = ["wrapper", "select"];
    const uniqueKey = `${id}-menu`;
    const idAccessor = props.idAccessor || ("id" as keyof Option);
    const nameAccessor = props.nameAccessor || ("name" as keyof Option);
    const requireTransform: boolean = !props.searchAccessor;
    const searchRef = createRef<HTMLInputElement>();

    const onClose = (): void => {
        setIsOpen(false);
        setSearch("");
        setContextMenuSelected(-1);
    };

    const { options, selectedValue } = useMemo(
        () =>
            SelectOptionGenerate<Fields, Option, Lang>({
                ...props,
                isOpen,
                search,
                requireTransform
            }),
        [isOpen, search, requireTransform, props]
    );

    const { handleKeyPress } = SelectBindingHook<Fields, Option, Lang>({
        isOpen,
        search,
        onClose,
        uniqueKey,
        setIsOpen,
        optionRefs,
        contextMenuSelected,
        localOptions: options,
        setContextMenuSelected,
        ...props
    });

    useAfterTriggerChanged(() => {
        onClose();
    }, [props.value]);

    const nameValue = (selectedValue?.[nameAccessor] as never) || "";

    if (props.className) wrapperStyle.push(props.className);
    if (props.isDisabled) wrapperStyle.push("select-disabled");

    return (
        <div className={wrapperStyle.join(" ")}>
            {!props.noLabel && (
                <label htmlFor={id} className={"input-label"}>
                    {t((props.labelText as never) || id)}
                    {props.required ? "*" : null}
                </label>
            )}
            <select
                required={props.required}
                className={"hidden-select-input"}
                id={`${id}-hidden-select-field`}
                onChange={() => {}}
                autoComplete={"no-auto-complete"}
                value={props.value}
                autoSave={"off"}
            >
                {props.required ? (
                    props.value ? (
                        <option value={props.value}></option>
                    ) : null
                ) : (
                    <option value={props.value}></option>
                )}
            </select>
            <div
                className="select-input"
                onMouseUp={() => {
                    if (props.isDisabled) return;
                    setIsOpen(true);
                }}
                id={uniqueKey}
            >
                <input
                    id={id}
                    name={id}
                    type={"text"}
                    ref={searchRef}
                    autoSave={"off"}
                    readOnly={!isOpen}
                    autoComplete={"new-password"}
                    onKeyDown={handleKeyPress}
                    disabled={props.isDisabled}
                    className={"select-input__search"}
                    placeholder={
                        isOpen
                            ? (t("search") as string)
                            : props.placeholder
                            ? t(props.placeholder as never)
                            : undefined
                    }
                    onChange={(e) => setSearch(e.target.value)}
                    value={
                        isOpen
                            ? search
                            : props.useTranslationOnName
                            ? t<string>(nameValue)
                            : nameValue
                    }
                />

                <div className="select-input__controls">
                    {props.isClearable && (
                        <Close
                            size={20}
                            className={"select-input__controls--icon"}
                            onMouseUp={(e: MouseEvent) => SelectClear(e, props)}
                        />
                    )}
                    <span className="select-input__controls--separator">|</span>
                    {isOpen ? (
                        <CaretUp
                            size={24}
                            className={"multi-select-input__controls--icon"}
                            onMouseUp={(e) => {
                                e.stopPropagation();
                                setIsOpen(false);
                            }}
                        />
                    ) : (
                        <CaretDown
                            size={24}
                            className={"multi-select-input__controls--icon"}
                            onMouseUp={(e) => {
                                e.stopPropagation();
                                if (props.isDisabled) return;
                                setIsOpen(true);
                                if (searchRef.current) searchRef.current.focus();
                            }}
                        />
                    )}
                </div>

                {isOpen && (
                    <div className="select-input__list">
                        <div className="select-input__list--scrollable">
                            {options.map((option, index) => {
                                const classes: string[] = ["select-input__list--item"];
                                const value = option[idAccessor] as never;

                                if (value === props.value) classes.push("--selected");
                                if (index === contextMenuSelected) classes.push("--active");

                                return (
                                    <div
                                        className={classes.join(" ")}
                                        ref={(r) => (optionRefs.current[index] = r)}
                                        key={`${index}-${id}-${option[idAccessor]}`}
                                        onMouseUp={() => {
                                            if (props.isDisabled) return;
                                            props.onChange(props.id, value, option);
                                            onClose();
                                        }}
                                    >
                                        {props.formatOptionLabel
                                            ? props.formatOptionLabel(option)
                                            : props.useTranslationOnName
                                            ? t<any, any>(option[nameAccessor])
                                            : option[nameAccessor]}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>

            {props.errors && <FormErrorView id={props.id} errors={props.errors} />}
            {props.description ? (
                <div className="input-description">{props.description}</div>
            ) : null}
        </div>
    );
};
