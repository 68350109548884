import { FC } from "react";
import { ILang } from "../../../Interfaces/ILang.type";
import { useAccess } from "../../../Contexts/Access.context";
import { TextInput } from "../../UI/InputFields/TextInput/TextInput.view";
import { useTemplate } from "../Provider/Template.provider";
import { SelectInput } from "../../UI/InputFields/Select/SelectInput.view";
import { useSideMenu } from "../../Sidemenu/Controller/SideMenu.provider";
import { useTranslation } from "react-i18next";
import { ITemplatesViewProps } from "./Templates.view";
import { Regexes, useDidMount } from "xa-generics";
import { TemplateCategoryModel } from "../Model/TemplateCategory.model";
import { TemplateRestaurantModel } from "../Model/TemplateRestaurant.model";
import Modal from "../../UI/Modal/Modal.view";
import { InlineLoading } from "../../UI/InlineLoading/InlineLoading.view";

interface ITemplateRestEditorViewProps extends ITemplatesViewProps {}

export const TemplateRestEditorView: FC<ITemplateRestEditorViewProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { accessModel } = useAccess();
    const { templateCategories, saveRestaurant, loading } = useTemplate();
    const { setisOpen } = useSideMenu();

    useDidMount(() => {
        setisOpen(false);
    });

    if (!accessModel.super_admin || !props.form) return null;

    const onSubmit = (values: TemplateRestaurantModel): void => {
        if (
            values.preview_url &&
            Regexes.Url.test(values.preview_url) &&
            values.template_category_id
        ) {
            saveRestaurant(values, () => props.form.setEditor(null));
        }
    };

    return (
        <Modal
            isForm
            heading={t<ILang>("sa_templates_title")}
            onClose={() => props.form.setEditor(null)}
            onSubmit={props.form.handleSubmit(onSubmit)}
            submitIcon={loading ? <InlineLoading isAlternate /> : undefined}
            allowOverflow
            size={"SM"}
        >
            <TextInput<TemplateRestaurantModel>
                required
                id={"preview_url"}
                value={props.form.editor!.preview_url}
                onChange={props.form.handleChange}
            />

            <SelectInput<TemplateRestaurantModel, TemplateCategoryModel>
                isClearable
                nameAccessor={"name"}
                id={"template_category_id"}
                options={templateCategories}
                idAccessor={"template_category_id"}
                value={props.form.editor!.template_category_id}
                onChange={props.form.handleChange}
            />

            <TextInput<TemplateRestaurantModel>
                id={"description"}
                labelText={"template_desc"}
                value={props.form.editor!.description}
                onChange={props.form.handleChange}
            />

            <TextInput<TemplateRestaurantModel>
                id={"description_secondary"}
                labelText={"template_desc_en"}
                value={props.form.editor!.description_secondary}
                onChange={props.form.handleChange}
            />
        </Modal>
    );
};
