import { ParseComponentStyle } from "sitebuilder-common";
import { GetWidgetOverlay } from "../../Util/WidgetOverlay.util";
import { NavbarLangPicker } from "./NavbarLang.view";
import { INavbarSettings } from "../Interfaces/INavbarWidget.interface";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { CalcContrast } from "sitebuilder-common";
import { NavbarStatus } from "./NavbarStatus.view";
import { NavbarStyles } from "../Static/NavbarStyles.static";
import { NavbarLogin } from "./NavbarLogin.view";
import { useWidgets } from "../../../Widgets/Controller/WidgetsContext.provider";
import { NavbarReg } from "./NavbarReg.view";
import { ISetState } from "xa-generics";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { Menu } from "@carbon/icons-react";
import WidgetOverlay from "../../../Widgets/View/WidgetOverlay.view";

export interface INavbarTopViewProps {
    isNavbarOpened: boolean;
    setIsNavbarOpened: ISetState<boolean>;
}

const NavbarTopView: React.FC<INavbarTopViewProps> = (props) => {
    const { size } = useDom();
    const { widgets } = useWidgets();
    const { config } = useRestaurant();

    const { navbarTopBar } = widgets.Navbar.draftSettings;
    const { showOverlay, ...events } = GetWidgetOverlay<INavbarSettings>("Navbar", "navbarTopBar");

    const topStyles = ParseComponentStyle(navbarTopBar, NavbarStyles, size) || {};

    if (!topStyles.color && topStyles.backgroundColor) {
        if (
            CalcContrast(topStyles.backgroundColor, config.is_dark_theme ? "#171717" : "#ffffff") <
            50
        ) {
            topStyles.color = "#000000";
        } else {
            topStyles.color = "#ffffff";
        }
    }

    return (
        <div className={"navbar__top"} style={topStyles} {...events}>
            {showOverlay && (
                <WidgetOverlay<INavbarSettings>
                    isBlock
                    align={"center"}
                    parent={widgets.Navbar}
                    subKey={"navbarTopBar"}
                />
            )}
            <NavbarLangPicker navbarTopColor={topStyles?.backgroundColor} />
            <div className={"navbar__top__meta"}>
                <NavbarLogin />
                <NavbarReg />
                <NavbarStatus />

                <div
                    className="navbar__top__meta--toggle"
                    onClick={() => props.setIsNavbarOpened(!props.isNavbarOpened)}
                >
                    <Menu size={20} />
                </div>
            </div>
        </div>
    );
};

export default NavbarTopView;
