import { Context, createContext, useState, useContext } from "react";
import { IChangeHistory, IChangeData } from "../Interfaces/IChangeHistory.interface";
import { IChangeHistoryContext } from "../Interfaces/IChangeHistoryContext.interface";
import { IDynamicObject } from "xa-generics";
import { useTranslation } from "react-i18next";
import { PageDataModel } from "../../DomTracker/Model/PageData.model";
import { clone } from "lodash";
import { ILang } from "../../../Interfaces/ILang.type";

/**
 * ## ChangeHistoryContext
 */
export const ChangeHistoryContext: Context<IChangeHistoryContext> =
    createContext<IChangeHistoryContext>(null as any);

ChangeHistoryContext.displayName = "ChangeHistoryContext";

interface IChangeHistoryContextProviderProps {}

/**
 * ## ChangeHistory context provider component
 *
 */
export const ChangeHistoryContextProvider: React.FC<IChangeHistoryContextProviderProps> = (
    props
) => {
    const {
        t,
        i18n: { language }
    } = useTranslation<ILang>();
    const [changeHistory, setChangeHistory] = useState<IDynamicObject<IChangeHistory[]>>({});
    const [currentIndex, updateIndexState] = useState<IDynamicObject<number>>({});

    const setCurrentIndex = (pageID: string, index: number): void => {
        const state = clone(currentIndex);
        state[pageID] = index;
        updateIndexState(state);
    };

    const createDefaultHistory = (pages: PageDataModel[]): void => {
        const state = clone(changeHistory);
        const indexState = clone(currentIndex);
        for (let page of pages) {
            if (!page.draft) continue; //Only draft pages have history
            state[page.page_id] = [
                {
                    displayMessage: `${t<ILang>("default_state")}`,
                    domType: "Page",
                    page_id: page.page_id,
                    state: page.draft.draft_elements.dom,
                    timestamp: page.draft.draft_elements.getUpdatedDate(),
                    lang: language
                }
            ];
            if (!indexState[page.page_id]) indexState[page.page_id] = 0;
        }
        updateIndexState(indexState);
        setChangeHistory(state);
    };

    const createHistory = (data: IChangeData): void => {
        const clonedState = clone(changeHistory);
        if (!clonedState[data.page_id]) clonedState[data.page_id] = [];
        clonedState[data.page_id].unshift({ ...data, timestamp: new Date(), lang: language });
        if (clonedState[data.page_id].length > 20) {
            clonedState[data.page_id].pop();
        }
        setChangeHistory(clonedState);
        setCurrentIndex(data.page_id, 0);
    };

    const clearHistory = (pageIDs: string[]): void => {
        updateIndexState((current) => {
            const state = clone(changeHistory);
            const indexState = clone(current);
            for (let page_id of pageIDs) {
                delete state[page_id];
                delete indexState[page_id];
            }
            setChangeHistory(state);
            return indexState;
        });
    };

    return (
        <ChangeHistoryContext.Provider
            value={{
                createDefaultHistory,
                setChangeHistory,
                setCurrentIndex,
                createHistory,
                changeHistory,
                clearHistory,
                currentIndex
            }}
        >
            {props.children}
        </ChangeHistoryContext.Provider>
    );
};

export const useChangeHistory = (): IChangeHistoryContext => useContext(ChangeHistoryContext);
