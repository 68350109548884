import { Slugify } from "xa-generics";
import { IMultiSelectInputProps } from "../MultiSelect.view";

interface IProps {
    isOpen: boolean;
    search: string;
    requireTransform: boolean;
}

export const SelectOptionGenerate = <F extends object, O extends object, L = string>(
    props: IProps & IMultiSelectInputProps<F, O, L>
): { options: O[]; selectedValues: O[] } => {
    let selectedValues: O[] = [];
    const searchText = Slugify(props.search);
    const options: O[] = [];
    const idAccessor = props.idAccessor || ("id" as keyof O);
    const nameAccessor = props.nameAccessor || ("name" as keyof O);

    for (let index in props.options) {
        const option = props.options[index];
        //Gets the current selected value
        const value = option[idAccessor] as never as string;
        if (props.value.indexOf(value) > -1) selectedValues.push(option);

        if (props.isOpen) {
            //Filters the options as necessary if the menu is open and the search field isn't empty
            if (props.search) {
                let name = props.requireTransform
                    ? Slugify(option[nameAccessor] as never as string)
                    : (option[props.searchAccessor!] as never as string);

                //This builds a new array on re-render, but it's necessary for filtering
                if (name.indexOf(searchText) > -1) options.push(option);
                continue;
            }
            //else, it just renders the whole list as usual
            else options.push(option);
        }
    }
    return { options, selectedValues };
};
