import { CalcContrast, IPartialCSS, IsMobile } from "sitebuilder-common";
import { IProductsIndexProps } from "./Products.index";
import { FC, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useRestaurant } from "../../../../../Contexts/Restaurant.context";
import { Close, Search } from "@carbon/icons-react";
import { ColorShader } from "sitebuilder-common";
import { useDidMount } from "xa-generics";
import { TextInput } from "../../../../UI/InputFields/TextInput/TextInput.view";
import { ILang } from "../../../../../Interfaces/ILang.type";

interface IProductSearcherViewProps extends IProductsIndexProps {
    style: IPartialCSS<any>;
}

export const ProductSearcherView: FC<IProductSearcherViewProps> = (props) => {
    const { t } = useTranslation<ILang>();

    const ref = useRef<HTMLInputElement | null>(null);
    const { config } = useRestaurant();

    let style: React.CSSProperties | undefined = undefined;

    useDidMount(() => {
        if (ref.current && !IsMobile()) {
            ref.current.focus();
        }
    });

    const defineAmount = useMemo(() => {
        if (!props.style.backgroundColor) return 0;
        if (CalcContrast(props.style.backgroundColor, "#000000") > 50) return -25;
        if (CalcContrast(props.style.backgroundColor, "#000000") < 12) return 60;
        if (CalcContrast(props.style.backgroundColor, "#000000") < 20) return 25;

        return -25;
    }, [props.style.backgroundColor]);

    if (props.content.alwaysShowSearchBorder) {
        style = {
            borderColor: config.main_btn_bg_color,
            borderStyle: "solid",
            borderWidth: "0.2rem"
        };
    }

    const s: React.CSSProperties = {
        backgroundColor: ColorShader(defineAmount, props.style.backgroundColor)
    };
    if (props.content.hideTileBackground) {
        s.backgroundColor = "transparent";
        s.boxShadow = "none";
    }

    return (
        <div className="products__search" style={s}>
            <div
                style={{
                    color: props.style.color || config.main_btn_bg_color
                }}
                className="products__search--menu"
            >
                {t<ILang>("food_menu")}
            </div>
            <TextInput
                onChange={(value) => props.setFilter(value)}
                className={"products__search--filter"}
                placeholder={t<ILang>("product_search")}
                value={props.filter}
                focusRef={ref}
                style={style}
                onUnitClick={() => {
                    if (props.filter) props.setFilter("");
                    else ref.current?.focus();
                }}
                unit={<div className="search-svg">{props.filter ? <Close /> : <Search />}</div>}
                id={"filter"}
                type={"text"}
                noLabel
            />
        </div>
    );
};
