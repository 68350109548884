import { IPartialCSS, ParseComponentStyle } from "sitebuilder-common";
import { IVideoContent } from "../../Interfaces/IVideoComponent.interface";
import { HeightStyles } from "../../Static/GroupsStyles.static";
import { IDomElement } from "../../../DomTracker/Interfaces/IDomTypes.interface";
import { VideoStyles } from "../../Static/VideoStyles.static";
import { DnDEvents } from "../../Util/DND.util";
import { FC } from "react";
import Overlay from "../../View/Overlay.view";

interface IDomVideoProps extends IDomElement<IVideoContent> {}

export const DomVideo: FC<IDomVideoProps> = (props) => {
    const { refs, size, id, showOverlay, ...events } = DnDEvents<IVideoContent>(props, "ELEMENT");

    let style = ParseComponentStyle(props.content, VideoStyles, size);

    const iframeStyle: IPartialCSS<any> = {};
    for (let cssKey in style) {
        if (cssKey in HeightStyles) {
            iframeStyle[cssKey as never] = style[cssKey as never];
            delete style[cssKey as never];
        }
    }

    return (
        <div
            id={id}
            {...events}
            className="dom__video"
            ref={refs.current[props.uuid]}
            style={style}
        >
            {showOverlay && <Overlay parent={props} />}

            {props.content.youtube_url ? (
                <iframe
                    src={props.content.youtube_url}
                    title="YouTube video player"
                    className={"dom__video--player"}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    style={iframeStyle}
                    allowFullScreen
                />
            ) : (
                <div className="spacing"></div>
            )}
        </div>
    );
};
