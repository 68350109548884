import { FC } from "react";
import { useIFrame } from "../../Provider/IFrame.context";
import { ImageInput } from "../../../UI/InputFields/ImageInput/Controller/ImageInput.controller";
import { SelectInput } from "../../../UI/InputFields/Select/SelectInput.view";
import { CheckboxInput } from "../../../UI/InputFields/Checkbox/Checkbox.view";
import { BG_SIZE_OPTIONS } from "../../../UI/InputGroups/BackgroundImage/BGSizeOptions.static";
import { FrameEditorGroup } from "../IFrameEditorGroup.view";
import { FrameConfigsModel } from "sitebuilder-common";
import { BG_POSITION_OPTIONS } from "../../../UI/InputGroups/BackgroundImage/BGPositionOptions.static";
import { BG_ATTACHMENT_OPTIONS } from "../../../UI/InputGroups/BackgroundImage/BGAttachmentOptions.static";
import GradientPickerInput from "../../../UI/InputFields/GradientPicker/GradientPicker.view";
import ColorInput from "../../../UI/InputFields/ColorInput/ColorInput.view";

interface IFrameHeaderFormProps {}

export const FrameHeaderForm: FC<IFrameHeaderFormProps> = (props) => {
    const { forms } = useIFrame<"header">();
    const { editor, fieldErrors, handleChange } = forms.header;

    return (
        <FrameEditorGroup groupKey={"header"} nameKey={"iframe_header"}>
            <ImageInput
                aspectRatio={16 / 9}
                onChange={handleChange}
                id={"image"}
                labelText={"backgroundImage"}
                value={editor.image}
            />
            <SelectInput
                useTranslationOnName
                onChange={handleChange}
                id={"imageSize"}
                isClearable
                options={BG_SIZE_OPTIONS}
                labelText={"backgroundSize"}
                value={editor.imageSize}
            />
            <SelectInput
                useTranslationOnName
                onChange={handleChange}
                id={"imagePosition"}
                isClearable
                options={BG_POSITION_OPTIONS}
                labelText={"backgroundPosition"}
                value={editor.imagePosition}
            />
            <SelectInput
                useTranslationOnName
                onChange={handleChange}
                id={"imageAttachment"}
                isClearable
                options={BG_ATTACHMENT_OPTIONS}
                labelText={"backgroundAttachment"}
                value={editor.imageAttachment}
            />
            <CheckboxInput<FrameConfigsModel["header"]>
                onChange={handleChange}
                id={"imageRepeat"}
                value={editor.imageRepeat}
                labelText={"backgroundRepeat"}
                errors={fieldErrors}
            />
            <ColorInput
                onChange={handleChange}
                id={"color"}
                labelText={"color"}
                value={editor.color}
                errors={fieldErrors}
                withValueSync
                isClearable
            />
            <CheckboxInput
                onChange={handleChange}
                id={"useGradientHeader"}
                value={editor.useGradientHeader}
            />
            {editor.useGradientHeader ? (
                <GradientPickerInput
                    onChange={(i, value) => handleChange("gradient", value)}
                    background={editor.gradient}
                    currentUnit={"GRADIENT"}
                />
            ) : (
                <ColorInput
                    onChange={handleChange}
                    labelText={"backgroundColor"}
                    value={editor.backgroundColor}
                    id={"backgroundColor"}
                    errors={fieldErrors}
                    withValueSync
                    isClearable
                />
            )}
        </FrameEditorGroup>
    );
};
