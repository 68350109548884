import { ILang } from "../../../../../Interfaces/ILang.type";
import { useImages } from "../../../../../Contexts/Images.context";
import { isEmpty, map } from "lodash";
import { useTranslation } from "react-i18next";
import { IImageInputViewProps } from "./ImageInput.view";
import { Close, Upload, Checkbox, TrashCan, CheckboxIndeterminate } from "@carbon/icons-react";
import Loading from "../../../Loading/Loading.view";
import Button from "../../../Button/Button.view";
import Modal from "../../../Modal/Modal.view";

interface IImageSelectorProps<Fields extends object> extends IImageInputViewProps<Fields> {}

export const ImageSelector = <Fields extends object>(props: IImageSelectorProps<Fields>) => {
    const { t } = useTranslation<ILang>();
    const { images, massDelete, deleteImage, loading } = useImages();

    const id = props.id as string;
    const localid = `modal_input_${id}`;

    const imgCount = Object.keys(images).length;
    const imageList = Object.values(images);
    const copiedImages = Object.values(images).filter((img) => img.is_copied);
    const size = imgCount > 4 ? "LG" : imgCount > 3 ? "MD" : "SM";

    return (
        <>
            <Modal
                isPassive
                size={size}
                heading={t<ILang>("select_an_image")}
                onClose={() => props.setShowSelector(false)}
            >
                <div className="wrapper">
                    <div className="input-label">{t<ILang>("upload_an_image")}</div>
                    <label htmlFor={localid} className={"image-input__container --layout-1"}>
                        <div className="image-input__upload">
                            <Upload size={20} />
                        </div>
                        <input
                            id={localid}
                            name={localid}
                            type={"file"}
                            multiple={false}
                            accept={"image/*"}
                            className={"file-input"}
                            onChange={(e) => {
                                const files: FileList | null = e.target.files;
                                if (files && files.length > 0) props.onFileSelect(files[0]);
                            }}
                        />
                    </label>
                </div>

                <div className="image-input__controls">
                    {!isEmpty(props.selectedImages) && (
                        <Button
                            kind={"DANGER"}
                            Icon={TrashCan}
                            onClick={() => {
                                props.setDeleteWarnImgId(Object.values(props.selectedImages));
                            }}
                        >
                            {t<ILang>("delete_marked_images")}
                        </Button>
                    )}
                    {copiedImages.length > 0 ? (
                        <Button
                            kind={"DANGER"}
                            Icon={TrashCan}
                            onClick={() => {
                                props.setDeleteWarnImgId(copiedImages.map((img) => img.image_id));
                            }}
                        >
                            {t<ILang>("delete_copied_images")}
                        </Button>
                    ) : null}
                </div>

                <h5 className="image-input__title">{t<ILang>("your_uploaded_images")}</h5>

                <div className={"image-input__image-selector"}>
                    {map(imageList, (image, index) => {
                        const classes: string[] = ["image-instance"];
                        const isMarked = image.image_id in props.selectedImages;
                        if (image.image_id === props.value) classes.push("--active");
                        if (isMarked) classes.push("--marked");
                        if (image.is_copied) classes.push("--copied");

                        return (
                            <div
                                key={`${index}-image-${id}`}
                                className={classes.join(" ")}
                                onClick={() => {
                                    props.onChange(props.id, image.image_id);
                                    props.setShowSelector(false);
                                    props.setInternalFile(image.image_id);
                                    props.setFileSrc("");
                                }}
                            >
                                <div className="image-instance__img-container">
                                    <img
                                        src={image.url}
                                        alt={image.parsedName}
                                        title={image.parsedName}
                                        className="image-instance__image"
                                    />
                                </div>
                                {image.is_copied && (
                                    <div className="image-instance__copied-text">
                                        {t<ILang>("copied_image")}
                                    </div>
                                )}
                                <div
                                    className="image-instance__image-mark"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        props.onImageSelect(image.image_id);
                                    }}
                                >
                                    {isMarked ? <CheckboxIndeterminate /> : <Checkbox />}
                                </div>
                                <div
                                    className="image-instance__image-delete"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        props.setDeleteWarnImgId(image.image_id);
                                    }}
                                >
                                    <Close />
                                </div>
                                <div className="image-instance__name">{image.parsedName}</div>
                            </div>
                        );
                    })}
                </div>
                <Loading loading={loading} isInline />
                {imgCount === 0 ? (
                    <div className="no-data">{t<ILang>("no_images_to_display")}</div>
                ) : null}
            </Modal>
            {props.deleteWarnImgId && (
                <Modal
                    heading={t<ILang>("warning")}
                    submitIcon={TrashCan}
                    onSubmit={() => {
                        if (props.deleteWarnImgId instanceof Array) {
                            massDelete(props.deleteWarnImgId);
                        } else deleteImage(props.deleteWarnImgId as string);
                        props.setDeleteWarnImgId(null);
                    }}
                    onClose={() => props.setDeleteWarnImgId(null)}
                    submitText={t<ILang>("delete")}
                    size={"XS"}
                    isDanger
                >
                    {t<ILang>("image_delete_warn")}
                </Modal>
            )}
        </>
    );
};
