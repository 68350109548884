import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useCart } from "../Provider/Cart.provider";
import { CartRadioRow } from "./CartRadioRow.view";
import { useTranslation } from "react-i18next";

interface ICartPaymentSplitProps {}

export const CartPaymentSplit: FC<ICartPaymentSplitProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { form } = useCart();

    return (
        <div className={"cart-block cart__payment-split"}>
            <h4 className="cart__title">{t<ILang>("payment_split")}</h4>
            <CartRadioRow
                labelText={"first_time"}
                isSelected={form.editor.payment_split}
                onClick={() => form.handleChange("payment_split", true)}
            />
            <CartRadioRow
                isSelected={!form.editor.payment_split}
                labelText={"each_delivery"}
                onClick={() => form.handleChange("payment_split", false)}
            />
        </div>
    );
};
