import { FC } from "react";
import { ILang } from "../../../Interfaces/ILang.type";
import { useApp } from "../Provider/App.context";
import { useTranslation } from "react-i18next";
import { CaretDown, CaretUp } from "@carbon/icons-react";
import { IActiveAppEditor } from "../Interfaces/IActiveFrameEditor.type";

interface IAppEditorGroupProps {
    nameKey: ILang;
    groupKey: IActiveAppEditor;
}

export const AppEditorGroup: FC<IAppEditorGroupProps> = (props) => {
    const { activeEditor, onSetActiveEditor } = useApp();
    const { t } = useTranslation<ILang>();
    const classes: string[] = ["app-editor-group"];

    const isOpen = activeEditor === props.groupKey;
    if (isOpen) classes.push("active-group");

    return (
        <div className={classes.join(" ")}>
            <div
                className="app-editor-group__head"
                onClick={() => onSetActiveEditor(isOpen ? "" : props.groupKey)}
            >
                <div className="app-editor-group__head--name">{t<ILang>(props.nameKey)}</div>
                <div className="app-editor-group__head--control">
                    {isOpen ? (
                        <CaretUp size={24} className={"icon"} />
                    ) : (
                        <CaretDown size={24} className={"icon"} />
                    )}
                </div>
            </div>
            <div className="app-editor-group__body">{isOpen ? props.children : null}</div>
        </div>
    );
};
