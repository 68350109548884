import { axios, IDynamicObject } from "xa-generics";
import { AXIOS_NAMES } from "../Static/AxiosInstanceNames.static";
import { AccessModel } from "sitebuilder-common";
import { ILoginForm } from "../Components/WidgetModules/Login/Interfaces/ILoginForm.interface";

export abstract class AccessDAO {
    private static readonly base: string = "/api/v3/admins";

    public static async login(data: ILoginForm): Promise<AccessModel> {
        const request = await axios
            .getInstance(AXIOS_NAMES.ONEMIN)
            .post<IDynamicObject<any>>(`${this.base}/sign_in`, {
                admin: data
            });
        return new AccessModel(request.data, request.headers.authorization);
    }

    public static async logout(): Promise<void> {
        await axios.getInstance(AXIOS_NAMES.ONEMIN).delete<void>(`${this.base}/sign_out`);
        return;
    }
}
