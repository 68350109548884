import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { TextInput } from "../../../UI/InputFields/TextInput/TextInput.view";
import { AddressModel } from "../../../../Models/Address.model";
import { ClientButton } from "../../../DomMapper/DomComponents/Button/ClientButton.view";
import { useTranslation } from "react-i18next";
import { IProfileIndexProps } from "./Profile.index";
import { Add, Close, Edit, Save, TrashCan } from "@carbon/icons-react";

interface IProfileAddressesViewProps extends IProfileIndexProps {}

export const ProfileAddressesView: FC<IProfileAddressesViewProps> = (props) => {
    const { t } = useTranslation<ILang>();

    const editor = props.addressForm.editor;
    const handleChange = props.addressForm.handleChange;

    return (
        <div className={"profile__addresses"}>
            <h4 className="profile__addresses--title">
                {t<ILang>(editor && !editor.id ? "new_address" : "addresses")}
                {!editor &&
                    (props.v2Btn ? (
                        <props.v2Btn
                            Icon={<Add />}
                            onClick={() => props.addressForm.setEditor(new AddressModel())}
                        >
                            {t<ILang>("add_new")}
                        </props.v2Btn>
                    ) : (
                        <ClientButton
                            RightIcon={Add}
                            onClick={() => props.addressForm.setEditor(new AddressModel())}
                        >
                            {t<ILang>("add_new")}
                        </ClientButton>
                    ))}
            </h4>
            {editor ? (
                <form onSubmit={props.onSaveAddress} className={"profile__addresses--form"}>
                    <TextInput<AddressModel>
                        required
                        id={"postcode"}
                        labelText={"zipcode"}
                        value={editor.postcode}
                        onChange={handleChange}
                    />
                    <TextInput<AddressModel>
                        required
                        id={"street"}
                        value={editor.street}
                        onChange={handleChange}
                    />
                    <TextInput<AddressModel>
                        required
                        id={"premise_number"}
                        value={editor.premise_number}
                        onChange={handleChange}
                    />
                    <TextInput<AddressModel>
                        id={"floor"}
                        value={editor.floor}
                        onChange={handleChange}
                    />
                    <TextInput<AddressModel>
                        id={"door_number"}
                        value={editor.door_number}
                        onChange={handleChange}
                    />
                    <TextInput<AddressModel>
                        id={"doorbell"}
                        value={editor.doorbell}
                        onChange={handleChange}
                    />
                    <div className="profile__addresses--form--controls">
                        {props.v2Btn ? (
                            <props.v2Btn
                                Icon={<Close />}
                                isSecondary
                                onClick={() => props.addressForm.setEditor(null)}
                            >
                                {t<ILang>("cancel")}
                            </props.v2Btn>
                        ) : (
                            <ClientButton
                                type={"button"}
                                RightIcon={Close}
                                isSecondary
                                onClick={() => props.addressForm.setEditor(null)}
                            >
                                {t<ILang>("cancel")}
                            </ClientButton>
                        )}
                        {props.v2Btn ? (
                            <props.v2Btn type={"submit"} Icon={<Save />}>
                                {t<ILang>("save")}
                            </props.v2Btn>
                        ) : (
                            <ClientButton type={"submit"} RightIcon={Save}>
                                {t<ILang>("save")}
                            </ClientButton>
                        )}
                    </div>
                </form>
            ) : (
                <>
                    {props.addresses.map((address, index) => (
                        <div
                            className="profile__addresses--address"
                            key={`${index}-${address.id}-address`}
                        >
                            <span className="name">{address.toString()}</span>

                            <div className="controls">
                                <Edit
                                    className="icon"
                                    onClick={() => props.addressForm.setEditor(address)}
                                />
                                <TrashCan
                                    className="icon"
                                    onClick={() => props.onDeleteAddress(address.id)}
                                />
                            </div>
                        </div>
                    ))}
                    {props.addresses.length === 0 && (
                        <div className="no-data">{t<ILang>("no_data")}</div>
                    )}
                </>
            )}
        </div>
    );
};
