import { IAllDomTypes } from "../Interfaces/IDomTypes.interface";
import { PageDataModel } from "../Model/PageData.model";

export const FindNodeByUUID = (
    pages: PageDataModel[] | PageDataModel,
    uuid: string
): IAllDomTypes => {
    let node!: IAllDomTypes;
    const findInList = (list: (IAllDomTypes | PageDataModel)[]) => {
        for (let item of list) {
            if (item instanceof PageDataModel) {
                if (item.draft) findInList(item.draft.draft_elements.dom);
                else continue;
            } else {
                if (item.uuid === uuid) {
                    node = item;
                    break;
                }

                if (!("elements" in item)) continue;
                findInList(item.elements);
            }
        }
    };
    if (pages instanceof Array) {
        findInList(pages);
    } else {
        const page = pages as PageDataModel;
        findInList(page.draft!.draft_elements.dom);
    }
    return node;
};
