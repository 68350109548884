import {
    UserCertification,
    ShoppingCart,
    Information,
    Restaurant,
    ResultOld,
    QrCode,
    Logout,
    Login,
    User,
    Home,
    Menu,
    Star
} from "@carbon/icons-react";
import { NavLink, useLocation, useMatch } from "react-router-dom";
import { DEF_BG, DEF_COLOR } from "../../../Static/IFrameBaseColors.static";
import { GetBackLocation } from "../Utils/BackLocation.util";
import { AppShoppingCart } from "../../UI/SVG/AppShoppingCart.svg";
import { useTranslation } from "react-i18next";
import { useRestaurant } from "../../../Contexts/Restaurant.context";
import { AppBackArrow } from "../../UI/SVG/AppBackArrow.svg";
import { useSwipeable } from "react-swipeable";
import { FONT_OPTIONS } from "sitebuilder-common";
import { FC, useState } from "react";
import { useProducts } from "../../DomMapper/DomComponents/Products/Context/ProductsContext.provider";
import { WidgetLogin } from "../../WidgetModules/Login/Controller/Login.controller";
import { IMenuProps } from "../Interfaces/IMenuProps.interface";
import { CustomSVG } from "../../UI/SVG/Custom.svg";
import { GetColors } from "../Utils/GetColors.util";
import { useImages } from "../../../Contexts/Images.context";
import { Slugify } from "xa-generics";
import { useApp } from "../Provider/App.context";
import { ILang } from "../../../Interfaces/ILang.type";

interface IAppNavbarProps extends IMenuProps {}

export const AppNavbar: FC<IAppNavbarProps> = (props) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(true);
    const [showLogin, setShowLogin] = useState<boolean>(false);

    const { data, dataSource } = useApp();
    const { restaurant } = useRestaurant();
    const { t, i18n } = useTranslation<ILang>();
    const { pathname } = useLocation();
    const { catObject } = useProducts();
    const { images } = useImages();
    const app = data[dataSource];

    const setIsOpen = props.setIsMenuOpen;
    const isOpen = props.isMenuOpen;

    const handlers = useSwipeable({
        onSwipedLeft: (e) => props.setIsMenuOpen(false),
        delta: 60
    });

    const cat = "/order/categories/:categoryId";
    const prod = `${cat}/products/:productId`;
    const categoryMatch = useMatch(cat);
    const prodMatch = useMatch(prod);
    const theme = GetColors(app);

    const classes = ["app-navbar"];
    const sidemenu = ["mobileapp-sidemenu"];

    const style: React.CSSProperties = {
        backgroundColor: theme.backgroundColor,
        color: theme.color
    };

    const sidemenuStyle: React.CSSProperties = {
        backgroundRepeat: app.sidemenu.backgroundRepeat ? "repeat" : "no-repeat",
        backgroundColor: app.sidemenu.backgroundColor || app.header.backgroundColor || undefined,
        color: app.sidemenu.color || app.header.color || undefined,
        backgroundAttachment: app.sidemenu.backgroundAttachment,
        backgroundPosition: app.sidemenu.backgroundPosition,
        backgroundImage: app.sidemenu.backgroundImage
            ? `url(${images[app.sidemenu.backgroundImage].url})`
            : undefined,
        backgroundSize: app.sidemenu.backgroundSize,
        backdropFilter: app.sidemenu.filter
    };

    const activeStyle = {
        backgroundColor: app.buttons.backgroundColor || DEF_BG,
        color: app.buttons.color || DEF_COLOR
    };

    const buttonStyle = {
        backgroundColor: sidemenuStyle.backgroundColor,
        color: sidemenuStyle.color,
        backdropFilter: sidemenuStyle.filter
    };

    const font = FONT_OPTIONS.find((opt) => opt.id === app.global.fontFamily);
    if (font) {
        classes.push(font.class);
        sidemenu.push(font.class);
    } else {
        const lastIndex = FONT_OPTIONS.length - 1;
        classes.push(FONT_OPTIONS[lastIndex].class);
        sidemenu.push(FONT_OPTIONS[lastIndex].class);
    }
    if (app.global.qr_cart_mode) classes.push("--WITH_QR_SCANNER");
    if (app.global.isDarkTheme) {
        classes.push("dark-navbar");
        sidemenu.push("dark-sidemenu");
    }
    if (app.header.backgroundColor) style.backgroundColor = app.header.backgroundColor;
    if (app.header.color) style.color = app.header.color;
    if (app.product.version === "V2" && pathname.indexOf("/order") > -1)
        classes.push("--NO_SHADOW");

    if (isOpen) sidemenu.push("--OPENED");

    const getLink = (
        path: string,
        icon: JSX.Element,
        text: ILang,
        extraHash?: string
    ): JSX.Element => {
        let isActive = pathname === path;
        if (pathname.indexOf(path) > -1) isActive = true;
        let pathTo = path;
        if (extraHash) pathTo += extraHash;
        return (
            <NavLink
                to={pathTo}
                onClick={() => setIsOpen(false)}
                className={"mobileapp-sidemenu__link"}
                style={isActive ? activeStyle : undefined}
            >
                {icon} <span className="text">{t<ILang>(text)}</span>
            </NavLink>
        );
    };

    let items = 12;

    return (
        <>
            <nav className={classes.join(" ")} style={style}>
                {pathname === "/order" ||
                (pathname.indexOf("/order") > -1 &&
                    pathname.indexOf("/products") === -1 &&
                    app.product.version === "V2") ? (
                    <button className="app-navbar__control" onClick={() => setIsOpen(true)}>
                        {app.header.menuSVG ? (
                            <CustomSVG key={"menu"} svgString={app.header.menuSVG} />
                        ) : (
                            <Menu size={32} />
                        )}
                    </button>
                ) : (
                    <NavLink
                        className="app-navbar__control"
                        to={GetBackLocation(pathname, categoryMatch, catObject, prodMatch)}
                    >
                        {app.header.backSVG ? (
                            <CustomSVG key={"back"} svgString={app.header.backSVG} />
                        ) : (
                            <AppBackArrow size={24} />
                        )}
                    </NavLink>
                )}
                <div className="app-navbar__name">
                    {app.header.backgroundImage && images[app.header.backgroundImage]?.url && (
                        <img
                            alt={"LOGO"}
                            src={images[app.header.backgroundImage].url}
                            className="app-navbar__name--logo"
                        />
                    )}
                    {app.header.isNameHidden ? null : app.global.displayedName || restaurant.name}
                </div>
                {app.global.qr_cart_mode && (
                    <button
                        style={{ color: app.header.color || theme.color }}
                        className={"app-navbar__control"}
                    >
                        {app.global.qrScanSVG ? (
                            <CustomSVG key={"qrScanSVG"} svgString={app.global.qrScanSVG} />
                        ) : (
                            <QrCode size={32} />
                        )}
                    </button>
                )}
                <NavLink
                    to={"/cart"}
                    className="app-navbar__control"
                    style={{ color: theme.backgroundColor }}
                >
                    {app.header.cartSVG ? (
                        <CustomSVG key={"cart"} svgString={app.header.cartSVG} />
                    ) : (
                        <AppShoppingCart size={44} />
                    )}
                    {items > 0 ? (
                        <div className={"app-navbar__control--item-count"}>{items}</div>
                    ) : null}
                </NavLink>
            </nav>
            <nav className={sidemenu.join(" ")} onClick={(e) => setIsOpen(false)} {...handlers}>
                <div className="content" onClick={(e) => e.stopPropagation()} style={sidemenuStyle}>
                    <img
                        className={"mobileapp-sidemenu__image"}
                        src={images[app.header.image]?.url}
                        style={{
                            maxWidth: app.header.maxImageWidth
                                ? `${app.header.maxImageWidth}%`
                                : "100%"
                        }}
                        alt="LOGO"
                    />

                    {getLink(
                        "/order",
                        app.sidemenu.orderSVG ? (
                            <CustomSVG svgString={app.sidemenu.orderSVG} />
                        ) : (
                            <Restaurant size={32} />
                        ),
                        "order"
                    )}
                    {getLink(
                        "/info",
                        app.sidemenu.infoSVG ? (
                            <CustomSVG svgString={app.sidemenu.infoSVG} />
                        ) : (
                            <Information size={32} />
                        ),
                        "about_us"
                    )}
                    {getLink(
                        "/cart",
                        app.sidemenu.cartSVG ? (
                            <CustomSVG svgString={app.sidemenu.cartSVG} />
                        ) : (
                            <ShoppingCart size={32} />
                        ),
                        "cart"
                    )}
                    {isLoggedIn && (
                        <>
                            {getLink(
                                "/profile/my-data",
                                app.sidemenu.userSVG ? (
                                    <CustomSVG svgString={app.sidemenu.userSVG} />
                                ) : (
                                    <User size={32} />
                                ),
                                "profile",
                                `#${Slugify(t<ILang>("my_data"))}`
                            )}
                            {(restaurant.preorder_delivery || restaurant.normal_delivery) &&
                                getLink(
                                    "/profile/addresses",
                                    app.sidemenu.addressesSVG ? (
                                        <CustomSVG svgString={app.sidemenu.addressesSVG} />
                                    ) : (
                                        <Home size={32} />
                                    ),
                                    "addresses",
                                    `#${Slugify(t<ILang>("addresses"))}`
                                )}
                            {getLink(
                                "/profile/past-orders",
                                app.sidemenu.prevOrdersSVG ? (
                                    <CustomSVG svgString={app.sidemenu.prevOrdersSVG} />
                                ) : (
                                    <ResultOld size={32} />
                                ),
                                "past_orders",
                                `#${Slugify(t<ILang>("past_orders"))}`
                            )}
                            {getLink(
                                "/profile/customer-points",
                                app.sidemenu.pointsSVG ? (
                                    <CustomSVG svgString={app.sidemenu.pointsSVG} />
                                ) : (
                                    <UserCertification size={32} />
                                ),
                                "customer_points",
                                `#${Slugify(t<ILang>("customer_points"))}`
                            )}
                        </>
                    )}

                    {(app.global.extraPageContent || app.global.extraPageLink) &&
                    app.global.extraPageLink ? (
                        <a
                            className="mobileapp-sidemenu__link"
                            href={app.global.extraPageLink}
                            onClick={() => setIsOpen(false)}
                            style={pathname === "/content" ? activeStyle : {}}
                        >
                            <Star size={32} />{" "}
                            <span className="text">
                                {i18n.language === restaurant.primary_language
                                    ? app.global.extraPageTitlePrimary
                                    : app.global.extraPageTitleSecondary ||
                                      app.global.extraPageTitlePrimary}
                            </span>
                        </a>
                    ) : (
                        <NavLink
                            to="/content"
                            className="mobileapp-sidemenu__link"
                            onClick={() => setIsOpen(false)}
                            style={pathname === "/content" ? activeStyle : {}}
                        >
                            <Star size={32} />{" "}
                            <span className="text">
                                {i18n.language === restaurant.primary_language
                                    ? app.global.extraPageTitlePrimary
                                    : app.global.extraPageTitleSecondary ||
                                      app.global.extraPageTitlePrimary}
                            </span>
                        </NavLink>
                    )}

                    {app.login.isLoginDisabled ? null : !isLoggedIn ? (
                        <button
                            style={buttonStyle}
                            onClick={() => setShowLogin(true)}
                            className={"mobileapp-sidemenu__link --LOGIN"}
                        >
                            {app.sidemenu.userSVG ? (
                                <CustomSVG svgString={app.sidemenu.userSVG} />
                            ) : (
                                <Login size={32} />
                            )}{" "}
                            <span className="text">{t<ILang>("login")}</span>
                        </button>
                    ) : (
                        <button
                            style={buttonStyle}
                            onClick={() => setIsLoggedIn(false)}
                            className={"mobileapp-sidemenu__link --LOGOUT"}
                        >
                            {app.sidemenu.logoutSVG ? (
                                <CustomSVG svgString={app.sidemenu.logoutSVG} />
                            ) : (
                                <Logout size={32} />
                            )}{" "}
                            <span className="text">{t<ILang>("logout")}</span>
                        </button>
                    )}
                </div>
            </nav>
            {showLogin && (
                <WidgetLogin
                    app={app.login}
                    onClose={() => setShowLogin(false)}
                    onSubmit={() => {
                        setIsLoggedIn(true);
                        setShowLogin(false);
                    }}
                />
            )}
        </>
    );
};
