import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { useCart } from "../../Cart/Provider/Cart.provider";
import { useWidgets } from "../../../Widgets/Controller/WidgetsContext.provider";
import { IPartialCSS } from "sitebuilder-common";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import { GetWidgetOverlay } from "../../Util/WidgetOverlay.util";
import { IThankYouSettings } from "../Interfaces/IThankYouWidget.interface";
import { ThankYouStatusElement } from "./ThankYouStatus.element";
import {
    GetCurrency,
    RenderPrice
} from "../../../DomMapper/DomComponents/Products/Utils/GetCurrency.util";
import WidgetOverlay from "../../../Widgets/View/WidgetOverlay.view";
import MapImage from "../../../../Assets/map.png";
import moment from "moment";

interface IThankYouViewProps {}

export const ThankYouView: FC<IThankYouViewProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { size } = useDom();
    const { order } = useCart();
    const currency = GetCurrency();
    const { currentFont, config } = useRestaurant();
    const { widgets } = useWidgets();

    const { showOverlay, ...events } = GetWidgetOverlay<IThankYouSettings>(
        "ThankYou",
        "isTrackerVisible"
    );

    const style: IPartialCSS<any> = {};
    if (config.main_btn_bg_color) {
        style.color = config.main_btn_bg_color;
    }
    const classes: string[] = ["ty", `ty-${size}`, currentFont];

    return (
        <div className={classes.join(" ")} {...events}>
            {showOverlay && (
                <WidgetOverlay<IThankYouSettings>
                    isMain
                    align={"center"}
                    parent={widgets.ThankYou}
                />
            )}
            {widgets.ThankYou.draftSettings.isTrackerVisible && (
                <div className="ty-container">
                    <h1 className="ty__title">{t<ILang>("thank_you_title")}!</h1>
                    <h6 className="ty__sub-title">{t<ILang>("track_order_here")}</h6>
                    <div className="ty__tracking">
                        <div className="ty__tracking--image">
                            <img src={MapImage} alt="Google Maps" className="ty__tracking--image" />
                            <div className="ty__tracking--image--text">
                                {t<ILang>("tracking_img_text")}
                            </div>
                        </div>
                        <div className="ty__tracking--details">
                            <div className="ty__tracking--details--table details-table">
                                <div className="details-table__head">
                                    <div className="details-table__head--left">
                                        <span className="label">
                                            {t<ILang>("expected_delivery_time")}
                                        </span>
                                        <span className="value">{t<ILang>("calculating")}</span>
                                    </div>
                                    <div className="details-table__head--right">
                                        {t<ILang>("delivery_accuracy")}
                                    </div>
                                </div>
                                <div className="details-table__block">
                                    <span className="label">{t<ILang>("elapsed_time")}</span>
                                    <span className="value">38 perc</span>
                                </div>
                                <div className="details-table__block">
                                    <span className="label">{t<ILang>("orders_before_you")}</span>
                                    <span className="value">3</span>
                                </div>
                                <div className="details-table__block">
                                    <span className="label">{t<ILang>("your_order_number")}</span>
                                    <span className="value">4</span>
                                </div>
                            </div>

                            <div className="ty__tracking--details--statuses">
                                <ThankYouStatusElement
                                    isCompleted
                                    style={style}
                                    text={"order_status_1"}
                                />
                                <div className="ty__tracking--details--statuses--connector"></div>
                                <ThankYouStatusElement
                                    isCompleted
                                    style={style}
                                    text={"order_status_2"}
                                />
                                <div className="ty__tracking--details--statuses--connector"></div>
                                <ThankYouStatusElement
                                    isCompleted
                                    style={style}
                                    text={"order_status_3"}
                                />
                                <div className="ty__tracking--details--statuses--connector"></div>
                                <ThankYouStatusElement style={style} text={"order_status_4"} />
                                <div className="ty__tracking--details--statuses--connector"></div>
                                <ThankYouStatusElement style={style} text={"order_status_5"} />
                                <div className="ty__tracking--details--statuses--connector"></div>
                                <ThankYouStatusElement style={style} text={"order_status_6"} />
                                <div className="ty__tracking--details--statuses--connector"></div>
                                <ThankYouStatusElement style={style} text={"order_status_7"} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="ty-container">
                <h6 className="ty__sub-title">{t<ILang>("order_details")}</h6>

                <div className="ty__details">
                    <div className="ty__details-container">
                        <div className="ty__details--data">
                            <span className="label">{t<ILang>("order_id")}</span>
                            <span className="value">{order.id}</span>
                        </div>
                        <div className="ty__details--data">
                            <span className="label">{t<ILang>("order_datetime")}</span>
                            <span className="value">{moment().format("YYYY. MM. DD HH:mm")}</span>
                        </div>
                        <div className="ty__details--data">
                            <span className="label">{t<ILang>("name")}</span>
                            <span className="value">{order.name}</span>
                        </div>
                        <div className="ty__details--data">
                            <span className="label">{t<ILang>("phone_number")}</span>
                            <span className="value">{order.phone_1}</span>
                        </div>
                        <div className="ty__details--data full-width-data">
                            <span className="label">{t<ILang>("address")}</span>
                            <span className="value">
                                {order.postcode}, {order.street} {order.premise_number}.{" "}
                                {order.floor}
                                {", "}
                                {order.door_number}. {t<ILang>("door_number")}
                            </span>
                        </div>
                        <div className="ty__details--data">
                            <span className="label">{t<ILang>("payment_method_id")}</span>
                            <span className="value">{t<ILang>("simplepay")}</span>
                        </div>
                        <div className="ty__details--data">
                            <span className="label">{t<ILang>("comment")}</span>
                            <span className="value comment">
                                Valami példa megjegyzés / Example comment
                            </span>
                        </div>
                    </div>
                    <div className="ty__details-prices">
                        <div className="ty__details--price">
                            <span className="label">{t<ILang>("partial_price")}</span>
                            <span className="value">
                                {RenderPrice(currency, order.total_price)}
                            </span>
                        </div>
                        <div className="ty__details--price">
                            <span className="label">{t<ILang>("delivery_price")}</span>
                            <span className="value">
                                {RenderPrice(currency, order.delivery_price)}
                            </span>
                        </div>
                        <div className="ty__details--price">
                            <span className="label">{t<ILang>("total_price")}</span>
                            <span className="value total-price">
                                {RenderPrice(currency, order.total_price)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
