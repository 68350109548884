import { Context, createContext, Dispatch, SetStateAction, useContext } from "react";
import { IWC, useAfterTriggerChanged } from "xa-generics";
import { OverflowMenuVertical } from "@carbon/icons-react";
import { createRef, FC } from "react";
import { useRestaurant } from "../../../Contexts/Restaurant.context";
import { useState } from "react";
import { DEF_BG2 } from "../../../Static/IFrameBaseColors.static";

interface IMenuContext {
    setIsOpened: Dispatch<SetStateAction<boolean>>;
    isOpened: boolean;
}
export const MenuContext: Context<IMenuContext> = createContext<IMenuContext>({
    isOpened: true,
    setIsOpened: null as any
});
MenuContext.displayName = "MenuContext";
export const useMenuContext = (): IMenuContext => {
    const ctx = useContext(MenuContext);
    if (ctx.setIsOpened === null) {
        console.error(
            "DEVELOPER ERROR: You need to put a 'ContextMenu' component above the MenuContext to use its consumer or the useMenuContext()!"
        );
    }
    return ctx;
};

export interface IContextMenuProps<TogglerInterface extends object = any> extends IWC {
    TogglerProps?: TogglerInterface;
    style?: React.CSSProperties;
    isDarkTheme?: boolean;
    isRounded?: boolean;
    onOpen?: () => void;
    Toggler?: FC<any>;
    id: string;
}

export const ContextMenu = <TogglerInterface extends object = any>({
    id,
    onOpen,
    Toggler,
    children,
    TogglerProps,
    ...props
}: IContextMenuProps<TogglerInterface>) => {
    const { config } = useRestaurant();
    const [isOpened, setIsOpened] = useState<boolean>(false);

    const handleClick = (e: MouseEvent): void => {
        const path = e.composedPath();
        let hasID: boolean = false;
        for (let target of path) {
            const targetID: string | undefined = (target as HTMLElement)?.id;
            if (targetID === `${id}-context`) hasID = true;
        }

        if (hasID) return;
        else setIsOpened(false);
    };

    const handleScroll = (e: Event): void => {
        if (isOpened) setIsOpened(false);
    };

    useAfterTriggerChanged(
        () => {
            if (isOpened) {
                window.addEventListener("scroll", handleScroll, { passive: true });
                window.addEventListener("click", handleClick, { passive: true });
                if (onOpen) onOpen();
            }
        },
        [isOpened],
        () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("click", handleClick);
        }
    );

    const containerRef = createRef<HTMLDivElement>();

    useAfterTriggerChanged(() => {
        if (!containerRef.current || !isOpened) return;
        const rects = containerRef.current.getBoundingClientRect();
        const parent = containerRef.current.parentElement!.getBoundingClientRect();

        let left = rects.left - 10;
        const isOverMaxLeft = left + rects.width >= document.body.clientWidth;

        if (isOverMaxLeft) {
            const right = document.body.clientWidth - (left + rects.width);
            left = left + right - 20;
        }

        containerRef.current.style.left = `${left}px`;
        containerRef.current.style.top = `${parent.top + parent.height}px`;
    }, [isOpened]);

    let style: React.CSSProperties = {};

    const main = ["context-menu"];
    const classes: string[] = ["context-menu__toggler"];

    if (props.isDarkTheme) main.push("dark-context-menu");
    if (props.isRounded) main.push("rounded-context-menu");
    if (Toggler) classes.push("allow-growth");

    let backgroundColor = props.style?.backgroundColor;
    let color = props.style?.color;

    if (config || props.style) {
        if (!backgroundColor && !color && isOpened) color = config.main_btn_bg_color || DEF_BG2;
    }

    style.backgroundColor = backgroundColor;
    style.color = color;

    return (
        <MenuContext.Provider value={{ isOpened, setIsOpened }}>
            <div className={main.join(" ")} id={`${id}-context`}>
                {Toggler ? (
                    <div
                        style={style}
                        className={classes.join(" ")}
                        onClick={() => setIsOpened(!isOpened)}
                    >
                        <Toggler {...TogglerProps} />
                    </div>
                ) : (
                    <div
                        style={style}
                        className={classes.join(" ")}
                        onClick={() => setIsOpened(!isOpened)}
                    >
                        <OverflowMenuVertical size={20} />
                    </div>
                )}
                {isOpened && (
                    <div style={style} ref={containerRef} className="context-menu__container">
                        {children}
                    </div>
                )}
            </div>
        </MenuContext.Provider>
    );
};
