import { FC } from "react";
import { ILang } from "../../../Interfaces/ILang.type";
import { useDom } from "../../DomTracker/Controller/DomTracker.provider";
import { useAccess } from "../../../Contexts/Access.context";
import { useTemplate } from "../Provider/Template.provider";
import { WithConfirm } from "../../UI/Confirm/Confirm.view";
import { useTranslation } from "react-i18next";
import { useComponentDnD } from "../../ComponentDnD/Provider/ComponentDnD.provider";
import { Launch, NotebookReference, TrashCan } from "@carbon/icons-react";

interface ITemplateSectionsViewProps {
    isSuperUserView: boolean;
}

export const TemplateSectionsView: FC<ITemplateSectionsViewProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { accessModel } = useAccess();
    const { templateBlocks, loading, removeBlock, setDraggedTemplateDOM, mountTemplateBlock } =
        useTemplate();
    const { handleSectionDragEnd } = useComponentDnD();
    const { setDraggedNode, currentPage } = useDom();

    const classes: string[] = ["template-block"];
    if (currentPage.isCurrentPageDraft) classes.push("draggable-block");

    return (
        <>
            {props.isSuperUserView && (
                <h6 className="templates-view__title templates-view__blocks--title">
                    {t<ILang>("block_template")}
                </h6>
            )}

            {templateBlocks.map((model) => (
                <div
                    className={classes.join(" ")}
                    key={`${model.template_id}-template-block`}
                    draggable={currentPage.isCurrentPageDraft}
                    onDragStart={(e) => {
                        if (!currentPage.isCurrentPageDraft) {
                            return e.preventDefault();
                        }
                        setDraggedNode("Section");
                        setDraggedTemplateDOM(model);
                    }}
                    onDragEnd={(e) => handleSectionDragEnd(e, "Section")}
                >
                    <div className="template-block__controls">
                        {!props.isSuperUserView && (
                            <>
                                <a
                                    href={model.preview_url}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="template-block__controls--button"
                                    title={t<ILang>("view_it")}
                                >
                                    <Launch />
                                </a>
                                <button
                                    className="template-block__controls--button"
                                    title={t<ILang>("template_apply")}
                                    onClick={() => mountTemplateBlock(model)}
                                >
                                    <NotebookReference />
                                </button>
                            </>
                        )}
                        {accessModel.super_admin && props.isSuperUserView && (
                            <WithConfirm
                                allowEnterKeyDown
                                loading={loading}
                                onConfirm={() => removeBlock(model.template_id)}
                            >
                                <button
                                    className="template-block__controls--button"
                                    title={t<ILang>("delete")}
                                >
                                    <TrashCan />
                                </button>
                            </WithConfirm>
                        )}
                    </div>
                    <div className="template-block__id">
                        {t<ILang>("template_title")}: {model.template_id}
                    </div>
                    <div className="template-block__image-container">
                        <img
                            alt={"Preview"}
                            src={model.preview_url}
                            className="template-block__image-container--image"
                        />
                    </div>
                </div>
            ))}

            {!templateBlocks.length && !loading ? (
                <div className="no-data">{t<ILang>("no_data")}</div>
            ) : null}
        </>
    );
};
