import { RootDAO } from "./Restaurant.dao";
import { DictModel, TextModel } from "sitebuilder-common";
import { axios, IDynamicObject } from "xa-generics";

export abstract class TextsDAO extends RootDAO {
    public static async loadTexts(): Promise<TextModel[]> {
        const request = await axios.getInstance().get<IDynamicObject<any>[]>(`/api/texts`);
        return request.data.map((item) => new TextModel(item));
    }

    public static async saveText(text: TextModel): Promise<TextModel> {
        const request = await axios.getInstance().post<IDynamicObject<any>>(`/api/texts`, text);
        return new TextModel(request.data);
    }

    public static async updateText(text: TextModel): Promise<TextModel> {
        const request = await axios
            .getInstance()
            .patch<IDynamicObject<any>>(`/api/texts/${text.text_id}`, text);
        return new TextModel(request.data);
    }

    public static async deleteText(textId: string): Promise<void> {
        await axios.getInstance().delete(`/api/texts/${textId}`);
    }

    public static async loadDictionary(): Promise<DictModel[]> {
        const request = await axios
            .getInstance()
            .get<IDynamicObject<any>[]>(`${this.baseUrl()}/dictionaries`);
        return request.data.map((model) => new DictModel(model));
    }

    public static async saveDictionary(dictionary: DictModel): Promise<DictModel> {
        const request = await axios
            .getInstance()
            .post<IDynamicObject<any>>(`${this.baseUrl()}/dictionaries`, dictionary.toAPIData);
        return new DictModel(request.data);
    }

    public static async updateDictionary(dictionary: DictModel): Promise<void> {
        await axios
            .getInstance()
            .patch<void>(
                `${this.baseUrl()}/dictionaries/${dictionary.dictionary_id}`,
                dictionary.toAPIData
            );
        return;
    }

    public static async deleteDictionary(dictionaryId: string): Promise<void> {
        await axios.getInstance().delete(`${this.baseUrl()}/dictionaries/${dictionaryId}`);
    }
}
