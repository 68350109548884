import { ProductsContextProvider } from "../../DomMapper/DomComponents/Products/Context/ProductsContext.provider";
import { useMountWithTriggers } from "xa-generics";
import { AppContainerView } from "./AppContainer.view";
import { AppSidemenuView } from "./AppSidemenu.view";
import { IMenuProps } from "../Interfaces/IMenuProps.interface";
import { GetColors } from "../Utils/GetColors.util";
import { useApp } from "../Provider/App.context";
import { FC } from "react";

interface IAppLayoutProps extends IMenuProps {}

export const AppLayout: FC<IAppLayoutProps> = (props) => {
    const { data, dataSource } = useApp();

    const classes = ["app-container"];
    if (data[dataSource].global.isRounded) classes.push("rounded-corners");
    if (data[dataSource].global.isDarkTheme) classes.push("dark-theme-app");

    useMountWithTriggers(() => {
        const style = document.createElement("style");
        const theme = GetColors(data[dataSource]);
        style.innerHTML = `
            ::-webkit-scrollbar {
                width: 2px;
            }
            ::-webkit-scrollbar-track {
                width: 2px;
                background-color: transparent !important;
            }
            ::-webkit-scrollbar-thumb {
                background-color: ${theme.backgroundColor} !important;
            }
        `;
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        };
    }, [data, dataSource]);

    return (
        <ProductsContextProvider>
            <div className={classes.join(" ")}>
                <AppSidemenuView {...props} />
                <div className="app-editor-view">
                    <AppContainerView {...props}>{props.children}</AppContainerView>
                </div>
            </div>
        </ProductsContextProvider>
    );
};
