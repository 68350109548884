import { IError, ILoading, SetCookie, useDidMount, AuthCookieName } from "xa-generics";
import { useState, createContext, Fragment, useContext } from "react";
import { useLocation, useNavigate } from "react-router";
import { TOKEN_EXPIRE_DAYS } from "../Static/TokenExpires.static";
import { IAccessContext } from "../Interfaces/IAccessContext.interface";
import { GetBuilderType } from "../Utils/GetBuilderType.util";
import { IBuilderTypes } from "../Interfaces/IBuilder.type";
import { PermissionDAO } from "../DAO/Permission.dao";
import { AccessModel } from "sitebuilder-common";
import { ILoginForm } from "../Components/WidgetModules/Login/Interfaces/ILoginForm.interface";
import { AccessDAO } from "../DAO/Access.dao";
import { LS } from "../Static/LS.static";
import Loading from "../Components/UI/Loading/Loading.view";
import Cookies from "js-cookie";
import qs from "qs";

/**
 * # Access Context
 *
 * This is a context for user authentication.
 * The provider should placed on the top level in the index.tsx file.
 *
 * ### For usage in classes, you can:
 * ```
 * MyClass.contextType = AccessContext;
 * ```
 * and then access it in the class with `this.context.accessLevel["property"]`;
 *
 * ### In function components:
 * ```typescript
 * const accessContext: IAccessContext = React.useContext(AccessContext);
 * console.log(accessContext.accessLevel["property"]);
 * ```
 */
export const AccessContext: React.Context<IAccessContext> = createContext<IAccessContext>(
    null as never
);

AccessContext.displayName = "AccessContext";

interface IApplicationAccessProps {}

export const ApplicationAccess: React.FC<IApplicationAccessProps> = (props) => {
    const location = useLocation();
    const navigateTo = useNavigate();

    const [accessModel, setAccessModel] = useState<AccessModel>(new AccessModel());
    const [builderType] = useState<IBuilderTypes>(() => {
        const params = qs.parse(location.search.slice(1));
        const lastSaved = GetBuilderType(localStorage.getItem(LS.builderType) || "");
        if (typeof params === "object" && "builderType" in params) {
            return GetBuilderType(params.builderType as string);
        }
        return lastSaved;
    });
    const [isInitializing, setIsInitializing] = useState<ILoading>(true);
    const [loading, setLoading] = useState<ILoading>(false);
    const [error, setError] = useState<IError>(null);

    const autoLogin = async (): Promise<void> => {
        const cookieData = Cookies.get(AuthCookieName);
        const query = qs.parse(location.search.slice(1));
        let authData = cookieData ? (JSON.parse(cookieData) as AccessModel) : null;

        let token = "";
        //This will handle query param token in case it's passed to the page (from admin or pos redirect, for example...)
        if (query && typeof query.token === "string") {
            const expire = new Date();
            expire.setMinutes(expire.getMinutes() + 5);
            SetCookie(AuthCookieName, query, expire);
            token = query.token;
            if (typeof query.restaurant_id === "string") {
                localStorage.setItem(LS.selectedRestaurant, query.restaurant_id);
            }
            navigateTo("/");
        }
        if (!token && authData && typeof authData.token === "string") {
            token = authData?.token;
        }
        if (token) {
            const me = await PermissionDAO.me(token);
            SetCookie(AuthCookieName, me, TOKEN_EXPIRE_DAYS);
            setAccessModel(me);
        } else {
            Cookies.remove(AuthCookieName);
        }
        return;
    };
    useDidMount(() => {
        autoLogin()
            .catch((error) => {
                console.error("Failed auto login due to an error: ", error);
                Cookies.remove(AuthCookieName);
            })
            .finally(() => setIsInitializing(false));
    });

    const login = (loginData: ILoginForm): void => {
        setLoading(
            AccessDAO.login(loginData)
                .then((authModel) => {
                    if (error) setError(null);
                    SetCookie(AuthCookieName, authModel, TOKEN_EXPIRE_DAYS);
                    setAccessModel(authModel);
                })
                .catch((error: IError) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    const logout = (cb?: () => void): void => {
        setLoading(
            AccessDAO.logout()
                .then(() => {
                    Cookies.remove(AuthCookieName);
                    setAccessModel(new AccessModel());
                    if (cb) cb();
                })
                .catch((error: IError) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    return (
        <AccessContext.Provider
            value={{
                setAccessModel,
                builderType,
                accessModel,
                setError,
                loading,
                logout,
                login,
                error
            }}
        >
            <Fragment key={`is-initializing--${isInitializing}`}>
                {isInitializing ? (
                    <Loading size={"lg"} useSpacing isAbsolute isExternalConditional />
                ) : (
                    props.children
                )}
            </Fragment>
        </AccessContext.Provider>
    );
};

export const useAccess = (): IAccessContext => useContext(AccessContext);
