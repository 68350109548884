import { ILang } from "../../../../Interfaces/ILang.type";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { useWidgets } from "../../../Widgets/Controller/WidgetsContext.provider";
import { WidgetLogin } from "../../Login/Controller/Login.controller";
import { Login, User } from "@carbon/icons-react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { INavbarSettings } from "../Interfaces/INavbarWidget.interface";
import { GetWidgetOverlay } from "../../Util/WidgetOverlay.util";
import { getScaledIconSize } from "../Utils/ScaledIconSize.util";
import { WidgetRegistration } from "../../Registration/Controller/Registration.controller";
import { NavbarButtonStyles } from "../Static/NavbarButtonStyles.static";
import { ParseComponentStyle } from "sitebuilder-common";
import { Interval, useDidMount } from "xa-generics";
import WidgetOverlay from "../../../Widgets/View/WidgetOverlay.view";

interface IV2NavbarProfileProps {
    isSidemenu?: boolean;
}

export const V2NavbarProfile: FC<IV2NavbarProfileProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { size } = useDom();
    const { widgets } = useWidgets();

    const [showLogin, setShowLogin] = useState<boolean>(false);
    const [kind, setKind] = useState<boolean>(false);
    const [showRegistration, setShowRegistration] = useState<boolean>(false);

    const { navbarLoginButton } = widgets.Navbar.draftSettings;

    const { showOverlay, ...events } = GetWidgetOverlay<INavbarSettings>(
        "Navbar",
        "navbarLoginButton"
    );

    const style = ParseComponentStyle(navbarLoginButton, NavbarButtonStyles, size);
    const iconSize = getScaledIconSize(style);

    const text = kind ? t<ILang>("login") : t<ILang>("profile");
    const icon = kind ? (
        <Login size={iconSize} className={"v2navbar__profile--icon"} />
    ) : (
        <User size={iconSize} className={"v2navbar__profile--icon"} />
    );

    useDidMount(() => {
        Interval.setInt(
            () => {
                setKind((current) => !current);
            },
            5000,
            "login"
        );

        return () => {
            Interval.clearInt("login");
        };
    });

    return (
        <>
            <button
                {...events}
                style={style}
                className="v2navbar__profile"
                onClick={() => setShowLogin(true)}
            >
                {showOverlay && (
                    <WidgetOverlay<INavbarSettings>
                        align={"right"}
                        parent={widgets.Navbar}
                        subKey={"navbarLoginButton"}
                    />
                )}
                {props.isSidemenu && <div className="v2navbar__profile--text">{text}</div>}
                {icon}
            </button>
            {showLogin && (
                <WidgetLogin
                    onClose={() => setShowLogin(false)}
                    setShowRegistration={setShowRegistration}
                />
            )}
            {showRegistration && <WidgetRegistration onClose={() => setShowRegistration(false)} />}
        </>
    );
};
