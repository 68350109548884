import { ILang } from "../../../Interfaces/ILang.type";
import { Width } from "../../UI/InputGroups/Width/Width.view";
import { Height } from "../../UI/InputGroups/Height/Height.view";
import { Margins } from "../../UI/InputGroups/Margins/Margins.view";
import { useForm } from "../../UseForm/UseForm.provider";
import { Paddings } from "../../UI/InputGroups/Paddings/Paddings.view";
import { EditorHook } from "../Utils/EditorHook.util";
import { ImageInput } from "../../UI/InputFields/ImageInput/Controller/ImageInput.controller";
import { IDomElement } from "../../DomTracker/Interfaces/IDomTypes.interface";
import { SelectInput } from "../../UI/InputFields/Select/SelectInput.view";
import { useTranslation } from "react-i18next";
import { clone, cloneDeep } from "lodash";
import {
    IGalleryCustoms,
    IGalleryEditors
} from "../../DomMapper/Interfaces/IGalleryComponent.interface";
import { NUMBER_SELECT_OPTIONS } from "../../../Static/NumberSelect.static";
import { InputGroupContextProvider } from "../../UI/InputGrouper/InputGroup.provider";
import BackgroundGroupView from "../../UI/InputGroups/Background/BackgroundGroup.view";
import FormSeparatorView from "../../UI/FormSeparator/FormSeparator.view";

export interface IGalleryEditorView {
    editor: IDomElement;
}

const GalleryEditorView: React.FC<IGalleryEditorView> = (props) => {
    const { t } = useTranslation<ILang>();
    const { onBlur, onClear, units, defaults } = EditorHook<IGalleryEditors>();

    const form = useForm<IGalleryCustoms, true>({
        editor: {
            imageCount: defaults.imageCount,
            images: defaults.images,
            defaultVisible: defaults.defaultVisible
        }
    });

    const renderImageInputs = (): JSX.Element[] => {
        const inputs: JSX.Element[] = [];
        const count = parseInt(form.editor.imageCount);
        for (let i = 0; i < count; i++) {
            const value = form.editor.images[i] || null;
            inputs.push(
                <ImageInput
                    value={value}
                    id={`image__${i}`}
                    onChange={(field, value) => {
                        const index = parseInt((field as string).split("__")[1]);
                        let blurData;
                        form.setEditor((current) => {
                            const state = cloneDeep(current);
                            state.images[index] = value;
                            blurData = state.images;
                            return state;
                        });
                        if (blurData) onBlur({ field: "images", value: blurData });
                    }}
                    onReset={(data) => {
                        const index = parseInt((data.field as string).split("__")[1]);
                        let blurData;
                        form.setEditor((current) => {
                            const state = cloneDeep(current);
                            delete state.images[index];
                            blurData = state.images;
                            return state;
                        });
                        if (blurData) onBlur({ field: "images", value: blurData });
                    }}
                    customLabel={`${i + 1}. ${t<ILang>("Image")}`}
                    key={`${i}-image-input-${value || `${i}-no-key`}`}
                />
            );
        }
        return inputs;
    };

    const onChangeCount = (value: string): void => {
        const dataset = [
            { field: "imageCount", value },
            { field: "images", value: {} }
        ];
        let defaultVisible = "10";
        if (parseInt(value) <= 5) defaultVisible = `${value}`;
        dataset.push({ field: "defaultVisible", value: defaultVisible });
        form.setEditor((current) => {
            const state = clone(current);
            state.defaultVisible = defaultVisible;
            state.imageCount = value;
            return state;
        });
        onBlur(dataset);
    };

    return (
        <InputGroupContextProvider defaultActive={"BackgroundGroup"}>
            <FormSeparatorView
                defKey={"Gallery"}
                defaultGroup={
                    <>
                        <SelectInput<IGalleryEditors, { id: string }>
                            onChange={(field, value) => onChangeCount(value)}
                            id={"imageCount"}
                            nameAccessor={"id"}
                            value={form.editor.imageCount}
                            options={NUMBER_SELECT_OPTIONS}
                        />
                        <SelectInput<IGalleryCustoms, { id: string }>
                            onChange={(field, value) => {
                                form.handleChange(field, value);
                                onBlur({ field, value });
                            }}
                            value={form.editor.defaultVisible}
                            id={"defaultVisible"}
                            nameAccessor={"id"}
                            options={[
                                { id: "1" },
                                { id: "2" },
                                { id: "3" },
                                { id: "4" },
                                { id: "5" },
                                { id: "10" }
                            ]}
                        />
                        {renderImageInputs()}
                    </>
                }
                advancedGroup={
                    <>
                        <BackgroundGroupView
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Paddings
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Margins
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Height
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                            defaultValues={defaults}
                        />
                        <Width
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                    </>
                }
            />
        </InputGroupContextProvider>
    );
};

export default GalleryEditorView;
