import { FC } from "react";
import { GetCurrency } from "../../../DomMapper/DomComponents/Products/Utils/GetCurrency.util";
import { FrameProductElement } from "./FrameProduct.element";
import { ProductCategoryModel } from "../../../DomMapper/DomComponents/Products/Models/ProductCategory.model";

interface IFrameCategoryElementProps {
    category: ProductCategoryModel;
}

export const FrameCategoryElement: FC<IFrameCategoryElementProps> = ({ category }) => {
    const currency = GetCurrency();

    const classes = ["category"];
    if (category.parent_id) classes.push("category-with-parent");

    return (
        <div className={classes.join(" ")} id={category.slugName}>
            <h3 className="category__name">{category.name}</h3>
            {category.description && (
                <h5 className="category__description">{category.description}</h5>
            )}

            <div className="category__products">
                {category.products.map((product) => (
                    <FrameProductElement
                        product={product}
                        currency={currency}
                        key={`${product.id}-product-element`}
                    />
                ))}
            </div>
        </div>
    );
};
