import { ILang } from "../../../Interfaces/ILang.type";
import { useApp } from "../Provider/App.context";
import { isEqual } from "lodash";
import { useAccess } from "../../../Contexts/Access.context";
import { AppMapForm } from "./Editors/AppMap.form";
import { LongSelect } from "../../UI/InputFields/LongSelect/LongSelect.view";
import { DATEFORMATS } from "sitebuilder-common";
import { AXIOS_NAMES } from "../../../Static/AxiosInstanceNames.static";
import { FC, useState } from "react";
import { AppLoginForm } from "./Editors/AppLogin.form";
import { CloneAppView } from "./CloneApp.view";
import { AppGlobalForm } from "./Editors/AppGlobal.form";
import { AppHeaderForm } from "./Editors/AppHeader.form";
import { useRestaurant } from "../../../Contexts/Restaurant.context";
import { InlineLoading } from "../../UI/InlineLoading/InlineLoading.view";
import { SaveLangCookie } from "../../../Utils/SaveLangCookie.util";
import { useTranslation } from "react-i18next";
import { AppButtonsForm } from "./Editors/AppButtons.form";
import { AppSidemenuForm } from "./Editors/AppSidemenu.form";
import { AppProductsForm } from "./Editors/AppProducts.form";
import { axios, useMountWithTriggers } from "xa-generics";
import { View, Edit, Menu, Close, Logout } from "@carbon/icons-react";
import { Information, ArrowRight, ArrowLeft, Launch } from "@carbon/icons-react";
import Button from "../../UI/Button/Button.view";
import moment from "moment";

interface IAppSidemenuViewProps {}

export const AppSidemenuView: FC<IAppSidemenuViewProps> = (props) => {
    const { i18n, t } = useTranslation<ILang>();
    const { logout, loading: accessLoading, accessModel } = useAccess();
    const { restaurant, switchToRestaurant, targets } = useRestaurant();
    const { data, setDataSource, dataSource, publishData, loading } = useApp();

    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [showToolbox, setShowToolbox] = useState<boolean>(false);
    const [isOpened, setIsOpened] = useState<boolean>(true);

    const classes = ["app-sidemenu"];
    if (!isOpened) classes.push("app-sidemenu-hidden");
    const isOriginal = dataSource === "elements_config";
    if (isOriginal) classes.push("original-version");
    if (showToolbox) classes.push("toolbox-visible");

    const changeLang = (lang: string): void => {
        axios.setLanguage(lang, AXIOS_NAMES.BASIC);
        axios.setLanguage(lang, AXIOS_NAMES.ONEMIN);
        i18n.changeLanguage(lang);
        SaveLangCookie(lang);
    };

    const handleOutClick = (e: MouseEvent) => {
        const path = e.composedPath();
        let isParent = false;
        for (const target of path) {
            if ((target as any)?.id === "toolbox") {
                isParent = true;
                break;
            }
        }
        if (!isParent) setShowToolbox(false);
    };

    useMountWithTriggers(() => {
        if (showToolbox) window.addEventListener("click", handleOutClick, { passive: true });
        return () => {
            window.removeEventListener("click", handleOutClick);
        };
    }, [showToolbox]);

    return (
        <div className={classes.join(" ")}>
            <div className={"app-sidemenu-toggler"} onClick={() => setIsOpened(!isOpened)}>
                {isOpened ? <ArrowLeft size={24} /> : <ArrowRight size={24} />}
            </div>
            <h4 className="app-sidemenu__title">
                {!isOriginal && (
                    <div
                        className="info-icon"
                        onMouseEnter={() => setShowInfo(true)}
                        onMouseLeave={() => setShowInfo(false)}
                    >
                        <Information />
                        {showInfo && (
                            <div className="app-sidemenu__info">
                                {t<ILang>("iframe_info")}
                                <br />
                                <b>App Link:</b>
                                <br />
                                /apps/android/{restaurant.id} <br />
                                /apps/ios/{restaurant.id}
                            </div>
                        )}
                    </div>
                )}
                {t<ILang>(isOriginal ? "production_version" : "editor")}
                <div
                    id={"toolbox"}
                    className={"app-sidemenu__toolbox"}
                    onClick={() => setShowToolbox(!showToolbox)}
                >
                    <Menu size={24} />

                    <div
                        className="app-sidemenu__toolbox-content"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="app-sidemenu__toolbox--head">
                            {t<ILang>("menu")}
                            <button className="close" onClick={() => setShowToolbox(false)}>
                                <Close />
                            </button>
                        </div>
                        {restaurant.secondary_language !== "" && (
                            <div className="app-sidemenu__toolbox--row lang-row">
                                <button
                                    className="app-sidemenu__toolbox--lang"
                                    onClick={() => changeLang(restaurant.primary_language)}
                                >
                                    {restaurant.primary_language.toUpperCase()}
                                </button>
                                /
                                <button
                                    className="app-sidemenu__toolbox--lang"
                                    onClick={() => changeLang(restaurant.secondary_language)}
                                >
                                    {restaurant.secondary_language.toUpperCase()}
                                </button>
                            </div>
                        )}
                        <div className="app-sidemenu__toolbox--row">
                            {targets.length > 0 ? (
                                <LongSelect
                                    options={targets}
                                    value={restaurant.id}
                                    id={"restaurant_selector"}
                                    onChange={(id, value) => switchToRestaurant(value)}
                                />
                            ) : null}
                            <button
                                className="app-sidemenu__toolbox--row logout-button"
                                onClick={() => logout()}
                            >
                                {t<ILang>("logout")}{" "}
                                {accessLoading ? <InlineLoading /> : <Logout />}
                            </button>
                        </div>
                    </div>
                </div>
            </h4>

            {dataSource === "draft_elements_config" ? (
                <form className="app-editor">
                    {accessModel.super_admin && <CloneAppView />}
                    <AppGlobalForm />
                    <AppButtonsForm />
                    <AppHeaderForm />
                    <AppSidemenuForm />
                    <AppLoginForm />
                    <AppProductsForm />
                    <AppMapForm />
                </form>
            ) : (
                <div className={"app-editor"}>{t<ILang>("iframe_info")}</div>
            )}
            <Button
                onClick={() => {
                    setDataSource(isOriginal ? "draft_elements_config" : "elements_config");
                }}
                kind={"DANGER"}
                className={"app-sidemenu__button"}
                Icon={isOriginal ? <Edit size={24} /> : View}
            >
                {t<ILang>(isOriginal ? "open_editor_mode" : "exit_editor_mode")}
            </Button>
            {dataSource === "draft_elements_config" && (
                <div className="app-sidemenu__footer">
                    <div className="app-sidemenu__footer--control">
                        {t<ILang>("last_saved")}:{" "}
                        {moment(data.updated_at).format(DATEFORMATS.DATETIMEFORMAT)}
                    </div>
                    <Button
                        disabled={
                            loading || isEqual(data.elements_config, data.draft_elements_config)
                        }
                        onClick={() => publishData()}
                        Icon={loading ? <InlineLoading isAlternate /> : Launch}
                    >
                        {t<ILang>("publish")}
                    </Button>
                </div>
            )}
        </div>
    );
};
