import { IDisplay } from "./IDisplay.interface";

export const DisplayDefaults: IDisplay = {
    justifyContent: "",
    flexDirection: "",
    alignItems: "",
    alignSelf: "",
    flexWrap: "",
    display: ""
};
