import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { useWidgets } from "../../../Widgets/Controller/WidgetsContext.provider";
import { SocialModel } from "sitebuilder-common";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import {
    Share,
    LogoTumblr,
    LogoTwitter,
    LogoYoutube,
    LogoFacebook,
    LogoInstagram
} from "@carbon/icons-react";
import { useTranslation } from "react-i18next";
import { IFooterSettings } from "../Interfaces/IFooterWidget.interface";
import { GetWidgetOverlay } from "../../Util/WidgetOverlay.util";
import { FooterBlockStyles } from "../Static/FooterBlockStyles.static";
import { ParseComponentStyle } from "sitebuilder-common";
import { ResolveSocialCSSBySize } from "../Util/ResolveSocialCssBySize.util";
import WidgetOverlay from "../../../Widgets/View/WidgetOverlay.view";

interface IFooterMidBarViewProps {}

export const FooterMidBarView: FC<IFooterMidBarViewProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { size } = useDom();
    const { widgets } = useWidgets();
    const { social } = useRestaurant();

    const { footerMid } = widgets.Footer.draftSettings;
    const { showOverlay, ...events } = GetWidgetOverlay<IFooterSettings>("Footer", "footerMid");

    const style = ParseComponentStyle(footerMid, FooterBlockStyles, size);

    const getItem = (
        key: Exclude<keyof SocialModel, "id" | "created_at" | "updated_at" | "restaurant_id">,
        Icon: FC<{ size?: number; className?: string }>,
        useFallback?: string
    ): JSX.Element | null => {
        if ((social && social[key]) || useFallback) {
            return (
                <a
                    key={key}
                    className="social"
                    href={useFallback || social![key]}
                    target={"_blank"}
                    rel={"noreferrer noopener"}
                >
                    <Icon className={"social__icon"} size={20} />
                    <span className="social__name">{t<ILang>(key)}</span>
                </a>
            );
        }
        return null;
    };

    const nodes: (JSX.Element | null)[] = [
        getItem("facebook_url", LogoFacebook, "https://facebook.com"),
        getItem("instagram_url", LogoInstagram, "https://instagram.com"),
        getItem("youtube_url", LogoYoutube, "https://youtube.com"),
        getItem("twitter_url", LogoTwitter, "https://twitter.com"),
        getItem("tumblr_url", LogoTumblr, "https://tumblr.com"),
        getItem("blog_url", Share, "https://yourblog.com")
    ].filter((item) => item !== null);

    return (
        <div style={style} {...events} className={"footer__mid"}>
            {showOverlay && (
                <WidgetOverlay<IFooterSettings>
                    isBlock
                    align={"center"}
                    subKey={"footerMid"}
                    parent={widgets.Footer}
                />
            )}
            <div style={ResolveSocialCSSBySize(size, nodes.length)} className="footer__mid--social">
                {nodes}
            </div>
        </div>
    );
};
