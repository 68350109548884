import { IFormWithUnit } from "../../../../Interfaces/IFormWithUnit.type";
import { IPaddings } from "./IPaddings.interface";

export const PaddingDefaults: IFormWithUnit<IPaddings> = {
    paddingTop: "",
    paddingLeft: "",
    paddingBottom: "",
    paddingRight: "",
    unit: "rem"
};
