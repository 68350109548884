import { INavbarStyles } from "../Interfaces/INavbarDetails.interface";
import {
    BackgroundStyles,
    BoxShadowSyles,
    PaddingStyles,
    ColorStyles,
    FontStyles
} from "../../../DomMapper/Static/GroupsStyles.static";

interface INavbar extends INavbarStyles<Required<true>> {}

export const NavbarStyles: INavbar = {
    ...BackgroundStyles,
    ...BoxShadowSyles,
    ...PaddingStyles,
    ...ColorStyles,
    ...FontStyles
};
