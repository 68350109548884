import { Information, Restaurant, User } from "@carbon/icons-react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRestaurant } from "../../../Contexts/Restaurant.context";
import { FrameButton } from "./FrameButton.view";
import { useIFrame } from "../Provider/IFrame.context";
import { useImages } from "../../../Contexts/Images.context";
import { CartSVG } from "./ShoppingCart.svg";
import { ILang } from "../../../Interfaces/ILang.type";
import { FC } from "react";
import HeaderImg from "../../../Assets/placeholders/iframe_header_bg.jpg";

interface IIFrameHeaderProps {}

export const IFrameHeader: FC<IIFrameHeaderProps> = (props) => {
    const { data, dataSource } = useIFrame();
    const { restaurant } = useRestaurant();
    const { t } = useTranslation<ILang>();
    const { pathname } = useLocation();
    const { images } = useImages();
    const header = data[dataSource].header;
    const buttons = data[dataSource].buttons;

    const active = {
        backgroundColor: buttons.backgroundColor,
        color: buttons.color
    };

    const contentStyle: React.CSSProperties = {};
    const topbarStyle: React.CSSProperties = {};

    //topbar setters
    if (header.backgroundColor) topbarStyle.backgroundColor = header.backgroundColor;
    if (header.color) topbarStyle.color = header.color;
    if (header.useGradientHeader) {
        delete topbarStyle.backgroundColor;
        topbarStyle.background = header.gradient;
    }

    //content setters
    if (pathname === "/order") {
        if (header.image) {
            contentStyle.backgroundImage = `url(${images[header.image].url})`;
            delete contentStyle.background;
        } else contentStyle.backgroundImage = `url(${HeaderImg})`;
    }
    if (header.imageAttachment) contentStyle.backgroundAttachment = header.imageAttachment;
    if (header.imagePosition) contentStyle.backgroundPosition = header.imagePosition;
    if (header.imageRepeat) contentStyle.backgroundRepeat = "repeat";
    else contentStyle.backgroundRepeat = "no-repeat";
    if (header.imageSize) contentStyle.backgroundSize = header.imageSize;

    return (
        <>
            <div className="iframe-header__top" style={topbarStyle}>
                <NavLink
                    to={"/order"}
                    style={pathname === "/order" ? active : undefined}
                    className="iframe-header__top--control"
                >
                    <Restaurant className={"icon"} /> {t<ILang>("menu")}
                </NavLink>
                <NavLink
                    to={"/info"}
                    style={pathname === "/info" ? active : undefined}
                    className="iframe-header__top--control"
                >
                    <Information className={"icon"} /> {t<ILang>("about_us")}
                </NavLink>
                <NavLink
                    to={"/profile"}
                    style={pathname === "/profile" ? active : undefined}
                    className="iframe-header__top--control"
                >
                    <User className={"icon"} /> {t<ILang>("profile")}
                </NavLink>
            </div>
            <div className="iframe-header" style={contentStyle}>
                {pathname === "/order" && (
                    <div className="iframe-header__overlay">
                        <div className="iframe-header__content">
                            <div className="iframe-header__content--left">
                                <div className="iframe-header__content--open">
                                    <div className="status-ball"></div>
                                    {t<ILang>("open_hours_short")}
                                    {" // 10:00 - 20:00"}
                                </div>
                                <h1 className="iframe-header__content--name">{restaurant.name}</h1>
                                <div className="iframe-header__content--location">
                                    {restaurant.city}, {restaurant.street}{" "}
                                    {restaurant.premise_number}.
                                </div>
                            </div>
                            <div className="iframe-header__content--right">
                                <NavLink to={"/cart"}>
                                    <FrameButton Icon={<CartSVG size={16} />}>
                                        <div className="cart-items">3</div>
                                        {t<ILang>("cart")}
                                    </FrameButton>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
