import { INavbarStatusStyles } from "../Interfaces/INavbarStatus.interface";
import {
    BackgroundStyles,
    ColorStyles,
    FontStyles
} from "../../../DomMapper/Static/GroupsStyles.static";

interface INavbarStatus extends INavbarStatusStyles<Required<true>> {}

export const NavbarStatusStyles: INavbarStatus = {
    ...BackgroundStyles,
    ...ColorStyles,
    ...FontStyles
};
