import { INavbarNameStyles } from "../Interfaces/INavbarName.interface";
import {
    PaddingStyles,
    MarginStyles,
    HeightStyles,
    ColorStyles,
    FontStyles
} from "../../../DomMapper/Static/GroupsStyles.static";

interface INavbarName extends INavbarNameStyles<Required<true>> {}

export const NavbarNameStyles: INavbarName = {
    ...PaddingStyles,
    ...MarginStyles,
    ...HeightStyles,
    ...ColorStyles,
    ...FontStyles
};
