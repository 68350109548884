import { Width } from "../../UI/InputGroups/Width/Width.view";
import { Height } from "../../UI/InputGroups/Height/Height.view";
import { Border } from "../../UI/InputGroups/Border/Border.view";
import { Margins } from "../../UI/InputGroups/Margins/Margins.view";
import { Paddings } from "../../UI/InputGroups/Paddings/Paddings.view";
import { EditorHook } from "../Utils/EditorHook.util";
import { ImageInput } from "../../UI/InputFields/ImageInput/Controller/ImageInput.controller";
import { IDomElement } from "../../DomTracker/Interfaces/IDomTypes.interface";
import { BorderRadius } from "../../UI/InputGroups/BorderRadius/BorderRadius.view";
import { OpacityInput } from "../../UI/InputGroups/Opacity/Opacity.view";
import { IImageTypeEditors } from "../../DomMapper/Interfaces/IImageTypeComponent.interface";
import { InputGroupContextProvider } from "../../UI/InputGrouper/InputGroup.provider";
import BackgroundGroupView from "../../UI/InputGroups/Background/BackgroundGroup.view";
import FormSeparatorView from "../../UI/FormSeparator/FormSeparator.view";
import AlignPickerView from "../../UI/InputFields/AlignPicker/AlignPicker.view";
import ComponentText from "../../UI/InputGroups/ComponentText/ComponentText.view";
import BoxShadowView from "../../UI/InputGroups/BoxShadow/BoxShadow.view";
import UrlGroupView from "../../UI/InputGroups/Url/UrlGroup.view";

export interface IImageEditorViewProps {
    editor: IDomElement;
}

export const ImageEditorView: React.FC<IImageEditorViewProps> = (props) => {
    const { onBlur, onClear, defaults, units } = EditorHook<IImageTypeEditors>();

    return (
        <InputGroupContextProvider defaultActive={"Width"}>
            <FormSeparatorView
                defKey={"Image"}
                defaultGroup={
                    <>
                        <ImageInput
                            id={"src"}
                            value={defaults.src}
                            onChange={(field, value) => onBlur({ field, value })}
                            labelText={"Image"}
                        />
                        <ComponentText
                            defaultValues={defaults}
                            labelText={"img_alt"}
                            defaultText={""}
                            onBlur={onBlur}
                            type={"TEXT"}
                        />
                        <UrlGroupView defaultValues={defaults} onBlur={onBlur} />
                        <BackgroundGroupView
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Border
                            defaultValues={defaults}
                            units={units}
                            onReset={onClear}
                            onBlur={onBlur}
                        />
                        <AlignPickerView
                            id={"textAlign"}
                            onBlur={onBlur}
                            valueSet={"FLEX"}
                            labelText={"imageAlign"}
                            defaultValue={defaults.textAlign}
                        />
                    </>
                }
                advancedGroup={
                    <>
                        <Paddings
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Margins
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <BorderRadius
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <OpacityInput onReset={onClear} onBlur={onBlur} defaultValues={defaults} />
                        <BoxShadowView
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Width
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Height
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                            defaultValues={defaults}
                        />
                    </>
                }
            />
        </InputGroupContextProvider>
    );
};
