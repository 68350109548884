import { FC } from "react";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { NavbarReg } from "./NavbarReg.view";
import { useWidgets } from "../../../Widgets/Controller/WidgetsContext.provider";
import { NavbarLogin } from "./NavbarLogin.view";
import { NavbarStatus } from "./NavbarStatus.view";
import { NavbarStyles } from "../Static/NavbarStyles.static";
import { INavbarSettings } from "../Interfaces/INavbarWidget.interface";
import { GetWidgetOverlay } from "../../Util/WidgetOverlay.util";
import { ParseComponentStyle } from "sitebuilder-common";
import WidgetOverlay from "../../../Widgets/View/WidgetOverlay.view";

interface IV3NavbarTopProps {}

export const V3NavbarTop: FC<IV3NavbarTopProps> = (props) => {
    const { size } = useDom();
    const { widgets } = useWidgets();
    const { navbarTopBar } = widgets.Navbar.draftSettings;

    const topStyles = ParseComponentStyle(navbarTopBar, NavbarStyles, size) || {};
    const { showOverlay, ...events } = GetWidgetOverlay<INavbarSettings>("Navbar", "navbarTopBar");

    return (
        <div className={"v3-navbar__top"} style={topStyles} {...events}>
            {showOverlay && (
                <WidgetOverlay<INavbarSettings>
                    isBlock
                    align={"center"}
                    parent={widgets.Navbar}
                    subKey={"navbarTopBar"}
                />
            )}
            <NavbarLogin />
            <NavbarReg />
            <NavbarStatus />
        </div>
    );
};
