import { INavbarLinkStyles } from "../Interfaces/INavbarLink.interface";
import {
    FontStyles,
    ColorStyles,
    MarginStyles,
    PaddingStyles,
    TextShadowSyles
} from "../../../DomMapper/Static/GroupsStyles.static";

interface INavbarLink extends INavbarLinkStyles<Required<true>> {}

export const NavbarLinkStyles: INavbarLink = {
    ...TextShadowSyles,
    ...PaddingStyles,
    ...MarginStyles,
    ...ColorStyles,
    ...FontStyles
};
