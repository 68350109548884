import { axios, IDynamicObject } from "xa-generics";
import { FontModel } from "sitebuilder-common";
import { RootDAO } from "./Restaurant.dao";

export abstract class FontDAO extends RootDAO {
    public static async load(): Promise<FontModel> {
        const request = await axios
            .getInstance()
            .get<IDynamicObject<any>>(`${this.baseUrl()}/fonts`);
        return new FontModel(request.data);
    }

    public static async saveOrReplace(data: FontModel): Promise<void> {
        await axios.getInstance().put<void>(`${this.baseUrl()}/fonts`, data);
        return;
    }
}
