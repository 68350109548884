import { LS } from "../Static/LS.static";
import {
    IError,
    ILoading,
    Objectify,
    ISetState,
    AuthCookieName,
    useMountWithTriggers,
    useAfterTriggerChanged
} from "xa-generics";
import { Context, createContext, useState, useContext } from "react";
import { ConfigModel, AccessModel, IPermissions } from "sitebuilder-common";
import { RestaurantDAO, RootDAO } from "../DAO/Restaurant.dao";
import { IRestaurantContext } from "../Interfaces/IRestaurantContext.interface";
import { RestaurantModel } from "sitebuilder-common";
import { PermissionDAO } from "../DAO/Permission.dao";
import { FloatingError } from "xa-error-with-lang";
import { FONT_OPTIONS } from "sitebuilder-common";
import { SocialModel } from "sitebuilder-common";
import { useAccess } from "./Access.context";
import { isEmpty } from "lodash";
import Loading from "../Components/UI/Loading/Loading.view";
import Cookies from "js-cookie";

/**
 * ## RestaurantContext
 */
export const RestaurantContext: Context<IRestaurantContext> = createContext<IRestaurantContext>(
    null as any
);

RestaurantContext.displayName = "RestaurantContext";

interface IRestaurantContextProviderProps {}

/**
 * ## Restaurant context provider component
 *
 */
export const RestaurantContextProvider: React.FC<IRestaurantContextProviderProps> = (props) => {
    const {
        logout,
        accessModel,
        builderType,
        setAccessModel,
        setError: setAuthError
    } = useAccess();

    const [error, setError] = useState<IError>(null);
    const [loading, setLoading] = useState<ILoading>(true);
    const [currentFont, setCurrentFont] = useState<string>("");
    const [targets, setTargets] = useState<RestaurantModel[]>([]);
    const [config, setConfig] = useState<ConfigModel | null>(null);
    const [social, setSocial] = useState<null | SocialModel>(null);
    const [permissions, setPermissions] = useState<IPermissions>({});
    const [createProductPicker, setCreateProductPicker] = useState<boolean>(false);
    const [restaurant, setRestaurant] = useState<RestaurantModel>(new RestaurantModel());
    const [isNewInstance, setIsNewInstance] = useState<boolean>(true);

    const loadSuperAdminRestaurants = (): void => {
        if (!accessModel.super_admin) {
            throw new Error("Only super admins can load all restaurants!");
        }
        const restID: string | null = localStorage.getItem(LS.selectedRestaurant);
        RestaurantDAO.loadSuperAdminRestaurants()
            .then((restaurantList) => {
                setTargets(restaurantList);
                //Check if there is a restaurant with the last selected id
                //(there won't be a match if it isn't set yet)
                let selectedRestaurant = restaurantList.find((rest) => rest.id === restID);
                //If a restaurant with the last id isn't found, pick the 0th element of the array
                if ((!restID || !selectedRestaurant) && restaurantList.length > 0) {
                    selectedRestaurant = restaurantList[0];
                    localStorage.setItem(LS.selectedRestaurant, selectedRestaurant.id);
                }
                if (selectedRestaurant) {
                    RootDAO.setPartnerID(selectedRestaurant.partner_id);
                    RootDAO.setRestID(selectedRestaurant.id);
                    setRestaurant(selectedRestaurant);
                } else {
                    return setError("There are no restaurants!");
                }
            })
            .catch((error) => setError(error))
            .finally(() => setLoading(false));
    };

    const loadPartnerRestaurants = (partner_id: string): void => {
        if (accessModel!.target_type !== "Partner") {
            throw new Error("Only partners can load partner restaurants!");
        }
        RootDAO.setPartnerID(partner_id);
        RestaurantDAO.loadPartner()
            .then((restaurants) => {
                setTargets(restaurants);
                const restID: string | null = localStorage.getItem(LS.selectedRestaurant);
                let selectedRestaurant = restaurants.find((rest) => rest.id === restID);
                if (!selectedRestaurant && restaurants.length > 0) {
                    selectedRestaurant = restaurants[0];
                }

                if (!selectedRestaurant) {
                    setAccessModel(new AccessModel());
                    return setError("There are no restaurants...");
                }
                RootDAO.setPartnerID(selectedRestaurant.partner_id);
                RootDAO.setRestID(selectedRestaurant.id);
                setRestaurant(selectedRestaurant);
            })
            .catch((error) => setError(error))
            .finally(() => setLoading(false));
    };

    const loadRestaurant = (): void => {
        if (accessModel.target_type !== "Restaurant") {
            throw new Error("Only restaurant target types can call this function!");
        }
        RootDAO.setPartnerID(accessModel.partner_id);
        RootDAO.setRestID(accessModel.target_id);
        setLoading(
            RestaurantDAO.loadRestaurant()
                .then((model) => {
                    RootDAO.setRestID(model.id);
                    setRestaurant(model);
                    setTargets([model]);
                })
                .catch((error: IError) => {
                    if ((error as any)?.response?.status === 401) {
                        logout();
                        Cookies.remove(AuthCookieName);
                        return window.location.replace("/");
                    }
                    setError(error);
                })
                .finally(() => setLoading(false))
        );
    };

    const initRestaurant = (rest: RestaurantModel): void => {
        RestaurantDAO.init(rest)
            .then(() => {
                setCreateProductPicker(true);
                setIsNewInstance(true);
                loadConfig();
            })
            .catch((error) => setError(error));
    };

    const loadConfig = (rest?: RestaurantModel): void => {
        RestaurantDAO.loadConfig()
            .then((conf) => {
                if (!conf.restaurant_id && rest) initRestaurant(rest);
                else {
                    setConfig(conf);
                    const fontConfig =
                        FONT_OPTIONS.find((opt) => opt.id === conf.font_family_cdn)?.class ||
                        "opensans";
                    setCurrentFont(fontConfig);
                }
            })
            .catch((error) => setError(error));
    };

    const declineConfigTemplate = (): void => {
        if (!config) return;

        const data = config.Transform;
        data.is_declined_template = true;

        RestaurantDAO.updateConfig(data)
            .then(() => loadConfig(restaurant))
            .catch((error) => setError(error));
    };

    const loadSocial = (): void => {
        RestaurantDAO.loadSocial()
            .then((model) => setSocial(model))
            .catch(() => setSocial(null));
    };

    const switchToRestaurant = (restID: string): void => {
        if (!restID || restID === restaurant?.id) return;
        const partnerRestaurant = targets.find((rest) => rest.id === restID);
        if (!partnerRestaurant) return;
        setConfig(null);
        setSocial(null);

        setTimeout(() => {
            RootDAO.setPartnerID(partnerRestaurant.partner_id);
            RootDAO.setRestID(partnerRestaurant.id);
            localStorage.setItem(LS.selectedRestaurant, partnerRestaurant.id);
            setRestaurant(partnerRestaurant);
        }, 300);
    };

    const loadPermissions = async (): Promise<void> => {
        const permissions = await PermissionDAO.load();
        const allowedList = permissions.filter(
            (perm) => accessModel.permission_ids.indexOf(perm.id) >= 0
        );
        const allowedPermissions = Objectify(allowedList, "key");
        if (!allowedPermissions["sitebuilder_login"]) throw Error("NO_SITEBUILDER_ACCESS");
        return setPermissions(allowedPermissions);
    };

    const init = (): void => {
        if (!accessModel.id) return setLoading(false);

        loadPermissions()
            .then(() => {
                if (accessModel.super_admin) {
                    loadSuperAdminRestaurants();
                } else if (accessModel.target_type === "Partner") {
                    loadPartnerRestaurants(accessModel.partner_id);
                } else {
                    RootDAO.setRestID(accessModel.target_id);
                    RootDAO.setPartnerID(accessModel.partner_id);
                    loadRestaurant();
                }
            })
            .catch((error) => {
                if (error?.message === "NO_SITEBUILDER_ACCESS") {
                    setAuthError({ response: { data: { code: "NO_SITEBUILDER_ACCESS" } } });
                } else setError(error);

                setAccessModel(new AccessModel());
                setLoading(false);
                Cookies.remove(AuthCookieName);
            });
    };

    useMountWithTriggers(init, [accessModel.id]);

    useAfterTriggerChanged(() => {
        if (builderType === "SITEBUILDER") {
            loadConfig(restaurant);
        }
        loadSocial();
    }, [restaurant, builderType]);

    const noRequiredData =
        !restaurant.id || isEmpty(permissions) || (builderType === "SITEBUILDER" && !config);

    return (
        <RestaurantContext.Provider
            value={{
                setConfig: setConfig as ISetState<ConfigModel>,
                setCreateProductPicker,
                declineConfigTemplate,
                createProductPicker,
                switchToRestaurant,
                setIsNewInstance,
                config: config!, //children won't be rendered if this is null, so it's safe to assert that it's not null
                setCurrentFont,
                isNewInstance,
                permissions,
                currentFont,
                restaurant,
                loadConfig,
                loading,
                targets,
                social,
                init,
                error
            }}
        >
            <FloatingError error={error} resetError={() => setError(null)} />
            {loading || (accessModel.id && noRequiredData) ? (
                <Loading isExternalConditional isAbsolute useSpacing />
            ) : (
                props.children
            )}
        </RestaurantContext.Provider>
    );
};

export const useRestaurant = (): IRestaurantContext => useContext(RestaurantContext);
