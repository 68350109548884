import { ILang } from "../../../../Interfaces/ILang.type";
import { DnDEvents } from "../../Util/DND.util";
import { useImages } from "../../../../Contexts/Images.context";
import { IDomElement } from "../../../DomTracker/Interfaces/IDomTypes.interface";
import { ClientButton } from "../Button/ClientButton.view";
import { GalleryStyles } from "../../Static/GalleryStyles.static";
import { useTranslation } from "react-i18next";
import { IGalleryContent } from "../../Interfaces/IGalleryComponent.interface";
import { useMemo, useState } from "react";
import { GalleryImageViewer } from "./GalleryImageViewer.view";
import { DefaultImageGetter } from "./DefaultImageGetter.util";
import { ParseComponentStyle } from "sitebuilder-common";
import { useAfterTriggerChanged } from "xa-generics";
import Overlay from "../../View/Overlay.view";

export interface IGalleryProps extends IDomElement<IGalleryContent> {}

export const Gallery: React.FC<IGalleryProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { images } = useImages();
    const [showCount, setShowCount] = useState<number>(
        parseInt(props.content.defaultVisible || "3")
    );
    const [viewImage, setViewImage] = useState<number>(-1);
    const { refs, size, id, showOverlay, ...events } = DnDEvents<IGalleryContent>(props, "ELEMENT");

    const style = ParseComponentStyle(props.content, GalleryStyles, size);

    const imageSrcs: string[] = useMemo(() => {
        if (!props.content.imageCount) return [];
        const defaultImages = DefaultImageGetter(props.content!.imageCount);
        const range = parseInt(props.content.imageCount);
        const srcs = [];
        for (let i = 0; i < range; i++) {
            const imageId = props.content!.images[i];
            const src = imageId && images[imageId] ? images[imageId].url : defaultImages[i];
            if (src) srcs.push(src);
        }
        return srcs;
    }, [props.content, images]);
    const classes: string[] = ["dom__gallery--images"];

    useAfterTriggerChanged(() => {
        setShowCount(parseInt(props.content.defaultVisible));
    }, [props.content.defaultVisible]);

    if (props.content.defaultVisible === "2") classes.push("grid-system grid-2");
    if (props.content.defaultVisible === "3") classes.push("grid-system grid-3");
    if (props.content.defaultVisible === "4") classes.push("grid-system grid-4");
    if (props.content.defaultVisible === "5") classes.push("grid-system grid-5");
    if (props.content.defaultVisible > 5) classes.push("grid-system grid-5");

    const imageJSXs = useMemo(() => {
        return imageSrcs.map((src, i) => (
            <div className="img-container" key={`${i}-gallery`}>
                <img
                    src={src}
                    className={"img-vert img-hori"}
                    alt={`${i}-gallery-img-${props.uuid}`}
                    onClick={() => setViewImage(i)}
                />
            </div>
        ));
    }, [imageSrcs, props.uuid]);

    return (
        <div
            id={id}
            {...events}
            style={style}
            className={"dom__gallery"}
            ref={refs.current[props.uuid]}
        >
            {showOverlay && <Overlay parent={props} unmountId={props.sectionUUID} />}
            <div className={classes.join(" ")}>{imageJSXs.slice(0, showCount)}</div>

            {showCount < imageJSXs.length ? (
                <div className="dom__gallery--show-more">
                    <ClientButton onClick={() => setShowCount(Infinity)}>
                        {t<ILang>("all_images")}
                    </ClientButton>
                </div>
            ) : null}

            {viewImage > -1 && (
                <GalleryImageViewer
                    imageSrcs={imageSrcs}
                    viewImage={viewImage}
                    setViewImage={setViewImage}
                    imageCount={parseInt(props.content.imageCount)}
                />
            )}
        </div>
    );
};
