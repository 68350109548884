import { ILang } from "../../../../Interfaces/ILang.type";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { cloneDeep } from "lodash";
import { TextInput } from "../../../UI/InputFields/TextInput/TextInput.view";
import { SelectInput } from "../../../UI/InputFields/Select/SelectInput.view";
import { IPageEditor } from "../../../DomMapper/Interfaces/IPageEditor.interface";
import { INavbarLink } from "../../../WidgetModules/Navbar/Interfaces/INavbarWidget.interface";
import { createRef, FC } from "react";
import { PageDataModel } from "../../../DomTracker/Model/PageData.model";
import { useTranslation } from "react-i18next";
import { INavbarEditorProps } from "./NavbarEditor.index";
import { Draggable, Launch, TrashCan } from "@carbon/icons-react";
import { CheckboxInput } from "../../../UI/InputFields/Checkbox/Checkbox.view";

interface INavbarLinkItemProps extends INavbarEditorProps {
    link: INavbarLink;
}

export const NavbarLinkItem: FC<INavbarLinkItemProps> = (props) => {
    const { t, i18n } = useTranslation<ILang>();
    const language = i18n.language;
    const { pages } = useDom();

    const { link } = props;
    const classes: string[] = ["navbar-editor__link"];
    const ref = createRef<HTMLDivElement>();
    if (props.draggedLink) classes.push("navbar-editor__link-with-dnd");

    return (
        <div
            ref={ref}
            draggable={true}
            id={`${link.index}::link-item`}
            className={classes.join(" ")}
            onDragStart={(e) => props.setDraggedLink(cloneDeep(link))}
            onDragEnd={(e) => {
                const id = (e.target as HTMLDivElement)?.id;
                //If there is no id or the id doesn't contain the split text ("::"), insert the dragged at the last index.
                if (typeof id !== "string" || id.indexOf("::") < 0) return props.insertLinkAt();
                const targetIndex = parseInt(id.split("::")[0]);
                const targetLink = props.navLinks[targetIndex];
                props.insertLinkAt(cloneDeep(targetLink));
            }}
            onDragOver={(e) => {
                const element = ref.current as HTMLDivElement;
                if (!element?.id || !element.parentElement) return;
                if (element.id === `${props.draggedLink?.index}::link-item`) return;
                const parent = element.parentElement;
                const rectTop = parent?.offsetTop || 0;
                let halfHeight = element.offsetTop + rectTop + element.offsetHeight / 2;
                if (e.pageY <= halfHeight) {
                    ref.current!.classList.add("navbar-editor__above-link");
                    ref.current!.classList.remove("navbar-editor__below-link");
                    props.dndTargetIndex.current = link.index;
                } else {
                    ref.current!.classList.add("navbar-editor__below-link");
                    ref.current!.classList.remove("navbar-editor__above-link");
                    props.dndTargetIndex.current = link.index + 1;
                }
            }}
            onDragLeave={() => {
                ref.current?.classList.remove(
                    "navbar-editor__above-link",
                    "navbar-editor__below-link"
                );
            }}
        >
            <div className="navbar-editor__link--drag">
                {link.index + 1}
                <Draggable size={20} />
            </div>
            <div className="navbar-editor__link--content">
                {link.isExternal ? (
                    <TextInput
                        noLabel
                        onBlur={() => {}}
                        placeholder={"Link"}
                        id={`${link.index}-link`}
                        value={(link[language] as IPageEditor)?.url || ""}
                        onChange={(id, value) => props.onLinkChange(link, "url", value)}
                        description={
                            <CheckboxInput
                                value={link.openOnNewPage}
                                id={`link-open-on-new-page-${link.index}`}
                                onChange={(id, value) => props.toggleNewTab(link)}
                                labelText={"open_in_new_tab"}
                            />
                        }
                    />
                ) : (
                    <SelectInput<PageDataModel, PageDataModel>
                        onChange={(id, value, option) => props.onLinkRefToPage(link, option!)}
                        value={(link[language] as IPageEditor)?.url || ""}
                        options={pages.filter((page) => page.is_active)}
                        id={`${link.index}-link` as never}
                        placeholder={"subpage_link"}
                        idAccessor={"page_id"}
                        nameAccessor={"name"}
                        noLabel
                    />
                )}
                <TextInput
                    noLabel
                    id={`${link.index}-text`}
                    value={(link[language] as IPageEditor)?.routeName || ""}
                    placeholder={t<ILang>("visible_text")}
                    onChange={(i, value) => props.onLinkChange(link, "routeName", value)}
                    onBlur={() => {}}
                />
            </div>
            <div
                className="navbar-editor__link--control"
                onClick={() => props.toggleExternal(link)}
            >
                <Launch size={20} />
            </div>
            <div className="navbar-editor__link--control" onClick={() => props.deleteLink(link)}>
                <TrashCan size={20} />
            </div>
        </div>
    );
};
