import { IBuilderTypes } from "../Interfaces/IBuilder.type";

type ILS = {
    builderType: IBuilderTypes;
    selectedRestaurant: string;
};

export const LS: Record<keyof ILS, string> = {
    builderType: "builderType",
    selectedRestaurant: "selectedRestaurant"
};
