import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useApp } from "../../Provider/App.context";
import { IAppStep } from "../../Interfaces/IAppStep.type";
import { GetColors } from "../../Utils/GetColors.util";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

interface IAppCartProgressbarProps {
    step: IAppStep;
}

export const AppCartProgressbar: FC<IAppCartProgressbarProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { data, dataSource } = useApp();
    const navigateTo = useNavigate();
    const theme = GetColors(data[dataSource]);
    const classes: string[] = ["app-cart__progressbar"];

    const activeStyle: React.CSSProperties = { backgroundColor: theme.backgroundColor };

    return (
        <div className={classes.join(" ")}>
            <div
                className="app-cart__progressbar--step --progress1"
                onClick={() => navigateTo("/cart")}
            >
                <div className="app-cart__progressbar--step--knob" style={activeStyle} />
                <div className="app-cart__progressbar--step--text">{t<ILang>("cart_step1")}</div>
            </div>
            <div
                className="app-cart__progressbar--step --progress2"
                onClick={() => navigateTo("/cart/settings")}
            >
                <div
                    className="app-cart__progressbar--step--knob"
                    style={props.step !== "content" ? activeStyle : undefined}
                />
                <div className="app-cart__progressbar--step--text">{t<ILang>("cart_step2")}</div>
            </div>
            <div
                className="app-cart__progressbar--step --progress3"
                onClick={() => navigateTo("/cart/finalize")}
            >
                <div
                    className="app-cart__progressbar--step--knob"
                    style={props.step === "finalize" ? activeStyle : undefined}
                />
                <div className="app-cart__progressbar--step--text">{t<ILang>("cart_step3")}</div>
            </div>
            <div className="app-cart__progressbar--thread">
                <div
                    className="progress"
                    style={{
                        backgroundColor: theme.backgroundColor,
                        width:
                            props.step === "content"
                                ? "0%"
                                : props.step === "settings"
                                ? "50%"
                                : "100%"
                    }}
                ></div>
            </div>
        </div>
    );
};
