import moment from "moment";
import { useTranslation } from "react-i18next";
import { ILang } from "../Interfaces/ILang.type";

export const GetEpochText = (
    time: number,
    now: number,
    date: moment.Moment | null
): string | JSX.Element => {
    const { t, i18n } = useTranslation<ILang>();

    const secs = Math.round((now - time) / 1000);
    const fiftyNineMinsInSecs = 3540;

    if (time === 0) return t<ILang>("unsaved");
    const oneMinute: number = 60;
    if (secs >= oneMinute && secs < fiftyNineMinsInSecs) {
        const s = Math.round(secs / 60);
        if (i18n.language === "en") {
            return `${t<ILang>("epoch_edited_minutes")} ${s} ${t<ILang>("epoch_ago")}`;
        } else {
            return `${s} ${t<ILang>("epoch_edited_minutes")}`;
        }
    }
    if (secs >= fiftyNineMinsInSecs && date) {
        return (
            <>
                {`${t<ILang>("epoch_edited_at")}`}: <br />
                {`${date.format("YYYY/MM/DD HH:mm:ss")}`}
            </>
        );
    }

    return t<ILang>("epoch_edited");
};
