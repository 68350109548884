import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useCart } from "../../../WidgetModules/Cart/Provider/Cart.provider";
import { useTranslation } from "react-i18next";
import { AppCartItemElement } from "./AppCartItem.element";

interface IAppCartItemsProps {}

export const AppCartItems: FC<IAppCartItemsProps> = (props) => {
    const { order } = useCart();
    const { t } = useTranslation<ILang>();

    return (
        <>
            <div className={"cart-block app-items-container"}>
                <h6 className="cart__title">{t<ILang>("cart")}</h6>
                {order.items.map((item) => (
                    <AppCartItemElement key={`${item.id}-cart-set1`} item={item} />
                ))}
                {order.items.map((item) => (
                    <AppCartItemElement key={`${item.id}-cart-set2`} item={item} />
                ))}
                {order.items.map((item) => (
                    <AppCartItemElement key={`${item.id}-cart-set3`} item={item} />
                ))}
                {order.items.map((item) => (
                    <AppCartItemElement key={`${item.id}-cart-set4`} item={item} />
                ))}
            </div>
        </>
    );
};
