import { INavbarButton } from "../Interfaces/INavbarButton.interface";
import {
    BackgroundStyles,
    BorderRadiusStyles,
    BorderStyles,
    BoxShadowSyles,
    ColorStyles,
    FontStyles
} from "../../../DomMapper/Static/GroupsStyles.static";

interface INavButton extends INavbarButton<Required<true>> {}

export const NavbarButtonStyles: INavButton = {
    ...BorderRadiusStyles,
    ...BackgroundStyles,
    ...BoxShadowSyles,
    ...BorderStyles,
    ...ColorStyles,
    ...FontStyles
};
