import { ILang } from "../../../../Interfaces/ILang.type";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { useEditor } from "../../Controller/EditorContext.provider";
import { DocumentAdd } from "@carbon/icons-react";
import { PageDataModel } from "../../../DomTracker/Model/PageData.model";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import { DomlessPageModel } from "../../../DomTracker/Model/DomlessPage.model";
import PageListElementView from "./PageList.element";
import Button from "../../../UI/Button/Button.view";

export interface IPageListViewProps {
    setShowCMPS: (value: boolean) => void;
}

const PageListView: React.FC<IPageListViewProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { setPageEditor } = useEditor();
    const { restaurant } = useRestaurant();
    const { pages } = useDom<PageDataModel>();

    return (
        <>
            <div className="editor__header">
                {t<ILang>("pages")}
                <Button
                    size={"SMALL"}
                    kind={"SIMPLE"}
                    Icon={DocumentAdd}
                    onClick={() => setPageEditor(new DomlessPageModel({}, restaurant))}
                >
                    {t<ILang>("create_new")}
                </Button>
            </div>
            <div className="editor__page-group-name no-top-spacing">
                {t<ILang>("essential_pages")}
            </div>
            {pages
                .filter((page) => page.page_type !== "")
                .map((page, index) => (
                    <PageListElementView
                        {...props}
                        page={page}
                        draft={null}
                        key={`${page.page_id}-page-item-${index}`}
                    />
                ))}
            <div className="editor__page-group-name">{t<ILang>("self_create_pages")}</div>
            {pages
                .filter((page) => page.page_type === "")
                .map((page, index) => (
                    <PageListElementView
                        {...props}
                        page={page}
                        draft={null}
                        key={`${page.page_id}-page-item-${index}`}
                    />
                ))}
        </>
    );
};

export default PageListView;
