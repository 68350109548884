import { ParseComponentStyle } from "sitebuilder-common";
import { NavbarLogoV3Styles } from "../Static/NavbarLogoV3.static";
import { NavbarNameAndLogo } from "./NavbarNameAndLogo.view";
import { GetWidgetOverlay } from "../../Util/WidgetOverlay.util";
import { INavbarSettings } from "../Interfaces/INavbarWidget.interface";
import { NavbarStyles } from "../Static/NavbarStyles.static";
import { NavbarLinks } from "./NavbarLinks.view";
import { NavbarCart } from "./NavbarCart.view";
import { useWidgets } from "../../../Widgets/Controller/WidgetsContext.provider";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { FC } from "react";
import WidgetOverlay from "../../../Widgets/View/WidgetOverlay.view";

interface IV3NavbarBottomProps {}

export const V3NavbarBottom: FC<IV3NavbarBottomProps> = (props) => {
    const { widgets } = useWidgets();
    const { size } = useDom();
    const { navbarBottomBar, navbarLogoV3, hideCartButtons } = widgets.Navbar.draftSettings;

    const bottomStyles = ParseComponentStyle(navbarBottomBar, NavbarStyles, size);
    const v3LogoStyles = ParseComponentStyle(navbarLogoV3, NavbarLogoV3Styles, size);

    const { showOverlay, ...events } = GetWidgetOverlay<INavbarSettings>(
        "Navbar",
        "navbarBottomBar"
    );

    const classes = ["v3-navbar__bottom"];
    if (hideCartButtons) classes.push("v3-navbar__bottom--1col");

    return (
        <div className={classes.join(" ")} style={bottomStyles} {...events}>
            {showOverlay && (
                <WidgetOverlay<INavbarSettings>
                    isBlock
                    align={"center"}
                    parent={widgets.Navbar}
                    subKey={"navbarBottomBar"}
                />
            )}
            <NavbarNameAndLogo v3LogoStyles={v3LogoStyles} />
            <NavbarLinks />
            <NavbarCart />
        </div>
    );
};
