import { ILang } from "../../../../Interfaces/ILang.type";
import { IHeight } from "./IHeight.interface";
import { useForm } from "../../../UseForm/UseForm.provider";
import { Height16 } from "../../SVG/Height.svg";
import { TextInput } from "../../InputFields/TextInput/TextInput.view";
import { SIZING_RULE } from "../../../../Static/FormRules.static";
import { ResetValues } from "../../../Editor/Utils/ResetValues.util";
import { IInputGroup } from "../../../../Interfaces/IInputGroup.interface";
import { Destructure } from "../../../../Utils/Destructure.util";
import { IFormWithUnit } from "../../../../Interfaces/IFormWithUnit.type";
import { useTranslation } from "react-i18next";
import { HeightDefaults } from "./Defaults.static";
import { BuildGroupData } from "../../../../Utils/BuildGroupData.util";
import { CheckIfHasValue } from "../../../../Utils/CheckIfHasValue.util";
import { useAfterTriggerChanged, useTimeout } from "xa-generics";
import SizeUnitControlView from "../../SizeUnitControl/SizeUnitControl.view";
import ResetFormView from "../../InputGrouper/ResetForm.view";
import InputGrouper from "../../InputGrouper/InputGrouper.view";

export interface IHeightProps extends IInputGroup<IHeight<any>> {}

export const Height: React.FC<IHeightProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { setTm } = useTimeout();
    const form = useForm<IFormWithUnit<IHeight>, true>({
        editor: Destructure(HeightDefaults, props.defaultValues, props.units.height),
        initialRules: {
            minHeight: SIZING_RULE(t),
            height: SIZING_RULE(t),
            maxHeight: SIZING_RULE(t)
        }
    });

    useAfterTriggerChanged(() => {
        form.handleSubmit((state) => {
            setTm(() => props.onBlur(BuildGroupData(state)), 500);
        })();
    }, [form.editor]);

    return (
        <InputGrouper
            groupKey={"Height"}
            Icon={Height16}
            labelKey={"height"}
            Reset={
                <ResetFormView
                    hasValue={CheckIfHasValue(form, HeightDefaults)}
                    onReset={(e) => ResetValues(e, HeightDefaults, form.reset, props.onReset)}
                />
            }
            Controls={<SizeUnitControlView unit={form.editor.unit} setUnit={form.handleChange} />}
        >
            <div className="height-form">
                <TextInput
                    noLabel
                    unit={"min"}
                    id={"minHeight"}
                    className="height-input"
                    value={form.editor.minHeight}
                    onChange={form.handleChange}
                    errors={form.fieldErrors}
                />
                <TextInput
                    noLabel
                    id={"height"}
                    className="height-input"
                    value={form.editor.height}
                    onChange={form.handleChange}
                    errors={form.fieldErrors}
                />
                <TextInput
                    noLabel
                    unit={"max"}
                    id={"maxHeight"}
                    className="height-input"
                    value={form.editor.maxHeight}
                    onChange={form.handleChange}
                    errors={form.fieldErrors}
                />
                <div className="global__form-group--desc global__form-group--three-row">
                    {t<ILang>("special_values_desc")}
                </div>
            </div>
        </InputGrouper>
    );
};
