import { IGalleryStyles } from "../Interfaces/IGalleryComponent.interface";
import {
    WidthStyles,
    HeightStyles,
    MarginStyles,
    PaddingStyles,
    BackgroundStyles
} from "./GroupsStyles.static";

export const GalleryStyles: Required<IGalleryStyles<true>> = {
    ...WidthStyles,
    ...HeightStyles,
    ...MarginStyles,
    ...PaddingStyles,
    ...BackgroundStyles
};
