import { DnDEvents } from "../../../Util/DND.util";
import { useImages } from "../../../../../Contexts/Images.context";
import { FC, useMemo } from "react";
import { useProducts } from "../Context/ProductsContext.provider";
import { ProductModel } from "../Models/Product.model";
import { ISelectorData } from "../Interfaces/ISelectorData.type";
import { useRestaurant } from "../../../../../Contexts/Restaurant.context";
import { IProductLayouts } from "../Interfaces/IProductLayouts.type";
import { BackgroundStyles } from "../../../Static/GroupsStyles.static";
import { IProductsContent } from "../../../Interfaces/IProductComponent.interface";
import { TopbarCategories } from "./TopbarCategories.view";
import { IProductSelector } from "../Interfaces/IProductSelector.interface";
import { ProductMapperView } from "./ProductMapper.view";
import { ProductSearcherView } from "./ProductSearcher.view";
import { CategorizedProducts } from "./CategorizedProducts.view";
import { TileCategoryMapperView } from "./TileCategoryMapper.view";
import { ProductsContainerStyles } from "../../../Static/ProductsStyles.static";
import { IProductsControllerProps } from "../Controller/Products.controller";
import { DefaultCategoryMapperView } from "./DefaultCategoryMapper.view";
import { ISetState, useMountWithTriggers } from "xa-generics";
import { CalcContrast, ColorShader, IPartialCSS, ParseComponentStyle } from "sitebuilder-common";
import Loading from "../../../../UI/Loading/Loading.view";
import Overlay from "../../../View/Overlay.view";

export interface IProductsIndexProps extends IProductsControllerProps {
    unsetSelector: (unsetType: keyof IProductSelector) => void;
    setSelectorData: (data: ISelectorData) => void;
    setFilteredProducts: ISetState<ProductModel[]>;
    setShowOptionWarn: ISetState<boolean>;
    filteredProducts: ProductModel[];
    setFilter: ISetState<string>;
    setError: ISetState<string>;
    selector: IProductSelector;
    isFilterPending: boolean;
    showOptionWarn: boolean;
    filteredIds?: string[];
    filter: string;
    error: string;
}

export const ProductsIndex: FC<IProductsIndexProps> = (props) => {
    const { showOverlay, id, refs, size, ...events } = DnDEvents<IProductsContent>(
        props,
        "ELEMENT"
    );

    const classes: string[] = ["products", `products-${size}`];
    const productsContainer: string[] = ["products-container"];
    const { config } = useRestaurant();
    const { catObject, loading } = useProducts(props.filteredIds);
    const { images } = useImages();
    const style = ParseComponentStyle(props.content, ProductsContainerStyles, size, images);

    const bg: IPartialCSS<any> = {};
    let contrastedElements: IPartialCSS<any> = {};
    for (let key in BackgroundStyles) {
        if (style && key in style) {
            bg[key as never] = style[key as never];
            delete style[key as never];
        }
    }
    if (style && style.color) {
        bg.color = style.color;
    }

    const shadePercentage = useMemo(() => {
        if (!bg.backgroundColor) return 0;
        //INFO read the documentation of CalcContrast to see what these numbers are. (Hover cursor over function)
        const darkContrastDiff = CalcContrast(bg.backgroundColor, "#000000");
        if (darkContrastDiff > 50) return -25;
        if (darkContrastDiff < 12) return 60;
        if (darkContrastDiff < 20) return 25;

        return -25;
    }, [bg.backgroundColor]);
    contrastedElements = { ...bg };
    contrastedElements.backgroundColor = ColorShader(shadePercentage, bg.backgroundColor);

    const handleKeyDown = (e: KeyboardEvent): void => {
        if (e.key === "Escape") {
            if (props.selector.category?.parent_id) {
                const category = catObject[props.selector.category!.parent_id];
                props.setSelectorData(category);
            } else props.unsetSelector("category");
        }
    };

    const trimDescription = parseInt(props.content.trimDescriptionAfterLength || "100");

    useMountWithTriggers(() => {
        window.addEventListener("keydown", handleKeyDown, { passive: true, capture: true });
        return () => window.removeEventListener("keydown", handleKeyDown, true);
    }, [props.selector.category, catObject]);

    classes.push(`product-layout--${props.content.layoutType}`);
    const layout = props.content.layoutType as IProductLayouts;
    if (layout === "categorized") productsContainer.push("categorized-container");
    if (props.content.hideTileBackground) {
        bg.boxShadow = "none";
    }
    return (
        <div
            id={id}
            {...events}
            style={style}
            ref={refs.current[id]}
            className={productsContainer.join(" ")}
        >
            {showOverlay && <Overlay parent={props} unmountId={props.sectionUUID} />}

            {layout === "categorized" ? (
                <>
                    <TopbarCategories
                        isDarkTheme={config.is_dark_theme}
                        isRounded={config.is_rounded_btn}
                        filteredIds={props.filteredIds}
                        size={size}
                        style={bg}
                    />
                    <CategorizedProducts
                        {...props}
                        trimDescription={trimDescription}
                        useTrimOnCategorized={props.content.useTrimOnCategorized || false}
                    />
                </>
            ) : (
                <div className={classes.join(" ")} style={bg}>
                    <Loading loading={loading} isAbsolute />
                    <ProductSearcherView {...props} style={contrastedElements} />
                    {layout === "default_layout" && <DefaultCategoryMapperView {...props} />}
                    {layout === "tile_layout" && (
                        <TileCategoryMapperView
                            {...props}
                            contrastedElements={contrastedElements}
                        />
                    )}
                    <ProductMapperView
                        {...props}
                        layout={layout}
                        trimDescription={trimDescription}
                    />
                </div>
            )}
        </div>
    );
};
