import { useProducts } from "../Context/ProductsContext.provider";
import { IPartialCSS } from "sitebuilder-common";
import { FC, useState } from "react";
import { useRestaurant } from "../../../../../Contexts/Restaurant.context";
import { IProductsIndexProps } from "./Products.index";
import { ProductCategoryModel } from "../Models/ProductCategory.model";
import { ArrowRight, CaretDown, CaretUp } from "@carbon/icons-react";

interface ICategoryRowElementProps extends IProductsIndexProps {
    category: ProductCategoryModel;
    isMainCategory: boolean;
}

export const CategoryRowElement: FC<ICategoryRowElementProps> = ({
    category,
    isMainCategory,
    ...props
}) => {
    const { categories } = useProducts();
    const { config } = useRestaurant();

    const [isOpen, setisOpen] = useState<boolean>(!category.has_child);
    const [isMouseOver, setIsMouseOver] = useState<boolean>(false);

    const classes: string[] = ["category-row"];
    if (!isMainCategory) classes.push("sub-category");
    if (!category.has_child) classes.push("end-category");
    else classes.push("category-with-child");

    if (config.main_btn_bg_color) classes.push("no-hover-filter");

    const style: IPartialCSS<any> = {};
    if (config.main_btn_bg_color && isMouseOver && !category.has_child) {
        style.color = config.main_btn_bg_color;
    }
    if (props.selector.category?.id === category.id) {
        style.color = config.main_btn_bg_color;
    }

    return (
        <div
            className={classes.join(" ")}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (category.has_child) setisOpen(!isOpen);
                else props.setSelectorData(category);
            }}
            onMouseEnter={() => setIsMouseOver(true)}
            onMouseLeave={() => setIsMouseOver(false)}
            style={style}
        >
            <div className="category-row__name">{category.name}</div>
            <div className="category-row__control">
                {category.has_child ? isOpen ? <CaretUp /> : <CaretDown /> : <ArrowRight />}
            </div>

            {category.has_child && isOpen && (
                <div className="category-row__sub-categories">
                    {categories
                        .filter((cat) => cat.parent_id === category.id)
                        .map((cat, index) => (
                            <CategoryRowElement
                                {...props}
                                key={`${cat.id}-${cat.parent_id}-${index}-sub-category`}
                                isMainCategory={false}
                                category={cat}
                            />
                        ))}
                </div>
            )}
        </div>
    );
};
