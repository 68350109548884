import { ILang } from "../../../../Interfaces/ILang.type";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { useState } from "react";
import { IUrlGroup } from "./IUrlGroup.interface";
import { TextInput } from "../../InputFields/TextInput/TextInput.view";
import { IFieldBlur } from "../../../Editor/Utils/EditorHook.util";
import { SelectInput } from "../../InputFields/Select/SelectInput.view";
import { ExtraRegexes } from "../../../../Utils/ExtraRegex.util";
import { CheckboxInput } from "../../InputFields/Checkbox/Checkbox.view";
import { useTranslation } from "react-i18next";
import { ISelectOption, Regexes } from "xa-generics";

export interface IUrlGroupViewProps {
    defaultValues: Partial<IUrlGroup<any>>;
    onBlur: (data: IFieldBlur<IUrlGroup<any>>) => void;
}

const UrlGroupView: React.FC<IUrlGroupViewProps> = (props) => {
    const { pages } = useDom();
    const { t, i18n } = useTranslation<ILang>();
    const language = i18n.language;

    const [openOnNewTab, setOpenOnNewTab] = useState<boolean>(
        props.defaultValues.openOnNewTab || false
    );
    const [isExternal, setIsExternal] = useState<boolean>(props.defaultValues.isExternal || false);
    const [url, setUrl] = useState<string>(props.defaultValues.url || "");
    const [error, setError] = useState<string>("");

    const validate = (newValue: string): boolean => {
        let value = isExternal ? newValue : `/${newValue}`;

        if (isExternal) {
            if (Regexes.Url.test(value)) {
                if (error) setError("");
                return true;
            }
            setError(t<ILang>("invalid_url"));
            return false;
        } else {
            if (ExtraRegexes.Path.test(value)) {
                if (error) setError("");
                return true;
            } else setError(t<ILang>("invalid_route_format"));
            return false;
        }
    };

    const routeMap: ISelectOption[] = [];
    for (let page of pages) {
        if (page.draft || !page.getSeo(language)) continue;
        routeMap.push({ id: page.getSeo(language).url, name: page.name });
    }

    return (
        <>
            {!isExternal ? (
                <SelectInput
                    options={routeMap}
                    onChange={(id, value) => {
                        setUrl(value || "");
                        props.onBlur({ field: id, value });
                    }}
                    labelText={"URL"}
                    value={url}
                    id={"url"}
                />
            ) : (
                <TextInput
                    onChange={(id, value) => {
                        validate(value);
                        setUrl(value);
                    }}
                    onBlur={(data) => {
                        if (validate(data.value)) {
                            props.onBlur(data);
                        }
                    }}
                    labelText={"URL"}
                    value={url}
                    id={"url"}
                />
            )}
            <div className="global__error-wrapper">
                {error ? <div className="global__error">{error}</div> : null}
            </div>
            <CheckboxInput
                onBlur={(data) => {
                    setUrl("");
                    setIsExternal(data.value);
                    props.onBlur(data);
                }}
                value={isExternal}
                id={"isExternal"}
            />
            <CheckboxInput
                onBlur={(data) => {
                    setOpenOnNewTab(data.value);
                    props.onBlur(data);
                }}
                labelText={"open_in_new_tab"}
                value={openOnNewTab}
                id={"openOnNewTab"}
            />
        </>
    );
};

export default UrlGroupView;
