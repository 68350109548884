import { i18n } from "i18next";
import { ILang } from "../../../Interfaces/ILang.type";
import { SeoModel } from "sitebuilder-common";
import { TFunction } from "react-i18next";
import { IPageTypes } from "../Interfaces/IPage.type";
import { DraftModel } from "./Draft.model";
import { IDynamicObject } from "xa-generics";
import { RestaurantModel } from "sitebuilder-common";

export abstract class PageModel {
    constructor(seos?: IDynamicObject<any>[], rest?: RestaurantModel, draft?: IDynamicObject<any>) {
        if (!rest) return;

        if (seos instanceof Array) {
            this.seos = seos.map((seo) => {
                const model = new SeoModel(seo);
                return model;
            });
        }
        //This happens when a new page is created! An empty seo must be generated for it!
        if (this.seos.length === 0) {
            this.seos.push(new SeoModel({ lang: rest.primary_language }));
        }
        //Generate an empty seo model in case they don't exist.
        //Here, the url will be a unique uuidv4 and the isTemporary flag will be set to true.
        if (rest.secondary_language && !this.getSeo(rest.secondary_language)) {
            this.seos.push(new SeoModel({ lang: rest.secondary_language }));
        }

        if (draft) {
            this.draft = new DraftModel(draft);
        }
    }

    restaurant_id: string = "";
    page_id: string = "";
    page_type: IPageTypes = "";
    name: string = "";
    updated_at: string = "";
    created_at: string = "";
    is_active: boolean = true;
    is_main_page: boolean = false;
    draft: null | DraftModel = null;
    competition_image_id: null | string = "";
    competition_image_align: null | "top" | "bottom" = "top";

    seos: SeoModel[] = [];

    //Custom props
    type: "Page" = "Page";
    isCurrentPageDraft: boolean = false;

    public getSeo(lang: string | null): SeoModel {
        if (!lang) return new SeoModel();

        const seo = this.seos.find((seo) => seo.lang === lang)!;
        return seo;
    }

    public getUrl(i18n: i18n): string {
        const seo = this.getSeo(i18n.language);
        if (!seo) return "/";
        if (this.draft && this.isCurrentPageDraft) {
            if (seo.url === "/") return `/${i18n.t<ILang>("draft")}`;
            return `${seo.url}/${i18n.t<ILang>("draft")}`;
        }
        return seo.url;
    }

    public getBothUrls(i18n: i18n): [string, string] {
        const seo = this.getSeo(i18n.language);
        if (!seo || seo.url === "/") return ["/", `/${i18n.t<ILang>("draft")}`];

        return [seo.url, `${seo.url}/${i18n.t<ILang>("draft")}`];
    }

    public getBaseAndDraftUrls(lang: string, t: TFunction<ILang>): [string, string | void] {
        const seo = this.getSeo(lang);
        let urls: [string, string | void] = [seo.url, void 0];
        if (this.draft) {
            if (seo.url === "/") urls[1] = `/${t<ILang>("draft")}`;
            else urls[1] = `${seo.url}/${t<ILang>("draft")}`;
        }
        return urls;
    }
}
