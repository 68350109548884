import { ILang } from "../../../Interfaces/ILang.type";
import { useTexts } from "../../../Contexts/Texts.context";
import { DictModel } from "sitebuilder-common";
import { cloneDeep } from "lodash";
import { TextInput } from "../../UI/InputFields/TextInput/TextInput.view";
import { SelectInput } from "../../UI/InputFields/Select/SelectInput.view";
import { useRestaurant } from "../../../Contexts/Restaurant.context";
import { InlineLoading } from "../../UI/InlineLoading/InlineLoading.view";
import { useTranslation } from "react-i18next";
import { IUseFormResult } from "../../UseForm/UseForm.provider";
import { IDictionaryProps } from "../Controller/Dictionary.controller";
import { FC, createRef, useEffect, useMemo } from "react";
import { Add, ArrowLeft, Edit, Save, TrashCan } from "@carbon/icons-react";
import Button from "../../UI/Button/Button.view";
import Modal from "../../UI/Modal/Modal.view";

interface IDictionaryViewProps extends IDictionaryProps {
    form: IUseFormResult<DictModel, false>;
}

export const DictionaryView: FC<IDictionaryViewProps> = (props) => {
    const { t, i18n } = useTranslation<ILang>();
    const { restaurant } = useRestaurant();
    const { dict, texts, loading, deleteDictionary, saveDictionary, updateDictionary } = useTexts();

    const ref = createRef<HTMLDivElement>();

    const options = useMemo(() => {
        const opts = [];
        for (const text of texts) {
            let isPresent = false;
            for (const d of dict) {
                if (text.text_key === d.text_key) {
                    isPresent = true;
                    break;
                }
            }
            if (isPresent) continue;
            else opts.push(text);
        }
        return opts;
    }, [dict, texts]);

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollTo(0, 0);
        }
    }, [props.form.editor, ref]);

    return (
        <Modal isPassive size={"LG"} heading={t("texts")} onClose={props.onClose}>
            <div className="dictionary" ref={ref}>
                <Button
                    Icon={Add}
                    useContainer
                    align={"right"}
                    onClick={() => {
                        props.form.setEditor(new DictModel({ restaurant_id: restaurant.id }));
                    }}
                >
                    {t("add_new")}
                </Button>
                {props.form.editor && (
                    <form
                        autoSave={"off"}
                        autoComplete={"off"}
                        className="dictionary__form"
                        onSubmit={props.form.handleSubmit((values) => {
                            if (values.dictionary_id) {
                                updateDictionary(values);
                            } else {
                                saveDictionary(values);
                            }
                        })}
                    >
                        <SelectInput
                            required
                            id={"text_key"}
                            idAccessor={"text_key"}
                            placeholder={"select_text_key"}
                            onChange={props.form.handleChange}
                            value={props.form.editor.text_key}
                            nameAccessor={i18n.language === "hu" ? "hu" : "en"}
                            isDisabled={props.form.editor.dictionary_id ? true : false}
                            options={props.form.editor.dictionary_id ? texts : options}
                        />
                        <TextInput
                            required
                            id={"primary_text"}
                            onChange={props.form.handleChange}
                            value={props.form.editor.primary_text}
                        />
                        <TextInput
                            required={restaurant.secondary_language ? true : false}
                            id={"secondary_text"}
                            onChange={props.form.handleChange}
                            value={props.form.editor.secondary_text}
                        />

                        <div className="key-name">
                            <small>{t("text_key")}:</small>
                            <br />
                            <small>{props.form.editor.text_key}</small>
                        </div>

                        <div className="om-container --right">
                            <Button
                                type="button"
                                Icon={ArrowLeft}
                                kind={"SECONDARY"}
                                onClick={() => props.form.setEditor(null)}
                            >
                                {t("cancel")}
                            </Button>
                            <Button
                                Icon={loading ? <InlineLoading isAlternate /> : <Save />}
                                type="submit"
                            >
                                {t("save")}
                            </Button>
                        </div>
                    </form>
                )}
                <div className="dictionary__list">
                    <div className={"dictionary__list--element header-element"}>
                        <h6 className={"key"}>{t("text_key")}</h6>
                        <p className={"t"}>{t("primary_text")}</p>
                        <p className={"t"}>{t("secondary_text")}</p>
                    </div>
                    {dict.map((item, index) => (
                        <div
                            className={"dictionary__list--element"}
                            key={`${item.dictionary_id}-dictionary-element`}
                        >
                            <h5 className={"key"}>{item.text_key}</h5>
                            <p className={"t"}>{item.primary_text}</p>
                            <p className={"t"}>{item.secondary_text || "-"}</p>
                            <div className="controls">
                                <button
                                    disabled={loading ? true : false}
                                    className="controls__button edit"
                                    onClick={() => {
                                        props.form.setEditor(new DictModel(cloneDeep(item)));
                                    }}
                                >
                                    <Edit />
                                </button>
                                <button
                                    disabled={loading ? true : false}
                                    className="controls__button delete"
                                    onClick={() => deleteDictionary(item.dictionary_id)}
                                >
                                    <TrashCan />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Modal>
    );
};
