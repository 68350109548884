import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useCart } from "../Provider/Cart.provider";
import { Calendar } from "@carbon/icons-react";
import { CartRadioRow } from "./CartRadioRow.view";
import { useTranslation } from "react-i18next";
import moment from "moment";

interface ICartDeliveryAtViewProps {}

export const CartDeliveryAtView: FC<ICartDeliveryAtViewProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { form } = useCart();
    return (
        <div className={"cart-block cart__delivery-at"}>
            <h4 className="cart__title">{t<ILang>("delivery_at")}</h4>
            <CartRadioRow
                isSelected={!form.editor.is_preorder}
                labelText={"now"}
                onClick={() => form.handleChange("is_preorder", false)}
                className={form.editor.is_preorder ? "no-margin-bottom" : undefined}
            />
            <CartRadioRow
                isSelected={form.editor.is_preorder}
                labelText={"order_later"}
                noBorder
                onClick={() => form.handleChange("is_preorder", true)}
            >
                {form.editor.is_preorder && (
                    <div className="mock-datepicker">
                        {moment().format("YYYY. MM. DD HH:mm")} <Calendar />
                    </div>
                )}
            </CartRadioRow>
        </div>
    );
};
