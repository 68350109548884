import { Save } from "@carbon/icons-react";
import { ILang } from "../../../Interfaces/ILang.type";
import { useEnv } from "../../../Contexts/Environment.context";
import { useDom } from "../../DomTracker/Controller/DomTracker.provider";
import { useImages } from "../../../Contexts/Images.context";
import { ReSortNodes } from "../../DomTracker/Util/ResortNodes.util";
import { TemplatesDAO } from "../DAO/Templates.dao";
import { dataURLToBlob } from "blob-util";
import { InlineLoading } from "../../UI/InlineLoading/InlineLoading.view";
import { ReplaceImages } from "../Utils/ReplaceImages.util";
import { useRestaurant } from "../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import { ISaveBlockData } from "../Interfaces/ISaveBlockData.interface";
import { FindPageSource } from "../Utils/FindPageSource.util";
import { BuildImageLinks } from "../Utils/BuildImageLinks.util";
import { SanitizeSection } from "../Utils/SanitizeSection.util";
import { ISectionContent } from "../../DomMapper/Interfaces/ISectionComponent.interface";
import { clone, cloneDeep } from "lodash";
import { ITemplateContext } from "../Interfaces/ITemplateContext.interface";
import { ReIndexIfHasIndex } from "../../DomTracker/Util/Reindex.util";
import { TemplateBlockModel } from "../Model/TemplateBlock.model";
import { TemplateCategoryModel } from "../Model/TemplateCategory.model";
import { FindNonExistentImages } from "../Utils/FindNonExistentImages.util";
import { TemplateRestaurantModel } from "../Model/TemplateRestaurant.model";
import { IError, ILoading, Objectify, useDidMount } from "xa-generics";
import { Context, createContext, useState, useContext } from "react";
import html2canvas from "html2canvas";
import Modal from "../../UI/Modal/Modal.view";

/**
 * ## TemplateContext
 */
const TemplateContext: Context<ITemplateContext> = createContext<ITemplateContext>(null as any);

TemplateContext.displayName = "TemplateContext";

interface ITemplateContextProviderProps {}

/**
 * ## Template context provider component
 *
 */
export const TemplateContextProvider: React.FC<ITemplateContextProviderProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const env = useEnv();

    const { currentPage, asyncClonePage, pages, refs, setPages, setCurrentPage } = useDom();
    const { images, cloneTemplateSectImages } = useImages();
    const { restaurant } = useRestaurant();

    const [templateRestaurants, setTemplateRestaurants] = useState<TemplateRestaurantModel[]>([]);
    const [templateCategories, setTemplateCategories] = useState<TemplateCategoryModel[]>([]);
    const [draggedTemplateDOM, setDraggedTemplateDOM] = useState<TemplateBlockModel | null>(null);
    const [templateBlocks, setTemplateBlocks] = useState<TemplateBlockModel[]>([]);
    const [loading, setLoading] = useState<ILoading>(false);
    const [error, setError] = useState<IError>(null);

    const [saveBlockData, setSaveBlockData] = useState<null | ISaveBlockData>(null);

    const init = (): void => {
        setLoading(
            Promise.all([
                TemplatesDAO.loadRestaurants(),
                TemplatesDAO.loadCategories(),
                TemplatesDAO.loadBlocks()
            ])
                .then(([restaurants, categories, blocks]) => {
                    setTemplateRestaurants(restaurants);
                    setTemplateCategories(categories);
                    setTemplateBlocks(blocks);
                })
                .catch((error) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    const loadRestaurants = (cb?: () => void) => {
        setLoading(
            TemplatesDAO.loadRestaurants()
                .then((data) => {
                    setTemplateRestaurants(data);
                    if (cb) cb();
                })
                .catch((error) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    const loadBlocks = () => {
        setLoading(
            TemplatesDAO.loadBlocks()
                .then((data) => setTemplateBlocks(data))
                .catch((error) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    const saveRestaurant = async (data: TemplateRestaurantModel, cb: () => void): Promise<void> => {
        const formData = await screenshotPage(data);
        setLoading(
            (data.created_at
                ? TemplatesDAO.updateRestaurant(data.restaurant_id, formData)
                : TemplatesDAO.saveRestaurant(formData)
            )
                .then((newModel) => loadRestaurants(cb))
                .catch((error) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    const saveTemplateBlock = (formData: FormData): void => {
        TemplatesDAO.saveBlock(formData)
            .then((newModel) => {
                const state = clone(templateBlocks);
                state.push(newModel);
                setTemplateBlocks(state);
            })
            .catch((error) => console.error("ERR", error))
            .finally(() => {
                setSaveBlockData(null);
                setLoading(false);
            });
    };

    const imgOnLoadPromise = (img: HTMLImageElement): Promise<void> => {
        return new Promise((resolve, reject) => {
            img.onload = () => {
                resolve();
            };
        });
    };

    const buildSectionFormData = async (
        data: ISaveBlockData,
        blob: Blob,
        url: string
    ): Promise<FormData> => {
        const formData = new FormData();
        formData.append("image", blob, data.section_id);
        formData.append("preview_file_name", data.section_id);
        formData.append("preview_file_size", `${blob.size}`);
        formData.append("preview_mime_type", blob.type);
        formData.append("dom", JSON.stringify(data.dom));
        formData.append("origin_restaurant_id", data.restaurant_id);

        const img = document.createElement("img");
        img.src = url;
        await imgOnLoadPromise(img);
        formData.append("preview_width", `${img.width}`);
        formData.append("preview_height", `${img.height}`);

        return formData;
    };

    const buildRestFormData = async (
        data: TemplateRestaurantModel,
        blob: Blob,
        url: string
    ): Promise<FormData> => {
        const formData = new FormData();
        const img = document.createElement("img");
        img.src = url;
        await imgOnLoadPromise(img);
        formData.append("image", blob, `rest_template_${restaurant.id}`);
        formData.append("image_file_name", `rest_template_${restaurant.id}`);
        formData.append("image_file_size", `${blob.size}`);
        formData.append("image_mime_type", blob.type);
        formData.append("image_width", `${img.width}`);
        formData.append("image_height", `${img.height}`);
        formData.append("restaurant_id", restaurant.id);
        formData.append("description", data.description);
        formData.append("description_secondary", data.description_secondary);
        formData.append("preview_url", data.preview_url);
        formData.append("template_category_id", data.template_category_id);
        return formData;
    };

    const saveBlock = async (data: ISaveBlockData): Promise<void> => {
        try {
            const element = document.getElementById(data.section_id);
            if (!element) return setSaveBlockData(null);
            setLoading(true);

            const canvasElement = await html2canvas(document.body, {
                allowTaint: true,
                proxy: `${env.REST_API_URL}/image-proxy`,
                x: element.offsetLeft,
                y: element.offsetTop,
                width: element.clientWidth,
                height: element.clientHeight,
                ignoreElements: (element) => {
                    return (
                        element.classList.contains("modal-container") ||
                        element.classList.contains("sidemenu") ||
                        element.classList.contains("sidemenu__side-control") ||
                        element.classList.contains("overlay") ||
                        element.classList.contains("fixed-edit-page-button-container")
                    );
                }
            });
            const dataUrl = canvasElement.toDataURL();
            const blob = dataURLToBlob(dataUrl);
            const formData = await buildSectionFormData(data, blob, dataUrl);

            saveTemplateBlock(formData);
        } catch (error) {
            console.error(error);
            setLoading(false);
            setSaveBlockData(null);
            setError(error as IError);
        }
    };

    const screenshotPage = async (data: TemplateRestaurantModel): Promise<FormData> => {
        setLoading(true);
        const canvasElement = await html2canvas(document.body, {
            allowTaint: true,
            proxy: `${env.REST_API_URL}/image-proxy`,
            x: 0,
            y: 0,
            width: window.innerWidth,
            height: window.innerHeight,
            ignoreElements: (element) => {
                return (
                    element.classList.contains("modal-container") ||
                    element.classList.contains("sidemenu") ||
                    element.classList.contains("sidemenu__side-control") ||
                    element.classList.contains("overlay") ||
                    element.classList.contains("fixed-edit-page-button-container")
                );
            }
        });
        const dataUrl = canvasElement.toDataURL();
        const blob = dataURLToBlob(dataUrl);

        return await buildRestFormData(data, blob, dataUrl);
    };

    const removeRestaurant = (restaurant_id: string): void => {
        setLoading(
            TemplatesDAO.removeRestaurant(restaurant_id)
                .then(() => {
                    setTemplateRestaurants((current) => {
                        const state = clone(current);
                        const index = state.findIndex(
                            (model) => model.restaurant_id === restaurant_id
                        );
                        state.splice(index, 1);
                        return state;
                    });
                })
                .catch((error) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    const removeBlock = (template_id: string): void => {
        setLoading(
            TemplatesDAO.removeBlock(template_id)
                .then(() => {
                    setTemplateBlocks((current) => {
                        const state = clone(current);
                        const index = state.findIndex((model) => model.template_id === template_id);
                        state.splice(index, 1);
                        return state;
                    });
                })
                .catch((error) => setError(error))
                .finally(() => setLoading(false))
        );
    };

    const mountTemplateBlock = async (
        template: TemplateBlockModel,
        dropIndex?: number,
        defaults?: Partial<ISectionContent>
    ): Promise<void> => {
        const { pageIndex, src } = await FindPageSource(
            pages,
            currentPage,
            restaurant,
            setCurrentPage,
            asyncClonePage
        );
        if (pageIndex < 0 || !src[pageIndex].draft) {
            setDraggedTemplateDOM(null);
            return;
        }
        const state = cloneDeep(src);
        const templateImages = await TemplatesDAO.loadRestImages(template.origin_restaurant_id);
        const nonExistentImageIds = FindNonExistentImages(
            template.dom,
            images,
            Objectify(templateImages, "image_id")
        );
        if (nonExistentImageIds.length > 0) {
            const result = await cloneTemplateSectImages(template.template_id, nonExistentImageIds);
            const imageLinks = BuildImageLinks(result.restaurantImages, result.templateImages);
            ReplaceImages(template.dom, imageLinks);
        } else {
            const result = await TemplatesDAO.loadRestImages(template.origin_restaurant_id);
            const imageLinks = BuildImageLinks(images, Objectify(result, "image_id"));
            ReplaceImages(template.dom, imageLinks);
        }
        const sectionDOM = SanitizeSection(template.dom, refs);
        const baseIndex = state[pageIndex].draft!.draft_elements.dom[0]?.index || 0;
        const finalIndex = dropIndex !== undefined ? dropIndex : baseIndex;
        sectionDOM.index = finalIndex;
        if (defaults?.childOf) sectionDOM.content.childOf = defaults.childOf;
        if (defaults?.slideIndex) sectionDOM.content.slideIndex = defaults.slideIndex;
        ReIndexIfHasIndex(state[pageIndex].draft!.draft_elements.dom, finalIndex);
        state[pageIndex].draft!.draft_elements.dom.unshift(sectionDOM);
        ReSortNodes(state);
        setPages(state);
        setDraggedTemplateDOM(null);
        return;
    };

    useDidMount(init);

    return (
        <TemplateContext.Provider
            value={{
                setDraggedTemplateDOM,
                templateRestaurants,
                mountTemplateBlock,
                draggedTemplateDOM,
                templateCategories,
                setSaveBlockData,
                removeRestaurant,
                loadRestaurants,
                templateBlocks,
                saveRestaurant,
                saveBlockData,
                removeBlock,
                loadBlocks,
                setError,
                loading,
                error,
                init
            }}
        >
            {props.children}

            {saveBlockData && (
                <Modal
                    allowEnterKeyDown={!loading}
                    heading={t<ILang>("warning")}
                    onClose={() => setSaveBlockData(null)}
                    onSubmit={() => saveBlock(saveBlockData)}
                    submitIcon={loading ? <InlineLoading /> : Save}
                >
                    {t<ILang>("save_block_text")}
                </Modal>
            )}
        </TemplateContext.Provider>
    );
};

export const useTemplate = (): ITemplateContext => useContext(TemplateContext);
