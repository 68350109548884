import { Context, createContext, useState, useContext } from "react";
import { IDynamicObject, useAfterTriggerChanged } from "xa-generics";
import { cloneDeep, isEmpty, isEqual } from "lodash";
import { IEditor, IEditorContext } from "../Interfaces/IEditorContext.interface";
import { IExistingDragData } from "../../DomTracker/Interfaces/IDomTypes.interface";
import { DomlessPageModel } from "../../DomTracker/Model/DomlessPage.model";
import { useTranslation } from "react-i18next";
import { Checkmark } from "@carbon/icons-react";
import { useDom } from "../../DomTracker/Controller/DomTracker.provider";
import { ILang } from "../../../Interfaces/ILang.type";
import Modal from "../../UI/Modal/Modal.view";

/**
 * ## EditorContext
 */
const EditorContext: Context<IEditorContext> = createContext<IEditorContext>(null as any);

EditorContext.displayName = "EditorContext";

interface IEditorContextProviderProps {}

/**
 * ## Editor context provider component
 *
 */
export const EditorContextProvider: React.FC<IEditorContextProviderProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { size, findNodeByUUID } = useDom();
    const [editor, setEditor] = useState<IEditor>(null);
    const [changes, setChanges] = useState<IDynamicObject<true>>({});
    const [navigateEvent, setNavigateEvent] = useState<Function | null>(null);
    const [pageEditor, setPageEditor] = useState<DomlessPageModel | null>(null);
    const [dragOrigin, setDragOrigin] = useState<IExistingDragData | null>(null);

    useAfterTriggerChanged(() => {
        if (editor) {
            const newDefault = findNodeByUUID(editor.uuid);
            if (!isEqual(newDefault, editor)) {
                setEditor(cloneDeep(newDefault));
            }
        }
    }, [size]);

    return (
        <EditorContext.Provider
            value={{
                hasChanges: !isEmpty(changes),
                setNavigateEvent,
                setDragOrigin,
                setPageEditor,
                dragOrigin,
                pageEditor,
                setChanges,
                setEditor,
                editor
            }}
        >
            {props.children}
            {navigateEvent && (
                <Modal
                    size={"XS"}
                    isDarkened
                    heading={t<ILang>("warning")}
                    submitText={t<ILang>("yes")}
                    onClose={() => setNavigateEvent(null)}
                    submitIcon={Checkmark}
                    onSubmit={() => {
                        setChanges({});
                        navigateEvent();
                        setNavigateEvent(null);
                    }}
                >
                    {t<ILang>("navigate_question")}
                </Modal>
            )}
        </EditorContext.Provider>
    );
};

export const useEditor = (): IEditorContext => useContext(EditorContext);
