import { clone } from "lodash";
import { ITextShadow } from "../Components/UI/InputGroups/TextShadow/ITextShadow.interface";
import { TextShDefaults } from "../Components/UI/InputGroups/TextShadow/Defaults.static";

export const ParseTextShadow = (shadow?: string): ITextShadow => {
    if (!shadow) return TextShDefaults;

    const result = clone(TextShDefaults);

    const content = shadow;
    const values = content.split(" ");
    let x = values[0] || "0";
    x = x.substring(0, x.length - 2);
    let y = values[1] || "0";
    y = y.substring(0, y.length - 2);
    let blur = values[2] || "0";
    blur = blur.substring(0, blur.length - 2);

    result.x = x;
    result.y = y;
    result.textBlur = blur;
    if (values[3]) result.textColor = values[3];

    return result;
};
