import { ITab } from "../Interfaces/ITab.interface";
import { ILang } from "../../../../Interfaces/ILang.type";
import { Slugify } from "xa-generics";
import { NavLink } from "react-router-dom";
import { IPartialCSS } from "sitebuilder-common";
import { useLocation } from "react-router";
import { FC, useState } from "react";
import { IProfileTabs } from "../Interfaces/IProfileTabs.type";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import { ProfileMyDataView } from "./ProfileMyData.view";
import { ProfilePrevOrders } from "./ProfilePrevOrders.view";
import { IProfileIndexProps } from "./Profile.index";
import { ProfileAddressesView } from "./ProfileAddresses.view";

interface IProfileTabulatorViewProps extends IProfileIndexProps {}

export const ProfileTabulatorView: FC<IProfileTabulatorViewProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { config } = useRestaurant();

    const style: IPartialCSS<any> = {};

    if (config) {
        if (config.main_btn_bg_color) {
            style.backgroundColor = config.main_btn_bg_color;
        }
        if (config.main_btn_color) {
            style.color = config.main_btn_color;
        }
    }
    if (props.styleData) {
        style.backgroundColor = props.styleData.buttons.backgroundColor || "#490151";
        style.color = props.styleData.buttons.color || "#ffffff";
    }

    const PROFILE_TABS: ITab[] = [
        {
            id: "past_orders",
            hash: `#${Slugify(t<ILang>("past_orders"))}`,
            component: ProfilePrevOrders
        },
        {
            id: "addresses",
            hash: `#${Slugify(t<ILang>("addresses"))}`,
            component: ProfileAddressesView
        },
        {
            id: "my_data",
            hash: `#${Slugify(t<ILang>("my_data"))}`,
            component: ProfileMyDataView
        }
    ];

    const { hash } = useLocation();

    const [tabId, setTabId] = useState<IProfileTabs>(
        (function (): IProfileTabs {
            if (!hash) return PROFILE_TABS[0].id;
            for (let tab of PROFILE_TABS) {
                if (tab.hash === hash) return tab.id;
            }
            return PROFILE_TABS[0].id;
        })()
    );

    return (
        <div className={"profile-tabs"}>
            <div className="profile-tabs__links">
                {PROFILE_TABS.map((tab, index) => {
                    const classes: string[] = ["profile-tabs__links--link"];
                    const isActive: boolean = tabId === tab.id;
                    if (isActive) classes.push("active-tab");

                    const activeStyle = isActive ? style : undefined;

                    return (
                        <NavLink
                            to={tab.hash}
                            replace={true}
                            style={activeStyle}
                            key={`${index}-${tab.id}-tab-link`}
                            className={classes.join(" ")}
                            onClick={() => setTabId(tab.id)}
                        >
                            {t<ILang>(tab.id)}
                        </NavLink>
                    );
                })}
            </div>
            <div className="profile-tabs__body">
                {PROFILE_TABS.map((tab, index) => {
                    if (tab.id !== tabId) return null;

                    return <tab.component key={`${index}-${tab.id}-tab-body`} {...props} />;
                })}
            </div>
        </div>
    );
};
