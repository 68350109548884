import { ILang } from "../../../Interfaces/ILang.type";
import { useDom } from "../../DomTracker/Controller/DomTracker.provider";
import { cloneDeep } from "lodash";
import { useEditor } from "../../Editor/Controller/EditorContext.provider";
import { Checkmark } from "@carbon/icons-react";
import { useTemplate } from "../../Templates/Provider/Template.provider";
import { ITargetKeys } from "../../DomTracker/Interfaces/ITargetKeys.interface";
import { IColumnEditor } from "../../DomMapper/Interfaces/IColumnComponent.interface";
import { isSpecialType } from "../../DomMapper/Util/IsSpecialType.util";
import { ISectionEditor } from "../../DomMapper/Interfaces/ISectionComponent.interface";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { SPECIAL_DOM_DEFAULTS } from "../Static/SpecialDomDefaults.static";
import { IComponentDnDContext } from "../Interfaces/IComponentDnDContext.interface";
import { Context, createContext } from "react";
import {
    IColumn,
    IDomElement,
    IAllDomTypes,
    ISpecialDomKeys,
    ISection,
    IAllDomKeys
} from "../../DomTracker/Interfaces/IDomTypes.interface";
import Modal from "../../UI/Modal/Modal.view";

/**
 * ## ComponentDnDContext
 */
const ComponentDnDContext: Context<IComponentDnDContext> = createContext<IComponentDnDContext>(
    null as any
);

ComponentDnDContext.displayName = "ComponentDnDContext";

export interface IComponentDnDProps {}

export const ComponentDnDContextProvider: React.FC<IComponentDnDProps> = (props) => {
    const { setEditor, dragOrigin, setDragOrigin, setChanges } = useEditor();
    const { mountTemplateBlock, draggedTemplateDOM } = useTemplate();
    const {
        refs,
        pages,
        draggedNode,
        currentPage,
        capturedNode,
        mountSection,
        mountElement,
        moveComponent,
        setDraggedNode,
        findNodeByUUID,
        mountSpecialElement
    } = useDom<ISectionEditor | IColumnEditor>();
    const { t } = useTranslation<ILang>();
    const [showWarning, setShowWarning] = useState<boolean>(false);

    const calcPositionAndIndex = (
        e: React.DragEvent<HTMLDivElement>,
        capturedNode: IAllDomTypes
    ): number => {
        const targetNode = refs.current[capturedNode.uuid].current;
        if (!targetNode) return 0;

        //clientHeight doesn't include borders, scrollbars, and margins, so it should be a 'fallback' option only.
        //offsetHeight includes them.
        const halfOfDropTargetBorderWidth: number = 1.5;
        const targetOffsTop = targetNode.offsetTop;
        const pageY = e.pageY;
        let halfHeight!: number;
        if (isSpecialType(draggedNode)) {
            halfHeight = targetOffsTop + targetNode.offsetHeight / 2 + halfOfDropTargetBorderWidth;
        } else {
            const rectTop = targetNode.parentNode?.parentElement?.offsetTop || 0;
            const halfOffsHeight: number = targetNode.offsetHeight / 2;
            halfHeight = targetOffsTop + rectTop + halfOffsHeight + halfOfDropTargetBorderWidth;
        }
        const node = findNodeByUUID(targetNode.id);
        let newIndex: number = 0;
        if (pageY > halfHeight) newIndex = node.index + 1;
        else newIndex = node.index;
        return newIndex;
    };

    const collectKeys = (element: IDomElement | IColumn): ITargetKeys => {
        let keys: ITargetKeys = {};
        //If the target is another element
        if ("columnUUID" in element) keys.columnUUID = element.columnUUID;
        else keys.columnUUID = element.uuid; //else the target is blank space in a column

        if (element.sectionUUID) keys.sectionUUID = element.sectionUUID;
        return keys;
    };

    const getSectionParams = (
        e: React.DragEvent<HTMLDivElement>
    ): { index: number; childOf: string; slideIndex?: number } | null => {
        if (!capturedNode.current || !draggedNode) return null;
        const captured = capturedNode.current;
        let index = 0;
        let childOf = "";

        if (captured === "PAGE_CONTAINER") {
            const page = pages.find((page) => page.page_id === currentPage.page_id);
            if (!page) return null;
            index = page.elements.dom.length;
        } else {
            if (
                "sectionUUID" in captured &&
                draggedNode === "Section" &&
                captured.type === "SlideShow"
            ) {
                childOf = captured.sectionUUID;
                index = 100000;
            } else if (captured.type === "Section" && captured.content.childOf) {
                childOf = captured.content.childOf;
                index = 100000; //INFO this is set to a large number because its index can be anything but shouldn't be the lowest on the page.
            } else {
                index = calcPositionAndIndex(e, captured);
            }
        }
        return { index, childOf };
    };

    const alterChanges = (domType: IAllDomKeys): void => {
        setChanges((current) => {
            const state = { ...current };
            state[domType] = true;
            return state;
        });
    };

    const handleSectionDragEnd = (e: React.DragEvent<HTMLDivElement>): void => {
        const result = getSectionParams(e);
        if (!result) return;

        const { index, childOf } = result;

        if (dragOrigin) {
            alterChanges(dragOrigin.type);
            moveComponent(dragOrigin, {}, index);
            setDraggedNode(null);
            return setDragOrigin(null);
        }
        if (draggedTemplateDOM) {
            alterChanges(draggedTemplateDOM.dom.type);
            mountTemplateBlock(cloneDeep(draggedTemplateDOM), index, { childOf });
            setDraggedNode(null);
            return;
        }
        if (draggedNode === "Section") {
            alterChanges("Section");
            const section = mountSection(index, { childOf });
            setEditor(section);
            setDraggedNode(null);
        } else {
            alterChanges(draggedNode as ISpecialDomKeys);
            const element = mountSpecialElement(
                draggedNode as ISpecialDomKeys,
                index,
                SPECIAL_DOM_DEFAULTS[draggedNode!]
            );
            setEditor(element);
            setDraggedNode(null);
        }
    };

    const handleItemDragEnd = (e: React.DragEvent<HTMLDivElement>): void => {
        const captured = capturedNode.current;
        if (!captured || !draggedNode) return;
        if (captured === "PAGE_CONTAINER") {
            return setShowWarning(true);
        }
        let index = calcPositionAndIndex(e, captured);
        const keys = collectKeys(captured as IDomElement | IColumn);

        alterChanges(draggedNode);

        if (dragOrigin) {
            moveComponent(dragOrigin, keys, index);
            setDragOrigin(null);
            setDraggedNode(null);
            return;
        }

        const element = mountElement(draggedNode, index, keys);
        setEditor(element);
        setDraggedNode(null);
    };

    const moveSectionByArrow = (uuid: string, dir: "UP" | "DOWN"): void => {
        const node = findNodeByUUID(uuid) as ISection;
        let previousIndex = 0;
        let maxIndex = 0;
        let nextIndex = 0;

        for (const page of pages) {
            if (!page.draft || page.page_id !== currentPage.page_id) continue;
            const listRef = page.draft.draft_elements.dom;
            maxIndex = listRef[listRef.length - 1]?.index || 0;

            for (let i = 0; i < listRef.length; i++) {
                if (listRef[i].uuid === node.uuid) {
                    if (listRef[i - 1] !== undefined) {
                        previousIndex = listRef[i - 1].index - 1;
                        if (previousIndex < 0) previousIndex = 0;
                    }
                    if (listRef[i + 1]?.index !== undefined) {
                        nextIndex = listRef[i + 1].index + 1;
                    } else nextIndex = 0;
                    break;
                }
            }
        }
        if (node.index === 0 && dir === "UP") return;
        if ((!nextIndex || maxIndex === nextIndex) && dir === "DOWN") return;

        alterChanges(node.type);

        if (dir === "UP") moveComponent(node, {}, previousIndex);
        else moveComponent(node, {}, nextIndex + 1);
    };

    return (
        <ComponentDnDContext.Provider
            value={{
                handleSectionDragEnd,
                moveSectionByArrow,
                handleItemDragEnd
            }}
        >
            {showWarning && (
                <Modal
                    size={"XS"}
                    submitIcon={Checkmark}
                    submitText={t<ILang>("ok")}
                    heading={t<ILang>("warning")}
                    onClose={() => setShowWarning(false)}
                    onSubmit={() => setShowWarning(false)}
                >
                    {t<ILang>("warn_missing_section")}
                </Modal>
            )}
            {props.children}
        </ComponentDnDContext.Provider>
    );
};

export const useComponentDnD = () => useContext(ComponentDnDContext);
