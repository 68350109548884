import { Width } from "../../UI/InputGroups/Width/Width.view";
import { Height } from "../../UI/InputGroups/Height/Height.view";
import { Margins } from "../../UI/InputGroups/Margins/Margins.view";
import { Paddings } from "../../UI/InputGroups/Paddings/Paddings.view";
import { EditorHook } from "../Utils/EditorHook.util";
import { ImageInput } from "../../UI/InputFields/ImageInput/Controller/ImageInput.controller";
import { IDomElement } from "../../DomTracker/Interfaces/IDomTypes.interface";
import { ITableReserverEditors } from "../../DomMapper/Interfaces/ITableReserverComponent.interface";
import { InputGroupContextProvider } from "../../UI/InputGrouper/InputGroup.provider";
import BackgroundGroupView from "../../UI/InputGroups/Background/BackgroundGroup.view";
import FormSeparatorView from "../../UI/FormSeparator/FormSeparator.view";
import AlignPickerView from "../../UI/InputFields/AlignPicker/AlignPicker.view";
import ComponentText from "../../UI/InputGroups/ComponentText/ComponentText.view";
import FontColor from "../../UI/InputGroups/FontInputs/FontColor.input";
import Font from "../../UI/InputGroups/FontInputs/Font.view";

export interface ITableReserverEditorView {
    editor: IDomElement;
}

const TableReserverEditorView: React.FC<ITableReserverEditorView> = (props) => {
    const { onBlur, onClear, units, defaults, onSpecialRuleClear } =
        EditorHook<ITableReserverEditors>();

    return (
        <InputGroupContextProvider defaultActive={"Font"}>
            <FormSeparatorView
                defKey={"TableReserver"}
                defaultGroup={
                    <>
                        <ComponentText
                            defaultValues={defaults}
                            defaultText={"reserve_table_desc"}
                            type={"TEXT_AREA"}
                            onBlur={onBlur}
                        />
                        <ImageInput
                            id={"src"}
                            onChange={(field, value) => onBlur({ field, value })}
                            labelText={"Image"}
                            value={defaults.src}
                            onReset={onSpecialRuleClear}
                        />
                        <AlignPickerView
                            id={"textAlign"}
                            onBlur={onBlur}
                            defaultValue={defaults.textAlign}
                        />
                        <FontColor
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Font
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <BackgroundGroupView
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                    </>
                }
                advancedGroup={
                    <>
                        <Paddings
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Margins
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Height
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                            defaultValues={defaults}
                        />
                        <Width
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                    </>
                }
            />
        </InputGroupContextProvider>
    );
};

export default TableReserverEditorView;
