import { IDynamicObject } from "xa-generics";
import { IPartialCSS } from "sitebuilder-common";
import { IFieldBlur } from "../../Editor/Utils/EditorHook.util";
import { ISizes } from "sitebuilder-common";

type IConstraint = {
    settings: IDynamicObject<any>;
    draftSettings: IDynamicObject<any>;
};

export const WidgetDataTransform = (
    root: IConstraint,
    data: IFieldBlur<any>,
    widgetProp: string,
    size: ISizes,
    isInStyles: boolean
): void => {
    const { field, value } = data;
    const settings = { ...root.draftSettings } || {};

    if (!settings[widgetProp]) settings[widgetProp] = {};

    if (!isInStyles) {
        settings[widgetProp] = value;
    } else {
        const cssRule = field as keyof IPartialCSS;
        if (!settings[widgetProp][cssRule]) settings[widgetProp][cssRule] = {};
        settings[widgetProp][cssRule][size] = data.value;
    }
    root.draftSettings = settings;
};
