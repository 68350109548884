import {
    IColumn,
    IDomKeys,
    ISection,
    IAllDomKeys,
    IDomElement,
    IAllDomTypes
} from "../Interfaces/IDomTypes.interface";
import { ITargetKeys } from "../Interfaces/ITargetKeys.interface";
import { PageDataModel } from "../Model/PageData.model";
import { ICommonContent } from "sitebuilder-common";
import { ISectionCustoms } from "../../DomMapper/Interfaces/ISectionComponent.interface";
import { ReIndexIfHasIndex } from "./Reindex.util";

const generateSection = (
    clonedPage: PageDataModel,
    uuid: string,
    newNodeType: IAllDomKeys,
    index: number,
    isSpecial: boolean = false,
    defaultContent?: Partial<ISectionCustoms>
): ISection | null => {
    if (newNodeType !== "Section") return null;
    ReIndexIfHasIndex(clonedPage.draft!.draft_elements.dom, index);
    const section: ISection = {
        type: "Section",
        uuid,
        index,
        content: { ...defaultContent, columnCount: 1 },
        elements: [],
        isSpecial
    };
    clonedPage.draft!.draft_elements.dom.push(section);
    return section;
};

const generateCol = (
    clonedSection: ISection,
    uuid: string,
    newNodeType: IAllDomKeys,
    index: number,
    isSpecial: boolean = false
): IColumn | null => {
    if (newNodeType !== "Column") return null;
    ReIndexIfHasIndex(clonedSection.elements, index);
    const column: IColumn = {
        type: "Column",
        uuid,
        index,
        content: {},
        sectionUUID: clonedSection.uuid,
        elements: [],
        isSpecial
    };
    clonedSection.elements.push(column);
    return column;
};

const generateElement = (
    clonedColumn: IColumn,
    uuid: string,
    newNodeType: IAllDomKeys,
    index: number,
    defaultContent?: ICommonContent
): IDomElement => {
    ReIndexIfHasIndex(clonedColumn.elements, index);
    const element: IDomElement = {
        type: newNodeType as IDomKeys,
        uuid,
        index,
        content: defaultContent || {},
        sectionUUID: clonedColumn.sectionUUID,
        columnUUID: clonedColumn.uuid
    };
    clonedColumn.elements.push(element);
    return element;
};

/**
 * ## MountNewNode
 *
 * This mounts a new node into the correct container
 * based on the passed parameters. You must pass a **deep-cloned**
 * state to it to avoid bugs.
 */
export const MountNewNode = (
    uuid: string,
    clonedState: PageDataModel[],
    newNodeType: IAllDomKeys,
    index: number,
    parentKeys: ITargetKeys & { page_id: string },
    isSpecial: boolean = false,
    defaultContent?: any
): IAllDomTypes | null => {
    if (!parentKeys.page_id) {
        throw new Error("Any component related to a page must have a page_id in the parentKeys!");
    }

    for (let page of clonedState) {
        if (page.page_id !== parentKeys.page_id) continue;
        if (!page.draft) continue;
        //Create section if type and page id match and return
        const section = generateSection(page, uuid, newNodeType, index, isSpecial, defaultContent);
        if (section) return section;

        if (!parentKeys.sectionUUID) {
            throw new Error("Columns and Elements must have a sectionUUID!");
        }

        for (let section of page.draft.draft_elements.dom) {
            if (section.uuid !== parentKeys.sectionUUID) continue;
            //Create column if type, page id, and section id match and return
            const column = generateCol(section, uuid, newNodeType, index, isSpecial);
            if (column) return column;

            if (!parentKeys.columnUUID) {
                throw new Error("Elements must have a columnUUID!");
            }

            for (let column of section.elements) {
                if (column.uuid !== parentKeys.columnUUID) continue;

                const element = generateElement(column, uuid, newNodeType, index, defaultContent);
                return element;
            }
        }
    }

    return null;
};
