import { IProductsStyles } from "../Interfaces/IProductComponent.interface";
import {
    BackgroundImageStyles,
    BackgroundStyles,
    PaddingStyles,
    ColorStyles,
    FontStyles
} from "./GroupsStyles.static";

export const ProductsContainerStyles: Required<IProductsStyles<true>> = {
    ...FontStyles,
    ...ColorStyles,
    ...PaddingStyles,
    ...BackgroundStyles,
    ...BackgroundImageStyles
};
