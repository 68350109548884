import { ISizes } from "sitebuilder-common";
import { SizeOrder } from "sitebuilder-common";
import { SOCIAL_CSS_BY_NODE_COUNT } from "../Static/SocialCSSByNodeCount.static";

export const ResolveSocialCSSBySize = (
    size: ISizes,
    length: number
): { gridTemplateColumns: string; maxWidth: string } => {
    if (size === "xl") {
        return SOCIAL_CSS_BY_NODE_COUNT.xl![length];
    }

    if (!SOCIAL_CSS_BY_NODE_COUNT[size]) {
        if (SOCIAL_CSS_BY_NODE_COUNT.xs && SizeOrder.xs > SizeOrder[size]) {
            return SOCIAL_CSS_BY_NODE_COUNT.xs[length];
        }
        if (SOCIAL_CSS_BY_NODE_COUNT.sm && SizeOrder.sm > SizeOrder[size]) {
            return SOCIAL_CSS_BY_NODE_COUNT.sm[length];
        }
        if (SOCIAL_CSS_BY_NODE_COUNT.md && SizeOrder.md > SizeOrder[size]) {
            return SOCIAL_CSS_BY_NODE_COUNT.md[length];
        }
        if (SOCIAL_CSS_BY_NODE_COUNT.lg && SizeOrder.lg > SizeOrder[size]) {
            return SOCIAL_CSS_BY_NODE_COUNT.lg[length];
        }
        return SOCIAL_CSS_BY_NODE_COUNT.xl![length];
    }

    return SOCIAL_CSS_BY_NODE_COUNT[size]![length];
};
