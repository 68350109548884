import { FC } from "react";
import { useApp } from "../../Provider/App.context";
import { TextArea } from "../../../UI/InputFields/TextInput/TextArea.view";
import { ImageInput } from "../../../UI/InputFields/ImageInput/Controller/ImageInput.controller";
import { CheckboxInput } from "../../../UI/InputFields/Checkbox/Checkbox.view";
import { AppEditorGroup } from "../AppEditorGroup.view";
import ColorInput from "../../../UI/InputFields/ColorInput/ColorInput.view";
import { TextInput } from "../../../UI/InputFields/TextInput/TextInput.view";

interface IAppHeaderFormProps {}

export const AppHeaderForm: FC<IAppHeaderFormProps> = (props) => {
    const { forms } = useApp<"header">();
    const { editor, handleChange, fieldErrors } = forms.header;

    return (
        <AppEditorGroup groupKey={"header"} nameKey={"iframe_header"}>
            <ImageInput
                id={"image"}
                aspectRatio={16 / 9}
                value={editor.image}
                onChange={handleChange}
                labelText={"sidemenu_logo"}
            />
            <TextInput
                isNumeric
                id={"maxImageWidth"}
                errors={fieldErrors}
                onChange={(id, value) => {
                    const numValue = parseInt(value);
                    if (value === "" || (!isNaN(numValue) && numValue < 100)) {
                        handleChange(id, value);
                    }
                }}
                unit={<div>%</div>}
                value={editor.maxImageWidth}
            />
            <ColorInput
                isClearable
                withValueSync
                errors={fieldErrors}
                id={"backgroundColor"}
                onChange={handleChange}
                value={editor.backgroundColor}
            />
            <ColorInput
                isClearable
                id={"color"}
                withValueSync
                errors={fieldErrors}
                value={editor.color}
                onChange={handleChange}
            />
            <ImageInput
                id={"backgroundImage"}
                onChange={handleChange}
                labelText={"app_header_logo"}
                value={editor.backgroundImage}
            />
            <CheckboxInput
                id={"isNameHidden"}
                errors={fieldErrors}
                onChange={handleChange}
                value={editor.isNameHidden}
            />
            <TextArea
                id={"menuSVG"}
                errors={fieldErrors}
                onChange={handleChange}
                value={editor.menuSVG}
            />
            <TextArea
                id={"backSVG"}
                errors={fieldErrors}
                onChange={handleChange}
                value={editor.backSVG}
            />
            <TextArea
                id={"cartSVG"}
                errors={fieldErrors}
                onChange={handleChange}
                value={editor.cartSVG}
            />
        </AppEditorGroup>
    );
};
