import { ILang } from "../../../../Interfaces/ILang.type";
import { IFormErrors } from "../../../UseForm/IUseForm.interface";
import { formatAlpha } from "../../../../Utils/FormatAlpha.util";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { Regexes, useDidMount, useMountWithTriggers, useTimeout } from "xa-generics";

export interface IColorInputProps<Fields extends object> {
    value: string;
    id: keyof Fields;
    noLabel?: boolean;
    labelText?: ILang;
    className?: string;
    isClearable?: boolean;
    withValueSync?: boolean;
    errors: IFormErrors<Fields>;
    onChange: (id: keyof Fields, value: string) => void;
}

const ColorInput = <Fields extends object>(props: IColorInputProps<Fields>) => {
    const { t } = useTranslation<ILang>();
    const wrapperStyle: string[] = ["wrapper", "color-wrapper"];
    const { setTm } = useTimeout();

    const [alpha, setAlpha] = useState<string>("255");
    const [value, setValue] = useState<string>("");
    const [error, setError] = useState<boolean>(false);

    useDidMount(() => {
        const alphaValue = props.value ? parseInt(props.value.slice(7), 16) : 255;
        const colorValue = props.value.slice(0, 7);
        setAlpha(alphaValue.toString());
        setValue(colorValue);
    });

    useMountWithTriggers(() => {
        if (props.withValueSync) {
            const alphaValue = props.value ? parseInt(props.value.slice(7), 16) : 255;
            const colorValue = props.value.slice(0, 7);
            setAlpha(alphaValue.toString());
            setValue(colorValue);
        }
    }, [props.value, props.withValueSync]);

    const parsedAlpha = useMemo(() => {
        return formatAlpha(parseInt(alpha).toString(16));
    }, [alpha]);
    if (props.className) wrapperStyle.push(props.className);

    const onRawChange = (newColorString: string): void => {
        if (!newColorString) {
            if (props.isClearable) {
                if (error) setError(false);
                return props.onChange(props.id, "");
            }
            //If the field is empty by default as well, do nothing.
            if (!props.value) return;
        }
        //Show local error for invalid colors
        if (!Regexes.Color.test(newColorString)) return setError(true);
        else if (error) setError(false);

        const finalValue = `${newColorString}${parsedAlpha}`;
        props.onChange(props.id, finalValue);
    };
    const onColorChange = (newColorString: string): void => {
        if (!Regexes.Color.test(newColorString)) return setError(true);
        else if (error) setError(false);
        setValue(newColorString);
        const finalValue = `${newColorString}${parsedAlpha}`;
        //To prevent too many change calls if the user keeps dragging around the color picker
        setTm(
            () => {
                props.onChange(props.id, finalValue);
            },
            400,
            "colorPicker"
        );
    };
    const onAlphaChange = (newAlpha: string): void => {
        setAlpha(newAlpha);
        //Skip calling external update if the color is still invalid.
        if (!value) return;
        else {
            const parsedAlpha = formatAlpha(parseInt(newAlpha).toString(16));
            const finalValue = `${value}${parsedAlpha}`;
            setTm(
                () => {
                    props.onChange(props.id, finalValue);
                },
                400,
                "alphaChange"
            );
        }
    };

    const id = props.id as ILang;
    const isValid = Regexes.Color.test(value);

    return (
        <div className={wrapperStyle.join(" ")} onDrag={(e) => e.stopPropagation()}>
            {!props.noLabel && (
                <label htmlFor={id} className="input-label">
                    <span>{t<ILang>(props.labelText || id)}</span>
                </label>
            )}
            <div className="color-picker-container">
                <input
                    id={id}
                    type={"text"}
                    name={id}
                    value={value}
                    placeholder={"#8c7b9a"}
                    className={"text-input"}
                    onChange={(e) => setValue(e.currentTarget.value)}
                    onBlur={(e) => onRawChange(e.currentTarget.value)}
                    autoComplete={"on"}
                />
                <div className="color-picker">
                    <input
                        type="color"
                        className="color-picker-input"
                        value={isValid ? value : "#ffffff"}
                        onChange={(e) => onColorChange(e.target.value)}
                    />
                    {!isValid && <div className="no-color"></div>}
                </div>
                <div className="alpha-slider">
                    <div className="input-label">{t<ILang>("alpha")}</div>
                    <input
                        type="range"
                        value={alpha}
                        onChange={(e) => onAlphaChange(e.target.value)}
                        className={"alpha-input"}
                        min={0}
                        max={255}
                    />
                </div>
            </div>
            {error && <div className="global__error">{t<ILang>("invalid_color")}</div>}
        </div>
    );
};

export default ColorInput;
