import { ISetState, useMountWithTriggers } from "xa-generics";
import { IMultiSelectInputProps } from "../MultiSelect.view";

interface IParams<O extends object> {
    search: string;
    isOpen: boolean;
    uniqueKey: string;
    onClose: () => void;
    localOptions: O[];
    contextMenuSelected: number;
    setIsOpen: ISetState<boolean>;
    setContextMenuSelected: ISetState<number>;
    optionRefs: React.MutableRefObject<{ [key: number]: HTMLDivElement | null }>;
}
interface IReturn {
    handleClick: (e: MouseEvent) => void;
    handleKeyPress: (e: KeyboardEvent | React.KeyboardEvent<HTMLInputElement>) => void;
}

export const SelectBindingHook = <Fields extends object, Option extends object, Lang = string>({
    value,
    isOpen,
    search,
    onClose,
    uniqueKey,
    optionRefs,
    localOptions,
    contextMenuSelected,
    setContextMenuSelected,
    ...props
}: IParams<Option> & IMultiSelectInputProps<Fields, Option, Lang>): IReturn => {
    const idAccessor = props.idAccessor || ("id" as keyof Option);

    const handleKeyPress = (
        e: KeyboardEvent | React.KeyboardEvent<HTMLInputElement | HTMLDivElement>
    ): void => {
        if (e.key === "ArrowDown") {
            e.preventDefault();
            e.stopPropagation();
            if (!isOpen) return props.setIsOpen(true);

            setContextMenuSelected((current) => {
                const next = current + 1;
                let val = next;
                if (next >= localOptions.length) val = current;
                optionRefs.current[val]?.scrollIntoView({ block: "end", inline: "end" });
                return val;
            });
        }
        if (e.key === "ArrowUp") {
            e.preventDefault();
            e.stopPropagation();
            if (!isOpen) return;

            setContextMenuSelected((current) => {
                const prev = current - 1;
                if (prev < 0) {
                    onClose();
                    return current;
                }
                optionRefs.current[prev]?.scrollIntoView({ block: "end", inline: "end" });
                return prev;
            });
        }
        if (e.key === "Escape") {
            if (!isOpen) return;
            e.preventDefault();
            e.stopPropagation();
            onClose();
        }
        if (e.key === "Enter") {
            if (!isOpen) return;
            e.preventDefault();
            e.stopPropagation();
            const item = localOptions[contextMenuSelected];
            if (!item) return onClose();
            const localValue = item[idAccessor] as never;
            const newArray: string[] = [];
            let isSelected: boolean = false;
            for (let opt of value) {
                if (opt === localValue) {
                    isSelected = true;
                    continue;
                }
                newArray.push(opt);
            }
            if (!isSelected) newArray.push(localValue);

            props.onChange(props.id, newArray);
            onClose();
        }
    };

    const handleClick = (e: MouseEvent): void => {
        const path = e.composedPath();
        for (let target of path) {
            const id: string | undefined = (target as HTMLElement)?.id;
            if (id === uniqueKey) return;
        }
        onClose();
    };

    useMountWithTriggers(() => {
        window.addEventListener("mouseup", handleClick, { passive: true });
        return () => {
            window.removeEventListener("mouseup", handleClick);
        };
    }, [isOpen, search, localOptions, value]);

    useMountWithTriggers(() => {
        setContextMenuSelected(-1);
    }, [localOptions.length]);

    return {
        handleClick,
        handleKeyPress
    };
};
