import { Border } from "../../UI/InputGroups/Border/Border.view";
import { Paddings } from "../../UI/InputGroups/Paddings/Paddings.view";
import { ListInput } from "../../UI/InputFields/ListInput/ListInput.view";
import { EditorHook } from "../Utils/EditorHook.util";
import { IDomElement } from "../../DomTracker/Interfaces/IDomTypes.interface";
import { IBulletListEditors } from "../../DomMapper/Interfaces/IBulletListComponent.interface";
import { InputGroupContextProvider } from "../../UI/InputGrouper/InputGroup.provider";
import BackgroundGroupView from "../../UI/InputGroups/Background/BackgroundGroup.view";
import FormSeparatorView from "../../UI/FormSeparator/FormSeparator.view";
import TextShadowView from "../../UI/InputGroups/TextShadow/TextShadow.view";
import BoxShadowView from "../../UI/InputGroups/BoxShadow/BoxShadow.view";
import FontColor from "../../UI/InputGroups/FontInputs/FontColor.input";
import Font from "../../UI/InputGroups/FontInputs/Font.view";

export interface IBulletListEditorViewProps {
    editor: IDomElement;
}

const BulletListEditorView: React.FC<IBulletListEditorViewProps> = (props) => {
    const { onBlur, onClear, units, defaults } = EditorHook<IBulletListEditors>();

    return (
        <InputGroupContextProvider defaultActive={"FontColor"}>
            <FormSeparatorView
                defKey={"List"}
                defaultGroup={
                    <>
                        <ListInput defaults={defaults} onBlur={onBlur} id={"list"} />
                        <FontColor
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Font
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                    </>
                }
                advancedGroup={
                    <>
                        <Border
                            defaultValues={defaults}
                            units={units}
                            onReset={onClear}
                            onBlur={onBlur}
                        />
                        <BackgroundGroupView
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <BoxShadowView
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <TextShadowView
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Paddings
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                    </>
                }
            />
        </InputGroupContextProvider>
    );
};

export default BulletListEditorView;
