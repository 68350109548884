import { IUntransformedUnits, Untransform } from "../../../Utils/UntransformComponentContent.util";
import { IGenericCSSFields } from "sitebuilder-common";
import { useTranslation } from "react-i18next";
import { ICommonContent } from "sitebuilder-common";
import { PageDataModel } from "../../DomTracker/Model/PageData.model";
import { IDomContext } from "../../DomTracker/Interfaces/IDomContext.interface";
import { useEditor } from "../Controller/EditorContext.provider";
import { useRef } from "react";
import { useDom } from "../../DomTracker/Controller/DomTracker.provider";
import { ISizes } from "sitebuilder-common";
import { ILang } from "../../../Interfaces/ILang.type";
import { clone } from "lodash";

export type IBlur<T extends object> =
    | { value: any; field: keyof T }
    | { value: any; field: keyof T }[];

export type IFieldBlur<T extends object> = { value: any; field: keyof T };

type IReturn<T extends ICommonContent> = {
    onClear: (keys: (keyof IGenericCSSFields)[]) => void;
    onSpecialRuleClear: (data: IFieldBlur<T>) => void;
    updateContent: IDomContext<T>["updateContent"];
    onBlur: (value: IBlur<T>) => void;
    units: IUntransformedUnits;
    pages: PageDataModel[];
    pageID: string;
    size: ISizes;
    defaults: T;
};

export const EditorHook = <T extends ICommonContent>(): IReturn<T> => {
    const {
        i18n: { language }
    } = useTranslation<ILang>();
    const { size, currentPage, pages, updateContent, clearRulesBySize, clearComponentSpecialProp } =
        useDom<T>();
    const { editor, setChanges } = useEditor();

    const untransformed = useRef(new Untransform(editor!.content, size, language));
    const defaults = useRef(untransformed.current.toJSON() as T);
    const units = useRef(untransformed.current.getUnits());
    const pageID: string = currentPage.page_id;

    const alterChanges = (data: Partial<T> | (keyof IGenericCSSFields)[]): void => {
        setChanges((current) => {
            const state = clone(current);
            for (const key in data) {
                const field = data instanceof Array ? data[key as never] : key;
                state[field] = true;
            }
            return state;
        });
    };

    const onBlur = (datas: IBlur<T>): void => {
        const values: Partial<T> = {};
        if (datas instanceof Array) {
            for (let data of datas) {
                if (data.value === "") continue;
                else values[data.field] = data.value;
            }
        } else {
            if (datas.value === "") return;
            else values[datas.field] = datas.value;
        }
        alterChanges(values);
        updateContent([
            {
                uuid: editor!.uuid,
                domType: editor!.type,
                ...(values as unknown as T)
            }
        ]);
    };

    const onClear = (keys: (keyof IGenericCSSFields)[]): void => {
        alterChanges(keys);
        clearRulesBySize(keys, editor!.uuid, editor!.type);
    };

    const onSpecialRuleClear = (data: IFieldBlur<T>): void => {
        alterChanges({ [data.field]: data.value } as Partial<T>);
        clearComponentSpecialProp(data.field, editor!.uuid, editor!.type);
    };

    return {
        size,
        pages,
        pageID,
        onBlur,
        onClear,
        updateContent,
        onSpecialRuleClear,
        units: units.current,
        defaults: defaults.current
    };
};
