import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useCart } from "../Provider/Cart.provider";
import { CheckboxInput } from "../../../UI/InputFields/Checkbox/Checkbox.view";
import { ICartUserForm } from "../Interfaces/ICartUserForm.interface";
import { useTranslation } from "react-i18next";

interface ICartDMCAViewProps {}

export const CartDMCAView: FC<ICartDMCAViewProps> = (props) => {
    const { form } = useCart();
    const { t } = useTranslation<ILang>();

    return (
        <div className={"cart-block cart__dmca"}>
            <CheckboxInput<ICartUserForm>
                noLabel
                id={"terms_and_conditions"}
                value={form.editor.terms_and_conditions}
                className={"terms_and_conditions"}
                onBlur={(data) => form.handleChange(data.field, data.value)}
            >
                <div>
                    {t<ILang>("i_accept")} {t<ILang>("preposition_1")}{" "}
                    <a
                        className={"link"}
                        rel={"noreferrer noopener"}
                        target="_blank"
                        href={"/unknown"}
                        onClick={(e) => e.preventDefault()}
                    >
                        {t<ILang>("terms_and_conditions")}
                    </a>
                </div>
            </CheckboxInput>

            <CheckboxInput<ICartUserForm>
                noLabel
                id={"privacy_policy"}
                value={form.editor.privacy_policy}
                className={"privacy_policy"}
                onBlur={(data) => form.handleChange(data.field, data.value)}
            >
                <div>
                    {t<ILang>("i_accept")} {t<ILang>("preposition_2")}{" "}
                    <a
                        className={"link"}
                        rel={"noreferrer noopener"}
                        target="_blank"
                        href={"/unknown"}
                        onClick={(e) => e.preventDefault()}
                    >
                        {t<ILang>("privacy_policy")}
                    </a>
                </div>
            </CheckboxInput>
        </div>
    );
};
