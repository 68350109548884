import { FontDAO } from "../DAO/Font.dao";
import { IWidgets } from "../Components/Widgets/Interfaces/IWidgets.type";
import { FontModel } from "sitebuilder-common";
import { useDidMount } from "xa-generics";
import { IFontSource } from "sitebuilder-common";
import { PageDataModel } from "../Components/DomTracker/Model/PageData.model";
import { IWidgetSettings } from "../Components/Widgets/Interfaces/IWidgetCommon.interface";
import { IActiveFontCollectionContext } from "../Interfaces/IActiveFontCollectionContext.interface";
import { Context, createContext, useContext, useState } from "react";
import { FONT_OPTIONS, ICommonContent, IFontOption } from "sitebuilder-common";

/**
 * ## ActiveFontCollectionContext
 */
const ActiveFontCollectionContext: Context<IActiveFontCollectionContext> =
    createContext<IActiveFontCollectionContext>(null as any);

ActiveFontCollectionContext.displayName = "ActiveFontCollectionContext";

interface IActiveFontCollectionContextProviderProps {}

/**
 * ## ActiveFontCollection context provider component
 *
 */
export const ActiveFontCollectionContextProvider: React.FC<
    IActiveFontCollectionContextProviderProps
> = (props) => {
    const [activeFonts, setActiveFonts] = useState<null | FontModel>(null);

    const updateCollection = (newModel: FontModel): void => {
        FontDAO.saveOrReplace(newModel).catch((error) => {
            console.error("Error saving font collection", newModel, "due to error:", error);
        });
    };

    const handleFontSet = (fontSet: Set<string>, dataTarget: IFontSource): void => {
        if (!activeFonts) return;

        const fontOptions: IFontOption[] = [];
        for (const fontFamily of FONT_OPTIONS) {
            if (fontSet.has(fontFamily.font)) {
                fontOptions.push(fontFamily);
            }
        }

        if (!activeFonts.isEqual(fontOptions, dataTarget)) {
            const newModel = new FontModel(activeFonts);
            newModel.updateFonts(fontOptions, dataTarget);
            setActiveFonts(newModel);
            updateCollection(newModel);
        }
    };

    const checkFontCollection = (data: PageDataModel[] | IWidgets): void => {
        const fontSet = new Set<string>();
        let dataTarget!: IFontSource;
        const lookupContent = (content: ICommonContent): void => {
            if ("fontFamily" in content) {
                for (const sizeKey in content.fontFamily) {
                    fontSet.add(content.fontFamily[sizeKey]);
                }
            }
        };

        if (data instanceof Array) {
            dataTarget = "pages";
            for (const page of data) {
                if (!page.is_active) continue;
                for (const section of page.elements.dom) {
                    lookupContent(section.content);
                    for (const column of section.elements) {
                        lookupContent(column.content);
                        for (const node of column.elements) {
                            lookupContent(node.content);
                        }
                    }
                }
            }
        } else {
            dataTarget = "widgets";
            for (const widgetKey in data) {
                const widget = data[widgetKey as never] as IWidgetSettings<any>;
                for (const setting in widget.settings) {
                    if (typeof widget.settings[setting] === "object") {
                        lookupContent(widget.settings[setting]);
                    }
                }
            }
        }

        handleFontSet(fontSet, dataTarget);
    };

    useDidMount(() => {
        FontDAO.load().then((fontModel) => setActiveFonts(fontModel));
    });

    return (
        <ActiveFontCollectionContext.Provider
            value={{
                checkFontCollection
            }}
        >
            {props.children}
        </ActiveFontCollectionContext.Provider>
    );
};

export const useActiveFontCollection = (): IActiveFontCollectionContext =>
    useContext(ActiveFontCollectionContext);
