import { FC } from "react";
import { IPartialCSS } from "sitebuilder-common";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { Add, Subtract } from "@carbon/icons-react";

interface IQtyViewProps {
    qty: number;
    className?: string;
    disabled?: boolean;
    decrement: () => void;
    increment: () => void;
}

export const QtyView: FC<IQtyViewProps> = (props) => {
    const qtyClasses: string[] = ["global__quantity-hook"];

    const { config } = useRestaurant();
    const style: IPartialCSS<any> = {};

    if (config.main_btn_bg_color) style.backgroundColor = config.main_btn_bg_color;
    if (config.main_btn_color) style.color = config.main_btn_color;

    if (props.className) qtyClasses.push(props.className);

    return (
        <div className={qtyClasses.join(" ")}>
            <button
                disabled={props.qty === 1 || props.disabled}
                className={"global__quantity-hook--btn"}
                onClick={props.decrement}
                style={style}
            >
                <Subtract />
            </button>
            <div className="global__quantity-hook--qty">{props.qty}</div>
            <button
                className={"global__quantity-hook--btn"}
                onClick={props.increment}
                disabled={props.disabled}
                style={style}
            >
                <Add />
            </button>
        </div>
    );
};
