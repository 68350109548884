import { ITableReserverStyles } from "../Interfaces/ITableReserverComponent.interface";
import {
    FontStyles,
    ColorStyles,
    WidthStyles,
    AlignStyles,
    HeightStyles,
    MarginStyles,
    PaddingStyles,
    BackgroundStyles
} from "./GroupsStyles.static";

export const TableReserverStyles: Required<ITableReserverStyles<true>> = {
    ...FontStyles,
    ...WidthStyles,
    ...ColorStyles,
    ...AlignStyles,
    ...HeightStyles,
    ...MarginStyles,
    ...PaddingStyles,
    ...BackgroundStyles
};
