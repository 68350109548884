import { FC } from "react";
import { useForm } from "../../UseForm/UseForm.provider";
import { TextInput } from "../../UI/InputFields/TextInput/TextInput.view";
import { IDomElement } from "../../DomTracker/Interfaces/IDomTypes.interface";
import { CheckboxInput } from "../../UI/InputFields/Checkbox/Checkbox.view";
import { EditorHook, IFieldBlur } from "../Utils/EditorHook.util";
import {
    ISlideShowCustoms,
    ISlideshowEditor
} from "../../DomMapper/Interfaces/ISlideShowComponent.interface";
import { InputGroupContextProvider } from "../../UI/InputGrouper/InputGroup.provider";
import BackgroundGroupView from "../../UI/InputGroups/Background/BackgroundGroup.view";
import FormSeparatorView from "../../UI/FormSeparator/FormSeparator.view";

interface ISlideshowEditorViewProps {
    editor: IDomElement;
}

export const SlideshowEditorView: FC<ISlideshowEditorViewProps> = (props) => {
    const { onBlur, defaults, onClear, units } = EditorHook<ISlideshowEditor>();
    const form = useForm<ISlideShowCustoms, true>({
        editor: {
            showArrows: defaults.showArrows,
            showElementDots: defaults.showElementDots,
            slideshowDelay: defaults.slideshowDelay
        },
        initialRules: {
            slideshowDelay: {
                pattern: { value: /^[1-9][0-9]*$/, message: "must_have_a_num_value" }
            }
        }
    });

    const handleDelayChange = (data: IFieldBlur<ISlideShowCustoms>): void => {
        form.handleSubmit(() => {
            onBlur(data);
        })();
    };

    return (
        <InputGroupContextProvider defaultActive={"Width"}>
            <FormSeparatorView
                defKey={"SlideShow"}
                defaultGroup={
                    <>
                        <TextInput
                            isNumeric
                            id={"slideshowDelay"}
                            onChange={form.handleChange}
                            value={form.editor.slideshowDelay}
                            onBlur={handleDelayChange}
                            errors={form.fieldErrors}
                        />
                        <CheckboxInput
                            onBlur={onBlur}
                            id={"showArrows"}
                            onChange={form.handleChange}
                            value={form.editor.showArrows}
                        />
                        <CheckboxInput
                            onBlur={onBlur}
                            id={"showElementDots"}
                            onChange={form.handleChange}
                            value={form.editor.showElementDots}
                        />
                    </>
                }
                advancedGroup={
                    <>
                        <BackgroundGroupView
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                    </>
                }
            />
        </InputGroupContextProvider>
    );
};
