import { LoginView } from "../View/Login.view";
import { ILoginForm } from "../Interfaces/ILoginForm.interface";
import { FC, useState } from "react";
import { AppConfigsModel } from "sitebuilder-common";
import { IError, ILoading, ISetState } from "xa-generics";

export interface IWidgetLoginProps {
    onClose: () => void;
    app?: AppConfigsModel["login"];
    setShowRegistration?: ISetState<boolean>;
    onSubmit?: () => void;
}

export const WidgetLogin: FC<IWidgetLoginProps> = (props) => {
    const [error, setError] = useState<IError>(null);
    const [loading] = useState<ILoading>(false);
    const [form, setForm] = useState<ILoginForm>({
        email: "",
        password: ""
    });

    return (
        <LoginView
            {...props}
            form={form}
            error={error}
            loading={loading}
            setForm={setForm}
            setError={setError}
        />
    );
};
