import { useMemo } from "react";
import { SelectClear } from "./Utils/SelectClear.util";
import { IFormErrors } from "../../../UseForm/IUseForm.interface";
import { FormErrorView } from "../../../UseForm/FormError.view";
import { useTranslation } from "react-i18next";
import { SelectBindingHook } from "./Utils/SelectBindingHook.util";
import { SelectOptionGenerate } from "./Utils/SelectOptionGenerate.util";
import { useAfterTriggerChanged } from "xa-generics";
import { createRef, useRef, useState } from "react";
import { CaretDown, CaretUp, Checkbox, CheckboxChecked, Close } from "@carbon/icons-react";

export interface IMultiSelectInputProps<
    Fields extends object,
    Option extends object,
    Lang = string
> {
    value: string[];
    id: keyof Fields;
    labelText?: Lang;
    options: Option[];
    noLabel?: boolean;
    className?: string;
    placeholder?: Lang;
    required?: boolean;
    isDisabled?: boolean;
    isClearable?: boolean;
    idAccessor?: keyof Option;
    nameAccessor?: keyof Option;
    errors?: IFormErrors<Fields>;
    useTranslationOnName?: boolean;
    isInputGrowthAllowed?: boolean;
    description?: string | JSX.Element;
    /**
     * This option key could be a slugified, lower-cased 'name' version
     * to speed up the process of filtering. If you define a **searchAccessor**,
     * the toLocaleLowerCase() and other similar steps will be skipped in the filter!
     */
    searchAccessor?: keyof Option;
    onChange: (id: keyof Fields, value: string[]) => void;
}

export const MultiSelectInput = <Fields extends object, Option extends object, Lang = string>(
    props: IMultiSelectInputProps<Fields, Option, Lang>
) => {
    const { t } = useTranslation();

    const [search, setSearch] = useState<string>("");
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [contextMenuSelected, setContextMenuSelected] = useState<number>(-1);

    const optionRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});

    const id = props.id as string;
    const wrapperStyle: string[] = ["wrapper", "multi-select"];
    const uniqueKey = `${id}-menu`;
    const idAccessor = props.idAccessor || ("id" as keyof Option);
    const nameAccessor = props.nameAccessor || ("name" as keyof Option);
    const requireTransform: boolean = !props.searchAccessor;
    const searchRef = createRef<HTMLInputElement>();

    const onClose = (): void => {
        setIsOpen(false);
        setSearch("");
        setContextMenuSelected(-1);
    };

    const { options, selectedValues } = useMemo(
        () =>
            SelectOptionGenerate<Fields, Option, Lang>({
                ...props,
                isOpen,
                search,
                requireTransform
            }),
        [isOpen, search, requireTransform, props]
    );

    const { handleKeyPress } = SelectBindingHook<Fields, Option, Lang>({
        isOpen,
        search,
        onClose,
        uniqueKey,
        setIsOpen,
        optionRefs,
        contextMenuSelected,
        localOptions: options,
        setContextMenuSelected,
        ...props
    });

    const setValue = (value: string, isSelected: boolean): void => {
        if (isSelected) {
            const array: string[] = [];
            for (let opt of props.value) {
                if (opt !== value) array.push(opt);
            }
            props.onChange(props.id, array);
        } else {
            props.onChange(props.id, [...props.value, value]);
        }
    };

    useAfterTriggerChanged(() => {
        if (isOpen && searchRef.current) {
            searchRef.current.focus();
        }
    }, [isOpen]);

    if (props.className) wrapperStyle.push(props.className);
    if (props.isInputGrowthAllowed) wrapperStyle.push("multi-select-growable");
    if (props.isDisabled) wrapperStyle.push("multi-select-disabled");

    return (
        <div className={wrapperStyle.join(" ")}>
            {!props.noLabel && (
                <label htmlFor={id} className={"input-label"}>
                    {t((props.labelText as never) || id)}
                    {props.required ? " *" : null}
                </label>
            )}
            <select
                className={"hidden-select-input"}
                id={`${id}-hidden-select-field`}
                onChange={() => {}}
                autoComplete={"off"}
                value={props.value}
                autoSave={"off"}
                multiple
            />
            <div
                className="multi-select-input"
                onMouseUp={() => {
                    if (props.isDisabled) return;
                    setIsOpen(true);
                }}
                id={uniqueKey}
            >
                {isOpen ? (
                    <input
                        id={id}
                        name={id}
                        type={"text"}
                        value={search}
                        ref={searchRef}
                        autoSave={"off"}
                        autoComplete={"off"}
                        onKeyDown={handleKeyPress}
                        disabled={props.isDisabled}
                        className={"multi-select-input__search"}
                        placeholder={t("search") as string}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                ) : (
                    <div className="multi-select-input__values">
                        {props.value.length === 0 ? (
                            props.placeholder ? (
                                <span className="placeholder">{t(props.placeholder as never)}</span>
                            ) : undefined
                        ) : (
                            selectedValues.map((option, index) => (
                                <div
                                    className="multi-select-input__values--option"
                                    key={`${option[idAccessor]}-${index}-option-value`}
                                >
                                    <span className="name">
                                        {props.useTranslationOnName
                                            ? t(option[nameAccessor] as never)
                                            : (option[nameAccessor] as never)}
                                    </span>
                                    <Close
                                        className={"option-clear-icon"}
                                        onMouseUp={(e) => {
                                            e.stopPropagation();
                                            setValue(option[idAccessor] as never, true);
                                        }}
                                    />
                                </div>
                            ))
                        )}
                    </div>
                )}

                <div className="multi-select-input__controls">
                    {props.isClearable && (
                        <Close
                            size={20}
                            className={"multi-select-input__controls--icon"}
                            onMouseUp={(e: MouseEvent) => SelectClear(e, props)}
                        />
                    )}
                    <span className="multi-select-input__controls--separator"></span>
                    {isOpen ? (
                        <CaretUp
                            size={24}
                            className={"multi-select-input__controls--icon"}
                            onMouseUp={(e) => {
                                e.stopPropagation();
                                setIsOpen(false);
                            }}
                        />
                    ) : (
                        <CaretDown
                            size={24}
                            className={"multi-select-input__controls--icon"}
                            onMouseUp={(e) => {
                                e.stopPropagation();
                                if (props.isDisabled) return;
                                setIsOpen(true);
                            }}
                        />
                    )}
                </div>

                {isOpen && (
                    <div className="multi-select-input__list">
                        <div className="multi-select-input__list--scrollable">
                            {options.map((option, index) => {
                                const classes: string[] = ["multi-select-input__list--item"];
                                const value = option[idAccessor] as never;
                                const isSelected: boolean = props.value.indexOf(value) > -1;

                                if (isSelected) classes.push("--selected");
                                if (index === contextMenuSelected) classes.push("--active");

                                return (
                                    <div
                                        className={classes.join(" ")}
                                        ref={(r) => (optionRefs.current[index] = r)}
                                        key={`${index}-${id}-${option[idAccessor]}`}
                                        onMouseUp={() => {
                                            if (props.isDisabled) return;
                                            setValue(value, isSelected);
                                        }}
                                    >
                                        {isSelected ? (
                                            <CheckboxChecked className="multi-select-input__list--item--icon" />
                                        ) : (
                                            <Checkbox className="multi-select-input__list--item--icon" />
                                        )}
                                        {props.useTranslationOnName
                                            ? t<any, any>(option[nameAccessor])
                                            : option[nameAccessor]}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>

            {props.errors && <FormErrorView id={props.id} errors={props.errors} />}
            {props.description ? (
                <div className="input-description">{props.description}</div>
            ) : null}
        </div>
    );
};
