import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { useState } from "react";
import { useWidgets } from "../../../Widgets/Controller/WidgetsContext.provider";
import { V3NavbarTop } from "./V3NavbarTop.view";
import { useSideMenu } from "../../../Sidemenu/Controller/SideMenu.provider";
import { NavbarStyles } from "../Static/NavbarStyles.static";
import { V2NavbarLogo } from "./V2NavbarLogo.view";
import { ErrorBoundary } from "../../../ErrorBoundary/View/ErrorBoundary.view";
import { V2NavbarLinks } from "./V2NavbarLinks.view";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { V2NavbarRight } from "./V2NavbarRight.view";
import { V3NavbarBottom } from "./V3NavbarBottom.view";
import { GetWidgetOverlay } from "../../Util/WidgetOverlay.util";
import { V2NavbarSidemenu } from "./V2NavbarSidemenu.view";
import { ParseComponentStyle } from "sitebuilder-common";
import { GetWidgetLeftAndRight } from "../../../../Utils/GetWidgetLeftAndRight.util";
import { INavbar, INavbarSettings } from "../Interfaces/INavbarWidget.interface";
import NavbarBottomView from "./NavbarBottom.view";
import NavbarTopView from "./NavbarTop.view";
import WidgetOverlay from "../../../Widgets/View/WidgetOverlay.view";

export interface IWidgetNavbarProps {}

const WidgetNavbar: React.FC<IWidgetNavbarProps> = (props) => {
    const [isNavbarOpened, setIsNavbarOpened] = useState<boolean>(false);
    const { size } = useDom();
    const { currentFont } = useRestaurant();
    const { widgets } = useWidgets<INavbar>();
    const navContent = widgets.Navbar;
    const { showOverlay, ...events } = GetWidgetOverlay<INavbarSettings>("Navbar", "navbarStyle");

    const classes: string[] = ["widget", "navbar", `navbar-${size}`, currentFont];
    const { isOpen } = useSideMenu();

    const { left, right } = GetWidgetLeftAndRight(size, isOpen);
    const { navbarStyle } = widgets.Navbar.draftSettings;

    const style = ParseComponentStyle(navbarStyle, NavbarStyles, size);
    const navVersion = navContent.draftSettings.navbarVersion || "default";

    if (isNavbarOpened && navVersion === "default") classes.push("navbar-opened");
    if (navVersion !== "default") classes.push(`--NAVBAR_${navVersion}`);

    return (
        <ErrorBoundary>
            <nav {...events} className={classes.join(" ")} style={{ left, right, ...style }}>
                {showOverlay && (
                    <WidgetOverlay<INavbarSettings>
                        isMain
                        align={"left"}
                        parent={navContent}
                        subKey={"navbarStyle"}
                    />
                )}
                {navVersion === "default" ? (
                    <>
                        <NavbarTopView
                            setIsNavbarOpened={setIsNavbarOpened}
                            isNavbarOpened={isNavbarOpened}
                        />
                        <NavbarBottomView />
                    </>
                ) : navVersion === "v2" ? (
                    <>
                        <V2NavbarLogo />
                        <V2NavbarLinks navVersion={navVersion} />
                        <V2NavbarRight
                            setIsNavbarOpened={setIsNavbarOpened}
                            isNavbarOpened={isNavbarOpened}
                            navVersion={navVersion}
                        />
                    </>
                ) : (
                    <>
                        <V3NavbarTop />
                        <V3NavbarBottom />
                    </>
                )}
            </nav>
            {navVersion === "v2" && (
                <V2NavbarSidemenu
                    navVersion={navVersion}
                    isNavbarOpened={isNavbarOpened}
                    setIsNavbarOpened={setIsNavbarOpened}
                />
            )}
        </ErrorBoundary>
    );
};

export default WidgetNavbar;
