import { ModelConstructor, IDynamicObject } from "xa-generics";

export class TemplateCategoryModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as TemplateCategoryMData;
        ModelConstructor(data, this);
    }

    template_category_id: string = "";
    name: string = "";
    name_secondary: string = "";
    created_at: string = "";
    updated_at: string = "";
}

//If you add public methods, you may want to replace the empty string with them!
type TemplateCategoryMData = Partial<InstanceType<typeof TemplateCategoryModel>>;
