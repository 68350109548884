import { axios, IDynamicObject } from "xa-generics";
import { PermissionModel } from "sitebuilder-common";
import { AXIOS_NAMES } from "./../Static/AxiosInstanceNames.static";
import { AccessModel } from "sitebuilder-common";
import { RootDAO } from "./Restaurant.dao";

export abstract class PermissionDAO extends RootDAO {
    public static async me(token: string): Promise<AccessModel> {
        const request = await axios
            .getInstance(AXIOS_NAMES.ONEMIN)
            .get<IDynamicObject>(`/api/v3/admin/me`, {
                headers: {
                    authorization: token
                }
            });
        return new AccessModel(request.data, token);
    }

    public static async load(): Promise<PermissionModel[]> {
        const request = await axios
            .getInstance(AXIOS_NAMES.ONEMIN)
            .get<IDynamicObject[]>(`/api/v3/admin/permissions`);
        return request.data.map((item) => new PermissionModel(item));
    }
}
