import { ILang } from "../../../Interfaces/ILang.type";
import { useDom } from "../../DomTracker/Controller/DomTracker.provider";
import { Add, Edit } from "@carbon/icons-react";
import { DnDEvents } from "../Util/DND.util";
import { useEditor } from "../../Editor/Controller/EditorContext.provider";
import { useImages } from "../../../Contexts/Images.context";
import { IPartialCSS } from "sitebuilder-common";
import { useSideMenu } from "../../Sidemenu/Controller/SideMenu.provider";
import { IPageEditor } from "../Interfaces/IPageEditor.interface";
import { Route, Routes } from "react-router";
import { useRestaurant } from "../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import { DomlessPageModel } from "../../DomTracker/Model/DomlessPage.model";
import { ChooseTemplateModal } from "../../Templates/View/ChooseTemplateModal.view";
import DomMapperView from "./DomMapper.view";
import WidgetNavbar from "../../WidgetModules/Navbar/View/Navbar.index";
import WidgetFooter from "../../WidgetModules/Footer/View/Footer.view";
import Button from "../../UI/Button/Button.view";

export interface IRouteMapperProps {}

const RouteMapper: React.FC<IRouteMapperProps> = (props) => {
    const { pages, currentPage, clonePage, setCurrentPage } = useDom<IPageEditor>();
    const { config, currentFont, restaurant, isNewInstance } = useRestaurant();
    const { setPageEditor } = useEditor();
    const { i18n, t } = useTranslation<ILang>();
    const { isOpen } = useSideMenu();
    const { images } = useImages();

    const style: IPartialCSS<any> = {
        backgroundColor: config.body_bg_color || undefined,
        color: config.body_color || undefined,
        backgroundImage: images[config.body_bg_image_id]?.url
            ? `url(${images[config.body_bg_image_id]!.url})`
            : undefined,
        backgroundSize: config.body_bg_image_size || undefined,
        backgroundAttachment: config.body_bg_image_attach || undefined,
        backgroundPosition: config.body_bg_image_position || undefined,
        backgroundRepeat: config.body_bg_repeat ? "repeat" : "no-repeat"
    };

    const { onDragOverCapture, size, id } = DnDEvents("PAGE_CONTAINER");
    const pageClasses: string[] = [`page-container`, `page-${size}`];
    if (currentFont) pageClasses.push(currentFont);
    if (config.is_dark_theme) pageClasses.push("dark-theme");
    if (config.is_rounded_btn) pageClasses.push("rounded-corners");
    if (size === "xl") {
        if (isOpen) pageClasses.push("global__menu-open-padding");
    } else {
        pageClasses.push("show-white-border");
        if (isOpen) pageClasses.push("page-open-menu");
    }
    const routes = [];
    for (const page of pages) {
        const url = page.getBaseAndDraftUrls(i18n.language, t);
        routes.push(
            <Route path={url[0]} key={page.page_id} element={<DomMapperView page={page} />} />
        );
        if (url[1]) {
            routes.push(
                <Route
                    path={url[1]}
                    key={`${page.page_id}-draft`}
                    element={<DomMapperView page={page} isMappingDraft />}
                />
            );
        }
    }

    return (
        <div
            onDragOverCapture={onDragOverCapture}
            className={pageClasses.join(" ")}
            style={style}
            id={id}
        >
            <WidgetNavbar />
            {!currentPage.isCurrentPageDraft && (
                <div className="fixed-edit-page-button-container">
                    <Button
                        kind={"MEGA"}
                        Icon={<Edit size={24} />}
                        onClick={() => {
                            const page = pages.find(
                                (page) => page.page_id === currentPage.page_id
                            )!;
                            if (!page.draft) clonePage(new DomlessPageModel(page, restaurant));
                            else {
                                const domlessModel = new DomlessPageModel(page, restaurant, true);
                                setCurrentPage(domlessModel);
                            }
                        }}
                    >
                        {t<ILang>(currentPage.draft ? "edit_draft" : "create_draft")}
                    </Button>
                </div>
            )}
            {isNewInstance && !config.applied_template_id && !config.is_declined_template && (
                <ChooseTemplateModal />
            )}
            <Routes>
                {routes}
                <Route
                    path={"*"}
                    element={
                        <div
                            className="no-page-elements-container"
                            onClick={() => {
                                setPageEditor(new DomlessPageModel({}, restaurant));
                            }}
                        >
                            <div className="no-page-elements-info">
                                {i18n.t<ILang>("no_page_info")}
                                <div className="svg">
                                    <Add size={32} />
                                </div>
                            </div>
                        </div>
                    }
                />
            </Routes>
            <WidgetFooter />
        </div>
    );
};

export default RouteMapper;
