import { Width } from "../../UI/InputGroups/Width/Width.view";
import { Border } from "../../UI/InputGroups/Border/Border.view";
import { Height } from "../../UI/InputGroups/Height/Height.view";
import { OpacityInput } from "../../UI/InputGroups/Opacity/Opacity.view";
import { Paddings } from "../../UI/InputGroups/Paddings/Paddings.view";
import { EditorHook } from "../Utils/EditorHook.util";
import { IDomElement } from "../../DomTracker/Interfaces/IDomTypes.interface";
import { BorderRadius } from "../../UI/InputGroups/BorderRadius/BorderRadius.view";
import { ILinkEditors } from "../../DomMapper/Interfaces/ILinkComponent.interface";
import { InputGroupContextProvider } from "../../UI/InputGrouper/InputGroup.provider";
import BackgroundImageGroup from "../../UI/InputGroups/BackgroundImage/BackgroundImageGroup.view";
import BackgroundGroupView from "../../UI/InputGroups/Background/BackgroundGroup.view";
import FormSeparatorView from "../../UI/FormSeparator/FormSeparator.view";
import AlignPickerView from "../../UI/InputFields/AlignPicker/AlignPicker.view";
import TextShadowView from "../../UI/InputGroups/TextShadow/TextShadow.view";
import BoxShadowView from "../../UI/InputGroups/BoxShadow/BoxShadow.view";
import ComponentText from "../../UI/InputGroups/ComponentText/ComponentText.view";
import UrlGroupView from "../../UI/InputGroups/Url/UrlGroup.view";
import FontColor from "../../UI/InputGroups/FontInputs/FontColor.input";
import Font from "../../UI/InputGroups/FontInputs/Font.view";

export interface ILinkEditorViewProps {
    isButtonType?: boolean;
    editor: IDomElement;
}

const LinkEditorView: React.FC<ILinkEditorViewProps> = (props) => {
    const { onBlur, onClear, defaults, units } = EditorHook<ILinkEditors<any>>();

    return (
        <InputGroupContextProvider>
            <FormSeparatorView
                defKey={"Link"}
                defaultGroup={
                    <>
                        <ComponentText
                            defaultText={"link_text"}
                            defaultValues={defaults}
                            onBlur={onBlur}
                            type={"TEXT"}
                        />
                        <UrlGroupView defaultValues={defaults} onBlur={onBlur} />
                        {props.isButtonType && (
                            <>
                                <BackgroundImageGroup
                                    defaultValues={defaults}
                                    onReset={onClear}
                                    onBlur={onBlur}
                                    units={units}
                                />
                                <BackgroundGroupView
                                    defaultValues={defaults}
                                    onReset={onClear}
                                    onBlur={onBlur}
                                    units={units}
                                />
                            </>
                        )}
                        <AlignPickerView
                            defaultValue={defaults.textAlign}
                            labelText={"alignButton"}
                            id={"textAlign"}
                            onBlur={onBlur}
                        />
                        <FontColor
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Font
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <BorderRadius
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        <Border
                            defaultValues={defaults}
                            units={units}
                            onReset={onClear}
                            onBlur={onBlur}
                        />
                    </>
                }
                advancedGroup={
                    <>
                        <Paddings
                            defaultValues={defaults}
                            onBlur={onBlur}
                            onReset={onClear}
                            units={units}
                        />
                        <OpacityInput onReset={onClear} onBlur={onBlur} defaultValues={defaults} />
                        <Height
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                            defaultValues={defaults}
                        />
                        <Width
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                        {props.isButtonType && (
                            <BoxShadowView
                                defaultValues={defaults}
                                onReset={onClear}
                                onBlur={onBlur}
                                units={units}
                            />
                        )}
                        <TextShadowView
                            defaultValues={defaults}
                            onReset={onClear}
                            onBlur={onBlur}
                            units={units}
                        />
                    </>
                }
            />
        </InputGroupContextProvider>
    );
};

export default LinkEditorView;
