import { ILang } from "../../../../Interfaces/ILang.type";
import { useForm } from "../../../UseForm/UseForm.provider";
import { TextInput } from "../../InputFields/TextInput/TextInput.view";
import { IPaddings } from "./IPaddings.interface";
import { SIZING_RULE } from "../../../../Static/FormRules.static";
import { ResetValues } from "../../../Editor/Utils/ResetValues.util";
import { IInputGroup } from "../../../../Interfaces/IInputGroup.interface";
import { Destructure } from "../../../../Utils/Destructure.util";
import { IFormWithUnit } from "../../../../Interfaces/IFormWithUnit.type";
import { BuildGroupData } from "../../../../Utils/BuildGroupData.util";
import { useTranslation } from "react-i18next";
import { CheckIfHasValue } from "../../../../Utils/CheckIfHasValue.util";
import { PaddingDefaults } from "./Defaults.static";
import { useAfterTriggerChanged } from "xa-generics";
import {
    OpenPanelFilledBottom,
    OpenPanelFilledRight,
    OpenPanelFilledLeft,
    OpenPanelFilledTop,
    Minimize
} from "@carbon/icons-react";
import SizeUnitControlView from "../../SizeUnitControl/SizeUnitControl.view";
import ResetFormView from "../../InputGrouper/ResetForm.view";
import InputGrouper from "../../InputGrouper/InputGrouper.view";

export interface IPaddingsProps extends IInputGroup<IPaddings<any>> {
    applyWarning?: ILang;
}

export const Paddings: React.FC<IPaddingsProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const form = useForm<IFormWithUnit<IPaddings>, true>({
        editor: Destructure(PaddingDefaults, props.defaultValues, props.units.padding),
        initialRules: {
            paddingLeft: SIZING_RULE(t),
            paddingRight: SIZING_RULE(t),
            paddingTop: SIZING_RULE(t),
            paddingBottom: SIZING_RULE(t)
        }
    });

    useAfterTriggerChanged(() => {
        form.handleSubmit((state) => {
            props.onBlur(BuildGroupData(state));
        })();
    }, [form.editor]);

    return (
        <InputGrouper
            groupKey={"Paddings"}
            Icon={Minimize}
            labelKey={"padding"}
            Reset={
                <ResetFormView
                    hasValue={CheckIfHasValue(form, PaddingDefaults)}
                    onReset={(e) => ResetValues(e, PaddingDefaults, form.reset, props.onReset)}
                />
            }
            Controls={<SizeUnitControlView unit={form.editor.unit} setUnit={form.handleChange} />}
        >
            <div className="padding-form">
                <TextInput
                    noLabel
                    unit={<OpenPanelFilledTop />}
                    onChange={form.handleChange}
                    value={form.editor.paddingTop}
                    id={"paddingTop"}
                    className="padding-input"
                    errors={form.fieldErrors}
                />
                <TextInput
                    noLabel
                    unit={<OpenPanelFilledBottom />}
                    onChange={form.handleChange}
                    value={form.editor.paddingBottom}
                    id={"paddingBottom"}
                    className="padding-input"
                    errors={form.fieldErrors}
                />
                <TextInput
                    noLabel
                    unit={<OpenPanelFilledLeft />}
                    onChange={form.handleChange}
                    value={form.editor.paddingLeft}
                    id={"paddingLeft"}
                    className="padding-input"
                    errors={form.fieldErrors}
                />
                <TextInput
                    noLabel
                    unit={<OpenPanelFilledRight />}
                    onChange={form.handleChange}
                    value={form.editor.paddingRight}
                    id={"paddingRight"}
                    className="padding-input"
                    errors={form.fieldErrors}
                />
                <div className="global__form-group--desc global__form-group--four-row">
                    {t<ILang>("special_values_desc")}
                </div>
                {props.applyWarning && (
                    <div className="global__form-group--apply-warning">
                        {t<ILang>(props.applyWarning)}
                    </div>
                )}
            </div>
        </InputGrouper>
    );
};
