import { ILang } from "../../../../Interfaces/ILang.type";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { useWidgets } from "../../../Widgets/Controller/WidgetsContext.provider";
import { UserFollow } from "@carbon/icons-react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { INavbarSettings } from "../Interfaces/INavbarWidget.interface";
import { GetWidgetOverlay } from "../../Util/WidgetOverlay.util";
import { getScaledIconSize } from "../Utils/ScaledIconSize.util";
import { NavbarButtonStyles } from "../Static/NavbarButtonStyles.static";
import { WidgetRegistration } from "../../Registration/Controller/Registration.controller";
import { ParseComponentStyle } from "sitebuilder-common";
import WidgetOverlay from "../../../Widgets/View/WidgetOverlay.view";

interface INavbarRegProps {}

export const NavbarReg: FC<INavbarRegProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { size } = useDom();
    const { widgets } = useWidgets();
    const [showRegistration, setShowRegistration] = useState<boolean>(false);

    const { navbarRegButton } = widgets.Navbar.draftSettings;

    const { showOverlay, ...events } = GetWidgetOverlay<INavbarSettings>(
        "Navbar",
        "navbarRegButton"
    );

    const regStyles = ParseComponentStyle(navbarRegButton, NavbarButtonStyles, size);
    const iconSize = getScaledIconSize(regStyles);

    return (
        <>
            <button
                style={regStyles}
                className={"navbar__top__meta--button reg-button"}
                {...events}
                onClick={() => setShowRegistration(true)}
            >
                {showOverlay && (
                    <WidgetOverlay<INavbarSettings>
                        align={"right"}
                        parent={widgets.Navbar}
                        subKey={"navbarRegButton"}
                    />
                )}
                <div className="text">{t<ILang>("registration")}</div>
                <div className="icon">
                    <UserFollow size={iconSize} />
                </div>
            </button>
            {showRegistration && <WidgetRegistration onClose={() => setShowRegistration(false)} />}
        </>
    );
};
