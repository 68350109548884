import { IDynamicObject } from "xa-generics";
import { useTranslation } from "react-i18next";
import { ILongSelectProps } from "../LongSelect.view";
import { LongSelectSlugify } from "./LongSelectSlugify.util";
import { createRef, useState } from "react";
import { Add, CaretDown, CaretUp, Close } from "@carbon/icons-react";
import {
    useTimeout,
    ISelectOption,
    useMountWithTriggers,
    useAfterTriggerChanged
} from "xa-generics";
import LongSelectControl from "./LongSelectControl.view";
import ListMapper from "./ListMapper.view";
import IsTyping from "./IsTyping.view";

export interface ILongSelectInputProps<
    Fields extends object,
    Lang = string,
    OptionType extends object = ISelectOption
> extends Omit<ILongSelectProps<Fields, Lang, OptionType>, "onChange" | "value"> {
    value: string;
    classes: string[];
    idAccessor: keyof OptionType;
    nameAccessor: keyof OptionType;
    optionObject: IDynamicObject<OptionType>;
    onChange: (value: string, option: OptionType | null) => void;
}

const LongSelectInput = <
    Fields extends object,
    Lang = string,
    OptionType extends object = ISelectOption
>(
    props: ILongSelectInputProps<Fields, Lang, OptionType>
) => {
    const { t } = useTranslation();
    const { setTm, clearTm } = useTimeout();
    const objectValue = props.optionObject[props.value];
    const limit = props.pageLimit || 100;
    if (limit > 200) {
        throw new Error(
            "DEVELOPER ERROR: Don't set the limit over two hundreds. It could make the list rendering laggy!"
        );
    }
    const inputId = props.id as string;

    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [localValue, setLocalValue] = useState<string>(
        objectValue ? (objectValue[props.nameAccessor] as never as string) : ""
    );
    const [isTyping, setisTyping] = useState<boolean>(false);
    const [optionPage, setOptionPage] = useState<number>(
        (function (): number {
            //Sets the default page to start with previously selected option on first list opening
            if (!objectValue) return 0;
            const index = props.options.findIndex(
                (item) => (item[props.idAccessor] as never) === props.value
            );
            if (index < 0) return 0;

            const flooredPage = Math.floor(index / limit);
            return flooredPage;
        })()
    );
    const sliceStart = optionPage * limit;
    const sliceEnd = sliceStart + limit;
    const optionLength: number = props.options.length;

    const inputRef = createRef<HTMLInputElement>();

    const handleOutsideClick = (e: MouseEvent): void => {
        const path = e.composedPath();
        let hasID = false;
        for (let target of path) {
            const id: string | undefined = (target as HTMLElement)?.id;
            if (id === `${inputId}-menu`) hasID = true;
        }
        if (hasID) return;

        //INFO if the input value doesn't match the prop value when the user clicks outside,
        //but something was typed in, this will set the first matching 'name' option as the value.
        if (localValue && localValue !== props.value) {
            let isFound: boolean = false;
            const sluggedValue = LongSelectSlugify(localValue);
            for (let key in props.optionObject) {
                const option = props.optionObject[key] as any;
                const name = option[props.nameAccessor];
                const sluggedName = option.longSlugName
                    ? option.longSlugName
                    : LongSelectSlugify(name);
                if (sluggedValue.test(sluggedName)) {
                    const value = option[props.idAccessor];
                    props.onChange(value, option);
                    setLocalValue(name);
                    isFound = true;
                    break;
                }
            }
            if (!isFound) setLocalValue("");
        }
        setShowOptions(false);
    };
    useMountWithTriggers(() => {
        if (showOptions) {
            window.addEventListener("mouseup", handleOutsideClick, {
                passive: true,
                capture: true
            });
        }
        return () => window.removeEventListener("mouseup", handleOutsideClick, true);
    }, [showOptions, props.value, localValue, props.optionObject]);

    useAfterTriggerChanged(() => {
        if (localValue !== props.value) {
            setLocalValue(objectValue[props.nameAccessor as never]);
        }
    }, [props.value, objectValue]);

    useAfterTriggerChanged(() => {
        if (isTyping) setShowOptions(true);
    }, [isTyping]);

    const getRelevantList = (): OptionType[] => {
        if (!localValue) return props.options.slice(sliceStart, sliceEnd);
        if (!isTyping) {
            const loweredValue = localValue.toLocaleLowerCase();
            return props.options.filter((item) =>
                (item[props.nameAccessor] as never as string)
                    .toLocaleLowerCase()
                    .includes(loweredValue)
            );
        } else return [];
    };

    return (
        <div className={props.classes.join(" ")} id={`${inputId}-menu`}>
            {!props.noLabel && (
                <label htmlFor={inputId} className="input-label">
                    {t((props.labelText as any) || inputId)}
                </label>
            )}
            <input
                readOnly
                type={"text"}
                value={props.value}
                required={props.required}
                id={`${inputId}_rawinput`}
                className="long-select__hidden-input"
                autoComplete={"off"}
            />
            <div className="long-select__input">
                <input
                    id={inputId}
                    value={localValue}
                    className={"common-input text-input"}
                    autoComplete={"off"}
                    disabled={props.isDisabled}
                    type="text"
                    ref={inputRef}
                    onMouseUp={() => {
                        setShowOptions(!showOptions);
                    }}
                    onChange={(e) => {
                        setisTyping(true);
                        setOptionPage(0);
                        setLocalValue(e.target.value);
                        if (e.target.value) {
                            setTm(() => setisTyping(false), 400, "long-select-internal");
                        } else {
                            clearTm("long-select-internal");
                            setisTyping(false);
                        }
                    }}
                />
                {!props.isDisabled && (
                    <div
                        onMouseUp={() => {
                            if (props.isDisabled) return;
                            props.onChange("", null);
                            setLocalValue("");
                            setShowOptions(true);
                            inputRef.current?.focus();
                        }}
                        className="text-input-unit"
                    >
                        <Close size={20} />
                    </div>
                )}
            </div>

            {showOptions && (
                <div className="long-select__options">
                    <LongSelectControl
                        value={-1}
                        isVisible={sliceStart > 0 && !localValue}
                        optionPage={optionPage}
                        setOptionPage={setOptionPage}
                    >
                        <CaretUp size={20} /> ({sliceStart - limit + 1} - {sliceStart})
                    </LongSelectControl>

                    {props.handleNewOptionClick && props.allowNewOption && (
                        <div
                            className="long-select__options--new"
                            onMouseUp={() => props.handleNewOptionClick!(props.id, localValue)}
                        >
                            <Add /> {t((props.newOptionLangKey as never as string) || "new_option")}
                        </div>
                    )}
                    <IsTyping isTyping={isTyping} isTypingTextKey={props.isTypingTextKey} />

                    <ListMapper
                        {...props}
                        list={getRelevantList()}
                        setLocalValue={setLocalValue}
                        setShowOptions={setShowOptions}
                    />

                    <LongSelectControl
                        isVisible={sliceEnd < props.options.length && !localValue}
                        setOptionPage={setOptionPage}
                        value={1}
                        optionPage={optionPage}
                    >
                        <CaretDown size={20} /> ({sliceEnd} - {sliceEnd + limit} / {optionLength})
                    </LongSelectControl>
                </div>
            )}
        </div>
    );
};

export default LongSelectInput;
