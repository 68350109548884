import { FC } from "react";
import { useWidgets } from "../../Widgets/Controller/WidgetsContext.provider";
import { WidgetPublish } from "./WidgetPublish.view";
import { CheckboxInput } from "../../UI/InputFields/Checkbox/Checkbox.view";
import { IThankYouSettings } from "../../WidgetModules/ThankYou/Interfaces/IThankYouWidget.interface";
import EditorHeaderView from "./EditorHeader.view";

interface IThankYouEditorProps {}

export const ThankYouEditor: FC<IThankYouEditorProps> = (props) => {
    const {
        widgets: { ThankYou },
        updateWidget
    } = useWidgets<IThankYouSettings>();

    const defaultValue = ThankYou?.draftSettings?.isTrackerVisible || false;
    const defaultStatusCheckVal = ThankYou?.draftSettings?.isStatusCheckOnWithoutLCT || false;

    return (
        <>
            <EditorHeaderView langKey={"thank_you"} />
            <CheckboxInput
                id={"isTrackerVisible"}
                value={defaultValue}
                onBlur={(data) =>
                    updateWidget([
                        {
                            widgetType: "ThankYou",
                            widgetProp: "isTrackerVisible",
                            data
                        },
                        {
                            widgetType: "ThankYou",
                            widgetProp: "isStatusCheckOnWithoutLCT",
                            data: { field: "isStatusCheckOnWithoutLCT", value: false }
                        }
                    ])
                }
            />
            <CheckboxInput
                id={"isStatusCheckOnWithoutLCT"}
                value={defaultStatusCheckVal}
                onBlur={(data) =>
                    updateWidget([
                        {
                            widgetType: "ThankYou",
                            widgetProp: "isStatusCheckOnWithoutLCT",
                            data
                        },
                        {
                            widgetType: "ThankYou",
                            widgetProp: "isTrackerVisible",
                            data: { field: "isTrackerVisible", value: false }
                        }
                    ])
                }
            />

            <WidgetPublish widgetType={"ThankYou"} />
        </>
    );
};
