import { FC } from "react";
import { useIFrame } from "../../Provider/IFrame.context";
import { TextInput } from "../../../UI/InputFields/TextInput/TextInput.view";
import { FrameEditorGroup } from "../IFrameEditorGroup.view";

interface IFrameMapFormProps {}

export const FrameMapForm: FC<IFrameMapFormProps> = (props) => {
    const { forms } = useIFrame<"map">();
    const { editor, fieldErrors, handleChange } = forms.map;

    return (
        <FrameEditorGroup groupKey={"map"} nameKey={"iframe_map"}>
            <TextInput
                id={"lat"}
                onChange={handleChange}
                value={editor.lat}
                errors={fieldErrors}
                labelText={"lat"}
            />
            <TextInput
                id={"lng"}
                onChange={handleChange}
                value={editor.lng}
                errors={fieldErrors}
                labelText={"lng"}
            />
            <TextInput
                id={"zoom"}
                onChange={handleChange}
                value={editor.zoom}
                errors={fieldErrors}
                labelText={"zoom"}
                isNumeric
            />
        </FrameEditorGroup>
    );
};
