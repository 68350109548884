import { ISpecialDomKeys } from "../Components/DomTracker/Interfaces/IDomTypes.interface";

export const SPECIAL_ELEMENT_TYPES: Record<ISpecialDomKeys, true> = {
    ContactForm: true,
    Gallery: true,
    TableReserver: true,
    RestMap: true,
    Products: true,
    SlideShow: true
};

export const SPECIAL_TYPES: Record<ISpecialDomKeys | "Section", true> = {
    ...SPECIAL_ELEMENT_TYPES,
    Section: true
};
