import { clone } from "lodash";
import { IBoxShadow } from "../Components/UI/InputGroups/BoxShadow/IBoxShadow.interface";
import { BoxShDefaults } from "../Components/UI/InputGroups/BoxShadow/Defaults.static";

export const ParseBoxShadow = (boxShadow?: string): IBoxShadow => {
    if (!boxShadow) return BoxShDefaults;

    const result = clone(BoxShDefaults);

    const content = boxShadow;
    const values = content.split(" ");
    let x = values[0] || "0";
    x = x.substring(0, x.length - 2);
    let y = values[1] || "0";
    y = y.substring(0, y.length - 2);
    let blur = values[2] || "0";
    blur = blur.substring(0, blur.length - 2);
    let spread = values[3] || "0";
    spread = spread.substring(0, spread.length - 2);

    result.offsetX = x;
    result.offsetY = y;
    result.blurRadius = blur;
    result.spreadRadius = spread;
    if (values[4]) result.color = values[4];
    return result;
};
