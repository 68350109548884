import { merge } from "lodash";
import { IPartialCSS } from "sitebuilder-common";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { FC, isValidElement } from "react";

interface IClientButtonProps {
    LeftIcon?: FC<any> | React.ReactNode;
    RightIcon?: FC<any> | React.ReactNode;
    isDisabled?: boolean;
    isSecondary?: boolean;
    style?: IPartialCSS<any>;
    type?: "button" | "reset" | "submit";
    onMouseEnter?: React.MouseEventHandler<any>;
    onMouseLeave?: React.MouseEventHandler<any>;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const ClientButton: FC<IClientButtonProps> = (props) => {
    const { config, currentFont } = useRestaurant();
    const classes: string[] = ["dom__button"];
    if (props.LeftIcon && props.RightIcon) classes.push("with-two-icons");
    else {
        if (props.LeftIcon) classes.push("with-left-icon");
        if (props.RightIcon) classes.push("with-right-icon");
    }

    const style: IPartialCSS<any> = {};

    if (props.isSecondary) {
        classes.push("--SECONDARY_BUTTON");
        if (config.secondary_btn_bg_color) style.backgroundColor = config.secondary_btn_bg_color;
        if (config.secondary_btn_color) style.color = config.secondary_btn_color;
    } else {
        if (config.main_btn_bg_color) style.backgroundColor = config.main_btn_bg_color;
        if (config.main_btn_color) style.color = config.main_btn_color;
    }
    if (config.is_rounded_btn) {
        style.borderTopLeftRadius = "1rem";
        style.borderBottomLeftRadius = "1rem";
        style.borderTopRightRadius = "1rem";
        style.borderBottomRightRadius = "1rem";
    }
    if (currentFont) classes.push(currentFont);

    return (
        <button
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            style={merge(style, props.style)}
            type={props.type || "button"}
            className={classes.join(" ")}
            disabled={props.isDisabled}
            onClick={props.onClick}
        >
            {props.LeftIcon && (
                <div className="dom__button--icons dom__button--left-icon">
                    {/* @ts-ignore */}
                    {isValidElement(props.LeftIcon) ? props.LeftIcon : <props.LeftIcon />}
                </div>
            )}
            <div className="dom__button--text">{props.children}</div>
            {props.RightIcon && (
                <div className="dom__button--icons dom__button--right-icon">
                    {/* @ts-ignore */}
                    {isValidElement(props.RightIcon) ? props.RightIcon : <props.RightIcon />}
                </div>
            )}
        </button>
    );
};
