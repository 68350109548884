import { CheckIfHasValue } from "../../../../Utils/CheckIfHasValue.util";
import { OpacityDefaults } from "./Defaults.static";
import { IInputGroup } from "../../../../Interfaces/IInputGroup.interface";
import { ResetValues } from "../../../Editor/Utils/ResetValues.util";
import { Destructure } from "../../../../Utils/Destructure.util";
import { useTimeout } from "xa-generics";
import { IOpacity } from "./IOpacity.interface";
import { useForm } from "../../../UseForm/UseForm.provider";
import { Opacity } from "@carbon/icons-react";
import ResetFormView from "../../InputGrouper/ResetForm.view";
import InputGrouper from "../../InputGrouper/InputGrouper.view";

export interface IOpacityProps extends Omit<IInputGroup<IOpacity<any>>, "units"> {}

export const OpacityInput: React.FC<IOpacityProps> = (props) => {
    const form = useForm<IOpacity, true>({
        editor: Destructure(OpacityDefaults, props.defaultValues)
    });
    const { setTm } = useTimeout();

    return (
        <InputGrouper
            Icon={Opacity}
            groupKey={"Opacity"}
            labelKey={"opacity"}
            Reset={
                <ResetFormView
                    hasValue={CheckIfHasValue(form, OpacityDefaults)}
                    onReset={(e) => ResetValues(e, OpacityDefaults, form.reset, props.onReset)}
                />
            }
        >
            <div className="bar-container">
                <div className="input-bar-container">
                    <input
                        type="range"
                        step="0.01"
                        value={form.editor.opacity}
                        onChange={(e) => {
                            form.handleChange("opacity", e.target.value);
                            setTm(
                                () => {
                                    props.onBlur({
                                        field: "opacity",
                                        value: `${e.target.value}`
                                    });
                                },
                                400,
                                "opacityChange"
                            );
                        }}
                        className={"input-slider"}
                        min={0}
                        max={1}
                    />
                </div>
            </div>
        </InputGrouper>
    );
};
