import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { AppleHU } from "../../../UI/SVG/AppleHU";
import { AppleEN } from "../../../UI/SVG/AppleEN";
import { DnDEvents } from "../../Util/DND.util";
import { IDomElement } from "../../../DomTracker/Interfaces/IDomTypes.interface";
import { AppStoreStyles } from "../../Static/AppStoreStyles.static";
import { useTranslation } from "react-i18next";
import { IAppStoreContent } from "../../Interfaces/IAppStoreComponent.interface";
import { ParseComponentStyle } from "sitebuilder-common";
import AndroidHU from "../../../../Assets/gp_badge_hu.png";
import AndroidEN from "../../../../Assets/gp_badge_en.png";
import Overlay from "../../View/Overlay.view";

interface IAppStoreProps extends IDomElement<IAppStoreContent> {}

export const AppStore: FC<IAppStoreProps> = (props) => {
    const { i18n } = useTranslation<ILang>();
    const lang = i18n.language;

    const { refs, size, id, showOverlay, ...events } = DnDEvents<IAppStoreContent>(
        props,
        "ELEMENT"
    );

    const style = ParseComponentStyle(props.content, AppStoreStyles, size);

    const androidLogo = lang === "hu" ? AndroidHU : AndroidEN;
    const iosLogo =
        lang === "hu" ? <AppleHU className={"svg"} s={40} /> : <AppleEN s={40} className={"svg"} />;

    return (
        <div
            id={id}
            {...events}
            style={style}
            className={"dom__app-store"}
            ref={refs.current[props.uuid]}
        >
            {showOverlay && <Overlay parent={props} />}

            <a
                href={props.content.android}
                target="_blank"
                rel="noreferrer noopener"
                className="dom__app-store--icon dom__app-store--android"
            >
                <img src={androidLogo} alt={"Android logo"} className={"image"} />
            </a>
            <a
                href={props.content.ios}
                target="_blank"
                rel="noreferrer noopener"
                className="dom__app-store--icon dom__app-store--ios"
            >
                {iosLogo}
            </a>
        </div>
    );
};
