import { DomModel } from "./Dom.model";
import { PageModel } from "./Page.model";
import { RestaurantModel } from "sitebuilder-common";
import { ModelConstructor, IDynamicObject } from "xa-generics";

export class PageDataModel extends PageModel {
    constructor(rawData?: IDynamicObject<any>, rest?: RestaurantModel) {
        super(rawData?.seos, rest, rawData?.draft);
        if (!rawData) return;
        const data = rawData as PageDataMData;
        ModelConstructor(data, this);

        if (data.elements) this.elements = new DomModel(data.elements);
    }

    elements: DomModel = new DomModel();
}

//If you add public methods, you may want to replace the empty string with them!
export type PageDataMData = Partial<InstanceType<typeof PageDataModel>>;
