import { FC } from "react";
import { useApp } from "../../Provider/App.context";
import { GetColors } from "../../Utils/GetColors.util";
import { ICartUserForm } from "../../../WidgetModules/Cart/Interfaces/ICartUserForm.interface";
import moment from "moment";

interface IDeliveryOptionElementProps {
    activatorValue: ICartUserForm["delivery_method"] | ICartUserForm["delivery_time"];
    currentValue: ICartUserForm["delivery_method"] | ICartUserForm["delivery_time"];
    datepicker?: {
        matrixFrom: "min_preorder_delivery_diff" | "min_preorder_pickup_diff";
        onCloseHook: () => void;
    };
    renderCondition: boolean;
    onClick: () => void;
}

export const DeliveryOptionElement: FC<IDeliveryOptionElementProps> = (props) => {
    const { data, dataSource } = useApp();

    const classes: string[] = ["app-delivery-options__card"];
    const theme = GetColors(data[dataSource]);

    const style: React.CSSProperties = {};
    if (props.currentValue === props.activatorValue) {
        classes.push("--active");
        style.borderColor = theme.backgroundColor;
        style.color = theme.backgroundColor;
    }
    if (props.datepicker) {
        classes.push("--WITH_DATEPICKER");
    }

    if (!props.renderCondition) return null;

    return (
        <div style={style} className={classes.join(" ")} onClick={() => props.onClick()}>
            <div className="app-delivery-options__card--text">{props.children}</div>
            {props.datepicker && (
                <input
                    type={"text"}
                    id={"expected_at"}
                    onChange={() => {}}
                    className="text-input"
                    value={moment().format("YYYY/MM/DD HH:mm")}
                />
            )}
        </div>
    );
};
