import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useForm } from "../../../UseForm/UseForm.provider";
import { Paddings } from "../../../UI/InputGroups/Paddings/Paddings.view";
import { TextInput } from "../../../UI/InputFields/TextInput/TextInput.view";
import { EditorHook } from "../../Utils/EditorHook.util";
import { useProducts } from "../../../DomMapper/DomComponents/Products/Context/ProductsContext.provider";
import { IDomElement } from "../../../DomTracker/Interfaces/IDomTypes.interface";
import { SelectInput } from "../../../UI/InputFields/Select/SelectInput.view";
import { CheckboxInput } from "../../../UI/InputFields/Checkbox/Checkbox.view";
import { useTranslation } from "react-i18next";
import { IProductLayouts } from "../../../DomMapper/DomComponents/Products/Interfaces/IProductLayouts.type";
import { MultiSelectInput } from "../../../UI/InputFields/MultiSelect/MultiSelect.view";
import { ProductCategoryModel } from "../../../DomMapper/DomComponents/Products/Models/ProductCategory.model";
import {
    IProductsCustom,
    IProductsEditors
} from "../../../DomMapper/Interfaces/IProductComponent.interface";
import { InputGroupContextProvider } from "../../../UI/InputGrouper/InputGroup.provider";
import BackgroundImageGroup from "../../../UI/InputGroups/BackgroundImage/BackgroundImageGroup.view";
import BackgroundGroupView from "../../../UI/InputGroups/Background/BackgroundGroup.view";
import EditorHeaderView from "../EditorHeader.view";
import FontColor from "../../../UI/InputGroups/FontInputs/FontColor.input";
import Font from "../../../UI/InputGroups/FontInputs/Font.view";

export interface IProductsEditorProps {
    editor: IDomElement;
}

type ILayoutOption = { id: IProductLayouts; name: ILang };

export const ProductsEditor: FC<IProductsEditorProps> = (props) => {
    const { onBlur, onClear, defaults, units } = EditorHook<IProductsEditors>();
    const { categories } = useProducts();
    const { t } = useTranslation<ILang>();

    const form = useForm<IProductsCustom, true>({
        editor: {
            layoutType: defaults.layoutType || "default_layout",
            displayCategoryIds: defaults.displayCategoryId || [],
            retainBlockRatio: defaults.retainBlockRatio || false,
            hideTileBackground: defaults.hideTileBackground || false,
            useTrimOnCategorized: defaults.useTrimOnCategorized || false,
            dontFocusSearchOnLoad: defaults.dontFocusSearchOnLoad || false,
            alwaysShowSearchBorder: defaults.alwaysShowSearchBorder || false,
            trimDescriptionAfterLength: defaults.trimDescriptionAfterLength || "100"
        },
        fieldHooks: {
            layoutType: (s, value) => onBlur({ field: "layoutType", value }),
            displayCategoryIds: (s, value) => {
                if (!value) onBlur({ field: "displayCategoryId", value: null });
                else onBlur({ field: "displayCategoryId", value });
            }
        }
    });

    return (
        <>
            <EditorHeaderView />

            <InputGroupContextProvider>
                <SelectInput<IProductsCustom, ILayoutOption>
                    value={form.editor.layoutType}
                    onChange={form.handleChange}
                    useTranslationOnName
                    id={"layoutType"}
                    options={[
                        { id: "default_layout", name: "default_layout" },
                        { id: "tile_layout", name: "tile_layout" },
                        { id: "categorized", name: "categorized" }
                    ]}
                />

                {form.editor.layoutType === "categorized" && (
                    <CheckboxInput
                        onBlur={onBlur}
                        id={"useTrimOnCategorized"}
                        onChange={form.handleChange}
                        value={form.editor.useTrimOnCategorized}
                    />
                )}
                {form.editor.layoutType !== "categorized" && (
                    <CheckboxInput
                        onBlur={onBlur}
                        id={"alwaysShowSearchBorder"}
                        onChange={form.handleChange}
                        value={form.editor.alwaysShowSearchBorder}
                    />
                )}
                {form.editor.layoutType === "tile_layout" && (
                    <>
                        <CheckboxInput
                            onBlur={onBlur}
                            id={"retainBlockRatio"}
                            onChange={form.handleChange}
                            value={form.editor.retainBlockRatio}
                        />
                        <CheckboxInput
                            onBlur={onBlur}
                            id={"hideTileBackground"}
                            onChange={form.handleChange}
                            value={form.editor.hideTileBackground}
                        />
                    </>
                )}

                {(form.editor.layoutType === "default_layout" ||
                    form.editor.layoutType === "tile_layout") && (
                    <CheckboxInput
                        onBlur={onBlur}
                        id={"dontFocusSearchOnLoad"}
                        onChange={form.handleChange}
                        value={form.editor.dontFocusSearchOnLoad}
                    />
                )}
                {(form.editor.layoutType === "default_layout" ||
                    form.editor.useTrimOnCategorized) && (
                    <TextInput<IProductsCustom>
                        isNumeric
                        onChange={form.handleChange}
                        onBlur={(data) => onBlur(data)}
                        id={"trimDescriptionAfterLength"}
                        value={form.editor.trimDescriptionAfterLength}
                    />
                )}

                <BackgroundImageGroup
                    units={units}
                    onBlur={onBlur}
                    onReset={onClear}
                    defaultValues={defaults}
                />

                <BackgroundGroupView
                    units={units}
                    onBlur={onBlur}
                    onReset={onClear}
                    defaultValues={defaults}
                />

                <Paddings
                    units={units}
                    onBlur={onBlur}
                    onReset={onClear}
                    defaultValues={defaults}
                />

                <FontColor
                    units={units}
                    onBlur={onBlur}
                    onReset={onClear}
                    defaultValues={defaults}
                />

                <Font units={units} onBlur={onBlur} onReset={onClear} defaultValues={defaults} />

                <MultiSelectInput<IProductsCustom, ProductCategoryModel>
                    isClearable
                    options={categories}
                    id={"displayCategoryIds"}
                    onChange={form.handleChange}
                    value={form.editor.displayCategoryIds}
                    placeholder={"display_category_placeholder"}
                    description={t<ILang>("displayCategoryDescription")}
                />
            </InputGroupContextProvider>
        </>
    );
};
