export const DISPLAY_OPTIONS: { id: string }[] = [
    { id: "flex" },
    { id: "block" },
    { id: "inline-block" },
    { id: "inline-flex" }
];

export const JUSTIFY_OPTIONS: { id: string }[] = [
    { id: "flex-start" },
    { id: "flex-end" },
    { id: "center" },
    { id: "space-between" },
    { id: "space-evenly" },
    { id: "space-around" },
    { id: "stretch" }
];

export const WRAP_OPTIONS: { id: string }[] = [
    { id: "wrap" },
    { id: "wrap-reverse" },
    { id: "nowrap" },
    { id: "unset" },
    { id: "inherit" },
    { id: "initial" }
];

export const FLEX_DIRECTION_OPTIONS: { id: string }[] = [
    { id: "row" },
    { id: "row-reverse" },
    { id: "column" },
    { id: "column-reverse" },
    { id: "unset" },
    { id: "inherit" },
    { id: "initial" }
];
