import { ModelConstructor, IDynamicObject, setBoolean } from "xa-generics";
import { RestaurantModel } from "sitebuilder-common";
import { PageModel } from "./Page.model";

export class DomlessPageModel extends PageModel {
    constructor(rawData?: IDynamicObject<any>, rest?: RestaurantModel, isDraft?: boolean) {
        super(rawData?.seos, rest, rawData?.draft);
        if (!rawData) return;
        const data = rawData as DomlessPageMData;
        ModelConstructor(data, this);

        if (isDraft !== undefined) {
            this.isCurrentPageDraft = setBoolean(isDraft);
        }
    }
}

//If you add public methods, you may want to replace the empty string with them!
type DomlessPageMData = Partial<InstanceType<typeof DomlessPageModel>>;
