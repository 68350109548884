import { ILang } from "../../../Interfaces/ILang.type";
import { useTranslation } from "react-i18next";
import { useComponentDnD } from "../Provider/ComponentDnD.provider";
import { TemplateSectionsView } from "../../Templates/View/TemplateSections.view";
import {
    Row,
    Map,
    Link,
    Image,
    Email,
    Video,
    LetterAa,
    Calendar,
    StringText,
    Restaurant,
    ListBulleted,
    ImageReference,
    TouchInteraction,
    CarouselHorizontal
} from "@carbon/icons-react";
import CreateCard from "./CreateCard.view";

export interface IComponentCreatorViewProps {}

const ComponentCreatorView: React.FC<IComponentCreatorViewProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { handleSectionDragEnd, handleItemDragEnd } = useComponentDnD();

    return (
        <div className="component-creator">
            <div className="component-creator__header">{t<ILang>("create_components")}</div>
            <div className="component-creator__content">
                <div className="component-creator__title">{t<ILang>("full_block_components")}</div>
                <div className="component-creator__card-container --full-width">
                    <CreateCard
                        Icon={<Restaurant size={32} />}
                        type={"Products"}
                        handleDragend={handleSectionDragEnd}
                    />
                    <CreateCard
                        Icon={<Email size={32} />}
                        type={"ContactForm"}
                        handleDragend={handleSectionDragEnd}
                    />
                    <CreateCard
                        Icon={<ImageReference size={32} />}
                        type={"Gallery"}
                        handleDragend={handleSectionDragEnd}
                    />
                    <CreateCard
                        Icon={<Calendar size={32} />}
                        type={"TableReserver"}
                        handleDragend={handleSectionDragEnd}
                    />
                    <CreateCard
                        Icon={<Map size={32} />}
                        type={"RestMap"}
                        handleDragend={handleSectionDragEnd}
                    />
                    <CreateCard
                        Icon={<CarouselHorizontal size={32} />}
                        type={"SlideShow"}
                        handleDragend={handleSectionDragEnd}
                    />
                </div>
                <div className="spacing"></div>
                <div className="component-creator__title">{t<ILang>("elements")}</div>
                <div className="component-creator__card-container">
                    <CreateCard
                        handleDragend={handleItemDragEnd}
                        Icon={<LetterAa size={32} />}
                        type={"Header"}
                    />
                    <CreateCard
                        handleDragend={handleItemDragEnd}
                        Icon={<StringText size={32} />}
                        type={"Text"}
                    />
                    <CreateCard
                        handleDragend={handleItemDragEnd}
                        Icon={<TouchInteraction size={32} />}
                        type={"LinkButton"}
                    />
                    <CreateCard
                        handleDragend={handleItemDragEnd}
                        Icon={<Link size={32} />}
                        type={"Link"}
                    />
                    <CreateCard
                        handleDragend={handleItemDragEnd}
                        Icon={<ListBulleted size={32} />}
                        type={"List"}
                    />
                    <CreateCard
                        handleDragend={handleItemDragEnd}
                        Icon={<Image size={32} />}
                        type={"Image"}
                    />
                    <CreateCard
                        handleDragend={handleItemDragEnd}
                        Icon={<Image size={32} />}
                        type={"AppStore"}
                    />
                    <CreateCard
                        handleDragend={handleItemDragEnd}
                        Icon={<Video size={32} />}
                        type={"Video"}
                    />
                </div>
                <div className="component-creator__title">{t<ILang>("blocks")}</div>
                <div className="component-creator__card-container --full-width">
                    <CreateCard
                        Icon={<Row size={32} />}
                        type={"Section"}
                        handleDragend={handleSectionDragEnd}
                    />
                    <TemplateSectionsView isSuperUserView={false} />
                </div>
            </div>
        </div>
    );
};

export default ComponentCreatorView;
