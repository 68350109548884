import { ILang } from "../../../../Interfaces/ILang.type";
import { IBlur } from "../../../Editor/Utils/EditorHook.util";
import { useForm } from "../../../UseForm/UseForm.provider";
import { TextInput } from "../../InputFields/TextInput/TextInput.view";
import { ResetValues } from "../../../Editor/Utils/ResetValues.util";
import { Destructure } from "../../../../Utils/Destructure.util";
import { IInputGroup } from "../../../../Interfaces/IInputGroup.interface";
import { NUMERIC_RULE } from "../../../../Static/FormRules.static";
import { IBorderRadius } from "./IBorderRadius.interface";
import { IFormWithUnit } from "../../../../Interfaces/IFormWithUnit.type";
import { useTranslation } from "react-i18next";
import { CheckIfHasValue } from "../../../../Utils/CheckIfHasValue.util";
import { BorderRadiusDefaults } from "./Defaults.static";
import SizeUnitControlView from "../../SizeUnitControl/SizeUnitControl.view";
import ResetFormView from "../../InputGrouper/ResetForm.view";
import InputGrouper from "../../InputGrouper/InputGrouper.view";
import {
    BorderRadius16,
    TopLeftRadius16,
    TopRightRadius16,
    BottomLeftRadius16,
    BottomRightRadius16
} from "../../SVG/Radiuses.svg";
import { useAfterTriggerChanged } from "xa-generics";

export interface IBorderRadiusProps extends IInputGroup<IBorderRadius<any>> {}

export const BorderRadius: React.FC<IBorderRadiusProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const form = useForm<IFormWithUnit<IBorderRadius>, true>({
        editor: Destructure(BorderRadiusDefaults, props.defaultValues, props.units.borderRadius),
        initialRules: {
            borderTopLeftRadius: NUMERIC_RULE(t),
            borderTopRightRadius: NUMERIC_RULE(t),
            borderBottomLeftRadius: NUMERIC_RULE(t),
            borderBottomRightRadius: NUMERIC_RULE(t)
        }
    });

    useAfterTriggerChanged(() => {
        form.handleSubmit((state) => {
            const data: IBlur<IBorderRadius> = [];
            for (let key in state) {
                if (key === "unit") continue;
                const field = key as keyof IBorderRadius;
                if (state[field]) data.push({ field, value: `${state[field]}${state.unit}` });
            }
            if (data.length > 0) props.onBlur(data);
        })();
    }, [form.editor]);

    return (
        <InputGrouper
            groupKey={"BorderRadius"}
            Icon={BorderRadius16}
            labelKey={"borderRadius"}
            Reset={
                <ResetFormView
                    hasValue={CheckIfHasValue(form, BorderRadiusDefaults)}
                    onReset={(e) => ResetValues(e, BorderRadiusDefaults, form.reset, props.onReset)}
                />
            }
            Controls={<SizeUnitControlView unit={form.editor.unit} setUnit={form.handleChange} />}
        >
            <div className="border-radius-form">
                <TextInput
                    noLabel
                    unit={<TopLeftRadius16 />}
                    onChange={form.handleChange}
                    id={"borderTopLeftRadius"}
                    value={form.editor.borderTopLeftRadius}
                    className="border-radius-input"
                    errors={form.fieldErrors}
                />
                <TextInput
                    noLabel
                    unit={<TopRightRadius16 />}
                    onChange={form.handleChange}
                    id={"borderTopRightRadius"}
                    value={form.editor.borderTopRightRadius}
                    className="border-radius-input"
                    errors={form.fieldErrors}
                />
                <TextInput
                    noLabel
                    unit={<BottomRightRadius16 />}
                    onChange={form.handleChange}
                    id={"borderBottomRightRadius"}
                    value={form.editor.borderBottomRightRadius}
                    className="border-radius-input"
                    errors={form.fieldErrors}
                />
                <TextInput
                    noLabel
                    unit={<BottomLeftRadius16 />}
                    onChange={form.handleChange}
                    id={"borderBottomLeftRadius"}
                    value={form.editor.borderBottomLeftRadius}
                    className="border-radius-input"
                    errors={form.fieldErrors}
                />
            </div>
        </InputGrouper>
    );
};
