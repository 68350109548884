import { ModelConstructor, IDynamicObject } from "xa-generics";
import { ISection } from "../Interfaces/IDomTypes.interface";
import moment from "moment";

export class DomModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as DomMData;
        ModelConstructor(data, this);

        this.dom = typeof data.dom === "string" ? JSON.parse(data.dom || "[]") : data.dom;
    }

    restaurant_id: string = "";
    draft_id: string = "";
    page_id: string = "";
    dom: ISection[] = [];
    created_at: string = "";
    updated_at: string = "";

    /**
     * Returns new Date if the updated_at is undefined
     */
    public getUpdatedDate(): Date {
        if (!this.updated_at) return new Date();
        return moment(this.updated_at).toDate();
    }
}

//If you add public methods, you may want to replace the empty string with them!
type DomMData = Partial<Omit<InstanceType<typeof DomModel>, "dom">> & {
    dom: string;
};
