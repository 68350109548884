import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useCart } from "../Provider/Cart.provider";
import { TextInput } from "../../../UI/InputFields/TextInput/TextInput.view";
import { ToMoneyInt } from "xa-generics";
import { IPartialCSS } from "sitebuilder-common";
import { GetCurrency } from "../../../DomMapper/DomComponents/Products/Utils/GetCurrency.util";
import { ClientButton } from "../../../DomMapper/DomComponents/Button/ClientButton.view";
import { ICartUserForm } from "../Interfaces/ICartUserForm.interface";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import { CartItemElement } from "./CartItem.element";
import { Checkmark, Close } from "@carbon/icons-react";

interface ICartItemsViewProps {}

export const CartItemsView: FC<ICartItemsViewProps> = (props) => {
    const { config } = useRestaurant();
    const { order, form, couponChange } = useCart();
    const currency = GetCurrency();
    const { t } = useTranslation<ILang>();

    const style: IPartialCSS<any> = {};
    if (config.main_btn_bg_color) style.borderColor = config.main_btn_bg_color;

    return (
        <>
            <div className={"cart-block cart__items"}>
                <h4 className="cart__title">{t<ILang>("cart")}</h4>
                {order.items.map((item, index) => (
                    <CartItemElement key={`${index}-cart-item`} item={item} />
                ))}
                <div className="cart__items--coupon">
                    <TextInput<ICartUserForm>
                        noLabel
                        id={"coupon_temp"}
                        value={form.editor.coupon_temp}
                        className={"coupon_temp"}
                        placeholder={t<ILang>("coupon")}
                        onChange={(id, value) => form.handleChange("coupon_temp", value)}
                    />
                    {!form.editor.apply_coupon ? (
                        <ClientButton
                            onClick={() => couponChange(true)}
                            RightIcon={Checkmark}
                            isDisabled={!form.editor.coupon_temp}
                        >
                            {t<ILang>("apply_coupon")}
                        </ClientButton>
                    ) : (
                        <ClientButton
                            isSecondary
                            onClick={() => couponChange(false)}
                            RightIcon={Close}
                        >
                            {t<ILang>("delete")}
                        </ClientButton>
                    )}
                </div>
            </div>
            <div className="cart-block cart__items--footer" style={style}>
                {form.editor.coupon && order.coupon_applied ? (
                    <div className="cart-items__footer--coupon">
                        <b>{t<ILang>("coupon")}:</b> {form.editor.coupon}
                    </div>
                ) : (
                    <span></span>
                )}
                <div className="price-container">
                    <span className="cart__items--footer--total-price">
                        {t<ILang>("total_price")}:
                    </span>
                    {order.discount_price || order.coupon_code ? (
                        <div className={"price-container__prices"}>
                            {order.original_price > order.total_price && (
                                <span className="crossed-price">
                                    {ToMoneyInt(order.original_price)} {currency}
                                </span>
                            )}
                            <span className="final-price">
                                {ToMoneyInt(order.total_price)} {currency}
                            </span>
                        </div>
                    ) : (
                        <span className="cart__items--footer--price">
                            {ToMoneyInt(order.total_price)} {currency}
                        </span>
                    )}
                </div>
            </div>
        </>
    );
};
