import { ILang } from "../../../../Interfaces/ILang.type";
import { TextArea } from "../../../UI/InputFields/TextInput/TextArea.view";
import { DnDEvents } from "../../Util/DND.util";
import { useImages } from "../../../../Contexts/Images.context";
import { TextInput } from "../../../UI/InputFields/TextInput/TextInput.view";
import { IDomElement } from "../../../DomTracker/Interfaces/IDomTypes.interface";
import { ClientButton } from "../Button/ClientButton.view";
import { useTranslation } from "react-i18next";
import { ContactFormStyles } from "../../Static/ContactFormStyles.static";
import { IContactFormContent } from "../../Interfaces/IContactFormComponent.interface";
import { FormStyleSeparator, ParseComponentStyle } from "sitebuilder-common";
import ContactUsPlaceholder from "../../../../Assets/placeholders/contact_us_placeholder.jpg";
import Overlay from "../../View/Overlay.view";

export interface IContactFormProps extends IDomElement<IContactFormContent> {}

export const ContactForm: React.FC<IContactFormProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { images } = useImages();

    const { refs, size, id, showOverlay, ...events } = DnDEvents<IContactFormContent>(
        props,
        "ELEMENT"
    );

    let style = ParseComponentStyle(props.content, ContactFormStyles, size);
    const imageFile = images[props.content.src];
    const { body, text, image } = FormStyleSeparator(style);

    return (
        <div
            id={id}
            {...events}
            style={body}
            className="dom__form dom__contact-form"
            ref={refs.current[props.uuid]}
        >
            {showOverlay && <Overlay parent={props} unmountId={props.sectionUUID} />}
            <div className="dom__contact-form--left">
                <h1 style={text} className={"dom__form--title dom__contact-form--title"}>
                    {t<ILang>("message_us")}
                </h1>
                <form
                    onSubmit={(e) => e.preventDefault()}
                    className="dom__form--element dom__contact-form--form"
                >
                    <TextInput id={"name"} onChange={() => {}} value={"Kiss József"} />
                    <TextInput
                        id={"phone"}
                        onChange={() => {}}
                        value={"+36 30 999 88 77"}
                        labelText={"phone_number"}
                    />
                    <TextInput id={"email"} onChange={() => {}} value={"example@gmail.com"} />
                    <TextArea
                        id={"message"}
                        onBlur={() => {}}
                        onChange={() => {}}
                        value={t<ILang>("message_text")}
                    />
                    <ClientButton type={"submit"}>{t<ILang>("send")}</ClientButton>
                </form>
            </div>
            <div className="dom__form--image dom__contact-form--right">
                <img
                    src={imageFile ? imageFile.url : ContactUsPlaceholder}
                    className={"img dom__contact-form--image"}
                    alt={"Contact form"}
                    draggable={false}
                    style={image}
                />
            </div>
        </div>
    );
};
