import { ModelConstructor, IDynamicObject } from "xa-generics";
import { AbstractProductCommonModel } from "./AbstractProductCommon.model";
import { OneminImgModel } from "./Image.model";
import { ProductModel } from "./Product.model";

export class ProductCategoryModel extends AbstractProductCommonModel {
    constructor(rawData?: IDynamicObject<any>) {
        super();
        if (!rawData) return;
        const data = rawData as ProductCategoryMData;
        ModelConstructor(data, this);

        this.image_web = new OneminImgModel(data.image_web);

        if (typeof data.image_app === "object") {
            this.image_app = new OneminImgModel(data.image_app!);
        }

        if (typeof data.image_desc === "object") {
            this.image_desc = new OneminImgModel(data.image_desc!);
        }

        if (data.products instanceof Array) {
            this.products = data.products.map((item) => new ProductModel(item));
        }

        this.setSlugAndShortName();
    }

    restaurant_id: string = "";
    description: string = "";
    description_sec_lang: string = "";
    visible_online: boolean = true;
    visible_sit_in: boolean = true;
    visible_phone: boolean = true;
    parent_id: string = "";
    depot_id: string = "";
    sit_in_vat_id: string = "";
    vat_id: string = "";
    available_from: string = "";
    available_to: string = "";
    expected_at: string = "";
    has_child: boolean = false;

    products: ProductModel[] = [];

    image_app: OneminImgModel | null = null;
    image_web!: OneminImgModel;
    image_desc: OneminImgModel | null = null;
    children: ProductCategoryModel[] = [];

    public setHasChild(categories: ProductCategoryModel[]): void {
        for (let category of categories) {
            if (category.parent_id === this.id) {
                this.has_child = true;
                this.children.push(category);
            }
        }
    }
}

//If you add public methods, you may want to replace the empty string with them!
type ProductCategoryMData = Partial<InstanceType<typeof ProductCategoryModel>>;
