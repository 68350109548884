import {
    BackgroundStyles,
    BorderStyles,
    ColorStyles
} from "../../../DomMapper/Static/GroupsStyles.static";
import { IFooterBlockStyles } from "../Interfaces/IFooterBlock.interface";

interface IFooter extends IFooterBlockStyles<Required<true>> {}

export const FooterBlockStyles: IFooter = {
    ...BackgroundStyles,
    ...BorderStyles,
    ...ColorStyles
};
