import { DocumentBlank, Edit, TrashCan } from "@carbon/icons-react";
import { IPageListViewProps } from "./PageList.view";
import { DomlessPageModel } from "../../../DomTracker/Model/DomlessPage.model";
import { useTranslation } from "react-i18next";
import { PageDataModel } from "../../../DomTracker/Model/PageData.model";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { PageControls } from "./PageControls.view";
import { IPageEditor } from "../../../DomMapper/Interfaces/IPageEditor.interface";
import { DraftModel } from "../../../DomTracker/Model/Draft.model";
import { useEditor } from "../../Controller/EditorContext.provider";
import { PageName } from "./PageName.view";
import { useState } from "react";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { ILang } from "../../../../Interfaces/ILang.type";
import Modal from "../../../UI/Modal/Modal.view";

export interface IPageListElementViewProps extends IPageListViewProps {
    page: PageDataModel;
    draft: DraftModel | null;
}

const PageListElementView: React.FC<IPageListElementViewProps> = (props) => {
    const page = props.page;
    const { t } = useTranslation<ILang>();
    const { restaurant } = useRestaurant();
    const { hasChanges, setNavigateEvent } = useEditor();
    const { currentPage, setCurrentPage, destroyPage, destroyDraftOfPage } = useDom<IPageEditor>();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);

    const classes: string[] = ["page"];
    const isParentOfDraft = page.draft && !props.draft;

    if (currentPage.page_id === page.page_id) {
        if (currentPage.isCurrentPageDraft && props.draft) classes.push("--active-page");
        if (!currentPage.isCurrentPageDraft && !props.draft) classes.push("--active-page");
    }
    if (props.draft || !props.page.is_active) {
        classes.push("--unpublished-page");
    }
    if (props.draft) {
        classes.push("--cloned-draft-page");
    }
    if (!props.page.is_active) {
        classes.push("--inactive-page");
    }
    if (isParentOfDraft) {
        classes.push("--parent-page");
    }

    return (
        <>
            <div
                className={classes.join(" ")}
                onClick={() => {
                    const event = () => {
                        setCurrentPage(
                            new DomlessPageModel(page, restaurant, page.draft ? true : false)
                        );
                    };
                    if (hasChanges) {
                        setNavigateEvent(() => event);
                    } else event();
                }}
                key={`${page.page_id}-page`}
            >
                <div className={"page__name"}>
                    {props.draft ? (
                        <div className={"page__child-marker"}>
                            <div className="vertical"></div>
                            <div className="horizontal"></div>
                        </div>
                    ) : null}
                    <div className="svg">{props.draft ? <Edit /> : <DocumentBlank />}</div>
                    <PageName page={page} draft={props.draft} />
                </div>

                <PageControls
                    {...props}
                    draft={props.draft}
                    setShowDeleteConfirm={setShowDeleteConfirm}
                />

                {showDeleteConfirm && (
                    <Modal
                        isDanger
                        size="XS"
                        submitIcon={TrashCan}
                        submitText={t<ILang>("yes")}
                        heading={t<ILang>("warning")}
                        onClose={() => setShowDeleteConfirm(false)}
                        onSubmit={() => {
                            if (props.draft) {
                                destroyDraftOfPage(page.page_id);
                            } else {
                                destroyPage(page.page_id);
                            }
                        }}
                    >
                        {t<ILang>("delete_page")}
                    </Modal>
                )}
            </div>
            {isParentOfDraft && <PageListElementView {...props} page={page} draft={page.draft} />}
        </>
    );
};

export default PageListElementView;
