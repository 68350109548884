import { IMultiSelectInputProps } from "../MultiSelect.view";

export const SelectClear = <O extends object, F extends object, L = string>(
    e: MouseEvent,
    props: IMultiSelectInputProps<O, F, L>
): void => {
    e.stopPropagation();
    e.preventDefault();
    props.onChange(props.id, []);
};
