import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { Untransform } from "../../../../Utils/UntransformComponentContent.util";
import { IWidgetGroups } from "../../../Widgets/Interfaces/IWidgetGroups.type";
import { useTranslation } from "react-i18next";
import { INavbarEditorProps } from "./NavbarEditor.index";
import BackgroundGroupView from "../../../UI/InputGroups/Background/BackgroundGroup.view";
import FontColor from "../../../UI/InputGroups/FontInputs/FontColor.input";
import Font from "../../../UI/InputGroups/FontInputs/Font.view";

interface INavbarStatusEditorViewProps extends INavbarEditorProps {}

export const NavbarStatusEditorView: FC<INavbarStatusEditorViewProps> = (props) => {
    const {
        i18n: { language }
    } = useTranslation<ILang>();
    const { size } = useDom();

    const widgetProp: IWidgetGroups = "navbarStatus";
    if (props.subKey !== widgetProp) return null;

    const model = new Untransform(props.editor.draftSettings.navbarStatus || {}, size, language);
    const defaults = model.toJSON();
    const units = model.getUnits();

    return (
        <>
            <BackgroundGroupView
                onBlur={(data) => props.handleChange(widgetProp, data)}
                onReset={(keys) => props.onReset(widgetProp, keys)}
                defaultValues={defaults}
                units={units}
            />

            <FontColor
                onBlur={(data) => props.handleChange(widgetProp, data)}
                onReset={(keys) => props.onReset(widgetProp, keys)}
                defaultValues={defaults}
                units={units}
            />

            <Font
                onBlur={(data) => props.handleChange(widgetProp, data)}
                onReset={(keys) => props.onReset(widgetProp, keys)}
                defaultValues={defaults}
                units={units}
            />
        </>
    );
};
