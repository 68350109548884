import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";
import { useWidgets } from "../../../Widgets/Controller/WidgetsContext.provider";
import { SelectInput } from "../../../UI/InputFields/Select/SelectInput.view";
import { Untransform } from "../../../../Utils/UntransformComponentContent.util";
import { CheckboxInput } from "../../../UI/InputFields/Checkbox/Checkbox.view";
import { IWidgetGroups } from "../../../Widgets/Interfaces/IWidgetGroups.type";
import { useTranslation } from "react-i18next";
import { INavbarEditorProps } from "./NavbarEditor.index";
import BackgroundGroupView from "../../../UI/InputGroups/Background/BackgroundGroup.view";
import AlignPickerView from "../../../UI/InputFields/AlignPicker/AlignPicker.view";
import BoxShadowView from "../../../UI/InputGroups/BoxShadow/BoxShadow.view";
import FontColor from "../../../UI/InputGroups/FontInputs/FontColor.input";
import Font from "../../../UI/InputGroups/FontInputs/Font.view";

interface INavbarDetailsEditorViewProps extends INavbarEditorProps {}

export const NavbarDetailsEditorView: FC<INavbarDetailsEditorViewProps> = (props) => {
    const {
        i18n: { language }
    } = useTranslation<ILang>();
    const { size } = useDom();
    const {
        widgets: {
            Navbar: { draftSettings }
        }
    } = useWidgets();
    const widgetProp: IWidgetGroups = "navbarStyle";
    const alignProp: IWidgetGroups = "navbarAlign";
    if (props.subKey !== widgetProp && props.subKey !== alignProp) return null;

    const model = new Untransform(props.editor.draftSettings.navbarStyle || {}, size, language);
    const defaults = model.toJSON();
    const units = model.getUnits();

    const alignModel = new Untransform(
        props.editor.draftSettings.navbarAlign || {},
        size,
        language
    );
    const alignDefaults = alignModel.toJSON();
    const navVersion = draftSettings.navbarVersion || "default";

    return (
        <>
            <SelectInput
                id={"navbarVersion"}
                onChange={(field, value) => props.handleChange("navbarVersion", { field, value })}
                value={navVersion}
                useTranslationOnName
                options={[
                    { id: "default", name: "default_layout" },
                    { id: "v2", name: "navbar_v2" },
                    { id: "v3", name: "navbar_v3" }
                ]}
            />
            <CheckboxInput
                id={"hideCartButtons"}
                value={draftSettings.hideCartButtons || false}
                onChange={(field, value) => props.handleChange(field, { field, value })}
            />
            <CheckboxInput
                id={"selectPaymentMethod"}
                value={draftSettings.selectPaymentMethod || false}
                onChange={(field, value) => props.handleChange(field, { field, value })}
            />
            <CheckboxInput
                id={"disableOrder"}
                value={draftSettings.disableOrder || false}
                onChange={(field, value) => props.handleChange(field, { field, value })}
            />
            <BackgroundGroupView
                onBlur={(data) => props.handleChange(widgetProp, data)}
                onReset={(keys) => props.onReset(widgetProp, keys)}
                defaultValues={defaults}
                units={units}
            />

            <BoxShadowView
                onBlur={(data) => props.handleChange(widgetProp, data)}
                onReset={(keys) => props.onReset(widgetProp, keys)}
                defaultValues={defaults}
                units={units}
            />

            <FontColor
                onBlur={(data) => props.handleChange(widgetProp, data)}
                onReset={(keys) => props.onReset(widgetProp, keys)}
                defaultValues={defaults}
                units={units}
            />

            <Font
                onBlur={(data) => props.handleChange(widgetProp, data)}
                onReset={(keys) => props.onReset(widgetProp, keys)}
                defaultValues={defaults}
                units={units}
            />
            {props.navVersion === "default" && (
                <AlignPickerView
                    id={"justifyContent"}
                    labelText={alignProp}
                    valueSet={"NAV_GRID"}
                    defaultValue={alignDefaults.justifyContent || "center"}
                    onBlur={(data) => props.handleChange(alignProp, data)}
                />
            )}
        </>
    );
};
