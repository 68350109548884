import { Reset } from "@carbon/icons-react";
import { ILang } from "../../../Interfaces/ILang.type";
import { useTranslation } from "react-i18next";

export interface IResetFormViewProps {
    onReset: (e: React.MouseEvent) => void;
    hasValue: boolean;
}

const ResetFormView: React.FC<IResetFormViewProps> = (props) => {
    const { t } = useTranslation<ILang>();

    if (!props.hasValue) return null;
    return (
        <div
            className="reset-svg"
            title={t<ILang>("reset_rule_on_this_size")}
            onClick={props.onReset}
        >
            <Reset />
        </div>
    );
};

export default ResetFormView;
