import { IFormWithUnit } from "../../../../Interfaces/IFormWithUnit.type";
import { ITextShadow } from "./ITextShadow.interface";

export const TextShDefaults: IFormWithUnit<ITextShadow> = {
    textBlur: "0",
    textColor: "#00000022",
    unit: "px",
    x: "0",
    y: "0"
};
