import { IColor, IFont } from "./IFont.interface";

export const ColorDefaults: IColor = {
    color: ""
};

export const FontDefaults = (FONT_FALLBACK: string): IFont => ({
    fontSize: "",
    fontStyle: "",
    fontWeight: "",
    letterSpacing: "",
    lineHeight: "",
    textDecoration: "",
    fontFamily: FONT_FALLBACK,
    textTransform: ""
});
