import { ILang } from "../../../Interfaces/ILang.type";
import { Objectify } from "xa-generics";
import { useAccess } from "../../../Contexts/Access.context";
import { FC, useMemo } from "react";
import { useTemplate } from "../Provider/Template.provider";
import { useRestaurant } from "../../../Contexts/Restaurant.context";
import { WithConfirm } from "../../UI/Confirm/Confirm.view";
import { useTranslation } from "react-i18next";
import { ITemplatesViewProps } from "./Templates.view";
import { TemplateRestaurantModel } from "../Model/TemplateRestaurant.model";
import {
    Add,
    Edit,
    Launch,
    TrashCan,
    Restaurant,
    ThumbsUpFilled,
    NotebookReference
} from "@carbon/icons-react";
import Button from "../../UI/Button/Button.view";

interface ITemplatesRestaurantsViewProps extends ITemplatesViewProps {}

export const TemplatesRestaurantsView: FC<ITemplatesRestaurantsViewProps> = (props) => {
    const { t, i18n } = useTranslation<ILang>();
    const lang = i18n.language;
    const { accessModel } = useAccess();
    const { restaurant, switchToRestaurant } = useRestaurant();
    const { templateRestaurants, templateCategories, removeRestaurant, loading } = useTemplate();

    const categories = useMemo(
        () => Objectify(templateCategories, "template_category_id"),
        [templateCategories]
    );
    const templateRef = useMemo((): TemplateRestaurantModel | null => {
        if (restaurant && templateRestaurants.length > 0) {
            const result = templateRestaurants.find(
                (model) => model.restaurant_id === restaurant.id
            );
            if (result) return result;
            return null;
        }
        return null;
    }, [templateRestaurants, restaurant]);

    const nameKey = lang !== "hu" ? "name_secondary" : "name";
    const descKey = lang !== "hu" ? "description_secondary" : "description";

    return (
        <div className="templates-view__restaurants">
            <h6 className="templates-view__title templates-view__restaurants--title">
                {t<ILang>("rest_template")}
            </h6>
            {accessModel.super_admin && !templateRef && (
                <Button
                    useContainer
                    Icon={Add}
                    size={"SMALL"}
                    kind={"SIMPLE"}
                    align="right"
                    onClick={() => {
                        const model = new TemplateRestaurantModel({
                            restaurant_id: restaurant.id,
                            preview_url: `https://${restaurant.domain}`,
                            description: restaurant.name
                        });
                        props.form.setEditor(model);
                    }}
                >
                    {t<ILang>("add_new")}
                </Button>
            )}
            {templateRef && (
                <div className="templates-view__desc">{t<ILang>("already_template")}</div>
            )}
            {templateRestaurants.map((model) => {
                const classes: string[] = ["template-restaurant"];
                const isCurrent = templateRef?.restaurant_id === model.restaurant_id;
                if (isCurrent) classes.push("current-restaurant");
                return (
                    <div
                        title={
                            isCurrent
                                ? `${t<ILang>("current_restaurant")} | ${model[descKey]}`
                                : model[descKey]
                        }
                        className={classes.join(" ")}
                        key={`${model.restaurant_id}-template-restaurant`}
                    >
                        <div className="template-restaurant__left">
                            <Restaurant />{" "}
                            <span className="text">
                                {categories[model.template_category_id]?.[nameKey]}
                            </span>
                        </div>
                        <div className="template-restaurant__controls">
                            <WithConfirm
                                className="template-restaurant__controls--button apply-button no-left-spacing"
                                questionContent={
                                    <div className={"global__justified"}>
                                        {t<ILang>("rest_apply_confirm")}
                                        <br />
                                        <br />
                                        <strong> {t<ILang>("rest_apply_recommend")}</strong>
                                    </div>
                                }
                                onConfirm={() => props.applyRestaurantTemplate(model)}
                                Icon={<ThumbsUpFilled />}
                                disabled={isCurrent}
                                isDanger={false}
                                size={"SM"}
                            >
                                <button
                                    className="template-restaurant__controls--button apply-button"
                                    title={t<ILang>(isCurrent ? "cant_apply_itself" : "apply")}
                                >
                                    <NotebookReference />
                                </button>
                            </WithConfirm>
                            <a
                                className="template-restaurant__controls--button"
                                title={t<ILang>("view_it")}
                                rel={"noreferrer noopener"}
                                href={model.preview_url}
                                target={"_blank"}
                            >
                                <Launch />
                            </a>
                            {accessModel.super_admin && (
                                <>
                                    <button
                                        className="template-restaurant__controls--button"
                                        onClick={() => {
                                            if (model.restaurant_id === restaurant.id)
                                                props.form.setEditor(model);
                                            else switchToRestaurant(model.restaurant_id);
                                        }}
                                        title={t<ILang>(
                                            model.restaurant_id === restaurant.id
                                                ? "edit"
                                                : "switch_to_rest_to_edit"
                                        )}
                                    >
                                        <Edit />
                                    </button>

                                    <WithConfirm
                                        onConfirm={() => removeRestaurant(model.restaurant_id)}
                                        className="template-restaurant__controls--button no-left-spacing"
                                    >
                                        <button
                                            className="template-restaurant__controls--button danger"
                                            title={t<ILang>("delete")}
                                        >
                                            <TrashCan />
                                        </button>
                                    </WithConfirm>
                                </>
                            )}
                        </div>
                        <div className="template-restaurant__image">
                            <img
                                src={model.image_url}
                                alt={model.image_file_name}
                                className={"img"}
                            />
                        </div>
                    </div>
                );
            })}
            {!templateRestaurants.length && !loading ? (
                <div className="no-data">{t<ILang>("no_data")}</div>
            ) : null}
        </div>
    );
};
