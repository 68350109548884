import { AppLayout } from "./App.layout";
import { AppInfoView } from "./AppInfo.view";
import { AppCartView } from "./Cart/AppCart.index";
import { FC, useState } from "react";
import { AppProfileIndex } from "./AppProfile.index";
import { AppExtraContent } from "./AppExtraContent.view";
import { AppOrderController } from "./Order/AppOrder.controller";
import { Navigate, Route, Routes } from "react-router";
import { AppConsumer, AppContextProvider } from "../Provider/App.context";

interface IAppRoutesProps {}

export const AppRoutes: FC<IAppRoutesProps> = (props) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const menuProps = { isMenuOpen, setIsMenuOpen };

    return (
        <AppContextProvider>
            <AppConsumer>
                {(value) => (
                    <AppLayout {...menuProps}>
                        <Routes>
                            <Route
                                path={"/order"}
                                element={<AppOrderController {...menuProps} />}
                            />
                            <Route
                                path={"/order/categories/:categoryId"}
                                element={<AppOrderController {...menuProps} />}
                            />
                            <Route
                                path={"/order/categories/:categoryId/products"}
                                element={<AppOrderController {...menuProps} />}
                            />
                            <Route
                                path={"/order/categories/:categoryId/products/:productId"}
                                element={<AppOrderController {...menuProps} />}
                            />
                            <Route path={"/info"} element={<AppInfoView />} />
                            <Route
                                path={"/profile/my-data"}
                                element={<AppProfileIndex {...menuProps} key={`my-data`} />}
                            />
                            <Route
                                path={"/profile/addresses"}
                                element={<AppProfileIndex {...menuProps} key={`my-addresses`} />}
                            />
                            <Route
                                path={"/profile/past-orders"}
                                element={<AppProfileIndex {...menuProps} key={`past-orders`} />}
                            />
                            <Route path={"/cart"} element={<AppCartView step={"content"} />} />
                            <Route
                                path={"/cart/settings"}
                                element={<AppCartView step={"settings"} />}
                            />
                            <Route
                                path={"/cart/finalize"}
                                element={<AppCartView step={"finalize"} />}
                            />
                            {value.data[value.dataSource].global.extraPageContent && (
                                <Route path="/content" element={<AppExtraContent />} />
                            )}
                            <Route path={"*"} element={<Navigate to={"/order"} replace />} />
                        </Routes>
                    </AppLayout>
                )}
            </AppConsumer>
        </AppContextProvider>
    );
};
