import { ConfigModel, ImageModel, RestaurantModel } from "sitebuilder-common";
import { TemplateRestaurantModel } from "../Model/TemplateRestaurant.model";
import { axios, IDynamicObject } from "xa-generics";
import { TemplateCategoryModel } from "../Model/TemplateCategory.model";
import { TemplateBlockModel } from "../Model/TemplateBlock.model";
import { PageDataModel } from "../../DomTracker/Model/PageData.model";
import { WidgetsModel } from "../../Widgets/Model/Widgets.model";

export abstract class TemplatesDAO {
    private static readonly base: string = "/api/templates";
    private static readonly rest: string = `${this.base}/restaurants`;
    private static readonly cats: string = `${this.base}/categories`;
    private static readonly sect: string = `${this.base}/sections`;

    private static restWithId(restaurant_id: string): string {
        return this.rest + `/${restaurant_id}`;
    }
    private static sectWithId(template_id: string): string {
        return this.sect + `/${template_id}`;
    }

    public static async loadCategories(): Promise<TemplateCategoryModel[]> {
        const request = await axios.getInstance().get<IDynamicObject<any>[]>(this.cats);
        return request.data.map((item) => new TemplateCategoryModel(item));
    }

    public static async loadBlocks(): Promise<TemplateBlockModel[]> {
        const request = await axios.getInstance().get<IDynamicObject<any>[]>(this.sect);
        return request.data.map((item) => new TemplateBlockModel(item));
    }

    public static async loadRestaurants(): Promise<TemplateRestaurantModel[]> {
        const request = await axios.getInstance().get<IDynamicObject<any>[]>(this.rest);
        return request.data.map((item) => new TemplateRestaurantModel(item));
    }

    public static async loadRestPages(
        templateRestId: string,
        rest: RestaurantModel
    ): Promise<PageDataModel[]> {
        const request = await axios
            .getInstance()
            .get<IDynamicObject<any>[]>(`${this.restWithId(templateRestId)}/pages`);
        return request.data.map((data) => new PageDataModel(data, rest));
    }

    public static async loadRestImages(templateRestId: string): Promise<ImageModel[]> {
        const request = await axios
            .getInstance()
            .get<IDynamicObject<any>[]>(`${this.restWithId(templateRestId)}/images`);
        return request.data.map((data) => new ImageModel(data));
    }

    public static async loadRestConfig(templateRestId: string): Promise<ConfigModel> {
        const request = await axios
            .getInstance()
            .get<IDynamicObject<any>>(`${this.restWithId(templateRestId)}/config`);
        return new ConfigModel(request.data);
    }

    public static async loadRestWidgets(templateRestId: string): Promise<WidgetsModel> {
        const request = await axios
            .getInstance()
            .get<IDynamicObject<any>>(`${this.restWithId(templateRestId)}/widgets`);
        return new WidgetsModel(request.data);
    }

    public static async saveBlock(data: IDynamicObject<any>): Promise<TemplateBlockModel> {
        const request = await axios.getInstance().post<IDynamicObject<any>>(this.sect, data);
        return new TemplateBlockModel(request.data);
    }

    public static async saveRestaurant(
        data: IDynamicObject<any>
    ): Promise<TemplateRestaurantModel> {
        const request = await axios.getInstance().post<IDynamicObject<any>>(this.rest, data);
        return new TemplateRestaurantModel(request.data);
    }

    public static async updateRestaurant(
        restaurant_id: string,
        data: IDynamicObject<any>
    ): Promise<void> {
        await axios.getInstance().patch<void>(this.restWithId(restaurant_id), data);
        return;
    }

    public static async removeBlock(template_id: string): Promise<void> {
        await axios.getInstance().delete<void>(this.sectWithId(template_id));
        return;
    }

    public static async removeRestaurant(restaurant_id: string): Promise<void> {
        await axios.getInstance().delete<void>(this.restWithId(restaurant_id));
        return;
    }
}
