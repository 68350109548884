import { FC } from "react";
import { ISetState } from "xa-generics";
import { NavbarCart } from "./NavbarCart.view";
import { Close, Menu } from "@carbon/icons-react";
import { NavbarStatus } from "./NavbarStatus.view";
import { V2NavbarLangs } from "./V2NavbarLangs.view";
import { V2NavbarProfile } from "./V2NavbarProfile.view";
import { INavbarVersions } from "../Interfaces/INavbar.type";

interface IV2NavbarRightProps {
    setIsNavbarOpened: ISetState<boolean>;
    navVersion: INavbarVersions;
    isNavbarOpened: boolean;
}

export const V2NavbarRight: FC<IV2NavbarRightProps> = (props) => {
    return (
        <div className={"v2navbar__right"}>
            <V2NavbarLangs />
            <NavbarStatus />
            <V2NavbarProfile />
            <NavbarCart navVersion={props.navVersion} />

            <button
                className="v2navbar__toggle"
                onClick={() => props.setIsNavbarOpened(!props.isNavbarOpened)}
            >
                {props.isNavbarOpened ? <Close size={28} /> : <Menu size={28} />}
            </button>
        </div>
    );
};
