import { ILang } from "../../../../../Interfaces/ILang.type";
import { TileView } from "./Tile.view";
import { FC, useRef } from "react";
import { DotsLoading } from "../../../../UI/Loading/DotsLoading.view";
import { ProductModel } from "../Models/Product.model";
import { useRestaurant } from "../../../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import { IProductLayouts } from "../Interfaces/IProductLayouts.type";
import { IProductsIndexProps } from "./Products.index";
import { ProductBlockElement } from "./ProductBlock.element";
import Modal from "../../../../UI/Modal/Modal.view";

interface IProductMapperViewProps extends IProductsIndexProps {
    layout: IProductLayouts;
    trimDescription: number;
}

export const ProductMapperView: FC<IProductMapperViewProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { config } = useRestaurant();
    const containerRef = useRef<HTMLDivElement | null>(null);

    if (!props.selector.category && props.filteredProducts.length === 0) return null;

    const classes: string[] = ["products__product-map"];
    const isDefault: boolean = props.layout === "default_layout";
    const isFiltered: boolean = props.filter.length > 0;

    if (isDefault) classes.push("--block-mode");
    else classes.push("--tile-mode");

    const list = isFiltered ? props.filteredProducts : props.selector.category!.products || [];

    return (
        <div className={classes.join(" ")} ref={(ref) => (containerRef.current = ref)}>
            {isDefault && props.selector.category && !isFiltered && (
                <div
                    className="products__product-map--heading"
                    style={{ borderBottomColor: config.main_btn_bg_color }}
                >
                    <div className="products__product-map--heading--name">
                        {props.selector.category.name}
                    </div>
                </div>
            )}

            {props.isFilterPending && (
                <div className={"products__info-text"}>
                    {t<ILang>("pending_filter")} <DotsLoading />
                </div>
            )}

            {isFiltered && props.filteredProducts.length === 0 && !props.isFilterPending && (
                <div className={"products__info-text"}>{t<ILang>("no_product_found")}</div>
            )}

            {list.map((product) => {
                const key = `${product.id}-${props.selector.category!.id}`;
                if (isDefault)
                    return (
                        <ProductBlockElement
                            key={key}
                            product={product}
                            trimDescription={props.trimDescription}
                        />
                    );
                return (
                    <TileView<ProductModel>
                        item={product}
                        nameKey={"name"}
                        imageUrl={product.image?.medium}
                        key={key}
                        content={props.content}
                        hideImageBackground={props.content.hideTileBackground}
                        onClick={() => props.setShowOptionWarn(true)}
                    />
                );
            })}
            {props.showOptionWarn && (
                <Modal
                    isPassive
                    onClose={() => props.setShowOptionWarn(false)}
                    allowEnterKeyDown
                    heading={t<ILang>("warning")}
                >
                    {t<ILang>("option_warn_desc")}
                </Modal>
            )}
        </div>
    );
};
