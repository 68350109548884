import { IColumnStyles } from "../Interfaces/IColumnComponent.interface";
import {
    BackgroundImageStyles,
    BackgroundStyles,
    DisplayStyles,
    HeightStyles,
    PaddingStyles,
    WidthStyles
} from "./GroupsStyles.static";

export const ColumnStyles: Required<IColumnStyles<true>> = {
    ...WidthStyles,
    ...HeightStyles,
    ...DisplayStyles,
    ...PaddingStyles,
    ...BackgroundStyles,
    ...BackgroundImageStyles
};
