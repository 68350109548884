import { IFormWithUnit } from "../../../../Interfaces/IFormWithUnit.type";
import { IMargins } from "./IMargins.interface";

export const MarginDefaults: IFormWithUnit<IMargins> = {
    marginBottom: "",
    marginLeft: "",
    marginTop: "",
    marginRight: "",
    unit: "rem"
};
