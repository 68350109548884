import { useTranslation } from "react-i18next";
import { GetSizeIcon } from "../Utils/GetSizeIcon.util";
import { ISizes } from "sitebuilder-common";
import { useDom } from "../../DomTracker/Controller/DomTracker.provider";
import { ILang } from "../../../Interfaces/ILang.type";
import { FC } from "react";

interface ISizeMenuItemProps {
    domSize: ISizes;
}

export const SizeMenuItem: FC<ISizeMenuItemProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { size, setSize } = useDom();

    const classes: string[] = ["menu-head__dropmenu--item"];
    if (props.domSize === size) classes.push("active-item");
    //Event bubbling will call close, so no need to set it
    return (
        <div className={classes.join(" ")} onClick={() => setSize(props.domSize)}>
            {GetSizeIcon(props.domSize)}
            <span className="text">{t<ILang>(props.domSize)}</span>
        </div>
    );
};
