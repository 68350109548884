import { ILang } from "../../../../../Interfaces/ILang.type";
import { IFieldBlur } from "../../../../Editor/Utils/EditorHook.util";
import { ImageInputView } from "../View/ImageInput.view";
import { useRef, useState } from "react";
import { IDynamicObject, useAfterTriggerChanged } from "xa-generics";

export interface IImageInputProps<Fields extends object> {
    children?: any;
    id: keyof Fields;
    labelText?: ILang;
    noLabel?: boolean;
    className?: string;
    disabled?: boolean;
    aspectRatio?: number;
    customLabel?: string;
    value: string | File | null;
    onReset?: (data: IFieldBlur<Fields>) => void;
    onChange: (id: keyof Fields, value: string) => void;
}

export const ImageInput = <T extends object>(props: IImageInputProps<T>) => {
    const [internalFile, setInternalFile] = useState<File | string | null>(
        props.value ? props.value : null
    );
    const [fileSrc, setFileSrc] = useState<string>("");
    const [showSelector, setShowSelector] = useState<boolean>(false);
    const [deleteWarnImgId, setDeleteWarnImgId] = useState<null | string | string[]>(null);
    const [selectedImages, setSelectedImages] = useState<IDynamicObject>({});
    const inputRef = useRef<HTMLInputElement | null>(null);

    const clearFile = (): void => {
        setInternalFile(null);
        setFileSrc("");
        if (inputRef.current) inputRef.current.value = "";
        if (props.onReset) props.onReset({ field: props.id, value: null });
    };

    useAfterTriggerChanged(() => {
        setInternalFile(props.value ? props.value : null);
        setFileSrc("");
    }, [props.value]);

    const onFileSelect = (file: File): void => {
        setInternalFile(file);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            if (typeof reader.result !== "string") {
                console.error("Unsupported file reader!");
                alert(
                    "Unsupported file reader! Try updating your browser or use a modern one (Firefox, Chrome, Opera...)"
                );
            } else {
                setFileSrc(reader.result);
            }
        });
        reader.readAsDataURL(file);
    };

    const onImageSelect = (imageId: string): void => {
        setSelectedImages((current) => {
            const state = { ...current };
            if (imageId in state) delete state[imageId];
            else state[imageId] = imageId;
            return state;
        });
    };

    return (
        <ImageInputView
            {...props}
            fileSrc={fileSrc}
            inputRef={inputRef}
            clearFile={clearFile}
            setFileSrc={setFileSrc}
            internalFile={internalFile}
            showSelector={showSelector}
            onFileSelect={onFileSelect}
            onImageSelect={onImageSelect}
            selectedImages={selectedImages}
            deleteWarnImgId={deleteWarnImgId}
            setShowSelector={setShowSelector}
            setInternalFile={setInternalFile}
            setDeleteWarnImgId={setDeleteWarnImgId}
        />
    );
};
