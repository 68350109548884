import { ModelConstructor, IDynamicObject } from "xa-generics";

export class OneminImgModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as ImageMData;
        ModelConstructor(data, this);

        if (this.thumb) this.file = this.thumb;
    }

    present: boolean = false;
    original: string = "";
    medium: string = "";
    thumb: string = "";

    file: File | string = "";
}

//If you add public methods, you may want to replace the empty string with them!
type ImageMData = Partial<InstanceType<typeof OneminImgModel>>;
