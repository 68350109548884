import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { OrderModel } from "../../../../Models/Order.model";
import { IPartialCSS } from "sitebuilder-common";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import { ProfileOrderItem } from "./ProfileOrderItem.element";
import { IProfileControllerProps } from "../Controller/Profile.controller";
import moment from "moment";

interface IPrevOrderElementProps extends IProfileControllerProps {
    order: OrderModel;
    currency: string;
}

export const PrevOrderElement: FC<IPrevOrderElementProps> = (props) => {
    const { config } = useRestaurant();
    const { t } = useTranslation<ILang>();
    const style: IPartialCSS<any> = {};
    if (config && config.main_btn_bg_color) style.color = config.main_btn_bg_color;
    if (props.styleData && props.styleData.buttons.backgroundColor) {
        style.color = props.styleData.buttons.backgroundColor;
    }

    return (
        <div className={"profile__prev-orders--order"}>
            <div className="order-info">
                <div className="date-time">
                    {moment(props.order.created_at).format("YYYY.MM.DD HH:mm")}
                </div>
                <button className={"add-to-cart"} style={style}>
                    {t<ILang>("add_to_cart")}
                </button>
            </div>

            {props.order.items.map((item, index) => (
                <ProfileOrderItem
                    item={item}
                    currency={props.currency}
                    key={`${props.order.id}-prevorder-${index}-item`}
                />
            ))}
        </div>
    );
};
