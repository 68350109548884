import { axios, IDynamicObject } from "xa-generics";
import { IFrameModel } from "../Models/IFrame.model";
import { RootDAO } from "../../../DAO/Restaurant.dao";

export abstract class IFrameDAO extends RootDAO {
    private static readonly path: string = "iframe";

    public static async initAndLoad(): Promise<IFrameModel> {
        const request = await axios
            .getInstance()
            .get<IDynamicObject<any>>(`${this.baseUrl()}/${this.path}`);
        return new IFrameModel(request.data);
    }

    public static async update(data: IDynamicObject<any>): Promise<void> {
        await axios.getInstance().put<void>(`${this.baseUrl()}/${this.path}`, data);
        return;
    }
}
