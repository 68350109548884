import { ILang } from "../../../../Interfaces/ILang.type";
import { Regexes } from "xa-generics";
import { IFieldBlur } from "../../../Editor/Utils/EditorHook.util";
import { IFormErrors } from "../../../UseForm/IUseForm.interface";
import { FormErrorView } from "../../../UseForm/FormError.view";
import { useTranslation } from "react-i18next";
import { MutableRefObject } from "react";

export type ITextInput<Fields extends object> = {
    value: string;
    children?: any;
    id: keyof Fields;
    hidden?: boolean;
    message?: string;
    noLabel?: boolean;
    labelText?: ILang;
    required?: boolean;
    disabled?: boolean;
    className?: string;
    isNumeric?: boolean;
    placeholder?: string;
    description?: string | JSX.Element;
    customError?: string;
    autoComplete?: boolean;
    onUnitClick?: () => void;
    unit?: string | JSX.Element;
    errors?: IFormErrors<Fields>;
    onBlur?: (data: IFieldBlur<Fields>) => void;
    focusRef?: MutableRefObject<HTMLInputElement | null>;
    onChange?: (id: keyof Fields, value: string) => void;
    labelColor?: string;
    style?: React.CSSProperties;
    type?: "text" | "email" | "password" | "tel" | "number" | "url";
};

export const TextInput = <Fields extends object>(props: ITextInput<Fields>) => {
    let wrapperStyle: string[] = ["wrapper"];
    const { t } = useTranslation();

    if (props.className) wrapperStyle.push(props.className);
    if (props.hidden) wrapperStyle.push("wrapper-hidden");
    if (props.disabled) wrapperStyle.push("wrapper-disabled");

    const id = props.id as string;

    const getUnit = (): null | JSX.Element => {
        if (!props.unit) return null;

        const evt = props.onUnitClick ? { onClick: props.onUnitClick } : {};
        const classes = ["text-input-unit"];
        if (props.onUnitClick) {
            classes.push("clickable-unit");
        }

        if (typeof props.unit === "string") {
            classes.push("text-input-label-unit");
            return (
                <span {...evt} className={classes.join(" ")}>
                    {props.unit}
                </span>
            );
        }

        return (
            <div {...evt} className={classes.join(" ")}>
                {props.unit}
            </div>
        );
    };

    return (
        <div className={wrapperStyle.join(" ")}>
            {!props.noLabel && (
                <label
                    style={{ color: props.labelColor }}
                    htmlFor={id as string}
                    className="input-label"
                >
                    {t(props.labelText || id)}
                    {props.required ? "*" : ""}
                </label>
            )}
            {props.children}
            <input
                id={id}
                name={id}
                value={props.value}
                style={props.style}
                className={"text-input"}
                disabled={props.disabled}
                type={props.type || "text"}
                placeholder={props.placeholder}
                ref={(ref) => {
                    if (props.focusRef && ref) {
                        props.focusRef.current = ref;
                    }
                }}
                onChange={(e) => {
                    const value = e.target.value;
                    if (props.isNumeric && value !== "" && !Regexes.Numeric.test(value)) return;
                    if (props.onChange) props.onChange(props.id, value);
                }}
                onBlur={(e) => {
                    const value = e.target.value;
                    if (props.isNumeric && value !== "" && !Regexes.Numeric.test(value)) return;
                    if (props.onBlur) props.onBlur({ field: props.id, value });
                }}
                autoComplete={props.autoComplete ? "on" : "off"}
            />

            {getUnit()}

            {props.errors && <FormErrorView id={props.id} errors={props.errors} />}
            {props.customError && <div className={"global__error"}>{props.customError}</div>}
            {props.description ? (
                <div className="input-description">{props.description}</div>
            ) : null}
        </div>
    );
};
