import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { Checkmark } from "@carbon/icons-react";
import { CartDMCAView } from "./CartDMCA.view";
import { CartItemsView } from "./CartItems.view";
import { useTranslation } from "react-i18next";
import { CartUserDataView } from "./CartUserData.view";
import { CartPaymentMethods } from "./CartPaymentMethods.view";
import { CartDeliveryAtView } from "./CartDeliveryAt.view";
import { CartPaymentSplit } from "./CartPaymentSplit.view";
import { CartMessageView } from "./CartMessage.view";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { ClientButton } from "../../../DomMapper/DomComponents/Button/ClientButton.view";
import { useDom } from "../../../DomTracker/Controller/DomTracker.provider";

interface ICartViewProps {}

export const CartView: FC<ICartViewProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { currentFont } = useRestaurant();
    const { size } = useDom();

    const classes: string[] = ["cart", `cart-${size}`, currentFont];

    return (
        <div className={classes.join(" ")}>
            <div className="cart__container">
                <div className="cart__container--left">
                    <CartUserDataView />
                </div>

                <div className="cart__container--right">
                    <CartItemsView />
                    <CartDeliveryAtView />
                    <CartPaymentMethods />
                    <CartPaymentSplit />
                    <CartMessageView />
                    <CartDMCAView />

                    <div className="cart__finalize-button-container">
                        <ClientButton RightIcon={<Checkmark size={20} />}>
                            {t<ILang>("finalize_order")}
                        </ClientButton>
                    </div>
                </div>
            </div>
        </div>
    );
};
