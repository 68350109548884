import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useAccess } from "../../../../Contexts/Access.context";
import { UserModel } from "../../../../Models/User.model";
import { AddressModel } from "../../../../Models/Address.model";
import { useTranslation } from "react-i18next";
import { IUseFormResult } from "../../../UseForm/UseForm.provider";
import { ProfileTabulatorView } from "./ProfileTabulator.view";
import { IProfileControllerProps } from "../Controller/Profile.controller";

export interface IProfileIndexProps extends IProfileControllerProps {
    onSaveAddress: (e: React.FormEvent<any>) => void;
    addressForm: IUseFormResult<AddressModel, false>;
    userForm: IUseFormResult<UserModel, true>;
    onDeleteAddress: (id: string) => void;
    addresses: AddressModel[];
    isUserAltered: boolean;
    onSaveUser: () => void;
}

export const ProfileIndex: FC<IProfileIndexProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { builderType } = useAccess();
    const classes: string[] = ["profile"];
    if (builderType === "IFRAME") classes.push("profile-md iframe-profile");
    if (builderType === "APP") classes.push("profile-xs app-profile");
    if (props.size) classes.push(`profile-${props.size || "md"}`);

    return (
        <div className={classes.join(" ")}>
            {!props.styleData && <h2 className="profile__header">{t<ILang>("profile")}</h2>}
            <ProfileTabulatorView {...props} />
        </div>
    );
};
