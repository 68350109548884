import { IDynamicObject, IError, ILoading, useDidMount } from "xa-generics";
import { Context, createContext, useState, useContext, useMemo } from "react";
import { ProductCategoryModel } from "../Models/ProductCategory.model";
import { IProductsContext } from "../Interfaces/IProductsContext.interface";
import { useTranslation } from "react-i18next";
import { FloatingError } from "xa-error-with-lang";
import { ProductModel } from "../Models/Product.model";
import { ProductsDAO } from "../DAO/Products.dao";
import { useAccess } from "../../../../../Contexts/Access.context";
import { ILang } from "../../../../../Interfaces/ILang.type";

/**
 * ## ProductsContext
 */
const ProductsContext: Context<IProductsContext> = createContext<IProductsContext>(null as any);

ProductsContext.displayName = "ProductsContext";

interface IProductsContextProviderProps {}

/**
 * ## Products context provider component
 *
 */
export const ProductsContextProvider: React.FC<IProductsContextProviderProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { builderType } = useAccess();

    //States accessible by UI type param
    const [categories, setCategories] = useState<ProductCategoryModel[]>([]);
    const [catObject, setCatObject] = useState<IDynamicObject<ProductCategoryModel>>({});
    const [products, setProducts] = useState<IDynamicObject<ProductModel[]>>({});
    const [loading, setLoading] = useState<ILoading>(false);
    const [error, setError] = useState<IError>(null);

    //API requests
    const init = (): void => {
        let type = "web";
        if (builderType === "APP") type = "app";
        ProductsDAO.setQuery(type);

        setLoading({
            reason: t<ILang>("loading_categories"),
            promise: ProductsDAO.loadFullTree()
                .then((data) => {
                    setCategories(data[0]);
                    setCatObject(data[1]);

                    const prodState: IDynamicObject<ProductModel[]> = {};
                    for (const category of data[0]) {
                        prodState[category.id] = category.products;
                    }
                    setProducts(prodState);
                })
                .catch((error) => setError(error))
                .finally(() => setLoading(false))
        });
    };

    useDidMount(init);

    return (
        <ProductsContext.Provider
            value={{
                categories,
                setLoading,
                catObject,
                products,
                loading
            }}
        >
            <FloatingError error={error} resetError={() => setError(null)} />
            {props.children}
        </ProductsContext.Provider>
    );
};

export const useProducts = (displayCategoryIds?: string[]): IProductsContext => {
    const ctx = useContext(ProductsContext);

    const { categories, catObject } = useMemo(() => {
        if (displayCategoryIds) {
            let categories: ProductCategoryModel[] = [];
            let catObject: IDynamicObject<ProductCategoryModel> = {};
            for (const category of ctx.categories) {
                const index = displayCategoryIds.indexOf(category.id);
                if (index !== -1) {
                    categories.push(category);
                    catObject[category.id] = category;
                }
            }
            return { categories, catObject };
        } else {
            return { categories: ctx.categories, catObject: ctx.catObject };
        }
    }, [displayCategoryIds, ctx.catObject, ctx.categories]);

    return { ...ctx, categories, catObject };
};
