import { FC } from "react";
import { axios } from "xa-generics";
import { ILang } from "../../../Interfaces/ILang.type";
import { useIFrame } from "../Provider/IFrame.context";
import { SelectInput } from "../../UI/InputFields/Select/SelectInput.view";
import { FrameRestMap } from "./FrameRestMap.view";
import { useRestaurant } from "../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import { SaveLangCookie } from "../../../Utils/SaveLangCookie.util";
import { FormatPhoneNumber } from "sitebuilder-common";
import {
    CircleStroke,
    ShoppingBag,
    Location,
    Money,
    Phone,
    Wikis,
    Time
} from "@carbon/icons-react";

interface IFrameInfoViewProps {}

export const FrameInfoView: FC<IFrameInfoViewProps> = (props) => {
    const { t, i18n } = useTranslation<ILang>();
    const { restaurant } = useRestaurant();
    const { data, dataSource } = useIFrame();

    const content = data[dataSource].map;
    const langOptions = [
        { id: restaurant.primary_language, name: restaurant.primary_language.toUpperCase() }
    ];
    if (restaurant.secondary_language) {
        langOptions.push({
            id: restaurant.secondary_language,
            name: restaurant.secondary_language.toUpperCase()
        });
    }

    const getOrderLI = (
        key: "normal_delivery" | "preorder_delivery" | "normal_pickup" | "preorder_pickup"
    ): JSX.Element => {
        const cls = ["list__item"];
        if (!restaurant[key]) cls.push("crossed-out");

        return (
            <li className={cls.join(" ")}>
                <CircleStroke className={"icon"} /> {t<ILang>(key)}
            </li>
        );
    };

    return (
        <div className={"frame-info"}>
            {content.lat && content.lng && <FrameRestMap />}

            <div className="frame-info__grid">
                <div className="frame-info__grid--item">
                    <h6 className="frame-info__grid--item--title">
                        <Time className={"icon"} /> {t<ILang>("open_hours")}
                    </h6>
                    <div className="frame-info__grid--item--info">
                        <ul className="list no-space">
                            <div className="list__item">
                                <CircleStroke className={"icon"} /> {t<ILang>("monday")} 08:00 -
                                23:00
                            </div>
                            <div className="list__item">
                                <CircleStroke className={"icon"} /> {t<ILang>("tuesday")} 10:00 -
                                20:00
                            </div>
                            <div className="list__item">
                                <CircleStroke className={"icon"} /> {t<ILang>("wednesday")} 08:00 -
                                23:00
                            </div>
                            <div className="list__item">
                                <CircleStroke className={"icon"} /> {t<ILang>("thursday")} 11:00 -
                                00:00
                            </div>
                            <div className="list__item">
                                <CircleStroke className={"icon"} /> {t<ILang>("friday")}{" "}
                                {t<ILang>("closed_hour")}
                            </div>
                            <div className="list__item">
                                <CircleStroke className={"icon"} /> {t<ILang>("saturday")} 12:00 -
                                00:00
                            </div>
                            <div className="list__item">
                                <CircleStroke className={"icon"} /> {t<ILang>("sunday")} 12:00 -
                                00:00
                            </div>
                        </ul>
                    </div>
                </div>
                <div className="frame-info__grid--item">
                    <h6 className="frame-info__grid--item--title">
                        <ShoppingBag className={"icon"} /> {t<ILang>("special_order")}
                    </h6>
                    <div className="frame-info__grid--item--info">
                        <ul className="list">
                            {getOrderLI("normal_delivery")}
                            {getOrderLI("preorder_delivery")}
                            {getOrderLI("normal_pickup")}
                            {getOrderLI("preorder_pickup")}
                        </ul>
                    </div>
                </div>
                <div className="frame-info__grid--item">
                    <h6 className="frame-info__grid--item--title">
                        <Wikis className={"icon"} /> {t<ILang>("languages")}
                    </h6>
                    <div className="frame-info__grid--item--info">
                        <SelectInput
                            noLabel
                            id={"language"}
                            value={i18n.language}
                            options={langOptions}
                            onChange={(id, lang) => {
                                axios.setLanguage(lang);
                                i18n.changeLanguage(lang);
                                SaveLangCookie(lang);
                            }}
                        />
                    </div>
                </div>
                <div className="frame-info__grid--item">
                    <h6 className="frame-info__grid--item--title">
                        <Money className={"icon"} /> {t<ILang>("payment_methods")}
                    </h6>
                    <div className="frame-info__grid--item--info">
                        <ul className="list">
                            <li className="list__item">
                                <CircleStroke className={"icon"} /> {t<ILang>("simplepay")}
                            </li>
                            <li className="list__item">
                                <CircleStroke className={"icon"} /> {t<ILang>("cash")}
                            </li>
                            <li className="list__item">
                                <CircleStroke className={"icon"} /> {t<ILang>("otp_nice_card")}
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="frame-info__grid--item">
                    <h6 className="frame-info__grid--item--title">
                        <Location className={"icon"} /> {t<ILang>("address")}
                    </h6>
                    <div className="frame-info__grid--item--info">
                        {restaurant.city} {restaurant.street} {restaurant.premise_number}
                    </div>
                </div>
                <div className="frame-info__grid--item">
                    <h6 className="frame-info__grid--item--title">
                        <Phone className={"icon"} /> {t<ILang>("phone_number")}
                    </h6>
                    <div className="frame-info__grid--item--info">
                        {FormatPhoneNumber(restaurant.phone_1)}
                    </div>
                </div>
            </div>
        </div>
    );
};
