import { FC } from "react";
import { OrderItemModel } from "../../../../Models/OrderItem.model";

interface IProfileOrderItemProps {
    item: OrderItemModel;
    currency: string;
}

export const ProfileOrderItem: FC<IProfileOrderItemProps> = (props) => {
    return (
        <div className={"profile__prev-orders--item"}>
            <span className="quantity">{props.item.quantity}x</span>
            <span className="name">{props.item.name}</span>
            <span className="price">
                {props.item.total_price} {props.currency}
            </span>
        </div>
    );
};
