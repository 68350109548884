import { ILang } from "../../../../Interfaces/ILang.type";
import { DnDEvents } from "../../Util/DND.util";
import { IDomElement } from "../../../DomTracker/Interfaces/IDomTypes.interface";
import { TextTypeStyles } from "../../Static/TextTypeStyles.static";
import { useTranslation } from "react-i18next";
import { ITextTypeContent } from "../../Interfaces/ITextTypeComponents.interface";
import { ParseComponentStyle } from "sitebuilder-common";
import HTMLParser from "html-react-parser";
import Overlay from "../../View/Overlay.view";

export interface IDomTextProps extends IDomElement<ITextTypeContent> {}

const DomText: React.FC<IDomTextProps> = (props) => {
    const { t, i18n } = useTranslation<ILang>();
    const currentLang = i18n.language;

    const { refs, size, id, showOverlay, ...events } = DnDEvents<ITextTypeContent>(
        props,
        "ELEMENT"
    );

    let style = ParseComponentStyle(props.content, TextTypeStyles, size);
    const text = props.content?.[currentLang]?.text || t<ILang>("Text");

    const htmlText = text.replace(/\n/g, "<br />");

    return (
        <div id={id} {...events} className="dom__text" ref={refs.current[props.uuid]} style={style}>
            {showOverlay && <Overlay parent={props} />}
            {HTMLParser(htmlText)}
        </div>
    );
};

export default DomText;
