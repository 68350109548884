interface ISVGProps {
    className?: string;
    size?: number;
}

export const AppBackArrow = ({ size, ...props }: ISVGProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={`${size || 16}px`}
            height={`${size || 16}px`}
            viewBox="0 0 8.871 14.914"
            {...props}
        >
            <g transform="translate(-556.211 -36.543)">
                <path
                    fill="none"
                    stroke="#fff"
                    strokeWidth="2"
                    d="M564.375 37.25l-6.75 6.75 6.75 6.75"
                ></path>
            </g>
        </svg>
    );
};
