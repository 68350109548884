import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { ToMoneyInt } from "xa-generics";
import { ProductModel } from "../../../DomMapper/DomComponents/Products/Models/Product.model";
import { ClientButton } from "../../../DomMapper/DomComponents/Button/ClientButton.view";
import { ShoppingCart } from "@carbon/icons-react";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import { IProfileControllerProps } from "../Controller/Profile.controller";
// import PlaceholderImage from "../../../../Assets/image_missing.png";

interface IOrderAgainElementProps extends IProfileControllerProps {
    currency: string;
    product: ProductModel;
}

export const OrderAgainElement: FC<IOrderAgainElementProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { config } = useRestaurant();

    const color = config?.main_btn_bg_color || props.styleData?.buttons.backgroundColor;

    return (
        <div className="order-again">
            <div className="order-again__name">{props.product.name}</div>
            <div className="order-again__img-container">
                <img
                    src={props.product.image.medium}
                    alt={props.product.name}
                    className="order-again__img-container--image"
                />
            </div>
            <div className="order-again__desc">{props.product.description}</div>
            <div className="order-again__price" style={{ color }}>
                {ToMoneyInt(props.product.base_price)} {props.currency}
            </div>
            <div className="order-again__add">
                {props.v2Btn ? (
                    <props.v2Btn Icon={<ShoppingCart />}>{t<ILang>("add_to_cart")}</props.v2Btn>
                ) : (
                    <ClientButton LeftIcon={ShoppingCart}>{t<ILang>("add_to_cart")}</ClientButton>
                )}
            </div>
        </div>
    );
};
