import { FC } from "react";
import { ListBulleted } from "@carbon/icons-react";

export const TopbarCategoryElement: FC<{ name: string; className: string; onClick: () => void }> = (
    props
) => {
    return (
        <div style={{ position: "relative" }} onClick={props.onClick} className={props.className}>
            <ListBulleted style={{ marginRight: "6px" }} /> {props.name}
        </div>
    );
};
