import { FC } from "react";
import { ILang } from "../../../Interfaces/ILang.type";
import { useAccess } from "../../../Contexts/Access.context";
import { useTemplate } from "../Provider/Template.provider";
import { InlineLoading } from "../../UI/InlineLoading/InlineLoading.view";
import { useTranslation } from "react-i18next";
import { IUseFormResult } from "../../UseForm/UseForm.provider";
import { TemplateSectionsView } from "./TemplateSections.view";
import { TemplateRestEditorView } from "./TemplateRestEditor.view";
import { TemplateRestaurantModel } from "../Model/TemplateRestaurant.model";
import { TemplatesRestaurantsView } from "./TemplatesRestaurants.view";

export interface ITemplatesViewProps {
    applyRestaurantTemplate: (template: TemplateRestaurantModel) => void;
    form: IUseFormResult<TemplateRestaurantModel, false>;
}

export const TemplatesView: FC<ITemplatesViewProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { loading } = useTemplate();
    const { accessModel } = useAccess();

    return (
        <div className="templates">
            <div className="templates__header">
                {loading ? <InlineLoading /> : t<ILang>("templates")}
            </div>
            <div className={"templates-view"}>
                {props.form.editor && <TemplateRestEditorView {...props} />}

                <TemplatesRestaurantsView {...props} />

                {accessModel.super_admin && (
                    <div className="templates-view__blocks">
                        <TemplateSectionsView isSuperUserView={true} />
                    </div>
                )}
            </div>
        </div>
    );
};
