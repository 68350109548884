import { LetterAa } from "@carbon/icons-react";
import { IFieldBlur } from "../../../Editor/Utils/EditorHook.util";
import { useState } from "react";
import InputGrouper from "../../InputGrouper/InputGrouper.view";

type IHeader = { headerType?: any };

export interface IAlignPickerViewProps<Fields extends IHeader> {
    id: keyof Fields;
    defaultValues: Fields;
    onBlur: (data: IFieldBlur<Fields>) => void;
}

type IValues = 1 | 2 | 3 | 4 | 5 | 6;

const HTypePickerView = <Fields extends IHeader>(props: IAlignPickerViewProps<Fields>) => {
    const [active, setActive] = useState<IValues>(props.defaultValues.headerType || 1);

    const getCol = (boxValue: IValues): JSX.Element => {
        const classes: string[] = ["column-picker__value"];
        if (boxValue === active) classes.push("column-picker__active");
        return (
            <div
                onClick={() => {
                    setActive(boxValue);
                    props.onBlur({ value: boxValue, field: props.id });
                }}
                className={classes.join(" ")}
            >
                H{boxValue}
            </div>
        );
    };
    return (
        <InputGrouper groupKey={"HeaderType"} Icon={LetterAa} labelKey={"Header"} defaultOpen>
            <div className="column-picker --values-6">
                <div className="column-picker__values">
                    {getCol(1)}
                    {getCol(2)}
                    {getCol(3)}
                    {getCol(4)}
                    {getCol(5)}
                    {getCol(6)}
                </div>
            </div>
        </InputGrouper>
    );
};

export default HTypePickerView;
