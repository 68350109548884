import { FC } from "react";
import { useApp } from "../../Provider/App.context";
import { AppEditorGroup } from "../AppEditorGroup.view";
import ColorInput from "../../../UI/InputFields/ColorInput/ColorInput.view";

interface IAppButtonsFormProps {}

export const AppButtonsForm: FC<IAppButtonsFormProps> = (props) => {
    const { forms } = useApp<"buttons">();
    const { editor, fieldErrors, handleChange } = forms.buttons;

    return (
        <AppEditorGroup groupKey={"buttons"} nameKey={"iframe_buttons"}>
            <ColorInput
                onChange={handleChange}
                id={"backgroundColor"}
                value={editor.backgroundColor}
                errors={fieldErrors}
                withValueSync
                isClearable
            />
            <ColorInput
                onChange={handleChange}
                id={"color"}
                value={editor.color}
                errors={fieldErrors}
                withValueSync
                isClearable
            />
            <ColorInput
                labelText={"secondary_btn_bg_color"}
                value={editor.secondaryColor}
                onChange={handleChange}
                id={"secondaryColor"}
                errors={fieldErrors}
                withValueSync
                isClearable
            />
        </AppEditorGroup>
    );
};
