import { FC } from "react";
import { useApp } from "../../Provider/App.context";
import { RenderPrice } from "../../../DomMapper/DomComponents/Products/Utils/GetCurrency.util";
import { ProductModel } from "../../../DomMapper/DomComponents/Products/Models/Product.model";
import { DEF_BG, DEF_COLOR } from "../../../../Static/IFrameBaseColors.static";
import { IAppOrderIndexProps } from "./AppOrder.index";

interface IAppProductElementProps extends IAppOrderIndexProps {
    product: ProductModel;
    currency: string;
}

export const AppProductElement: FC<IAppProductElementProps> = ({ product, ...props }) => {
    const { data, dataSource } = useApp();
    const app = data[dataSource];
    const p = app.product;

    const style: React.CSSProperties = {
        backgroundColor: p.backgroundColor,
        color: p.color
    };
    const priceStyle: React.CSSProperties = {
        backgroundColor: p.priceBackground || `${app.buttons.backgroundColor || DEF_BG}`,
        color: p.priceColor || `${app.buttons.color || DEF_COLOR}`
    };

    const nameStyle: React.CSSProperties = {};
    if (p.nameFontSize) nameStyle.fontSize = `${p.nameFontSize}rem`;
    if (p.nameLineHeight) nameStyle.lineHeight = `${p.nameLineHeight}rem`;
    if (p.disablePricePadding) {
        priceStyle.paddingTop = "0";
        priceStyle.paddingLeft = "0";
        priceStyle.paddingRight = "0";
        priceStyle.paddingBottom = "0";
    }

    const descStyle: React.CSSProperties = {};
    if (p.descFontSize) descStyle.fontSize = `${p.descFontSize}rem`;

    let hasShadow = true;
    if (style.backgroundColor) hasShadow = false;
    const maxDescLength = parseInt(app.product.descriptionBreakCharacterCount || "100");

    return (
        <div
            style={style}
            className={"app-product"}
            onClick={() => props.appProudctNavigate(product)}
        >
            <div
                className="app-product__image"
                style={{
                    backgroundImage: `url(${product.image.medium})`,
                    backgroundSize: p.imageRenderContain ? "contain" : undefined
                }}
            />
            <div className="app-product__content">
                <div className="app-product__content--name" style={nameStyle}>
                    {product.name}

                    {!p.hideLineUnderName && (
                        <div
                            className="app-product__content--name--underline"
                            style={{
                                backgroundColor: `${app.buttons.backgroundColor || DEF_BG}`
                            }}
                        />
                    )}
                </div>
                <div className="app-product__content--description" style={descStyle}>
                    {product.description.length > maxDescLength
                        ? `${product.description.slice(0, maxDescLength)}...`
                        : product.description}
                </div>
                <div
                    className="app-product__content--price"
                    style={hasShadow ? undefined : { boxShadow: "none" }}
                >
                    <span className="price-label" style={priceStyle}>
                        {RenderPrice(props.currency, product.base_price)}
                    </span>
                </div>
            </div>
        </div>
    );
};
