import moment from "moment";
import { IOrderStatus } from "sitebuilder-common";
import { OrderItemModel, OrderOptionModel } from "./OrderItem.model";
import { ModelConstructor, IDynamicObject, setString } from "xa-generics";

export class OrderModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as OrderMData;
        ModelConstructor(data, this);

        this.initSubModelsAndCustoms(data);
        if (data.items) this.items = data.items.map((item) => new OrderItemModel(item));
    }

    id: string = "";
    country: string = "";
    state_province_county: string = "";
    city: string = "";
    street: string = "";
    premise_number: string = "";
    building: string = "";
    floor: string = "";
    door_number: string = "";
    doorbell: string = "";
    address_notes: string = "";
    postcode: string = "";
    customer_id: string = "";
    message: string = "";
    payment_method: string = "";
    created_at: string = "";
    updated_at: string = "";
    status: IOrderStatus = "received";
    coupon_code: string = "";
    platform: string = "";
    delivery_data = {
        delivery_region_id: ""
    };
    first_name: string = "";
    last_name: string = "";
    email: string = "";
    phone_1: string = "";
    locale: string = "hu";
    fcm_token: string = "";
    total_price: number = 0;
    simplepay_form: string = "";
    products_price: number = 0;
    delivery_price: number = 0;
    coupon_applied: boolean = false;
    valid_sale: boolean = false;
    restaurant_id: string = "";
    courier_id: string = "";
    pickup: boolean = false;
    is_preorder: boolean = false;
    expected_at: string = "";
    discount_price: number = 0;
    products_base: number = 0;
    original_price: number = 0;
    netpincer_tx_id: string = "";
    counter: string = "";
    tip: number = 0;
    estimated_delivery_time: string = "";
    remote_id: string = "";
    qstatus: string = "";
    table_name: string = "";
    archived_at: string = "";
    admin_id: string = "";
    order_parent_id: string = "";
    is_printed: boolean = false;
    sale_id: string = "";
    rule_id: string = "";
    modified_columns: string = "";
    is_cart: boolean = false;
    table_id: string = "";
    courier_turn_id: string = "";
    shadow_customer_address_id: string = "";
    show_at: string = "";
    shadow_customer = {
        id: "1",
        restaurant_id: "",
        customer_id: "3",
        email: "example_pelda@user.com",
        phone_1: "36 10 999 99 99",
        first_name: "Pelda",
        last_name: "Felhasznalo",
        comment: "This is some comment about the user...",
        order_number: 23,
        order_total_sum: 184327,
        last_order_at: moment().subtract({ days: 3 }).format("yyyy/MM/DD HH:mm"),
        note: "This is a note about the user...",
        name: "Pelda Felhasznalo",
        balance: "8 212"
    };
    items: OrderItemModel[] = [];
    courier = {
        id: "1",
        first_name: "Példa",
        last_name: "Futár",
        name: "Példa Futár"
    };

    //Custom props
    name: string = "";
    time: string = "";
    dateTime: string = "";
    date: string = "";
    phone: string = "";

    protected initSubModelsAndCustoms(data: IDynamicObject<any> | OrderMData): void {
        this.name = `${this.first_name.trim()} ${this.last_name.trim()}`.trim();

        if (this.show_at) {
            const dtmoment = moment(this.show_at, moment.ISO_8601);
            this.time = dtmoment.format("HH:mm");
            this.dateTime = dtmoment.format("yyyy/MM/DD HH:mm");
            this.date = dtmoment.format("yyyy/MM/DD");
        }
        if (data.delivery_data?.delivery_region_id) {
            this.delivery_data.delivery_region_id = setString(
                data.delivery_data.delivery_region_id
            );
        }
    }
}

//If you add public methods, you may want to replace the empty string with them!
type OrderMData = Partial<InstanceType<typeof OrderModel>>;

export const MOCK_ORDER_MODEL = new OrderModel({
    id: "1001412",
    country: "Magyarország",
    state_province_county: "Hajdú-Bihar",
    city: "Debrecen",
    street: "Simonffy utca",
    premise_number: "4-6",
    building: "",
    floor: "1. emelet",
    door_number: "123",
    doorbell: "",
    address_notes: "Halköz üzletházban / In Halköz",
    postcode: "4025",
    customer_id: "3",
    message: "Example message / példa üzenet",
    payment_method: "Simplepay",
    created_at: "",
    updated_at: "",
    status: "received",
    coupon_code: "xl7dlk3jc712pq3",
    platform: "browser",
    delivery_data: {
        delivery_region_id: "1"
    },
    first_name: "Test",
    last_name: "Elek",
    email: "example@pelda.com",
    phone_1: "36 30 111 00 00",
    locale: "hu",
    total_price: 5950,
    products_price: 5350,
    delivery_price: 600,
    coupon_applied: true,
    valid_sale: false,
    restaurant_id: "1",
    courier_id: "1",
    pickup: false,
    is_preorder: false,
    expected_at: "",
    discount_price: 600,
    products_base: 5950,
    original_price: 6550,
    counter: "1",
    is_cart: true,
    shadow_customer_address_id: "1",
    items: [
        new OrderItemModel({
            id: "1",
            order_id: "1001412",
            subproduct_id: "24",
            quantity: "2",
            message: "Example message",
            total_price: "1250",
            qstatus: "done",
            product: { name: "Bolognai spagetti" },
            subproduct: { name: "trappista sajttal" },
            item_options: [
                new OrderOptionModel({
                    id: "1",
                    order_item_id: "1",
                    option_id: "12",
                    quantity: 2,
                    total_price: 200,
                    qstatus: "done",
                    option: { name: "trappista sajt" }
                }),
                new OrderOptionModel({
                    id: "2",
                    order_item_id: "1",
                    option_id: "13",
                    quantity: 1,
                    total_price: 200,
                    qstatus: "done",
                    option: { name: "edámi sajt" }
                })
            ]
        }),
        new OrderItemModel({
            id: "2",
            order_id: "1001412",
            subproduct_id: "20",
            quantity: "1",
            message: "",
            total_price: "2000",
            qstatus: "in_progress",
            product: { name: "Sonkás pizza" },
            subproduct: { name: "32 cm" },
            item_options: [
                new OrderOptionModel({
                    id: "3",
                    order_item_id: "2",
                    option_id: "4",
                    quantity: 2,
                    unit_price: 300,
                    total_price: 600,
                    qstatus: "done",
                    option: { name: "trappista sajt" }
                }),
                new OrderOptionModel({
                    id: "4",
                    order_item_id: "2",
                    option_id: "5",
                    quantity: 1,
                    total_price: 200,
                    qstatus: "done",
                    option: { name: "sonka" }
                })
            ]
        })
    ]
});
