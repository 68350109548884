import { OrderItemModel } from "./../../../../Models/OrderItem.model";
import { OrderModel } from "../../../../Models/Order.model";
import moment from "moment";

export const MOCK_PREV_ORDERS: OrderModel[] = [
    new OrderModel({
        id: 4,
        total_price: 2250,
        created_at: moment().subtract({ day: 1 }).format("YYYY-MM-DDTHH:mm:ss"),
        items: [
            new OrderItemModel({
                id: 84,
                quantity: 1,
                total_price: 1950,
                product: { name: "Al capone" },
                subproduct: { name: "32 cm" }
            })
        ]
    }),
    new OrderModel({
        id: 3,
        total_price: 2000,
        created_at: moment().subtract({ day: 3 }).format("YYYY-MM-DDTHH:mm:ss"),
        items: [
            new OrderItemModel({
                id: 82,
                quantity: 1,
                total_price: 1700,
                product: { name: "Amigo" },
                subproduct: { name: "24 cm" }
            })
        ]
    }),
    new OrderModel({
        id: 2,
        total_price: 2000,
        created_at: moment().subtract({ day: 7 }).format("YYYY-MM-DDTHH:mm:ss"),
        items: [
            new OrderItemModel({
                id: 83,
                quantity: 1,
                total_price: 1700,
                product: { name: "Argentin grill" }
            })
        ]
    })
];
