import { ISizes } from "sitebuilder-common";

export const GetSizeValue = (size: ISizes): number => {
    const sizes = {
        xs: 320,
        sm: 768,
        md: 991,
        lg: 1200,
        xl: 0
    };
    return sizes[size];
};
