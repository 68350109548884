import { merge } from "lodash";
import { useApp } from "../Provider/App.context";
import { GetColors } from "../Utils/GetColors.util";
import { FC, isValidElement } from "react";

export interface IAppButtonProps {
    isThick?: boolean;
    isModal?: boolean;
    disabled?: boolean;
    className?: string;
    isSecondary?: boolean;
    Icon: JSX.Element | string;
    style?: React.CSSProperties;
    type?: "submit" | "button" | "reset";
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    onMouseUp?: React.MouseEventHandler<HTMLButtonElement>;
}

export const AppButton: FC<IAppButtonProps> = (props) => {
    const { data, dataSource } = useApp();

    const app = data[dataSource];
    const classes = ["app_button"];
    const theme = GetColors(app);
    const style: React.CSSProperties = {
        backgroundColor: theme.backgroundColor,
        color: theme.color
    };

    if (props.isSecondary) classes.push("--SECONDARY");
    if (app.global.isRounded && !props.isModal) classes.push("global__rounded-corners");
    if (app.global.isDarkTheme) classes.push("dark_app_button");
    if (props.isModal) classes.push("modal-app-button");
    if (props.isThick) classes.push("--increased-height");
    if (props.className) classes.push(props.className);

    return (
        <button
            style={merge(props.isSecondary ? {} : style, props.style)}
            type={props.type || "button"}
            className={classes.join(" ")}
            onMouseUp={props.onMouseUp}
            disabled={props.disabled}
            onClick={props.onClick}
        >
            <div className="app_button__text">{props.children}</div>
            <div className="app_button__icon">
                {/* @ts-ignore */}
                {isValidElement(props.Icon) ? props.Icon : <props.Icon />}
            </div>
        </button>
    );
};
