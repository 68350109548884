import { ModelConstructor, IDynamicObject } from "xa-generics";
import { AbstractProductCommonModel } from "./AbstractProductCommon.model";
import { SubproductModel } from "./Subproduct.model";
import { OneminImgModel } from "./Image.model";

export class ProductModel extends AbstractProductCommonModel {
    constructor(rawData?: IDynamicObject<any>) {
        super();
        if (!rawData) return;
        const data = rawData as ProductMData;
        ModelConstructor(data, this);

        if (data.subproducts instanceof Array) {
            this.subproducts = data.subproducts.map((item) => new SubproductModel(item));
        }

        this.image = new OneminImgModel(data.image);
        this.setSlugAndShortName();
    }

    description: string = "";
    product_category_id: string = "";
    description_sec_lang: string = "";
    depot_id: string = "";
    available_from: string = "";
    available_to: string = "";
    expected_at: string = "";
    cassapoint_id: string = "";
    base_price: number = 0;
    subproducts: SubproductModel[] = [];

    image!: OneminImgModel;
}

//If you add public methods, you may want to replace the empty string with them!
type ProductMData = Partial<InstanceType<typeof ProductModel>>;
