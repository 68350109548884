import { ILang } from "../../../../Interfaces/ILang.type";
import { useForm } from "../../../UseForm/UseForm.provider";
import { IMargins } from "./IMargins.interface";
import { TextInput } from "../../InputFields/TextInput/TextInput.view";
import { IInputGroup } from "../../../../Interfaces/IInputGroup.interface";
import { SIZING_RULE } from "../../../../Static/FormRules.static";
import { ResetValues } from "../../../Editor/Utils/ResetValues.util";
import { Destructure } from "../../../../Utils/Destructure.util";
import { IFormWithUnit } from "../../../../Interfaces/IFormWithUnit.type";
import { useTranslation } from "react-i18next";
import { MarginDefaults } from "./Defaults.static";
import { BuildGroupData } from "../../../../Utils/BuildGroupData.util";
import { CheckIfHasValue } from "../../../../Utils/CheckIfHasValue.util";
import { useAfterTriggerChanged } from "xa-generics";
import {
    OpenPanelFilledBottom,
    OpenPanelFilledRight,
    OpenPanelFilledLeft,
    OpenPanelFilledTop,
    Maximize
} from "@carbon/icons-react";
import SizeUnitControlView from "../../SizeUnitControl/SizeUnitControl.view";
import ResetFormView from "../../InputGrouper/ResetForm.view";
import InputGrouper from "../../InputGrouper/InputGrouper.view";

export interface IMarginsProps extends IInputGroup<IMargins<any>> {}

export const Margins: React.FC<IMarginsProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const form = useForm<IFormWithUnit<IMargins>, true>({
        editor: Destructure(MarginDefaults, props.defaultValues, props.units.margin),
        initialRules: {
            marginBottom: SIZING_RULE(t),
            marginLeft: SIZING_RULE(t),
            marginRight: SIZING_RULE(t),
            marginTop: SIZING_RULE(t)
        }
    });

    useAfterTriggerChanged(() => {
        form.handleSubmit((state) => {
            props.onBlur(BuildGroupData(state));
        })();
    }, [form.editor]);

    return (
        <InputGrouper
            groupKey={"Margins"}
            Icon={Maximize}
            labelKey={"margin"}
            Reset={
                <ResetFormView
                    hasValue={CheckIfHasValue(form, MarginDefaults)}
                    onReset={(e) => ResetValues(e, MarginDefaults, form.reset, props.onReset)}
                />
            }
            Controls={<SizeUnitControlView unit={form.editor.unit} setUnit={form.handleChange} />}
        >
            <div className="margin-form">
                <TextInput
                    noLabel
                    unit={<OpenPanelFilledTop />}
                    onChange={form.handleChange}
                    value={form.editor.marginTop}
                    id={"marginTop"}
                    className="margin-input"
                    errors={form.fieldErrors}
                />
                <TextInput
                    noLabel
                    unit={<OpenPanelFilledBottom />}
                    onChange={form.handleChange}
                    value={form.editor.marginBottom}
                    id={"marginBottom"}
                    className="margin-input"
                    errors={form.fieldErrors}
                />
                <TextInput
                    noLabel
                    unit={<OpenPanelFilledLeft />}
                    onChange={form.handleChange}
                    value={form.editor.marginLeft}
                    id={"marginLeft"}
                    className="margin-input"
                    errors={form.fieldErrors}
                />
                <TextInput
                    noLabel
                    unit={<OpenPanelFilledRight />}
                    onChange={form.handleChange}
                    value={form.editor.marginRight}
                    id={"marginRight"}
                    className="margin-input"
                    errors={form.fieldErrors}
                />

                <div className="global__form-group--desc global__form-group--four-row">
                    {t<ILang>("special_values_desc")}
                </div>
            </div>
        </InputGrouper>
    );
};
