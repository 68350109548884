import { PageDataMData, PageDataModel } from "../Model/PageData.model";
import { IPageCreate, IPageUpdate } from "../Interfaces/IPageCreate.interface";
import { axios, IDynamicObject } from "xa-generics";
import { ISeoGeneratorBody } from "../Interfaces/ISeoGenerate.interface";
import { RestaurantModel } from "sitebuilder-common";
import { DomModel } from "../Model/Dom.model";
import { RootDAO } from "../../../DAO/Restaurant.dao";

export abstract class DomDAO extends RootDAO {
    private static url(page_id?: string, draft_id?: string): string {
        let url = `${this.baseUrl()}/pages`;
        if (page_id) url += `/${page_id}`;
        if (draft_id) url += `/drafts/${draft_id}`;
        return url;
    }

    public static async generateMissingSeos(data: ISeoGeneratorBody): Promise<boolean> {
        const request = await axios
            .getInstance()
            .post<{ is_new_seo_generated: boolean }>(`${this.baseUrl()}/generate-seo`, data);
        return request.data.is_new_seo_generated;
    }

    public static async loadPages(restaurant: RestaurantModel): Promise<PageDataModel[]> {
        const request = await axios.getInstance().get<PageDataMData[]>(`${this.url()}`);
        return request.data.map((page) => new PageDataModel(page, restaurant));
    }

    public static async saveDom(data: DomModel): Promise<void> {
        await axios.getInstance().put(`${this.url(data.page_id, data.draft_id)}/dom`, {
            elements: data.dom
        });
        return;
    }

    public static async createPage(
        page: IPageCreate,
        restaurant: RestaurantModel
    ): Promise<PageDataModel> {
        const request = await axios.getInstance().post<PageDataModel>(`${this.url()}`, page);
        return new PageDataModel(request.data, restaurant);
    }

    public static async updatePage(page_id: string, page: IPageUpdate): Promise<void> {
        await axios.getInstance().put(`${this.url(page_id)}`, page);
        return;
    }

    public static async updatePageWithTemplate(data: PageDataModel): Promise<void> {
        await axios
            .getInstance()
            .put<IDynamicObject<any>>(`${this.url(data.page_id)}/template-commit`, data);
        return;
    }

    public static async patchPage(
        page_id: string,
        page: Partial<Omit<IPageUpdate, "seos">>
    ): Promise<void> {
        await axios.getInstance().patch(`${this.url(page_id)}`, page);
        return;
    }

    public static async clonePage(id: string, restaurant: RestaurantModel): Promise<PageDataModel> {
        const request = await axios
            .getInstance()
            .post<IDynamicObject<any>>(`${this.url(id)}/clone`);
        return new PageDataModel(request.data, restaurant);
    }

    public static async publishPage(page_id: string): Promise<void> {
        await axios.getInstance().patch(`${this.url(page_id)}/publish`);
        return;
    }

    public static async destroyPage(page_id: string): Promise<void> {
        await axios.getInstance().delete(`${this.url(page_id)}`);
        return;
    }

    public static async destroyDraft(page_id: string): Promise<void> {
        await axios.getInstance().delete<void, void>(`${this.url(page_id)}/drafts`);
        return;
    }
}
