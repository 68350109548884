import ReactDOM from "react-dom";
import { axios } from "xa-generics";
import { AXIOS_NAMES } from "./Static/AxiosInstanceNames.static";
import { BrowserRouter } from "react-router-dom";
import { IDynamicObject } from "xa-generics";
import { SitebuilderRouter } from "./Router";
import { ApplicationAccess } from "./Contexts/Access.context";
import { EnvironmentContext } from "./Contexts/Environment.context";
import { ImagesContextProvider } from "./Contexts/Images.context";
import { AxiosResponse, AxiosError } from "axios";
import { RestaurantContext, RestaurantContextProvider } from "./Contexts/Restaurant.context";
import DefaultAxios from "axios";
import Environment from "./Models/Environment.model";
import Cookies from "js-cookie";
import Config from "./Config/Packages.config";
import "./Index.css";
import { TextsContextProvider } from "./Contexts/Texts.context";

const parsePath = (): string | undefined => {
    const path = window.location.pathname;
    if (path.includes("/en")) return "en";
    if (path.includes("/de")) return "de";
    return undefined;
};

const defaultLang: string | undefined = parsePath() || Cookies.get("lang");
Config(defaultLang);

const Main: React.FC<Environment> = (props) => {
    return (
        <EnvironmentContext.Provider value={props}>
            <ApplicationAccess>
                <RestaurantContextProvider>
                    <TextsContextProvider>
                        <RestaurantContext.Consumer>
                            {(state) => (
                                <ImagesContextProvider key={`${state.restaurant.id}-restaurant-id`}>
                                    <SitebuilderRouter />
                                </ImagesContextProvider>
                            )}
                        </RestaurantContext.Consumer>
                    </TextsContextProvider>
                </RestaurantContextProvider>
            </ApplicationAccess>
        </EnvironmentContext.Provider>
    );
};

DefaultAxios.get("/api/rest")
    .then((response: AxiosResponse<IDynamicObject>) => new Environment(response.data))
    .then((ENV_KEYS: Environment) => {
        const lang = (defaultLang?.substring(0, 2) as "hu") || "hu";
        const axiosHeaders = { header: "authorization", cookie_key: "token" };
        axios.initAxios(ENV_KEYS.REST_API_URL, AXIOS_NAMES.BASIC, lang, axiosHeaders); //You won't have to pass the axios name to getInstance() for this one.
        axios.initAxios(ENV_KEYS.AUTH_BACKEND_URL, AXIOS_NAMES.ONEMIN, "hu", axiosHeaders);

        const AppRoot = (
            <BrowserRouter>
                <Main {...ENV_KEYS} />
            </BrowserRouter>
        );

        ReactDOM.render(AppRoot, document.getElementById("root"));
    })
    .catch((error: AxiosError) => {
        ReactDOM.render(
            <div>
                <h2>We're sorry but something has gone wrong.</h2>
                <p>Maybe copy the error message below to help us solve the issue:</p>
                <p>{error.message}</p>
                {console.error(error)}
            </div>,
            document.getElementById("root")
        );
    });
// serviceWorkerRegistration.register();
// ReportWebVitals(console.log);
