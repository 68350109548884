import { DotsLoading } from "../../../Loading/DotsLoading.view";
import { useTranslation } from "react-i18next";

export interface IIsTypingProps {
    isTyping: boolean;
    isTypingTextKey: any;
}

const IsTyping: React.FC<IIsTypingProps> = (props) => {
    const { t } = useTranslation();
    if (!props.isTyping) return null;

    return (
        <div className="long-select__options--is-typing">
            {props.isTypingTextKey ? `${t(props.isTypingTextKey)} ` : null}
            <DotsLoading />
        </div>
    );
};

export default IsTyping;
