import { FC } from "react";
import { useApp } from "../Provider/App.context";
import { AppNavbar } from "./AppNavbar.view";
import { IMenuProps } from "../Interfaces/IMenuProps.interface";
import { useLocation } from "react-router";
import { useMountWithTriggers } from "xa-generics";
import { FONT_OPTIONS, IPartialCSS } from "sitebuilder-common";

interface IAppContainerViewProps extends IMenuProps {}

export const AppContainerView: FC<IAppContainerViewProps> = (props) => {
    const { data, dataSource } = useApp();
    const { pathname } = useLocation();
    const content = data[dataSource].global;

    const style: IPartialCSS<any> = {};

    if (content.fontFamily) {
        style.fontFamily = FONT_OPTIONS.find((o) => o.id === content.fontFamily)?.font;
    }
    if (
        content.backgroundColor &&
        (pathname === "/order" || pathname === "/info" || pathname === "/content")
    ) {
        style.backgroundColor = content.backgroundColor;
    }

    useMountWithTriggers(() => {
        const el = document.getElementById("APP_CONTAINER");
        if (el) el.scrollTo(0, 0);
    }, [pathname]);

    const classes = ["container"];
    if (data[dataSource].global.isRounded) classes.push("rounded-corners");
    if (data[dataSource].global.isDarkTheme) classes.push("dark-container");

    return (
        <div className={classes.join(" ")} style={style} id={"APP_MAIN_CONTAINER"}>
            <AppNavbar {...props} />
            <div className="app-sub-container" id={"APP_CONTAINER"}>
                {props.children}
            </div>
        </div>
    );
};
