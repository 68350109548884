import { IProductsContent } from "../../../Interfaces/IProductComponent.interface";
import { IPartialCSS } from "sitebuilder-common";
import { useDom } from "../../../../DomTracker/Controller/DomTracker.provider";

export interface ITileViewProps<T extends object> {
    children?: any;
    item: T;
    imageKey?: keyof T;
    imageUrl?: string;
    nameKey: keyof T;
    onClick: () => void;
    style?: IPartialCSS<any>;
    content: Partial<Record<string | number, any>>;
    hideImageBackground?: boolean;
}

export const TileView = <T extends object>(props: ITileViewProps<T>) => {
    const content = props.content as IProductsContent;
    const { size } = useDom();
    if (props.imageKey === undefined && props.imageUrl === undefined) {
        throw new Error("DEV ERROR: Either pass an imageUrl or the imageKey property!");
    }
    const imgStyle: React.CSSProperties = {
        backgroundImage: `url(${props.imageUrl || props.item[props.imageKey!]})`
    };
    let containerStyle = { ...props.style };
    if (props.hideImageBackground) {
        containerStyle.backgroundColor = "transparent";
        containerStyle.boxShadow = "unset";
        imgStyle.backgroundColor = "transparent";
    }
    if (content.retainBlockRatio && size === "xs") {
        containerStyle.minHeight = "30rem";
        imgStyle.height = "25rem";
    }
    return (
        <div className={"products__tile"} onClick={props.onClick} style={containerStyle}>
            <div className="products__tile--image" style={imgStyle}></div>
            <div className="products__tile--name">{props.item[props.nameKey]}</div>
            {props.children}
        </div>
    );
};
