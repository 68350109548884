import { FC } from "react";
import { ILang } from "../../../Interfaces/ILang.type";
import { useApp } from "../Provider/App.context";
import { useForm } from "../../UseForm/UseForm.provider";
import { CopyFile } from "@carbon/icons-react";
import { SelectInput } from "../../UI/InputFields/Select/SelectInput.view";
import { useRestaurant } from "../../../Contexts/Restaurant.context";
import { useTranslation } from "react-i18next";
import Button from "../../UI/Button/Button.view";

interface ICloneAppViewProps {}

export const CloneAppView: FC<ICloneAppViewProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { targets } = useRestaurant();
    const { cloneAppFrom } = useApp();

    const form = useForm<{ restaurant_id: string }, true>({
        editor: {
            restaurant_id: ""
        },
        initialRules: {
            restaurant_id: true
        }
    });

    const onSubmit = (): void => {
        cloneAppFrom(form.editor.restaurant_id);
        form.handleChange("restaurant_id", "");
    };

    return (
        <div className={"app-editor-group active-group"}>
            <SelectInput
                isClearable
                options={targets}
                id={"restaurant_id"}
                errors={form.fieldErrors}
                onChange={form.handleChange}
                labelText={"copy_restaurant"}
                value={form.editor.restaurant_id}
            />
            <Button
                useContainer
                size={"SMALL"}
                type={"submit"}
                Icon={CopyFile}
                align={"right"}
                kind={"SIMPLE"}
                onClick={form.handleSubmit(onSubmit)}
            >
                {t<ILang>("publish")}
            </Button>
        </div>
    );
};
