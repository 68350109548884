import { ImageModel } from "sitebuilder-common";
import { IDynamicObject } from "xa-generics";

export const BuildImageLinks = (
    restaurantImages: IDynamicObject<ImageModel>,
    templateImages: IDynamicObject<ImageModel>
): IDynamicObject<ImageModel> => {
    const imageLinker: IDynamicObject<ImageModel> = {};

    for (let key in restaurantImages) {
        const restImage = restaurantImages[key];

        //Store its own images as well, to prevent content deletion
        imageLinker[key] = restImage;

        for (let tempKey in templateImages) {
            const tempImage = templateImages[tempKey];

            /** //INFO If the file name of a template image matches with the restaurant image, that is a 'copied' image.
             * Therefore, the imageLinker will store the restaurant image model through the image_id of the
             * template image. When the dom is looped through and an image_id is found, the id can be replaced
             * to the restaurant image instance through the imageLinker. If an image_id isn't found in the replace process,
             * the 'content' containing the image_id will be deleted, whether it's a backgroundImage, seo, or whatever.
             */
            if (tempImage.file_name === restImage.file_name) {
                imageLinker[tempKey] = restImage;
            }
        }
    }
    return imageLinker;
};
