import { Save } from "@carbon/icons-react";
import { ILang } from "../../../Interfaces/ILang.type";
import { SeoModel } from "sitebuilder-common";
import { PathSlug } from "../../../Utils/Slugify.util";
import { TextInput } from "../../UI/InputFields/TextInput/TextInput.view";
import { ImageInput } from "../../UI/InputFields/ImageInput/Controller/ImageInput.controller";
import { SelectInput } from "../../UI/InputFields/Select/SelectInput.view";
import { FC, Fragment } from "react";
import { useRestaurant } from "../../../Contexts/Restaurant.context";
import { FloatingError } from "xa-error-with-lang";
import { CheckboxInput } from "../../UI/InputFields/Checkbox/Checkbox.view";
import { useTranslation } from "react-i18next";
import { IUseFormResult } from "../../UseForm/UseForm.provider";
import { DomlessPageModel } from "../../DomTracker/Model/DomlessPage.model";
import { IPageEditorProps } from "../Controller/PageEditor.controller";
import { IError, ILoading, ISetState } from "xa-generics";
import FormSeparatorView from "../../UI/FormSeparator/FormSeparator.view";
import Loading from "../../UI/Loading/Loading.view";
import Button from "../../UI/Button/Button.view";
import Modal from "../../UI/Modal/Modal.view";

interface IPageEditorViewProps extends IPageEditorProps {
    error: IError;
    loading: ILoading;
    onSubmit: () => void;
    validationMsg: string;
    setError: ISetState<IError>;
    setValidationMsg: ISetState<string>;
    form: IUseFormResult<DomlessPageModel, true>;
    onSeoChange: (id: keyof SeoModel, value: any, lang: string) => void;
}

export const PageEditorView: FC<IPageEditorViewProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { restaurant } = useRestaurant();

    const { handleChange, handleSubmit, editor, fieldErrors } = props.form;

    const seo1 = editor.seos.find((seo: SeoModel) => seo.lang === restaurant.primary_language)!;
    const seo2 = editor.seos.find((seo: SeoModel) => seo.lang === restaurant.secondary_language);

    const renderInputs = (seo: SeoModel | undefined) => {
        if (!seo || !seo.lang) return null;

        const onChange = (id: keyof SeoModel, value: any): void => {
            props.onSeoChange(id, value, seo.lang);
        };
        return (
            <Fragment>
                <TextInput<SeoModel>
                    id={"url"}
                    required
                    labelText={"URL"}
                    value={seo.url}
                    placeholder={seo.isTemporary ? t<ILang>("url_placeholder") : seo.url}
                    onChange={(i, value) => onChange("url", PathSlug(value))}
                />
                <TextInput<SeoModel>
                    id={"title"}
                    value={seo.title}
                    labelText={"title"}
                    onChange={onChange}
                />

                <TextInput<SeoModel>
                    id={"description"}
                    value={seo.description}
                    labelText={"description"}
                    onChange={onChange}
                />

                <ImageInput<SeoModel>
                    id={"image_id"}
                    value={seo.image_id}
                    labelText={"og_image_id"}
                    onChange={onChange}
                />

                <TextInput<SeoModel>
                    id={"image_alt"}
                    value={seo.image_alt}
                    labelText={"image_alt"}
                    onChange={onChange}
                />
            </Fragment>
        );
    };

    return (
        <>
            <form onSubmit={handleSubmit(props.onSubmit)} className="editor__page-form">
                <div className="editor__header">
                    <span>
                        {t<ILang>("pages")} / {editor.name || t<ILang>("new_page")}
                    </span>
                    <Button
                        type={"submit"}
                        size={"FIELD"}
                        kind={"PRIMARY"}
                        Icon={Save}
                        disabled={props.loading ? true : false}
                    >
                        {props.loading ? (
                            <Loading isInline isExternalConditional isAlternate size="sm" />
                        ) : (
                            t<ILang>("save")
                        )}
                    </Button>
                </div>
                <TextInput<DomlessPageModel>
                    required
                    id={"name"}
                    value={editor.name}
                    errors={fieldErrors}
                    onChange={handleChange}
                    labelText={"page_identifier_name"}
                    placeholder={t<ILang>("page_name_placeholder")}
                    description={t<ILang>("page_description")}
                />
                <ImageInput<DomlessPageModel>
                    id={"competition_image_id"}
                    value={editor.competition_image_id}
                    onChange={handleChange}
                />
                <SelectInput
                    options={[
                        { id: "top", name: t<ILang>("top") },
                        { id: "bottom", name: t<ILang>("bottom") }
                    ]}
                    id={"competition_image_align"}
                    value={editor.competition_image_align}
                    onChange={handleChange}
                />

                {!editor.page_type && (
                    <>
                        <CheckboxInput<DomlessPageModel>
                            id={"is_main_page"}
                            value={editor.is_main_page}
                            onChange={handleChange}
                        />
                        <CheckboxInput<DomlessPageModel>
                            id={"is_active"}
                            value={editor.is_active}
                            onChange={handleChange}
                        />
                    </>
                )}

                <FormSeparatorView
                    areKeysStrings
                    defaultGroup={renderInputs(seo1)}
                    advancedGroup={renderInputs(seo2)}
                    advancedKey={seo2?.lang.toUpperCase()}
                    defKey={seo1.lang.toUpperCase() as never}
                    noSecondaryKey={restaurant.secondary_language ? false : true}
                />
            </form>
            <FloatingError error={props.error} resetError={() => props.setError(null)} />
            {props.validationMsg && (
                <Modal
                    isPassive
                    size={"XS"}
                    heading={t<ILang>("warning")}
                    onClose={() => props.setValidationMsg("")}
                >
                    {props.validationMsg}
                </Modal>
            )}
        </>
    );
};
