import { Add, Menu, Close, Logout, Settings, Template, Book } from "@carbon/icons-react";
import { ISetState, useMountWithTriggers } from "xa-generics";
import { useWidgetEditor } from "../../Editor/Controller/WidgetEditorContext.provider";
import { useTranslation } from "react-i18next";
import { useRestaurant } from "../../../Contexts/Restaurant.context";
import { SizeMenuItem } from "./SizeMenuItem.view";
import { LangBoxView } from "./LangBox.view";
import { GetSizeIcon } from "../Utils/GetSizeIcon.util";
import { LongSelect } from "../../UI/InputFields/LongSelect/LongSelect.view";
import { useAccess } from "../../../Contexts/Access.context";
import { useEditor } from "../../Editor/Controller/EditorContext.provider";
import { useState } from "react";
import { useDom } from "../../DomTracker/Controller/DomTracker.provider";
import { ILang } from "../../../Interfaces/ILang.type";
import Loading from "../../UI/Loading/Loading.view";
import Button from "../../UI/Button/Button.view";
import { Dictionary } from "../../Dictionary/Controller/Dictionary.controller";

export interface ISidemenuViewProps {
    setShowCMPS: ISetState<boolean>;
    showCMPS: boolean;
    setShowHistory: ISetState<boolean>;
    showHistory: boolean;
    showConfig: boolean;
    setShowConfig: ISetState<boolean>;
    showTemplates: boolean;
    setShowTemplates: ISetState<boolean>;
}

const SidemenuHeaderView: React.FC<ISidemenuViewProps> = (props) => {
    const [showSizes, setshowSizes] = useState<boolean>(false);
    const [showControls, setShowControls] = useState<boolean>(false);
    const [showDictionary, setShowDictionary] = useState<boolean>(false);

    const { size, pages, currentPage } = useDom();
    const { widgetEditor, setWidgetEditor } = useWidgetEditor();
    const { editor, pageEditor, setEditor, setPageEditor } = useEditor();
    const { restaurant, switchToRestaurant, targets } = useRestaurant();
    const { logout, loading } = useAccess();
    const { t } = useTranslation<ILang>();

    const isCreateDisabled: boolean = pages.length === 0 || !currentPage.isCurrentPageDraft;
    const createClasses: string[] = ["menu-head__creator"];
    if (isCreateDisabled) createClasses.push("disabled-create");

    const hasComponent =
        props.showCMPS ||
        props.showHistory ||
        editor ||
        widgetEditor.group ||
        pageEditor ||
        props.showConfig ||
        props.showTemplates;

    const unsetEverything = (): void => {
        if (props.showCMPS) props.setShowCMPS(false);
        if (showSizes) setshowSizes(false);
        if (props.showHistory) props.setShowHistory(false);
        if (editor) setEditor(null);
        if (widgetEditor) setWidgetEditor({ group: null, subKey: "" });
        if (pageEditor) setPageEditor(null);
        if (props.showConfig) props.setShowConfig(false);
        if (props.showTemplates) props.setShowTemplates(false);
    };

    const handleBurgerClick = (e: MouseEvent): void => {
        const path = e.composedPath();
        let hasID = false;
        for (let target of path) {
            const id = (target as HTMLElement)?.id;
            if (id === `menu-head-controls`) hasID = true;
        }
        if (hasID) return;
        setShowControls(false);
    };
    const handleSizeClick = (e: MouseEvent): void => {
        const path = e.composedPath();
        let hasID = false;
        for (let target of path) {
            const id = (target as HTMLElement)?.id;
            if (id === `menu-size-controls`) hasID = true;
        }
        if (hasID) return;
        setshowSizes(false);
    };

    const sizeClasses: string[] = ["menu-head__icon"];
    const configClasses: string[] = ["menu-head__icon"];
    const pagesClasses: string[] = ["menu-head__pages menu-head__icon"];

    useMountWithTriggers(() => {
        window.addEventListener("click", handleBurgerClick);
        return () => {
            window.removeEventListener("click", handleBurgerClick);
        };
    }, [showControls]);

    useMountWithTriggers(() => {
        window.addEventListener("click", handleSizeClick);
        return () => {
            window.removeEventListener("click", handleSizeClick);
        };
    }, [showSizes]);

    if (showSizes) sizeClasses.push("--active");
    if (showControls) pagesClasses.push("--active");
    if (props.showConfig) configClasses.push("--active");

    return (
        <>
            <div className="menu-head">
                <div
                    className={pagesClasses.join(" ")}
                    onClick={() => {
                        if (hasComponent) unsetEverything();
                        else setShowControls(!showControls);
                    }}
                    id={"menu-head-controls"}
                >
                    {hasComponent ? <Close size={20} /> : <Menu size={20} />}
                    {showControls && (
                        <div className="menu-head__dropmenu">
                            <div className="menu-head__dropmenu--close">
                                {t<ILang>("page_controls")} <Close />
                            </div>
                            {restaurant.secondary_language && (
                                <div className="menu-head__dropmenu--lang">
                                    <LangBoxView
                                        lang={restaurant.primary_language}
                                        unsetEverything={unsetEverything}
                                    />
                                    <span className="separator">/</span>
                                    <LangBoxView
                                        lang={restaurant.secondary_language}
                                        unsetEverything={unsetEverything}
                                    />
                                </div>
                            )}
                            {targets.length > 1 ? (
                                <form
                                    className="menu-head__dropmenu--restaurant"
                                    onClick={(e) => e.stopPropagation()}
                                    onSubmit={(e) => e.preventDefault()}
                                    autoComplete={"off"}
                                >
                                    <LongSelect
                                        noLabel
                                        options={targets}
                                        value={restaurant.id}
                                        id={"restaurant_selector"}
                                        onChange={(id, value, opiton) => switchToRestaurant(value)}
                                    />
                                </form>
                            ) : null}
                            <Button
                                Icon={<Template size={20} />}
                                kind={"GHOST"}
                                size={"NORMAL"}
                                className={"menu-head__dropmenu--templates"}
                                onClick={() => props.setShowTemplates(!props.showTemplates)}
                            >
                                {t<ILang>("rest_template")}
                            </Button>
                            <Button
                                kind={"DANGER"}
                                size={"NORMAL"}
                                onClick={() => logout()}
                                Icon={Logout}
                            >
                                {loading ? (
                                    <Loading isInline isExternalConditional />
                                ) : (
                                    t<ILang>("logout")
                                )}
                            </Button>
                        </div>
                    )}
                </div>
                <div
                    onClick={() => setshowSizes(!showSizes)}
                    className={sizeClasses.join(" ")}
                    title={t<ILang>("show_size")}
                    id={"menu-size-controls"}
                >
                    {GetSizeIcon(size)}
                    {showSizes && (
                        <div className="menu-head__dropmenu">
                            <div className="menu-head__dropmenu--close">
                                {t<ILang>("screen_size")} <Close />
                            </div>
                            <SizeMenuItem domSize={"xs"} />
                            <SizeMenuItem domSize={"sm"} />
                            <SizeMenuItem domSize={"md"} />
                            <SizeMenuItem domSize={"lg"} />
                            <SizeMenuItem domSize={"xl"} />
                        </div>
                    )}
                </div>

                <div
                    className={configClasses.join(" ")}
                    onClick={() => props.setShowConfig(!props.showConfig)}
                    title={t<ILang>("show_config")}
                >
                    <Settings size={20} />
                </div>

                <div
                    className={"menu-head__icon"}
                    onClick={() => setShowDictionary(!showDictionary)}
                    title={t<ILang>("texts")}
                >
                    <Book size={20} />
                </div>

                <div
                    className={createClasses.join(" ")}
                    title={t<ILang>(props.showCMPS ? "close" : "create_components")}
                    onClick={() => {
                        if (!isCreateDisabled) props.setShowCMPS(!props.showCMPS);
                    }}
                >
                    <div className="svg">
                        {props.showCMPS ? <Close size={32} /> : <Add size={32} />}
                    </div>
                </div>
            </div>

            {showDictionary && <Dictionary onClose={() => setShowDictionary(false)} />}
        </>
    );
};

export default SidemenuHeaderView;
