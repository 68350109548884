import { ISelectOption } from "xa-generics";

export const PRODUCT_FONT_SIZE_OPTIONS: ISelectOption[] = [
    { id: "1", name: "1rem" },
    { id: "1.2", name: "1.2rem" },
    { id: "1.4", name: "1.4rem" },
    { id: "1.5", name: "1.5rem" },
    { id: "1.6", name: "1.6rem" },
    { id: "1.7", name: "1.7rem" },
    { id: "1.8", name: "1.8rem" },
    { id: "1.9", name: "1.9rem" },
    { id: "2", name: "2rem" },
    { id: "2.2", name: "2.2rem" },
    { id: "2.3", name: "2.3rem" },
    { id: "2.4", name: "2.4rem" },
    { id: "2.5", name: "2.5rem" },
    { id: "2.6", name: "2.6rem" }
];
