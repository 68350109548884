import { FC, isValidElement } from "react";

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    useContainer?: boolean;
    align?: "left" | "center" | "right";
    Icon?: FC<any> | React.ReactNode;
    type?: "submit" | "reset" | "button";
    kind?: "PRIMARY" | "SECONDARY" | "GHOST" | "SIMPLE" | "DANGER" | "MEGA";
    size?: "NORMAL" | "FIELD" | "SMALL";
}

const Button: React.FC<IButtonProps> = ({
    Icon,
    useContainer,
    align,
    type,
    kind,
    size,
    className,
    ...props
}) => {
    const classes: string[] = ["om-btn"];
    if (kind) classes.push(`--${kind}`);
    if (size) classes.push(`--${size}`);
    if (props.disabled) classes.push("--disabled");
    if (className) classes.push(className);

    const getContainer = (child: JSX.Element): JSX.Element => {
        if (useContainer) {
            return <div className={`om-container --${align || "right"}`}>{child}</div>;
        } else return <>{child}</>;
    };
    return getContainer(
        <button {...props} className={classes.join(" ")} type={type || "button"}>
            <div className="om-btn__text">{props.children}</div>
            {/* @ts-ignore */}
            {Icon && <div className="om-btn__icon">{isValidElement(Icon) ? Icon : <Icon />}</div>}
        </button>
    );
};

export default Button;
