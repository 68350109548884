import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { DraftModel } from "../../../DomTracker/Model/Draft.model";
import { PageDataModel } from "../../../DomTracker/Model/PageData.model";
import { useTranslation } from "react-i18next";

interface IPageNameProps {
    page: PageDataModel;
    draft: DraftModel | null;
}

export const PageName: FC<IPageNameProps> = ({ page, draft }) => {
    const { t } = useTranslation<ILang>();
    return (
        <div className="name">
            {page.name}
            {draft && <span className="draft-name"> {t<ILang>("draft")}</span>}
            {!page.is_active && !draft && (
                <span className="draft-name"> {t<ILang>("not_published")}</span>
            )}
        </div>
    );
};
