import { ILang } from "../../../Interfaces/ILang.type";
import { useDom } from "../../DomTracker/Controller/DomTracker.provider";
import { IWidgets } from "../Interfaces/IWidgets.type";
import { cloneDeep } from "lodash";
import { WidgetsDAO } from "../DAO/Widgets.dao";
import { ALL_STYLES } from "../../../Static/AllStyles.static";
import { WidgetsModel } from "../Model/Widgets.model";
import { v4 as uuidv4 } from "uuid";
import { useRestaurant } from "../../../Contexts/Restaurant.context";
import { FloatingError } from "xa-error-with-lang";
import { useTranslation } from "react-i18next";
import { IWidgetContent } from "../Interfaces/IWidgetContent.interface";
import { IWidgetsContext } from "../Interfaces/IWidgetsContext.interface";
import { IGenericCSSFields } from "sitebuilder-common";
import { WidgetDataTransform } from "../Utils/WidgetDataTransform.util";
import { useActiveFontCollection } from "../../../Contexts/ActiveFontCollection.context";
import { IError, ILoading, useDidMount } from "xa-generics";
import { Context, createContext, useState, useContext } from "react";
import Loading from "../../UI/Loading/Loading.view";

/**
 * ## WidgetsContext
 */
const WidgetsContext: Context<IWidgetsContext<any>> = createContext<IWidgetsContext<any>>(
    null as any
);

WidgetsContext.displayName = "WidgetsContext";

interface IWidgetsContextProviderProps {}

/**
 * ## Widgets context provider component
 *
 */
export const WidgetsContextProvider: React.FC<IWidgetsContextProviderProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { size } = useDom();
    const { restaurant } = useRestaurant();
    const { checkFontCollection } = useActiveFontCollection();

    const [error, setError] = useState<IError>(null);
    const [init, setInit] = useState<ILoading>(false);
    const [widgets, setWidgets] = useState<IWidgets>(
        new WidgetsModel().getWidgetsJSON(restaurant.lct_enabled)
    );
    const [loading, setLoading] = useState<ILoading>(true);

    const loadWidgets = (): void => {
        setLoading({
            reason: t<ILang>("loading"),
            promise: WidgetsDAO.load()
                .then((model) => {
                    setWidgets(model.getWidgetsJSON(restaurant.lct_enabled));
                    if (!init) setInit(true);
                })
                .catch((error: IError) => setError(error))
                .finally(() => setLoading(false))
        });
    };
    useDidMount(loadWidgets);

    const saveWidgets = (newState?: IWidgets): void => {
        setLoading({
            reason: t<ILang>("loading"),
            promise: WidgetsDAO.save(newState || widgets)
                .catch((error: IError) => setError(error))
                .finally(() => setLoading(false))
        });
    };

    const ensureWidgetDataExists = (clonedState: IWidgets, widgetType: keyof IWidgets): void => {
        if (!clonedState[widgetType]) {
            clonedState[widgetType].uuid = uuidv4();
            clonedState[widgetType].type = widgetType;
        }
        if (!clonedState[widgetType].draftSettings) {
            clonedState[widgetType].draftSettings = cloneDeep(clonedState[widgetType].settings);
        }
    };

    const clearRulesBySize = (
        widgetType: keyof IWidgets,
        widgetProp: string,
        keys: (keyof IGenericCSSFields)[]
    ): void => {
        const state = cloneDeep(widgets);
        if (state[widgetType].draftSettings) {
            if (state[widgetType].draftSettings[widgetProp as never]) {
                for (let key of keys) {
                    delete state[widgetType].draftSettings[widgetProp as never][key];
                }
            }
        }
        setWidgets(state);
        saveWidgets(state);
    };

    const updateWidget = (values: IWidgetContent<any>[]): void => {
        const state = cloneDeep(widgets);
        for (let value of values) {
            const { widgetProp, widgetType, data } = value;
            ensureWidgetDataExists(state, widgetType);

            if (!(data.field in ALL_STYLES)) {
                WidgetDataTransform(state[widgetType], data, data.field as string, size, false);
            } else {
                WidgetDataTransform(state[widgetType], data, widgetProp as string, size, true);
            }
        }
        setWidgets(state);
        saveWidgets(state);
    };

    const publishWidget = (key: keyof IWidgets): void => {
        const state = cloneDeep(widgets);
        state[key].settings = cloneDeep(state[key].draftSettings);
        setWidgets(state);
        saveWidgets(state);
        checkFontCollection(state);
    };

    return (
        <WidgetsContext.Provider
            value={{
                clearRulesBySize,
                publishWidget,
                updateWidget,
                saveWidgets,
                setWidgets,
                loading,
                widgets
            }}
        >
            <FloatingError error={error} resetError={() => setError(null)} />
            {loading && !init ? (
                <Loading loading={loading} isAbsolute useSpacing size={"lg"} />
            ) : (
                props.children
            )}
        </WidgetsContext.Provider>
    );
};

export const useWidgets = <ValueType extends object>(): IWidgetsContext<ValueType> => {
    const ctx = useContext(WidgetsContext) as never as IWidgetsContext<ValueType>;
    return ctx;
};
